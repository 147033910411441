import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import * as SC from '../StyledComponents'
import EventIcon from './EventIcon'

const EventIconWithBackground: FunctionComponent<{
  eventType: types.EventTypeString
}> = ({ eventType }) => {
  const EventTypeToColor: { [type in types.EventTypeString]: string } = {
    tour: '#FB923C',
    email: '#6366F1',
    sms: '#6366F1',
    call: '#6366F1',
    rental_application: '#14B8A6',
    note: '#9CA3AF',
    inquiry: '#0EA5E9',
    task: '#10B981'
  }

  const color = EventTypeToColor[eventType]

  return (
    <SC.IconBackground color={color}>
      <EventIcon eventType={eventType} />
    </SC.IconBackground>
  )
}

export default EventIconWithBackground
