import { Tab } from '@headlessui/react'
import { useHomeByPropertyIdQuery } from 'graphql/generated'
import React, { useEffect, useMemo, useState } from 'react'
import { FunctionComponent } from 'react'
import { RouteComponentProps, useParams, useHistory } from 'react-router'
import { Spinner } from 'reactstrap'
import PropertyHeader from './HomeHeader'
import NavBar, { HudsonPathPart } from './NavBar'
import { ConstructionPanel } from './Panels/ConstructionPanel'
import { OverviewPanel } from './Panels/OverviewPanel'

const TABS = ['Overview', 'Construction', 'Other']
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const PATH_TO_PAGE: HudsonPathPart[] = [
  {
    name: 'Homes',
    href: '/homes'
  }
]
const HomeProfile: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const { propertyId } = useParams<{ propertyId: string }>()
  const currentTab = location.hash
  console.log(currentTab)
  const [selectedIndex, setSelectedIndex] = useState(0)
  useEffect(() => {
    const tabIndex = TABS.map((tab) => `#${tab.toLowerCase()}`).indexOf(
      currentTab
    )
    if (tabIndex != -1) {
      setSelectedIndex(tabIndex)
    }
  }, [currentTab])
  const context = useMemo(() => ({ suspense: false }), [])
  const history = useHistory()
  const [{ data }] = useHomeByPropertyIdQuery({
    variables: {
      propertyId
    },
    context
  })

  if (!data) {
    return <Spinner />
  }

  const property = data.properties[0]
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '100px',
        marginLeft: '100px'
      }}
    >
      {/** TODO: somehow have this be a global context thing that pages add/subtract to */}
      <NavBar
        path={[
          ...PATH_TO_PAGE,
          {
            name:
              property.real_estate_acquisition?.mls_listing.display_line_1 ||
              '<address>',
            href: `#`
          }
        ]}
      />
      {/** TODO: have property header be on top of scrolling content */}
      <PropertyHeader property={property} />
      <div className='w-full max-w-md px-2 py-8 sm:px-0'>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className='flex space-x-4 border-0 border-b border-t border-gray-200 border-solid'>
            {TABS.map((tab) => (
              <Tab
                onClick={() =>
                  history.replace({
                    hash: tab.toLowerCase()
                  })
                }
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-gray-100 text-gray-700'
                      : 'bg-white text-gray-500 hover:text-gray-700',
                    'my-3 px-3 py-2 font-medium text-sm rounded-md border-0'
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <OverviewPanel property={property} />
            <ConstructionPanel property={property} />
            <Tab.Panel>Panel 3</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}

export default HomeProfile
