import {
  Rental_Applications_ItemsFragment,
  Mls_Listing_ItemsFragment,
  Rental_Site_Lead_ItemsFragment,
  Conversations_ItemsFragment,
  Tour_ItemsFragment
} from 'graphql/generated'

import { format } from 'date-fns'

const dateFormatString: string = 'M/d/yyyy h:mm a'

type Maybe<T> = T | null | undefined

type InteractionType =
  | 'tour'
  | 'sms'
  | 'email'
  | 'voice'
  | 'rental_application_event'
  | 'rental_site_inquiry'

export interface SortableInteraction {
  interactionType: InteractionType
  happenedAt: Date
  happenedAtFormatted: string
}

type TourEventType = 'scheduled' | 'cancelled' | 'confirmed' | 'completed'

export class PropertyDetails {
  id: any
  address?: Maybe<string>
  city?: Maybe<string>
  state?: Maybe<string>
  county?: Maybe<string>
  zip?: Maybe<string>
  description?: Maybe<string>
  size?: Maybe<string>
  listPrice?: any | null | undefined
  propertyType?: Maybe<string>
  area?: Maybe<string>
  bathsFull?: Maybe<number>
  bathsHalf?: Maybe<number>
  bathsTotal?: Maybe<number>
  firestoreId: any
  status?: Maybe<string>
  lotsizeSqft?: any | null | undefined
  rent?: any | null | undefined
  internalStatus?: Maybe<string>
  market?: string

  constructor(details: Mls_Listing_ItemsFragment) {
    this.id = details?.id
    this.address = details?.delivery_line
    this.city = details?.city
    this.state = details?.state
    this.county = details?.county
    this.zip = details?.zip
    this.description = details?.description
    this.size = details?.size
    this.listPrice = details?.list_price
    this.propertyType = details?.property_type
    this.area = details?.area
    this.bathsFull = details?.baths_full
    this.bathsHalf = details?.baths_half
    this.firestoreId = details?.firestore_id
    this.status = details?.status
    this.lotsizeSqft = details?.lotsize_sqft
    this.rent = details?.internal_attributes[0]?.rent
    this.internalStatus = details?.internal_attributes[0]?.internal_status
    this.market = details?.market.display_name
  }
}

export class TourEvent implements SortableInteraction {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  scheduledFor: string
  interactionType: 'tour'
  eventType: TourEventType
  tourType: Maybe<string>
  status: string
  propertyDetails?: Maybe<PropertyDetails>

  constructor(details: Tour_ItemsFragment, eventType: TourEventType) {
    this.interactionType = 'tour'
    this.id = details?.id
    this.scheduledFor = format(new Date(details?.start_at), dateFormatString)
    this.eventType = eventType
    this.tourType = details.type
    this.status = details.status
    this.propertyDetails = details?.tour_properties?.at(0)?.mls_listing
      ? new PropertyDetails(details.tour_properties[0].mls_listing)
      : undefined

    switch (eventType) {
      case 'scheduled': {
        this.happenedAt = new Date(details.created_at)
        break
      }
      case 'cancelled': {
        this.happenedAt = new Date(details.canceled_at)
        break
      }
      case 'confirmed': {
        this.happenedAt = new Date(details.lead_group_confirmed_at)
        break
      }
      case 'completed': {
        this.happenedAt = new Date(details.completed_at)
        break
      }
      default: {
        this.happenedAt = details.start_at
      }
    }

    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
  }
}

export interface SMS extends SortableInteraction {
  id: any
  happenedAt: Date
  interactionType: 'sms'
  from?: Maybe<string>
  externalSource: string
  direction: string
  content: string
}

interface SMSClosePayloadStructure {
  event: {
    data: {
      text: string
    }
  }
}

export class SMSClose implements SMS {
  id: any
  interactionType: 'sms'
  happenedAt: Date
  happenedAtFormatted: string
  from?: Maybe<string>
  externalSource: string
  direction: string
  content: string

  constructor(details: Conversations_ItemsFragment) {
    this.interactionType = 'sms'
    this.id = details.id
    this.happenedAt = new Date(details.created_at)
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
    this.externalSource = details.external_source
    this.direction = details.direction
    this.from = details.from

    const payloadJSON: SMSClosePayloadStructure = JSON.parse(
      JSON.stringify(details.payload)
    )
    this.content = payloadJSON.event.data.text
  }
}

interface SMSCustomerIOPayloadStructure {
  data: {
    content: string
  }
}

export class SMSCustomerIO implements SMS {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  interactionType: 'sms'
  from?: Maybe<string>
  externalSource: string
  direction: string
  content: string

  constructor(details: Conversations_ItemsFragment) {
    this.interactionType = 'sms'
    this.id = details.id
    this.happenedAt = new Date(details.created_at)
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
    this.externalSource = details.external_source
    this.direction = details.direction
    this.from = details.from

    const payloadJSON: SMSCustomerIOPayloadStructure = JSON.parse(
      JSON.stringify(details.payload)
    )
    this.content = payloadJSON.data.content
  }
}

export interface Email extends SortableInteraction {
  id: any
  happenedAt: Date
  interactionType: 'email'
  externalSource: string
  direction: string
  status: string
  subject: Maybe<string>
  to: Maybe<string> | string[]
  content: Maybe<string>
  sender?: Maybe<string>
  preview?: string
}

interface EmailCloseStructure {
  event: {
    data: {
      subject: string
      to: string[]
      sender: string
      body_text: string
      body_preview: string
    }
  }
}

export class EmailClose implements Email {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  interactionType: 'email'
  externalSource: string
  direction: string
  status: string
  subject: Maybe<string>
  to: Maybe<string> | string[]
  content: Maybe<string>
  sender?: Maybe<string>
  preview?: string

  constructor(details: Conversations_ItemsFragment) {
    this.interactionType = 'email'
    this.id = details.id
    this.happenedAt = new Date(details.created_at)
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
    this.status = details.status
    this.externalSource = details.external_source
    this.direction = details.direction

    const payloadJSON: EmailCloseStructure = JSON.parse(
      JSON.stringify(details.payload)
    )

    this.subject = payloadJSON.event.data.subject
    this.to = payloadJSON.event.data.to
    this.sender = payloadJSON.event.data.sender
    this.content = payloadJSON.event.data.body_text
    this.preview = payloadJSON.event.data.body_preview
  }
}

interface EmailCustomerIOStructure {
  data: {
    content: Maybe<string>
    subject: Maybe<string>
    recipient: string
  }
}

export class EmailCustomerIO implements Email {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  interactionType: 'email'
  externalSource: string
  direction: string
  status: string
  subject: Maybe<string>
  to: Maybe<string>
  content: Maybe<string>
  sender?: Maybe<string>

  constructor(details: Conversations_ItemsFragment) {
    this.interactionType = 'email'
    this.id = details.id
    this.happenedAt = new Date(details.created_at)
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
    this.status = details.status
    this.externalSource = details.external_source
    this.direction = details.direction

    const payloadJSON: EmailCustomerIOStructure = JSON.parse(
      JSON.stringify(details.payload)
    )

    this.subject = payloadJSON.data.subject
    this.to = payloadJSON.data.recipient

    let parser = new DOMParser()
    let doc = parser.parseFromString(
      payloadJSON.data?.content ? payloadJSON.data?.content : '',
      'text/html'
    )
    this.content = doc.body.innerText
  }
}

export interface Voice extends SortableInteraction {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  interactionType: 'voice'
  from?: Maybe<string>
  externalSource: string
  direction: string
}

export class VoiceClose implements Voice {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  interactionType: 'voice'
  from?: Maybe<string>
  externalSource: string
  direction: string

  constructor(details: Conversations_ItemsFragment) {
    this.interactionType = 'voice'
    this.id = details.id
    this.happenedAt = new Date(details.created_at)
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
    this.from = details.from
    this.externalSource = details.external_source
    this.direction = details.direction
  }
}

export class RentalSiteInquiry implements SortableInteraction {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  source: string
  interactionType: InteractionType
  inquiryComments?: Maybe<string>
  propertyDetails?: Maybe<PropertyDetails>

  constructor(details: Rental_Site_Lead_ItemsFragment) {
    this.interactionType = 'rental_site_inquiry'
    this.id = details.id
    this.happenedAt = new Date(details.created_at)
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
    this.source = details.rental_site_listing.rental_site_account.source
    this.inquiryComments = details.lead_comments
    this.propertyDetails = details?.rental_site_listing.mls_listing
      ? new PropertyDetails(details.rental_site_listing.mls_listing)
      : null
  }
}

type RentalApplicationEventType =
  | 'cancelled'
  | 'created'
  | 'underwritten'
  | 'completed'

export class RentalApplicationEvent implements SortableInteraction {
  id: any
  happenedAt: Date
  happenedAtFormatted: string
  interactionType: 'rental_application_event'
  eventType: RentalApplicationEventType
  cancellationReason?: Maybe<string>
  projectedOccupancyDate?: Date
  propertyDetails?: Maybe<PropertyDetails>
  currentStatus: string

  constructor(
    details: Rental_Applications_ItemsFragment,
    eventType: RentalApplicationEventType
  ) {
    this.id = details.id
    this.interactionType = 'rental_application_event'
    this.eventType = eventType
    this.projectedOccupancyDate = details.projected_occupancy_date
    this.currentStatus = details.status
    this.propertyDetails = details.mls_listing
      ? new PropertyDetails(details.mls_listing)
      : undefined

    switch (eventType) {
      case 'created': {
        this.happenedAt = new Date(details.created_at)
        break
      }
      case 'underwritten': {
        this.happenedAt = new Date(details.underwritten_at)
        break
      }
      case 'completed': {
        this.happenedAt = new Date(details.completed_at)
        break
      }
      case 'cancelled': {
        this.happenedAt = new Date(details.canceled_at)
        this.cancellationReason = details.cancelation_reason
        break
      }
      default: {
        this.happenedAt = new Date(details.created_at)
      }
    }
    this.happenedAtFormatted = format(this.happenedAt, dateFormatString)
  }
}

export type Interaction = (
  | SMSClose
  | SMSCustomerIO
  | EmailClose
  | EmailCustomerIO
  | VoiceClose
  | RentalApplicationEvent
  | RentalSiteInquiry
  | TourEvent
) & {
  [key: string]: any
}
