import React from 'react'
import { Button } from 'components/Toolkit'
import { CSVLink } from 'react-csv'

export default function DownloadButton({
  data,
  label,
  filePrefix
}: {
  data: string
  label: string
  filePrefix: string
}) {
  return (
    <Button
      size='vvs'
      isSecondary={false}
      style={{ color: 'white', marginBottom: '.5rem', marginRight: '1rem' }}
    >
      <CSVLink
        style={{ color: 'white', textDecoration: 'none' }}
        data={data}
        filename={`${filePrefix}${new Date().getTime()}.csv`}
      >
        {label}
      </CSVLink>
    </Button>
  )
}
