"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNOOZE_REASONS = exports.ARCHIVE_REASONS = exports.LEAD_STATUS_PRIORITY = exports.LEAD_STATUSES = void 0;
const rental_applications_1 = require("./rental-applications");
exports.LEAD_STATUSES = Object.freeze({
    APPLIED: 'applied',
    NEEDS_APPLICATION_INTRO: 'needs_application_intro',
    NEEDS_TOUR_FOLLOW_UP: 'needs_tour_follow_up',
    INCOMPLETE_TOUR: 'incomplete_tour',
    NEEDS_PRIORITY_MATCHING: 'needs_priority_matching',
    NEEDS_STANDARD_MATCHING: 'needs_standard_matching'
});
/** lower index = higher priority */
exports.LEAD_STATUS_PRIORITY = Object.freeze([
    exports.LEAD_STATUSES.APPLIED,
    exports.LEAD_STATUSES.NEEDS_APPLICATION_INTRO,
    exports.LEAD_STATUSES.NEEDS_TOUR_FOLLOW_UP,
    exports.LEAD_STATUSES.INCOMPLETE_TOUR,
    exports.LEAD_STATUSES.NEEDS_PRIORITY_MATCHING,
    exports.LEAD_STATUSES.NEEDS_STANDARD_MATCHING
]);
exports.ARCHIVE_REASONS = Object.freeze(Object.assign({ LEASE_TERMS: 'lease_terms', DEPOSIT_TERMS: 'deposit_terms', TOO_MANY_NO_SHOWS: 'too_many_no_shows', CORE_PROGRAM: 'core_program', FOUND_A_HOME: 'found_a_home', DECIDED_TO_BUY: 'decided_to_buy', MOVING_IN_TIMELINE: 'moving_in_timeline', UNRESPONSIVE: 'unresponsive', UNQUALIFIED_OUT_OF_FUNNEL: 'unqualified_out_of_funnel', INVALID_CONTACT_INFO: 'invalid_contact_info', UNABLE_TO_MATCH: 'unable_to_match' }, rental_applications_1.RENTAL_APPLICATION_CANCELATION_REASONS));
exports.SNOOZE_REASONS = Object.freeze({
    AUTO_SNOOZED_ON_CREATION: 'auto_snoozed_on_creation'
});
