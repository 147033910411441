import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { startCase, kebabCase } from 'lodash'
import { Button } from 'reactstrap'

import { firestoreCollections } from '../../lib/firebase'
import Dropzone from '../Dropzone'
import connect from 'react-redux/es/connect/connect'
import moment from 'moment'

class UserTask extends Component {
  static propTypes = {
    userTask: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired
  }

  state = {
    showDropzone: false
  }

  handleToggle = () => {
    this.setState({
      showDropzone: !this.state.showDropzone
    })
  }

  handleSuccess = () => {
    firestoreCollections.userTasksRef
      .doc(this.props.userTask.id)
      .update({ completed: true })
  }

  render() {
    const { userTask, user, property } = this.props
    const { showDropzone } = this.state

    if (!property) return null

    return (
      <>
        <tr>
          <td>
            {user.firstName} {user.lastName}
          </td>
          <td>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </td>
          <td>{user.phone}</td>
          <td>{property.address.displayLine1}</td>
          <td>{startCase(userTask.name)}</td>
          <td>{moment(userTask.createdAt.toDate()).format('YYYY-MM-DD')}</td>
          <td>
            {userTask.type === 'document_upload' && (
              <Button
                color={showDropzone ? 'danger' : 'primary'}
                onClick={this.handleToggle}
              >
                {showDropzone ? 'Cancel' : 'Upload'}
              </Button>
            )}
          </td>
        </tr>

        {showDropzone && (
          <tr>
            <td colSpan='4'>
              <Dropzone
                onSuccess={this.handleSuccess}
                header={`${startCase(userTask.name)}`}
                uploadPath={`/users/${userTask.userId}/documents/${kebabCase(
                  userTask.name
                )}`}
              />
            </td>
          </tr>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.users.firebaseUsers.get(props.userTask.userId),
  property: state.properties.data.get(props.userTask.metadata.propertyId)
})

export default connect(mapStateToProps)(UserTask)
