import React, { FunctionComponent } from 'react'
import { useQuery } from 'urql'
import { Spinner } from 'reactstrap'
import { startCase } from 'lodash'

import * as SC from '../StyledComponents'
import { REQUEST_POLICY } from 'constants/urql'
import { LeasingStaffByUserIdDocument as queryDoc } from 'graphql/generated'
import { AssignedLeasingStaff } from '../types'

type LeasingStaffComponentPropTypes = {
  userId: string
}

const LeasingStaff: FunctionComponent<LeasingStaffComponentPropTypes> = ({
  userId
}) => {
  const [{ data, fetching, error }] = useQuery({
    query: queryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  if (fetching) {
    return <Spinner />
  }
  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  const leasingStaff = data?.agent_user_owners
  if (!leasingStaff.length) {
    return <SC.MessageText>No assigned leasing staff found</SC.MessageText>
  }

  return (
    <SC.SmallBoxContainer>
      {leasingStaff.map((ls: AssignedLeasingStaff, i: number) => (
        <SC.DetailsContainer key={i}>
          <SC.DetailHeading>{startCase(ls.agent_type) || ''}</SC.DetailHeading>
          <SC.DetailText>
            {ls.admin?.first_name || ''} {ls.admin?.last_name || ''}
          </SC.DetailText>
        </SC.DetailsContainer>
      ))}
    </SC.SmallBoxContainer>
  )
}

export default LeasingStaff
