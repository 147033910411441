import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from 'urql'
import { Spinner } from 'reactstrap'
import { Comparators as Comp } from 'utils/hasura-comparators'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'

import axios from '../../lib/axios'
import AgentDetails from '../../components/SellerAgents/Details'
import AgentSearchDetails from '../../components/SellerAgents/SearchDetails'

import { Upup_SellerAgents_ListDocument as queryDoc } from 'graphql/generated'
import { HeaderText, Input } from 'components/Toolkit'

function SellerAgentEntries({ searchText = '', onSelect }) {
  const [{ data }] = useQuery({
    query: queryDoc,
    variables: {
      where: {
        seller_agent_searchable: {
          info: Comp.isLike(`%${searchText}%`)
        }
      }
    }
  })

  const sellerAgents = data?.seller_agents || []

  return searchText
    ? sellerAgents.map((agent) => (
        <AgentSearchDetails key={agent.id} agent={agent} onSelect={onSelect} />
      ))
    : null
}

SellerAgentEntries.propTypes = {
  searchText: PropTypes.string.isRequired
}

function SellerAgents() {
  const history = useHistory()
  const { agentId: urlAgentId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [agent, setAgent] = useState(false)

  const loadAgent = useCallback(
    async (agentId) => {
      setIsLoading(true)

      history.push(`/seller-agents/${agentId}`)

      const res = await axios.get(`/seller_agents/${agentId}`)

      setAgent(res.data)

      setIsLoading(false)
    },
    [history]
  )

  const selectAgent = useCallback(
    (agent) => {
      loadAgent(agent.id)
      setSearchText('')
      setInputText('')
    },
    [loadAgent]
  )

  useEffect(() => {
    if (urlAgentId) {
      setAgent(null)
      loadAgent(urlAgentId)
    }
  }, [urlAgentId, loadAgent])

  const [searchText, setSearchText] = useState('')
  const [inputText, setInputText] = useState('')

  const setSearchTextDebounced = React.useMemo(
    () => debounce(setSearchText, 250, { trailing: true }),
    [setSearchText]
  )

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto  ' }}>
      <HeaderText size='h3'>Seller Agents</HeaderText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px'
        }}
      >
        <div style={{ width: '600px', marginRight: '20px' }}>
          <Input
            placeholder='Search...'
            value={inputText}
            onChange={(value) => {
              // We need two separate values so we can debounce to the subscription
              setInputText(value)
              setSearchTextDebounced(value)
            }}
          />
        </div>
      </div>
      {isLoading && <p>Loading...</p>}
      {!isLoading && (
        <React.Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                padding: '6px',
                justifyContent: 'center',
                marginTop: '200px'
              }}
            >
              <Spinner color='primary' />
            </div>
          }
        >
          <SellerAgentEntries onSelect={selectAgent} searchText={searchText} />
          {agent && <AgentDetails agent={agent} />}
        </React.Suspense>
      )}
    </div>
  )
}

export default SellerAgents
