import React from 'react'
import PropTypes from 'prop-types'
import { UpupApplicationUserQualDocument } from '../../graphql/generated'
import { useQuery } from 'urql'
import { Alert, Spinner } from 'reactstrap'
import { startCase } from 'lodash'
import { REQUEST_POLICY } from 'constants/urql'

const QualificationStatus: React.FC<{ userId: string }> = ({ userId }) => {
  const [{ data, fetching, error }] = useQuery({
    query: UpupApplicationUserQualDocument,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })
  if (error != null) {
    return (
      <div style={{ display: 'flex' }}>
        <Alert color='danger'>Error</Alert>
      </div>
    )
  }
  if (fetching) {
    return (
      <div style={{ display: 'flex', padding: '6px' }}>
        <Spinner color='primary' />
      </div>
    )
  }
  return (
    <div>{startCase(data?.getUserQualificationData?.status ?? undefined)}</div>
  )
}

QualificationStatus.propTypes = {
  userId: PropTypes.string.isRequired
}

export default QualificationStatus
