import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { useMutation } from 'urql'

import { Button, Input, ErrorText } from 'components/Toolkit'
import { Upup_EditRentalAgreementHistoryDocument as EditRentalAgreementHistory } from 'graphql/generated'

export default function EditRentalAgreementHistoryForm({
  onSubmit = () => null,
  rentalAgreementHistory
}: {
  onSubmit: () => void
  rentalAgreementHistory: any
}) {
  const [endsAt, setEndsAt] = useState(rentalAgreementHistory.ends_at)
  const [isSaving, setIsSaving] = useState(false)

  const [{ error }, editRentalAgreementHistory] = useMutation(
    EditRentalAgreementHistory
  )

  const momentEndsAt = endsAt ? moment(endsAt) : null

  const isValid =
    momentEndsAt &&
    momentEndsAt.isValid() &&
    moment(rentalAgreementHistory.starts_at).isSameOrBefore(momentEndsAt)

  const submitRentalAgreementHistory = async () => {
    if (!isValid) {
      return
    }

    setIsSaving(true)

    const result = await editRentalAgreementHistory({
      ends_at: momentEndsAt.format('YYYY-MM-DD'),
      id: rentalAgreementHistory.id
    })

    setIsSaving(false)
    if (result.error === undefined) {
      setEndsAt(rentalAgreementHistory.ends_at)
      onSubmit()
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <Input
          label='Ends at'
          type='date'
          onChange={setEndsAt}
          value={endsAt}
          placeholder='Enter the date the new rent ends'
          required
          disabled={isSaving}
          hasError={!endsAt || !momentEndsAt?.isValid()}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={submitRentalAgreementHistory}
            disabled={!isValid || isSaving}
          >
            Edit Rental Agreement History
          </Button>
          {error && <ErrorText>{error.message}</ErrorText>}
        </div>
      </React.Suspense>
    </>
  )
}
