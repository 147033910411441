import React, { FunctionComponent, useState, useEffect } from 'react'
import axios from 'lib/axios'
import * as SC from '../StyledComponents'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState, TypedDispatch } from 'store'

import { setErrorMessage, setErrorModalIsOpen } from 'state/modals/actions'

import InputModal from '../Modals/InputModal'

import { hasCapability } from 'lib/admin-perms'
import { capabilities } from '@homevest/utils'
import { documents } from '@homevest/utils'
const { DOCUMENT_STATUSES } = documents
const { USER_DOC_VIEWER } = capabilities.CAPABILITY_TYPES

type ReviewButtonsPropTypes = {
  docId: string
  reexecuteQuery: Function
  setErrorMessage: Function
  setErrorModalIsOpen: Function
}

const ReviewButtons: FunctionComponent<ReviewButtonsPropTypes> = ({
  docId,
  reexecuteQuery,
  setErrorMessage
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState('')

  const [isDocReviewAllowed, setIsDocReviewAllowed] = useState(false)
  const admin = useSelector((store: StoreState) => store.admin)

  useEffect(() => {
    setIsDocReviewAllowed(hasCapability(admin, USER_DOC_VIEWER))
  }, [admin])

  const setDocStatus = async (note: string) => {
    try {
      await axios.post(`/admin/documents/${docId}/review`, {
        status,
        review_notes: note || undefined
      })
    } catch (err: any) {
      setErrorMessage(err.message)
      setErrorModalIsOpen(true)
    }

    setStatus('')
    reexecuteQuery()
  }

  return isDocReviewAllowed ? (
    <>
      <SC.ButtonContainer>
        <SC.ReviewButton
          onClick={() => {
            setStatus(DOCUMENT_STATUSES.VALID)
            setIsModalOpen(true)
          }}
        >
          <SC.GreenCheck />
        </SC.ReviewButton>
        <SC.ReviewButton
          onClick={() => {
            setStatus(DOCUMENT_STATUSES.INVALID)
            setIsModalOpen(true)
          }}
        >
          <SC.RedXIcon />
        </SC.ReviewButton>
      </SC.ButtonContainer>
      <InputModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        message={'Submit a document review note'}
        callback={setDocStatus}
      />
    </>
  ) : null
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
  setErrorModalIsOpen: (mode: boolean) => dispatch(setErrorModalIsOpen(mode))
})

export default connect(null, mapDispatchToProps)(ReviewButtons)
