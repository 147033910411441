import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'lib/axios'
import Cookies from 'universal-cookie'
import { webUrl, cookieOptions } from 'lib/config'
import { hasCapability } from 'lib/admin-perms'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState, TypedDispatch } from '../../../../store'

import { setErrorMessage, setErrorModalIsOpen } from 'state/modals/actions'

import * as SC from '../StyledComponents'

import { capabilities } from '@homevest/utils'
const { IMPERSONATOR } = capabilities.CAPABILITY_TYPES

type ImpersonateComponentPropTypes = {
  setErrorModalIsOpen: Function
  setErrorMessage: Function
}

const Impersonate: FunctionComponent<ImpersonateComponentPropTypes> = ({
  setErrorModalIsOpen,
  setErrorMessage
}) => {
  const { userId } = useParams<{ userId: string }>()
  const admin = useSelector((store: StoreState) => store.admin)

  const [isImpersonationAllowed, setIsImpersonationAllowed] = useState(false)

  useEffect(() => {
    setIsImpersonationAllowed(hasCapability(admin, IMPERSONATOR))
  }, [admin.id])

  const impersonateUser = async (userId: string, adminId: string) => {
    try {
      const { data: token } = await axios.get(
        `/admin/users/${userId}/sudo_token`
      )
      const cookies = new Cookies()

      // Set this cookie for a short time.
      // In production, security and domain are additionally set.
      cookies.set('hudsonCustomToken', token, {
        maxAge: 5,
        path: '/',
        ...cookieOptions
      })

      // Session cookies to track who is doing what for future web work.
      cookies.set('impersonatingAdminId', adminId, {
        expires: undefined,
        path: '/',
        ...cookieOptions
      })

      cookies.set('isImpersonating', true, {
        expires: undefined,
        path: '/',
        ...cookieOptions
      })

      // eslint-disable-next-line no-undef
      window.open(webUrl, '_blank')
    } catch (err: any) {
      setErrorMessage(err.message)
      setErrorModalIsOpen(true)
    }
  }

  return isImpersonationAllowed ? (
    <SC.DropDownMenuItem onClick={() => impersonateUser(userId, admin.id)}>
      Impersonate
    </SC.DropDownMenuItem>
  ) : null
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  setErrorModalIsOpen: (mode: boolean) => dispatch(setErrorModalIsOpen(mode)),
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message))
})

export default connect(null, mapDispatchToProps)(Impersonate)
