import React, { FunctionComponent } from 'react'
import _ from 'lodash'
import { types } from '@homevest/timelines'
import { Customer } from '../types'
import * as SC from '../../StyledComponents'
import DisplayPropertyDetails from './PropertyDetails'
import { mapContentToBadge } from '../../badges'

// todo: add startAt time to tour_events table and tour type
export const DisplayTour: FunctionComponent<{
  event: types.TourTimelineEvent
  expanded: boolean
  customer: Customer
  homes?: types.HomeDetails[]
}> = ({ event, expanded, customer, homes }) => {
  const homeDetails = homes?.find((h) => h.mlsListingId === event.mlsListingId)

  const expandedContent = (
    <>
      <DisplayPropertyDetails details={homeDetails} />
      <SC.Subsection>
        <SC.DescriptionText>
          {_.capitalize(event.actionType)} on {''}
          <SC.InlineBold>
            {new Date(event.eventTimeAt).toDateString()}
          </SC.InlineBold>
        </SC.DescriptionText>
      </SC.Subsection>
    </>
  )
  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>
          {customer.firstName} {customer.lastName}
        </SC.InlineBold>{' '}
        {event.actionType} a tour for{' '}
        <SC.InlineBold>{homeDetails?.address || `some property`}</SC.InlineBold>
      </SC.MessageText>
      {mapContentToBadge(event.actionType)}
      {expanded ? <SC.FadeIn>{expandedContent}</SC.FadeIn> : null}
    </div>
  )
}
