import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { capabilities } from '@homevest/utils'

import { BodyText, Button, HeaderText, Label } from 'components/Toolkit'

import { formatMoney } from 'lib/numbers'
import { hasCapability } from 'lib/admin-perms'
import { useSelector } from 'react-redux'

const { INCOME_REVIEWER, USER_PLAID_DOC_VIEWER } = capabilities.CAPABILITY_TYPES

function UserIncome({ user }) {
  const [isIncomeReviewer, isPlaidDocViewer] = useSelector((store) => [
    hasCapability(store.admin, INCOME_REVIEWER),
    hasCapability(store.admin, USER_PLAID_DOC_VIEWER)
  ])
  const { user_income: userIncome } = user
  const history = useHistory()
  const addIncome = () => {
    history.push(`/users/${user.id}/user-income-editor`)
  }

  const getOrigin = (origin) => {
    if (origin === 'plaid_income_verifications') {
      return 'Plaid'
    } else if (origin === 'documents') {
      return 'Internal Doc'
    } else {
      return 'Unknown'
    }
  }

  const userIncomeResource = userIncome?.user_income_resources?.[0]
  const isPlaidIncome =
    userIncomeResource?.resource_type === 'plaid_income_verifications'

  return (
    <div style={{ marginTop: '30px' }}>
      <div
        style={{
          margin: '30px 0 20px 0',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <HeaderText size='h3' style={{ margin: 0 }}>
          Income
        </HeaderText>
        {isIncomeReviewer && (
          <Button
            onClick={addIncome}
            style={{ marginLeft: '25px' }}
            size='s'
            isSecondary
          >
            + Add / Edit
          </Button>
        )}
        {isPlaidDocViewer && isPlaidIncome && (
          <Button
            isLink
            isSecondary
            style={{ marginLeft: '25px' }}
            size='s'
            onClick={() => {
              history.push(
                `/plaid-income-verification/${userIncomeResource.resource_id}/reviewer`
              )
            }}
          >
            Debug Plaid Info
          </Button>
        )}
      </div>

      {!userIncome && <BodyText>This user has no income! 🤑 </BodyText>}

      {userIncome && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}
          >
            <div style={{ width: '33%' }}>
              <Label>Projected Annual Income</Label>
              <BodyText style={{ marginTop: 0 }}>
                {formatMoney(userIncome.projected_annual_income, 2, '$')}
              </BodyText>
            </div>
            <div style={{ width: '33%' }}>
              <Label>YTD Gross Income</Label>
              <BodyText style={{ marginTop: 0 }}>
                {formatMoney(userIncome.ytd_gross_income, 2, '$')}
              </BodyText>
            </div>
            <div style={{ width: '33%' }}>
              <Label>YTD Net Income</Label>
              <BodyText style={{ marginTop: 0 }}>
                {formatMoney(userIncome.ytd_net_income, 2, '$')}
              </BodyText>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}
          >
            <div style={{ width: '33%' }}>
              <Label>Employee Name</Label>
              <BodyText style={{ marginTop: 0 }}>
                {userIncome.employee_name}
              </BodyText>
            </div>
            <div style={{ width: '33%' }}>
              <Label>Employer</Label>
              <BodyText style={{ marginTop: 0 }}>
                {userIncome.employer_name}
              </BodyText>
            </div>
            <div style={{ width: '33%' }}>
              <Label>Pay Frequency</Label>
              <BodyText style={{ marginTop: 0 }}>
                {userIncome.pay_frequency || 'N/A'}
              </BodyText>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}
          >
            <div style={{ width: '33%' }}>
              <Label>Origin</Label>
              <BodyText style={{ marginTop: 0 }}>
                {getOrigin(
                  get(userIncome, 'user_income_resources[0].resource_type')
                )}
              </BodyText>
            </div>
            <div style={{ width: '33%' }}>
              <Label>Status</Label>
              <BodyText style={{ marginTop: 0 }}>
                {userIncome.status || 'N/A'}
              </BodyText>
            </div>
            <div style={{ width: '33%' }}>
              <Label>Verified By</Label>
              <BodyText style={{ marginTop: 0 }}>
                {get(userIncome, 'verified_by_admin.name')}
              </BodyText>
            </div>
          </div>
          {userIncome.user_paystubs.map((paystub) => (
            <div key={paystub.id}>
              <HeaderText
                style={{ display: 'flex', justifyContent: 'space-between' }}
                size='h4'
              >
                Paystub {paystub.pay_period_start_date} to{' '}
                {paystub.pay_period_end_date}
              </HeaderText>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ width: '25%' }}>
                  <Label>Verification</Label>
                  <BodyText style={{ marginTop: 0 }}>
                    {paystub.verification_status}
                  </BodyText>
                </div>
                <div style={{ width: '25%' }}>
                  <Label>Verification Reason</Label>
                  <BodyText style={{ marginTop: 0 }}>
                    {paystub.verification_attributes}
                  </BodyText>
                </div>
                {paystub.income_breakdown.length === 0 && (
                  <div>
                    <div style={{ width: '25%' }}>
                      <Label>Gross Earnings</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {formatMoney(paystub.pay_period_gross_earnings, 2, '$')}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Check Amount</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {formatMoney(paystub.pay_period_check_amount, 2, '$')}
                      </BodyText>
                    </div>
                  </div>
                )}
              </div>
              {paystub.income_breakdown.length > 0 &&
                paystub.income_breakdown.map((breakdown) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px'
                    }}
                    key={breakdown.type}
                  >
                    <div style={{ width: '25%' }}>
                      <Label>Type</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {breakdown.type}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Rate</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {formatMoney(breakdown.rate, 2, '$')}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Hours</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {breakdown.hours}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Total</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {formatMoney(breakdown.total, 2, '$')}
                      </BodyText>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

UserIncome.propTypes = {
  user: PropTypes.object.isRequired
}

export default memo(UserIncome)
