"use strict";
const { AGREEMENT_TYPES } = require('./agreements');
exports.DOCUMENT_EXTERNAL_SOURCES = Object.freeze({
    GOOGLE_CLOUD: 'google_cloud',
    HELLOSIGN: 'hellosign'
});
exports.DOCUMENT_STATUSES = Object.freeze({
    INVALID: 'invalid',
    PENDING_REVIEW: 'pending_review',
    VALID: 'valid'
});
exports.DOCUMENT_TYPES = Object.freeze(Object.assign(Object.assign({}, AGREEMENT_TYPES), { BANK_STATEMENT: 'bank_statement', BID: 'bid', CREDIT_REPORT: 'credit_report', CRIMINAL_REPORT: 'criminal_report', EVICTION_REPORT: 'eviction_report', IDENTITY_DOCUMENT: 'identity_document', NOTE_ATTACHMENT: 'note_attachment', OFFER_LETTER: 'offer_letter', PAY_STUB: 'pay_stub', PLAID_INCOME_UPLOAD: 'plaid_income_upload', SUPPLEMENTAL_DOCUMENT: 'supplemental_document', TAX_RETURN: 'tax_return' }));
exports.FINANCIAL_DOCUMENT_TYPES = Object.freeze([
    exports.DOCUMENT_TYPES.BANK_STATEMENT,
    exports.DOCUMENT_TYPES.OFFER_LETTER,
    exports.DOCUMENT_TYPES.PAY_STUB,
    exports.DOCUMENT_TYPES.TAX_RETURN
]);
