import React, { FunctionComponent } from 'react'
import { useQuery } from 'urql'
import { Spinner } from 'reactstrap'

import * as SC from '../StyledComponents'
import { formatPreQualData as format } from '../formats'
import { REQUEST_POLICY } from 'constants/urql'
import { PrequalificationDetailsByUserIdDocument as queryDoc } from 'graphql/generated'
import CustomerPrequalDetail from './CustomerPrequalDetails'

type PrequalificationDetailsComponentPropTypes = {
  userId: string
}

const PrequalificationDetails: FunctionComponent<
  PrequalificationDetailsComponentPropTypes
> = ({ userId }) => {
  const [{ data, fetching, error }] = useQuery({
    query: queryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  if (fetching) {
    return <Spinner />
  }
  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  const prequalificationDetails = data?.user_prequalification_data[0]
  if (!prequalificationDetails) {
    return (
      <SC.MessageText>
        No pre-qualification details found for user
      </SC.MessageText>
    )
  }

  const {
    prequalification_status,
    has_coapplicant,
    has_cosigner,
    is_credit_qualified,
    is_income_qualified,
    is_ok_with_two_year_lease,
    is_section_eight
  } = prequalificationDetails

  return (
    <SC.SmallBoxContainer>
      <CustomerPrequalDetail
        header={'Pre-qualification status'}
        content={format(prequalification_status)}
      />
      <CustomerPrequalDetail
        header={'Is credit qualified'}
        content={format(is_credit_qualified)}
      />
      <CustomerPrequalDetail
        header={'Is income qualified'}
        content={format(is_income_qualified)}
      />
      <CustomerPrequalDetail
        header={'Has qualified cosigner'}
        content={format(has_cosigner)}
      />
      <CustomerPrequalDetail
        header={'Has qualified coapplicant'}
        content={format(has_coapplicant)}
      />
      <CustomerPrequalDetail
        header={'Is OK with 2 year lease'}
        content={format(is_ok_with_two_year_lease)}
      />
      <CustomerPrequalDetail
        header={'Is Section 8'}
        content={format(is_section_eight)}
      />
    </SC.SmallBoxContainer>
  )
}

export default PrequalificationDetails
