import axios from 'axios'

import upUpAxios from 'lib/axios'

import { graphqlConfig } from 'lib/config'

export const findMlsListingByFirestoreId = async (firestoreId) => {
  // This is dumb as hell but this is all temporary....
  const {
    data: { credentials }
  } = await upUpAxios.post('/hasura/auth')

  // 	"query": "query MlsListingAddressByFirestoreIdQuery { mls_listings (where: { firestore_id: { _eq: \"9f44a578-9350-4ec2-8cc9-85c3bc7df109\" }}) { display_line_1 }}"
  const response = await axios.post(
    graphqlConfig.serverURL,
    {
      query: `
        query MlsListingAddressByFirestoreIdQuery {
          mls_listings(where: {firestore_id: {_eq: "${firestoreId}"}}) {
            city
            display_line_1
            display_line_2
            id
            state
            zip
            firestore_id
          }
        }
      `
    },
    {
      headers: {
        Authorization: `Bearer ${credentials}`
      }
    }
  )

  return response.data
}

export const findMlsListingsByFirestoreIds = async (firestoreIds) => {
  // This is dumb as hell but this is all temporary....
  const {
    data: { credentials }
  } = await upUpAxios.post('/hasura/auth')

  const response = await axios.post(
    graphqlConfig.serverURL,
    {
      query: `
        query MlsListingAddressByFirestoreIdQuery {
          mls_listings(where: {firestore_id: {_in: [${firestoreIds
            .filter((i) => Boolean(i))
            .map((i) => '"' + i + '"')
            .join(',')}]}}) {
            baths_total
            beds
            city
            display_line_1
            display_line_2
            id
            internal_attributes {
              rent
            }
            photos(order_by: {created_at: asc}){
              url
            }
            region
            state
            zip
            firestore_id
          }
        }
      `
    },
    {
      headers: {
        Authorization: `Bearer ${credentials}`
      }
    }
  )

  return response.data
}
