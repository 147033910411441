import React, { FunctionComponent } from 'react'

interface ConstructionHistoryCardProps {
  propertyId: string
}

export const ConstructionHistoryCard: FunctionComponent<
  ConstructionHistoryCardProps
> = ({ propertyId }) => {
  console.log(propertyId)

  return <div>YURT: Construction in Progress</div>
}
