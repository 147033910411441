import React, { FunctionComponent, useState, useEffect } from 'react'
import { useQuery } from 'urql'
import { Spinner } from 'reactstrap'

import * as SC from '../StyledComponents'
import { REQUEST_POLICY } from 'constants/urql'
import {
  LeadGroupUsersByLeadGroupIdDocument as queryDoc,
  Lead_Group_Users
} from 'graphql/generated'
import { mapContentToBadge } from '../badges'
import { LeadGroupUser } from '../types'

type LeadGroupUsersComponentPropTypes = {
  leadGroupId: string
}

const LeadGroupUsers: FunctionComponent<LeadGroupUsersComponentPropTypes> = ({
  leadGroupId
}) => {
  const [leadGroupUsers, setLeadGroupUsers] = useState([])
  const [{ data, fetching, error }] = useQuery({
    query: queryDoc,
    variables: { id: leadGroupId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  useEffect(() => {
    let users = data?.lead_group_users
    if (!users) {
      return
    }
    // order by primary user first
    users = [
      ...users.filter((user: Lead_Group_Users) => user.type === 'primary'),
      ...users.filter((user: Lead_Group_Users) => user.type !== 'primary')
    ]
    setLeadGroupUsers(users)
  }, [data])

  if (fetching) {
    return <Spinner />
  }
  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  if (!leadGroupUsers.length) {
    return <SC.MessageText>No lead group users found</SC.MessageText>
  }

  return (
    <SC.SmallBoxContainer>
      {leadGroupUsers.map((lgu: LeadGroupUser) => (
        <SC.LeadGroupUserContainer key={lgu.id}>
          <SC.DetailsContainer>
            <SC.SubsectionTitleLink href={`${lgu.user_id}`}>
              {lgu.user?.first_name || ''} {lgu.user?.last_name || ''}
            </SC.SubsectionTitleLink>
            <SC.LightDetailText>
              {lgu.emails[0]?.contact_info || ''}
            </SC.LightDetailText>
          </SC.DetailsContainer>
          {mapContentToBadge(lgu.type)}
        </SC.LeadGroupUserContainer>
      ))}
    </SC.SmallBoxContainer>
  )
}

export default LeadGroupUsers
