import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { sellerAgents } from '@homevest/utils'
import * as moment from 'moment'
import { startCase } from 'lodash'
import { Link } from 'react-router-dom'

import SoftOfferSentButton from './SoftOfferSentButton'
import HardOfferSentButton from './HardOfferSentButton'
import PendingHomeOwnerApprovalButton from './PendingHomeOwnerApprovalButton'
import ApproveReject from './ApproveReject'
import ListingNote from './ListingNote'
import axios from '../../lib/axios'
import { formatMoney } from '../../lib/numbers'
import { isSellerAgentPrimary } from '../../lib/seller-agents'

const { SELLER_AGENT_PROPERTY_APPROVAL_STATUSES } = sellerAgents

export default function SellerAgentProperty({
  sellerAgentProperty: propsSellerAgentProperty
}) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [sellerAgentProperty, setSellerAgentProperty] = useState(
    propsSellerAgentProperty
  )

  useEffect(() => {
    setSellerAgentProperty(propsSellerAgentProperty)
  }, [propsSellerAgentProperty])

  const {
    id: sellerAgentPropertyId,
    property_id: propertyId,
    seller_agent_id: sellerAgentId,
    listing,
    note,
    is_soft_offer_sent: isSoftOfferSent,
    is_hard_offer_sent: isHardOfferSent,
    approval_status: approvalStatus,
    rejection_reason: rejectionReason
  } = sellerAgentProperty

  const isPrimaryAgent = isSellerAgentPrimary(
    sellerAgentId,
    sellerAgentProperty
  )

  const {
    internal_attributes: {
      internal_status: homevestStatus,
      rent,
      percent_over_comp: percentOverComp
    },
    display_line_1: displayLine1,
    display_line_2: displayLine2,
    list_price: listPrice,
    listing_date: listingDate
  } = listing

  const handleListingUpdate = async (update, sellerAgentPropertyId) => {
    setIsDisabled(true)

    let res

    // Joi validation doesn't like empty
    // strings, and fwiw it doesn't
    // make sense to store them,
    // so convert the empty note string
    // to null instead
    if (update.note === '') {
      update.note = null
    }

    if (sellerAgentPropertyId) {
      res = await axios.post(
        `/seller_agent_properties/${sellerAgentPropertyId}`,
        {
          called_from_hudson: true,
          ...update
        }
      )
    } else {
      res = await axios.post('/seller_agent_properties', {
        seller_agent_id: sellerAgentId,
        property_id: propertyId,
        ...update
      })
    }

    setSellerAgentProperty(res.data)

    setIsDisabled(false)
  }

  const showNeedHomeOwnerApprovalButton =
    !approvalStatus ||
    [
      SELLER_AGENT_PROPERTY_APPROVAL_STATUSES.PENDING_SELLER_AGENT_APPROVAL
    ].includes(approvalStatus)

  return (
    <div
      style={{
        paddingBottom: '1rem',
        paddingTop: '1rem',
        borderTop: '1px solid #dee2e6'
      }}
    >
      <Row>
        <Col lg={4}>
          <div>
            <b>
              <Link to={`/review/initial/${propertyId}`} target='_blank'>
                {displayLine1}, {displayLine2} <br />
              </Link>
            </b>
          </div>
          <div>
            <b>Approval Status: </b>
            {approvalStatus ||
              SELLER_AGENT_PROPERTY_APPROVAL_STATUSES.PENDING_SELLER_AGENT_APPROVAL}{' '}
          </div>
          <div>
            <b>Homevest Status:</b> {homevestStatus}
          </div>

          <div>
            <b>List Price:</b> ${formatMoney(listPrice, 0)}
          </div>
          {homevestStatus !== 'removed' && (
            <div>
              <b>Days on Market:</b> {moment().diff(listingDate, 'days')}
            </div>
          )}
          <div>
            <b>Rent:</b> ${formatMoney(rent, 0)}
          </div>
          <div>
            <b>Percent Over Comp: </b> {formatMoney(percentOverComp * 100, 2)}%
          </div>
          <div>
            <b>Is Primary Agent: </b> {Boolean(isPrimaryAgent).toString()}
          </div>
          <div>
            <b>Is Soft Offer Sent: </b> {Boolean(isSoftOfferSent).toString()}
          </div>
          <div>
            <b>Is Hard Offer Sent: </b> {Boolean(isHardOfferSent).toString()}
          </div>
        </Col>
        <Col lg={8}>
          {rejectionReason ? (
            <Row>
              <Col>
                <p>
                  <b>Rejection Reason: </b>
                  {startCase(rejectionReason).replace('Upandup', 'Up&Up')}
                </p>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              {showNeedHomeOwnerApprovalButton && (
                <PendingHomeOwnerApprovalButton
                  isDisabled={isDisabled}
                  onUpdate={handleListingUpdate}
                  sellerAgentPropertyId={sellerAgentPropertyId}
                />
              )}
              {!isSoftOfferSent && (
                <SoftOfferSentButton
                  isDisabled={isDisabled}
                  onUpdate={handleListingUpdate}
                  sellerAgentPropertyId={sellerAgentPropertyId}
                  style={
                    showNeedHomeOwnerApprovalButton
                      ? { marginLeft: '1rem' }
                      : {}
                  }
                />
              )}
              {!isHardOfferSent && (
                <HardOfferSentButton
                  isDisabled={isDisabled}
                  onUpdate={handleListingUpdate}
                  sellerAgentPropertyId={sellerAgentPropertyId}
                  style={
                    showNeedHomeOwnerApprovalButton || !isSoftOfferSent
                      ? { marginLeft: '1rem' }
                      : {}
                  }
                />
              )}
            </Col>
          </Row>
          <ApproveReject
            isDisabled={isDisabled}
            onUpdate={handleListingUpdate}
            sellerAgentPropertyId={sellerAgentPropertyId}
            style={{ marginTop: '1rem' }}
            showApprove={
              approvalStatus !==
                SELLER_AGENT_PROPERTY_APPROVAL_STATUSES.APPROVED &&
              approvalStatus !==
                SELLER_AGENT_PROPERTY_APPROVAL_STATUSES.REJECTED
            }
            showReject={
              approvalStatus !==
              SELLER_AGENT_PROPERTY_APPROVAL_STATUSES.REJECTED
            }
          />
          <ListingNote
            note={note || ''}
            onUpdate={handleListingUpdate}
            isDisabled={isDisabled}
            sellerAgentPropertyId={sellerAgentPropertyId}
            style={{ marginTop: '1rem' }}
          />
        </Col>
      </Row>
    </div>
  )
}

SellerAgentProperty.propTypes = {
  sellerAgentProperty: PropTypes.object.isRequired
}
