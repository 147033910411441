import React, { useState } from 'react'
import { useSubscription } from 'urql'
import { Spinner, Table } from 'reactstrap'
import { Comparators as Comp } from 'utils/hasura-comparators'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import { UpupUserListDocument as query } from 'graphql/generated'
import { HeaderText, Input, Checkbox } from 'components/Toolkit'
import { getContactInfo } from 'lib/users'

function UserEntries({ searchText = '', deleted = false }) {
  const [{ data }] = useSubscription({
    query,
    variables: {
      where: {
        user_searchable: {
          info: Comp.isLike(`%${searchText}%`)
        },
        deleted_at: deleted ? Comp.isNotNull() : Comp.isNull()
      }
    }
  })

  const users = data?.users || []

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>

        <tbody>
          {users.map(
            ({
              id,
              first_name: firstName,
              last_name: lastName,
              user_contact_details
              // email,
              // phone
            }) => {
              return (
                <tr key={id}>
                  <td>
                    <Link
                      to={{
                        pathname: `/users/${id}`
                      }}
                    >
                      {id}
                    </Link>
                  </td>
                  <td>
                    {firstName} {lastName}
                  </td>
                  <td>{getContactInfo('email', user_contact_details)}</td>
                  <td>{getContactInfo('phone', user_contact_details)}</td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </div>
  )
}

UserEntries.propTypes = {
  searchText: PropTypes.string.isRequired,
  deleted: PropTypes.bool
}

function Users() {
  const [searchText, setSearchText] = useState('')
  const [inputText, setInputText] = useState('')
  const [deleted, setDeleted] = useState(false)

  const setSearchTextDebounced = React.useMemo(
    () => debounce(setSearchText, 250, { trailing: true }),
    [setSearchText]
  )

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto  ' }}>
      <HeaderText size='h3'>User Search</HeaderText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px'
        }}
      >
        <div
          style={{
            width: '1000px',
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Input
            style={{ width: '600px' }}
            placeholder='Search...'
            value={inputText}
            onChange={(value) => {
              // We need two separate values so we can debounce to the subscription
              setInputText(value)
              setSearchTextDebounced(value)
            }}
          />
          <Checkbox
            label={'Show Deleted Users ? '}
            onChange={setDeleted}
            value={deleted}
          />
        </div>
      </div>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <UserEntries searchText={searchText} deleted={deleted} />
      </React.Suspense>
    </div>
  )
}

export default Users
