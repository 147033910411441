import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { BodyText, Label } from 'components/Toolkit'
import ReportLink from '../ReportLink'

export default function CreditReportDetails({ creditReport }) {
  const creditReportDocumentId = get(creditReport, 'document.id', null)

  if (!creditReport) {
    return <BodyText style={{ marginTop: '.5rem' }}>No report run</BodyText>
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <ReportLink
        documentId={creditReportDocumentId}
        reportName='Credit Report'
        style={{ flex: 1 }}
      />
      <div style={{ flex: 1 }}>
        <Label>OFAC Status Type</Label>
        <BodyText style={{ marginTop: 0 }}>
          {creditReport.ofac_status_type || 'N/A'}
        </BodyText>
      </div>
      <div style={{ flex: 1 }}>
        <Label>OFAC Status Result</Label>
        <BodyText style={{ marginTop: 0 }}>
          {creditReport.ofac_result || 'N/A'}
        </BodyText>
      </div>
    </div>
  )
}

CreditReportDetails.propTypes = {
  creditReport: PropTypes.object.isRequired
}
