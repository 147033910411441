import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { formatMoney } from 'lib/numbers'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import axios from 'lib/axios'
import get from 'lodash/get'
import { BodyText, HeaderText, Button, TextArea } from 'components/Toolkit'
import { RefundInfoType } from '../../types'

const IssuePaymentRefundForm: React.FC<{
  onSubmit: () => void
  refundInfo: RefundInfoType
}> = ({ onSubmit, refundInfo }) => {
  const [refundReason, setRefundReason] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const admin = useSelector<StoreState, any>((store) => store.admin)

  const handleSubmit = async () => {
    if (
      window.confirm(
        `Are you sure you want to refund this customer for: ${formatMoney(
          refundInfo.amount,
          2,
          '$'
        )}
        \nReversing is a pain in the ass ⚠.`
      )
    ) {
      try {
        setIsSaving(true)

        const payload = {
          refund_reason: refundReason
        }

        await axios.post(
          `/admin/payments/${refundInfo.paymentId}/refund`,
          payload
        )

        onSubmit()
      } catch (err) {
        const error = err as any
        const detailMessage = get(error, 'response.data.message')
        window.alert(detailMessage || error.message)
      } finally {
        setIsSaving(false)
      }
    }
  }

  return (
    <React.Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            padding: '6px',
            justifyContent: 'center',
            marginTop: '200px'
          }}
        >
          <Spinner color='primary' />
        </div>
      }
    >
      <HeaderText size='h4'>Team Member Submitting Refund</HeaderText>
      <BodyText>
        {admin.first_name} {admin.last_name}
      </BodyText>
      <br />
      <HeaderText size='h4'>Refund Address</HeaderText>
      <BodyText>{refundInfo.address}</BodyText>
      <br />
      <HeaderText size='h4'>Refund Amount</HeaderText>
      <BodyText>{formatMoney(refundInfo.amount, 2, '$')}</BodyText>
      <br />
      <HeaderText size='h4'>Date of Payment Being Refunded</HeaderText>
      <BodyText>{refundInfo.date}</BodyText>
      <br />
      <HeaderText size='h4'>Include Refund Reason</HeaderText>
      <TextArea
        label='Refund Reason'
        type='textarea'
        rows={5}
        onChange={setRefundReason}
        value={refundReason}
        placeholder='Enter the reason for giving a refund'
        disabled={isSaving}
        hasError={!refundReason}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          isSecondary={false}
          style={{
            marginTop: '2rem',
            marginBottom: '2rem'
          }}
          onClick={handleSubmit}
          disabled={isSaving || !refundReason}
        >
          Issue Payment Refund
        </Button>
      </div>
    </React.Suspense>
  )
}

export default IssuePaymentRefundForm
