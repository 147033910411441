import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import pluralize from 'pluralize'

import { Label } from 'components/Toolkit'
import LeadGroupReminders from './LeadGroupReminders'

const DOCUMENT_OPTIONS = [
  {
    label: 'Identity Document',
    value: 'Identity Document'
  },
  {
    label: 'Pay Stub(s)',
    value: 'Pay Stub(s)'
  },
  {
    label: 'Bank Statement',
    value: 'Bank Statement'
  },
  {
    label: 'Tax Return',
    value: 'Tax Return'
  },
  {
    label: 'Offer Letter',
    value: 'Offer Letter'
  }
]

export default function DocumentReminders({
  canSend,
  disabled,
  onSend,
  rentalApplication
}) {
  const [documents, setDocuments] = useState([])
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (documents.length) {
      setMessage(
        `Before your application can move forward, we still need you to upload the following ${pluralize(
          'document',
          documents.length
        )}:

${documents.join('\n')}

Warmly,
Your Up&Up Leasing Concierge`
      )
    } else {
      setMessage('')
    }
  }, [documents])

  const setFormattedDocuments = (rawDocuments) => {
    const newDocuments = (rawDocuments || []).map((doc) => doc.value)
    setDocuments(newDocuments)
  }

  return (
    <div>
      <Label>Documents</Label>
      <Select
        onChange={setFormattedDocuments}
        options={DOCUMENT_OPTIONS}
        isMulti
        disabled={disabled}
      />
      <LeadGroupReminders
        canSend={canSend}
        disabled={disabled || !documents.length}
        messageTemplate={message}
        onSend={onSend}
        rentalApplication={rentalApplication}
      />
    </div>
  )
}

DocumentReminders.propTypes = {
  canSend: PropTypes.bool,
  disabled: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
  rentalApplication: PropTypes.object.isRequired
}
