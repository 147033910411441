import { RECEIVED_FIREBASE_USER, RECEIVED_USER } from './actions'
import { append } from '../../lib/map'

export default function reducer(
  state = {
    firebaseUsers: new Map(),
    users: new Map()
  },
  action
) {
  switch (action.type) {
    case RECEIVED_FIREBASE_USER:
      return {
        ...state,
        firebaseUsers: append(
          state.firebaseUsers,
          action.firebaseUser.id,
          action.firebaseUser
        )
      }
    case RECEIVED_USER: {
      return {
        ...state,
        users: append(state.users, action.user.id, action.user)
      }
    }

    default:
      return state
  }
}
