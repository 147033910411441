import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { ErrorText, HeaderText, BodyText } from 'components/Toolkit'

function Lease({ completedAgreement, documentUrls }) {
  if (!completedAgreement.document) {
    return (
      <ErrorText>
        The completed agreement currently has no document. Engineering must
        backfill.
      </ErrorText>
    )
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '20px',
        marginTop: '20px',
        backgroundColor: '#fff'
      }}
    >
      <HeaderText size='h3'>Completed Agreement</HeaderText>
      <BodyText>
        <a
          rel='noopener noreferrer'
          target='_blank'
          href={documentUrls[completedAgreement.document.id]}
        >
          Lease
        </a>
      </BodyText>
    </div>
  )
}

Lease.propTypes = {
  completedAgreement: PropTypes.object.isRequired,
  documentUrls: PropTypes.object.isRequired
}

export default memo(Lease)
