import React from 'react'
import PropTypes from 'prop-types'

const SuccessErrorMessage = ({ isError, message }) => {
  return <p style={{ color: isError ? 'red' : 'green' }}>{message}</p>
}

SuccessErrorMessage.propTypes = {
  isError: PropTypes.bool.isRequired,
  message: PropTypes.string
}

export default SuccessErrorMessage
