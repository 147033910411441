// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React, { useEffect, useState } from 'react'
import { Table, Spinner } from 'reactstrap'
import cloneDeep from 'lodash/cloneDeep'

const ASC_SORT = 1

const sortItems = (
  items: any[],
  sortFunc: (firstItem: any, secondItem: any) => number
): any[] => {
  items = cloneDeep(items)

  items.sort(sortFunc)

  return items
}

type SortFunction = (a: any, b: any) => number

interface Column {
  formatter?: (item: any) => any
  isDefault?: boolean
  isSortable?: boolean
  key: string
  name: string
  sort?: SortFunction
}

interface SortableTableProps {
  columns: Column[]
  items: any[]
}

export default function SortableTable(props: SortableTableProps) {
  const { columns, items: propsItems } = props

  const [sortDirection, setSortDirection] = useState(ASC_SORT)
  const [sortColumn, setSortColumn] = useState<Column | undefined>(
    columns.find((column) => column.isDefault)
  )
  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    if (sortColumn && sortColumn.sort) {
      setItems(
        sortItems(
          propsItems,
          (a: any, b: any) => sortColumn.sort(a, b) * sortDirection
        )
      )
    }
  }, [propsItems, columns, sortColumn, sortDirection])

  const updateSortColumn = (column: Column) => {
    if (sortColumn.key === column.key) {
      setSortDirection(sortDirection * -1)
    } else {
      setSortDirection(ASC_SORT)
      setSortColumn(column)
    }
  }

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key} onClick={() => updateSortColumn(column)}>
                {column.name}
                {sortColumn?.key === column.key &&
                  (sortDirection === ASC_SORT ? '↑' : '↓')}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {items.map((item) => (
            <tr key={item.id} style={item.style || {}}>
              {columns.map(({ formatter, key }) => {
                if (!formatter) {
                  formatter = (x) => x[key]
                }
                return <td key={key}>{formatter(item)}</td>
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {!items.length ? (
        <div
          style={{
            margin: '4rem auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Spinner color='primary' style={{ width: '4rem', height: '4rem' }} />
        </div>
      ) : null}
    </div>
  )
}
