// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import PropTypes from 'prop-types'
import {
  UpupAdminsWithCapabilitiesQuery,
  UpupCapabilitiesWithAdminQuery
} from 'graphql/generated'
import { Modal, BodyText, HeaderText } from 'components/Toolkit'
import CapabilityToggle from './CapabilityToggle'

type Props = {
  isOpen: boolean
  onClose: () => void
  admin?: UpupAdminsWithCapabilitiesQuery['admins'][number]
  capabilities: UpupCapabilitiesWithAdminQuery['capabilities']
}

const EditAdminModal: React.FC<Props> = ({
  isOpen,
  onClose,
  admin,
  capabilities
}) => {
  const adminCapToPkMap = React.useMemo(
    () =>
      admin?.admin_capabilities.reduce(
        (acc, adminCap) => ({ ...acc, [adminCap.capability.id]: adminCap.id }),
        {}
      ) ?? {},
    [admin]
  ) as { [id: string]: string }

  return isOpen ? (
    <Modal hasCloseButton close={onClose} width='66vw'>
      <HeaderText size='h3'>
        {admin?.first_name} {admin?.last_name}
      </HeaderText>
      <BodyText size='s' style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
        Changes are applied immediately.
      </BodyText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridAutoColumns: 'none',
          maxHeight: '66vh',
          overflowY: 'auto'
        }}
      >
        <BodyText>Capability</BodyText>
        <BodyText style={{ textAlign: 'center' }}>Has Access</BodyText>
        <hr
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 'end',
            width: '100%',
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
          }}
        />
        {capabilities.map((cap) => (
          <React.Fragment key={cap.id}>
            <BodyText weight='bold' style={{ gridColumn: 1 }}>
              {cap.name}
            </BodyText>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CapabilityToggle
                value={typeof adminCapToPkMap[cap.id] === 'string'}
                adminId={admin?.id}
                capId={cap.id}
                adminCapPk={adminCapToPkMap[cap.id]}
              />
            </div>
            <div
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 'end',
                width: '100%',
                marginBottom: '0.5rem'
              }}
            >
              <BodyText size='s'>{cap.description}</BodyText>
            </div>
          </React.Fragment>
        ))}
      </div>
    </Modal>
  ) : null
}

EditAdminModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  admin: PropTypes.shape({
    admin_capabilities: PropTypes.array.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }),
  capabilities: PropTypes.array.isRequired
}

export default EditAdminModal
