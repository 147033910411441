"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatMoney = void 0;
function formatMoney(number, decimals = 2, currencyPrepend = '') {
    if (typeof number !== 'number') {
        return currencyPrepend;
    }
    else if (number === 0) {
        return currencyPrepend + '0';
    }
    else {
        return (currencyPrepend +
            Number(number).toLocaleString(undefined, {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            }));
    }
}
exports.formatMoney = formatMoney;
