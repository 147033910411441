import React from 'react'
import * as SC from './StyledComponents'

export const NACircle = () => (
  <SC.NAText>
    <SC.SmallQuestionCircle />
    N/A
  </SC.NAText>
)

export const YesCircle = () => (
  <SC.YesText>
    <SC.SmallCheckCircle />
    Yes
  </SC.YesText>
)

export const NoCircle = () => (
  <SC.NoText>
    <SC.SmallXCircle />
    No
  </SC.NoText>
)

export const AddButton = (
  contactType: string,
  clickEvent: React.MouseEventHandler<HTMLButtonElement>
) => (
  <SC.AddText onClick={clickEvent}>
    <SC.AddIcon />
    Add {contactType}
  </SC.AddText>
)
