import React, { FunctionComponent, useEffect, useState } from 'react'

import axios from 'lib/axios'
import { useQuery } from 'urql'
import { REQUEST_POLICY } from 'constants/urql'
import { PersonalDetailsByUserIdDocument as queryDoc } from 'graphql/generated'

import * as SC from '../StyledComponents'
import { formatPersonalDetails as format } from '../formats'
import { Spinner } from 'reactstrap'

import { StoreState } from 'store'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { setPersonalDetails } from 'state/personal-details/actions'
import { initialState } from 'state/personal-details/reducer'
import { mapCategoryToProps } from './detailProps'
import { UserContactDetailsWithValidation } from '../types'

import MultiDetailRow from './MultiDetailRow'
import SingleDetailRow from './SingleDetailRow'

type PersonalDetailsComponentPropTypes = {
  userId: string
  firstName: string
  lastName: string
  phones: UserContactDetailsWithValidation[]
  emails: UserContactDetailsWithValidation[]
  inEditMode: boolean
  triggerReexecute: boolean
  setProfileName: Function
  setPersonalDetails: Function
}

const PersonalDetails: FunctionComponent<PersonalDetailsComponentPropTypes> = ({
  userId,
  firstName,
  lastName,
  phones,
  emails,
  inEditMode,
  triggerReexecute,
  setProfileName,
  setPersonalDetails
}) => {
  const [funnelStatus, setFunnelStatus] = useState('')
  const [creditScore, setCreditScore] = useState('')

  // fetch personal details from graphql and initialize redux store
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: queryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  useEffect(() => {
    // requery if edit canceled
    if (triggerReexecute) {
      reexecuteQuery()
    }
  }, [triggerReexecute])

  useEffect(() => {
    const user = data?.users[0]
    if (!user) return

    const { first_name, last_name } = user

    const phones = user.phones.map(
      (number: UserContactDetailsWithValidation) => ({
        ...number,
        isValid: true
      })
    )

    const emails = user.emails.map(
      (emailAddress: UserContactDetailsWithValidation) => ({
        ...emailAddress,
        isValid: true
      })
    )

    setPersonalDetails({
      first_name,
      last_name,
      phones,
      emails
    })
    setFunnelStatus(user.user_funnel_status?.lead_status)
    setCreditScore(user.credit_reports[0]?.score)
    setProfileName({ firstName: first_name, lastName: last_name })
  }, [data])

  // fetch market from api endpoint
  const [market, setMarket] = useState('N/A')
  useEffect(() => {
    try {
      const fetchMarket = async (userId: string) => {
        const { data } = await axios.get(`/360/users/${userId}/market`)
        setMarket(data.market)
      }

      fetchMarket(userId)
    } catch (err) {
      throw err
    }
  }, [userId])

  // early return scenarios
  if (fetching) {
    return <Spinner style={{ margin: '1.5rem' }} />
  }

  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  const user = data?.users[0]
  if (!user) {
    return <SingleDetailRow header={'No user found'} content={''} />
  }

  return (
    <SC.DetailsContainer>
      <SingleDetailRow
        header={'First name'}
        content={firstName}
        editable={inEditMode}
        {...mapCategoryToProps.firstName}
      />
      <SingleDetailRow
        header={'Last name'}
        content={lastName}
        editable={inEditMode}
        {...mapCategoryToProps.lastName}
      />
      <MultiDetailRow
        header={'Phone number'}
        content={phones}
        editable={inEditMode}
        {...mapCategoryToProps.phone}
      />
      <MultiDetailRow
        header={'Email address'}
        content={emails}
        editable={inEditMode}
        {...mapCategoryToProps.email}
      />
      <SingleDetailRow header={'Credit score'} content={format(creditScore)} />
      <SingleDetailRow
        header={'Funnel status'}
        content={format(funnelStatus)}
      />
      <SingleDetailRow header={'Market'} content={format(market)} />
    </SC.DetailsContainer>
  )
}

const mapStateToProps = (state: StoreState) => ({
  inEditMode: state.personalDetails.editMode,
  triggerReexecute: state.refreshComponents.reexecutePersonalDetails,
  firstName: state.personalDetails.first_name,
  lastName: state.personalDetails.last_name,
  phones: state.personalDetails.phones,
  emails: state.personalDetails.emails
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPersonalDetails: (details: typeof initialState) =>
    dispatch(setPersonalDetails(details))
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)
