import {
  refreshTableData,
  closeModal,
  startModalAction,
  endModalAction
} from 'state/dispatch/actions'
import { apiError } from 'lib/error-handling'
import axios from 'lib/axios'

export const SET_LEAD_GROUPS = 'LEAD_GROUP/SET_LEAD_GROUPS'

export const setLeadGroups = (leadGroups) => ({
  type: SET_LEAD_GROUPS,
  payload: leadGroups
})

export const fetchLeadGroups = () => {
  return async (dispatch) => {
    const resp = await axios.get('/lead_groups')
    dispatch(setLeadGroups(resp.data))
  }
}

// addUsers is an array of tuples { user_id, type }
// where type is one of LEAD_GROUP_USER_TYPES
export const updateLeadGroup = (
  leadGroupId,
  leadGroupUsersToAdd,
  leadGroupUserIdsToRemove
) => {
  return async (dispatch) => {
    dispatch(startModalAction())

    try {
      await Promise.all(
        leadGroupUsersToAdd
          .map(({ user_id: userId, type }) =>
            axios.post('/admin/lead_group_users', {
              lead_group_id: leadGroupId,
              user_id: userId,
              type: type
            })
          )
          .concat(
            leadGroupUserIdsToRemove.map((id) =>
              axios.post(`/admin/lead_group_users/${id}/deactivate`)
            )
          )
      )

      dispatch(refreshTableData())
      dispatch(closeModal())
    } catch (e) {
      apiError(e)
    }

    dispatch(endModalAction())
  }
}

// users is an array of tuples { user_id, type }
// where type is one of LEAD_GROUP_USER_TYPES
export const createLeadGroup = (users) => {
  return async (dispatch) => {
    dispatch(startModalAction())

    try {
      await axios.post('/lead_groups', users)
      dispatch(refreshTableData())
      dispatch(closeModal())
    } catch (e) {
      apiError(e)
    }

    dispatch(endModalAction())
  }
}
