import React from 'react'
import { useQuery } from 'urql'
import PropTypes from 'prop-types'

import { Upup_LedgerAccountsDocument as query } from 'graphql/generated'
import { Dropdown } from 'components/Toolkit'

LedgerAccountsDropdown.propTypes = {
  creditType: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object
}

export default function LedgerAccountsDropdown({
  disabled,
  onSelect,
  value
}: {
  disabled: boolean
  onSelect: any
  value: string
}) {
  const [{ data }] = useQuery({
    query
  })

  const ledgerAccounts: any[] = data?.ledger_accounts || []

  const ledgerAccountOptions = ledgerAccounts
    .map((la) => ({
      label: la.name,
      value: la.id
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onSelectLedgerAccount = (id: string) => {
    onSelect(id)
  }

  return (
    <Dropdown
      disabled={disabled}
      label='Offsetting Ledger Account'
      placeholder='Select an offsetting ledger account'
      showSelectText
      onChange={onSelectLedgerAccount}
      options={ledgerAccountOptions}
      value={value}
    />
  )
}
