import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import styled from 'styled-components'

import axios from '../../lib/axios'
import IcMemoModal from './IcMemoModal'

const StyledButton = styled(Button)`
  margin-top: 25px;
`

const generateIcMemo = async (propertyId) => {
  try {
    const response = await axios.post('/admin/bids/generate_ic_memo', {
      property_id: propertyId
    })

    return response.data
  } catch {
    // silly temporary workaround for the 30s Heroku timeout
    // second call to the same endpoint will run significantly
    // faster bc the HC API calls are cached
    const response = await axios.post('/admin/bids/generateICMemo', {
      property_id: propertyId
    })

    return response.data
  }
}

const IcMemoButton = ({ propertyId }) => {
  // modal config
  const [modal, setModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [icMemoSheetData, setIcMemoSheetData] = useState({})

  const toggle = () => {
    setModal(!modal)
  }

  const clickHandler = async () => {
    toggle()
    setLoading(true)
    setError(false)

    try {
      const response = await generateIcMemo(propertyId)

      setIcMemoSheetData({ ...response })
    } catch (error) {
      setError(true)
      const newErrorMessage = `${error.error} (${error.statusCode}): ${error.message}`
      setErrorMessage(newErrorMessage)
    } finally {
      setLoading(false)
      setModal(true)
    }
  }

  return (
    <>
      <StyledButton color='success' onClick={clickHandler}>
        Generate IC Memo
      </StyledButton>
      <IcMemoModal
        modal={modal}
        toggle={toggle}
        isLoading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
        icMemoSheetData={icMemoSheetData}
      />
    </>
  )
}

IcMemoButton.propTypes = {
  propertyId: PropTypes.string
}

export default IcMemoButton
