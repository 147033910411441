import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

export default function AgentUpdateButton({
  isDisabled,
  onUpdate,
  text,
  color
}) {
  return (
    <Button
      type='button'
      color={color}
      size='sm'
      onClick={onUpdate}
      disabled={isDisabled}
      style={{ marginRight: '1rem' }}
    >
      {text}
    </Button>
  )
}

AgentUpdateButton.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  text: PropTypes.string,
  color: PropTypes.string
}

AgentUpdateButton.defaultProps = {
  isDisabled: false,
  onUpdate: () => null,
  text: 'Update',
  color: 'primary'
}
