import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { documents } from '@homevest/utils'

import axios from 'lib/axios'
import { ErrorText } from 'components/Toolkit'

import UserDocuments from 'components/Documents/user-documents'
import AddDocumentModal from 'components/Documents/add-document-modal'

const { DOCUMENT_STATUSES } = documents

const StyledContainer = styled.div`
  padding: 20px;
  height: calc(100vh - 248px);
  width: 100%;
`

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
`

const PENDING_OR_VALID_DOCUMENT = [
  DOCUMENT_STATUSES.VALID,
  DOCUMENT_STATUSES.PENDING_REVIEW
]

async function loadData(
  setDocuments,
  setDocumentUrls,
  setLoadingError,
  userId
) {
  try {
    const { data } = await axios.get(`/admin/documents/${userId}`)
    const documents = data.filter((doc) =>
      PENDING_OR_VALID_DOCUMENT.includes(doc.status)
    )

    const documentUrls = await Promise.all(
      documents.map(({ id }) =>
        axios.get(`/admin/documents/${id}/url`).then((resp) => resp.data.url)
      )
    )
    const documentUrlsMapped = documents.reduce(
      (acc, { id }, idx) => ({ ...acc, [id]: documentUrls[idx] }),
      {}
    )

    setDocumentUrls(documentUrlsMapped)
    setDocuments(documents)
  } catch (err) {
    setLoadingError(err.message)
  }
}

function Documents({ userId }) {
  const [isAddingDocument, setIsAddingDocument] = useState(false)
  const [documents, setDocuments] = useState(null)
  const [documentUrls, setDocumentUrls] = useState({})
  const [loadingError, setLoadingError] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  async function setDocumentReviewStatus(docId, status, notes) {
    setIsSaving(true)
    await axios.post(`/admin/documents/${docId}/review`, {
      status,
      review_notes: notes
    })
    loadData(setDocuments, setDocumentUrls, setLoadingError, userId)
    setIsSaving(false)
  }

  async function updateDocumentReviewStatus(docId, notes) {
    setIsSaving(true)
    await axios.put(`/admin/documents/${docId}`, {
      review_notes: notes
    })
    loadData(setDocuments, setDocumentUrls, setLoadingError, userId)
    setIsSaving(false)
  }

  useEffect(() => {
    if (userId) {
      loadData(setDocuments, setDocumentUrls, setLoadingError, userId)
    }
  }, [userId])

  if (loadingError) {
    return (
      <StyledContainer>
        <ErrorText>{loadingError}, please contact Engineering.</ErrorText>
      </StyledContainer>
    )
  }

  if (!documents) {
    return (
      <StyledLoading>
        <span>Loading...</span>
      </StyledLoading>
    )
  }

  return (
    <StyledContainer>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <UserDocuments
            documentUrls={documentUrls}
            documents={documents}
            isSaving={isSaving}
            setDocumentReviewStatus={setDocumentReviewStatus}
            updateDocumentReviewStatus={updateDocumentReviewStatus}
            addDocument={() => {
              setIsAddingDocument(true)
            }}
          />
        </div>
      </div>
      {isAddingDocument && (
        <AddDocumentModal
          userId={userId}
          onFinish={() => {
            loadData(setDocuments, setDocumentUrls, setLoadingError, userId)
          }}
          close={() => setIsAddingDocument(false)}
        />
      )}
    </StyledContainer>
  )
}

Documents.propTypes = {
  userId: PropTypes.string.isRequired
}

export default Documents
