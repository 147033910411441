import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'

export default function SellerAgentSearchDetails({ agent, onSelect }) {
  return (
    <Row
      style={{
        marginLeft: 0,
        marginTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid black'
      }}
    >
      <Col md={6}>
        <div>
          <b>Name: </b>
          {agent.first_name} {agent.last_name}
        </div>
        <div>
          <b>Phone: </b>
          {agent.phone}
        </div>
        {agent.email && (
          <div>
            <b>Email: </b>
            {agent.email}
          </div>
        )}
      </Col>
      <Col md={6}>
        <Button onClick={() => onSelect(agent)}>View Details</Button>
      </Col>
    </Row>
  )
}

SellerAgentSearchDetails.propTypes = {
  agent: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
}
