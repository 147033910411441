import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, TextArea } from 'components/Toolkit'

export default function LeadGroupReminders({
  disabled,
  messageTemplate,
  onSend,
  rentalApplication
}) {
  const [message, setMessage] = useState('')
  const {
    mls_listing: { display_line_1: displayLine1 }
  } = rentalApplication

  useEffect(() => {
    setMessage(
      `I'm reaching out regarding your application for ${displayLine1}. ${messageTemplate}`
    )
  }, [displayLine1, messageTemplate])

  const canSend = message && !disabled

  const sendReminder = async () => {
    if (!canSend) {
      return
    }

    onSend(message)
  }

  return (
    <div style={{ marginTop: '2rem' }}>
      <TextArea
        label='Hi {FIRST_NAME},'
        hasLabel={false}
        onChange={setMessage}
        value={message}
        disabled={disabled}
        rows={10}
        noMargin
      />
      <Button
        onClick={sendReminder}
        style={{ width: '100%', marginTop: '2rem' }}
        disabled={disabled || !canSend}
      >
        Send reminder
      </Button>
    </div>
  )
}

LeadGroupReminders.propTypes = {
  disabled: PropTypes.bool,
  messageTemplate: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  rentalApplication: PropTypes.object.isRequired
}
