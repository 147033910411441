"use strict";
const { SELLER_AGENT_REJECTION_REASONS } = require('./seller-agents');
exports.TOUR_STATUSES = {
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    CONFIRMED: 'confirmed',
    PENDING: 'pending'
};
exports.TOUR_CANCELATION_REASONS = {
    ALL_HOMES_UNAVAILABLE: 'all_homes_unavailable',
    CUSTOMER_CANCELED: 'customer_canceled',
    CUSTOMER_DID_NOT_CONFIRM: 'customer_did_not_confirm',
    CUSTOMER_NO_SHOW: 'customer_no_show',
    CUSTOMER_RESCHEDULED: 'customer_rescheduled',
    CUSTOMER_UNQUALIFIED: 'customer_unqualified',
    SELLER_AGENT_CANCELED: 'seller_agent_canceled',
    SELLER_AGENT_NO_SHOW: 'seller_agent_no_show',
    UNKNOWN: 'unknown',
    UP_AND_UP_AGENT_CANCELED: 'upandup_agent_canceled',
    UP_AND_UP_AGENT_NO_SHOW: 'upandup_agent_no_show',
    UP_AND_UP_AGENT_UNAVAILABLE: 'upandup_agent_unavailable'
};
exports.TOUR_TYPES = {
    IN_PERSON: 'in_person',
    OPEN_HOUSE: 'open_house',
    RECORDING: 'recording',
    SAFE_MODE: 'safe_mode',
    SELF_SHOW: 'self_show',
    VIDEO_CALL: 'video_call',
    VIRTUAL_OPEN_HOUSE: 'virtual_open_house'
};
exports.SELF_SHOWING_TOUR_TYPES = [
    exports.TOUR_TYPES.SELF_SHOW,
    exports.TOUR_TYPES.SAFE_MODE
];
exports.TOUR_PROPERTY_STATUSES = {
    CANCELED: 'canceled',
    CONFIRMED: 'confirmed',
    PENDING: 'pending'
};
exports.TOUR_PROPERTY_CANCELATION_REASONS = Object.assign(Object.assign({}, SELLER_AGENT_REJECTION_REASONS), { CUSTOMER_CANCELED: 'customer_canceled', REMOVED_FROM_MLS: 'removed_from_mls', SHOWINGTIME_UNAVAILABLE: 'showingtime_unavailable', TOUR_CANCELED: 'tour_canceled', UNKNOWN: 'unknown', UP_AND_UP_AGENT_DECLINED: 'upandup_agent_declined' });
exports.TOUR_UPDATED_BY_TYPES = {
    ADMIN: 'admin',
    AGENT: 'agent',
    AUTO: 'auto',
    USER: 'user'
};
