import React from 'react'
import * as SC from './StyledComponents'
import { startCase } from 'lodash'

type BadgeColor = 'yellow' | 'green' | 'red' | 'gray' | 'blue'

export const mapStatusToBadgeColor: { [key: string]: BadgeColor } = {
  // prequalification statuses
  needs_cosigner: 'yellow',
  prequalified: 'green',
  unqualified: 'red',
  // funnel statuses
  applied: 'blue',
  needs_standard_matching: 'yellow',
  incomplete_tour: 'yellow',
  needs_application_intro: 'yellow',
  needs_tour_follow_up: 'yellow',
  needs_priority_matching: 'yellow',
  // header statuses
  needs_prequalification: 'yellow', // true if user does not have corresponding row in user_prequalification_data
  renter: 'green', // true if user has active rental in rental_users table
  application_intro: 'yellow', // might not be used
  canceled: 'red',
  deleted: 'red',
  // lead group user types (gray if not listed)
  primary: 'green',
  cosigner: 'blue',
  // calls
  answered: 'green',
  'no-answer': 'red',
  'vm-answer': 'yellow',
  'vm-left': 'yellow',
  busy: 'red',
  blocked: 'red',
  error: 'red',
  abandoned: 'red',
  unknown: 'gray',
  // rental app statuses
  created: 'yellow',
  underwritten: 'yellow',
  completed: 'green',
  reviewed: 'green',
  review_approved: 'blue',
  review_unapproved: 'red',
  complete: 'green',
  pending_application_review: 'yellow',
  pending_rental_agreement_creation: 'yellow',
  pending_customer_information: 'yellow',
  // documents
  pending_review: 'yellow',
  valid: 'green',
  invalid: 'red'
}

export const mapContentToBadge = (status: string, dot = true) => {
  const badgeColor = mapStatusToBadgeColor[status]

  status = startCase(status)
  switch (badgeColor) {
    case 'yellow':
      return yellowBadge(status, dot)
    case 'green':
      return greenBadge(status, dot)
    case 'red':
      return redBadge(status, dot)
    case 'blue':
      return blueBadge(status, dot)
    case 'gray':
      return grayBadge(status, dot)
    default:
      return grayBadge(status, dot)
  }
}

const blueBadge = (content: string, dot = true) => (
  <SC.BlueBadge>
    {dot ? <SC.BlueDot /> : ' '}
    <SC.badgeContent dot={dot}>{content}</SC.badgeContent>
  </SC.BlueBadge>
)

const grayBadge = (content: string, dot = true) => (
  <SC.GrayBadge>
    {dot ? <SC.GrayDot /> : ' '}
    <SC.badgeContent dot={dot}>{content}</SC.badgeContent>
  </SC.GrayBadge>
)

const greenBadge = (content: string, dot = true) => (
  <SC.GreenBadge>
    {dot ? <SC.GreenDot /> : null}
    <SC.badgeContent dot={dot}>{content}</SC.badgeContent>
  </SC.GreenBadge>
)

const redBadge = (content: string, dot = true) => (
  <SC.RedBadge>
    {dot ? <SC.RedDot /> : null}
    <SC.badgeContent dot={dot}>{content}</SC.badgeContent>
  </SC.RedBadge>
)

const yellowBadge = (content: string, dot = true) => (
  <SC.YellowBadge>
    {dot ? <SC.YellowDot /> : null}
    <SC.badgeContent dot={dot}>{content}</SC.badgeContent>
  </SC.YellowBadge>
)
