import { DropdownToggle } from 'reactstrap'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

import COLORS from 'components/Toolkit/colors'
import { Button } from 'components/Toolkit'

function NotificationDropdownToggle({ count, text }) {
  return (
    <DropdownToggle>
      <div style={{ position: 'relative' }}>
        {text}
        <div
          style={{
            position: 'absolute',
            right: '-25px',
            bottom: '15px'
          }}
        >
          {Boolean(count) && (
            <Button
              size='vvvs'
              isLink
              href='/tasks'
              style={{
                zIndex: '999',
                background: COLORS.ACCENTS.SALMON
              }}
            >
              {count}
            </Button>
          )}
        </div>
      </div>
    </DropdownToggle>
  )
}

NotificationDropdownToggle.propTypes = {
  count: PropTypes.number,
  text: PropTypes.string
}

export default memo(NotificationDropdownToggle)
