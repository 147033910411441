import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import FormHeader from '../components/AddAgent/FormHeader'
import AddAgentForm from '../components/AddAgent/AddAgentForm'

class AddAgentScreen extends React.Component {
  render() {
    return (
      <div>
        <Container>
          <Container>
            <Row>
              <FormHeader />
            </Row>
            <Row>
              <Col>
                <AddAgentForm
                  initialValues={{
                    mls: 'fmls'
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    )
  }
}

export default AddAgentScreen
