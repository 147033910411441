import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import IssuePaymentRefundForm from './Form'
import { RefundInfoType } from '../../types'

const IssuePaymentRefundModal: React.FC<{
  close: () => void
  isOpen: boolean
  refundInfo: RefundInfoType
}> = ({ close, isOpen, refundInfo }) => {
  return (
    <Modal toggle={close} isOpen={isOpen}>
      <ModalHeader toggle={close}>Issue a Payment Refund</ModalHeader>
      <ModalBody>
        <IssuePaymentRefundForm onSubmit={close} refundInfo={refundInfo} />
      </ModalBody>
    </Modal>
  )
}

export default IssuePaymentRefundModal
