import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'

import { getBathsString } from '../../lib/property'
import { formatMoney } from '../../lib/numbers'
import SellerAgentDetails from './AgentDetails'
import MLSAgentDetails from './MLSAgentDetail'
import IcMemoButton from './IcMemoButton'

const Group = styled.div`
  background-color: #ececec;
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 15px;
`

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
`

const KVRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`

const Value = styled.span`
  font-size: 16px;
`

const Key = styled(Value)`
  font-weight: bold;
  margin-right: 5px;
`
export default class PropertyInsights extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    sellerAgents: PropTypes.array
  }

  render() {
    const { property } = this.props
    const internalAttributes = property.internal_attributes[0]
    const isListed = ['internally_listed', 'listed'].includes(
      internalAttributes.internal_status
    )

    return (
      <>
        <Group>
          <Header>Property Info</Header>
          <KVRow>
            <Key>Up&Up Url:</Key>
          </KVRow>
          <KVRow>
            <Value>
              <a
                href={internalAttributes.url}
                rel='noopener noreferrer'
                target='_blank'
              >
                {internalAttributes.url}
              </a>
            </Value>
          </KVRow>
          <KVRow>
            <Key>Manage Photos:</Key>
          </KVRow>
          <KVRow>
            <Value>
              <a href={`/properties/mls-photo-manager/${property.id}`}>
                /properties/mls-photo-manager/${property.id}
              </a>
            </Value>
          </KVRow>
          {isListed && (
            <>
              <KVRow>
                <Key>Application Link:</Key>
              </KVRow>
              <KVRow>
                <Value>{`https://app.upandup.co/user/apply/${property.firestore_id}`}</Value>
              </KVRow>
            </>
          )}
          <KVRow>
            <Key>Source:</Key>
            <Value>
              {property.provider === 'homejunction'
                ? property.mls
                : property.provider}
            </Value>
          </KVRow>
          <KVRow>
            <Key>MLS Status:</Key>
            <Value>{property.status}</Value>
          </KVRow>
          <KVRow>
            <Key>Up&Up Status:</Key>
            <Value>{internalAttributes.internal_status}</Value>
          </KVRow>
          <KVRow>
            <Key>Should call agent:</Key>
            <Value>
              {Boolean(
                property.status === 'Active' &&
                  internalAttributes.internal_status !== 'reviewer_rejected'
              ).toString()}
            </Value>
          </KVRow>
          <KVRow>
            <Key>In Real Estate Buy Box:</Key>
            <Value>
              {Boolean(internalAttributes.is_in_real_estate_buy_box).toString()}
            </Value>
          </KVRow>
          <KVRow>
            <Key>In Customer Buy Box:</Key>
            <Value>
              {Boolean(internalAttributes.is_in_customer_buy_box).toString()}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Rent Percent Over Comp:</Key>
            <Value>
              {typeof internalAttributes.percent_over_comp === 'number'
                ? `${formatMoney(internalAttributes.percent_over_comp * 100)}%`
                : 'N/A'}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Price:</Key>
            <Value>${formatMoney(property.list_price, 0)}</Value>
          </KVRow>
          <KVRow>
            <Key>Suggested Rent:</Key>
            <Value>
              {internalAttributes.rent
                ? `$${formatMoney(internalAttributes.rent, 0)}`
                : 'N/A'}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Disclosures:</Key>
            <Value>{(property.xf_attributes.xf_disc || []).join(', ')}</Value>
          </KVRow>
          <KVRow>
            <Key>Agent Remarks:</Key>
            <Value>{property.xf_attributes.xf_agent_only_remarks}</Value>
          </KVRow>
          <KVRow>
            <Key>Beds:</Key>
            <Value>{property.beds}</Value>
          </KVRow>
          <KVRow>
            <Key>Baths:</Key>
            <Value>{getBathsString(property)}</Value>
          </KVRow>
          <KVRow>
            <Key>Square feet:</Key>
            <Value>{Number(property.size).toLocaleString()}</Value>
          </KVRow>
          <KVRow>
            <Key>Lot square feet:</Key>
            <Value>
              {property.lotsize_sqft
                ? Number(property.lotsize_sqft).toLocaleString()
                : 'Unknown'}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Area:</Key>
            <Value>{property.area}</Value>
          </KVRow>
          <KVRow>
            <Key>Municipality:</Key>
            <Value>{property.xf_attributes.xf_municipalitytownship}</Value>
          </KVRow>
          <KVRow>
            <Key>County:</Key>
            <Value>{property.county}</Value>
          </KVRow>
          <KVRow>
            <Key>Listing date:</Key>
            <Value>
              {moment(new Date(property.listing_date)).format('YYYY-MM-DD')}
            </Value>
          </KVRow>
          <KVRow>
            <Key>Days on market:</Key>
            <Value>{moment().diff(new Date(property.listing_date), 'd')}</Value>
          </KVRow>
          <KVRow>
            <Key>Year built:</Key>
            <Value>{property.year_built}</Value>
          </KVRow>
          <KVRow>
            <Key>Property type:</Key>
            <Value>{property.property_type}</Value>
          </KVRow>
          <MLSAgentDetails property={property} />
          <SellerAgentDetails propertyId={property.firestore_id} />
          <IcMemoButton propertyId={property.firestore_id} />
        </Group>
      </>
    )
  }
}
