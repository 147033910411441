import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import {
  setPrimaryAgent,
  fetchPropertySellerAgents
} from '../../state/properties/actions'
import AgentRow from './AgentRow'
import AddAgentModal from './AddAgentModal'

const Key = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
`
const Value = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`

const Header = styled(Key)`
  margin-bottom: 10px;
`

const AddAgentButton = styled(Button)`
  margin-left: 15px;
  flex: 1;
`

const SellerAgentDetails = ({ propertyId }) => {
  const dispatch = useDispatch()
  const sellerAgents = useSelector((state) => state.properties.sellerAgents)

  // modal config
  const [modal, setModal] = useState(false)

  const sellerAgentString = JSON.stringify(sellerAgents) // allow useEffect to check for changes to object
  useEffect(() => {
    dispatch(fetchPropertySellerAgents(propertyId))
  }, [sellerAgentString, propertyId])

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Header>Seller Agent Contacts:</Header>

      {sellerAgents.length > 0 ? (
        sellerAgents
          // primary agent goes first
          .sort((a, b) =>
            a.is_primary_agent === b.is_primary_agent
              ? 0
              : a.is_primary_agent
              ? -1
              : 1
          )
          .map(
            ({
              first_name: firstName,
              last_name: lastName,
              id,
              seller_agent_property_id: propertyId,
              is_primary_agent: isPrimary
            }) => {
              return (
                <AgentRow
                  key={propertyId}
                  propertyId={propertyId}
                  firstName={firstName}
                  lastName={lastName}
                  sellerAgentPropertyId={propertyId}
                  agentId={id}
                  isPrimary={isPrimary}
                  propertyHasSecondaryAgents={sellerAgents.length > 1}
                  handlePrimaryUpdate={() =>
                    dispatch(setPrimaryAgent(propertyId, propertyId))
                  }
                />
              )
            }
          )
      ) : (
        <Value style={{ marginLeft: '15px' }}> No agent contact history.</Value>
      )}
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <AddAgentButton outline onClick={toggle} color='success' size='sm'>
          + Add an Agent
        </AddAgentButton>
        <div style={{ flex: 3 }} />
      </div>
      <AddAgentModal toggle={toggle} modal={modal} propertyId={propertyId} />
    </div>
  )
}

SellerAgentDetails.propTypes = {
  propertyId: PropTypes.string.isRequired
}

export default SellerAgentDetails
