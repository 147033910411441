import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as form } from 'redux-form'

import admin from './admin/reducer'
import agents from './agents/reducer'
import dispatch from './dispatch/reducer'
import dispatchHeuristicScheduler from './dispatch-heuristic-scheduler/reducer'
import documentsModal from './documents-modal/reducer'
import modals from './modals/reducer'
import leadGroups from './lead-groups/reducer'
import personalDetails from './personal-details/reducer'
import properties from './properties/reducer'
import refreshComponents from './refresh-components/reducer'
import rentals from './rentals/reducer'
import search from './search/reducer'
import sdrs from './sdrs/reducer'
import users from './users/reducer'
import url from './url/reducer'

const history = (history) =>
  combineReducers({
    router: connectRouter(history),
    admin,
    agents,
    dispatch,
    dispatchHeuristicScheduler,
    documentsModal,
    modals,
    form,
    leadGroups,
    personalDetails,
    properties,
    refreshComponents,
    rentals,
    sdrs,
    search,
    url,
    users
  })

export default history
