import React from 'react'

const FormHeader = () => (
  <div style={{ paddingBottom: 30 }}>
    <h2>Add an Agent</h2>
    <p style={{ fontStyle: 'normal' }}>
      Use this form to add an agent to the database. <br />
      <b>
        (MLS ID is required - other values will be used if not found in
        HomeJunction)
      </b>
    </p>
  </div>
)

export default FormHeader
