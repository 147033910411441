import React, { FunctionComponent, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import type { HomesGridQuery } from 'graphql/generated'
import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent
} from 'ag-grid-community'
import { columns } from './gridColumns'
import 'ag-grid-community/styles//ag-grid.css'
import 'ag-grid-community/styles//ag-theme-alpine.css'
import { useHistory } from 'react-router-dom'

export type PropertyGridDataType = HomesGridQuery['properties'][number]
const gridOptions: GridOptions<PropertyGridDataType> = {
  domLayout: 'autoHeight',
  sideBar: true,
  defaultColDef: {
    minWidth: 40,
    sortable: true,
    filter: true,
    floatingFilter: true
  },
  pagination: true,
  paginationPageSize: 20
}

interface GridProps {
  rowData: HomesGridQuery['properties']
  onGridReady: (evt: GridReadyEvent<PropertyGridDataType>) => void
}

export const Grid: FunctionComponent<GridProps> = ({
  onGridReady,
  rowData
}) => {
  const history = useHistory()
  const [gridApi, setGridApi] = useState<GridApi<PropertyGridDataType>>()
  const [columnApi, setColumnApi] = useState<ColumnApi>()

  const gridSetup = (evt: GridReadyEvent<PropertyGridDataType>) => {
    setGridApi(evt.api)
    setColumnApi(evt.columnApi)
    onGridReady(evt)
  }

  useEffect(() => {
    if (gridApi && rowData) {
      // if row data prop changes, need to update programatically
      gridApi.setRowData(rowData)
      gridApi.sizeColumnsToFit()
      gridApi.onFilterChanged()
    }
  }, [gridApi, columnApi, rowData])

  return (
    <div className='ag-theme-alpine'>
      <AgGridReact<PropertyGridDataType>
        gridOptions={gridOptions}
        columnDefs={columns}
        rowData={rowData}
        onGridReady={gridSetup}
        onRowDoubleClicked={(evt) => {
          history.push(`/home/${evt.data?.id}`)
        }}
      />
    </div>
  )
}
