import React, { useState } from 'react'
import moment from 'moment'
import { Table } from 'reactstrap'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import { StoreState } from 'store'

import { Button, HeaderText } from 'components/Toolkit'
import { formatMoney } from 'lib/numbers'
import { hasCapability } from 'lib/admin-perms'
import CreateRentalAgreementHistoryModal from './CreateRentalAgreementHistoryModal'
import EditRentalAgreementHistoryModal from './EditRentalAgreementHistoryModal'
import FullRental from 'types/FullRental'

const {
  LEDGER_RENTAL_AGREEMENT_HISTORY_CREATOR,
  LEDGER_RENTAL_AGREEMENT_HISTORY_EDITOR
} = capabilities.CAPABILITY_TYPES

export default function RentalAgreementHistories({
  rental
}: {
  rental: FullRental
}) {
  const [
    isCreateRentalAgreementHistoryModalOpen,
    setIsCreateRentalAgreementHistoryModalOpen
  ] = useState(false)
  const [
    isEditRentalAgreementHistoryModalOpen,
    setIsEditRentalAgreementHistoryModalOpen
  ] = useState(false)
  const [rentalAgreementHistoryToEdit, setRentalAgreementHistoryToEdit] =
    useState<any>(null)
  const admin = useSelector<StoreState, any>((state) => state.admin)

  const canCreateRentalAgreements = hasCapability(
    admin,
    LEDGER_RENTAL_AGREEMENT_HISTORY_CREATOR
  )
  const canEditRentalAgreements = hasCapability(
    admin,
    LEDGER_RENTAL_AGREEMENT_HISTORY_EDITOR
  )

  const {
    id: rentalId,
    rental_agreement_histories: rentalAgreementHistories = []
  } = rental

  return (
    <div style={{ marginBottom: '1rem', marginRight: '1rem' }}>
      <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div>Rental Agreements</div>
          {canCreateRentalAgreements && (
            <Button
              isSecondary={false}
              size='vvs'
              style={{ float: 'right' }}
              onClick={() => setIsCreateRentalAgreementHistoryModalOpen(true)}
            >
              Create +
            </Button>
          )}
        </div>
      </HeaderText>
      <div>
        {rentalAgreementHistories.length ? (
          <Table>
            <thead>
              <tr>
                <th>Starts At</th>
                <th>Ends At</th>
                <th>Rent</th>
                <th>Option Premium</th>
                {canEditRentalAgreements && <th />}
              </tr>
            </thead>
            <tbody>
              {rentalAgreementHistories
                .sort((a, b) =>
                  moment(a.ends_at).isBefore(b.ends_at) ? 1 : -1
                )
                .map((rentalAgreementHistory) => (
                  <tr key={rentalAgreementHistory.id}>
                    <td>{rentalAgreementHistory.starts_at}</td>
                    <td>{rentalAgreementHistory.ends_at}</td>
                    <td>{formatMoney(rentalAgreementHistory.rent, 2, '$')}</td>
                    <td>
                      {formatMoney(
                        rentalAgreementHistory.option_premium,
                        2,
                        '$'
                      )}
                    </td>
                    {canEditRentalAgreements && (
                      <td>
                        <Button
                          isSecondary={false}
                          size='vvs'
                          onClick={() => {
                            setIsEditRentalAgreementHistoryModalOpen(true)
                            setRentalAgreementHistoryToEdit(
                              rentalAgreementHistory
                            )
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <div>Need to backfill rental agreement histories</div>
        )}
      </div>
      {canCreateRentalAgreements && (
        <CreateRentalAgreementHistoryModal
          rentalId={rentalId}
          close={() => setIsCreateRentalAgreementHistoryModalOpen(false)}
          isOpen={isCreateRentalAgreementHistoryModalOpen}
        />
      )}
      {canEditRentalAgreements && rentalAgreementHistoryToEdit && (
        <EditRentalAgreementHistoryModal
          rentalAgreementHistory={rentalAgreementHistoryToEdit}
          close={() => {
            setIsEditRentalAgreementHistoryModalOpen(false)
            setRentalAgreementHistoryToEdit(null)
          }}
          isOpen={isEditRentalAgreementHistoryModalOpen}
        />
      )}
    </div>
  )
}
