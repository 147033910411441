import React from 'react'
import PropTypes from 'prop-types'

import { HeaderText } from 'components/Toolkit'

export default function ReportHeader({ text }) {
  return (
    <HeaderText
      style={{
        marginTop: '1rem',
        marginBottom: 0
      }}
      size='h3'
    >
      {text}
    </HeaderText>
  )
}

ReportHeader.propTypes = {
  text: PropTypes.string.isRequired
}
