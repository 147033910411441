import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import TimelineEvent from './TimelineEvent'
import * as SC from '../StyledComponents'
import { Customer } from './types'

const Timeline: FunctionComponent<{
  customer: Customer
  timelineResponse?: types.TimelineResponse
  loading: boolean
  typesToHide: types.EventTypeString[]
  reverse?: boolean
}> = ({ customer, timelineResponse, loading, typesToHide, reverse }) => {
  console.log('timeline response: ', timelineResponse)
  if (loading) {
    return <SC.MessageText>Loading timeline...</SC.MessageText>
  }

  if (timelineResponse?.timeline.length) {
    const filteredTimeline = timelineResponse.timeline.filter(
      ({ eventType }) => !typesToHide.includes(eventType)
    )

    if (reverse) {
      filteredTimeline.reverse()
    }

    return (
      <SC.TimelineListContainer>
        {filteredTimeline.map((e, i) => {
          return (
            <TimelineEvent
              event={e}
              eventIdx={i}
              timelineLength={filteredTimeline.length}
              customer={customer}
              key={i}
              homes={timelineResponse.properties}
            />
          )
        })}
      </SC.TimelineListContainer>
    )
  }

  return (
    <SC.MessageText>
      No events found for {customer.firstName || 'user'}
    </SC.MessageText>
  )
}

export default Timeline
