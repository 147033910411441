import React, { useState } from 'react'
import { useMutation } from 'urql'
import { useSelector } from 'react-redux'
import { capabilities, rentals } from '@homevest/utils'

import FullRental from 'types/FullRental'
import { ErrorText } from 'components/Toolkit'
import { Input } from 'reactstrap'

import { Upup_SetCardPaymentDocument as CardPayment } from 'graphql/generated'
import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'

const { CUSTOMER_CHARGER } = capabilities.CAPABILITY_TYPES
const { RENTAL_STATUS } = rentals

export default function CardPaymentCheckbox({
  rental
}: {
  rental: FullRental
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [isCardPaymentAllowed, setIsCardPaymentAllowed] = useState(
    rental.is_card_payment_allowed
  )
  const isCharger = useSelector<StoreState, boolean>((store) =>
    hasCapability(store.admin, CUSTOMER_CHARGER)
  )
  const [{ fetching, error }, setCardPayment] = useMutation(CardPayment)

  const isDisabled =
    ![RENTAL_STATUS.ACTIVE, RENTAL_STATUS.PENDING].includes(rental.status) ||
    !isCharger ||
    isSaving ||
    fetching

  const updateCardPayment = async (isCardPaymentAllowed: boolean) => {
    if (isDisabled) {
      return
    }
    setIsSaving(true)

    setIsCardPaymentAllowed(isCardPaymentAllowed)
    await setCardPayment({
      is_card_payment_allowed: isCardPaymentAllowed,
      id: rental.id
    })

    setIsSaving(false)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Input
        type='checkbox'
        checked={isCardPaymentAllowed}
        onChange={(e) => updateCardPayment(e.target.checked)}
        disabled={isDisabled}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </div>
  )
}
