import React, { useEffect, useState } from 'react'
import { get, omit, startCase } from 'lodash'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { capabilities, documents, userIncomes } from '@homevest/utils'

import {
  BodyText,
  Button,
  Dropdown,
  ErrorText,
  HeaderText,
  Input,
  Label,
  SuccessText,
  TextArea
} from 'components/Toolkit'

import UserDocuments from 'components/Documents/user-documents'

import COLORS from 'components/Toolkit/colors'

import axios from 'lib/axios'
import { hasCapability } from 'lib/admin-perms'
import { formatMoney } from 'lib/numbers'
import { useSelector } from 'react-redux'

const { INCOME_REVIEWER } = capabilities.CAPABILITY_TYPES

const {
  FINANCIAL_DOCUMENT_TYPES,
  DOCUMENT_STATUSES: { VALID }
} = documents

const {
  STATUSES: { UNVERIFIED, VERIFIED }
} = userIncomes

const StyledContent = styled.div`
  width: 600px;
  margin: 0 auto;
`

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
`

const ADD_NEW = 'add_new'

const fetchDocuments = async (userId, context) => {
  const { setDocumentUrls, setDocuments } = context

  const { data } = await axios.get(`/admin/documents/${userId}`)
  const documents = data.filter(
    (doc) => FINANCIAL_DOCUMENT_TYPES.includes(doc.type) && doc.status === VALID
  )
  const documentUrls = await Promise.all(
    documents.map(({ id }) =>
      axios.get(`/admin/documents/${id}/url`).then((resp) => resp.data.url)
    )
  )
  const documentUrlsMapped = documents.reduce(
    (acc, { id }, idx) => ({ ...acc, [id]: documentUrls[idx] }),
    {}
  )

  setDocumentUrls(documentUrlsMapped)
  setDocuments(documents)
}

const fetchUser = async (userId, context) => {
  const { setUser } = context
  const { data } = await axios.get(`/users/${userId}`)
  setUser(data)
}

const fetchUserIncomes = async (userId, context) => {
  const { setEditUserIncome, setEditUserIncomeId, setUserIncomes } = context
  const { data } = await axios.get(`/admin/users/${userId}/incomes`)
  setUserIncomes(data)
  if (data.length) {
    setEditUserIncome(data[0])
    setEditUserIncomeId(data[0].id)
  }
}

const fetchUserIncomeById = async (id, context) => {
  const { setEditUserIncome } = context

  if (!id || id === ADD_NEW) {
    return
  }

  const { data } = await axios.get(`/admin/user_incomes/${id}`)
  setEditUserIncome(data)
}

const saveUserIncome = async (
  editUserIncomeId,
  editUserIncome,
  userId,
  context
) => {
  const {
    setErrorMessage,
    setSuccessMessage,
    setSaving,
    setEditUserIncomeId,
    fetchUserIncomes
  } = context

  try {
    setErrorMessage(null)
    setSuccessMessage(null)
    setSaving(true)

    editUserIncome = omit(editUserIncome, [
      'submitted_by_admin',
      'user_paystubs',
      'user_income_resources',
      'verified_by_admin',
      'unverified_by_admin'
    ])
    ;['projected_annual_income', 'ytd_gross_income', 'ytd_net_income'].forEach(
      (f) => {
        if (editUserIncome[f] === '') {
          editUserIncome[f] = null
        }
      }
    )

    if (editUserIncomeId === ADD_NEW) {
      editUserIncome.user_id = userId
      const { data } = await axios.post('/admin/user_incomes', editUserIncome)
      setEditUserIncomeId(data.id)
    } else {
      await axios.put(`/admin/user_incomes/${editUserIncomeId}`, editUserIncome)
    }

    await Promise.all([
      fetchUserIncomes(userId, context),
      fetchUserIncomeById(editUserIncomeId, context)
    ])
    setSuccessMessage('User income saved!')
  } catch (err) {
    setErrorMessage(err.message)
  } finally {
    setSaving(false)
  }
}

function UserIncomeEditor() {
  const { userId } = useParams()

  const history = useHistory()

  const isAllowed = useSelector((store) =>
    hasCapability(store.admin, INCOME_REVIEWER)
  )
  const [documentUrls, setDocumentUrls] = useState([])
  const [documents, setDocuments] = useState([])
  const [editUserIncome, setEditUserIncome] = useState({})
  const [editUserIncomeId, setEditUserIncomeId] = useState(ADD_NEW)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [userIncomes, setUserIncomes] = useState(null)
  const [saving, setSaving] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const context = {
    fetchUserIncomes,
    setDocumentUrls,
    setDocuments,
    setEditUserIncome,
    setEditUserIncomeId,
    setErrorMessage,
    setIsLoading,
    setUser,
    setUserIncomes,
    setSaving,
    setSuccessMessage
  }

  const getUserIncomeLabel = (ui) => {
    const resourceType = get(ui, 'user_income_resources[0].resource_type')
    const a = []
    if (resourceType === 'plaid_income_verifications') {
      a.push('Plaid')
    } else {
      a.push(get(ui, 'submitted_by_admin.name', '?'))
    }

    a.push(startCase(ui.status))

    a.push(moment(ui.created_at).format('MM/DD/yyyy @ hh:mm A'))
    return a.join(', ')
  }

  useEffect(() => {
    async function run() {
      try {
        setIsLoading(true)
        await Promise.all([
          fetchUser(userId, context),
          fetchDocuments(userId, context),
          fetchUserIncomes(userId, context)
        ])
      } catch (err) {
        setErrorMessage(err.message)
      } finally {
        setIsLoading(false)
      }
    }
    run()
  }, [userId])

  useEffect(() => {
    async function run() {
      try {
        await fetchUserIncomeById(editUserIncomeId, context)
      } catch (err) {
        setErrorMessage(err.message)
      }
    }
    run()
  }, [editUserIncomeId])

  if (isLoading || !userIncomes) {
    return (
      <StyledLoading>
        <span>Loading...</span>
      </StyledLoading>
    )
  }

  if (!isAllowed) {
    return (
      <ErrorText>You do not have the capability of income reviewer</ErrorText>
    )
  }

  const paystubs = get(editUserIncome, 'user_paystubs', [])
  const userIncomeResource = get(editUserIncome, 'user_income_resources[0]', {})
  let plaidIncomeVerificationId
  if (userIncomeResource.resource_type === 'plaid_income_verifications') {
    plaidIncomeVerificationId = userIncomeResource.resource_id
  }

  return (
    <StyledContent>
      <HeaderText style={{ textAlign: 'center' }} size='h3'>
        Income Editor {user.name}
      </HeaderText>
      <Dropdown
        label='Income Statement To Edit'
        value={editUserIncomeId}
        onChange={(val) => {
          setEditUserIncomeId(val)
          if (val === ADD_NEW) {
            setEditUserIncome({
              employee_name: user.name
            })
          }
        }}
        options={[{ label: 'Add New', value: ADD_NEW }].concat(
          userIncomes.map((ui) => {
            return {
              label: getUserIncomeLabel(ui),
              value: ui.id
            }
          })
        )}
      />
      {(editUserIncome.status === UNVERIFIED || editUserIncomeId === ADD_NEW) &&
        documents.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <UserDocuments
              documentUrls={documentUrls}
              documents={documents}
              isSaving={false}
            />
          </div>
        )}
      {editUserIncome.status === UNVERIFIED && paystubs.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <HeaderText size='h3'>Plaid Paystubs</HeaderText>
          {paystubs.map((paystub) => (
            <div key={paystub.id}>
              <HeaderText
                style={{ display: 'flex', justifyContent: 'space-between' }}
                size='h5'
              >
                Paystub {paystub.pay_period_start_date} to{' '}
                {paystub.pay_period_end_date}
              </HeaderText>
              {paystub.income_breakdown.length === 0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px'
                  }}
                >
                  <div style={{ width: '50%' }}>
                    <Label>Gross Earnings</Label>
                    <BodyText style={{ marginTop: 0 }}>
                      {formatMoney(paystub.pay_period_gross_earnings, 2, '$')}
                    </BodyText>
                  </div>
                  <div style={{ width: '50%' }}>
                    <Label>Check Amount</Label>
                    <BodyText style={{ marginTop: 0 }}>
                      {formatMoney(paystub.pay_period_check_amount, 2, '$')}
                    </BodyText>
                  </div>
                </div>
              )}
              {paystub.income_breakdown.length > 0 &&
                paystub.income_breakdown.map((breakdown) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px'
                    }}
                    key={breakdown.type}
                  >
                    <div style={{ width: '25%' }}>
                      <Label>Type</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {breakdown.type}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Rate</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {formatMoney(breakdown.rate, 2, '$')}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Hours</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {breakdown.hours}
                      </BodyText>
                    </div>
                    <div style={{ width: '25%' }}>
                      <Label>Total</Label>
                      <BodyText style={{ marginTop: 0 }}>
                        {formatMoney(breakdown.total, 2, '$')}
                      </BodyText>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}

      <Input
        label='Employee Name'
        value={editUserIncome.employee_name || ''}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            employee_name: val
          })
        }
      />
      <Input
        label='Employee Title'
        value={editUserIncome.employee_title || ''}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            employee_title: val
          })
        }
      />
      <Input
        label='Employer Name'
        value={editUserIncome.employer_name || ''}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            employer_name: val
          })
        }
      />
      <Input
        label='Employer Industry'
        value={editUserIncome.employer_industry || ''}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            employer_industry: val
          })
        }
      />
      <Input
        disableNumberScroll
        label='Annual Income'
        type='number'
        value={editUserIncome.projected_annual_income}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            projected_annual_income: val
          })
        }
      />
      <Input
        disableNumberScroll
        label='YTD Gross Income'
        type='number'
        value={editUserIncome.ytd_gross_income}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            ytd_gross_income: val
          })
        }
      />
      <Input
        disableNumberScroll
        label='YTD Net Income'
        type='number'
        value={editUserIncome.ytd_net_income}
        onChange={(val) =>
          setEditUserIncome({
            ...editUserIncome,
            ytd_net_income: val
          })
        }
      />
      <Dropdown
        label='Pay Frequency'
        value={editUserIncome.pay_frequency || ''}
        onChange={(val) =>
          setEditUserIncome({ ...editUserIncome, pay_frequency: val })
        }
        options={[
          'monthly',
          'semimonthly',
          'biweekly',
          'weekly',
          'unknown'
        ].map((i) => {
          return {
            label: i,
            value: i
          }
        })}
      />
      <TextArea
        label='Notes'
        rows='4'
        value={editUserIncome.notes || ''}
        onChange={(val) => setEditUserIncome({ ...editUserIncome, notes: val })}
      />
      <div>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        {successMessage && <SuccessText>{successMessage}</SuccessText>}
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {editUserIncome.status !== VERIFIED && (
          <Button
            size='vs'
            disabled={saving}
            style={{ marginRight: '15px' }}
            onClick={() =>
              saveUserIncome(editUserIncomeId, editUserIncome, userId, context)
            }
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        )}
        <Button
          size='vs'
          isLink
          isSecondary
          onClick={() => {
            history.goBack()
          }}
        >
          Back
        </Button>
        {editUserIncome.status !== VERIFIED && (
          <Button
            size='vs'
            style={{ marginLeft: '40px', background: COLORS.ACCENTS.SALMON }}
            onClick={() => {
              editUserIncome.status = VERIFIED
              saveUserIncome(editUserIncomeId, editUserIncome, userId, context)
            }}
          >
            Save + Verify
          </Button>
        )}

        {editUserIncomeId !== ADD_NEW && editUserIncome.status !== UNVERIFIED && (
          <Button
            size='vs'
            style={{ marginLeft: '40px', background: COLORS.ACCENTS.SALMON }}
            onClick={() => {
              editUserIncome.status = UNVERIFIED
              saveUserIncome(editUserIncomeId, editUserIncome, userId, context)
            }}
          >
            Unverify
          </Button>
        )}
        {plaidIncomeVerificationId && (
          <Button
            size='vs'
            style={{ marginLeft: '40px' }}
            isSecondary
            isLink
            onClick={() => {
              history.push(
                `/plaid-income-verification/${plaidIncomeVerificationId}/reviewer`
              )
            }}
          >
            Debug
          </Button>
        )}
      </div>
    </StyledContent>
  )
}

export default UserIncomeEditor
