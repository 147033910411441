import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { StoreState } from 'store'
import { Dispatch } from 'redux'

import { setSuccessModalIsOpen } from 'state/modals/actions'

import * as SC from '../StyledComponents'

const Challenge: FunctionComponent<{
  isOpen: boolean
  setIsOpen: Function
  successMessage: string
}> = ({ isOpen, setIsOpen, successMessage }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false)
      }, 3000)
    }
  }, [isOpen])

  return (
    <SC.SuccessToast isOpen={isOpen}>
      <SC.SuccessToastSubContainer>
        <SC.SmallCheckCircle />
        {successMessage}
      </SC.SuccessToastSubContainer>
      <SC.XIcon onClick={() => setIsOpen(false)} />
    </SC.SuccessToast>
  )
}

const mapStateToProps = (state: StoreState) => ({
  isOpen: state.modals.successModalIsOpen,
  successMessage: state.modals.successMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsOpen: (isOpen: boolean) => dispatch(setSuccessModalIsOpen(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(Challenge)
