import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Chart from 'react-c3-component'
import 'c3/c3.css'
import { firestoreCollections } from 'lib/firebase'

const StyledContainer = styled.div`
  display: flex;
  align-items: stretch;
  height: calc(100vh - 96px);
`
async function getData(setData, showAllCities) {
  const users = await firestoreCollections.usersRef
    .where('userRegionPreference', '==', 'atlanta-ga')
    .get()

  const numbersByCity = {}

  const data = users.docs
    .map((item) => item.data())
    .reduce((acc, item) => {
      if (
        !item.preferences ||
        !item.preferences.rent ||
        item.preferences.rent.max > 2800
      ) {
        return acc
      }
      const rentMax = Math.floor(item.preferences.rent.max / 100) * 100
      const cities =
        item.preferences['address.city'] ||
        (showAllCities ? ['All Cities'] : [])

      if (!acc[rentMax]) {
        acc[rentMax] = {}
      }

      return cities.reduce((acc, city) => {
        if (!numbersByCity[city]) {
          numbersByCity[city] = 0
        }

        numbersByCity[city]++

        if (!acc[rentMax][city]) {
          acc[rentMax][city] = 0
        }
        acc[rentMax][city]++
        return acc
      }, acc)
    }, {})

  const filteredData = Object.keys(data).reduce((acc, rent) => {
    acc[rent] = Object.keys(data[rent]).reduce((acc, city) => {
      if (numbersByCity[city] > 6) {
        acc[city] = data[rent][city]
      }
      return acc
    }, {})

    return acc
  }, {})

  const allCities = Object.keys(filteredData).reduce((acc, rent) => {
    Object.keys(filteredData[rent]).forEach((city) => {
      acc[city] = true
    })

    return acc
  }, {})

  const a = {
    x: 'x',
    columns: [
      ['x', ...Object.keys(filteredData).map((item) => parseInt(item))],
      ...Object.keys(allCities).map((city) => [
        city,
        ...Object.keys(filteredData).map(
          (rent) => filteredData[rent][city] || 0
        )
      ])
    ],
    type: 'bar',
    groups: [Object.keys(allCities)]
  }

  // Break up into groups of rent, place city count as vars
  setData(a)
}

const grid = {
  y: {
    lines: [{ value: 0 }]
  }
}

function Stats() {
  const [data, setData] = useState(null)
  const [showAllToo, setShowAllToo] = useState(true)

  useEffect(() => {
    getData(setData, showAllToo)
  }, [setData, showAllToo])

  if (!data) {
    return <div />
  }

  return (
    <div>
      <button
        style={{
          position: 'absolute',
          top: '100px',
          right: '100px',
          zIndex: '9999'
        }}
        onClick={() => {
          setShowAllToo(!showAllToo)
          setData(null)
        }}
      >
        {showAllToo ? 'Hide All Cities' : 'Show All Cities'}
      </button>
      <StyledContainer>
        <Chart config={{ data, grid }} />
      </StyledContainer>
    </div>
  )
}

Stats.propTypes = {}

export default Stats
