import { Tab } from '@headlessui/react'
import { PropertyGridDataType } from 'components/Homes/Grid/grid'
import React, { FunctionComponent } from 'react'
import { ConstructionHistoryCard } from '../Cards/ConstructionHistory'

// TODO: create GraphQL fragment that has exact data needed for this panel
interface ConstructionPanelProps {
  property: PropertyGridDataType
}
export const ConstructionPanel: FunctionComponent<ConstructionPanelProps> = ({
  property
}) => (
  <Tab.Panel>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minWidth: '1500px'
      }}
    >
      <ConstructionHistoryCard propertyId={property.id} />
    </div>
  </Tab.Panel>
)
