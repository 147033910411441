import _ from 'lodash'
export const removeTimezone = (date: Date) => {
  return new Date(date.toISOString().slice(0, 10) + ZERO_TIMEZONE)
}

export const ZERO_TIMEZONE = 'T10:00:00.000Z'

export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const HOURS_PER_DAY = 24
export const SECONDS_PER_DAY =
  SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY
export const MILLISECONDS_PER_SECOND = 1000

export const millisToDays = (milliseconds: number) =>
  milliseconds / MILLISECONDS_PER_SECOND / SECONDS_PER_DAY

export const UPUP = {
  date: (date: string, { leaveNull = false, addDay = false } = {}) => {
    if (leaveNull && !date) {
      return null
    }
    const handleNull = (date: any) => {
      if (date === null || !date || date.length === 0) {
        return new Date()
      }
      return date
    }
    const baseDate = new Date(handleNull(date))

    if (addDay) {
      const DateObj = new Date(
        +baseDate + SECONDS_PER_DAY * MILLISECONDS_PER_SECOND
      )
      return removeTimezone(DateObj)
    }
    return removeTimezone(baseDate)
  }
}

export function dateRangeOverlaps(
  a_start: Date,
  a_end: Date,
  b_start: Date,
  b_end: Date
) {
  if (a_start < b_start && b_start < a_end) return true // b starts in a
  if (a_start < b_end && b_end < a_end) return true // b ends in a
  if (b_start < a_start && a_end < b_end) return true // a in b
  return false
}

// the plus changes to seconds
export const daysBetween = (start: Date, end: Date) =>
  millisToDays(+end - +start) + 1

export const removeBackSlashR = (str: string) => {
  return str.replace('\r', '')
}

export const first = (arr: any[]) => {
  arr.sort((a, b) => +a - +b)
  const smallest = arr[0]
  return smallest
}

export const latest = (arr: any[]) => {
  arr.sort((a, b) => +b - +a)
  const largest = arr[0]
  return largest
}

export const print = (...args: any) => {
  try {
    const makeDatePretty = (date: any) => date.toString().slice(4, 15)

    const handleArray = (arr: any[]): any => {
      return arr.map((item) => {
        if (!item) {
          return null
        }
        if (item instanceof Date) {
          return makeDatePretty(item)
        }
        if (Array.isArray(item)) {
          return handleArray(item)
        }
        if (typeof item === 'object') {
          return handleObject(item)
        }
        return item
      })
    }

    const handleObject = (obj: any) => {
      if (!obj) {
        return null
      }
      const out = {}
      Object.entries(obj).forEach(([key, value]) => {
        if (!key) {
          // don't add anything to out
        } else if (!value) {
          _.set(out, key, null)
        } else if (value instanceof Date) {
          _.set(out, key, makeDatePretty(value))
        } else if (Array.isArray(value)) {
          _.set(out, key, handleArray(value))
        } else if (typeof value === 'object') {
          _.set(out, key, handleObject(value))
        } else {
          _.set(out, key, value)
        }
      })
      return out
    }

    console.log(
      ...args.map((arg: any) => {
        if (!arg) {
          return null
        }
        if (arg instanceof Date) {
          return makeDatePretty(arg)
        } else if (Array.isArray(arg)) {
          return handleArray(arg)
        } else if (typeof arg === 'object') {
          return handleObject(arg)
        } else {
          return arg
        }
      })
    )
  } catch (err) {
    console.log(args)
  }
}

export const combineTranformations = ({
  changes,
  previousTransforms
}: {
  changes: {}
  previousTransforms: {}
}) => {
  const previousTransformKeys = Object.keys(previousTransforms)

  // const previousTransformDataKeys = previousTransformKeys.map((key) =>
  //   Object.keys(previousTransforms[key]),
  // ).flat();

  // console.log(previousTransformDataKeys);

  return {
    ...previousTransforms,
    [`transform${previousTransformKeys.length}`]: Object.entries(
      changes
    ).reduce((obj, [key, value]) => {
      _.set(obj, key, value)
      return obj
    }, {})
  }
}

export function arrayToCSV(data: any) {
  if (Array.isArray(data)) {
    const csv = data.map((row) => Object.values(row))
    console.log(csv)
    csv.unshift(Object.keys(data[0]))
    return csv.join('\n')
  }
  alert('error with array to csv')
  return 'failed,csv'
}
