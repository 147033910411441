import moment from 'moment'

import { UpupRentalByIdSubscription } from 'graphql/generated'
import { payments as PAYMENTS } from '@homevest/utils'

const { PAYMENT_CATEGORIES, PAYMENT_STATUSES } = PAYMENTS

type Rental = UpupRentalByIdSubscription['rentals'][number]

type RentalPayment = Rental['payments'][number]

type Payment = Omit<RentalPayment, 'user'> & { user?: RentalPayment['user'] }

type LedgerItem = {
  amount: number
  category?: string | null
  liabilityLabel: string
  date: string
  id: string
  status: string
  type: string
  availableOn?: string
  user?: Rental['payments'][number]['user']
  lifecycle?: string
  initiatedAt?: string
}

export const getLedgerItemsWithBalance = (
  payments: Payment[],
  rentalLiabilities: Rental['rental_liabilities'],
  rentalCredits: Rental['rental_credits']
) => {
  const ledgerData = [
    ...payments.map(
      ({
        amount,
        available_on: availableOn,
        created_at: createdAt,
        id,
        category,
        liability_type: liabilityType,
        payment_date: paymentDate = '',
        payment_method: paymentMethod,
        status,
        lifecycle,
        initiated_at: initiatedAt,
        user
      }) => ({
        amount,
        availableOn,
        category,
        liabilityLabel: liabilityType.name,
        date: paymentDate || moment(createdAt).format('YYYY-MM-DD'),
        paymentMethod,
        id,
        type: 'Payment',
        status,
        lifecycle,
        initiatedAt,
        user
      })
    ),
    ...rentalLiabilities.map(({ amount, date, id, liability_type }) => ({
      amount,
      category: liability_type.payment_category,
      liabilityLabel: liability_type.name,
      date,
      id,
      status: '',
      type: 'Charge'
    })),
    ...rentalCredits.map(({ amount, date, id }) => ({
      amount,
      category: PAYMENT_CATEGORIES.RENT,
      liabilityLabel: 'Rent',
      date,
      id,
      status: 'succeeded',
      type: 'Credit'
    }))
  ]

  const sortedWithData = ledgerData
    .sort((a, b) => {
      const daysDiff = moment(a.date).diff(b.date, 'days')

      if (daysDiff !== 0) {
        return daysDiff
      } else if (a.type === 'Charge') {
        return -1
      } else {
        return 1
      }
    })
    .reduce(
      (
        { ledgerDataWithBalances, balance, investmentToDate, rentPaidToDate },
        ld
      ) => {
        if (
          ['Payment', 'Credit'].includes(ld.type) &&
          ld.category !== PAYMENT_CATEGORIES.INITIAL_PAYMENT &&
          [PAYMENT_STATUSES.SUCCEEDED, PAYMENT_STATUSES.PROCESSING].includes(
            ld.status as any
          )
        ) {
          balance -= ld.amount

          if (ld.category === PAYMENT_CATEGORIES.RENT) {
            rentPaidToDate += ld.amount
          } else if (ld.category === PAYMENT_CATEGORIES.OPTION_PREMIUM) {
            investmentToDate += ld.amount
          }
        } else if (ld.type === 'Charge') {
          balance += ld.amount
        }

        const dataWithBalance = {
          ...ld,
          balance
        }

        ledgerDataWithBalances.push(dataWithBalance)

        return {
          ledgerDataWithBalances,
          balance,
          investmentToDate,
          rentPaidToDate
        }
      },
      {
        ledgerDataWithBalances: [] as Array<LedgerItem & { balance: number }>,
        balance: 0,
        investmentToDate: 0,
        rentPaidToDate: 0
      }
    )

  sortedWithData.ledgerDataWithBalances.reverse()

  return sortedWithData
}
