import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import { format } from 'date-fns'
import * as SC from '../../StyledComponents'
import DisplayPropertyDetails from './PropertyDetails'
import { Customer } from '../types'
import { mapContentToBadge } from '../../badges'

const RenderResource = (resourceType: string, customerName: string) => {
  switch (resourceType) {
    case 'users': {
      return (
        <span>
          {' '}
          <SC.InlineBold>{customerName}</SC.InlineBold>
        </span>
      )
    }
    case 'rental_applications': {
      return (
        <span>
          a <SC.InlineBold>rental application</SC.InlineBold>
        </span>
      )
    }
    case 'rentals': {
      return (
        <span>
          a <SC.InlineBold>rental</SC.InlineBold>
        </span>
      )
    }
    case 'lead_groups': {
      return (
        <span>
          a <SC.InlineBold>lead group</SC.InlineBold>
        </span>
      )
    }
    case 'evictions': {
      return (
        <span>
          the <SC.InlineBold>eviction</SC.InlineBold>
        </span>
      )
    }
    case 'user_funnel_statuses': {
      return (
        <span>
          the <SC.InlineBold>user funnel status</SC.InlineBold>
        </span>
      )
    }
    default:
      return null
  }
}

const RenderApplicationDetails = (
  resourceType: string,
  userFunnelStatus?: string,
  rentalApplicationStatus?: string,
  homeDetails?: types.HomeDetails
) => {
  switch (resourceType) {
    case 'users': {
      return null
      // return (
      //   <>
      //     <SC.EventSubheading>User Details</SC.EventSubheading>
      //   </>
      // )
    }
    case 'rental_applications': {
      return (
        <>
          <SC.EventSubheading>Rental Application</SC.EventSubheading>{' '}
          {rentalApplicationStatus !== undefined
            ? mapContentToBadge(rentalApplicationStatus)
            : null}
          <DisplayPropertyDetails details={homeDetails} shouldHideHeading />
        </>
      )
    }
    case 'rentals': {
      return (
        <>
          {' '}
          <SC.EventSubheading>Rental</SC.EventSubheading>{' '}
          <DisplayPropertyDetails details={homeDetails} shouldHideHeading />
        </>
      )
    }
    case 'lead_groups': {
      return null
      // return (
      //   <>
      //     {' '}
      //     <SC.EventSubheading>Lead Group Details</SC.EventSubheading>{' '}
      //   </>
      // )
    }
    case 'evictions': {
      return (
        <>
          {' '}
          <SC.EventSubheading>Evictions Details</SC.EventSubheading>{' '}
          <DisplayPropertyDetails details={homeDetails} shouldHideHeading />
        </>
      )
    }
    case 'user_funnel_statuses': {
      return (
        <>
          {' '}
          <SC.EventSubheading>User Funnel Status</SC.EventSubheading>{' '}
          {userFunnelStatus ? mapContentToBadge(userFunnelStatus) : null}
        </>
      )
    }
    default:
      return null
  }
}

export const DisplayNote: FunctionComponent<{
  event: types.NoteTimelineEvent
  expanded: boolean
  customer: Customer
  homes: types.HomeDetails[]
}> = ({ event, expanded, customer, homes }) => {
  const homeDetails = homes?.find(
    (h) => h.mlsListingId === event.additionalInfo.mlsListingId
  )
  const expandedContent = (
    <>
      <>
        <SC.EventSubheading>Note</SC.EventSubheading>
        <SC.MessageText>{event.content}</SC.MessageText>
        <SC.DescriptionText>
          Created by <SC.InlineBold>{event.createdByAdminName}</SC.InlineBold>
        </SC.DescriptionText>
        <SC.DescriptionText>
          Created at{' '}
          <SC.InlineBold>
            {format(new Date(event.eventTimeAt), 'ppp')}
          </SC.InlineBold>{' '}
          on{' '}
          <SC.InlineBold>
            {format(new Date(event.eventTimeAt), 'P')}
          </SC.InlineBold>
        </SC.DescriptionText>
      </>
      <SC.Subsection>
        {RenderApplicationDetails(
          event.resourceType,
          event.additionalInfo.userFunnelStatus,
          event.additionalInfo.rentalApplicationStatus,
          homeDetails
        )}
      </SC.Subsection>
    </>
  )

  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{event.createdByAdminName}</SC.InlineBold> created a note
        associated with{' '}
        {RenderResource(
          event.resourceType,
          customer.firstName + ' ' + customer.lastName
        )}
      </SC.MessageText>
      {expanded ? <SC.FadeIn>{expandedContent}</SC.FadeIn> : null}
    </div>
  )
}
