export const MATCH_FAILURE_REASONS = Object.freeze({
  NEED_CUSTOMER_AVAILABILITY: 'need_customer_availability',
  NEED_AGENT_AVAILABILITY: 'need_agent_availability',
  ALL_TOURS_SCHEDULED: 'all_tours_scheduled'
})

export const ALL_MARKETS = 'all_markets'

// Tour modal next button options
export const SHOWINGTIME_UNAVAILABLE = 'showingtime_unavailable'
export const SKIP_TOUR = 'skip_tour'
export const TOUR_DONE = 'tour_done'

// Tour modal states
export const ATTEMPTING_TO_BOOK_TOUR = 'DISPATCH/ATTEMPTING_TO_BOOK_TOUR'
export const INDICATED_SHOWINGTIME_UNAVAILABLE =
  'DISPATCH/INDICATED_SHOWINGTIME_UNAVAILABLE'
export const NEED_CUSTOMER_AVAILABILITY = 'DISPATCH/NEED_CUSTOMER_AVAILABILITY'
export const NEED_AGENT_AVAILABILITY = 'DISPATCH/NEED_AGENT_AVAILABILITY'
export const MODAL_CLOSED = 'DISPATCH/MODAL_CLOSED'
export const MODAL_LOADING = 'DISPATCH/MODAL_LOADING'
export const MODAL_ERROR = 'DISPATCH/MODAL_ERROR'

// Showingtime unavailability input fields
export const CURRENT_DATE = 'currentDate'
export const CURRENT_START = 'currentStart'
export const CURRENT_END = 'currentEnd'
