import { get } from 'lodash'
import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import ReactJson from 'react-json-view'
import { capabilities } from '@homevest/utils'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import {
  BodyText,
  HeaderText,
  ErrorText,
  SuccessText
} from 'components/Toolkit'
import Dropzone from 'components/Dropzone'

import { hasCapability } from 'lib/admin-perms'
import axios from 'lib/axios'

const { MLS_UPLOADER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function OperationsUploader() {
  const isIncomeReviewer = useSelector((store) =>
    hasCapability(store.admin, MLS_UPLOADER)
  )

  if (!isIncomeReviewer) {
    return (
      <ErrorText>You do not have the capability of MLS uploader.</ErrorText>
    )
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        MLS Uploader
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <OperationsUploaderViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function OperationsUploaderViewer() {
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [successPayload, setSuccessPayload] = useState([])
  const [errorPayload, setErrorPayload] = useState([])
  const handleSuccess = async (url) => {
    // Need to axios post the URL to API, who will pass to GCF.
    try {
      setIsLoading(true)
      const { data } = await axios.post(
        '/admin/properties/mls_listings_uploader',
        {
          url
        }
      )
      setSuccessMessage(
        'Upload complete, your listings / sausages are being processed. 🌭'
      )
      setSuccessPayload(data.filter((i) => !i.is_error))
      setErrorPayload(data.filter((i) => i.is_error))
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (errorMessage) {
    return <ErrorText>{errorMessage}</ErrorText>
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>{successMessage}</SuccessText>
        <HeaderText size='h4'>Processed ({successPayload.length})</HeaderText>
        {successPayload.map((i) => (
          <div key={i.id}>
            <HeaderText size='h5'>
              {get(i, 'address.displayLine1', '!!Error!!')}
            </HeaderText>
            <ReactJson collapsed='true' src={i} />
          </div>
        ))}
        <hr />
        <HeaderText size='h4'>Failed ({errorPayload.length})</HeaderText>
        {errorPayload.map((i) => (
          <div key={i.id}>
            <HeaderText size='h5' style={{ color: 'red' }}>
              {get(i, 'payload.address.displayLine1')}
            </HeaderText>
            <BodyText>{i.error_message}</BodyText>
            <ReactJson collapsed='true' src={i} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <BodyText style={{ marginBottom: '20px' }}>
        This tool takes a CSV and uploads them into the MLS ingestion engine.
        Hopefully Brandon's sausage maker will output some MLS listings!
      </BodyText>
      <Dropzone
        onSuccess={handleSuccess}
        uploadPath={'/operations/mls-uploader/' + uuidv4()}
      />
    </div>
  )
}
