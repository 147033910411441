"use strict";
exports.RENTAL_APPLICATION_CANCELATION_REASONS = Object.freeze({
    ADVERSE_RENTAL_HISTORY: 'adverse_rental_history',
    APPLICATION_INCOMPLETE: 'application_incomplete',
    CREDIT_REPORT: 'credit_report',
    CRIMINAL_REPORT: 'criminal_report',
    DOCUMENTS_REJECTED: 'documents_rejected',
    FAILED_UNDERWRITING: 'failed_underwriting',
    FOUND_OTHER_HOME: 'found_other_home',
    HOME_FAILED_INSPECTION: 'home_failed_inspection',
    HOME_OFF_MARKET: 'home_off_market',
    HOME_RENTED: 'home_rented',
    INCOME_INSUFFICIENT: 'income_insufficient',
    LOST_BID: 'lost_bid',
    NOT_INTERESTED_IN_PROGRAM_TERMS: 'not_interested_in_program_terms'
});
exports.RENTAL_APPLICATION_STATUSES = Object.freeze({
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    PENDING_APPLICATION_REVIEW: 'pending_application_review',
    PENDING_CUSTOMER_INFORMATION: 'pending_customer_information',
    PENDING_CUSTOMER_PAYMENT: 'pending_customer_payment',
    PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION: 'pending_customer_rental_agreement_completion',
    PENDING_PROPERTY_PURCHASE: 'pending_property_purchase',
    PENDING_RENTAL_AGREEMENT_CREATION: 'pending_rental_agreement_creation',
    PENDING_UNDERWRITING: 'pending_underwriting',
    PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION: 'pending_upandup_rental_agreement_completion'
});
