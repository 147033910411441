import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { firestore } from '../../lib/firebase'

const StyledH3 = styled.h3`
  margin-top: 2rem;
`

export default class PropertyDetails extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired
  }

  state = {
    propertyCrmData: null
  }

  async componentDidMount() {
    const { property } = this.props

    await this.setPropertyCrmData(property.id)
  }

  setPropertyCrmData = async (propertyId) => {
    // Get the most recent CRM data we have for this property (if any)
    const propertyCrmDataQuery = await firestore
      .collection('property_crm_recommendations')
      .where('propertyId', '==', propertyId)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()

    if (propertyCrmDataQuery.docs.length) {
      const propertyCrmData = propertyCrmDataQuery.docs[0].data()

      this.setState({
        propertyCrmData
      })
    }
  }

  render() {
    const { propertyCrmData } = this.state

    return (
      propertyCrmData && (
        <>
          <StyledH3>Customers to Contact</StyledH3>
          <Table>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(this.state.propertyCrmData.matchedLeads).map(
                (lead) => (
                  <tr key={lead.id}>
                    <th scope='row'>
                      <Link
                        to={`/users/${lead.id}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {lead.firstName} {lead.lastName}
                      </Link>
                    </th>
                    <td>{lead.email}</td>
                    <td>{lead.phone}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </>
      )
    )
  }
}
