import React, { useState, useCallback, useEffect } from 'react'
import { Input } from 'reactstrap'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

const DEBOUNCE_TIMEOUT = 3000

export default function ListingNote({
  isDisabled,
  note: propsNote,
  onUpdate,
  sellerAgentPropertyId,
  style = {}
}) {
  const [note, setNote] = useState(propsNote)

  useEffect(() => {
    if (note !== propsNote) {
      setNote(propsNote)
    }
  }, [propsNote])

  const handleNoteUpdate = useCallback(
    debounce(
      (note) => onUpdate({ note }, sellerAgentPropertyId),
      DEBOUNCE_TIMEOUT
    ),
    [propsNote]
  )

  return (
    <Input
      style={style}
      disabled={isDisabled}
      type='textarea'
      rows={3}
      onChange={(e) => {
        const note = e.target.value
        setNote(note)

        handleNoteUpdate(note)
      }}
      value={note}
    />
  )
}

ListingNote.propTypes = {
  isDisabled: PropTypes.bool,
  note: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  sellerAgentPropertyId: PropTypes.string,
  style: PropTypes.object
}
