import React from 'react'
import { useMutation } from 'urql'
import { UpupInsertAdminDocument } from 'graphql/generated'
import { Input, Button, Modal, ErrorText } from 'components/Toolkit'

type Props = {
  isOpen: boolean
  onCreate: () => void
  onClose: () => void
}

const CreateAdminModal: React.FC<Props> = ({ isOpen, onCreate, onClose }) => {
  const [{ fetching, error }, createAdmin] = useMutation(
    UpupInsertAdminDocument
  )
  const [email, setEmail] = React.useState<string>('')
  const [firstName, setFirstName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')

  const handleCreate = async (): Promise<void> => {
    const result = await createAdmin({ email, firstName, lastName })
    if (result.error === undefined) {
      onCreate()
      setEmail('')
      setFirstName('')
      setLastName('')
    }
  }

  return isOpen ? (
    <Modal hasCloseButton close={onClose}>
      <Input
        value={email}
        type='text'
        onChange={setEmail}
        hasError={false}
        label='email'
      />
      <Input
        value={firstName}
        type='text'
        onChange={setFirstName}
        hasError={false}
        label='first name'
      />
      <Input
        value={lastName}
        type='text'
        onChange={setLastName}
        hasError={false}
        label='last name'
      />
      <Button
        disabled={!email || !firstName || !lastName || fetching}
        onClick={handleCreate}
        isSecondary={false}
      >
        Save
      </Button>
      {error !== undefined && <ErrorText>{error.message}</ErrorText>}
    </Modal>
  ) : null
}

export default CreateAdminModal
