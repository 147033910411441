import { ZILLOW_PROPERTY_PAGE_SLUG } from 'constants/urls'

export const buildZillowUrlFromAddress = (
  address?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null
) => {
  if (address && city && state && zip) {
    const formattedAddress = `${address}-${city}-${state}-${zip}`.replaceAll(
      ' ',
      '-'
    )
    return `${ZILLOW_PROPERTY_PAGE_SLUG}${formattedAddress}_rb/`
  }

  console.warn('could not build zillow link: an address param is empty')
  return ''
}
