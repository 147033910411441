import React, { FunctionComponent } from 'react'
import * as SC from '../StyledComponents'
import { isNoteTimelineEvent } from '../Timeline/types'
import { format, compareDesc } from 'date-fns'
import {
  TimelineEvent,
  NoteTimelineEvent
} from '@homevest/timelines/build/src/utils/types'

type NoteComponentPropTypes = {
  events?: TimelineEvent[]
}

const Note: FunctionComponent<NoteComponentPropTypes> = ({ events }) => {
  return (
    <SC.StyledTable>
      <colgroup>
        <col width={'60%'} />
        <col width={'20%'} />
        <col width={'20%'} />
      </colgroup>
      <SC.StyledTableHeader>
        <tr>
          <SC.StyledTableHeadData>Note</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Date created</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>Posted by</SC.StyledTableHeadData>
        </tr>
      </SC.StyledTableHeader>
      <tbody>
        {events
          ? events
              .filter(isNoteTimelineEvent)
              .sort((a, b) =>
                compareDesc(new Date(a.eventTimeAt), new Date(b.eventTimeAt))
              )
              .map((note: NoteTimelineEvent) => {
                return (
                  <SC.StyledTableRow key={note.eventId}>
                    <SC.StyledTableData>{note.content}</SC.StyledTableData>
                    <SC.StyledTableData>
                      {format(new Date(note.eventTimeAt), 'PPppp')}
                    </SC.StyledTableData>
                    <SC.StyledTableData>
                      {note.createdByAdminName}
                    </SC.StyledTableData>
                  </SC.StyledTableRow>
                )
              })
          : null}
      </tbody>
    </SC.StyledTable>
  )
}

export default Note
