"use strict";
exports.REPORT_TYPES = Object.freeze({
    CREDIT: 'credit_reports',
    EVICTION: 'eviction_reports',
    CRIMINAL: 'criminal_reports'
});
exports.STATUSES = Object.freeze({
    PENDING: 'pending',
    FAILED: 'failed',
    SUCCEEDED: 'succeeded'
});
