import React, { FunctionComponent } from 'react'

import * as SC from '../StyledComponents'

const SortButton: FunctionComponent<{
  setReverse: (reverse: boolean) => void
  reverse: boolean
}> = ({ setReverse, reverse }) => {
  return (
    <SC.ActionButton type='button' onClick={() => setReverse(!reverse)}>
      {reverse ? <SC.DownArrowIcon /> : <SC.UpArrowIcon />}
      Sort
    </SC.ActionButton>
  )
}

export default SortButton
