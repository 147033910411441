import React, { useState } from 'react'

import FullRental from 'types/FullRental'
import { Button, HeaderText } from 'components/Toolkit'
import { Table } from 'reactstrap'
import AddOnRow from './AddOnRow'
import CreateRentalAddOnModal from './CreateRentalAddOnModal'

export default function AddOns({ rental }: { rental: FullRental }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const addOns = rental.rental_add_ons || []

  return (
    <div style={{ marginBottom: '1rem', marginRight: '2rem' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
          Add Ons
        </HeaderText>

        <Button
          style={{ marginBottom: '.5rem' }}
          size='vvs'
          onClick={() => setIsModalOpen(true)}
          isSecondary={false}
        >
          Create Add On
        </Button>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Add On</th>
            <th>Price</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {addOns.map((addOn) => (
            <AddOnRow addOn={addOn} key={addOn.id} />
          ))}
        </tbody>
      </Table>
      <CreateRentalAddOnModal
        rentalId={rental.id}
        occupancyDate={rental.occupancy_date}
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </div>
  )
}
