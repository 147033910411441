import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

export default function SoftOfferSentButton({
  isDisabled,
  onUpdate,
  sellerAgentPropertyId,
  style
}) {
  const handleSoftOfferSent = async () => {
    if (
      !isDisabled &&
      window.confirm('Are you sure you sent the offer? This is irreversible ⚠.')
    ) {
      await onUpdate({ is_soft_offer_sent: true }, sellerAgentPropertyId)
    }
  }
  return (
    <Button
      type='button'
      disabled={isDisabled}
      size='sm'
      color='secondary'
      style={style}
      onClick={handleSoftOfferSent}
    >
      Soft Offer Sent
    </Button>
  )
}

SoftOfferSentButton.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  sellerAgentPropertyId: PropTypes.string,
  style: PropTypes.object
}

SoftOfferSentButton.defaultProps = {
  isDisabled: false,
  onUpdate: () => null,
  style: {}
}
