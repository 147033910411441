import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'

const StyledOptionsContainer = styled.div`
  background: #ffffff;
  border: 1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY};
  box-sizing: border-box;
  border-radius: 10px;
`

const StyledOption = styled.div`
  align-items: center;
  border-bottom: ${(props) =>
    props.isLast ? '0px' : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  display: flex;
  justify-content: space-between
  padding: 16px 22px;
`

const StyledOptionLabel = styled.span`
  color: ${COLORS.PRIMARY.PLUM};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 168.68%;
`

const StyledCircle = styled.div`
  background: ${COLORS.PRIMARY.COCONUT};
  border: ${(props) =>
    props.isSelected
      ? `7px solid ${COLORS.PRIMARY.JELLO}`
      : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  box-sizing: border-box;
  border-radius: 100%;
  height: 25px;
  transition: border 100ms ease-in;
  width: 25px;
  cursor: pointer;
`

function RadioGroup({ label, value, options, onChange }) {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <StyledOptionsContainer>
        {options.map(({ label, value: optionValue }, idx) => (
          <StyledOption
            isLast={idx === options.length - 1}
            key={idx}
            onClick={() => onChange(optionValue)}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '25px' }}>
                <StyledCircle isSelected={value === optionValue} />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <StyledOptionLabel style={{ flex: 1 }}>
                  {label}
                </StyledOptionLabel>
              </div>
            </div>
          </StyledOption>
        ))}
      </StyledOptionsContainer>
    </div>
  )
}

RadioGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default memo(RadioGroup)
