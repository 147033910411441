import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import axios from 'lib/axios'
import { formatMoney } from 'lib/numbers'

import { HeaderText, BodyText, Label } from 'components/Toolkit'

function PaymentSchedule({
  occupancyDate: propsOccupancyDate,
  rent,
  rentalApplicationId
}) {
  const [moveInCalculation, setMoveInCalculation] = useState(null)
  const occupancyDate = moment(propsOccupancyDate)

  useEffect(() => {
    async function run() {
      const { data } = await axios.get(
        `/admin/rental_applications/${rentalApplicationId}/move_in_payment?rent=${rent}&occupancy_date=${occupancyDate.format(
          'YYYY-MM-DD'
        )}`
      )
      setMoveInCalculation(data)
    }
    run()
  }, [])

  if (!moveInCalculation) {
    return null
  }

  return (
    <>
      <div>
        <HeaderText
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem'
          }}
          size='h3'
        >
          <span>Payment Schedule</span>
        </HeaderText>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ flex: 1 }}>
          <Label>Initial Deposit</Label>
          <BodyText style={{ marginTop: 0 }}>
            {formatMoney(moveInCalculation.initial_payment, 2, '$')} - Today
          </BodyText>
        </div>

        <div style={{ flex: 1 }}>
          <Label>Total Move-In Payment</Label>
          <BodyText style={{ marginTop: 0 }}>
            {formatMoney(moveInCalculation.total_move_in_payment, 2, '$')} -{' '}
            {moment(moveInCalculation.charge_date).format('MMMM Do, YYYY')}
          </BodyText>
        </div>

        <div style={{ flex: 1, marginLeft: '1rem' }}>
          <Label>Remaining Deposit</Label>
          <BodyText style={{ marginTop: 0 }}>
            {formatMoney(
              moveInCalculation.initial_option_premium -
                moveInCalculation.initial_payment,
              0,
              '$'
            )}
          </BodyText>
        </div>

        <div style={{ flex: 1, marginLeft: '1rem' }}>
          <Label>Prorated Rent</Label>
          <BodyText style={{ marginTop: 0 }}>
            {formatMoney(moveInCalculation.rent, 2, '$')}
          </BodyText>
        </div>

        {moveInCalculation.next_months_rent > 0 && (
          <div style={{ flex: 1, marginLeft: '1rem' }}>
            <Label>First Full Months&apos; Rent</Label>
            <BodyText style={{ marginTop: 0 }}>
              {formatMoney(moveInCalculation.next_months_rent, 2, '$')}
            </BodyText>
          </div>
        )}

        <div style={{ flex: 1 }}>
          <Label>Move-In Date</Label>
          <BodyText style={{ marginTop: 0 }}>
            {occupancyDate.format('MMMM Do, YYYY')}
          </BodyText>
        </div>
      </div>
    </>
  )
}

PaymentSchedule.propTypes = {
  occupancyDate: PropTypes.string.isRequired,
  rent: PropTypes.number.isRequired,
  rentalApplicationId: PropTypes.string.isRequired
}

export default memo(PaymentSchedule)
