"use strict";
exports.LEASE_END_REASONS = Object.freeze({
    EARLY_TERMINATION: 'early_termination',
    EVICTED: 'evicted',
    LEFT_PROGRAM: 'left_program',
    NEVER_MOVED_IN: 'never_moved_in',
    PURCHASED_HOME: 'purchased_home',
    SWITCHED_HOMES: 'switched_homes'
});
exports.RENTAL_STATUS = Object.freeze({
    ACTIVE: 'active',
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    PENDING: 'pending'
});
