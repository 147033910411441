import * as Sentry from '@sentry/react'
import React from 'react'
import { Provider as UrqlProvider } from 'urql'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Spinner } from 'reactstrap'

import Routes from './components/Routes'
import Navbar from './components/Navbar'

import store, { history } from './store'
import axios from './lib/axios'
import firebase from './lib/firebase'
import createUrqlClient from './lib/urql-client'

store.dispatch({ type: 'BOOTSTRAPPED' })

const getHasuraCreds = async (
  cb: (token: string | null) => void
): Promise<void> => {
  try {
    const response = await axios.post<any, any>('/hasura/auth', {})
    const {
      data: { credentials }
    } = response

    cb(credentials)
  } catch (err: any) {
    if (err.isAxiosError && err.response.status === 401) {
      if (
        window.confirm(
          `We're sorry but your token is no longer valid, do you want to reload and login again?`
        )
      ) {
        window.location.reload()
      }
    } else {
      Sentry.captureException(err)
      cb(null)
    }
  }
}

const App: React.FC = () => {
  const [ready, setReady] = React.useState<boolean>(false)
  const [auth, setAuth] = React.useState<firebase.User | null>(null)
  const [urqlToken, setUrqlToken] = React.useState<string | null>(null)

  const client = React.useMemo(() => {
    setReady(true)
    return createUrqlClient(urqlToken)
  }, [urqlToken])

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        setAuth(user)
      },
      (err) => {
        Sentry.captureException(err)
      }
    )
    return unsubscribe
  }, [])

  React.useEffect(() => {
    if (auth !== null) {
      void getHasuraCreds((token) => setUrqlToken(token))
    } else {
      setUrqlToken(null)
    }
  }, [auth])

  if (!ready) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner color='primary' />
      </div>
    )
  }

  return (
    <UrqlProvider value={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <Navbar />
            <Routes />
          </div>
        </ConnectedRouter>
      </Provider>
    </UrqlProvider>
  )
}

export default App
