import axios from 'axios'

import firebase from './firebase'
import { apiUrl } from './config'

const client = axios.create({
  baseURL: apiUrl
})

function responseInterceptor(res) {
  // Log in development
  // eslint-disable-next-line no-undef
  if (window.location.hostname !== 'hudson.upandup.co') {
    const response = res instanceof Error ? res.response : res

    if (response) {
      console.log(
        '[Axios] %s %s %s',
        response.status,
        response.config.method.toUpperCase(),
        response.config.url
      )
      console.log('[Axios]', response)
    } else if (res.message) {
      console.log('[Axios]', res.message)
      console.log(res)
    }
  }

  return res instanceof Error ? Promise.reject(handleAxiosError(res)) : res
}

client.interceptors.response.use(responseInterceptor, responseInterceptor)

client.interceptors.request.use(async (requestConfig) => {
  // Add authorization header for each request if we have received an admin
  if (!requestConfig.headers.Authorization && firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken()

    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }

    return requestConfig
  } else {
    return requestConfig
  }
})

const handleAxiosError = (errorObject) => {
  if (errorObject.response) {
    // Request made and server responded
    return errorObject.response.data
  } else if (errorObject.request) {
    // The request was made but no response was received
    return errorObject.request
  } else {
    // Something happened in setting up the request that triggered an Error
    return errorObject.message
  }
}

export default client

export const uninterceptedClient = axios.create({
  baseURL: apiUrl
})
