"use strict";
const uuidByString = require('uuid-by-string');
const { v4: uuid } = require('uuid');
const short = require('short-uuid');
const moment = require('moment-timezone');
const { get, merge } = require('lodash');
const translator = short();
const PROVIDERS = Object.freeze({
    HOMEJUNCTION: 'homejunction',
    MANUAL_UPLOAD: 'manual_upload'
});
const getDefaultConfig = (purchasePrice) => ({
    acquisition: {
        purchasePrice,
        improvementCost: 0,
        closingCostPercentage: 0.015
    },
    mortgage: {
        purchasePricePercentage: 0.6,
        interestRate: 0.045
    },
    expenses: {
        propertyTaxRate: 0.0116,
        insuranceRate: 0.0056,
        HOARate: 0,
        capexRate: 0.005
    },
    tenantTerms: {
        initialLeaseTerm: 24
    }
});
exports.activeStatus = 'Active';
exports.inactiveStatus = 'Inactive';
exports.homevestStatus = {
    pendingReview: 'pending_review',
    listed: 'listed',
    // Pre-purchased homes or off-market listings
    internallyListed: 'internally_listed',
    pendingApplication: 'pending_application',
    homevested: 'homevested',
    removed: 'removed',
    reviewerRejected: 'reviewer_rejected',
    demandTest: 'demand_test'
};
// Should not automatically update homevestStatus for
// properties with these statuses
exports.protectedHomevestStatuses = [
    exports.homevestStatus.internallyListed,
    exports.homevestStatus.pendingApplication,
    exports.homevestStatus.homevested,
    exports.homevestStatus.reviewerRejected,
    exports.homevestStatus.demandTest
];
exports.removedStatuses = [
    exports.homevestStatus.reviewerRejected,
    exports.homevestStatus.removed
];
exports.getConfigFromReview = (property) => {
    const review = property.reviews[property.reviews.length - 1];
    const rent = Number(review.homevestRent);
    return {
        acquisition: {
            purchasePrice: Number(review.homevestPurchasePrice),
            improvementCost: Number(review.improvementCost),
            closingCostPercentage: Number(review.closingCostPercentage) / 100
        },
        mortgage: {
            purchasePricePercentage: Number(review.mortgagePurchasePricePercentage) / 100,
            interestRate: Number(review.interestRatePercentage) / 100
        },
        expenses: {
            propertyTaxRate: Number(review.propertyTaxRatePercentage) / 100,
            insuranceRate: Number(review.insuranceRatePercentage) / 100,
            HOARate: Number(review.HOARatePercentage) / 100,
            capexRate: Number(review.capexRatePercentage) / 100
        },
        tenantTerms: {
            rent,
            initialLeaseTerm: Number(review.initialLeaseTerm)
        }
    };
};
exports.createOperationsProperty = (operationsProperty, propertyOverrides = {}) => {
    const property = exports.formatOperationsProperty(operationsProperty, propertyOverrides);
    if (!get(operationsProperty, 'address.stdAddress')) {
        throw new Error('Address standardization is required for a dark listing!');
    }
    property.id = uuidByString(operationsProperty.address.stdAddress);
    property.shortId = translator.fromUUID(property.id);
    property.listPriceHistory = [exports.formatListPriceHistoryItem(property)];
    property.homevestStatusHistory = [
        exports.formatHomevestStatusHistoryItem(property)
    ];
    property.statusHistory = [exports.formatStatusHistoryItem(property)];
    return property;
};
exports.createHomejunctionProperty = (homejunctionProperty, propertyOverrides = {}) => {
    const property = exports.formatHomejunctionProperty(homejunctionProperty, propertyOverrides);
    property.id = uuid();
    property.shortId = translator.fromUUID(property.id);
    property.listPriceHistory = [exports.formatListPriceHistoryItem(property)];
    property.homevestStatusHistory = [
        exports.formatHomevestStatusHistoryItem(property)
    ];
    property.statusHistory = [exports.formatStatusHistoryItem(property)];
    return property;
};
// Has an admin selected an status override before our current time?
// If so we replace the status with the override
exports.overrideNewPropertyStatus = (previousProperty, newProperty) => {
    if (!previousProperty) {
        throw new Error('A previousProperty is required!');
    }
    if (!newProperty) {
        throw new Error('A newProperty is required!');
    }
    const { statusOverride, statusOverrideUntil } = previousProperty;
    newProperty = Object.assign({}, newProperty);
    if (statusOverride &&
        statusOverrideUntil &&
        moment(new Date(statusOverrideUntil.seconds * 1000)).isAfter(new Date())) {
        newProperty.status = statusOverride;
    }
    return newProperty;
};
exports.updateHomejunctionProperty = (previousProperty, homejunctionProperty, propertyOverrides = {}) => {
    return exports.updateProperty(previousProperty, homejunctionProperty, PROVIDERS.HOMEJUNCTION, propertyOverrides);
};
/**
 * Determine if the listing from a provider is allowed.
 *
 * @param {Object} listing - The listing object from the provider.
 * @param {Object} provider - The provider ID.
 *
 */
exports.isListingAllowed = (providerListing, provider) => {
    if (provider !== PROVIDERS.HOMEJUNCTION) {
        return true;
    }
    // HJ Specific rule, flush this out better when we have more restrictions.
    // Withheld addresses
    // https://slipstream.homejunction.com/#/ws/responses?id=withheld-address
    if (!get(providerListing, 'address.deliveryLine')) {
        console.warn('No deliveryLine passed, this is a withheld listing and will not be processed.');
        return false;
    }
    else {
        return true;
    }
};
exports.castProviderId = (listing, provider) => {
    if ([PROVIDERS.HOMEJUNCTION, PROVIDERS.MANUAL_UPLOAD].includes(provider)) {
        return listing.id;
    }
    else {
        throw new Error('Unknown provider passed to update MLS!');
    }
};
exports.createPropertyFromListing = (providerListing, provider) => {
    let newProperty;
    if (provider === PROVIDERS.HOMEJUNCTION) {
        newProperty = exports.createHomejunctionProperty(providerListing);
    }
    else if (provider === PROVIDERS.MANUAL_UPLOAD) {
        newProperty = exports.createOperationsProperty(providerListing);
    }
    else {
        throw new Error('Unknown provider passed to update MLS!');
    }
    return newProperty;
};
exports.updateProperty = (previousProperty, property, provider, propertyOverrides = {}) => {
    // If the property has been reviewed, default property overrides to
    // be from the review
    if (get(previousProperty, 'reviews.length')) {
        const configFromReview = exports.getConfigFromReview(previousProperty);
        propertyOverrides = merge({
            rent: configFromReview.tenantTerms.rent,
            config: configFromReview
        }, propertyOverrides);
    }
    // Pass homevestStatus as an override bc current homevestStatus
    // can inform future homevestStatus
    propertyOverrides.homevestStatus = previousProperty.homevestStatus;
    const initialNewProperty = exports.overrideNewPropertyStatus(previousProperty, property);
    let formattedProperty;
    if (provider === PROVIDERS.HOMEJUNCTION) {
        formattedProperty = exports.formatHomejunctionProperty(initialNewProperty, propertyOverrides);
    }
    else if (provider === PROVIDERS.MANUAL_UPLOAD) {
        formattedProperty = exports.formatOperationsProperty(initialNewProperty, propertyOverrides);
    }
    else {
        throw new Error(`Unknown provider ${provider} to update property!`);
    }
    const newProperty = Object.assign({}, previousProperty, formattedProperty);
    if (previousProperty.images && newProperty.images) {
        newProperty.images = exports.getImages(previousProperty, newProperty);
    }
    // Update the listPriceHistory if the listPrice has changed
    if (previousProperty.listPrice !== newProperty.listPrice) {
        newProperty.listPriceHistory = [
            exports.formatListPriceHistoryItem(newProperty)
        ].concat(previousProperty.listPriceHistory || []);
    }
    if (previousProperty.status !== newProperty.status) {
        newProperty.statusHistory = [
            exports.formatStatusHistoryItem(newProperty)
        ].concat(newProperty.statusHistory || []);
    }
    if (previousProperty.homevestStatus !== newProperty.homevestStatus) {
        newProperty.homevestStatusHistory = [
            exports.formatHomevestStatusHistoryItem(newProperty)
        ].concat(newProperty.homevestStatusHistory || []);
    }
    return newProperty;
};
/**
 * This is a temp fix to prevent updates to properties where we have uploaded images
 */
exports.getImages = (previousProperty, newProperty) => {
    const hasUploadedImages = get(previousProperty, 'images[0]', '').startsWith('https://storage');
    return hasUploadedImages ? previousProperty.images : newProperty.images;
};
exports.formatOperationsProperty = (homejunctionProperty) => {
    const formattedProperty = Object.assign({}, homejunctionProperty);
    formattedProperty.homevestStatus =
        formattedProperty.homevestStatus || exports.homevestStatus.pendingReview;
    // Set provider id
    const providerId = get(homejunctionProperty, 'address.stdAddress');
    if (!providerId) {
        throw new Error('Manual upload properties require a standardized address as provider ID!');
    }
    formattedProperty.provider = 'manual_upload';
    formattedProperty.systemId = providerId;
    formattedProperty.providerId = providerId;
    // Add display lines to address
    const [displayLine1, displayLine2] = exports.getDisplayLine(homejunctionProperty.address);
    formattedProperty.address.displayLine1 = displayLine1;
    formattedProperty.address.displayLine2 = displayLine2;
    // Add weighted total to baths
    // This should be refactored
    if (typeof formattedProperty.baths === 'object') {
        formattedProperty.baths.weightedTotal =
            (formattedProperty.baths.full || 0) +
                (formattedProperty.baths.half || 0) / 2;
    }
    return formattedProperty;
};
exports.formatHomejunctionProperty = (homejunctionProperty, propertyOverrides = {}) => {
    const formattedProperty = Object.assign({}, homejunctionProperty);
    formattedProperty.provider = 'homejunction';
    formattedProperty.providerId = homejunctionProperty.id;
    // Add weighted total to baths
    if (typeof formattedProperty.baths === 'object') {
        formattedProperty.baths.weightedTotal =
            (formattedProperty.baths.full || 0) +
                (formattedProperty.baths.half || 0) / 2;
    }
    // Add display lines to address
    const [displayLine1, displayLine2] = exports.getDisplayLine(homejunctionProperty.address);
    formattedProperty.address.displayLine1 = displayLine1;
    formattedProperty.address.displayLine2 = displayLine2;
    delete formattedProperty.id;
    delete formattedProperty.daysOnHJI;
    delete formattedProperty.daysOnMarket;
    merge(formattedProperty, propertyOverrides);
    if (propertyOverrides.config) {
        formattedProperty.config = merge(getDefaultConfig(formattedProperty.listPrice), propertyOverrides.config);
    }
    if (get(formattedProperty, 'listingAgent.id')) {
        formattedProperty.listingAgent.id = String(formattedProperty.listingAgent.id).toUpperCase();
    }
    if (get(formattedProperty, 'coListingAgent.id')) {
        formattedProperty.coListingAgent.id = String(formattedProperty.coListingAgent.id).toUpperCase();
    }
    formattedProperty.homevestStatus =
        exports.getHomevestStatus(formattedProperty);
    return formattedProperty;
};
exports.getDisplayLine = (address) => {
    const displayLine1 = address.deliveryLine || address.street;
    let displayLine2 = '';
    if (address.city) {
        displayLine2 += `${address.city}, `;
    }
    if (address.state) {
        displayLine2 += `${address.state}`;
    }
    if (address.zip) {
        if (address.state)
            displayLine2 += ' ';
        displayLine2 += address.zip;
    }
    return [displayLine1, displayLine2];
};
exports.getHomevestStatus = (property) => {
    if (property.provider === 'homejunction') {
        return getHomejunctionPropertyHomevestStatus(property);
    }
    else {
        throw new Error(`Unknown provider: ${property.provider}`);
    }
};
const getHomejunctionPropertyHomevestStatus = (property) => {
    // Don't change protected statuses
    if (exports.protectedHomevestStatuses.includes(property.homevestStatus)) {
        return property.homevestStatus;
    }
    else if (!property.inBuyBox && !get(property, 'reviews.length')) {
        return exports.homevestStatus.removed;
    }
    else if (property.status !== exports.activeStatus &&
        (property.homevestStatus === exports.homevestStatus.listed ||
            property.homevestStatus === exports.homevestStatus.pendingReview)) {
        return exports.homevestStatus.removed;
        // If the property is active, update according to required listing criteria
    }
    else if (property.status === exports.activeStatus) {
        return property.rent && property.config && get(property, 'images.length')
            ? exports.homevestStatus.listed
            : exports.homevestStatus.pendingReview;
    }
    else {
        return property.homevestStatus;
    }
};
exports.formatStatusHistoryItem = (property) => {
    return {
        status: property.status,
        dataPulledAt: new Date()
    };
};
exports.formatHomevestStatusHistoryItem = (property) => {
    return {
        homevestStatus: property.homevestStatus,
        dataPulledAt: new Date()
    };
};
exports.formatListPriceHistoryItem = (property) => {
    const history = {
        listPrice: property.listPrice,
        dataPulledAt: new Date()
    };
    if (property.listingAgent) {
        history.listingAgent = property.listingAgent;
    }
    if (property.listingOffice) {
        history.listingOffice = property.listingOffice;
    }
    return history;
};
exports.generateUrlForMlsListing = (mlsListing) => {
    if (!mlsListing.region || !mlsListing.id) {
        throw new Error('A region and ID are required for a URL!');
    }
    return `https://app.upandup.co/homes/${encodeURIComponent(mlsListing.region)}/${encodeURIComponent(mlsListing.id)}`;
};
exports.footprintZips = [
    '63116',
    '63109',
    '63139',
    '63143',
    '63119',
    '63132',
    '63130',
    '63123',
    '63111',
    '63117',
    '63144',
    '63110'
];
