import React, { useState } from 'react'
import { useSubscription } from 'urql'
import { Spinner } from 'reactstrap'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import startCase from 'lodash/startCase'

import { HeaderText, Button } from 'components/Toolkit'
import Notes from 'components/Notes'
import Ledger from './Ledger'
import Tenants from './Tenants'
import Documents from './Documents'
import FinalLiabilityDateModal from './FinalLiabilityDateModal'
import MoveOutDateModal from './MoveOutDateModal'
import FullRental from 'types/FullRental'
import RentalAgreementHistories from './RentalAgreementHistories'
import MoveInPayment from './MoveInPayment'
import LineItemsOwed from './LineItemsOwed'
import AddOns from './AddOns'
import BankAccount from './BankAccount'
import {
  UpupRentalByIdSubscription,
  UpupRentalByIdDocument
} from 'graphql/generated'
import { formatMoney } from 'lib/numbers'
import { getLlc, convertRentalToFullRental } from 'lib/rentals'
import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'

import NoteWidgetOption from 'types/NoteWidgetOption'

const {
  LEDGER_FINAL_LIABILITY_DATE_EDITOR,
  LEDGER_FINAL_LIABILITY_DATE_SETTER,
  LEDGER_MOVE_OUT_DATE_EDITOR,
  LEDGER_MOVE_OUT_DATE_SETTER,
  LEDGER_VIEWER
} = capabilities.CAPABILITY_TYPES

function RentalView({ id }: { id: string }) {
  const [isFinalLiabilityDateModalOpen, setIsFinalLiabilityDateModalOpen] =
    useState(false)
  const [isMoveOutDateModalOpen, setIsMoveOutDateModalOpen] = useState(false)
  const [{ data }] = useSubscription<UpupRentalByIdSubscription>({
    query: UpupRentalByIdDocument,
    variables: { id }
  })
  const history = useHistory()

  const admin = useSelector<StoreState, any>((state) => state.admin)

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)
  const canEditFinalLiabilityDate = hasCapability(
    admin,
    LEDGER_FINAL_LIABILITY_DATE_EDITOR
  )
  const canSetFinalLiabilityDate = hasCapability(
    admin,
    LEDGER_FINAL_LIABILITY_DATE_SETTER
  )
  const canEditMoveOutDate = hasCapability(admin, LEDGER_MOVE_OUT_DATE_EDITOR)
  const canSetMoveOutDate = hasCapability(admin, LEDGER_MOVE_OUT_DATE_SETTER)

  if (!canViewPage) {
    history.push('/review')
    return null
  }

  // TODO: add loading spinner

  if (!data || !data.rentals || !data.rentals.length) {
    return (
      <div>
        <Link to={{ pathname: '/rent-roll' }}>Back to Rent Roll</Link>
      </div>
    )
  }

  const rental: FullRental = convertRentalToFullRental(data.rentals[0] as any)

  const {
    address,
    final_liability_date: finalLiabilityDate,
    lease_end_reason: leaseEndReason,
    move_out_date: moveOutDate,
    occupancy_date: occupancyDate,
    rent,
    optionPremium
  } = rental

  const llc = getLlc(rental)

  const infoLabel = `${formatMoney(rent, 0, '$')} rent | ${formatMoney(
    optionPremium,
    0,
    '$'
  )} investment`

  const liabilityDateInfo = finalLiabilityDate
    ? `Final liability date: ${moment(finalLiabilityDate).format('YYYY-MM-DD')}`
    : ''

  const moveOutDateInfo = moveOutDate
    ? `Move out date: ${moment(moveOutDate).format('YYYY-MM-DD')}`
    : ''

  const canUpdateFinalLiabilityDate =
    (finalLiabilityDate && canEditFinalLiabilityDate) ||
    (!finalLiabilityDate && canSetFinalLiabilityDate)
  const canUpdateMoveOutDate =
    (moveOutDate && canEditMoveOutDate) || (!moveOutDate && canSetMoveOutDate)

  const resourceNoteWidgetOptions: NoteWidgetOption[] = []

  const rentalOption: NoteWidgetOption = {
    id: rental.id,
    type: 'rentals',
    writable: true,
    displayName:
      'Rental for ' + rental.rental_application.mls_listing.display_line_1
  }
  resourceNoteWidgetOptions.push(rentalOption)

  return (
    <div>
      <Link to={{ pathname: '/rent-roll' }}>Back to Rent Roll</Link>
      <HeaderText size='h3' style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        {address}
      </HeaderText>
      <HeaderText size='h4'>
        {llc?.name || 'Need to backfill llc_property'}
      </HeaderText>
      <HeaderText size='h4'>{infoLabel}</HeaderText>
      <HeaderText size='h4'>Occupancy date: {occupancyDate}</HeaderText>
      <HeaderText size='h4'>
        {liabilityDateInfo}
        {canUpdateFinalLiabilityDate && (
          <Button
            isSecondary={false}
            size='vvs'
            style={finalLiabilityDate ? { marginLeft: '1rem' } : {}}
            onClick={() => setIsFinalLiabilityDateModalOpen(true)}
          >
            {finalLiabilityDate ? 'Edit' : 'Set Final Liability Date'}
          </Button>
        )}
      </HeaderText>
      <HeaderText size='h4'>
        {moveOutDateInfo}
        {canUpdateMoveOutDate && (
          <Button
            isSecondary={false}
            size='vvs'
            style={moveOutDate ? { marginLeft: '1rem' } : {}}
            onClick={() => setIsMoveOutDateModalOpen(true)}
          >
            {moveOutDate ? 'Edit' : 'Set Move Out Date'}
          </Button>
        )}
      </HeaderText>
      {leaseEndReason && (
        <HeaderText size='h4'>{startCase(leaseEndReason)}</HeaderText>
      )}
      <div style={{ display: 'flex', marginTop: '2rem', flexWrap: 'wrap' }}>
        <div style={{ flex: 2 }}>
          <BankAccount rental={rental} />
          <LineItemsOwed rental={rental} />
          <MoveInPayment rental={rental} />
          <AddOns rental={rental} />
          <Tenants rental={rental} />
          <RentalAgreementHistories rental={rental} />
          <Documents rental={rental} />
          <div style={{ marginRight: '1rem' }}>
            <Notes resourceNoteWidgetOptions={resourceNoteWidgetOptions} />
          </div>
        </div>
        <div style={{ flex: 3 }}>
          <Ledger rental={rental} />
        </div>
      </div>
      {canUpdateFinalLiabilityDate && (
        <FinalLiabilityDateModal
          rental={rental}
          close={() => setIsFinalLiabilityDateModalOpen(false)}
          isOpen={isFinalLiabilityDateModalOpen}
        />
      )}
      {canUpdateMoveOutDate && (
        <MoveOutDateModal
          rental={rental}
          close={() => setIsMoveOutDateModalOpen(false)}
          isOpen={isMoveOutDateModalOpen}
        />
      )}
    </div>
  )
}

function RentalDetails() {
  const { rentalId } = useParams<{ rentalId: string }>()

  return (
    <div style={{ margin: '0 1rem' }}>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <RentalView id={rentalId} />
      </React.Suspense>
    </div>
  )
}

export default RentalDetails
