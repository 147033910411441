import {
  createStore,
  applyMiddleware,
  compose,
  StoreEnhancer,
  AnyAction
} from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

import createRootReducer from './state'
import firebaseUsersWorker from './workers/firebase-users'
import authWorker from './workers/auth'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer
  }
}

export const history = createBrowserHistory()

const RootReducer = createRootReducer(history)
export type StoreState = ReturnType<typeof RootReducer>
export type AppDispatch = typeof store.dispatch
export type TypedDispatch = ThunkDispatch<StoreState, any, AnyAction>

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const initialState = {}
const enhancers = [sentryReduxEnhancer]
const middleware = [
  thunk,
  authWorker,
  firebaseUsersWorker,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(RootReducer, initialState, composedEnhancers)

export default store
