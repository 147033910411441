import {
  MATCH_FAILURE_REASONS,
  MODAL_CLOSED,
  MODAL_ERROR,
  ATTEMPTING_TO_BOOK_TOUR,
  NEED_CUSTOMER_AVAILABILITY,
  NEED_AGENT_AVAILABILITY
} from 'constants/dispatch'

export const RECEIVED_MARKETS = 'DISPATCH/RECEIVED_MARKETS'
export const RECEIVED_POTENTIAL_TOURS = 'DISPATCH/RECEIVED_POTENTIAL_TOURS'
export const MARKET_SELECTED = 'DISPATCH/MARKET_SELECTED'
export const SET_MODAL_STATE = 'DISPATCH/SET_MODAL_STATE'
export const TOUR_TO_BOOK_RECEIVED = 'DISPATCH/TOUR_TO_BOOK_RECEIVED'
export const TOUR_BOOKED = 'DISPATCH/TOUR_BOOKED'
export const TOUR_SKIPPED = 'DISPATCH/TOUR_SKIPPED'
export const INDICATED_SHOWINGTIME_UNAVAILABLE =
  'DISPATCH/INDICATED_SHOWINGTIME_UNAVAILABLE'
export const SHOWINGTIME_UNAVAILABILITY_CHANGED =
  'DISPATCH/SHOWINGTIME_UNAVAILABILITY_CHANGED'
export const SHOWINGTIME_UNAVAILABILITY_ROW_ADDED =
  'DISPATCH/SHOWINGTIME_UNAVAILABILITY_ROW_ADDED'
export const SHOWINGTIME_UNAVAILABILITY_CONFIRMED =
  'DISPATCH/SHOWINGTIME_UNAVAILABILITY_CONFIRMED'
export const RECEIVED_TASK_TRIGGER_REQUEST =
  'DISPATCH/RECEIVED_TASK_TRIGGER_REQUEST'
export const TASK_TRIGGERED = 'DISPATCH/TASK_TRIGGERED'
export const TRIGGER_TASK_DECLINED = 'DISPATCH/TRIGGER_TASK_DECLINED'

export const receivedMarkets = (markets) => ({
  type: RECEIVED_MARKETS,
  markets
})

export const receivedPotentialTours = (potentialTours) => ({
  type: RECEIVED_POTENTIAL_TOURS,
  potentialTours
})

export const marketSelected = (selectedMarket) => ({
  type: MARKET_SELECTED,
  selectedMarket
})

export const setModalState = (newModalState) => ({
  type: SET_MODAL_STATE,
  modalState: newModalState
})

export const tourToBookReceived = (tourToBook) => {
  const { is_tour_schedulable: isTourSchedulable } = tourToBook
  if (isTourSchedulable) {
    return {
      type: TOUR_TO_BOOK_RECEIVED,
      modalState: ATTEMPTING_TO_BOOK_TOUR,
      tourToBook
    }
  }

  const { unable_to_schedule_reason: unableToScheduleReason } = tourToBook

  let modalState
  switch (unableToScheduleReason) {
    case MATCH_FAILURE_REASONS.NEED_CUSTOMER_AVAILABILITY:
      modalState = NEED_CUSTOMER_AVAILABILITY
      break
    case MATCH_FAILURE_REASONS.NEED_AGENT_AVAILABILITY:
      modalState = NEED_AGENT_AVAILABILITY
      break
    case MATCH_FAILURE_REASONS.ALL_TOURS_SCHEDULED:
      modalState = MODAL_CLOSED
      break
    default:
      modalState = MODAL_ERROR
  }

  return {
    type: TOUR_TO_BOOK_RECEIVED,
    modalState,
    tourToBook
  }
}

export const tourSkipped = () => ({
  type: TOUR_SKIPPED
})

export const tourBooked = () => ({
  type: TOUR_BOOKED
})

export const indicatedShowingtimeUnavailable = () => ({
  type: INDICATED_SHOWINGTIME_UNAVAILABLE
})

export const showingtimeUnavailabilityChanged = (
  inputField,
  propertyId,
  rowIdx,
  value
) => ({
  type: SHOWINGTIME_UNAVAILABILITY_CHANGED,
  inputField,
  propertyId,
  rowIdx,
  value
})

export const showingtimeUnavailabilityRowAdded = (propertyId) => ({
  type: SHOWINGTIME_UNAVAILABILITY_ROW_ADDED,
  propertyId
})

export const showingtimeUnavailabilityConfirmed = (
  mergedUnavailabilities,
  additionalNotes
) => ({
  type: SHOWINGTIME_UNAVAILABILITY_CONFIRMED,
  mergedUnavailabilities,
  additionalNotes
})

export const receivedTaskTriggerRequest = (requestTarget) => ({
  type: RECEIVED_TASK_TRIGGER_REQUEST,
  requestTarget
})

export const taskTriggered = () => ({
  type: TASK_TRIGGERED
})
