import React, { FunctionComponent, useEffect, useState } from 'react'

import * as SC from '../StyledComponents'

import axios from 'lib/axios'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'

import { Rentals } from 'graphql/generated'

import ChargeModal from 'screens/Users/ChargeModal'

import { hasCapability } from 'lib/admin-perms'
import { capabilities } from '@homevest/utils'
const { CUSTOMER_CHARGER } = capabilities.CAPABILITY_TYPES

const ChargeUser: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>()
  const admin = useSelector((store: StoreState) => store.admin)

  const [isChargingAllowed, setIsChargingAllowed] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [user, setUser] = useState({ has_payment_source: null })
  const [activeRental, setActiveRental] = useState<Rentals | null>(null)

  useEffect(() => {
    setIsChargingAllowed(hasCapability(admin, CUSTOMER_CHARGER))
  }, [admin])

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await axios.get(`/admin/users/${userId}`)
        setUser(data)
      } catch (err) {
        throw err
      }
    }

    const getActiveRentals = async () => {
      try {
        const { data } = await axios.get(`/users/${userId}`)
        const { rentals } = data
        const activeRental = rentals.find(
          (rental: Rentals) => rental.status === 'active'
        )
        setActiveRental(activeRental)
      } catch (err) {
        throw err
      }
    }

    getUser()
    getActiveRentals()
  }, [userId])

  return isChargingAllowed && user.has_payment_source && activeRental ? (
    <>
      <SC.DropDownMenuItem onClick={() => setIsModalOpen(!isModalOpen)}>
        Charge
      </SC.DropDownMenuItem>
      <ChargeModal
        user={user}
        isOpen={isModalOpen}
        onChargeSuccess={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  ) : null
}

export default ChargeUser
