import React, { FunctionComponent } from 'react'
import {
  CalendarIcon,
  CurrencyDollarIcon,
  MapPinIcon
} from '@heroicons/react/20/solid'
import { format } from 'date-fns'
import { PropertyHeaderFragment } from 'graphql/generated'

interface PropertyHeaderProps {
  property: PropertyHeaderFragment
}
const PropertyHeader: FunctionComponent<PropertyHeaderProps> = ({
  property
}) => {
  return (
    <div className='lg:flex lg:items-center lg:justify-between'>
      <div className='min-w-0 flex-1'>
        <h2 className='mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
          {property.real_estate_acquisition?.mls_listing?.addressLineOne}
        </h2>
        <div>
          {property.real_estate_acquisition?.mls_listing?.addressLineTwo}
        </div>
        <div className='mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6'>
          <div className='mt-2 flex items-center text-sm text-gray-500'>
            <MapPinIcon
              className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            {property.real_estate_acquisition?.mls_listing.city}
          </div>
          <div className='mt-2 flex items-center text-sm text-gray-500'>
            <CurrencyDollarIcon
              className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            {property.real_estate_acquisition?.closingPrice}
          </div>
          <div className='mt-2 flex items-center text-sm text-gray-500'>
            <CalendarIcon
              className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
              aria-hidden='true'
            />
            Closed on{' '}
            {format(
              new Date(property.real_estate_acquisition?.closing_date),
              'PP'
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyHeader
