import React, { FunctionComponent } from 'react'

interface CheckboxListProps {
  options: {
    label: string
    checked: boolean
    value: string | null | undefined
  }[]
  onOptionClicked: (value: string | null | undefined, checked: boolean) => void
}

export const CheckboxList: FunctionComponent<CheckboxListProps> = ({
  options,
  onOptionClicked
}) => {
  return (
    <fieldset className='space-y-5'>
      {options.map((option) => {
        return (
          <div key={option.label} className='relative flex items-start'>
            <div className='flex h-5 items-center'>
              <input
                id={option.label}
                name={option.label}
                onChange={(evt) => {
                  onOptionClicked(option.value, evt.target.checked)
                }}
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
              />
            </div>
            <div className='ml-3 text-sm'>
              <label
                htmlFor={option.label}
                className='font-medium text-gray-700'
              >
                {option.label}
              </label>
            </div>
          </div>
        )
      })}
    </fieldset>
  )
}
