// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React, { useState } from 'react'
import { Table, UncontrolledTooltip } from 'reactstrap'
import startCase from 'lodash/startCase'
import { CSVLink } from 'react-csv'
import { useHistory, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { capabilities, payments as PAYMENTS } from '@homevest/utils'

import { Button, HeaderText } from 'components/Toolkit'
import { formatMoney } from 'lib/numbers'
import { getLedgerItemsWithBalance } from 'lib/ledger'
import { getAddress, convertRentalToCsvLedgerData } from 'lib/rentals'
import { hasCapability } from 'lib/admin-perms'
import CreateRentalLiabilityModal from './CreateRentalLiabilityModal'
import IssueCreditModal from './IssueCreditModal'
import IssuePaymentRefundModal from './IssuePaymentRefundModal'
import FullRental from 'types/FullRental'
import { StoreState } from 'store'
import { RefundInfoType } from '../types'

const {
  LEDGER_LIABILITY_CREATOR,
  LEDGER_RENT_CREDIT_ISSUER,
  LEDGER_VIEWER,
  PAYMENT_REFUNDER
} = capabilities.CAPABILITY_TYPES

const { PAYMENT_LIFECYCLE, PAYMENT_STATUSES } = PAYMENTS

export default function Ledger({ rental }: { rental: FullRental }) {
  const [
    isCreateRentalLiabilityModalOpen,
    setIsCreateRentalLiabilityModalOpen
  ] = useState(false)
  const [isIssueCreditModalOpen, setIsIssueCreditModalOpen] = useState(false)
  const admin = useSelector((state: StoreState) => state.admin)
  const [isRefundPaymentModalOpen, setIsRefundPaymentModalOpen] =
    useState(false)
  const [refundInfo, setRefundInfo] = useState<RefundInfoType>()
  const history = useHistory()

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)
  const canIssueRentCredits = hasCapability(admin, LEDGER_RENT_CREDIT_ISSUER)
  const canCreateLiabilities = hasCapability(admin, LEDGER_LIABILITY_CREATOR)
  const canRefund = hasCapability(admin, PAYMENT_REFUNDER)

  if (!canViewPage) {
    history.push('/review')
    return null
  }

  const {
    id: rentalId,
    payments = [],
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = []
  } = rental

  const { ledgerDataWithBalances, balance, investmentToDate, rentPaidToDate } =
    getLedgerItemsWithBalance(payments, rentalLiabilities, rentalCredits)

  const address = getAddress(rental)

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
          Amount owed: {formatMoney(balance, 2, '$')}
        </HeaderText>
        <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
          Rent paid: {formatMoney(rentPaidToDate, 2, '$')}
        </HeaderText>
        <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
          Wallet Contribution: {formatMoney(investmentToDate, 2, '$')}
        </HeaderText>
        <>
          {canIssueRentCredits && (
            <Button
              style={{ marginBottom: '.5rem' }}
              size='vvs'
              isSecondary={false}
              onClick={() => setIsIssueCreditModalOpen(true)}
            >
              + Issue Credit
            </Button>
          )}
          {canCreateLiabilities && (
            <Button
              style={{ marginBottom: '.5rem' }}
              size='vvs'
              isSecondary={false}
              onClick={() => setIsCreateRentalLiabilityModalOpen(true)}
            >
              + Create Liability
            </Button>
          )}
        </>
        <Button
          size='vvs'
          isSecondary={false}
          style={{ color: 'white', marginBottom: '.5rem' }}
        >
          <CSVLink
            style={{ color: 'white', textDecoration: 'none' }}
            data={convertRentalToCsvLedgerData(rental, true)}
            filename={`${address}-ledger-${new Date().getTime()}.csv`}
          >
            Download Ledger
          </CSVLink>
        </Button>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Charge Date</th>
            <th>Available on</th>
            <th>Amount</th>
            {canRefund && <th>Refund Payment</th>}
            <th>Type</th>
            <th>Category</th>
            <th>Paid by</th>
            <th>Status</th>
            <th>Payment Method</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {ledgerDataWithBalances.map(
            ({
              id,
              user,
              amount,
              availableOn,
              balance,
              date,
              paymentMethod,
              liabilityLabel,
              type,
              status,
              lifecycle,
              initiatedAt
            }) => (
              <tr
                key={id}
                className={
                  [
                    PAYMENT_STATUSES.FAILED,
                    PAYMENT_STATUSES.NSF_FAILED
                  ].includes(status)
                    ? 'alert-danger'
                    : ''
                }
              >
                <td>{date}</td>
                <td>{availableOn}</td>
                <td>{formatMoney(amount, 2, '$')}</td>
                {canRefund && (
                  <td>
                    {type === 'Payment' && status === 'succeeded' && (
                      <Button
                        style={{ marginBottom: '.5rem' }}
                        size='vvs'
                        isSecondary={false}
                        onClick={() => {
                          setRefundInfo({
                            amount,
                            address,
                            date,
                            paymentId: id
                          })
                          setIsRefundPaymentModalOpen(true)
                        }}
                      >
                        Refund Payment
                      </Button>
                    )}
                  </td>
                )}
                <td>{startCase(type)}</td>
                <td>{startCase(liabilityLabel)}</td>
                <td>
                  {user && (
                    <Link
                      to={{ pathname: `/users/${user?.id}` }}
                      target='_blank'
                    >
                      {user?.first_name} {user?.last_name}
                    </Link>
                  )}
                </td>
                <td>
                  <span
                    style={{
                      textDecoration: isInitiallyCleared(status, lifecycle)
                        ? 'underline'
                        : 'none'
                    }}
                    id={`status-ic-${id}`}
                  >
                    {startCase(
                      isInitiallyCleared(status, lifecycle) ? lifecycle : status
                    )}
                  </span>
                  {isInitiallyCleared(status, lifecycle) && (
                    <UncontrolledTooltip
                      placement='top'
                      target={`status-ic-${id}`}
                    >
                      <div>
                        We have a high degree of certainty that this payment
                        succeeded.
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>
                        Status:&nbsp;<b>{startCase(status)}</b>
                      </div>
                      {Boolean(initiatedAt) && (
                        <div>
                          Initiated:&nbsp;
                          <b>{new Date(initiatedAt).toString()}</b>
                        </div>
                      )}
                    </UncontrolledTooltip>
                  )}
                </td>
                <td>{paymentMethod?.toUpperCase() ?? '-'}</td>
                <td>{formatMoney(balance, 2, '$')}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
      <>
        {canCreateLiabilities && (
          <CreateRentalLiabilityModal
            rentalId={rentalId}
            occupancyDate={rental.occupancy_date}
            close={() => setIsCreateRentalLiabilityModalOpen(false)}
            isOpen={isCreateRentalLiabilityModalOpen}
          />
        )}
        {canIssueRentCredits && (
          <IssueCreditModal
            rentalId={rentalId}
            close={() => setIsIssueCreditModalOpen(false)}
            isOpen={isIssueCreditModalOpen}
          />
        )}
        {canRefund && (
          <IssuePaymentRefundModal
            close={() => setIsRefundPaymentModalOpen(false)}
            isOpen={isRefundPaymentModalOpen}
            refundInfo={refundInfo}
          />
        )}
      </>
    </>
  )
}

const isInitiallyCleared = (status: string, lifecycle: string) =>
  [PAYMENT_STATUSES.PENDING, PAYMENT_STATUSES.PROCESSING].includes(status) &&
  lifecycle === PAYMENT_LIFECYCLE.INITIALLY_CLEARED
