import React from 'react'
import { get } from 'lodash'

import { BodyText, Label } from 'components/Toolkit'
import ReportLink from '../ReportLink'

export default function EvictionReportDetails({ evictionReport }) {
  const evictionReportDocumentId = get(evictionReport, 'document.id', null)

  if (!evictionReport) {
    return <BodyText style={{ marginTop: '.5rem' }}>No report run</BodyText>
  }

  if (evictionReportDocumentId) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <ReportLink
          documentId={evictionReportDocumentId}
          reportName='Eviction Report'
          style={{ flex: 1 }}
        />
      </div>
    )
  }

  const matches = get(evictionReport, 'matches', null)

  if (!matches || !matches.length) {
    return (
      <BodyText style={{ marginTop: '.5rem' }}>No matches found ✅</BodyText>
    )
  }

  return matches.map((match, idx) => {
    const {
      activity: {
        filingDate,
        judgement,
        judgementAmount,
        judgementDate,
        noticeType
      }
    } = match

    return (
      <div
        key={idx}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          borderBottom:
            idx === matches.length - 1 ? '' : '1px solid rgb(204, 204, 204)'
        }}
      >
        <div style={{ flex: '1 0 33%' }}>
          <Label>Judgement Date</Label>
          <BodyText style={{ marginTop: 0 }}>
            {judgementDate || 'Not reported'}
          </BodyText>
        </div>
        <div style={{ flex: '1 0 33%' }}>
          <Label>Judgement</Label>
          <BodyText style={{ marginTop: 0 }}>
            {judgement || 'Not reported'}
          </BodyText>
        </div>
        <div style={{ flex: '1 0 33%' }}>
          <Label>Judgement Amount</Label>
          <BodyText style={{ marginTop: 0 }}>
            {judgementAmount || 'Not reported'}
          </BodyText>
        </div>
        <div style={{ flex: '1 0 33%' }}>
          <Label>Notice Type</Label>
          <BodyText style={{ marginTop: 0 }}>
            {noticeType || 'Not reported'}
          </BodyText>
        </div>
        <div style={{ flex: '1 0 33%' }}>
          <Label>Filing Date</Label>
          <BodyText style={{ marginTop: 0 }}>
            {filingDate || 'Not reported'}
          </BodyText>
        </div>
      </div>
    )
  })
}
