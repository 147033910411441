import { tours } from '@homevest/utils'

import {
  REFRESH_TABLE_DATA,
  TOGGLE_REFRESH_TABLE_DATA,
  SEARCH_STATE_CHANGE,
  OPEN_MODAL,
  CLOSE_MODAL,
  START_MODAL_ACTION,
  END_MODAL_ACTION,
  CLEAR,
  TOURS_RECEIVED,
  TOUR_RECEIVED,
  TOUR_PROPERTY_RECEIVED
} from './actions'

const { TOUR_STATUSES, TOUR_PROPERTY_STATUSES } = tours

const DEFAULT = {
  refresh: false,
  modalOpen: '',
  modalData: null,
  modalIsActioning: false,
  tours: {}
}

export default function reducer(state = { ...DEFAULT }, action) {
  switch (action.type) {
    case START_MODAL_ACTION:
      return { ...state, ...{ modalIsActioning: true } }
    case END_MODAL_ACTION:
      return { ...state, ...{ modalIsActioning: false } }
    case REFRESH_TABLE_DATA:
      return { ...state, ...{ refresh: true } }
    case TOGGLE_REFRESH_TABLE_DATA:
      return { ...state, ...{ refresh: !state.refresh } }
    case SEARCH_STATE_CHANGE:
      return { ...state, ...{ refresh: false } }
    case OPEN_MODAL:
      return {
        ...state,
        ...{
          modalOpen: action.payload.name,
          modalData: action.payload.data || null
        }
      }
    case CLOSE_MODAL:
      return { ...state, ...{ modalOpen: '', modalData: null } }
    case CLEAR:
      return { ...DEFAULT }
    case TOURS_RECEIVED: {
      const { tours, status } = action

      return {
        ...state,
        tours: {
          ...state.tours,
          [status]: tours
        }
      }
    }
    case TOUR_RECEIVED: {
      const { tour, previousStatus } = action

      const toursWithPreviousStatus = [...(state.tours[previousStatus] || [])]

      const newTours = {
        ...state.tours
      }

      const existingTourIndex = toursWithPreviousStatus.findIndex(
        (t) => t.id === tour.id
      )

      // If the tour status hasn't changed, just swap it out
      if (tour.status === previousStatus) {
        toursWithPreviousStatus.splice(existingTourIndex, 1, tour)

        newTours[previousStatus] = toursWithPreviousStatus
      } else {
        // Else remove the tour from the previous status
        // Add the tour to the new status
        toursWithPreviousStatus.splice(existingTourIndex, 1)

        const newStatus = tour.status

        newTours[newStatus] = [...(state.tours[newStatus] || []), tour]
      }

      newTours[previousStatus] = toursWithPreviousStatus

      return {
        ...state,
        tours: newTours
      }
    }
    case TOUR_PROPERTY_RECEIVED: {
      const { tourProperty, tourStatus } = action

      const newTours = { ...state.tours }

      const tours = [...(state.tours[tourStatus] || [])]

      const existingTourIndex = tours.findIndex(
        (t) => t.id === tourProperty.tour_id
      )

      if (existingTourIndex < 0) {
        return state
      }

      const existingTour = tours[existingTourIndex]

      const existingTourPropertyIndex = existingTour.tour_properties.findIndex(
        (tp) => tp.id === tourProperty.id
      )

      const existingTourProperty =
        existingTour.tour_properties[existingTourPropertyIndex]

      const newTour = { ...existingTour }

      newTour.tour_properties = [...(existingTour.tour_properties || [])]

      // We do this because the images and mls listing details don't come back
      // from the /lead_group_properties/update endpoint
      // so we just overwrite the fields we care about
      newTour.tour_properties.splice(existingTourPropertyIndex, 1, {
        ...existingTourProperty,
        status: tourProperty.status,
        cancelation_reason: tourProperty.cancelation_reason,
        canceled_at: tourProperty.canceled_at,
        confirmed_at: tourProperty.confirmed_at
      })

      const numPropertiesInTour = newTour.tour_properties.length

      // If all of the tour_properties are canceled for this tour, move it to the `canceled` status key and remove it from the existing status key
      if (
        numPropertiesInTour ===
        newTour.tour_properties.filter(
          (tp) => tp.status === TOUR_PROPERTY_STATUSES.CANCELED
        ).length
      ) {
        newTour.status = TOUR_STATUSES.CANCELED

        newTours[TOUR_STATUSES.CANCELED] = [
          ...(state.tours[TOUR_STATUSES.CANCELED] || []),
          newTour
        ]

        tours.splice(existingTourIndex, 1)

        // Else if all of the tour_properties are confirmed for this tour, move it to the `confirmed` status key and remove it from the existing status key
      } else if (
        numPropertiesInTour ===
        newTour.tour_properties.filter(
          (tp) => tp.status === TOUR_PROPERTY_STATUSES.CONFIRMED
        ).length
      ) {
        newTour.status = TOUR_STATUSES.CONFIRMED

        newTours[TOUR_STATUSES.CONFIRMED] = [
          ...(state.tours[TOUR_STATUSES.CONFIRMED] || []),
          newTour
        ]

        tours.splice(existingTourIndex, 1)
      } else {
        // Else there has been no tour status change, so just update the tour with the new tour_property
        tours.splice(existingTourIndex, 1, newTour)
      }

      newTours[tourStatus] = tours

      return {
        ...state,
        tours: newTours
      }
    }

    default:
      return state
  }
}
