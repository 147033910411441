import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { company } from '@homevest/utils'
import startCase from 'lodash/startCase'
import Select from 'react-select'

import {
  Checkbox,
  HeaderText,
  Label,
  Modal,
  Dropdown
} from 'components/Toolkit'
import { ATL_NUMBER, STL_NUMBER } from 'constants/phone'
import DocumentReminders from './DocumentReminders'
import LeadGroupReminders from './LeadGroupReminders'

const { companyDomain } = company

const constsToOptions = (consts) =>
  Object.entries(consts).map(([key, value]) => {
    return {
      label: startCase(key.toLowerCase()),
      value
    }
  })

const REMINDERS = {
  DOCUMENT_REMINDERS: 'document_reminders',
  COSIGNER_REMINDERS: 'cosigner_reminders',
  GOOD_CREDIT_ROOMMATE: 'good_credit_roommate_reminders',
  EVICTION_REMINDERS: 'eviction_reminders'
}

const REMINDER_OPTIONS = constsToOptions(REMINDERS)

const METHODS = {
  EMAIL: 'email',
  TEXT: 'phone'
}

const METHOD_OPTIONS = constsToOptions(METHODS)

const FROM_PHONE_OPTIONS = [
  {
    label: 'Atlanta Main - (404) 383-8508',
    value: ATL_NUMBER
  },
  {
    label: 'St Louis Main - (314) 665-2324',
    value: STL_NUMBER
  }
]

export default function ReminderModal({ close, rentalApplication }) {
  const [reminderOption, setReminderOption] = useState('')
  const [method, setMethod] = useState('')
  const [fromNumber, setFromNumber] = useState('')
  const [sendToLeadGroup, setSendToLeadGroup] = useState(true)
  const [usersToSendTo, setUsersToSendTo] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)

  const {
    lead_group: { active_lead_group_users: activeLeadGroupUsers }
  } = rentalApplication

  const generateFromInfo = () => {
    switch (method) {
      case METHODS.EMAIL: {
        return `hi@${companyDomain}`
      }
      case METHODS.TEXT: {
        return fromNumber
      }
      default: {
        // eslint-disable-next-line no-undef
        window.alert(
          'Unknown method. Please let the nerds know they broke something!'
        )
        throw new Error(
          'Unknown method. Please let the nerds know they broke something!'
        )
      }
    }
  }

  const generateToFromInfo = (user) => {
    const { email, phone } = user

    const from = generateFromInfo()

    let to

    switch (method) {
      case METHODS.EMAIL: {
        to = email
        break
      }
      case METHODS.TEXT: {
        to = phone
        break
      }
      default: {
        // eslint-disable-next-line no-undef
        window.alert(
          'Unknown method. Please let the nerds know they broke something!'
        )
        throw new Error(
          'Unknown method. Please let the nerds know they broke something!'
        )
      }
    }

    return { from, to }
  }

  const resetAndClose = () => {
    setReminderOption('')
    setMethod('')
    setFromNumber('')
    setIsDisabled(false)
    close()
  }

  const alertForUsersWithoutTo = (usersWithoutTo) => {
    // eslint-disable-next-line no-undef
    window.alert(
      `You have selected to send a ${method} reminder, but the following users do not have ${method} set:\n\n${usersWithoutTo
        .map(
          ({ first_name: firstName, last_name: lastName }) =>
            `${firstName} ${lastName}`
        )
        .join('\n')}`
    )
  }

  const sendReminderToUser = (user, rentalApplication, message) => {
    const { from, to } = generateToFromInfo(user)

    if (!to) {
      alertForUsersWithoutTo([{ ...user, to }])
      return
    }

    // eslint-disable-next-line no-undef
    window.analytics.track('rental_application.reminder_sent', {
      entity: 'admin',
      from,
      message,
      method,
      reminder_option: reminderOption,
      rental_application_id: rentalApplication.id,
      to,
      user_id: user.id
    })
  }

  const sendLeadGroupReminders = (message) => {
    if (isDisabled) {
      return
    }

    const usersToSendReminder = sendToLeadGroup
      ? activeLeadGroupUsers.map(({ user }) => user)
      : usersToSendTo.map(({ value }) => value)

    const { usersWithoutTo, userTos } = usersToSendReminder.reduce(
      (acc, user) => {
        const { to } = generateToFromInfo(user)
        acc.userTos.push(to)

        if (!to) {
          acc.usersWithoutTo.push({ ...user, to })
        }

        return acc
      },
      { usersWithoutTo: [], userTos: [] }
    )

    if (usersWithoutTo.length) {
      alertForUsersWithoutTo(usersWithoutTo)
      return
    }

    const from = generateFromInfo()
    const tos = sendToLeadGroup
      ? 'everyone in the lead group'
      : userTos.join(', ')

    // eslint-disable-next-line no-undef
    const confirmed = window.confirm(`
Are you sure you to send the following reminder to ${tos} via ${method} from ${from}?

Hi {FIRST_NAME},

${message}`)

    if (!confirmed) {
      return
    }

    setIsDisabled(true)

    for (const lgu of activeLeadGroupUsers) {
      const { user } = lgu
      const { first_name: firstName } = user
      sendReminderToUser(
        user,
        rentalApplication,
        `Hi ${firstName},\n\n${message}`
      )
    }

    resetAndClose()
  }

  const USER_OPTIONS = useMemo(
    () =>
      activeLeadGroupUsers.map(({ user }) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user
      })),
    [activeLeadGroupUsers]
  )

  const canSend =
    Boolean(reminderOption) &&
    (sendToLeadGroup || Boolean(usersToSendTo.length)) &&
    (method === METHODS.EMAIL || fromNumber)

  return (
    <Modal close={close} hasCloseButton width='50%' height='50%'>
      <HeaderText style={{ marginBottom: '1rem' }} size='h4'>
        Send a reminder
      </HeaderText>
      <Dropdown
        onChange={setReminderOption}
        options={REMINDER_OPTIONS}
        label='Reminder Type'
        value={reminderOption}
        disabled={isDisabled}
      />
      <Checkbox
        label='Send to everyone in lead group?'
        value={sendToLeadGroup}
        onChange={setSendToLeadGroup}
      />
      {!sendToLeadGroup && (
        <div>
          <Label>Customer(s)</Label>
          <Select
            onChange={setUsersToSendTo}
            options={USER_OPTIONS}
            isMulti
            disabled={isDisabled}
          />
        </div>
      )}
      <Dropdown
        onChange={setMethod}
        options={METHOD_OPTIONS}
        label='Email or text?'
        value={method}
        disabled={isDisabled}
      />
      {method === METHODS.TEXT && (
        <Dropdown
          onChange={setFromNumber}
          options={FROM_PHONE_OPTIONS}
          label='From'
          value={fromNumber}
          disabled={isDisabled}
        />
      )}
      {reminderOption === REMINDERS.DOCUMENT_REMINDERS && (
        <DocumentReminders
          canSend={canSend}
          disabled={isDisabled}
          onSend={sendLeadGroupReminders}
          rentalApplication={rentalApplication}
        />
      )}
      {reminderOption === REMINDERS.COSIGNER_REMINDERS && (
        <LeadGroupReminders
          canSend={canSend}
          disabled={isDisabled}
          messageTemplate={`We require a minimum of a 680+ credit score and an income greater than 3x rent in order to qualify for our homes. Unfortunately, it looks like you do not meet these requirements.

Will anyone else be living in the home with you? Or do you have a co-signer by chance?

If you have any questions about our qualification standards, I'd be happy to hop on a call!

Warmly,
Your Up&Up Leasing Concierge`}
          onSend={sendLeadGroupReminders}
          rentalApplication={rentalApplication}
        />
      )}
      {reminderOption === REMINDERS.GOOD_CREDIT_ROOMMATE && (
        <LeadGroupReminders
          canSend={canSend}
          disabled={isDisabled}
          messageTemplate={`We require a minimum of a 680+ credit score and an income greater than 3x rent in order to qualify for our homes. Unfortunately, it looks like you do not meet these requirements.

Will anyone else be living in the home with you?

If you have any questions about our qualification standards, I'd be happy to hop on a call!

Warmly,
Your Up&Up Leasing Concierge`}
          onSend={sendLeadGroupReminders}
          rentalApplication={rentalApplication}
        />
      )}
      {reminderOption === REMINDERS.EVICTION_REMINDERS && (
        <LeadGroupReminders
          canSend={canSend}
          disabled={isDisabled}
          messageTemplate={`When running your background check, our provider reported an eviction on your record. Unfortunately this means that our underwriting team is not able to approve your lease at this time, and thus we are unable to proceed with your application.

If you feel that this is a mistake, please don't hesitate to reach out and we'd be happy to walk you through your report!

Warmly,
Your Up&Up Leasing Concierge

          `}
          onSend={sendLeadGroupReminders}
          rentalApplication={rentalApplication}
        />
      )}
    </Modal>
  )
}

ReminderModal.propTypes = {
  close: PropTypes.func.isRequired,
  rentalApplication: PropTypes.object.isRequired
}
