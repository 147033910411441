import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalFooter, Button } from 'reactstrap'

import IcMemoModalBody from './IcMemoModalBody'

const IcMemoModal = ({
  modal,
  toggle,
  isLoading,
  isError,
  errorMessage,
  icMemoSheetData
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {isLoading
          ? 'Generating memo...'
          : isError
          ? 'Something went wrong...'
          : 'Success!'}
      </ModalHeader>
      <IcMemoModalBody
        isLoading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
        icMemoSheetData={icMemoSheetData}
      />
      {isLoading ? null : (
        <ModalFooter>
          <Button color='danger' onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

IcMemoModal.propTypes = {
  errorMessage: PropTypes.string,
  icMemoSheetData: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  modal: PropTypes.bool,
  toggle: PropTypes.func
}

export default IcMemoModal
