import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from 'urql'

import {
  documents,
  agreements,
  rentalApplications,
  agreementSigners,
  capabilities
} from '@homevest/utils'

import axios from 'lib/axios'
import { hasCapability } from 'lib/admin-perms'
import { firestoreCollections } from 'lib/firebase'
import hellosign from 'lib/hellosign'

import UserDocuments from 'components/Documents/user-documents'
import UserInfo from './user-info'
import UserBackgroundCheckInfo from './user-background-check-info'
import UserIncome from './user-income'
import ApplicationDetails from './ApplicationDetails'
import ApplicationInvestmentCommitteeDetails from './ApplicationInvestmentCommitteeDetails'
import Review from './review'
import Sign from './sign'
import Lease from './lease'
import AddDocumentModal from 'components/Documents/add-document-modal'
import CancelationModal from './cancelation-modal'
import ReminderModal from './ReminderModal'
import {
  ErrorText,
  HeaderText,
  Button,
  ContentContainer
} from 'components/Toolkit'
import Notes from 'components/Notes'
import { REQUEST_POLICY } from 'constants/urql'
import { UpupApplicationMlsListingsByFirestoreIdsDocument } from 'graphql/generated'

const { AGREEMENT_SIGNER, APPLICATION_REVIEWER } = capabilities.CAPABILITY_TYPES

const HEADER_BUTTON_SIZE = 'vs'

const { AGREEMENT_STATUSES } = agreements
const { DOCUMENT_STATUSES, DOCUMENT_TYPES } = documents
const { RENTAL_APPLICATION_STATUSES } = rentalApplications
const { ROLES, AGREEMENT_SIGNER_STATUSES } = agreementSigners

const PENDING_OR_VALID_DOCUMENT = [
  DOCUMENT_STATUSES.VALID,
  DOCUMENT_STATUSES.PENDING_REVIEW
]

const DOC_TYPES_REQUIRING_APPROVAL = [
  DOCUMENT_TYPES.IDENTITY_DOCUMENT,
  DOCUMENT_TYPES.BANK_STATEMENT,
  DOCUMENT_TYPES.OFFER_LETTER,
  DOCUMENT_TYPES.PAY_STUB,
  DOCUMENT_TYPES.TAX_RETURN,
  DOCUMENT_TYPES.SUPPLEMENTAL_DOCUMENT
]

const CRM_APPLICATION_FIELDS = ['occupation', 'salary', 'landlord_reference']

async function updateField(userId, fieldId, value) {
  await axios.post(`/users/${userId}/fields/${fieldId}`, {
    value
  })
}

export default function ApplicationScreen() {
  const { applicationId } = useParams()

  return (
    <React.Suspense
      fallback={
        <div>
          <HeaderText
            style={{ textAlign: 'center', marginTop: '400px' }}
            size='h3'
          >
            Loading...
          </HeaderText>
        </div>
      }
    >
      <ApplicationView applicationId={applicationId} />
    </React.Suspense>
  )
}

function ApplicationView({ applicationId }) {
  const admin = useSelector((state) => state.admin)
  const history = useHistory()
  const [errorText, setErrorText] = useState(null)
  const [showCancelationModal, setShowCancelationModal] = useState(false)
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [rentalApplication, setRentalApplication] = useState(null)
  const [firestoreUsers, setFirestoreUsers] = useState(null)
  const [crmUsers, setCrmUsers] = useState(null)
  const [addDocumentUserId, setAddDocumentUserId] = useState(null)
  const [documentUrls, setDocumentUrls] = useState({})
  const [crmFields, setCrmFields] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [companySigned, setCompanySigned] = useState(false)
  const [landlordSigned, setLandlordSigned] = useState(false)
  const [mlsListing, setMlsListing] = useState(null)
  const [leaseData, setLeaseData] = useState({
    initial_lease_term: '',
    initial_option_premium: '',
    monthly_option_premium: '',
    occupancy_date: '',
    rent: ''
  })
  const pendingAgreement =
    rentalApplication &&
    rentalApplication.agreements.find(
      (agreement) => agreement.status === AGREEMENT_STATUSES.PENDING
    )
  const completedAgreement =
    rentalApplication &&
    rentalApplication.agreements.find(
      (agreement) => agreement.status === AGREEMENT_STATUSES.COMPLETE
    )
  const completedOrPendingAgreement =
    rentalApplication &&
    rentalApplication.agreements.find((agreement) =>
      [AGREEMENT_STATUSES.COMPLETE, AGREEMENT_STATUSES.PENDING].includes(
        agreement.status
      )
    )
  const landlordSigner =
    pendingAgreement &&
    pendingAgreement.agreement_signers.find(
      (signer) =>
        signer.role === ROLES.LANDLORD &&
        signer.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE
    )
  const companySigner =
    pendingAgreement &&
    pendingAgreement.agreement_signers.find(
      (signer) =>
        (signer.role === ROLES.COMPANY || signer.role === ROLES.AGENT) &&
        signer.status === AGREEMENT_SIGNER_STATUSES.PENDING_SIGNATURE
    )

  async function cancelApplication(cancelReason) {
    setIsSaving(true)

    await axios.post(
      `/admin/rental_applications/${rentalApplication.id}/cancel`,
      {
        cancelation_reason: cancelReason
      }
    )

    history.push('/applications')

    setIsSaving(false)
  }

  async function cancelAgreement() {
    setIsSaving(true)

    await axios.post(
      `/admin/agreements/${completedOrPendingAgreement.id}/cancel`
    )

    await getApplicationData()

    setIsSaving(false)
  }

  async function review(isApproved) {
    setIsSaving(true)
    try {
      await axios.post(
        `/admin/rental_applications/${rentalApplication.id}/review`,
        {
          is_review_approved: isApproved
        }
      )
    } catch (e) {
      // eslint-disable-next-line no-undef
      window.alert(e.message)
    }
    await getApplicationData()
    setIsSaving(false)
  }

  async function setDocumentReviewStatus(docId, status, notes) {
    setIsSaving(true)
    await axios.post(`/admin/documents/${docId}/review`, {
      status,
      review_notes: notes
    })
    await getApplicationData()
    setIsSaving(false)
  }

  async function updateDocumentReviewStatus(docId, notes) {
    setIsSaving(true)
    await axios.put(`/admin/documents/${docId}`, {
      review_notes: notes
    })
    await getApplicationData()
    setIsSaving(false)
  }

  async function getApplicationData() {
    const { data: application } = await axios.get(
      `/admin/rental_applications/${applicationId}`
    )

    // Get the user ids from the lead group since we now have "occupants" that
    // won't be agreement signers but do need to show up on te screen
    // TODO 2022-03-09 replace with rental application users once that is done
    const userIds = application.lead_group.active_lead_group_users.map(
      (lgu) => lgu.user.id
    )

    const firestoreUserData = await Promise.all(
      userIds.map((id) => firestoreCollections.usersRef.doc(id).get())
    )
    const userData = await Promise.all(
      userIds.map((id) =>
        axios.get(`/admin/users/${id}`).then((resp) => {
          const userData = resp.data

          return {
            ...userData,
            email: userData.email2,
            phone: userData.phone2
          }
        })
      )
    )
    const { data: fields } = await axios.get('/fields')

    const leaseDocId = get(
      application.agreements.find(
        (agreement) => agreement.status === AGREEMENT_STATUSES.COMPLETE
      ),
      'document.id',
      null
    )
    const leaseDocIds = leaseDocId ? [leaseDocId] : []

    const documentIds = userData
      .reduce(
        (acc, { documents }) => acc.concat(documents.map((doc) => doc.id)),
        []
      )
      .concat(leaseDocIds)

    const documentUrls = await Promise.all(
      documentIds.map((docId) =>
        axios.get(`/admin/documents/${docId}/url`).then((resp) => resp.data.url)
      )
    )

    const documentUrlsMapped = documentIds.reduce(
      (acc, id, idx) => ({ ...acc, [id]: documentUrls[idx] }),
      {}
    )

    setDocumentUrls(documentUrlsMapped)

    setCrmFields(
      fields.filter((field) => CRM_APPLICATION_FIELDS.includes(field.label))
    )
    setFirestoreUsers(
      firestoreUserData
        .map((data) => data.data())
        .reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
    )
    setCrmUsers(
      userData.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {})
    )
    setRentalApplication(application)
  }

  async function launchLease(signer, setFinished) {
    const signingId = signer.external_id

    const {
      data: { sign_url: signedUrl }
    } = await axios.get(`/hellosign/sign_url/${signingId}`)

    hellosign.on('sign', async () => {
      setFinished(true)
      await getApplicationData()
    })

    hellosign.open(signedUrl)
  }

  useEffect(() => {
    try {
      return getApplicationData()
    } catch (err) {
      setErrorText(err.message)
    }
  }, [])

  useEffect(() => {
    if (!mlsListing) {
      return
    }

    const rent = Number(get(mlsListing, 'internal_attributes[0].rent'))

    setLeaseData({
      initial_lease_term: '24',
      initial_option_premium: (rent * 2).toString(),
      monthly_option_premium: '50',
      occupancy_date: '',
      rent: rent.toString()
    })
  }, [mlsListing])

  const firestoreIds = []
  const firestoreId = get(rentalApplication, 'mls_listing.firestore_id')
  if (firestoreId) {
    firestoreIds.push(firestoreId)
  }

  const [{ data, fetching, error }] = useQuery({
    query: UpupApplicationMlsListingsByFirestoreIdsDocument,
    variables: { ids: firestoreIds },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    pause: !firestoreIds.length
  })

  useEffect(() => {
    if (!data || !data.mls_listings) {
      return
    }
    setMlsListing(data.mls_listings[0])
  }, [data])

  if (error) {
    return <ErrorText>{error.message}</ErrorText>
  }

  if (errorText) {
    return (
      <ErrorText>
        Please contact engineering, the following error occurred: {errorText}
      </ErrorText>
    )
  }

  if (fetching || !data) {
    return (
      <div>
        <HeaderText
          style={{ textAlign: 'center', marginTop: '400px' }}
          size='h3'
        >
          Loading...
        </HeaderText>
      </div>
    )
  }

  if (!rentalApplication || !mlsListing) {
    return (
      <div>
        <HeaderText
          style={{ textAlign: 'center', marginTop: '400px' }}
          size='h3'
        >
          Loading...
        </HeaderText>
      </div>
    )
  }

  const resourceNoteWidgetOptions = []
  const displayLine1 = rentalApplication.mls_listing.display_line_1

  const raOption = {
    id: rentalApplication.id,
    type: 'rental_applications',
    displayName: 'Application for ' + displayLine1,
    writable: true
  }
  resourceNoteWidgetOptions.push(raOption)

  let lgOptionDisplayName = 'Lead group for '

  if (rentalApplication.lead_group.active_lead_group_users.length) {
    lgOptionDisplayName += rentalApplication.lead_group.active_lead_group_users
      .map((lgu) => lgu.user.first_name)
      .join(', ')
  } else {
    lgOptionDisplayName += displayLine1
  }

  const lgOption = {
    id: rentalApplication.lead_group.id,
    type: 'lead_groups',
    displayName: lgOptionDisplayName,
    writable: true
  }
  resourceNoteWidgetOptions.push(lgOption)

  return (
    <ContentContainer type='intro'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <HeaderText style={{ margin: 0 }} size='h3'>
          <Link
            to={`/review/initial/${rentalApplication.mls_listing.firestore_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {rentalApplication.mls_listing.display_line_1}
          </Link>
        </HeaderText>
        {rentalApplication.status !== RENTAL_APPLICATION_STATUSES.COMPLETE && (
          <div>
            {rentalApplication.status !==
              RENTAL_APPLICATION_STATUSES.CANCELED && (
              <Button
                onClick={() => setShowCancelationModal(true)}
                size={HEADER_BUTTON_SIZE}
                style={{ backgroundColor: 'red' }}
              >
                Cancel Application
              </Button>
            )}
            {!!completedOrPendingAgreement && (
              <Button
                onClick={cancelAgreement}
                size={HEADER_BUTTON_SIZE}
                isSecondary
                style={{
                  color: 'red',
                  borderColor: 'red',
                  marginLeft: '10px'
                }}
              >
                Cancel Agreement
              </Button>
            )}
            <Button
              style={{ marginLeft: '10px' }}
              size={HEADER_BUTTON_SIZE}
              onClick={() => setShowReminderModal(true)}
            >
              Send Reminder
            </Button>
          </div>
        )}
      </div>
      <div style={{ marginBottom: '20px' }}>
        <ApplicationDetails rentalApplication={rentalApplication} />
      </div>
      <ApplicationInvestmentCommitteeDetails
        rentalApplication={rentalApplication}
      />

      <div style={{ marginTop: '20px' }}>
        {completedAgreement && (
          <Lease
            completedAgreement={completedAgreement}
            documentUrls={documentUrls}
          />
        )}
        {rentalApplication.status ===
          RENTAL_APPLICATION_STATUSES.PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION &&
          ((companySigner && !companySigned) ||
            (landlordSigner && !landlordSigned)) &&
          hasCapability(admin, AGREEMENT_SIGNER) && (
            <Sign
              waitingForCompany={companySigner && !companySigned}
              waitingForLandlord={landlordSigner && !landlordSigned}
              launchCompanyLease={() => {
                launchLease(companySigner, setCompanySigned)
              }}
              launchLandlordLease={() => {
                launchLease(landlordSigner, setLandlordSigned)
              }}
            />
          )}
        {rentalApplication.status ===
          RENTAL_APPLICATION_STATUSES.PENDING_RENTAL_AGREEMENT_CREATION &&
          hasCapability(admin, APPLICATION_REVIEWER) && (
            <a
              href='https://upandup.tryretool.com/apps/Leasing/Create%20Lease'
              target='_blank'
            >
              <Button isSecondary={false} size='s'>
                Create Lease in Retool
              </Button>
            </a>
          )}

        {rentalApplication.status ===
          RENTAL_APPLICATION_STATUSES.PENDING_APPLICATION_REVIEW &&
          hasCapability(admin, APPLICATION_REVIEWER) && (
            <Review isSaving={isSaving} review={review} />
          )}
      </div>

      {rentalApplication.lead_group.id && (
        <div
          style={{
            border: '1px solid #ccc',
            padding: '20px',
            marginTop: '20px',
            backgroundColor: '#fff'
          }}
        >
          <div>
            <HeaderText size='h3'>
              <span>Notes</span>
            </HeaderText>
          </div>
          <Notes
            headerless={true}
            resourceNoteWidgetOptions={resourceNoteWidgetOptions}
          />
        </div>
      )}

      <div>
        {rentalApplication.lead_group.active_lead_group_users.map((lgu) => {
          const userId = lgu.user.id
          const documentsPendingOrComplete = lgu.user.documents.filter(
            (doc) =>
              DOC_TYPES_REQUIRING_APPROVAL.includes(doc.type) &&
              PENDING_OR_VALID_DOCUMENT.includes(doc.status)
          )
          const agreementSigner = completedOrPendingAgreement
            ? completedOrPendingAgreement.agreement_signers.find(
                (signer) =>
                  signer.resource_id === userId &&
                  signer.status === AGREEMENT_SIGNER_STATUSES.SIGNED
              ) || null
            : null

          return (
            <div
              style={{
                border: '1px solid #ccc',
                padding: '20px',
                marginTop: '20px',
                backgroundColor: '#fff'
              }}
              key={userId}
            >
              <UserInfo
                hasSigned={Boolean(agreementSigner)}
                signedLead={rentalApplication.agreements}
                documentUrls={documentUrls}
                leadGroupUser={lgu}
                firestoreUser={firestoreUsers[userId]}
                crmUser={crmUsers[userId]}
                crmFields={crmFields}
                updateField={updateField}
              />
              <UserBackgroundCheckInfo leadGroupUser={lgu} />
              <UserIncome user={lgu.user} />
              <UserDocuments
                documentUrls={documentUrls}
                addDocument={() => setAddDocumentUserId(userId)}
                isSaving={isSaving}
                setDocumentReviewStatus={setDocumentReviewStatus}
                updateDocumentReviewStatus={updateDocumentReviewStatus}
                documents={documentsPendingOrComplete}
              />
            </div>
          )
        })}
      </div>
      {addDocumentUserId && (
        <AddDocumentModal
          userId={addDocumentUserId}
          onFinish={getApplicationData}
          close={() => setAddDocumentUserId(null)}
        />
      )}
      {showCancelationModal && (
        <CancelationModal
          cancel={cancelApplication}
          close={() => setShowCancelationModal(false)}
        />
      )}
      {showReminderModal && (
        <ReminderModal
          close={() => setShowReminderModal(false)}
          rentalApplication={rentalApplication}
        />
      )}
    </ContentContainer>
  )
}

ApplicationView.propTypes = {
  applicationId: PropTypes.string.isRequired
}
