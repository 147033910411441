import {
  START_SYNCING_FIREBASE_USER,
  STOP_SYNCING_FIREBASE_USER,
  receivedFirebaseUser
} from '../state/users/actions'
import { firestoreCollections } from '../lib/firebase'

export default (store) => (next) => (action) => {
  switch (action.type) {
    case START_SYNCING_FIREBASE_USER:
      next(action)
      startSyncingFirebaseUser(action.userId, store)
      break
    case STOP_SYNCING_FIREBASE_USER:
      next(action)
      stopSyncingFirebaseUser(action.userId)
      break
    // TODO on logout should stop syncing all listeners and
    // should clear the store
    default:
      next(action)
  }
}

const listeners = new Map()

// TODO logout
// function stopSyncingAll () {
//   for (const unsubscribe of listeners.values()) {
//     unsubscribe()
//   }
// }

function startSyncingFirebaseUser(userId, store) {
  const unsubscribe = firestoreCollections.usersRef
    .doc(userId)
    .onSnapshot((userDoc) => {
      store.dispatch(receivedFirebaseUser(userDoc.data()))
    })

  listeners.set(userId, unsubscribe)
}

function stopSyncingFirebaseUser(userId) {
  const unsubscribe = listeners.get(userId)
  unsubscribe && unsubscribe()
}
