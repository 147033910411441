import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import {
  UpupInsertAdminCapabilityDocument,
  UpupDeleteAdminCapabilityDocument
} from 'graphql/generated'
import { Checkbox } from 'components/Toolkit'
import { Spinner } from 'reactstrap'

type Props = {
  value: boolean
  adminId: string
  capId: string
  adminCapPk?: string
}

const CapabilityToggle: React.FC<Props> = ({
  value,
  adminId,
  capId,
  adminCapPk
}) => {
  const [{ fetching: addFetching }, addCapToAdmin] = useMutation(
    UpupInsertAdminCapabilityDocument
  )
  const [{ fetching: removeFetching }, removeCapFromAdmin] = useMutation(
    UpupDeleteAdminCapabilityDocument
  )

  return addFetching || removeFetching ? (
    <Spinner />
  ) : (
    <Checkbox
      label={null}
      style={{ margin: 0 }}
      value={value}
      onChange={() => {
        if (value) {
          void removeCapFromAdmin({ id: adminCapPk })
        } else {
          void addCapToAdmin({ admin_id: adminId, capability_id: capId })
        }
      }}
    />
  )
}

CapabilityToggle.propTypes = {
  adminId: PropTypes.string.isRequired,
  adminCapPk: PropTypes.string,
  capId: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired
}

export default CapabilityToggle
