import './homes_base.css'
import React from 'react'
import { FunctionComponent } from 'react'
import HomesList from './HomesList'

const HomesGridPage: FunctionComponent = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '1000px' }}>
      <HomesList />
    </div>
  )
}

export default HomesGridPage
