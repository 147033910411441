"use strict";
exports.REGIONS = {
    ATLANTA: 'atlanta-ga',
    CHARLOTTE: 'charlotte-nc',
    DALLAS: 'dallas-tx',
    INDIANAPOLIS: 'indianapolis-in',
    LOS_ANGELES: 'los-angeles-ca',
    PHOENIX: 'phoenix-az',
    RALEIGH: 'raleigh-nc',
    ST_LOUIS: 'st-louis-mo',
    TAMPA: 'tampa-fl'
};
exports.SEARCHABLE_REGION_CODES = {
    [exports.REGIONS.ATLANTA]: 'Atlanta',
    [exports.REGIONS.CHARLOTTE]: 'Charlotte',
    [exports.REGIONS.DALLAS]: 'Dallas',
    [exports.REGIONS.INDIANAPOLIS]: 'Indianapolis',
    [exports.REGIONS.LOS_ANGELES]: 'Los Angeles',
    [exports.REGIONS.PHOENIX]: 'Phoenix',
    [exports.REGIONS.RALEIGH]: 'Raleigh',
    [exports.REGIONS.TAMPA]: 'Tampa'
};
exports.STATES = {
    AZ: 'AZ',
    CA: 'CA',
    GA: 'GA',
    IN: 'IN',
    MO: 'MO',
    NC: 'NC'
};
