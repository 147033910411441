// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import { get } from 'lodash'
import { Container } from 'reactstrap'
import { capabilities } from '@homevest/utils'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'

import axios from 'lib/axios'
import {
  BodyText,
  Button,
  ErrorText,
  HeaderText,
  Input,
  Modal
} from 'components/Toolkit'

import LeadGroupSearch from '../../components/LeadGroups/Search'
import List from './List'

import { useQuery } from 'urql'
import { useSelector } from 'react-redux'
import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'

import { REQUEST_POLICY } from 'constants/urql'
import { UpupApplicationMlsListingsByAddressDocument } from 'graphql/generated'

import { formatMoney } from 'lib/numbers'

const { APPLICATION_VIEWER } = capabilities.CAPABILITY_TYPES

const ApplicationsScreen: React.FC = () => {
  const isRentalAppViewer = useSelector<StoreState, boolean>((store) =>
    hasCapability(store.admin, APPLICATION_VIEWER)
  )
  const [showCreateModal, setShowCreateModal] = React.useState(false)
  React.useState<{
    id: string
  } | null>(null)

  if (!isRentalAppViewer) {
    return (
      <Container>
        <ErrorText>You do not have permissions to view this content</ErrorText>
      </Container>
    )
  }

  return (
    <div>
      <Container>
        <div
          style={{
            marginLeft: '4%',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <HeaderText style={{ margin: 0 }} size='h3'>
            Applications
          </HeaderText>
          <div>
            <Button
              isSecondary={false}
              onClick={() => setShowCreateModal(true)}
              size='s'
            >
              + Create
            </Button>
          </div>
        </div>

        <List />
      </Container>

      {showCreateModal && (
        <CreateApplicationModal setShowCreateModal={setShowCreateModal} />
      )}
    </div>
  )
}

function CreateApplicationModal({ setShowCreateModal }) {
  const [searchAddress, setSearchAddress] = React.useState(null)
  const [newApplicationProperty, setNewApplicationProperty] =
    React.useState(null)

  return (
    <Modal
      hasCloseButton
      close={() => {
        setShowCreateModal(false)
        setSearchAddress(null)
      }}
    >
      {newApplicationProperty == null && (
        <div>
          <HeaderText size='h3'>Property Search</HeaderText>
          <BodyText>Find a property for the application</BodyText>
          <Input
            type='string'
            label='Property Address'
            value={searchAddress}
            onChange={(val) => {
              setSearchAddress(val)
            }}
          />
          <React.Suspense fallback={<div>No properties found!</div>}>
            <CreateApplicationPropertySearch
              address={searchAddress}
              setNewApplicationProperty={setNewApplicationProperty}
            />
          </React.Suspense>
        </div>
      )}

      {Boolean(newApplicationProperty) && (
        <div>
          <LeadGroupSearch
            selectText='Select'
            isDisabled={false}
            onSelect={async (val: any) => {
              try {
                await axios.post('/admin/rental_applications', {
                  lead_group_id: val.id,
                  property_id: newApplicationProperty!.firestore_id
                })
              } catch (e) {
                window.alert(`Error: ${(e as Error).message}`)
              }

              setNewApplicationProperty(null)
              setShowCreateModal(false)
            }}
          />
        </div>
      )}
    </Modal>
  )
}

function CreateApplicationPropertySearch({
  address,
  setNewApplicationProperty
}) {
  const [{ data, fetching, error }] = useQuery({
    query: UpupApplicationMlsListingsByAddressDocument,
    variables: { address: `%${address}%` },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    pause: !address
  })

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  if (fetching) {
    return <BodyText>Searching for ${address}...</BodyText>
  }

  if (!data) {
    return <BodyText>No properties found</BodyText>
  }

  return (
    <div>
      <HeaderText size='h4'>
        {data.mls_listings.length}{' '}
        {pluralize('Property', data.mls_listings.length)} Found
      </HeaderText>
      {!fetching &&
        data &&
        data.mls_listings.map((listing) => {
          return (
            <div key={listing.id} style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <HeaderText size='h5'>{listing.display_line_1}</HeaderText>
                <BodyText>{listing.display_line_2}</BodyText>
                <BodyText>
                  {listing.beds} Beds/ {listing.baths_weighted} Baths
                </BodyText>
                <BodyText>
                  List Price: {formatMoney(listing.list_price, 2, '$')}
                </BodyText>
                <BodyText>
                  Rent:{' '}
                  {formatMoney(
                    get(listing, 'internal_attributes[0].rent'),
                    2,
                    '$'
                  )}{' '}
                  / Status:{' '}
                  {get(listing, 'internal_attributes[0].internal_status')}
                </BodyText>
              </div>
              <div>
                {get(listing, 'photos[0].url') && (
                  <img height='64px' src={listing.photos[0].url} />
                )}
                <Button
                  isSecondary={false}
                  onClick={() => {
                    setNewApplicationProperty(listing)
                  }}
                  size='vvs'
                >
                  Select
                </Button>
              </div>
            </div>
          )
        })}
    </div>
  )
}

CreateApplicationModal.propTypes = {
  setShowCreateModal: PropTypes.func.isRequired
}

CreateApplicationPropertySearch.propTypes = {
  address: PropTypes.string.isRequired,
  setNewApplicationProperty: PropTypes.func.isRequired
}

export default ApplicationsScreen
