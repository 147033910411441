import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { startCase } from 'lodash'

export default function StatusSectionHeader({ status }) {
  return (
    <Row style={{ marginTop: 50 }}>
      <Col>
        <h4>{startCase(status)}</h4>
      </Col>
    </Row>
  )
}

StatusSectionHeader.propTypes = {
  status: PropTypes.string.isRequired
}
