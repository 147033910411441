import { get, pick } from 'lodash'
import humanizeString from 'humanize-string'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { capabilities, rentalApplications } from '@homevest/utils'

import {
  Button,
  BodyText,
  ErrorText,
  HeaderText,
  Label,
  TextArea
} from 'components/Toolkit'

import COLORS from 'components/Toolkit/colors'
import axios from 'lib/axios'
import { hasCapability } from 'lib/admin-perms'

const { IC_DECIDER, IC_EXECUTIVE_DECIDER } = capabilities.CAPABILITY_TYPES
const { RENTAL_APPLICATION_STATUSES } = rentalApplications

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
`

const STATUS_COLORS = Object.freeze({
  green: '✅',
  yellow: '🟡',
  red: '🛑',
  gray: '⚪️'
})

const escalateRentalApplication = async (rentalApplicationId, context) => {
  const {
    setCurrentRentalApplication,
    setIsSaving,
    setNeedRefreshAt,
    setSaveError
  } = context

  if (
    // eslint-disable-next-line no-undef
    !window.confirm(
      'Are you sure you want to escalate this decision to the executive team?'
    )
  ) {
    return
  }

  try {
    setIsSaving(true)
    const { data } = await axios.put(
      `/admin/rental_applications/${rentalApplicationId}/ic/escalate`
    )

    setCurrentRentalApplication(data)
    setNeedRefreshAt(new Date())
  } catch (err) {
    setSaveError(err.message)
  } finally {
    setIsSaving(false)
  }
}

const decideRentalApplication = async (
  rentalApplicationId,
  rentalApplicationDecisionId,
  payload,
  context
) => {
  const {
    setIsSaving,
    setNeedRefreshAt,
    setRentalApplicationDecisionId,
    setRentalApplicationDecision,
    setSaveError
  } = context

  let response
  try {
    setIsSaving(true)
    if (rentalApplicationDecisionId) {
      response = await axios.put(
        `/admin/rental_application_decisions/${rentalApplicationDecisionId}`,
        pick(payload, ['is_approved', 'notes'])
      )
    } else {
      payload.rental_application_id = rentalApplicationId
      response = await axios.post(
        '/admin/rental_application_decisions',
        pick(payload, ['is_approved', 'notes', 'rental_application_id'])
      )
    }

    const { data } = response

    setRentalApplicationDecision(data)
    setRentalApplicationDecisionId(data.id)
    setNeedRefreshAt(new Date())
  } catch (err) {
    setSaveError(err.message)
  } finally {
    setIsSaving(false)
  }
}

function ApplicationInvestmentCommitteeDetails({ rentalApplication }) {
  const [admins, setAdmins] = useState([])
  const [currentAdmin, setCurrentAdmin] = useState(null)
  const [icReviewData, setIcReviewData] = useState(null)
  const [isIcExecutiveDecider, setIsIcExecutiveDecider] = useState(false)
  const [isIcDecider, setIsIcDecider] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [loadError, setLoadError] = useState(null)
  const [needRefreshAt, setNeedRefreshAt] = useState(null)
  const [currentRentalApplication, setCurrentRentalApplication] =
    useState(rentalApplication)
  const [rentalApplicationDecision, setRentalApplicationDecision] = useState({})
  const [rentalApplicationDecisionId, setRentalApplicationDecisionId] =
    useState(null)
  const [rentalApplicationDecisions, setRentalApplicationDecisions] = useState(
    []
  )
  const [saveError, setSaveError] = useState(null)

  const getIcValue = (v) => {
    const s = STATUS_COLORS[v.status]

    if (v.reason) {
      return `${s} ${v.reason}`
    } else {
      return s
    }
  }

  const isDecidable = (
    rentalApplication,
    isIcDecider,
    isIcExecutiveDecider
  ) => {
    if (
      rentalApplication.status !==
      RENTAL_APPLICATION_STATUSES.PENDING_UNDERWRITING
    ) {
      return false
    }

    if (rentalApplication.is_ic_escalated) {
      return isIcExecutiveDecider
    } else {
      return isIcDecider || isIcExecutiveDecider
    }
  }

  const displayRecommendation = (category, i) => {
    const k = i[0]
    const v = i[1]
    return (
      <div key={k}>
        <Label>
          {humanizeString(category)} - {humanizeString(k)}
        </Label>
        <BodyText size='s' style={{ marginTop: 0 }}>
          {getIcValue(v)}
        </BodyText>
      </div>
    )
  }

  useEffect(() => {
    async function run() {
      try {
        const [reviewResponse, decisionsResponse] = await Promise.all([
          axios.get(
            `/admin/rental_applications/${rentalApplication.id}/ic/review`
          ),
          axios.get(
            `/admin/rental_applications/${rentalApplication.id}/ic/decisions`
          )
        ])

        const rentalApplicationDecisions =
          decisionsResponse.data.rental_application_decisions

        // The admin has already voted, so this will be an update
        if (currentAdmin) {
          const previousRentalApplicationDecision =
            rentalApplicationDecisions.find(
              (d) => d.admin_id === currentAdmin.id
            )
          if (previousRentalApplicationDecision) {
            setRentalApplicationDecisionId(previousRentalApplicationDecision.id)
            setRentalApplicationDecision(previousRentalApplicationDecision)
          }
        }

        // These admins have not voted and will be painted as pending
        const alreadyVotedAdminIds = rentalApplicationDecisions.map(
          (d) => d.admin_id
        )

        setAdmins(
          decisionsResponse.data.admins.filter(
            (a) => !alreadyVotedAdminIds.includes(a.id)
          )
        )
        setIcReviewData(reviewResponse.data)
        setRentalApplicationDecisions(rentalApplicationDecisions)
      } catch (err) {
        setLoadError(err.message)
      } finally {
        setIsLoading(false)
      }
    }
    run()
  }, [rentalApplication, currentAdmin, needRefreshAt])

  useEffect(() => {
    async function run() {
      try {
        setIsLoading(true)
        const { data: admin } = await axios.get('/admins/me')

        const isIcDecider = hasCapability(admin, IC_DECIDER)
        const isIcExecutiveDecider = hasCapability(admin, IC_EXECUTIVE_DECIDER)

        setCurrentAdmin(admin)
        setIsIcDecider(isIcDecider)
        setIsIcExecutiveDecider(isIcExecutiveDecider)
      } catch (err) {
        setLoadError(err.message)
      } finally {
        setIsLoading(false)
      }
    }
    run()
  }, [])

  if (isLoading || !icReviewData || !rentalApplication) {
    return (
      <StyledLoading>
        <span>Loading...</span>
      </StyledLoading>
    )
  }

  if (loadError) {
    return <ErrorText>{loadError}</ErrorText>
  }

  const incomeResults = Object.entries(get(icReviewData, 'income', {}))
  const creditResults = Object.entries(get(icReviewData, 'credit', {}))
  const context = {
    setCurrentAdmin,
    setCurrentRentalApplication,
    setIcReviewData,
    setIsIcDecider,
    setIsIcExecutiveDecider,
    setIsSaving,
    setNeedRefreshAt,
    setRentalApplicationDecision,
    setRentalApplicationDecisions,
    setRentalApplicationDecisionId,
    setSaveError
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '20px',
        marginTop: '20px',
        backgroundColor: '#fff'
      }}
    >
      <div>
        <HeaderText size='h3'>
          <span>Credit Committee Review</span>
        </HeaderText>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ width: '33%' }}>
          {displayRecommendation('Income', incomeResults[0])}
        </div>

        <div style={{ width: '33%' }}>
          {displayRecommendation('Income', incomeResults[1])}
        </div>

        <div style={{ width: '33%' }}>
          {displayRecommendation('Credit', creditResults[0])}
        </div>
      </div>

      {(currentRentalApplication.status ===
        RENTAL_APPLICATION_STATUSES.PENDING_UNDERWRITING ||
        rentalApplicationDecisions.length > 0) && (
        <div style={{ marginTop: '10px' }}>
          <table style={{ tableLayout: 'fixed', width: '100%' }}>
            <tr>
              <th style={{ width: '25%' }}>Member</th>
              <th>Approved?</th>
              <th>Date</th>
              <th style={{ width: '50%', wordWrap: 'break-word' }}>Notes</th>
            </tr>
            {rentalApplicationDecisions.map((d) => (
              <tr key={d.id}>
                <td style={{ width: '25%' }}>
                  <BodyText size='s'>{d.admin.name}</BodyText>
                </td>
                <td>{d.is_approved ? '🟢' : '🔴'}</td>
                <td>
                  <BodyText size='s'>
                    {moment(d.decided_at).format('MM/DD/yy')}
                  </BodyText>
                </td>
                <td style={{ width: '50%', wordWrap: 'break-word' }}>
                  <BodyText size='s'>{d.notes}</BodyText>
                </td>
              </tr>
            ))}
            {admins.map((a) => (
              <tr key={a.id}>
                <td>
                  <BodyText size='s'>{a.name}</BodyText>
                </td>
                <td>🟡</td>
                <td />
                <td />
              </tr>
            ))}
          </table>
        </div>
      )}

      {isDecidable(
        currentRentalApplication,
        isIcDecider,
        isIcExecutiveDecider
      ) && (
        <div style={{ marginTop: '10px' }}>
          <HeaderText size='h5'>
            <span>Your Decision</span>
          </HeaderText>
          <div
            style={{
              marginTop: '20px'
            }}
          >
            <TextArea
              label='Notes'
              value={rentalApplicationDecision.notes}
              onChange={(notes) => {
                setRentalApplicationDecision({
                  ...rentalApplicationDecision,
                  notes
                })
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {saveError && <ErrorText>{saveError}</ErrorText>}
              <Button
                disabled={isSaving}
                style={{ marginRight: '15px' }}
                onClick={() => {
                  rentalApplicationDecision.is_approved = true

                  decideRentalApplication(
                    currentRentalApplication.id,
                    rentalApplicationDecisionId,
                    rentalApplicationDecision,
                    context
                  )
                }}
              >
                {rentalApplicationDecision.is_approved ? 'Update' : ' Approve'}
              </Button>
              <Button
                disabled={isSaving}
                style={{
                  marginLeft: '40px',
                  background: COLORS.ACCENTS.SALMON
                }}
                onClick={() => {
                  rentalApplicationDecision.is_approved = false

                  decideRentalApplication(
                    currentRentalApplication.id,
                    rentalApplicationDecisionId,
                    rentalApplicationDecision,
                    context
                  )
                }}
              >
                {rentalApplicationDecisionId &&
                !rentalApplicationDecision.is_approved
                  ? 'Update'
                  : 'Reject'}
              </Button>
              {!currentRentalApplication.is_ic_escalated && (
                <Button
                  disabled={isSaving}
                  isSecondary
                  style={{
                    marginLeft: '80px'
                  }}
                  onClick={() => {
                    escalateRentalApplication(
                      currentRentalApplication.id,
                      context
                    )
                  }}
                >
                  Escalate
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ApplicationInvestmentCommitteeDetails.propTypes = {
  rentalApplication: PropTypes.object.isRequired
}

export default memo(ApplicationInvestmentCommitteeDetails)
