export const BLUE_100 = '#dbeafe'
export const BLUE_400 = '#60a5fa'
export const BLUE_600 = '#2563EB'
export const BLUE_800 = '#1e40af'
export const GRAY_50 = `#F9FAFB`
export const GRAY_100 = '#f3f4f6'
export const GRAY_200 = '#E5E7EB'
export const GRAY_300 = '#D1D5DB'
export const GRAY_400 = '#9CA3AF'
export const GRAY_500 = '#6B7280'
export const GRAY_600 = '#4B5563'
export const GRAY_700 = '#374151'
export const GRAY_800 = '#1F29371'
export const GRAY_900 = '#111827'
export const GREEN_50 = '#ecfdf5'
export const GREEN_100 = '#d1fae5'
export const GREEN_400 = '#34D399'
export const GREEN_500 = '#10b981'
export const GREEN_600 = ' #059669'
export const GREEN_800 = '#065f46'
export const INDIGO_500 = '#6366f1'
export const INDIGO_600 = '#4F46E5'
export const RED_100 = '#fee2e2'
export const RED_400 = '#F87171'
export const RED_500 = '#EF4444'
export const RED_600 = '#dc2626'
export const RED_800 = '#991b1b'
export const YELLOW_100 = '#fef3c7'
export const YELLOW_400 = '#fbbf24'
export const YELLOW_800 = '#92400e'
