import React, { memo } from 'react'
import PropTypes from 'prop-types'

function ForwardChevron({ color = '#302654', height = '16' }) {
  return (
    <svg width={height} height={height} viewBox='0 0 8 16' fill='none'>
      <path
        d='M0.355353 0.420928C-0.118451 0.979754 -0.118451 1.88247 0.355353 2.4413L5.0691 8.0009L0.355353 13.5605C-0.118451 14.1193 -0.118451 15.0221 0.355353 15.5809C0.829157 16.1397 1.59453 16.1397 2.06834 15.5809L7.64465 9.00392C8.11845 8.4451 8.11845 7.54238 7.64465 6.98355L2.06834 0.406599C1.60668 -0.137898 0.829157 -0.137898 0.355353 0.420928Z'
        fill={color}
      />
    </svg>
  )
}

ForwardChevron.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default memo(ForwardChevron)
