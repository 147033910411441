// the file containing the components for the customer profile screen
import React, { FunctionComponent, useEffect, useState } from 'react'
import { types } from '@homevest/timelines/'
import TwoColumnLayout from './TwoColumnLayout'
import ContentSection from './ContentSection'
import Timeline from './Timeline'
import PersonalDetails from './PersonalDetails'
import PrequalificationDetails from './PrequalificationDetails'
import LeadGroup from './LeadGroup'
import LeasingStaff from './LeasingStaff'
import TimelineFilterButton from './Timeline/FilterButton'
import TimelineSortButton from './Timeline/SortButton'
import EditPanel from './PersonalDetails/EditPanel'
import Properties from './Properties'
import Applications from './Applications'
// import BreadcrumbBar from './BreadcrumbBar'
import PageTitle from './PageTitle'
import Note from './Note'
import Documents from './Documents'
// import NoteFilterButton from './NoteFilterButton'
// import CreateNewNoteButton from './CreateNewNoteButton'
import * as SC from './StyledComponents'
import axios from 'lib/axios'
import { Link } from 'react-router-dom'
import { Customer } from './types'
import Modals from './Modals'

const CustomerProfile: FunctionComponent<{ userId: string }> = ({ userId }) => {
  const [response, setResponse] = useState<types.TimelineResponse>()
  const [loading, setLoading] = useState(false)
  const [userName, setUserName] = useState({
    firstName: '...',
    lastName: '...'
  })
  const [eventTypesToHide, setEventTypesToHide] = useState<
    types.EventTypeString[]
  >([])
  const [reverseTimeline, setReverseTimeline] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/360/users/${userId}/timeline`)
      .then((response) => {
        setLoading(false)
        setResponse(response.data)
      })
      .catch((err) => {
        throw err
      })
  }, [userId])

  const customer: Customer = {
    userId,
    firstName: userName.firstName,
    lastName: userName.lastName
  }

  const mainContent = (
    <>
      <ContentSection
        title={'Personal Details'}
        content={
          <PersonalDetails userId={userId} setProfileName={setUserName} />
        }
        action={<EditPanel userId={userId} setProfileName={setUserName} />}
        borders={true}
      />
      <ContentSection
        title={'Timeline'}
        content={
          <Timeline
            customer={customer}
            timelineResponse={response}
            loading={loading}
            typesToHide={eventTypesToHide}
            reverse={reverseTimeline}
          />
        }
        action={
          <SC.ButtonContainer>
            <TimelineSortButton
              setReverse={setReverseTimeline}
              reverse={reverseTimeline}
            />
            <TimelineFilterButton
              setTypesToHide={setEventTypesToHide}
              typesToHide={eventTypesToHide}
            />
          </SC.ButtonContainer>
        }
      />
      <ContentSection
        borders
        title={'Documents'}
        content={<Documents userId={userId} />}
      />
      <ContentSection
        borders
        title={'Notes'}
        content={<Note events={response?.timeline} />}
        /*action={
          <SC.ActionBar>
            <CreateNewNoteButton />
            <NoteFilterButton />
          </SC.ActionBar>
        }*/
      />
    </>
  )

  const sidebarContent = (
    <>
      <ContentSection
        title={'Pre-qualification Details'}
        size='small'
        content={<PrequalificationDetails userId={userId} />}
      />
      <ContentSection
        title={'Applications'}
        content={<Applications userId={userId} />}
        size={'large'}
        borders
      />
      <ContentSection
        title={'Properties'}
        content={<Properties homes={response?.properties} />}
        borders={!!response?.properties.length}
        size={'large'}
      />
      <ContentSection
        title={'Lead Group'}
        content={<LeadGroup userId={userId} />}
      />
      <ContentSection
        title={'Assigned Leasing Staff'}
        content={<LeasingStaff userId={userId} />}
      />
    </>
  )

  // mock page history data,
  // TODO: implement working breadcrumb history
  // const breadcrumbPages = {
  //   first: { title: 'Properties', link: '#' },
  //   second: { title: '4140 Parker Rd.', link: '#' }
  // }

  return (
    <SC.PageContainer>
      <SC.PageHeader>
        {/* <BreadcrumbBar history={breadcrumbPages} /> */}
        <PageTitle customer={customer} />
        <Link
          to={{
            pathname: `/legacy/users/${userId}`
          }}
        >
          Legacy user page
        </Link>
      </SC.PageHeader>
      <SC.PageContent>
        <TwoColumnLayout
          mainContent={mainContent}
          sidebarContent={sidebarContent}
        />
      </SC.PageContent>
      <Modals />
    </SC.PageContainer>
  )
}

export default CustomerProfile
