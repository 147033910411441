import _ from 'lodash'
import React, { FunctionComponent } from 'react'
import { NestedDetails, PropertyLine, PropertyTitle } from './StyledComponents'
import { RenderableField } from './RenderFields'

type RenderPropertyDetailsPropTypes = {
  details: any
}

const fieldsToShow: RenderableField[] = [
  { fieldName: 'id', displayName: 'ID' },
  { fieldName: 'address', displayName: 'Address' },
  { fieldName: 'city', displayName: 'City' },
  { fieldName: 'state', displayName: 'State' },
  { fieldName: 'county', displayName: 'County' },
  { fieldName: 'zip', displayName: 'Zip' },
  { fieldName: 'size', displayName: 'Size', unit: 'sqft' },
  { fieldName: 'listPrice', displayName: 'List price' },
  { fieldName: 'bathsHalf', displayName: 'Full baths' },
  { fieldName: 'bathsFull', displayName: 'Half baths' },
  { fieldName: 'status', displayName: 'Status' },
  { fieldName: 'area', displayName: 'Area' },
  { fieldName: 'rent', displayName: 'Rent', unit: '/ month' },
  { fieldName: 'internalStatus', displayName: 'Internal status' },
  { fieldName: 'market', displayName: 'Market' },
  { fieldName: 'description', displayName: 'Description' }
]

// this component is for rendering property details within a
// displayed interaction on the customer timeline

const RenderPropertyDetails: FunctionComponent<
  RenderPropertyDetailsPropTypes
> = ({ details }) => {
  return (
    <NestedDetails>
      {fieldsToShow.map(({ fieldName, displayName, unit }, i) => {
        if (!details[fieldName]) return null

        return (
          <PropertyLine key={i}>
            <PropertyTitle>{_.capitalize(displayName)}</PropertyTitle>:{' '}
            {details[fieldName] + (unit ? ' ' + unit : '')}
          </PropertyLine>
        )
      })}
    </NestedDetails>
  )
}

export default RenderPropertyDetails
