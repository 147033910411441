import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import { format } from 'date-fns'
import _ from 'lodash'
import { Customer } from '../types'
import * as SC from '../../StyledComponents'
import DisplayPropertyDetails from './PropertyDetails'
import { mapContentToBadge } from '../../badges'

export const DisplayRentalApplication: FunctionComponent<{
  event: types.RentalAppTimelineEvent
  expanded: boolean
  customer: Customer
  homes?: types.HomeDetails[]
}> = ({ event, expanded, customer, homes }) => {
  const homeDetails = homes?.find((h) => h.mlsListingId === event.mlsListingId)

  const expandedContent = (
    <>
      <DisplayPropertyDetails details={homeDetails} />
      <SC.MessageText>
        <SC.InlineBold>ID</SC.InlineBold> {event.rentalApplicationId}
      </SC.MessageText>
      <SC.MessageText>
        <SC.InlineBold>{_.capitalize(event.status)}</SC.InlineBold>
        at {format(new Date(event.eventTimeAt), 'PPPp')}
      </SC.MessageText>
    </>
  )
  const ApplicationBadge: FunctionComponent = () => {
    if (event.status === 'reviewed') {
      if (event.metadata?.is_review_approved === true) {
        return mapContentToBadge('review_approved')
      } else if (event.metadata?.is_review_approved === false) {
        return mapContentToBadge('review_unapproved')
      }
    }
    return mapContentToBadge(event.status)
  }

  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>
          {customer.firstName} {customer.lastName}'s
        </SC.InlineBold>{' '}
        application for{' '}
        <SC.InlineBold>{homeDetails?.address || 'some property'}</SC.InlineBold>{' '}
        was {event.status}
      </SC.MessageText>
      <ApplicationBadge />
      {expanded ? <SC.FadeIn>{expandedContent}</SC.FadeIn> : null}
    </div>
  )
}
