import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'

import { HeaderText } from 'components/Toolkit'
import CreditReport from './CreditReport'
import CriminalReport from './CriminalReport'
import EvictionReport from './EvictionReport'

import { hasCapability } from 'lib/admin-perms'
const { USER_DOC_VIEWER } = capabilities.CAPABILITY_TYPES

function UserBackgroundCheckInfo({ leadGroupUser }) {
  const creditReport = get(leadGroupUser, 'user.credit_report', null)

  const criminalReport = get(leadGroupUser, 'user.criminal_report', null)

  const evictionReport = get(leadGroupUser, 'user.eviction_report', null)

  const isDocViewer = useSelector((store) =>
    hasCapability(store.admin, USER_DOC_VIEWER)
  )

  if (!isDocViewer) {
    return null
  }

  return (
    <div>
      <div>
        <HeaderText style={{ marginTop: '2rem' }} size='h3'>
          Background Check Info
        </HeaderText>
      </div>
      <CreditReport creditReport={creditReport} />
      <CriminalReport criminalReport={criminalReport} />
      <EvictionReport evictionReport={evictionReport} />
    </div>
  )
}

UserBackgroundCheckInfo.propTypes = {
  leadGroupUser: PropTypes.object
}

export default memo(UserBackgroundCheckInfo)
