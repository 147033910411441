import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap'
const {
  leadGroups: { LEAD_GROUP_USER_TYPES }
} = require('@homevest/utils')
const startCase = require('lodash/startCase')

export default class UpdateModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    leadGroup: PropTypes.object.isRequired,
    isActioning: PropTypes.bool.isRequired
  }

  state = {
    originalUserIdsToLeadGroupUsers: {},
    users: [],
    newUserId: '',
    newType: ''
  }

  componentDidMount() {
    const { leadGroup } = this.props

    const newOriginalUserIdsToLeadGroupUsers =
      leadGroup.lead_group_users.reduce((acc, lgu) => {
        acc[lgu.user.id] = lgu

        return acc
      }, {})

    this.setState({
      originalUserIdsToLeadGroupUsers: newOriginalUserIdsToLeadGroupUsers,
      users: leadGroup.lead_group_users.map(({ user }) => user)
    })
  }

  handleSubmitUpdate = () => {
    const { onUpdate, leadGroup } = this.props
    const { originalUserIdsToLeadGroupUsers, users, newUserId, newType } =
      this.state

    const usersToAdd = []

    if (newUserId && newType && !originalUserIdsToLeadGroupUsers[newUserId]) {
      usersToAdd.push({ user_id: newUserId, type: newType })
    }

    const userIds = users.map((user) => user.id)

    const leadGroupUserIdsToRemove = Object.keys(
      originalUserIdsToLeadGroupUsers
    ).reduce((acc, userId) => {
      if (!userIds.includes(userId)) {
        acc.push(originalUserIdsToLeadGroupUsers[userId].id)
      }

      return acc
    }, [])

    onUpdate(leadGroup.id, usersToAdd, leadGroupUserIdsToRemove)
  }

  removeUser = (userIndexToRemove) => {
    const { users } = this.state

    this.setState({
      users: users.filter((user, idx) => idx !== userIndexToRemove)
    })
  }

  render() {
    const { onClose, isActioning } = this.props
    const { users, newUserId, newType, originalUserIdsToLeadGroupUsers } =
      this.state

    const userIds = users.map((user) => user.id)
    const userIdsToRemove = Object.keys(originalUserIdsToLeadGroupUsers).filter(
      (id) => userIds.indexOf(id) === -1
    )

    const canUpdate =
      !isActioning && ((newUserId && newType) || userIdsToRemove.length)

    return (
      <>
        <Modal size='lg' isOpen toggle={onClose}>
          <ModalHeader>Update Lead Group</ModalHeader>
          <ModalBody>
            {users.length ? (
              <div>
                <div>
                  <b>Existing users *</b>
                </div>
                <div>
                  {users.map((user, idx) => (
                    <div style={{ padding: '10px 0' }} key={user.id}>
                      {`${user.first_name} ${user.last_name}`}
                      <Button
                        size='sm'
                        style={{ marginLeft: '1rem' }}
                        color='danger'
                        onClick={() => this.removeUser(idx)}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <div>
              <div>
                <b>Add new user by User ID *</b>
              </div>
              <div>
                <Input
                  onChange={(event) =>
                    this.setState({ newUserId: event.target.value })
                  }
                  value={newUserId}
                  type='text'
                />
                <Input
                  style={{ marginTop: '1rem' }}
                  onChange={(event) =>
                    this.setState({ newType: event.target.value })
                  }
                  value={newType}
                  type='select'
                >
                  <option value=''>Select the tenant type</option>
                  {Object.values(LEAD_GROUP_USER_TYPES).map((type) => (
                    <option key={type} value={type}>
                      {startCase(type)}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              disabled={!canUpdate}
              onClick={this.handleSubmitUpdate}
            >
              {isActioning ? 'Updating...' : 'Update'}
            </Button>
            <Button color='secondary' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
