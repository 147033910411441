import React, { useEffect, useState } from 'react'

//import { capabilities } from '@homevest/utils'
import { Spinner, Table } from 'reactstrap'
import styled from 'styled-components'
//import { useSelector } from 'react-redux'

import {
  Dropdown,
  HeaderText,
  ErrorText,
  SuccessText
} from 'components/Toolkit'
import { useMutation, useQuery, useSubscription } from 'urql'
import {
  UpUpAddProcessedUtilityBillDocument,
  UpupProcessedUtilityBillsDocument,
  UpUpPropertyInformationForUtilityBillsDocument
} from 'graphql/generated'
import moment from 'moment'
import { parseUtilityBillData } from './utility_chargebacks'
//import { hasCapability } from 'lib/admin-perms'
//const { DAGGER } = capabilities.CAPABILITY_TYPES
const CONSERVICE = 'conservice'
const BILL_TYPES = Object.freeze({
  Conservice: CONSERVICE
})

const StyledContent = styled.div`
  width: 90%;
  margin: 0 auto;
`

export default function ProrationEngineUploader() {
  const isDagger = true
  // left since i'll be gone for the week
  //useSelector((store: any) =>
  //   hasCapability(store.admin, DAGGER)
  // )

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        Proration Engine Uploader
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <ProrationEngineUploaderViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function ProrationEngineUploaderViewer() {
  const [billType, setBillType] = useState<string>(CONSERVICE)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [{ data }] = useQuery({
    query: UpUpPropertyInformationForUtilityBillsDocument
  })

  const [propertyData, setPropertyData] = useState<any>()

  useEffect(() => {
    setPropertyData(data)
  }, [data])

  const [showAllRows, setShowAllRows] = React.useState<{
    buildium: boolean[]
    rental_liabilities: boolean[]
  }>({ buildium: [false], rental_liabilities: [false] })

  const [processed_utility_bills, set_processed_utility_bills] = useState<
    {
      buildium_csv: string
      original_upload_url: any
      rental_liabilities: any
      transformations: string
      created_at: string
      rental_liabilities_csv: string
    }[]
  >([])

  const [{ data: subscriptionData }] = useSubscription({
    query: UpupProcessedUtilityBillsDocument
  })

  console.log(subscriptionData)

  const [_, saveProcessedUtilityBill] = useMutation(
    UpUpAddProcessedUtilityBillDocument
  )

  React.useEffect(() => {
    try {
      const { kafka_connector_prorated_utility_bill_line_items } =
        subscriptionData

      set_processed_utility_bills(
        kafka_connector_prorated_utility_bill_line_items ?? []
      )

      setShowAllRows({
        buildium: Array(
          kafka_connector_prorated_utility_bill_line_items.length
        ).fill(false),
        rental_liabilities: Array(
          kafka_connector_prorated_utility_bill_line_items.length
        ).fill(false)
      })
    } catch (err) {
      console.log(err)
    }
  }, [subscriptionData])

  const handleSuccess = async (csv_data: string) => {
    console.log('handling', csv_data)
    try {
      setIsLoading(true)
      setSuccessMessage('')
      parseUtilityBillData(
        csv_data,
        {
          ...propertyData
        },
        saveProcessedUtilityBill
      )
    } catch (err: any) {
      if (err.isAxiosError) {
        setErrorMessage(err.response.data)
      } else {
        setErrorMessage(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const FileUploadPage = () => {
    const [selectedFile, setSelectedFile] = useState<any>()
    const [isSelected, setIsSelected] = useState<any>(false)

    const changeHandler = (event: any) => {
      setSelectedFile(event.target.files[0])
      setIsSelected(true)
    }

    const handleSubmission = () => {
      const reader = new FileReader()
      reader.onload = (event) =>
        event && event.target
          ? handleSuccess(event?.target?.result as string)
          : null
      reader.onerror = (error) => console.log(error)
      reader.readAsText(selectedFile)
    }

    return (
      <div>
        <input type='file' name='file' onChange={changeHandler} />
        {isSelected ? (
          <div>
            <p>Filename: {selectedFile.name}</p>
            <p>Filetype: {selectedFile.type}</p>
            <p>Size in bytes: {selectedFile.size}</p>
            <p>
              lastModifiedDate:{' '}
              {selectedFile.lastModifiedDate.toLocaleDateString()}
            </p>
          </div>
        ) : (
          <p>Select a file to show details</p>
        )}
        <div>
          <button onClick={handleSubmission}>Submit</button>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>{successMessage}</SuccessText>
      </div>
    )
  }

  function CSVToArray(strData: string, strDelimiter: string) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ','

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      '(\\' +
        strDelimiter +
        '|\\r?\\n|\\r|^)' +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        '\\r\\n]*))',
      'gi'
    )

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData: string[][] = [[]]

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1]

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([])
      }

      var strMatchedValue

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3]
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue)
    }

    // Return the parsed data.
    return arrData
  }

  return (
    <div>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <h1>Upload a bill to the proration engine.</h1>

      <Dropdown
        label='Bill Type'
        value={billType}
        onChange={(val) => {
          alert(val)
          setBillType(val)
        }}
        options={Object.entries(BILL_TYPES).map(([key, value]) => {
          return {
            label: key,
            value
          }
        })}
      />

      <FileUploadPage />

      <h1>Processed Files</h1>
      <Table responsive>
        <thead>
          <tr>
            <th>Links</th>
            <th>Csv Samples</th>
          </tr>
        </thead>

        <tbody>
          {processed_utility_bills.map(
            (
              {
                buildium_csv,
                original_upload_url,
                created_at,
                rental_liabilities_csv,
                transformations
              },
              index
            ) => {
              const buildiumCSV = CSVToArray(buildium_csv, ',')

              const buildiumCsvFile = new Blob([buildium_csv], {
                type: 'text/csv'
              })
              const buildium_file_url = URL.createObjectURL(buildiumCsvFile)

              const rentalLiabilitiesCsv = CSVToArray(
                rental_liabilities_csv,
                ','
              )
              const rentalLiabilitiesCsvFile = new Blob(
                [rental_liabilities_csv],
                {
                  type: 'text/csv'
                }
              )
              const rental_liabilities_file_url = URL.createObjectURL(
                rentalLiabilitiesCsvFile
              )

              const transformationsFile = new Blob(
                [JSON.stringify(JSON.parse(transformations), null, 4)],
                {
                  type: 'text/plain'
                }
              )
              const transformations_file_url =
                URL.createObjectURL(transformationsFile)

              const toggleViewAllRentalLiabilityRows = (
                <h6
                  onClick={() => {
                    setShowAllRows({
                      rental_liabilities: [
                        ...showAllRows.rental_liabilities.slice(0, index),
                        !showAllRows.rental_liabilities[index],
                        ...showAllRows.rental_liabilities.slice(index + 1)
                      ],
                      buildium: showAllRows.buildium
                    })
                  }}
                >
                  Toggle View All
                </h6>
              )

              const toggleViewAllBuildiumRows = (
                <h6
                  onClick={() => {
                    setShowAllRows({
                      buildium: [
                        ...showAllRows.buildium.slice(0, index),
                        !showAllRows.buildium[index],
                        ...showAllRows.buildium.slice(index + 1)
                      ],
                      rental_liabilities: showAllRows.rental_liabilities
                    })
                  }}
                >
                  Toggle View All
                </h6>
              )

              return (
                <tr key={original_upload_url}>
                  <td scope='row' style={{ minWidth: 280 }}>
                    <a href={original_upload_url} target='_blank'>
                      Original Csv
                    </a>

                    <br />

                    <p>
                      Processed on: {moment(created_at).format('MMM D, YYYY')}
                    </p>

                    <a href={buildium_file_url} download={'buildium-utilities'}>
                      Download Buildium CSV
                    </a>

                    <br />

                    <a
                      href={rental_liabilities_file_url}
                      download={'rental-liabilities-utilities'}
                    >
                      Download Rental Liabilities CSV
                    </a>

                    <br />

                    <a
                      href={transformations_file_url}
                      download={'utility-calculations'}
                    >
                      Download Calculations
                    </a>
                    <br />
                  </td>

                  <td>
                    {[
                      {
                        header: 'Buildium CSV Sample',
                        csv: buildiumCSV,
                        type: 'buildium',
                        toggleButton: toggleViewAllBuildiumRows
                      },
                      {
                        header: 'Rental Liabilities CSV Sample',
                        csv: rentalLiabilitiesCsv,
                        type: 'rental_liabilities',
                        toggleButton: toggleViewAllRentalLiabilityRows
                      }
                    ].map(
                      ({
                        header,
                        csv,
                        type,
                        toggleButton
                      }: {
                        header: string
                        csv: string[][]
                        type: string
                        toggleButton: JSX.Element
                      }) => {
                        return (
                          <>
                            <h3>{header}</h3>
                            {toggleButton}
                            <Table responsive>
                              <thead>
                                <tr>
                                  {csv[0].map((headerVal) => (
                                    <th>{headerVal}</th>
                                  ))}
                                </tr>
                              </thead>

                              <tbody>
                                {(showAllRows[
                                  type as 'buildium' | 'rental_liabilities'
                                ][index]
                                  ? csv.slice(1)
                                  : csv.slice(1, 6)
                                ).map((row: string[]) => {
                                  return (
                                    <tr>
                                      {row.map((val: string) => (
                                        <td>
                                          <p style={{ minWidth: 100 }}>{val}</p>
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </>
                        )
                      }
                    )}
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </Table>
    </div>
  )
}
