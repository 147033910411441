import firebase from '../lib/firebase'
import { receivedAdmin } from '../state/admin/actions'
import { setInitialPath } from '../state/url/actions'
import axios from '../lib/axios'

export default (store) => (next) => (action) => {
  switch (action.type) {
    case 'BOOTSTRAPPED':
      next(action)
      trackHistory(store)
      syncAuth(store)
      break

    default:
      next(action)
  }
}

export function syncAuth(store) {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (!user) return

    try {
      const adminRes = await axios.get('admins/me')

      const admin = adminRes.data

      // eslint-disable-next-line no-undef
      window.analytics.identify(admin.id, {
        name: admin.name,
        email: admin.email
      })
      store.dispatch(receivedAdmin(admin))
    } catch (err) {
      // eslint-disable-next-line no-undef
      window.alert(err)
      console.error(err)
      // eslint-disable-next-line no-undef
      window.alert(`No admin found with email ${user.email}`)
    }
  })
}

function trackHistory(store) {
  const state = store.getState().url

  if (!state.landingPath) {
    // eslint-disable-next-line no-undef
    const landingPath = window.location.pathname

    store.dispatch(setInitialPath(landingPath))
  }
}
