import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { sellerAgents } from '@homevest/utils'

const { SELLER_AGENT_PROPERTY_APPROVAL_STATUSES } = sellerAgents

export default function AgentPropertyPendingHomeOwnerApprovalButton({
  isDisabled,
  onUpdate,
  sellerAgentPropertyId,
  style
}) {
  const handlePendingHomeOwnerApproval = async () => {
    if (
      !isDisabled &&
      window.confirm(
        'Are you sure the home owner just has to sign off? This is irreversible ⚠.'
      )
    ) {
      await onUpdate(
        {
          approval_status:
            SELLER_AGENT_PROPERTY_APPROVAL_STATUSES.PENDING_HOME_OWNER_APPROVAL
        },
        sellerAgentPropertyId
      )
    }
  }

  return (
    <Button
      type='button'
      disabled={isDisabled}
      size='sm'
      color='info'
      style={style}
      onClick={handlePendingHomeOwnerApproval}
    >
      Need Owner Approval
    </Button>
  )
}

AgentPropertyPendingHomeOwnerApprovalButton.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  sellerAgentPropertyId: PropTypes.string,
  style: PropTypes.object
}

AgentPropertyPendingHomeOwnerApprovalButton.defaultProps = {
  isDisabled: false,
  onUpdate: () => null,
  style: {}
}
