import moment from 'moment'
import { payments as PAYMENTS, rentals as RENTALS } from '@homevest/utils'

import { getLedgerItemsWithBalance } from 'lib/ledger'
import { getContactInfo } from './users'
import { UpupRentalListQuery } from 'graphql/generated'
import FullRental from 'types/FullRental'

const { PAYMENT_CATEGORIES, PAYMENT_STATUSES } = PAYMENTS
const { LEASE_END_REASONS, RENTAL_STATUS } = RENTALS

type Rental = UpupRentalListQuery['rentals'][number]

export const getAddress = (rental: Rental) => {
  const mlsListing = rental.property.real_estate_acquisition?.mls_listing

  return mlsListing
    ? `${mlsListing.display_line_1}, ${mlsListing.display_line_2}`
    : 'Need to backfill real estate acquisition to get address'
}

export const getMoveOutDate = (rental: Rental) => {
  const {
    final_liability_date: finalLiabilityDate,
    move_out_date: moveOutDate
  } = rental

  const rentalAgreements = rental.rental_agreement_histories || []

  const endAt =
    moveOutDate || finalLiabilityDate || rentalAgreements[0]?.ends_at

  if (!endAt) {
    return null
  }

  if (endAt.includes('/')) {
    return moment(endAt, 'M/D/YYYY')
  }

  return moment(endAt)
}

export const getMoveOutStatus = (rental: Rental) => {
  const { occupancy_date: occupancyDate, status } = rental

  const moveOutDate = getMoveOutDate(rental)
  const isFutureMoveIn = moment(occupancyDate).isAfter(moment())

  const daysUntilMoveout = moveOutDate
    ? moment(moveOutDate).diff(moment().startOf('day'), 'days')
    : 0

  let statusLabel: string

  if (typeof daysUntilMoveout === 'number') {
    if (daysUntilMoveout > 90) {
      statusLabel = isFutureMoveIn ? 'Pending move-in' : '3+ months'
    } else if (status === 'active') {
      if (daysUntilMoveout < 0) {
        statusLabel = 'Holdover'
      } else {
        statusLabel = `${daysUntilMoveout}`
      }
    } else if (status === 'pending') {
      statusLabel = 'Pending move-in'
    } else {
      statusLabel = 'Moved out'
    }
  } else if (status === 'active') {
    statusLabel = 'Holdover'
  } else if (status === 'pending') {
    statusLabel = 'Pending move-in'
  } else {
    statusLabel = 'Moved out'
  }

  return {
    daysUntilMoveout,
    statusLabel
  }
}

export const getLlc = (rental: Rental) => {
  const {
    property: { llc_properties: llcProperties }
  } = rental

  const llcProperty = llcProperties ? llcProperties[0] : null
  return llcProperty?.llc
}

export const convertRentalToFullRental = (rental: Rental): FullRental => {
  const {
    occupancy_date: rawOccupancyDate,
    payments = [],
    property: {
      real_estate_acquisition: realEstateAcquisition = { mls_listing: {} }
    },
    rental_agreement_histories: rentalAgreementHistories,
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = [],
    target_monthly_option_premium: targetMonthlyOptionPremium
  } = rental

  const { display_line_1: displayLine1, display_line_2: displayLine2 } =
    realEstateAcquisition?.mls_listing || ({} as any)

  const address = `${displayLine1}, ${displayLine2}`

  const { option_premium: optionPremium, rent } =
    rentalAgreementHistories.find((rah) => {
      const { ends_at: endsAt, starts_at: startsAt } = rah

      const occupancyDate = moment(rawOccupancyDate).startOf('day')
      const today = moment().startOf('day')
      const dateForAgreement = occupancyDate.isAfter(today)
        ? occupancyDate
        : today

      return (
        dateForAgreement.isSameOrAfter(startsAt) &&
        (!endsAt || dateForAgreement.isSameOrBefore(endsAt))
      )
    }) || {}

  const { statusLabel, daysUntilMoveout } = getMoveOutStatus(rental)

  const { balance, investmentToDate, rentPaidToDate } =
    getLedgerItemsWithBalance(payments, rentalLiabilities, rentalCredits)

  return {
    ...rental,
    address,
    balance,
    daysUntilMoveout,
    investmentToDate: investmentToDate,
    optionPremium: optionPremium || targetMonthlyOptionPremium / 100,
    rent,
    rentPaidToDate: rentPaidToDate,
    statusLabel
  }
}

export const convertRentalToCsvLedgerData = (
  rental: FullRental,
  addBalance = false
) => {
  const csvData = [
    [
      'rental_id',
      'property_id',
      'address',
      'llc_name',
      'tenant',
      'email',
      'phone',
      'date',
      'available_on',
      'category',
      'amount',
      'type',
      'status'
    ]
  ]

  const {
    balance,
    payments = [],
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = [],
    user
  } = rental

  if (!payments.length && !rentalLiabilities.length) {
    return csvData
  }

  const address = getAddress(rental)
  const email = getContactInfo('email', user.user_contact_details)
  const phone = getContactInfo('phone', user.user_contact_details)
  const llc = getLlc(rental)
  const llcName = llc?.name
  const propertyId = rental.property?.id

  payments.forEach((payment) => {
    const newCsvData = [
      rental.id,
      propertyId,
      address,
      llcName,
      `${user.first_name} ${user.last_name}`,
      email,
      phone,
      payment.payment_date || moment(payment.created_at).format('YYYY-MM-DD'),
      payment.available_on,
      payment.liability_type.name,
      payment.amount.toFixed(2),
      payment.payment_method === 'rental_credit' ? 'credit' : 'payment',
      payment.status
    ]

    csvData.push(newCsvData)
  })

  rentalLiabilities.forEach((rl) => {
    const newCsvData = [
      rental.id,
      propertyId,
      address,
      llcName,
      `${user.first_name} ${user.last_name}`,
      email,
      phone,
      rl.date,
      '',
      rl.liability_type.name,
      rl.amount.toFixed(2),
      'charge',
      ''
    ]

    csvData.push(newCsvData)
  })

  rentalCredits.forEach((credit) => {
    const newCsvData = [
      rental.id,
      propertyId,
      address,
      llcName,
      `${user.first_name} ${user.last_name}`,
      email,
      phone,
      credit.date,
      '',
      'Rent Credit',
      credit.amount.toFixed(2),
      'credit',
      ''
    ]

    csvData.push(newCsvData)
  })

  if (addBalance) {
    csvData.unshift(['Balance', `${balance}`])
    csvData.unshift(['Date', moment().format('YYYY-MM-DD')])
  }

  return csvData
}

export const convertRentalToCsvBalanceData = (rental: FullRental) => {
  const csvData = [
    [
      'rental_id',
      'property_id',
      'address',
      'tenant',
      'status',
      'email',
      'phone',
      'llc_name',
      'balance',
      '0-30',
      '30-60',
      '60-90',
      '90+'
    ]
  ]

  const address = getAddress(rental)
  const llc = getLlc(rental)
  const llcName = llc?.name
  const propertyId = rental.property?.id

  const {
    payments = [],
    rental_credits: rentalCredits = [],
    rental_liabilities: rentalLiabilities = [],
    user
  } = rental

  if (!payments.length && !rentalLiabilities.length) {
    return csvData
  }

  const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day')
  const sixtyDaysAgo = moment().subtract(60, 'days').startOf('day')
  const ninetyDaysAgo = moment().subtract(90, 'days').startOf('day')

  let zeroToThirtyBalance = 0
  let thirtyToSixtyBalance = 0
  let sixtyToNinetyBalance = 0
  let ninetyPlusBalance = 0

  payments.forEach(
    ({
      amount,
      category,
      created_at: createdAt,
      payment_date: paymentDate,
      status
    }) => {
      const date = paymentDate || moment(createdAt).format('YYYY-MM-DD')

      if (
        category !== PAYMENT_CATEGORIES.INITIAL_PAYMENT &&
        [PAYMENT_STATUSES.PROCESSING, PAYMENT_STATUSES.SUCCEEDED].includes(
          status as any
        )
      )
        if (ninetyDaysAgo.isAfter(date)) {
          ninetyPlusBalance -= amount
        } else if (sixtyDaysAgo.isAfter(date)) {
          sixtyToNinetyBalance -= amount
        } else if (thirtyDaysAgo.isAfter(date)) {
          thirtyToSixtyBalance -= amount
        } else {
          zeroToThirtyBalance -= amount
        }
    }
  )

  rentalCredits.forEach(({ amount, date }) => {
    if (ninetyDaysAgo.isAfter(date)) {
      ninetyPlusBalance -= amount
    } else if (sixtyDaysAgo.isAfter(date)) {
      sixtyToNinetyBalance -= amount
    } else if (thirtyDaysAgo.isAfter(date)) {
      thirtyToSixtyBalance -= amount
    } else {
      zeroToThirtyBalance -= amount
    }
  })

  rentalLiabilities.forEach(({ amount, date }) => {
    if (ninetyDaysAgo.isAfter(date)) {
      ninetyPlusBalance += amount
    } else if (sixtyDaysAgo.isAfter(date)) {
      sixtyToNinetyBalance += amount
    } else if (thirtyDaysAgo.isAfter(date)) {
      thirtyToSixtyBalance += amount
    } else {
      zeroToThirtyBalance += amount
    }
  })

  csvData.push([
    rental.id,
    propertyId,
    address,
    `${user.first_name} ${user.last_name}`,
    getStatusForLeaseEndDateAndLeaseEndReason(
      rental.move_out_date,
      rental.lease_end_reason || '',
      rental.status
    ),
    getContactInfo('email', user?.user_contact_details) || '',
    getContactInfo('phone', user?.user_contact_details) || '',
    llcName,
    rental.balance.toFixed(2),
    zeroToThirtyBalance.toFixed(2),
    thirtyToSixtyBalance.toFixed(2),
    sixtyToNinetyBalance.toFixed(2),
    ninetyPlusBalance.toFixed(2)
  ])

  return csvData
}

export const getStatusForLeaseEndDateAndLeaseEndReason = (
  leaseEndDate: string,
  leaseEndReason: string,
  defaultStatus: string
) =>
  leaseEndDate && moment(leaseEndDate).isBefore(moment().startOf('day'))
    ? [
        LEASE_END_REASONS.PURCHASED_HOME,
        LEASE_END_REASONS.SWITCHED_HOMES,
        LEASE_END_REASONS.LEFT_PROGRAM
      ].includes(leaseEndReason)
      ? RENTAL_STATUS.COMPLETE
      : RENTAL_STATUS.CANCELED
    : defaultStatus

export const getRentalLeaseSigners = (rental: FullRental) => {
  const { rental_application: rentalApplication } = rental

  const { agreements }: any = rentalApplication || { agreements: [] }

  const { agreement_signers: agreementSigners } = agreements[0] || {
    agreement_signers: []
  }

  return agreementSigners.sort((a: any, b: any) =>
    a.role === 'Tenant #1' ? -1 : b.role === 'Tenant #1' ? 1 : 0
  )
}
