import React, { memo, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Tag from 'components/Toolkit/Tag'
import COLORS from 'components/Toolkit/colors'
import Input from 'components/Toolkit/Inputs/Input'
import { BodyText, Dropdown } from 'components/Toolkit'

const StyledResults = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  border-radius: 10px;
  z-index: 2;
  background-color: #fff;
  margin-top: 20px;
`

const StyledOption = styled.div`
  border-bottom: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  padding: 10px 20px;
  cursor: pointer;
`

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
`

const StyledTags = styled.div`
  margin-top: 15px;
`

function MultiSelect({
  onChange = () => null,
  options = [],
  values = [],
  isSearchable = true
}) {
  const [valueToItem] = useState(
    options.reduce((acc, item) => ({ ...acc, ...{ [item.value]: item } }), {})
  )
  const [searchText, setSearchText] = useState('')
  const optionsLeft = options.filter((item) => !values.includes(item.value))
  const searchOptions = isSearchable
    ? optionsLeft
        .filter(
          (item) =>
            item.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
        .slice(0, 5)
    : optionsLeft

  return (
    <StyledContainer>
      {isSearchable ? (
        <div>
          <Input
            placeholder='Search...'
            value={searchText}
            onChange={setSearchText}
          />
          {searchText && searchOptions.length ? (
            <StyledResults>
              {searchOptions.map((item) => (
                <StyledOption
                  key={item.value}
                  onClick={() => {
                    onChange(values.concat(item.value))
                    setSearchText('')
                  }}
                >
                  <BodyText>
                    <div>{item.label}</div>
                  </BodyText>
                </StyledOption>
              ))}
            </StyledResults>
          ) : null}
        </div>
      ) : (
        <Dropdown
          value=''
          onChange={(val) => onChange(values.concat(val))}
          options={searchOptions}
        />
      )}

      <StyledTags>
        {values.map((item, idx) => (
          <Tag
            key={valueToItem[item].value}
            label={valueToItem[item].label}
            onClose={() => {
              const newList = values.filter(
                (value, valueIdx) => idx !== valueIdx
              )
              onChange(newList)
            }}
          />
        ))}
      </StyledTags>
    </StyledContainer>
  )
}

MultiSelect.propTypes = {
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired
}

export default memo(MultiSelect)
