import React from 'react'
import { Link } from 'react-router-dom'
import startCase from 'lodash/startCase'

import { HeaderText } from 'components/Toolkit'
import FullRental from 'types/FullRental'
import { getRentalLeaseSigners } from 'lib/rentals'
import { getContactInfo } from 'lib/users'

export default function Tenants({ rental }: { rental: FullRental }) {
  const { user } = rental

  const agreementSigners = getRentalLeaseSigners(rental)

  return (
    <div style={{ marginBottom: '1rem' }}>
      <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
        Tenants
      </HeaderText>
      {agreementSigners.length ? (
        agreementSigners.map(({ role, user }: { role: any; user: any }) => (
          <div key={user?.id} style={{ marginBottom: '.5rem' }}>
            <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
              {user?.first_name} {user?.last_name} - {startCase(role)}
              <br />
              {getContactInfo('email', user?.user_contact_details)}{' '}
              {getContactInfo('phone', user?.user_contact_details)}
            </Link>
          </div>
        ))
      ) : (
        <div>
          <div>Need to backfill agreement signers</div>
          <div style={{ marginBottom: '.5rem' }}>
            <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
              {user?.first_name} {user?.last_name} - Primary
              <br />
              {getContactInfo('email', user?.user_contact_details)}{' '}
              {getContactInfo('phone', user?.user_contact_details)}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
