// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!

import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import styled from 'styled-components'
import moment from 'moment'
import { get, startCase } from 'lodash'

import {
  Button,
  BodyText,
  COLORS,
  HeaderText,
  Input,
  RadioGroup
} from 'components/Toolkit'
import {
  Notes_Bool_Exp as NotesBoolExp,
  UpupGenericNotesDocument as queryDoc,
  Order_By as OrderBy
} from 'graphql/generated'
import CreateNoteModal from './CreateNoteModal'

import axios from 'lib/axios'

import NoteWidgetOption from 'types/NoteWidgetOption'

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
  margin-top: 50px;
`

function getAuthorLine(note: any): string {
  const author = note.admin

  return `By: ${author.first_name} ${author.last_name}`
}

function displayNote(text: string, term: string): string {
  if (!term) {
    return text
  }
  return text.replace(
    new RegExp(term, 'gi'),
    (match) => `<span style="background-color: yellow">${match}</span>`
  )
}

function getCategoryColor(category: string): string {
  switch (category) {
    case 'access':
    case 'home_purchase':
    case 'move_in':
      // bright green
      return '#00ff00'
    case 'move_out':
      // orange
      return '#FFA500'
    case 'project_notes':
    case 'insurance':
      // light blue
      return '#ADD8E6'
    case 'collections':
    case 'evictions':
      // red
      return '#FF0000'
    case 'work_order':
      // pink
      return '#FFC0CB'
    default:
      // light grey
      return '#D3D3D3'
  }
}

function getEntityLine(
  note: any,
  noteWidgetOptions: NoteWidgetOption[],
  entityType: string
): string {
  const option = noteWidgetOptions.find((nwo) => {
    if (entityType === 'resource') {
      return nwo.id === note.resource_id
    } else if (entityType === 'subject') {
      return nwo.id === note.subject_id
    }
    return false
  })
  const displayEntityType =
    entityType === 'resource' ? note.resource_type : note.subject_type

  let display = startCase(displayEntityType)
  if (option && option.displayName) {
    display = option.displayName
  }

  if (option && option.linkTo) {
    return `${startCase(entityType)}: <a target="_blank" href="${
      option.linkTo
    }">${display}</a>`
  } else {
    return `${startCase(entityType)}: ${display}`
  }
}

function Notes({
  headerless = false,
  readCategories = [],
  resourceNoteWidgetOptions = [],
  title = 'Notes'
}: {
  headerless?: boolean
  readCategories?: string[]
  resourceNoteWidgetOptions?: NoteWidgetOption[]
  title?: string
  writeCategory?: string | null
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sortOrder, setSortOrder] = useState<OrderBy>(OrderBy.DescNullsLast)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const sortOptions: any[] = [
    {
      label: 'Ascending',
      value: OrderBy.AscNullsLast
    },
    {
      label: 'Descending',
      value: OrderBy.DescNullsLast
    }
  ]

  return (
    <>
      {headerless && (
        <div>
          <Button
            isSecondary={false}
            size='vvs'
            style={{ width: '100%' }}
            onClick={() => setIsModalOpen(true)}
          >
            Add Note
          </Button>
        </div>
      )}
      {!headerless && (
        <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
          {title}
          <Button
            isSecondary={false}
            size='vvvs'
            style={{ float: 'right' }}
            onClick={() => setIsModalOpen(true)}
          >
            Add Note
          </Button>
        </HeaderText>
      )}
      <div style={{ marginBottom: '30px' }}>
        <Input
          type={undefined}
          hasError={false}
          label='Search Notes Body'
          value={searchTerm || ''}
          onChange={(value: string) => {
            setSearchTerm(value)
          }}
        />
        <RadioGroup
          label='Sort Notes Date'
          value={sortOrder}
          options={sortOptions}
          onChange={(i) => {
            setSortOrder(i)
          }}
        />
      </div>
      <NotesResults
        readCategories={readCategories}
        resourceNoteWidgetOptions={resourceNoteWidgetOptions}
        isModalOpen={isModalOpen}
        searchTerm={searchTerm}
        sortOrder={sortOrder}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}

function NotesResults({
  isModalOpen,
  sortOrder,
  searchTerm,
  setIsModalOpen,
  readCategories = [],
  resourceNoteWidgetOptions = []
}: {
  isModalOpen: boolean
  readCategories: string[]
  resourceNoteWidgetOptions?: NoteWidgetOption[]
  sortOrder: OrderBy
  searchTerm: string
  setIsModalOpen: (toggle: boolean) => void
}) {
  const { ids, types } = resourceNoteWidgetOptions.reduce<{
    ids: string[]
    types: string[]
  }>(
    (acc, nwo) => {
      acc.ids.push(nwo.id)
      acc.types.push(nwo.type)

      return acc
    },
    {
      ids: [],
      types: []
    }
  )

  const andClause: NotesBoolExp[] = [
    {
      _or: [
        {
          resource_id: { _in: ids },
          resource_type: { _in: types }
        },
        {
          subject_id: { _in: ids },
          subject_type: { _in: types }
        }
      ]
    }
  ]

  if (searchTerm) {
    andClause.push({ note: { _ilike: `%${searchTerm}%` } })
  }

  if (readCategories && readCategories.length) {
    andClause.push({ category: { _in: readCategories } })
  }

  const [{ data }] = useQuery({
    query: queryDoc,
    variables: {
      where: {
        is_deleted: { _eq: false },
        _and: andClause
      },
      created_at: sortOrder
    }
  })

  const notes: any[] = data?.notes ?? []

  return (
    <>
      <NotesResultsDisplay
        isModalOpen={isModalOpen}
        notes={notes}
        resourceNoteWidgetOptions={resourceNoteWidgetOptions}
        setIsModalOpen={setIsModalOpen}
        searchTerm={searchTerm}
      />
    </>
  )
}

function NotesResultsDisplay({
  isModalOpen,
  notes,
  searchTerm,
  setIsModalOpen,
  resourceNoteWidgetOptions = []
}: {
  isModalOpen: boolean
  notes: object[]
  resourceNoteWidgetOptions?: NoteWidgetOption[]
  searchTerm?: string
  setIsModalOpen: (toggle: boolean) => void
}) {
  const [documentUrlsMapped, setDocumentUrlsMapped] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const allDocuments = notes.reduce((acc, note) => {
        return acc.concat(note.documents)
      }, [])

      const documentUrls = await Promise.all(
        allDocuments.map(({ id }) =>
          axios.get(`/admin/documents/${id}/url`).then((resp) => resp.data.url)
        )
      )

      const mapping = allDocuments.reduce(
        (acc, { id }, idx) => ({ ...acc, [id]: documentUrls[idx] }),
        {}
      )

      setDocumentUrlsMapped(mapping)
    }
    return fetchData()
  }, [notes])

  return (
    <>
      {notes.length === 0 && (
        <BodyText size='r'>There are no notes yet.</BodyText>
      )}
      {notes.length > 0 ? (
        <div style={{ marginBottom: '1rem' }}>
          {notes.map((note, idx) => (
            <div key={note.id}>
              {idx !== 0 ? <hr /> : null}
              <BodyText size='r' color={COLORS.PRIMARY.PLUM}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: displayNote(note.note, searchTerm)
                  }}
                />
              </BodyText>
              <div style={{ display: 'flex' }}>
                <div style={{ minWidth: '200px' }}>
                  <BodyText size='s'>{getAuthorLine(note)}</BodyText>
                </div>
                {note.category && (
                  <div
                    style={{
                      minWidth: '30px',
                      borderRadius: '50px',
                      fontSize: '12px',
                      padding: '0px 10px',
                      color: '#FFF',
                      backgroundColor: getCategoryColor(note.category),
                      textAlign: 'center'
                    }}
                  >
                    <BodyText size='s'>{startCase(note.category)}</BodyText>
                  </div>
                )}
              </div>
              <BodyText size='s'>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getEntityLine(
                      note,
                      resourceNoteWidgetOptions,
                      'resource'
                    )
                  }}
                />
              </BodyText>
              {note.subject_id && (
                <BodyText size='s'>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getEntityLine(
                        note,
                        resourceNoteWidgetOptions,
                        'subject'
                      )
                    }}
                  />
                </BodyText>
              )}
              <BodyText size='s'>
                {moment(note.created_at).format(
                  'dddd, MMMM Do YYYY, h:mm:ss a'
                )}
              </BodyText>
              {documentUrlsMapped &&
                get(note, 'documents', []).map((document: any) => (
                  <div key={document.id}>
                    <a
                      href={documentUrlsMapped[document.id]}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {document.friendly_name}
                    </a>
                  </div>
                ))}
            </div>
          ))}
        </div>
      ) : null}
      {isModalOpen && (
        <CreateNoteModal
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
          resourceNoteWidgetOptions={resourceNoteWidgetOptions}
        />
      )}
    </>
  )
}

export default Notes
