import React from 'react'
import { useQuery } from 'urql'
import PropTypes from 'prop-types'

import { Upup_LiabilityTypesDocument as query } from 'graphql/generated'
import { Dropdown } from 'components/Toolkit'

LiabilityTypesDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.object
}

export default function LiabilityTypesDropdown({
  disabled,
  onSelect,
  value
}: {
  disabled: boolean
  onSelect: any
  value: any
}) {
  const [{ data }] = useQuery({
    query
  })

  const liabilityTypes: any[] = data?.liability_types || []

  const liabilityTypeOptions = liabilityTypes.map((lt) => ({
    label: lt.name,
    value: lt.id
  }))

  const onSelectLiabilityType = (id: string) => {
    onSelect(liabilityTypes.find((x) => x.id === id))
  }

  return (
    <Dropdown
      disabled={disabled}
      label='Liability Type'
      placeholder='Select a liability type'
      onChange={onSelectLiabilityType}
      options={liabilityTypeOptions}
      value={value?.id || ''}
    />
  )
}
