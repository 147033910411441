import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import { StoreState } from 'store'
import get from 'lodash/get'

import { formatMoney } from 'lib/numbers'
import FullRental from 'types/FullRental'
import axios from 'lib/axios'
import { Button, HeaderText } from 'components/Toolkit'
import { hasCapability } from 'lib/admin-perms'

const { CUSTOMER_CHARGER } = capabilities.CAPABILITY_TYPES

type LineItem = {
  amount: number
  liabilityTypeId: string
  name: string
}

export default function LineItemsOwed({ rental }: { rental: FullRental }) {
  const [lineItemsOwed, setLineItemsOwed] = useState<LineItem[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const admin = useSelector<StoreState, any>((state) => state.admin)
  const canChargePayment = hasCapability(admin, CUSTOMER_CHARGER)

  useEffect(() => {
    const loadLineItemsOwed = async (rentalId: string) => {
      const res = await axios.get(`/admin/rentals/${rentalId}/line_items_owed`)

      setLineItemsOwed(res.data)
    }

    loadLineItemsOwed(rental.id)
  }, [rental])

  const handleSubmit = async () => {
    if (!lineItemsOwed.length || !canChargePayment) {
      return
    }

    if (
      window.confirm(
        `Are you sure you want to charge this customer for:\n\n${lineItemsOwed.reduce(
          (acc, lineItem) =>
            `${acc}${lineItem.name}: ${formatMoney(lineItem.amount, 2, '$')}\n`,
          ''
        )}\nReversing is a pain in the ass ⚠.`
      )
    ) {
      try {
        setIsSaving(true)

        await axios.post(`/admin/rentals/${rental.id}/charge`)
      } catch (err) {
        const error = err as any
        const detailMessage = get(error, 'response.data.message')

        window.alert(detailMessage || error.message)
        setIsSaving(false)
      }
    }
  }

  if (!lineItemsOwed.length) {
    return null
  }

  return (
    <div style={{ marginBottom: '2rem' }}>
      <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
        Upcoming Payment Breakdown
      </HeaderText>
      <div>
        <span>Note: (charges - completed payments and credits) per type</span>
        <br />
        <span>
          Does not contain payment/credit information (negative values) if there
          is not a corresponding charge.{' '}
        </span>
      </div>
      {lineItemsOwed.map(({ amount, liabilityTypeId, name }) => (
        <p key={liabilityTypeId}>
          {name}: ${formatMoney(amount)}
        </p>
      ))}

      <Button
        size='vs'
        onClick={handleSubmit}
        disabled={isSaving}
        isSecondary={false}
      >
        Charge balance
      </Button>
    </div>
  )
}
