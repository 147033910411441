import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { useMutation } from 'urql'

import { Button, Input, ErrorText, TextArea } from 'components/Toolkit'
import LiabilityTypesDropdown from './LiabilityTypesDropdown'
import {
  Upup_CreateRentalLiabilityDocument as CreateRentalLiability,
  Upup_LiabilityTypesQuery as LiabilityTypesQuery
} from 'graphql/generated'

type LiabilityType = LiabilityTypesQuery['liability_types'][number]

CreateRentalLiabilityForm.propTypes = {
  onSubmit: PropTypes.func,
  rentalId: PropTypes.string.isRequired
}

export default function CreateRentalLiabilityForm({
  onSubmit = () => {},
  rentalId,
  occupancyDate
}: {
  onSubmit: () => void
  rentalId: string
  occupancyDate: string
}) {
  const [liabilityType, setLiabilityType] = useState<LiabilityType | null>(null)
  const [price, setPrice] = useState('')
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [note, setNote] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const [{ fetching, error }, createRentalLiability] = useMutation(
    CreateRentalLiability
  )

  const isOccupancyDateValid =
    occupancyDate &&
    moment(occupancyDate).isValid() &&
    moment(date).isSameOrAfter(occupancyDate)

  const isValid =
    date &&
    moment(date).isValid() &&
    price &&
    liabilityType &&
    note &&
    note.trim() &&
    isOccupancyDateValid

  const submitRentalLiability = async () => {
    if (!isValid) {
      return
    }

    setIsSaving(true)
    const result = await createRentalLiability({
      liability_type_id: liabilityType!.id,
      rental_id: rentalId,
      date,
      amount: Number(price),
      note: note.trim()
    })

    setIsSaving(false)
    if (result.error === undefined) {
      setLiabilityType(null)
      setPrice('')
      setDate('')
      setNote('')
      onSubmit()
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <LiabilityTypesDropdown
          onSelect={(lType) => {
            setLiabilityType(lType)
            if (lType.price) {
              setPrice(`${lType.price}`)
            }
          }}
          value={liabilityType}
          disabled={fetching || isSaving}
        />
        <Input
          label='Price'
          type='number'
          onChange={setPrice}
          value={price}
          placeholder='Enter the amount of the liability'
          required
          min='0'
          hasError={Number(price) <= 0}
          disabled={isSaving || fetching || !liabilityType?.is_variable}
        />
        <Input
          label='Date to charge customer'
          type='date'
          onChange={setDate}
          value={date}
          placeholder='Enter the date to charge the customer'
          required
          disabled={fetching || isSaving}
          hasError={!date}
        />
        <TextArea
          label='Note'
          type='textarea'
          rows={5}
          onChange={setNote}
          value={note}
          placeholder='Enter the reason for the charge'
          required
          disabled={fetching || isSaving}
          hasError={!note}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={submitRentalLiability}
            disabled={!isValid || fetching || isSaving}
          >
            Create Rental Liability
          </Button>
          {error && <ErrorText>{error.message}</ErrorText>}
          {!isOccupancyDateValid && (
            <ErrorText>
              The occupancy date {occupancyDate} is not valid. No liabilities
              can be created.
            </ErrorText>
          )}
        </div>
      </React.Suspense>
    </>
  )
}
