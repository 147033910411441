import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import get from 'lodash/get'
import { agreements } from '@homevest/utils'
import moment from 'moment'

import CrmField from 'components/CrmField'
import { formatMoney } from 'lib/numbers'
import axios from 'lib/axios'
import { HeaderText, BodyText, Label } from 'components/Toolkit'
import PaymentSchedule from './PaymentSchedule'

const { AGREEMENT_STATUSES } = agreements

const FIELD_TYPES = {
  VALUE: 'value',
  RANGE: 'range',
  SELECT: 'select',
  MULTI_SELECT: 'multi_select'
}

const FIELD_VALUE_TYPES = {
  STRING: 'string',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  DATE: 'date'
}
const MONTHLY_OPTION_PREMIUM = 50

function ApplicationDetails({ rentalApplication: propsRentalApplication }) {
  const [isSaving, setIsSaving] = useState(false)
  const [rentalApplication, setRentalApplication] = useState(
    propsRentalApplication
  )

  const updateProjectedOccupancyDate = async (occupancyDate) => {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    try {
      const res = await axios.post(
        `/admin/rental_applications/${rentalApplication.id}`,
        {
          projected_occupancy_date: occupancyDate
        }
      )

      setRentalApplication(res.data)
    } catch (err) {
      // eslint-disable-next-line no-undef
      window.alert(err.message)
    }

    setIsSaving(false)
  }

  const {
    agreements,
    created_at: createdAt,
    mls_listing: {
      internal_attributes: { internal_status: internalStatus, rent }
    },
    projected_occupancy_date: projectedOccupancyDate,
    status,
    status_metadata: statusMetadata
  } = rentalApplication

  const leaseAgreement =
    agreements.length &&
    agreements.find(
      (agreement) => agreement.status !== AGREEMENT_STATUSES.CANCELED
    )

  const occupancyDate = get(
    leaseAgreement,
    'metadata.custom_fields.startDate',
    projectedOccupancyDate
  )

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '20px',
        marginTop: '20px',
        backgroundColor: '#fff'
      }}
    >
      <div>
        <HeaderText size='h3'>Details</HeaderText>
        <HeaderText size='h5'>
          Created: {moment(createdAt).format('MMM Do, h:mm A')}
        </HeaderText>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ flex: 1 }}>
          <Label>Status</Label>
          <BodyText style={{ marginTop: 0 }}>
            {startCase(status).replace('Upandup', 'Up&Up')}
          </BodyText>
          <BodyText size='xs' style={{ marginTop: 0 }}>
            {statusMetadata.message}
          </BodyText>
        </div>
        <div style={{ flex: 1 }}>
          <Label>Property Status</Label>
          <BodyText style={{ marginTop: 0 }}>
            {startCase(internalStatus)}
          </BodyText>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ flex: 1 }}>
          <Label>Rent</Label>
          <BodyText style={{ marginTop: 0 }}>${formatMoney(rent, 0)}</BodyText>
        </div>
        <div style={{ flex: 1 }}>
          <CrmField
            label='Projected Occupancy Date'
            fieldType={FIELD_TYPES.VALUE}
            fieldValueType={FIELD_VALUE_TYPES.DATE}
            value={projectedOccupancyDate || ''}
            onChange={updateProjectedOccupancyDate}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ flex: 1 }}>
          <Label>Monthly Option Premium</Label>
          <BodyText style={{ marginTop: 0 }}>
            ${formatMoney(MONTHLY_OPTION_PREMIUM, 0)}
          </BodyText>
        </div>
        <div style={{ flex: 1 }}>
          <Label>Total Monthly Payment</Label>
          <BodyText style={{ marginTop: 0 }}>
            ${formatMoney(rent + MONTHLY_OPTION_PREMIUM, 0)}
          </BodyText>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ flex: 1 }}>
          <Label>User Application</Label>
          <BodyText style={{ marginTop: 0 }}>
            {`https://app.upandup.co/user/application/${rentalApplication.id}`}
          </BodyText>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ flex: 1 }}>
          <Label>Application Invite Link</Label>
          <BodyText style={{ marginTop: 0 }}>
            {`https://app.upandup.co/join/${rentalApplication.id}`}
          </BodyText>
        </div>
      </div>

      {Boolean(occupancyDate) && (
        <PaymentSchedule
          occupancyDate={occupancyDate}
          rent={rent}
          rentalApplicationId={rentalApplication.id}
        />
      )}
    </div>
  )
}

ApplicationDetails.propTypes = {
  rentalApplication: PropTypes.object.isRequired
}

export default memo(ApplicationDetails)
