import React from 'react'
import { useSelector } from 'react-redux'
import {
  Navbar as Nav,
  NavbarBrand,
  Collapse,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import styled from 'styled-components'
import { capabilities } from '@homevest/utils'
import { Link } from 'react-router-dom'

import 'styles/nav.css'
import firebase from 'lib/firebase'
import { hasCapability } from 'lib/admin-perms'

import { StoreState } from 'store'
const {
  APPLICATION_VIEWER,
  DAGGER,
  MLS_UPLOADER,
  PERMISSION_EDITOR,
  LEDGER_VIEWER
} = capabilities.CAPABILITY_TYPES

const StyledNavbarBrand = styled(NavbarBrand)`
  font-family: 'Work Sans', sans-serif !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #00cfcc !important;
`

const StyledLink = styled(Link)`
  color: #6c757d;
  margin: 0 5px;
`

const Dropdown = styled(UncontrolledDropdown)`
  margin: 0 5px;
`

const StyledContainer = styled(Container)`
  @media print {
    display: none !important;
  }
`

const Navbar: React.FC = () => {
  const admin = useSelector<StoreState, any>((store) => store.admin)
  const isDagger: boolean = hasCapability(admin, DAGGER)
  const isRentalAppViewer: boolean = hasCapability(admin, APPLICATION_VIEWER)
  const isPermissionEditor: boolean = hasCapability(admin, PERMISSION_EDITOR)
  const isLedgerViewer: boolean = hasCapability(admin, LEDGER_VIEWER)
  const isMlsUploader: boolean = hasCapability(admin, MLS_UPLOADER)

  return (
    <StyledContainer>
      <Nav light expand='md'>
        <StyledNavbarBrand href='/'>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Flogos%2FPrimary_Blue_Blue.png?alt=media&token=f4a199b7-d4f4-442d-985c-2a7efc6d04b8'
            alt='Up&Up Brand Logo'
          />
        </StyledNavbarBrand>

        {typeof admin?.name === 'string' && (
          <Collapse isOpen navbar>
            <Nav className='ml-auto'>
              {/* todo: lockdown with permission? */}
              <StyledLink className='nav-link' to='/review'>
                Review
              </StyledLink>

              {isRentalAppViewer && (
                <StyledLink className='nav-link' to='/applications'>
                  Applications
                </StyledLink>
              )}

              {isLedgerViewer && (
                <StyledLink className='nav-link' to='/rent-roll'>
                  Rent Roll
                </StyledLink>
              )}

              <StyledLink className='nav-link' to='/dispatch-lead-groups'>
                Lead Groups
              </StyledLink>

              {isPermissionEditor && (
                <StyledLink className='nav-link' to='/capabilities'>
                  Permissions
                </StyledLink>
              )}

              {/* todo: lockdown with permission? */}
              <Dropdown>
                <DropdownToggle>Agent Approvals</DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <StyledLink className='nav-link' to='/seller-agents'>
                      Seller Agents
                    </StyledLink>
                  </DropdownItem>
                  <DropdownItem>
                    <StyledLink className='nav-link' to='/add-agent'>
                      Add Agent
                    </StyledLink>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {isDagger && (
                <Dropdown>
                  <DropdownToggle>🗡 Daggers Only</DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/statements/gl-transactions'
                      >
                        GL Transactions
                      </StyledLink>
                    </DropdownItem>
                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/statements/hpa-engine'
                      >
                        HPA Engine
                      </StyledLink>
                    </DropdownItem>

                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/statements/mortgage-transformer'
                      >
                        Mortgage Transformer
                      </StyledLink>
                    </DropdownItem>
                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/statements/proration-engine'
                      >
                        Proration Engine
                      </StyledLink>
                    </DropdownItem>
                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/statements/send-test'
                      >
                        Send Test Statements
                      </StyledLink>
                    </DropdownItem>
                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/statements/backup-and-rebuild'
                      >
                        Snapshot and Rebuild Statements
                      </StyledLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}

              {/* todo: lockdown with permission? */}
              <Dropdown>
                <DropdownToggle>Properties</DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <StyledLink className='nav-link' to='/properties'>
                      Search Listings
                    </StyledLink>
                  </DropdownItem>

                  <DropdownItem>
                    <a
                      target='_blank'
                      className='nav-link'
                      style={{ color: '#6c757d', margin: '0 5px' }}
                      rel='noreferrer'
                      href='https://upandup.tryretool.com/apps/Data%20Entry/Listing%20Tool'
                    >
                      Pre-Buys
                    </a>
                  </DropdownItem>

                  {isMlsUploader && (
                    <DropdownItem>
                      <StyledLink
                        className='nav-link'
                        to='/properties/mls-uploader'
                      >
                        MLS Uploader
                      </StyledLink>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>

              <Dropdown>
                <DropdownToggle>Customers</DropdownToggle>

                <DropdownMenu>
                  <DropdownItem>
                    <StyledLink className='nav-link' to='/users'>
                      Search Customers
                    </StyledLink>
                  </DropdownItem>

                  <DropdownItem>
                    <StyledLink className='nav-link' to='/users/create'>
                      Create Customer
                    </StyledLink>
                  </DropdownItem>

                  {/* todo: lockdown with permission? */}
                  <DropdownItem>
                    <StyledLink className='nav-link' to='/user-tasks'>
                      Pending Customer Tasks
                    </StyledLink>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown>
                <DropdownToggle caret>{admin.name}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      void firebase.auth().signOut()
                    }}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        )}
      </Nav>
    </StyledContainer>
  )
}

export default Navbar
