import { types } from '@homevest/timelines'

type TimelineEvent = types.TimelineEvent

export type { Customer } from '../types'

export const isEmailTimelineEvent = (
  event: TimelineEvent
): event is types.EmailTimelineEvent => {
  return event.eventType === 'email'
}

export const isSmsTimelineEvent = (
  event: TimelineEvent
): event is types.SmsTimelineEvent => {
  return event.eventType === 'sms'
}

export const isCallTimelineEvent = (
  event: TimelineEvent
): event is types.CallTimelineEvent => {
  return event.eventType === 'call'
}

export const isTourTimelineEvent = (
  event: TimelineEvent
): event is types.TourTimelineEvent => {
  return event.eventType === 'tour'
}

export const isInquiryTimelineEvent = (
  event: TimelineEvent
): event is types.InquiryTimelineEvent => {
  return event.eventType === 'inquiry'
}

export const isRentalAppTimelineEvent = (
  event: TimelineEvent
): event is types.RentalAppTimelineEvent => {
  return event.eventType === 'rental_application'
}

export const isTaskTimelineEvent = (
  event: TimelineEvent
): event is types.TaskTimelineEvent => {
  return event.eventType === 'task'
}

export const isNoteTimelineEvent = (
  event: TimelineEvent
): event is types.NoteTimelineEvent => {
  return event.eventType === 'note'
}
