import React from 'react'
import PropTypes from 'prop-types'
import { ModalBody, Spinner, Jumbotron } from 'reactstrap'

import styled from 'styled-components'

const LoadingSpinner = styled(Spinner)`
  flex-position: center;
`
const TopBorderDiv = styled.div`
  border-top: 1px solid #e9ecef;
`
const PSText = styled.p`
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: smaller;
`

const ErrorMessageText = styled.p`
  margin: 20px;
  font-size: smaller;
  color: #cc0000;
`

const ModalBodyLoadingComplete = ({
  isError,
  errorMessage,
  spreadsheetUrl
}) => {
  return (
    <>
      {!isError ? (
        <>
          <p>
            The memo was successfully created!{' '}
            <span role='img' aria-label='tada'>
              🎉
            </span>
          </p>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={spreadsheetUrl}
            style={{ marginBottom: '30px' }}
          >
            Take me there!
          </a>
          <TopBorderDiv>
            <PSText>
              <b>P.S.</b>
              <br />
              For now, you<span>&apos;</span>ll have to manually pull in the
              data from BigQuery by going to <span>&quot;</span>Data/Data
              connectors/Refresh options<span>&quot;</span> and clicking the
              green <span>&quot;</span>Refresh all<span>&quot;</span> button.
              <br />
              <br />
              The IC memo also requires some manual inputs - these will be
              highlighted in
              <mark style={{ backgroundColor: '#FFF2CC' }}>yellow</mark>.
            </PSText>
          </TopBorderDiv>
        </>
      ) : (
        <>
          <p>
            Well... that<span>&apos;</span>s embarrassing! <br /> <br />
            Looks like an error occurred while generating the memo, please try
            again later.
          </p>
          <Jumbotron
            fluid
            style={{
              width: '90%',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginTop: ' 20px'
            }}
          >
            <ErrorMessageText>{errorMessage}</ErrorMessageText>
          </Jumbotron>
        </>
      )}
    </>
  )
}

ModalBodyLoadingComplete.propTypes = {
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  spreadsheetUrl: PropTypes.string
}

const IcMemoModalBody = ({
  isLoading,
  isError,
  errorMessage,
  icMemoSheetData
}) => {
  const { spreadsheetUrl } = icMemoSheetData
  return (
    <ModalBody
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {isLoading ? (
        <>
          <p>Hang on, the memo is loading...</p>

          <LoadingSpinner color='primary' />
          <p style={{ marginTop: '10px', fontSize: 'smaller' }}>
            (This could take a minute)
          </p>
        </>
      ) : (
        <ModalBodyLoadingComplete
          isError={isError}
          errorMessage={errorMessage}
          spreadsheetUrl={spreadsheetUrl}
        />
      )}
    </ModalBody>
  )
}

IcMemoModalBody.propTypes = {
  errorMessage: PropTypes.string,
  icMemoSheetData: PropTypes.object.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default IcMemoModalBody
