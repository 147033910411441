import { Tab } from '@headlessui/react'
import { PropertyGridDataType } from 'components/Homes/Grid/grid'
import React, { FunctionComponent } from 'react'
import AcquisitionPanel from '../Cards/AcquisitionPanel'
import PropertyOverviewPanel from '../Cards/PropertyPanel'

// TODO: create GraphQL fragment that has exact data needed for this panel
interface OverviewPanelProps {
  property: PropertyGridDataType
}
export const OverviewPanel: FunctionComponent<OverviewPanelProps> = ({
  property
}) => (
  <Tab.Panel>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minWidth: '1500px'
      }}
    >
      <PropertyOverviewPanel title='Property Overview' property={property} />
      <AcquisitionPanel
        title='Acqusition Details'
        acquisition={property.real_estate_acquisition}
      />
    </div>
  </Tab.Panel>
)
