import { Container, Col, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { capabilities } from '@homevest/utils'

import Map from '../components/Map'
import PropertyInsights from '../components/Property/Insights'
import PropertyInfo from '../components/Property/Info'
import { ErrorText, HeaderText } from 'components/Toolkit'
import PropertyLeadRecommendations from '../components/Property/PropertyLeadRecommendations'
import { canBeReviewed } from '../lib/property'
import AlertBar from '../components/Property/AlertBar'
import { clearPropertySellerAgentData } from '../state/properties/actions'
import { hasCapability } from 'lib/admin-perms'
import { REQUEST_POLICY } from 'constants/urql'
import { UpupInitialReviewMlsListingsByFirestoreIdsDocument } from 'graphql/generated'

const { PROPERTY_PRICER } = capabilities.CAPABILITY_TYPES

const RightCol = styled(Col)``

const LeftCol = styled(RightCol)``

const StyledRow = styled(Row)``

function InitialReviewScreen() {
  const { propertyId } = useParams()
  return (
    <React.Suspense
      fallback={
        <div>
          <HeaderText
            style={{ textAlign: 'center', marginTop: '400px' }}
            size='h3'
          >
            Loading...
          </HeaderText>
        </div>
      }
    >
      <InitialReviewScreenView propertyId={propertyId} />
    </React.Suspense>
  )
}

function InitialReviewScreenView({ propertyId }) {
  const admin = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const history = useHistory()

  const [property, setProperty] = useState(null)
  const [canReview, setCanReview] = useState(false)
  const [floodPlain, setFloodPlain] = useState(false)

  useEffect(() => {
    if (!property) {
      return
    }

    setCanReview(canBeReviewed(property))
    setFloodPlain(
      (property.xf_attributes.xf_disc || [])
        .join(',')
        .includes('Flood Plain Yes')
    )

    return () => dispatch(clearPropertySellerAgentData())
  }, [dispatch, history, property])

  const isPricer = hasCapability(admin, PROPERTY_PRICER)

  const firestoreIds = [propertyId]

  const [{ data, fetching, error }] = useQuery({
    query: UpupInitialReviewMlsListingsByFirestoreIdsDocument,
    variables: { ids: firestoreIds },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    pause: !firestoreIds.length
  })

  useEffect(() => {
    if (!data || !data.mls_listings) {
      return
    }
    setProperty(data.mls_listings[0])
  }, [data])

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  if (fetching || !property) {
    return (
      <div>
        <HeaderText
          style={{ textAlign: 'center', marginTop: '400px' }}
          size='h3'
        >
          Loading...
        </HeaderText>
      </div>
    )
  }

  return (
    <Container className='initial-review-container'>
      <AlertBar />

      <StyledRow>
        <LeftCol xs={12} md={6}>
          <PropertyInfo property={property} />
          {isPricer && floodPlain && (
            <div>
              <br />
              This property cannot be reviewed: {canReview[1]}.
              {floodPlain && (
                <>
                  <br />
                  ⚠️ THIS PROPERTY IS IN A FLOOD PLAIN!
                </>
              )}
            </div>
          )}
        </LeftCol>

        <RightCol xs={12} md={6}>
          <PropertyInsights property={property} />

          <PropertyLeadRecommendations property={property} />

          {property.lat && property.lng && (
            <Map
              coordinates={{ latitude: property.lat, longitude: property.lng }}
            />
          )}
        </RightCol>
      </StyledRow>
    </Container>
  )
}

export default InitialReviewScreen

InitialReviewScreenView.propTypes = {
  propertyId: PropTypes.string.isRequired
}
