import React, { FunctionComponent } from 'react'
import format from 'date-fns/format'
import { Customer } from '../types'
import { types } from '@homevest/timelines'
import * as SC from '../../StyledComponents'
import { formatPersonalDetails } from '../../formats'
import { mapContentToBadge } from '../../badges'

export const DisplayCall: FunctionComponent<{
  event: types.CallTimelineEvent
  expanded: boolean
  customer: Customer
}> = ({ event, expanded, customer }) => {
  let from, to, customerNumber, ourNumber
  if (event.direction === 'out') {
    customerNumber = formatPersonalDetails(event.toPhone || '')
    ourNumber = formatPersonalDetails(event.fromPhone || '')
    from = event.adminName ?? ourNumber
    to = customerNumber
  } else {
    customerNumber = formatPersonalDetails(event.fromPhone || '')
    ourNumber = formatPersonalDetails(event.toPhone || '')
    from = customer.firstName + `(${customerNumber})`
    to = ourNumber
  }
  const expandedContent = (
    <>
      <SC.EventSubheading>Call</SC.EventSubheading>
      {event.recordingLink ? (
        <>
          <SC.MessageText>Recording</SC.MessageText>
          <a
            href={event.recordingLink}
            onClick={(e) => e.stopPropagation()}
            target='_blank'
            rel='noopener noreferrer'
          >
            Call recording
          </a>
        </>
      ) : null}
      {event.voicemailLink ? (
        <>
          <SC.MessageText>Recording</SC.MessageText>
          <a
            href={event.voicemailLink}
            onClick={(e) => e.stopPropagation()}
            target='_blank'
            rel='noopener noreferrer'
          >
            Voicemail recording
          </a>
        </>
      ) : null}
      <SC.DescriptionText>
        Customer number <SC.InlineBold>{customerNumber}</SC.InlineBold>
      </SC.DescriptionText>
      <SC.DescriptionText>
        Up&Up number <SC.InlineBold>{ourNumber}</SC.InlineBold>
      </SC.DescriptionText>
      <SC.DescriptionText>
        Call duration <SC.InlineBold>{event.duration}</SC.InlineBold>
      </SC.DescriptionText>
      <SC.DescriptionText>
        Call started{' '}
        <SC.InlineBold>
          {format(new Date(event.eventTimeAt), 'PPppp')}
        </SC.InlineBold>
      </SC.DescriptionText>
    </>
  )
  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>{from}</SC.InlineBold> called {}
        <SC.InlineBoldClickable
          onClick={(e) => e.stopPropagation()}
          href={event.messageLink}
          rel='noopener noreferrer'
          target='_blank'
        >
          {to}
        </SC.InlineBoldClickable>
      </SC.MessageText>
      {mapContentToBadge(event.status)}
      {expanded ? <SC.FadeIn>{expandedContent}</SC.FadeIn> : null}
    </div>
  )
}
