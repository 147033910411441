import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import FinalLiabilityDateForm from './Form'
import FullRental from 'types/FullRental'

export default function FinalLiabilityDateModal({
  close,
  isOpen,
  rental
}: {
  close: () => void
  isOpen: boolean
  rental: FullRental
}) {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>Set Final Liability Date</ModalHeader>
        <ModalBody>
          <FinalLiabilityDateForm onSubmit={close} rental={rental} />
        </ModalBody>
      </Modal>
    </>
  )
}
