import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import {
  IDoesFilterPassParams,
  IFilterParams,
  ISimpleFilterModel,
  RowNode
} from 'ag-grid-community'
import { CheckboxList } from './CheckboxList'

export interface SetFilterModel<T> extends ISimpleFilterModel {
  filter: T[]
  filterType: 'contains'
  options: T[]
}

// TODO: allow passing of generic type to be used for the filter options/items
export default forwardRef((props: IFilterParams, ref) => {
  const [filterItems, setFilterItems] = useState<(string | null | undefined)[]>(
    []
  )
  const [filterOptions, setFilterOptions] = useState<
    (string | null | undefined)[]
  >([])

  const valueGetter = (node: RowNode<any>) => {
    const { api, colDef, column, columnApi, context } = props
    return props.valueGetter({
      api,
      colDef,
      column,
      columnApi,
      context,
      data: node.data,
      getValue: (field) => node.data[field],
      node
    })
  }

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        const { node } = params
        const value = valueGetter(node)
        if (filterItems.includes(value)) {
          return true
        }
        return false
      },

      isFilterActive() {
        return filterItems != null && filterItems.length > 0
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null
        }

        return { filter: filterItems, options: filterOptions }
      },

      getModelAsString() {
        // map first for null/undefined
        return filterItems.map((item) => `${item}`).join(', ')
      },

      setModel(model: SetFilterModel<string>) {
        setFilterItems(model == null ? [] : model.filter)
        if (model?.options !== undefined) {
          setFilterOptions(model.options)
        }
      }
    }
  })

  useEffect(() => {
    props.filterChangedCallback()
  }, [filterItems])

  useEffect(() => {
    const filterSet = new Set<string>()
    props.rowModel.forEachNode((node) => {
      const nodeVal = valueGetter(node)
      filterSet.add(nodeVal ? nodeVal.trim() : nodeVal)
    })
    setFilterOptions(Array.from(filterSet))
  }, [])

  const handleOptionClicked = (
    optionValue: string | null | undefined,
    checked: boolean
  ) => {
    if (checked) {
      setFilterItems([...filterItems, optionValue])
    } else {
      setFilterItems(filterItems.filter((item) => item !== optionValue))
    }
  }
  return (
    <div className='ag-input-wrapper'>
      <CheckboxList
        options={filterOptions?.map((option) => ({
          label: `${option}`,
          value: option,
          checked: !!filterItems.includes(option)
        }))}
        onOptionClicked={handleOptionClicked}
      />
    </div>
  )
})
