import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { rentalApplications } from '@homevest/utils'

import { HeaderText, Modal, Dropdown, Button } from 'components/Toolkit'
import { startCase } from 'lodash'

const { RENTAL_APPLICATION_CANCELATION_REASONS } = rentalApplications

const CANCELATION_REASONS = Object.values(
  RENTAL_APPLICATION_CANCELATION_REASONS
).map((value) => ({ label: startCase(value), value }))

function CancelApplication({ cancel, close }) {
  const [cancelReason, setCancelReason] = useState('')

  return (
    <Modal close={close} hasCloseButton>
      <HeaderText style={{ marginTop: '40px', marginBottom: '20px' }} size='h4'>
        Why do you want to cancel?
      </HeaderText>
      <Dropdown
        onChange={setCancelReason}
        options={CANCELATION_REASONS}
        label='Reason'
        value={cancelReason}
      />
      <Button
        onClick={async () => await cancel(cancelReason)}
        style={{ width: '100%', marginTop: '30px' }}
        disabled={!cancelReason}
      >
        Cancel
      </Button>
    </Modal>
  )
}

CancelApplication.propTypes = {
  cancel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

export default memo(CancelApplication)
