import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as moment from 'moment'

import axios from '../../lib/axios'
import AgentUpdateButton from './AgentUpdateButton'

export default function AgentDetailsForm({ agent: propsAgent }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [agent, setAgent] = useState(propsAgent)

  useEffect(() => {
    if (propsAgent && propsAgent.id !== agent.id) {
      setAgent(propsAgent)
    }
  }, [propsAgent])

  const handleAgentUpdate = async (fieldName, fieldValue) => {
    setIsDisabled(true)

    const update = {
      [fieldName]: fieldValue,
      called_from_hudson: true
    }

    const res = await axios.post(`/seller_agents/${agent.id}`, update)

    setAgent(res.data)
    setIsDisabled(false)
  }

  const {
    id,
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    do_not_contact: doNotContact,
    permission_for_all_postings: permissionForAll,
    last_contacted_at: lastContactedAt
  } = agent

  const perpetualPermissionGranted = async () => {
    if (
      !isDisabled &&
      window.confirm(
        'Are you sure the agent gave permission for all of their future listings? This is irreversible ⚠.'
      )
    ) {
      await handleAgentUpdate('permission_for_all_postings', true)
    }
  }

  return (
    <div
      style={{
        borderTop: '5px solid #dee2e6',
        paddingTop: '1rem',
        marginBottom: '1rem'
      }}
    >
      <h5>
        {firstName} {lastName}{' '}
        {doNotContact
          ? 'DO NOT CONTACT'
          : `• ${phone} ${email ? `• ${email}` : ''}`}
        •{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={`https://app.close.com/search/%20custom.seller_agent_id%3A%22${id}%22/`}
        >
          Link to Close
        </a>
      </h5>
      <span>
        {lastContactedAt ? (
          <p>
            <b>Last Contacted:</b>{' '}
            {moment(lastContactedAt).format('MMM Do, YYYY @ h:mm A')}
          </p>
        ) : (
          <p>Not previously contacted</p>
        )}
      </span>
      <div>
        {!doNotContact && (
          <AgentUpdateButton
            isDisabled={isDisabled}
            onUpdate={() => handleAgentUpdate('do_not_contact', true)}
            text='Do Not Contact'
            color='danger'
          />
        )}
        {!permissionForAll && (
          <AgentUpdateButton
            isDisabled={isDisabled}
            onUpdate={perpetualPermissionGranted}
            text='Received Perpetual Permission'
            color='info'
          />
        )}
      </div>
    </div>
  )
}

AgentDetailsForm.propTypes = {
  agent: PropTypes.object.isRequired
}
