import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import TasksScreen from 'screens/Tasks'
import InitialReviewScreen from 'screens/InitialReview'
import AllPropertiesScreen from 'screens/Properties'
import ApplicationsScreen from 'screens/Applications'
import ApplicationScreen from 'screens/Application'
import UsersCreateScreen from 'screens/Users/Create'
import UsersSearchScreen from 'screens/Users/Search'
import UsersEmailRedirectScreen from 'screens/UsersEmailRedirect'
import AuthScreen from 'screens/Auth'
import UserTasksScreen from '../../screens/UserTasks'
import LeadGroupsScreen from 'screens/Dispatch/LeadGroups'
import ShortUrlsScreen from 'screens/ShortUrls'
import SellerAgentsScreen from 'screens/SellerAgents'
import AddAgentScreen from 'screens/AddAgent'
import UserCrm from 'screens/UserCrm'
import UserIncomeEditor from 'screens/UserIncome/Editor'
import PlaidIncomeVerificationReviewer from 'screens/PlaidIncomeVerification/Reviewer'
import StatsScreen from 'screens/Stats'
import CapabilitiesScreen from 'screens/Capabilities'
import RentRollScreen from 'screens/RentRoll'
import RentRollDetailsScreen from 'screens/RentRoll/Details'
import PropertiesMlsPhotoManager from 'screens/Properties/MlsPhotoManager'
import PropertiesMlsUploader from 'screens/Properties/MlsUploader'
import StatementsBackupAndRebuild from 'screens/Statements/BackupAndRebuild'
import StatementsGlTransactions from 'screens/Statements/GlTransactions'
import StatementsHpaEngine from 'screens/Statements/HpaEngine'
import StatementsMortgageTransformer from 'screens/Statements/MortgageTransformer'
import StatementsProrationEngine from 'screens/Statements/ProrationEngine'
import StatementsSendTest from 'screens/Statements/SendTest'
import CustomerProfile from 'screens/CustomerProfile'
import RouteConnectedAuth from 'components/Routes/ConnectedAuth'
import HomesGridPage from 'screens/Homes'
import HomeProfile from 'components/Homes/HomeProfile'

const Routes = () => (
  <Switch>
    <Route exact path='/auth' component={AuthScreen} />
    <RouteConnectedAuth exact path='/review' component={TasksScreen} />
    <RouteConnectedAuth
      exact
      path='/plaid-income-verification/:plaidIncomeVerificationId/reviewer'
      component={PlaidIncomeVerificationReviewer}
    />
    <RouteConnectedAuth exact path='/users' component={UsersSearchScreen} />
    <RouteConnectedAuth
      exact
      path='/users/email/:email'
      component={UsersEmailRedirectScreen}
    />
    <RouteConnectedAuth
      exact
      path='/users/create'
      component={UsersCreateScreen}
    />
    <RouteConnectedAuth
      exact
      path='/legacy/users/:userId'
      component={UserCrm}
    />
    <RouteConnectedAuth
      exact
      path='/users/:userId/user-income-editor'
      component={UserIncomeEditor}
    />
    <RouteConnectedAuth exact path='/users/:userId/:tab' component={UserCrm} />
    <RouteConnectedAuth
      exact
      path='/users/:userId'
      component={CustomerProfile}
    />
    <RouteConnectedAuth
      exact
      path='/applications'
      component={ApplicationsScreen}
    />
    <RouteConnectedAuth
      exact
      path='/applications/:applicationId'
      component={ApplicationScreen}
    />
    <RouteConnectedAuth exact path='/homes' component={HomesGridPage} />
    <RouteConnectedAuth
      exact
      path='/home/:propertyId'
      component={HomeProfile}
    />
    <RouteConnectedAuth
      exact
      path='/properties'
      component={AllPropertiesScreen}
    />
    <RouteConnectedAuth
      exact
      path='/properties/mls-photo-manager/:mlsListingId'
      component={PropertiesMlsPhotoManager}
    />
    <RouteConnectedAuth
      exact
      path='/properties/mls-uploader'
      component={PropertiesMlsUploader}
    />
    <RouteConnectedAuth
      exact
      path='/statements/backup-and-rebuild'
      component={StatementsBackupAndRebuild}
    />
    <RouteConnectedAuth
      exact
      path='/statements/gl-transactions'
      component={StatementsGlTransactions}
    />
    <RouteConnectedAuth
      exact
      path='/statements/hpa-engine'
      component={StatementsHpaEngine}
    />
    <RouteConnectedAuth
      exact
      path='/statements/mortgage-transformer'
      component={StatementsMortgageTransformer}
    />
    <RouteConnectedAuth
      exact
      path='/statements/proration-engine'
      component={StatementsProrationEngine}
    />
    <RouteConnectedAuth
      exact
      path='/statements/send-test'
      component={StatementsSendTest}
    />
    <RouteConnectedAuth
      exact
      path='/review/initial/:propertyId'
      component={InitialReviewScreen}
    />
    <RouteConnectedAuth exact path='/user-tasks' component={UserTasksScreen} />
    <RouteConnectedAuth
      exact
      path='/dispatch-lead-groups'
      component={LeadGroupsScreen}
    />
    <RouteConnectedAuth exact path='/short-urls' component={ShortUrlsScreen} />
    <RouteConnectedAuth
      exact
      path='/seller-agents'
      component={SellerAgentsScreen}
    />
    <RouteConnectedAuth
      exact
      path='/seller-agents/:agentId'
      component={SellerAgentsScreen}
    />
    <RouteConnectedAuth exact path='/add-agent' component={AddAgentScreen} />
    <RouteConnectedAuth exact path='/stats' component={StatsScreen} />
    <RouteConnectedAuth
      exact
      path='/capabilities'
      component={CapabilitiesScreen}
    />

    <RouteConnectedAuth
      exact
      path='/rent-roll/:rentalId'
      component={RentRollDetailsScreen}
    />
    <RouteConnectedAuth exact path='/rent-roll' component={RentRollScreen} />
    <Redirect to='/review' />
  </Switch>
)

export default Routes
