import React from 'react'
import { get } from 'lodash'

import { BodyText, Label } from 'components/Toolkit'
import ReportLink from '../ReportLink'
import CriminalReportOffense from './CriminalReportOffense'

export default function CriminalReportDetails({ criminalReport }) {
  const criminalReportDocumentId = get(criminalReport, 'document.id', null)

  if (!criminalReport) {
    return <BodyText style={{ marginTop: '.5rem' }}>No report run</BodyText>
  }

  if (criminalReportDocumentId) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <ReportLink
          documentId={criminalReportDocumentId}
          reportName='Criminal Report'
          style={{ flex: 1 }}
        />
      </div>
    )
  }

  const matches = get(criminalReport, 'matches', null)

  if (!matches || !matches.length) {
    return (
      <BodyText style={{ marginTop: '.5rem' }}>No matches found ✅</BodyText>
    )
  }

  return matches.map((match, idx) => {
    const {
      offenses: { count: offenseCount, offense: offenses },
      subject: { category: subjectCategory, comments: subjectComments }
    } = match

    return (
      <div
        key={idx}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          marginTop: idx === 0 ? '0' : '1rem'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <Label>Subject Category</Label>
            <BodyText style={{ marginTop: 0 }}>{subjectCategory}</BodyText>
          </div>
          <div style={{ flex: 1 }}>
            <Label>Offense Count</Label>
            <BodyText style={{ marginTop: 0 }}>{offenseCount}</BodyText>
          </div>
        </div>
        <div>
          <Label>Comments</Label>
          <BodyText style={{ marginTop: 0 }}>{subjectComments}</BodyText>
        </div>
        {offenses.map((offense, idx2) => (
          <CriminalReportOffense
            key={idx2}
            offense={offense}
            hasBottomBorder={idx2 !== offenses.length - 1}
          />
        ))}
      </div>
    )
  })
}
