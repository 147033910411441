import ContentSection from 'components/360/CustomerProfile/ContentSection'
import React, { FunctionComponent } from 'react'
import { DetailsContainer } from 'components/360/CustomerProfile/StyledComponents'
import SingleDetailRow from 'components/360/CustomerProfile/PersonalDetails/SingleDetailRow'
import { PropertyGridDataType } from 'components/Homes/Grid/grid'
import { get } from 'lodash'

interface OverviewPanelProps {
  title: string
  property: PropertyGridDataType
}

interface PanelAttribute {
  label: string
  value?: string
  valueGetter?: (property: PropertyGridDataType) => string
}

const OVERVIEW_PANEL_CONFIG: PanelAttribute[] = [
  {
    label: 'Address',
    value: 'real_estate_acquisition.mls_listing.display_line_1'
  },
  {
    label: 'City',
    value: 'real_estate_acquisition.mls_listing.city'
  },
  {
    label: 'State',
    value: 'real_estate_acquisition.mls_listing.state'
  },
  {
    label: 'Zip Code',
    value: 'real_estate_acquisition.mls_listing.zip'
  },
  {
    label: 'Market',
    value: 'real_estate_acquisition.mls_listing.market.display_name'
  },
  {
    label: 'Beds',
    value: 'real_estate_acquisition.mls_listing.beds'
  },
  {
    label: 'Baths',
    value: 'real_estate_acquisition.mls_listing.baths_total'
  },
  {
    label: 'Square Footage',
    value: 'real_estate_acquisition.mls_listing.size'
  },
  {
    label: 'Year Built',
    value: 'real_estate_acquisition.mls_listing.year_built'
  }
  // {
  //   label: "ID",
  //   value: "id"
  // }
]

const PropertyOverviewPanel: FunctionComponent<OverviewPanelProps> = ({
  title,
  property
}) => {
  return (
    <ContentSection
      title={title}
      content={
        <DetailsContainer>
          {OVERVIEW_PANEL_CONFIG.map((attribute) => {
            let value = ''
            if (attribute.value) {
              value = get(property, attribute.value)
            } else if (attribute.valueGetter) {
              value = attribute.valueGetter(property)
            } else {
              value = get(property, attribute.label.toLowerCase())
            }
            return (
              <SingleDetailRow
                header={attribute.label}
                content={value}
                editable={false}
              />
            )
          })}
        </DetailsContainer>
      }
    />
  )
}

export default PropertyOverviewPanel
