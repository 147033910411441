import { SET_INITIAL_PATH } from './actions'

export default function reducer(
  state = {
    landingPath: null
  },
  action
) {
  switch (action.type) {
    case SET_INITIAL_PATH:
      return {
        ...state,
        landingPath: action.landingPath
      }
    default:
      return state
  }
}
