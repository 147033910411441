// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'

import COLORS from 'components/Toolkit/colors'
import { BodyText, Button, ErrorText, HeaderText } from 'components/Toolkit'
import axios from 'lib/axios'
import { getContactInfo } from 'lib/users'

MergeModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

function UserTable({ user }: { user: object }) {
  return (
    <table>
      <tr>
        <th>ID</th>
        <td>{user.id}</td>
      </tr>
      <tr>
        <th>First Name</th>
        <td>{user.first_name}</td>
      </tr>
      <tr>
        <th>Last Name</th>
        <td>{user.last_name}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{getContactInfo('email', user.user_contact_details)}</td>
      </tr>
      <tr>
        <th>Phone</th>
        <td>{getContactInfo('phone', user.user_contact_details)}</td>
      </tr>
    </table>
  )
}

export default function MergeModal({
  close,
  isOpen,
  sourceUser,
  destinationUser
}: {
  close: () => void
  isOpen: boolean
  sourceUser: object
  destinationUser: object
}) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [error, setError] = useState('')

  const executeMerge = async () => {
    try {
      setIsDisabled(true)
      await axios.post(`/admin/users/${sourceUser.id}/merge`, {
        primary_user_id: destinationUser.id
      })

      // Need to refresh page to reload latest user state :/
      window.location.reload(false)
    } catch (err) {
      if (err.isAxios) {
        setError(err.response.data)
      } else {
        setError(err.message)
      }
    } finally {
      setIsDisabled(true)
    }
  }

  return (
    <>
      <Modal size='lg' toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>
          Merge into {destinationUser.id}?
        </ModalHeader>
        <ModalBody>
          {error && <ErrorText>{error}</ErrorText>}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <HeaderText size='h4'>Source</HeaderText>
              <UserTable user={sourceUser} />
            </div>
            <div style={{ minWidth: '50px', position: 'relative' }}>
              <div style={{ position: 'absolute', top: '50%' }}>➡️</div>
            </div>
            <div>
              <HeaderText size='h4'>Destination</HeaderText>
              <UserTable user={destinationUser} />
            </div>
          </div>
          <BodyText>
            Merging{' '}
            <b>
              {sourceUser.first_name} {sourceUser.last_name}
            </b>{' '}
            into{' '}
            <b>
              {destinationUser.first_name} {destinationUser.last_name}
            </b>{' '}
            is irreversible, please review the source and destination before
            clicking below.
          </BodyText>
          <Button
            disabled={isDisabled}
            isSecondary={false}
            onClick={() => {
              return executeMerge()
            }}
            style={{ background: COLORS.ACCENTS.SALMON }}
          >
            Merge
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}
