import { get } from 'lodash'
import * as moment from 'moment-timezone'
import ReactJson from 'react-json-view'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useQuery } from 'urql'
import { capabilities } from '@homevest/utils'

import { BodyText, Label, HeaderText, ErrorText } from 'components/Toolkit'
import UserDocuments from 'components/Documents/user-documents'

import { FindPlaidIncomeVerificationByIdQueryDocument } from 'graphql/generated'
import axios from 'lib/axios'
import { hasCapability } from 'lib/admin-perms'
import { REQUEST_POLICY } from 'constants/urql'

const { INCOME_REVIEWER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function PlaidIncomeVerificationReviewer({ match }) {
  const isIncomeReviewer = useSelector((store) =>
    hasCapability(store.admin, INCOME_REVIEWER)
  )
  const plaidIncomeVerificationId = match.params.plaidIncomeVerificationId
  if (!isIncomeReviewer) {
    return (
      <ErrorText>You do not have the capability of income reviewer</ErrorText>
    )
  }

  return (
    <div>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        Plaid Income Review For {plaidIncomeVerificationId}
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <PlaidIncomeVerification
          plaidIncomeVerificationId={plaidIncomeVerificationId}
        />
      </React.Suspense>
    </div>
  )
}

PlaidIncomeVerificationReviewer.propTypes = {
  match: PropTypes.object.isRequired
}

function PlaidIncomeVerification({ plaidIncomeVerificationId }) {
  const [{ data, fetching, error }] = useQuery({
    query: FindPlaidIncomeVerificationByIdQueryDocument,
    variables: { plaidIncomeVerificationId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  if (fetching || !data) {
    return null
  }

  const plaidIncomeVerification = get(data, 'plaid_income_verifications[0]', {})
  const { documents } = plaidIncomeVerification

  return (
    <StyledContent>
      <Label>Up&Up User</Label>
      <BodyText>
        {plaidIncomeVerification.user.first_name}{' '}
        {plaidIncomeVerification.user.last_name}
      </BodyText>
      <Label>Processed At</Label>
      <BodyText>
        {moment(plaidIncomeVerification.processed_at).format(
          'MM/DD/yyyy hh:mm:ss'
        )}
      </BodyText>
      <Label>Income Verification ID</Label>
      <BodyText>{plaidIncomeVerification.income_verification_id}</BodyText>
      <Label>Income Verification Request ID</Label>
      <BodyText>
        {plaidIncomeVerification.income_verification_request_id}
      </BodyText>
      <Label>Link Token Request ID</Label>
      <BodyText>{plaidIncomeVerification.link_token_request_id}</BodyText>
      <Label>Income Response JSON</Label>
      <ReactJson
        collapsed='true'
        src={plaidIncomeVerification.income_response}
      />
      <Label>Paystub JSON</Label>
      <ReactJson collapsed='true' src={plaidIncomeVerification.paystub_data} />
      <Label>Summary JSON</Label>
      <ReactJson collapsed='true' src={plaidIncomeVerification.summary_data} />
      <div style={{ marginTop: '20px' }}>
        <Label>Files ({documents.length})</Label>
        <PlaidIncomeVerificationDocuments documents={documents} />
      </div>
    </StyledContent>
  )
}

PlaidIncomeVerification.propTypes = {
  plaidIncomeVerificationId: PropTypes.string.isRequired
}

function PlaidIncomeVerificationDocuments({ documents }) {
  const [documentUrls, setDocumentUrls] = useState(null)

  useEffect(() => {
    async function run() {
      if (!documents) {
        return
      }

      const urls = await Promise.all(
        documents.map(({ id }) =>
          axios.get(`/admin/documents/${id}/url`).then((resp) => resp.data.url)
        )
      )
      const mapped = documents.reduce(
        (acc, { id }, idx) => ({ ...acc, [id]: urls[idx] }),
        {}
      )

      setDocumentUrls(mapped)
    }
    run()
  }, [documents])

  if (!documents || !documentUrls) {
    return null
  }

  return (
    <UserDocuments
      documentUrls={documentUrls}
      documents={documents}
      isSaving={false}
    />
  )
}

PlaidIncomeVerificationDocuments.propTypes = {
  documents: PropTypes.array.isRequired
}
