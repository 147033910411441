import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  margin-right: 0.5rem;
`

const StyledSettingsOptionsContainer = styled.div`
  background-color: #f1f1f1;
  width: fit-content;
  padding: 10px;
  margin-top: 20px;
`

type StyledCheckboxPropTypes = {
  getter: boolean
  setter: any
  text: string
}

const StyledCheckbox: FunctionComponent<StyledCheckboxPropTypes> = ({
  getter,
  setter,
  text
}) => (
  <div onClick={() => setter(!getter)}>
    <StyledInput type={'checkbox'} checked={getter} readOnly />
    {text}
  </div>
)

const StyledSettingsContainer = styled.div`
  width: 100%;
  padding: 20px 40px 0 40px;
  margin-bottom: 20px;
`

const StyledSettingsBox = styled.div`
  height: auto;
  width: fit-content;
  padding: 10px;
  background-color: lightgrey;
  &:hover {
    background-color: darkgrey;
  }
`
interface StateObject {
  getter: boolean
  setter: React.Dispatch<React.SetStateAction<boolean>>
  text: string
  onlyShowIf?: boolean
}

type SettingsPropTypes = {
  settingsStateObjects: StateObject[]
}

const Settings: FunctionComponent<SettingsPropTypes> = ({
  settingsStateObjects: filterStateObjects
}) => {
  const [settingsOpen, setSettingsOpen] = useState(false)
  const rightArrow = String.fromCharCode(9654)
  const downArrow = String.fromCharCode(9660)

  const SettingsBox = () => (
    <StyledSettingsBox
      onClick={() => {
        setSettingsOpen(!settingsOpen)
      }}
    >
      Options {settingsOpen ? downArrow : rightArrow}
    </StyledSettingsBox>
  )

  const settingsOptions = (
    <StyledSettingsOptionsContainer>
      {filterStateObjects.map((state, i) => (
        <StyledCheckbox {...state} key={i} />
      ))}
    </StyledSettingsOptionsContainer>
  )

  return (
    <StyledSettingsContainer>
      <SettingsBox />
      {settingsOpen ? settingsOptions : null}
    </StyledSettingsContainer>
  )
}

export default Settings
