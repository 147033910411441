import React from 'react'
import {
  UpupAdminsWithCapabilitiesQuery,
  UpupCapabilitiesWithAdminQuery
} from 'graphql/generated'
import { BodyText, Button, COLORS } from 'components/Toolkit'
import EditAdminModal from './EditAdminModal'

type Props = {
  capabilities?: UpupCapabilitiesWithAdminQuery['capabilities']
  admins?: UpupAdminsWithCapabilitiesQuery['admins']
  onClone: (admin: UpupAdminsWithCapabilitiesQuery['admins'][number]) => void
}

const AdminList: React.FC<Props> = ({
  admins = [],
  capabilities = [],
  onClone
}) => {
  const [edit, setEdit] = React.useState<number | null>(null)

  return (
    <>
      <div style={{ paddingRight: '0.25rem' }}>
        {(admins ?? []).map((admin, idx) => {
          return (
            <React.Fragment key={admin.id}>
              {idx !== 0 && (
                <hr
                  style={{
                    width: '100%',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                  }}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <BodyText style={{ flex: 1 }} weight='bold'>
                  {admin.first_name} {admin.last_name}
                </BodyText>
                <BodyText style={{ flex: 1 }}>{admin.email}</BodyText>
                <Button
                  style={{ marginRight: '0.5rem' }}
                  size='vs'
                  isSecondary
                  onClick={() => setEdit(idx)}
                >
                  Edit
                </Button>
                <Button size='vs' isSecondary onClick={() => onClone(admin)}>
                  Clone
                </Button>
              </div>
              <div
                style={{
                  background: COLORS.LIGHT_NEUTRALS.GARLIC,
                  padding: '0.5rem'
                }}
              >
                <BodyText
                  size='s'
                  style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}
                  weight='bold'
                >
                  {admin.admin_capabilities.length > 0
                    ? 'Capabilities'
                    : 'No current capabilities'}
                </BodyText>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap'
                  }}
                >
                  {admin.admin_capabilities
                    .sort((a, b) =>
                      a.capability.name.localeCompare(b.capability.name)
                    )
                    .map((cap) => (
                      <BodyText
                        key={cap.id}
                        size='s'
                        style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}
                      >
                        {cap.capability.name}
                      </BodyText>
                    ))}
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
      <EditAdminModal
        isOpen={edit !== null}
        onClose={() => setEdit(null)}
        admin={edit !== null ? admins[edit] ?? undefined : undefined}
        capabilities={capabilities}
      />
    </>
  )
}

export default AdminList
