import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import IssueCreditForm from './IssueCreditForm'

const IssueCreditModal: React.FC<{
  close: () => void
  isOpen: boolean
  rentalId: string
}> = ({ close, isOpen, rentalId }) => {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalHeader toggle={close}>Issue a Rental Credit</ModalHeader>
        <ModalBody>
          <IssueCreditForm onSubmit={close} rentalId={rentalId} />
        </ModalBody>
      </Modal>
    </>
  )
}

IssueCreditModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rentalId: PropTypes.string.isRequired
}

export default IssueCreditModal
