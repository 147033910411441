// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import startCase from 'lodash/startCase'

import { BodyText, HeaderText } from 'components/Toolkit'
import axios from 'lib/axios'
import { Documents as DocsType } from 'graphql/generated'
import FullRental from 'types/FullRental'

export default function Documents({ rental }: { rental: FullRental }) {
  const [docUrlMap, setDocUrlMap] = useState<{ [id: string]: string }>({})

  const documents = rental.rental_application?.agreements[0]?.documents

  useEffect(() => {
    setDocumentUrls(documents, setDocUrlMap)
  }, [documents])

  return (
    <div style={{ marginBottom: '1rem' }}>
      <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
        Documents
      </HeaderText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, 1fr)'
        }}
      >
        <BodyText weight='500'>Name</BodyText>
        <BodyText weight='500'>Status</BodyText>
        <BodyText weight='500'>Uploaded</BodyText>
        <BodyText weight='500'>Reviewed</BodyText>
        <BodyText weight='500'>Reviewed By</BodyText>
        <BodyText weight='500'>Notes</BodyText>
        {documents?.map((doc, idx) => (
          <React.Fragment key={doc.id}>
            {idx !== 0 && (
              <div
                style={{
                  paddingTop: '4px',
                  marginBottom: '4px',
                  gridColumnStart: 1,
                  gridColumnEnd: 'end',
                  borderBottom: '1px solid #fdfdfd'
                }}
              />
            )}
            <BodyText style={{ paddingRight: '4px' }}>
              <a href={docUrlMap[doc.id]}>{doc.friendly_name}</a>
            </BodyText>
            <BodyText style={{ paddingRight: '4px' }}>
              {startCase(doc.status)}
            </BodyText>
            <BodyText style={{ paddingRight: '4px' }}>
              {doc.created_at
                ? moment(doc.created_at).format('YYYY-MM-DD')
                : 'N/A'}
            </BodyText>
            <BodyText style={{ paddingRight: '4px' }}>
              {doc.reviewed_at
                ? moment(doc.reviewed_at).format('YYYY-MM-DD')
                : 'N/A'}
            </BodyText>
            <BodyText style={{ paddingRight: '4px' }}>
              {doc.admin
                ? `${doc.admin?.first_name} ${doc.admin?.last_name}`
                : 'N/A'}
            </BodyText>
            <BodyText style={{ paddingRight: '4px' }}>
              {doc.review_notes || 'N/A'}
            </BodyText>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

type Doc = Pick<
  DocsType,
  | 'id'
  | 'created_at'
  | 'external_id'
  | 'external_source'
  | 'friendly_name'
  | 'review_notes'
  | 'reviewed_at'
  | 'status'
>

const setDocumentUrls = async (
  documents: Doc[] = [],
  callback: React.SetStateAction<any>
) => {
  const urls = await Promise.all(
    documents.map(({ id }) =>
      axios
        .get(`/admin/documents/${id}/url`)
        .then((resp) => resp.data.url)
        .then((url) => ({ id, url }))
    )
  )
  const urlMap = urls.reduce((acc, { id, url }) => ({ ...acc, [id]: url }), {})
  callback(urlMap)
}
