import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import styled from 'styled-components'
import { leadGroups } from '@homevest/utils'

import { Dropdown, Label, Button, Pencil } from './Toolkit'
import axios from '../lib/axios'

const StyledField = styled.div`
  &:hover {
    background-color: ${(props) => (props.isEditing ? '#fff' : '#eee')};

    .pencil {
      display: ${(props) => (props.isEditing ? 'none' : 'block')};
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pencil {
    display: none;
  }
`

const { LEAD_GROUP_USER_TYPES } = leadGroups

const LEAD_GROUP_USER_TYPE_OPTIONS = Object.values(LEAD_GROUP_USER_TYPES).map(
  (type) => ({
    label: startCase(type),
    value: type
  })
)

function LeadType({ leadGroupUser }) {
  const [type, setType] = useState(leadGroupUser.type)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updateLeadGrouUserType = async () => {
    if (type === leadGroupUser.type || !isEditing) {
      return
    }

    setIsSubmitting(true)

    try {
      await axios.post(`/admin/lead_group_users/${leadGroupUser.id}`, { type })
    } catch (err) {
      // eslint-disable-next-line no-undef
      window.alert(err.message)
    }
    setIsSubmitting(false)
  }

  const isDisabled = isSubmitting || type === leadGroupUser.type

  return (
    <StyledField
      style={{
        cursor: isEditing ? '' : 'pointer'
      }}
      onClick={() => {
        if (!isEditing && !isSubmitting) {
          setIsEditing(true)
        }
      }}
      isEditing={isEditing}
    >
      <div>
        <Label>Lead type</Label>
        {isEditing ? (
          <div style={{ display: 'flex', alignItems: 'top ' }}>
            <div style={{ flexGrow: 1 }}>
              <Dropdown
                showSelectText={false}
                value={type}
                onChange={(val) => setType(val)}
                options={LEAD_GROUP_USER_TYPE_OPTIONS}
              />
            </div>
            <div style={{ marginLeft: '5px' }}>
              <Button
                style={{ whiteSpace: 'nowrap' }}
                onClick={updateLeadGrouUserType}
                disabled={isDisabled}
              >
                Done
              </Button>
            </div>
          </div>
        ) : (
          <div>{startCase(type)}</div>
        )}
      </div>
      <div className='pencil' style={{ marginRight: '10px' }}>
        <Pencil />
      </div>
    </StyledField>
  )
}

LeadType.propTypes = {
  leadGroupUser: PropTypes.object.isRequired
}

export default memo(LeadType)
