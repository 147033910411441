import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { orderBy } from 'lodash'
import { formatMoney } from 'lib/numbers'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 500px;
`

const StyledLabel = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #bdbbb8;
  margin-bottom: 5px;
`

const StyledHomesContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dbd9d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  flex-grow: 1;
  overflow-y: scroll;
`

const StyledHome = styled.a`
  text-decoration: none !important;
`

const StyledAddress = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #302654;
`

const StyledDate = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #85807a;
`

const StyledBeside = styled.div`
  display: flex;
  align-items: center;
`

const StyledPhoto = styled.img`
  width: 120px;
  height: auto;
  margin-right: 10px;
`

function InteractionGroup({ label, homes }) {
  const sortedArray = orderBy(
    homes,
    (o) => {
      return moment(o.interaction.createdAt.toDate()).format('YYYYMMDD')
    },
    ['desc']
  )

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      {sortedArray.length ? (
        <StyledHomesContainer>
          {sortedArray.map((home, idx) => (
            <StyledHome href={home.url} target='_blank' key={home.id}>
              {idx !== 0 ? <hr /> : null}
              <StyledBeside>
                <div>
                  <StyledPhoto src={home.photos[0].url} />
                </div>
                <div>
                  <StyledAddress>{home.display_line_1}</StyledAddress>
                  <StyledDate>
                    {moment(home.interaction.createdAt.toDate()).format(
                      'MMM Do, YYYY'
                    )}
                  </StyledDate>
                  <StyledDate>
                    ${formatMoney(home.internal_attributes[0].rent + 50, 0)} ‧{' '}
                    {home.beds}bd ‧ {home.baths_total}bath
                  </StyledDate>
                  <StyledDate>
                    {home.city} ‧ {home.region}
                  </StyledDate>
                </div>
              </StyledBeside>
            </StyledHome>
          ))}
        </StyledHomesContainer>
      ) : null}
    </StyledContainer>
  )
}

InteractionGroup.propTypes = {
  label: PropTypes.string.isRequired,
  homes: PropTypes.array.isRequired
}

export default InteractionGroup
