import React, { useState } from 'react'
import { useSubscription } from 'urql'
import { Spinner, Button, Table } from 'reactstrap'
import { Comparators as Comp } from 'utils/hasura-comparators'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { UpupLeadGroupsUserListDocument as query } from 'graphql/generated'

import UpdateModal from 'components/Dispatch/LeadGroups/UpdateModal'
import CreateModal from 'components/Dispatch/LeadGroups/CreateModal'

import { HeaderText, Input } from 'components/Toolkit'

import { updateLeadGroup, createLeadGroup } from 'state/lead-groups/actions'
import { openModal, closeModal } from 'state/dispatch/actions'

function LeadGroupEntries({ searchText }) {
  const dispatch = useDispatch()
  const { modalOpen, modalData, modalIsActioning } = useSelector(
    (state) => state.dispatch
  )
  const [{ data }] = useSubscription({
    query,
    variables: {
      where: {
        status: { _eq: 'active' },
        lead_group_users: {
          user_searchable: {
            info: Comp.isLike(`%${searchText}%`)
          }
        }
      }
    }
  })

  const leadGroups = data?.lead_groups || []

  return (
    <div>
      <>
        <Table responsive>
          <thead>
            <tr>
              <th>Users</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {leadGroups.map((leadGroup) => {
              return (
                <tr key={leadGroup.id}>
                  <td>
                    {leadGroup.lead_group_users.map(
                      (
                        {
                          user: {
                            first_name: firstName,
                            last_name: lastName,
                            email,
                            phone
                          }
                        },
                        idx
                      ) => (
                        <div
                          key={`lead_group_user_${idx}`}
                          style={idx !== 0 ? { marginTop: '5px' } : {}}
                        >
                          <div>
                            <b>
                              {firstName} {lastName}
                            </b>
                          </div>
                          <div style={{ whiteSpace: 'nowrap' }}>{email}</div>
                          <div>{phone}</div>
                        </div>
                      )
                    )}
                  </td>
                  <td>{leadGroup.status}</td>
                  <td>
                    <Button
                      color='primary'
                      onClick={() => dispatch(openModal('update', leadGroup))}
                    >
                      Update
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {modalOpen === 'update' ? (
          <UpdateModal
            onUpdate={(...props) => dispatch(updateLeadGroup(...props))}
            onClose={(...props) => dispatch(closeModal(...props))}
            leadGroup={modalData}
            isActioning={modalIsActioning}
          />
        ) : null}
        {modalOpen === 'create' ? (
          <CreateModal
            onCreate={(...props) => dispatch(createLeadGroup(...props))}
            onClose={(...props) => dispatch(closeModal(...props))}
            isActioning={modalIsActioning}
          />
        ) : null}
      </>
    </div>
  )
}

LeadGroupEntries.propTypes = {
  searchText: PropTypes.string.isRequired
}

function LeadGroups() {
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto  ' }}>
      <HeaderText size='h3'>Lead Groups</HeaderText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px'
        }}
      >
        <div style={{ width: '600px', marginRight: '20px' }}>
          <Input
            placeholder='Search...'
            value={searchText}
            onChange={setSearchText}
          />
        </div>
        <Button color='primary' onClick={() => dispatch(openModal('create'))}>
          Create +
        </Button>
      </div>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <LeadGroupEntries searchText={searchText} />
      </React.Suspense>
    </div>
  )
}

export default LeadGroups
