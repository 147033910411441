import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { useMutation } from 'urql'
import startCase from 'lodash/startCase'
import { v4 as uuidv4 } from 'uuid'
import { notes as noteUtils } from '@homevest/utils'

import Dropzone from 'components/Dropzone'
import { Button, ErrorText, Dropdown, TextArea } from 'components/Toolkit'
import {
  UpupInsertDocumentDocument,
  UpupInsertNoteDocument
} from 'graphql/generated'
import NoteWidgetOption from 'types/NoteWidgetOption'
import { StoreState } from 'store'

const CreateNoteForm: React.FC<{
  onSubmit: () => void
  resourceNoteWidgetOptions: NoteWidgetOption[]
  writeCategory: string
}> = ({ onSubmit, resourceNoteWidgetOptions, writeCategory }) => {
  const [attachmentPath, setAttachmentPath] = useState<string | null>(null)
  const [category, setCategory] = useState(writeCategory)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isAttaching, setIsAttaching] = useState(false)
  const [note, setNote] = useState('')
  const [resourceNoteWidgetOption, setResourceNoteWidgetOption] =
    useState<NoteWidgetOption | null>(null)
  const [noteTextAreaLabel, setNoteTextAreaLabel] = useState<string>('Note')
  const [isSaving, setIsSaving] = useState(false)
  const admin = useSelector((state: StoreState) => state.admin)

  const [{ fetching: fetchingNote, error: errorNote }, createNote] =
    useMutation(UpupInsertNoteDocument)
  const [{ fetching: fetchingDocument, error: errorDocument }, createDocument] =
    useMutation(UpupInsertDocumentDocument)

  const isValid = admin && note && note.trim() && resourceNoteWidgetOption

  const handleSubmit = async () => {
    if (!isValid) {
      return
    }

    try {
      setIsSaving(true)

      const resultNote = await createNote({
        note,
        category,
        created_by_admin_id: admin.id,
        resource_type: resourceNoteWidgetOption!.type,
        resource_id: resourceNoteWidgetOption!.id
      })

      const errors = []

      if (isAttaching) {
        const resultDocument = await createDocument({
          external_id: attachmentPath,
          external_source: 'google_cloud',
          friendly_name: `Attachment by ${admin.first_name} ${admin.last_name}.`,
          resource_id: resultNote!.data!.insert_notes_one!.id,
          resource_type: 'notes',
          reviewed_at: new Date(),
          reviewed_by_admin_id: admin.id,
          status: 'valid',
          type: 'note_attachment'
        })

        if (resultDocument.error) {
          errors.push(resultDocument.error.message)
        }
      }

      if (resultNote.error === undefined) {
        setNote('')
        setResourceNoteWidgetOption(null)
        onSubmit()
      } else {
        errors.push(resultNote.error.message)
      }

      if (errors.length) {
        setErrorMessage(errors.join(', '))
      }
    } catch (err) {
      const error = err as any
      setErrorMessage(error?.message)
    } finally {
      setIsSaving(false)
    }
  }

  const resourceNoteOptions = resourceNoteWidgetOptions.map((rnwo) => ({
    label: rnwo.displayName || startCase(rnwo.type) + ' ' + startCase(rnwo.id),
    value: rnwo.id
  }))

  let categories = Object.values(noteUtils.CATEGORIES).map((i) => {
    return {
      label: startCase(i),
      value: i
    }
  })

  categories = categories.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })

  const onAttachmentSuccess = () => {
    setIsAttaching(true)
  }

  const onAttachmentRemove = () => {
    setIsAttaching(false)
  }

  useEffect(() => {
    if (!resourceNoteWidgetOption) {
      return
    }

    setAttachmentPath(
      'notes-widget/' +
        resourceNoteWidgetOption.type +
        '-' +
        resourceNoteWidgetOption.id +
        '/' +
        uuidv4()
    )
  }, [resourceNoteWidgetOption])

  useEffect(() => {
    if (resourceNoteWidgetOptions.length !== 1) {
      return
    }
    setNoteTextAreaLabel(resourceNoteWidgetOptions[0].displayName || 'Error')
    setResourceNoteWidgetOption(resourceNoteWidgetOptions[0])
  }, [resourceNoteWidgetOptions])

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        {errorDocument !== undefined && (
          <ErrorText>{errorDocument.message}</ErrorText>
        )}
        {errorNote !== undefined && <ErrorText>{errorNote.message}</ErrorText>}
        {resourceNoteWidgetOptions.length > 1 && (
          <Dropdown
            onChange={(id: string) => {
              const option: NoteWidgetOption | undefined =
                resourceNoteWidgetOptions.find((rnwo) => rnwo.id === id)
              if (!option) {
                setErrorMessage(
                  'Something went wrong, please contact Engineering.'
                )
                return
              }
              setResourceNoteWidgetOption(option)
            }}
            disabled={fetchingDocument || fetchingNote || isSaving}
            options={resourceNoteOptions}
            label='Who or what does the note belong to.'
            placeholder='Please select'
            showSelectText
            value={resourceNoteWidgetOption?.id || undefined}
          />
        )}
        {!writeCategory && (
          <Dropdown
            onChange={(category) => {
              setCategory(category)
            }}
            disabled={fetchingDocument || fetchingNote || isSaving}
            options={categories}
            label='Category'
            showSelectText
            value={category}
          />
        )}

        <TextArea
          label={noteTextAreaLabel}
          type='textarea'
          rows={5}
          onChange={setNote}
          value={note}
          placeholder='Enter the note'
          required
          disabled={fetchingDocument || fetchingNote || isSaving}
          hasError={!note}
        />
        {resourceNoteWidgetOption && (
          <div style={{ padding: '5px', border: 'dotted 1px black' }}>
            <Dropzone
              onSuccess={onAttachmentSuccess}
              onRemove={onAttachmentRemove}
              uploadPath={attachmentPath}
            />
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={handleSubmit}
            disabled={!isValid || fetchingDocument || fetchingNote || isSaving}
          >
            Save Note
          </Button>
        </div>
      </React.Suspense>
    </>
  )
}

CreateNoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resourceNoteWidgetOptions: PropTypes.array.isRequired
}

export default CreateNoteForm
