import React from 'react'
import { Container } from 'reactstrap'
import { Button, ErrorText } from 'components/Toolkit'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { hasCapability } from 'lib/admin-perms'
import { capabilities } from '@homevest/utils'

import AdminTab from './AdminTab'
import CapabilityTab from './CapabilityTab'

const { PERMISSION_EDITOR } = capabilities.CAPABILITY_TYPES

const CapabilitiesScreen: React.FC = () => {
  const [tab, setTab] = React.useState<'admin' | 'capability'>('admin')
  const isEditor = useSelector<StoreState, boolean>((store) =>
    hasCapability(store.admin, PERMISSION_EDITOR)
  )

  if (!isEditor) {
    return (
      <Container>
        <ErrorText>You do not have permissions to view this content</ErrorText>
      </Container>
    )
  }

  return (
    <Container style={{ paddingBottom: '2rem' }}>
      <Button
        isSecondary
        onClick={() => setTab('admin')}
        style={{ marginRight: '1rem' }}
      >
        By admins
      </Button>
      <Button isSecondary onClick={() => setTab('capability')}>
        By capabilities
      </Button>
      {tab === 'admin' && <AdminTab />}
      {tab === 'capability' && <CapabilityTab />}
    </Container>
  )
}

export default CapabilitiesScreen
