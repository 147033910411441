import React, { FunctionComponent, useState } from 'react'
import { formatDistanceToNowStrict } from 'date-fns'
import { types } from '@homevest/timelines'
import * as SC from '../StyledComponents'
import EventIconWithBackground from './EventIconWithBackground'
import DisplayEventContent from './DisplayEventContent'
import { Customer } from '../types'

type TimelineItemComponentPropTypes = {
  event: types.TimelineEvent
  eventIdx: number
  timelineLength: number
  customer: Customer
  homes: types.HomeDetails[]
}

const TimelineItemComponent: FunctionComponent<
  TimelineItemComponentPropTypes
> = ({ event, eventIdx, timelineLength, customer, homes }) => {
  const [expanded, setExpanded] = useState(false)

  const relativeTime = formatDistanceToNowStrict(new Date(event.eventTimeAt), {
    roundingMethod: 'floor'
  })

  return (
    <li>
      <SC.EventOuter onClick={() => setExpanded(!expanded)}>
        {eventIdx !== timelineLength - 1 ? (
          <SC.Line aria-hidden='true' />
        ) : null}
        <SC.EventContainer>
          <EventIconWithBackground
            eventType={event.eventType}
            aria-hidden='true'
          />
          <SC.EventContent>
            <DisplayEventContent
              event={event}
              expanded={expanded}
              customer={customer}
              homes={homes}
            />
            <SC.Timestamp>
              <time dateTime={event.eventTimeAt.toString()}>
                {relativeTime} ago
              </time>
            </SC.Timestamp>
          </SC.EventContent>
        </SC.EventContainer>
      </SC.EventOuter>
    </li>
  )
}

export default TimelineItemComponent
