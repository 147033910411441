"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CATEGORIES = void 0;
exports.CATEGORIES = Object.freeze({
    ACCESS: 'access',
    COLLECTIONS: 'collections',
    EARLY_TERM: 'early_term',
    EVICTIONS: 'evictions',
    HOA: 'hoa',
    HOME_PURCHASE: 'home_purchase',
    INSURANCE: 'insurance',
    MOVE_IN: 'move_in',
    MOVE_OUT: 'move_out',
    PROJECT: 'project_notes',
    RESOLUTION: 'resolution',
    WORK_ORDER: 'work_order'
});
