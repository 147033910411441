// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React, { Suspense, useMemo } from 'react'
import { useQuery, useSubscription } from 'urql'
import { Link, useHistory } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'

import { Button, HeaderText } from 'components/Toolkit'
import SortableTable from './SortableTable'
import FullRental from 'types/FullRental'
import AutoChargeCheckbox from './AutoChargeCheckbox'
import CardPaymentCheckbox from './CardPaymentCheckbox'
import DownloadCSVFileButton from '../DownloadCSVFileButton'

import {
  convertRentalToFullRental,
  getMoveOutDate,
  getMoveOutStatus,
  getAddress,
  convertRentalToCsvLedgerData,
  convertRentalToCsvBalanceData
} from 'lib/rentals'
import {
  UpupRentalListDocument,
  UpupRentalListQuery,
  useUpupRentalListQuery
} from 'graphql/generated'
import { formatMoney } from 'lib/numbers'
import { hasCapability } from 'lib/admin-perms'

const { LEDGER_VIEWER } = capabilities.CAPABILITY_TYPES

type Rental = UpupRentalListQuery['rentals'][number]

function AddressLink({ rental }: { rental: FullRental }) {
  const { id, user } = rental
  const address = getAddress(rental)

  return (
    <Link
      to={{
        pathname: `/rent-roll/${id}`
      }}
      target='_blank'
    >
      {address} <br />
      {user.first_name} {user.last_name}
    </Link>
  )
}

const generateAddress = (rental: FullRental) => {
  return <AddressLink rental={rental} />
}

const generateAutoChargeCheckbox = (rental: FullRental) => {
  return <AutoChargeCheckbox rental={rental} />
}

const generateCardPaymentCheckbox = (rental: FullRental) => {
  return <CardPaymentCheckbox rental={rental} />
}

const columns = [
  {
    key: 'address',
    name: 'Rental',
    formatter: generateAddress
  },
  {
    key: 'occupancy',
    name: 'Occupancy Dates',
    formatter: (rental: FullRental) => {
      const moveOutDate = getMoveOutDate(rental)?.format('YYYY-MM-DD') || '?'
      return `${rental.occupancy_date} - ${moveOutDate}`
    },
    sort: (a: FullRental, b: FullRental) => {
      return moment(a.occupancy_date).diff(b.occupancy_date, 'days')
    }
  },
  {
    key: 'daysUntilMoveout',
    name: 'Days Until Lease Ends',
    isDefault: true,
    formatter: ({ statusLabel }: FullRental) => statusLabel,
    sort: (a: FullRental, b: FullRental) => {
      const { daysUntilMoveout: aDaysUntilMoveOut, statusLabel: aStatusLabel } =
        getMoveOutStatus(a)
      const { daysUntilMoveout: bDaysUntilMoveOut, statusLabel: bStatusLabel } =
        getMoveOutStatus(b)

      if (
        aDaysUntilMoveOut &&
        aDaysUntilMoveOut >= 0 &&
        aDaysUntilMoveOut <= 7 &&
        (!bDaysUntilMoveOut || bDaysUntilMoveOut < 0 || bDaysUntilMoveOut > 7)
      ) {
        return -1
      }

      if (
        bDaysUntilMoveOut &&
        bDaysUntilMoveOut >= 0 &&
        bDaysUntilMoveOut <= 7 &&
        (!aDaysUntilMoveOut || aDaysUntilMoveOut > 7 || aDaysUntilMoveOut < 0)
      ) {
        return 1
      }

      if (
        aDaysUntilMoveOut &&
        bDaysUntilMoveOut &&
        aDaysUntilMoveOut >= 0 &&
        bDaysUntilMoveOut >= 0 &&
        aDaysUntilMoveOut <= 7 &&
        bDaysUntilMoveOut <= 7
      ) {
        return aDaysUntilMoveOut - bDaysUntilMoveOut
      }

      if (
        aStatusLabel === 'Pending move-in' &&
        bStatusLabel !== 'Pending move-in'
      ) {
        return -1
      }

      if (
        aStatusLabel !== 'Pending move-in' &&
        bStatusLabel === 'Pending move-in'
      ) {
        return 1
      }

      if (aStatusLabel === 'Holdover' && bStatusLabel !== 'Holdover') {
        return -1
      }

      if (aStatusLabel !== 'Holdover' && bStatusLabel === 'Holdover') {
        return 1
      }

      if (aStatusLabel === 'Moved out' && bStatusLabel !== 'Moved out') {
        return 1
      }

      if (aStatusLabel !== 'Moved out' && bStatusLabel === 'Moved out') {
        return -1
      }

      return aDaysUntilMoveOut - bDaysUntilMoveOut
    }
  },
  {
    key: 'rent',
    name: 'Rent',
    formatter: ({ rent }: FullRental) => formatMoney(rent, 2, '$'),
    sort: (a: FullRental, b: FullRental) => a.rent - b.rent
  },
  {
    key: 'optionPremium',
    name: 'Monthly Option Premium',
    formatter: ({ optionPremium }: FullRental) =>
      formatMoney(optionPremium, 2, '$'),
    sort: (a: FullRental, b: FullRental) => a.optionPremium - b.optionPremium
  },
  {
    key: 'balance',
    name: 'Balance',
    formatter: ({ balance }: FullRental) => formatMoney(balance, 2, '$'),
    sort: (a: FullRental, b: FullRental) => a.balance - b.balance
  },
  {
    key: 'investmentToDate',
    name: 'Amount Invested',
    formatter: ({ investmentToDate }: FullRental) =>
      formatMoney(investmentToDate, 2, '$'),
    sort: (a: FullRental, b: FullRental) =>
      a.investmentToDate - b.investmentToDate
  },
  {
    key: 'rentPaidToDate',
    name: 'Rent Paid to Date',
    formatter: ({ rentPaidToDate }: FullRental) =>
      formatMoney(rentPaidToDate, 2, '$'),
    sort: (a: FullRental, b: FullRental) => a.rentPaidToDate - b.rentPaidToDate
  },
  {
    key: 'auto_charge',
    name: 'Auto Charge Enabled?',
    formatter: generateAutoChargeCheckbox,
    sort: (a: FullRental, b: FullRental) =>
      a.auto_charge && !b.auto_charge ? -1 : 1
  },
  {
    key: 'is_card_payment_allowed',
    name: 'Card Payment Enabled?',
    formatter: generateCardPaymentCheckbox,
    sort: (a: FullRental, b: FullRental) =>
      a.is_card_payment_allowed && !b.is_card_payment_allowed ? -1 : 1
  }
]

function Rentals() {
  const context = useMemo(
    () => ({
      suspense: false
    }),
    []
  )
  const [{ data }] = useUpupRentalListQuery({
    context
  })
  const admin = useSelector((state) => state.admin)
  const history = useHistory()

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)

  if (!canViewPage) {
    history.push('/review')
    return null
  }

  const rentals: Rental[] = data?.rentals.map(convertRentalToFullRental) || []

  const csvLedgerData = rentals.reduce((acc, rental, idx) => {
    const rentalCsvData = convertRentalToCsvLedgerData(rental)

    acc.push(...rentalCsvData.slice(idx === 0 ? 0 : 1))

    return acc
  }, [])

  const csvBalanceData = rentals.reduce((acc, rental, idx) => {
    const rentalCsvData = convertRentalToCsvBalanceData(rental)

    acc.push(...rentalCsvData.slice(idx === 0 ? 0 : 1))

    return acc
  }, [])

  return (
    <div style={{ margin: '0 1rem', display: 'flex', flexDirection: 'column' }}>
      <HeaderText size='h3'>Rent Roll</HeaderText>
      <div>
        <DownloadCSVFileButton
          label={'Download Ledger'}
          data={csvLedgerData}
          filePrefix={'upandup-ledger-'}
        ></DownloadCSVFileButton>
        <DownloadCSVFileButton
          label={'Download Balance Report'}
          data={csvBalanceData}
          filePrefix={'upandup-balances-'}
        ></DownloadCSVFileButton>
      </div>

      <SortableTable
        columns={columns}
        items={rentals.map((r) => ({
          ...r,
          style:
            getMoveOutStatus(r).statusLabel === 'Moved out'
              ? { backgroundColor: '#dee2e6' }
              : {}
        }))}
      />
    </div>
  )
}

export default Rentals
