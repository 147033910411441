import { ColDef } from 'ag-grid-community'
import { first } from 'lodash'

import SetFilter from './filters/SetFilter'
import { PropertyGridDataType } from './grid'
// import SetFloatingFilter from './filters/SetFloatingFilter';

export const columns: ColDef<PropertyGridDataType>[] = [
  {
    headerName: 'ID',
    field: 'id',
    hide: true
  },
  {
    colId: 'address',
    headerName: 'Address',
    field: 'real_estate_acquisition.mls_listing.display_line_1'
  },
  {
    headerName: 'Market',
    field: 'real_estate_acquisition.mls_listing.market.display_name',
    filter: SetFilter
  },
  {
    headerName: 'Sq Ft.',
    field: 'real_estate_acquisition.mls_listing.size',
    filter: 'number'
  },
  {
    headerName: 'Year',
    field: 'real_estate_acquisition.mls_listing.year_built',
    filter: 'number'
  },
  {
    colId: 'num_beds',
    headerName: 'Beds',
    width: 80,
    field: 'real_estate_acquisition.mls_listing.beds',
    filter: 'number',
    filterParams: {
      defaultOption: 'greaterThanOrEqual'
    }
  },
  {
    colId: 'num_baths',
    headerName: 'Baths',
    width: 80,
    field: 'real_estate_acquisition.mls_listing.baths_total',
    filter: 'number',
    filterParams: {
      defaultOption: 'greaterThanOrEqual'
    }
  },
  {
    headerName: 'Status',
    field: 'real_estate_acquisition.status',
    filter: SetFilter
  },
  {
    headerName: 'Type',
    field: 'real_estate_acquisition.type',
    filter: SetFilter
  },
  {
    headerName: 'Closing Date',
    field: 'real_estate_acquisition.closing_date',
    filter: 'date'
  },
  {
    headerName: 'Purchase Price',
    field: 'real_estate_acquisition.final_purchase_price',
    filter: 'number'
  },
  {
    headerName: 'Rent Ready',
    colId: 'rent_ready_date',
    valueGetter: (params) => {
      const rentReadyDate = first(
        params.data?.real_estate_acquisition?.mls_listing.rent_ready_dates
      )
      return rentReadyDate?.earliest_move_in_date
    },
    filter: 'date'
  }
]
