import React, { useState, FunctionComponent } from 'react'
import styled from 'styled-components'

import { REQUEST_POLICY } from 'constants/urql'
import { ErrorText } from 'components/Toolkit'
import TimelineComponent from 'components/360/Timeline'
import Settings from './Settings'

import { useUpUpUserTimelineByUserIdQuery as useTimelineQuery } from 'graphql/generated'

import PropTypes from 'prop-types'

const StyledContainer = styled.div`
  margin-top: 20px;
  padding: 0 20px 0 20px;
  width: 100%;
  white-space: pre-line;
`

type TimelineProps = {
  userId: string
}

const Timeline: FunctionComponent<TimelineProps> = ({ userId }) => {
  const [propertyDetailsIncluded, setPropertyDetailsIncluded] = useState(true)
  const [conversationsIncluded, setConversationsIncluded] = useState(true)
  const [toursIncluded, setToursIncluded] = useState(true)
  const [rentalAppEventsIncluded, setRentalAppEventsIncluded] = useState(true)
  const [inquiriesIncluded, setInquiriesIncluded] = useState(true)
  const [ascending, setAscending] = useState(true)

  const settingsObjects = [
    {
      getter: propertyDetailsIncluded,
      setter: setPropertyDetailsIncluded,
      text: 'Property details included?'
    },
    {
      getter: conversationsIncluded,
      setter: setConversationsIncluded,
      text: 'Conversations included?'
    },
    {
      getter: toursIncluded,
      setter: setToursIncluded,
      text: 'Tours included?'
    },
    {
      getter: rentalAppEventsIncluded,
      setter: setRentalAppEventsIncluded,
      text: 'Rental application events included?'
    },
    {
      getter: inquiriesIncluded,
      setter: setInquiriesIncluded,
      text: 'Rental site inquiries included?'
    },
    {
      getter: ascending,
      setter: setAscending,
      text: 'Ascending?'
    }
  ]
  return (
    <StyledContainer>
      <h2>Customer Timeline</h2>
      <Settings settingsStateObjects={settingsObjects} />
      <React.Suspense fallback={<span>Loading...</span>}>
        <TimelineRender
          {...{
            userId,
            conversationsIncluded,
            propertyDetailsIncluded,
            toursIncluded,
            rentalAppEventsIncluded,
            inquiriesIncluded,
            ascending
          }}
        />
      </React.Suspense>
    </StyledContainer>
  )
}

type TimelineRenderProps = {
  userId: string
  propertyDetailsIncluded: boolean
  conversationsIncluded: boolean
  toursIncluded: boolean
  rentalAppEventsIncluded: boolean
  inquiriesIncluded: boolean
  ascending?: boolean
}

const TimelineRender: FunctionComponent<TimelineRenderProps> = ({
  userId,
  propertyDetailsIncluded,
  conversationsIncluded,
  toursIncluded,
  rentalAppEventsIncluded,
  inquiriesIncluded,
  ascending
}) => {
  const [{ data, error, fetching }] = useTimelineQuery({
    variables: {
      id: userId,
      with_conversations: conversationsIncluded,
      with_property_details: propertyDetailsIncluded,
      with_tours: toursIncluded,
      with_rental_app_events: rentalAppEventsIncluded,
      with_rental_site_leads: inquiriesIncluded
    },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (fetching) {
    return <StyledContainer>Fetching...</StyledContainer>
  }

  if (error) {
    return (
      <ErrorText>
        <pre>
          <code>{JSON.stringify(error, null, 4)}</code>
        </pre>
      </ErrorText>
    )
  }

  return (
    <StyledContainer>
      <TimelineComponent queryData={data} ascending={ascending} />
    </StyledContainer>
  )
}

Timeline.propTypes = {
  userId: PropTypes.string.isRequired
}

export default Timeline
