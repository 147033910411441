import React from 'react'
import PropTypes from 'prop-types'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const Paginator: React.FC<{
  itemCount: number | undefined
  onPageChange: (page: number) => void
  pageOffset: number
  pages: number | undefined
  pageSize: number | undefined
}> = ({
  itemCount = 0,
  onPageChange,
  pageOffset,
  pages = 1,
  pageSize = 50
}) => {
  const pageList = new Array(pages).fill(0).map((_, i) => i + 1)
  const lastPage = itemCount < pageSize

  return (
    <Pagination>
      <PaginationItem key='first' disabled={pageOffset === 0}>
        <PaginationLink first onClick={() => onPageChange(0)} />
      </PaginationItem>
      <PaginationItem key='prev' disabled={pageOffset === 0}>
        <PaginationLink previous onClick={() => onPageChange(pageOffset - 1)} />
      </PaginationItem>
      {pageList.map((key) => (
        <PaginationItem key={key} active={pageOffset === key - 1}>
          <PaginationLink
            onClick={() =>
              pageOffset === key - 1 ? null : onPageChange(key - 1)
            }
          >
            {key}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem key='next' disabled={lastPage}>
        <PaginationLink next onClick={() => onPageChange(pageOffset + 1)} />
      </PaginationItem>
    </Pagination>
  )
}

Paginator.propTypes = {
  itemCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  pageOffset: PropTypes.number.isRequired,
  pages: PropTypes.number,
  pageSize: PropTypes.number
}

export default Paginator
