import { removeBackSlashR } from './helpers'
import _ from 'lodash'
export function csvToJsonArr(csv: any) {
  const lines = csv.split('\n').filter((line: any) => line.length > 0)

  const result = []

  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    const obj = {}
    const currentline = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      _.set(obj, headers[j], removeBackSlashR(currentline[j]))
    }
    result.push(obj)
  }

  //return result; //JavaScript object
  return result //JSON
}
