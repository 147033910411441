import React, { FunctionComponent, useState } from 'react'
import Impersonate from './Impersonate'
import DeleteUser from './DeleteUser'
import ChargeUser from './ChargeUser'

import * as SC from '../StyledComponents'

const DropDown: FunctionComponent = () => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  return (
    <>
      <SC.DropDownContainer>
        <SC.DropDownIcon
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen)
          }}
        />
        <SC.DropDownMenu isOpen={isDropDownOpen}>
          <ChargeUser />
          <Impersonate />
          {/* <div>Merge</div> */}
          <DeleteUser />
        </SC.DropDownMenu>
      </SC.DropDownContainer>
    </>
  )
}

export default DropDown
