import React, { FunctionComponent } from 'react'
import * as SC from '../StyledComponents'

type NonEditModePropTypes = {
  content: JSX.Element | string
  primary?: boolean
}

const NonEditMode: FunctionComponent<NonEditModePropTypes> = ({
  content,
  primary = false
}) => {
  return (
    <SC.DetailsText>
      {content}
      {primary ? <SC.PrimaryStar /> : null}
    </SC.DetailsText>
  )
}

export default NonEditMode
