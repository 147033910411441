import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'lib/axios'
import { hasCapability } from 'lib/admin-perms'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState, TypedDispatch } from 'store'

import InputModal from '../Modals/InputModal'
// users are prompted to enter the customer's name before deleting
// if the customer's name is blank, use an alternative word as confirmation
import { ALT_ANSWER } from 'constants/deletion-challenge'

import {
  setErrorMessage,
  setErrorModalIsOpen,
  setSuccessMessage,
  setSuccessModalIsOpen
} from 'state/modals/actions'
import { setReexecuteHeader } from 'state/refresh-components/actions'

import * as SC from '../StyledComponents'
import { RED_600 } from '../colors'

import { capabilities } from '@homevest/utils'
const { USER_MANAGER } = capabilities.CAPABILITY_TYPES

type DeleteUserComponentPropTypes = {
  setErrorMessage: Function
  setErrorModalIsOpen: Function
  setSuccessMessage: Function
  setSuccessModalIsOpen: Function
  setReexecuteHeader: Function
  firstName: string
  lastName: string
}

const DeleteUser: FunctionComponent<DeleteUserComponentPropTypes> = ({
  setErrorMessage,
  setErrorModalIsOpen,
  setSuccessMessage,
  setSuccessModalIsOpen,
  setReexecuteHeader,
  firstName,
  lastName
}) => {
  const { userId } = useParams<{ userId: string }>()
  const admin = useSelector((store: StoreState) => store.admin)
  const userName = `${firstName} ${lastName}`.trim()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeletionAllowed, setisDeletionAllowed] = useState(false)

  const message = `You are about to delete ${
    userName ? userName : 'this user'
  }. Please enter ${userName ? 'their name' : ALT_ANSWER} to confirm deletion.`

  useEffect(() => {
    setisDeletionAllowed(hasCapability(admin, USER_MANAGER))
  }, [admin])

  const checkChallenge = (challengeAnswer: string) => {
    if (
      (!userName && challengeAnswer === ALT_ANSWER) ||
      (userName && challengeAnswer === userName)
    ) {
      deleteUser()
    } else if (challengeAnswer) {
      setErrorMessage(
        'You failed to enter the challenge properly, deletion ignored.'
      )
      setErrorModalIsOpen(true)
    }
  }

  const deleteUser = async () => {
    try {
      await axios.delete(`/admin/users/${userId}`)
    } catch (err: any) {
      setErrorMessage(err.message)
      setErrorModalIsOpen(true)
      return
    }
    // set success and trigger requery
    setSuccessMessage('The user has been deleted! 👋')
    setSuccessModalIsOpen(true)
    setReexecuteHeader(true)
  }

  return isDeletionAllowed ? (
    <>
      <SC.DropDownMenuItem
        style={{ color: RED_600 }}
        onClick={() => setIsModalOpen(true)}
      >
        Delete
      </SC.DropDownMenuItem>
      <InputModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        message={message}
        callback={checkChallenge}
      />
    </>
  ) : null
}

const mapStateToProps = (state: StoreState) => ({
  firstName: state.personalDetails.first_name,
  lastName: state.personalDetails.last_name
})

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
  setErrorModalIsOpen: (mode: boolean) => dispatch(setErrorModalIsOpen(mode)),
  setSuccessMessage: (message: string) => dispatch(setSuccessMessage(message)),
  setSuccessModalIsOpen: (mode: boolean) =>
    dispatch(setSuccessModalIsOpen(mode)),
  setReexecuteHeader: (mode: boolean) => dispatch(setReexecuteHeader(mode))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser)
