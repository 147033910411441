import React from 'react'
import PropTypes from 'prop-types'
import { groupBy } from 'lodash'

import { isSellerAgentPrimary } from '../../lib/seller-agents'
import AgentDetailsForm from './DetailsForm.js'
import SellerAgentProperty from './SellerAgentProperty'
import StatusSectionHeader from './StatusSectionHeader'

/**
 * Divides the raw seller agent properties into an Object
 * where the key is the internal status and the value is an
 * Array containing all the properties with that internal status
 * @param {Array} sellerAgentProperties
 */
const divideByStatus = (sellerAgentProperties) => {
  return groupBy(
    sellerAgentProperties,
    (p) => p.listing.internal_attributes.internal_status
  )
}

export default function SellerAgentDetails({ agent }) {
  const { id: sellerAgentId } = agent

  // agent.listings is an array of seller agent properties -- naming is a bit confusing
  const primaryListings = agent.listings.filter((sellerAgentProperty) =>
    isSellerAgentPrimary(sellerAgentId, sellerAgentProperty)
  )
  const listingsByStatus = divideByStatus(primaryListings)

  return (
    <div style={{ marginTop: '2rem' }}>
      <AgentDetailsForm agent={agent} />
      {Object.entries(listingsByStatus)
        .sort((a, b) => a[0].localeCompare(b[0])) // order sections alphabetically by status for now
        .map(([status, listings]) => {
          return (
            <div key={status}>
              <StatusSectionHeader status={status} />
              {listings.map((sellerAgentProperty) => (
                <SellerAgentProperty
                  key={
                    sellerAgentProperty.id ||
                    sellerAgentProperty.listing.firestore_id
                  }
                  sellerAgentProperty={sellerAgentProperty}
                />
              ))}
            </div>
          )
        })}
    </div>
  )
}

SellerAgentDetails.propTypes = {
  agent: PropTypes.object.isRequired
}
