import React from 'react'
import { useQuery } from 'urql'
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Button as StrapButton,
  Spinner
} from 'reactstrap'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'

import AdminList from './AdminList'
import BulkEditModal from './BulkEditModal'
import CreateAdminModal from './CreateAdminModal'
import {
  Button,
  COLORS,
  ErrorText,
  HeaderText,
  ThickPlusIcon
} from 'components/Toolkit'
import { REQUEST_POLICY } from 'constants/urql'
import {
  UpupAdminsWithCapabilitiesDocument,
  UpupAdminsWithCapabilitiesQuery,
  UpupCapabilitiesWithAdminDocument
} from 'graphql/generated'
import { hasCapability } from 'lib/admin-perms'
import { capabilities } from '@homevest/utils'
import { isNotEqual } from 'utils/hasura-comparators'

const { ACCOUNT_CREATOR } = capabilities.CAPABILITY_TYPES

const AdminTab: React.FC = () => {
  const isCreator = useSelector<StoreState, boolean>((store) =>
    hasCapability(store.admin, ACCOUNT_CREATOR)
  )
  const [showBulkModal, setShowBulkModal] = React.useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false)
  const [searchTextView, setSearchTextView] = React.useState<string>('')
  const [searchTextControl, setSearchTextControl] = React.useState<string>('')

  const setSearchSlow = React.useMemo(
    () => debounce(setSearchTextControl, 250, { trailing: true }),
    [setSearchTextControl]
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '2rem',
          marginBottom: '1rem'
        }}
      >
        <HeaderText style={{ margin: 0 }} size='h3'>
          Manage admins
        </HeaderText>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '2rem',
            marginBottom: '1rem'
          }}
        >
          {isCreator && (
            <Button
              isSecondary={false}
              onClick={() => setShowCreateModal(true)}
            >
              <ThickPlusIcon color={COLORS.PRIMARY.COCONUT} /> Add new
            </Button>
          )}
          <Button
            style={{ marginLeft: '1rem' }}
            isSecondary={false}
            onClick={() => setShowBulkModal(true)}
          >
            Bulk edit
          </Button>
        </div>
      </div>
      <InputGroup style={{ marginBottom: '1rem' }}>
        <Input
          autoFocus
          placeholder='Search...'
          value={searchTextView}
          onChange={({ target }) => {
            setSearchTextView(target.value)
            setSearchSlow(target.value)
          }}
        />
        {searchTextView.length > 0 && (
          <InputGroupAddon addonType='append'>
            <StrapButton
              outline
              onClick={() => {
                setSearchTextView('')
                setSearchTextControl('')
              }}
            >
              &#10005;
            </StrapButton>
          </InputGroupAddon>
        )}
      </InputGroup>
      <React.Suspense
        fallback={
          <div
            style={{
              paddingTop: '2rem',
              paddingBottom: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <AdminListWrapper
          searchText={searchTextControl}
          showMultiModal={showBulkModal}
          onCloneAdminCaps={() => setShowBulkModal(true)}
          onCloseMultiModal={() => setShowBulkModal(false)}
        />
      </React.Suspense>
      {isCreator && (
        <CreateAdminModal
          isOpen={showCreateModal}
          onCreate={() => setShowCreateModal(false)}
          onClose={() => setShowCreateModal(false)}
        />
      )}
    </>
  )
}

export default AdminTab

type AdminListWrapperProps = {
  searchText: string
  showMultiModal: boolean
  onCloseMultiModal: () => void
  onCloneAdminCaps: () => void
}

const AdminListWrapper: React.FC<AdminListWrapperProps> = ({
  searchText,
  showMultiModal,
  onCloseMultiModal,
  onCloneAdminCaps
}) => {
  const [clone, setClone] =
    React.useState<UpupAdminsWithCapabilitiesQuery['admins'][number]>()

  const [{ data: capData, error: capError }] = useQuery({
    query: UpupCapabilitiesWithAdminDocument,
    variables: {
      where: {
        type: isNotEqual(ACCOUNT_CREATOR)
      }
    },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  const [{ data: adminData, error: adminError }] = useQuery({
    query: UpupAdminsWithCapabilitiesDocument,
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (capError !== undefined || adminError !== undefined) {
    return (
      <ErrorText>
        {capError?.message ?? ''}
        {adminError?.message ?? ''}
      </ErrorText>
    )
  }

  const filteredAdmins =
    searchText.length > 0
      ? adminData?.admins.filter((admin: any) =>
          `${admin.first_name}${admin.last_name}`
            .toLowerCase()
            .includes(searchText)
        )
      : adminData?.admins

  return (
    <>
      <AdminList
        admins={filteredAdmins}
        capabilities={capData?.capabilities}
        onClone={(admin) => {
          setClone(admin)
          onCloneAdminCaps()
        }}
      />
      <BulkEditModal
        isOpen={showMultiModal}
        cloneFromAdmin={clone}
        onClose={() => {
          setClone(undefined)
          onCloseMultiModal()
        }}
        admins={adminData?.admins}
        capabilities={capData?.capabilities}
      />
    </>
  )
}
