import axios from './axios'
import { formatPhoneNumber } from './phone'
import { getName } from './users'

export const handleAgentPropertyUpdate = (update, sellerAgentPropertyId) => {
  return axios.post(`/seller_agent_properties/${sellerAgentPropertyId}`, {
    called_from_hudson: true,
    ...update
  })
}

export const handleCreateNewAgentPropertyWithUpdate = (
  property,
  propertyAgent,
  approvalStatus,
  isPrimaryAgent,
  doNotContact = false,
  update = {}
) => {
  const contactedAt = new Date()
  const { firstName, lastName } = getName(property.listingAgent.name)

  const sellerAgentProperty = {
    contacted_at: contactedAt,
    property_id: property.id,
    is_primary_agent: isPrimaryAgent
  }

  if (approvalStatus) {
    sellerAgentProperty.approval_status = approvalStatus
  }

  if (update.rejection_reason) {
    sellerAgentProperty.rejection_reason = update.rejection_reason
  }

  if (update.is_soft_offer_sent) {
    sellerAgentProperty.is_soft_offer_sent = update.is_soft_offer_sent
  }

  if (update.note) {
    sellerAgentProperty.note = update.note
  }

  return axios.post('/seller_agents', {
    email: propertyAgent.email,
    phone: formatPhoneNumber(propertyAgent.phone),
    first_name: firstName,
    last_name: lastName,
    mls_id: propertyAgent.id,
    permission_for_all_postings: false,
    do_not_contact: doNotContact,
    mls: property.market,
    last_contacted_at: contactedAt,
    called_from_hudson: true,
    seller_agent_properties: [sellerAgentProperty]
  })
}

export const isSellerAgentPrimary = (
  agentId,
  { is_primary_agent: isPrimaryAgent, seller_agent_id: sellerAgentId, listing }
) => {
  // if seller agent property exists, use that value
  if (isPrimaryAgent !== undefined && agentId === sellerAgentId) {
    return isPrimaryAgent
  }

  // otherwise check if the agent is listed as primary in the MLS
  return listing.listing_agent_id === agentId
}
