"use strict";
exports.LEAD_GROUP_PROPERTY_STATUSES = Object.freeze({
    ACTIVE: 'active',
    CUSTOMER_CANCELED: 'customer_canceled',
    CUSTOMER_NOT_A_FIT: 'customer_not_a_fit',
    CUSTOMER_NOT_INTERESTED_IM_PROGRAM: 'customer_not_interested_in_program',
    CUSTOMER_UNQUALIFIED: 'customer_unqualified',
    DEPRIORITIZED: 'deprioritized',
    REMOVED_FROM_MLS: 'removed_from_mls',
    UP_AND_UP_REJECTED: 'upandup_rejected'
});
// 2022-05-18 If you update this, ensure that you also update
// the signatoryLeadGroupUsers relation in api/resources/lead-groups/model.js to ensure that the exclusion of only OCCUPANT
// is still a valid exclusion
exports.LEAD_GROUP_USER_TYPES = Object.freeze({
    COSIGNER: 'cosigner',
    OCCUPANT: 'occupant',
    NON_INCOME_TENANT: 'non_income_tenant',
    PRIMARY: 'primary',
    TENANT: 'tenant'
});
exports.LEAD_GROUP_STATUSES = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive'
});
