import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import format from 'date-fns/format'
import { Customer } from '../types'
import * as SC from '../../StyledComponents'

export const DisplayEmail: FunctionComponent<{
  event: types.EmailTimelineEvent
  expanded: boolean
  customer: Customer
}> = ({ event, expanded }) => {
  const isAutomated = event.fromEmail === 'success@upandup.co'
  const expandedContent = (
    <>
      <SC.EventSubheading>Email</SC.EventSubheading>
      <SC.MessageText>{event.content}</SC.MessageText>
      {event.repliesLink ? (
        <a href={event.repliesLink} target='_blank' rel='noopener noreferrer'>
          Previous replies
        </a>
      ) : null}
      <SC.DescriptionText>
        Subject <SC.InlineBold>{event.subject}</SC.InlineBold>
      </SC.DescriptionText>
      <SC.DescriptionText>
        To
        <SC.InlineBold>
          {event.toEmails.reduce((acc, e) => acc + ' ' + e, '')}
        </SC.InlineBold>
      </SC.DescriptionText>
      <SC.DescriptionText>
        From <SC.InlineBold>{event.fromEmail}</SC.InlineBold>
      </SC.DescriptionText>
      {event.ccEmails.length !== 0 ? (
        <SC.DescriptionText>
          CC{' '}
          <SC.InlineBold>
            {event.ccEmails.reduce((acc, e) => acc + ' ' + e, '')}
          </SC.InlineBold>
        </SC.DescriptionText>
      ) : null}
      {event.bccEmails.length !== 0 ? (
        <SC.DescriptionText>
          BCC{' '}
          <SC.InlineBold>
            {event.bccEmails.reduce((acc, e) => acc + ' ' + e, '')}
          </SC.InlineBold>
        </SC.DescriptionText>
      ) : null}
      <SC.DescriptionText>
        Sent{' '}
        <SC.InlineBold>
          {format(new Date(event.eventTimeAt), 'PPppp')}
        </SC.InlineBold>
      </SC.DescriptionText>
      <SC.DescriptionText>
        Status: <SC.InlineBold>{event.status}</SC.InlineBold>
      </SC.DescriptionText>
    </>
  )
  return (
    <div>
      <SC.MessageText>
        {isAutomated ? (
          'Automated email sent to'
        ) : (
          <span>
            <SC.InlineBold>
              {event.fromName === '' ? event.fromEmail : event.fromName}
            </SC.InlineBold>{' '}
            sent in email to
          </span>
        )}
        <SC.InlineBold>
          {event.toNames.reduce((acc, e) => acc + ' ' + e, '')}
        </SC.InlineBold>{' '}
        from{' '}
        <SC.InlineBoldClickable
          onClick={(e) => e.stopPropagation()}
          href={event.messageLink}
          rel='noopener noreferrer'
          target='_blank'
        >
          {event.provider}
        </SC.InlineBoldClickable>
      </SC.MessageText>
      {expanded ? <SC.FadeIn>{expandedContent}</SC.FadeIn> : null}
    </div>
  )
}
