import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { HeaderText, Button } from 'components/Toolkit'

function Sign({
  waitingForCompany,
  waitingForLandlord,
  launchCompanyLease,
  launchLandlordLease
}) {
  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '20px',
        marginTop: '20px',
        backgroundColor: '#fff'
      }}
    >
      <HeaderText size='h3'>Sign Lease</HeaderText>
      {waitingForCompany && (
        <Button
          style={{ marginRight: '20px' }}
          onClick={() => launchCompanyLease()}
        >
          Company Sign
        </Button>
      )}
      {waitingForLandlord && (
        <Button onClick={() => launchLandlordLease()}>Landlord Sign</Button>
      )}
    </div>
  )
}

Sign.propTypes = {
  launchCompanyLease: PropTypes.func.isRequired,
  launchLandlordLease: PropTypes.func.isRequired,
  waitingForCompany: PropTypes.bool,
  waitingForLandlord: PropTypes.bool
}

export default memo(Sign)
