import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  _jsonb: any
  _varchar: any
  bigint: any
  date: any
  geography: any
  geometry: any
  json: any
  jsonb: any
  numeric: any
  timestamp: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>
  _gt?: Maybe<Scalars['Boolean']>
  _gte?: Maybe<Scalars['Boolean']>
  _in?: Maybe<Array<Scalars['Boolean']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Boolean']>
  _lte?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Scalars['Boolean']>
  _nin?: Maybe<Array<Scalars['Boolean']>>
}

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: Maybe<Scalars['Float']>
  _gt?: Maybe<Scalars['Float']>
  _gte?: Maybe<Scalars['Float']>
  _in?: Maybe<Array<Scalars['Float']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Float']>
  _lte?: Maybe<Scalars['Float']>
  _neq?: Maybe<Scalars['Float']>
  _nin?: Maybe<Array<Scalars['Float']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>
  _gt?: Maybe<Scalars['Int']>
  _gte?: Maybe<Scalars['Int']>
  _in?: Maybe<Array<Scalars['Int']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Int']>
  _lte?: Maybe<Scalars['Int']>
  _neq?: Maybe<Scalars['Int']>
  _nin?: Maybe<Array<Scalars['Int']>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>
  _gt?: Maybe<Scalars['String']>
  _gte?: Maybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>
  _in?: Maybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>
  _is_null?: Maybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>
  _lt?: Maybe<Scalars['String']>
  _lte?: Maybe<Scalars['String']>
  _neq?: Maybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>
  _nin?: Maybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>
}

export type UserQualificationData = {
  __typename: 'UserQualificationData'
  hasCosigner?: Maybe<Scalars['Boolean']>
  hasGoodCreditRoommate?: Maybe<Scalars['Boolean']>
  performedCreditCheck?: Maybe<Scalars['Boolean']>
  selfReportedCreditScoreHigh?: Maybe<Scalars['Int']>
  selfReportedCreditScoreLow?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  verifiedCredit?: Maybe<Scalars['Boolean']>
  voucherType?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "_jsonb". All fields are combined with logical 'AND'. */
export type _Jsonb_Comparison_Exp = {
  _eq?: Maybe<Scalars['_jsonb']>
  _gt?: Maybe<Scalars['_jsonb']>
  _gte?: Maybe<Scalars['_jsonb']>
  _in?: Maybe<Array<Scalars['_jsonb']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['_jsonb']>
  _lte?: Maybe<Scalars['_jsonb']>
  _neq?: Maybe<Scalars['_jsonb']>
  _nin?: Maybe<Array<Scalars['_jsonb']>>
}

/** Boolean expression to compare columns of type "_varchar". All fields are combined with logical 'AND'. */
export type _Varchar_Comparison_Exp = {
  _eq?: Maybe<Scalars['_varchar']>
  _gt?: Maybe<Scalars['_varchar']>
  _gte?: Maybe<Scalars['_varchar']>
  _in?: Maybe<Array<Scalars['_varchar']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['_varchar']>
  _lte?: Maybe<Scalars['_varchar']>
  _neq?: Maybe<Scalars['_varchar']>
  _nin?: Maybe<Array<Scalars['_varchar']>>
}

/** columns and relationships of "admin_capabilities" */
export type Admin_Capabilities = {
  __typename: 'admin_capabilities'
  /** An object relationship */
  admin: Admins
  admin_id: Scalars['uuid']
  /** An object relationship */
  capability: Capabilities
  capability_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** order by aggregate values of table "admin_capabilities" */
export type Admin_Capabilities_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Admin_Capabilities_Max_Order_By>
  min?: Maybe<Admin_Capabilities_Min_Order_By>
}

/** input type for inserting array relation for remote table "admin_capabilities" */
export type Admin_Capabilities_Arr_Rel_Insert_Input = {
  data: Array<Admin_Capabilities_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Admin_Capabilities_On_Conflict>
}

/** Boolean expression to filter rows from the table "admin_capabilities". All fields are combined with a logical 'AND'. */
export type Admin_Capabilities_Bool_Exp = {
  _and?: Maybe<Array<Admin_Capabilities_Bool_Exp>>
  _not?: Maybe<Admin_Capabilities_Bool_Exp>
  _or?: Maybe<Array<Admin_Capabilities_Bool_Exp>>
  admin?: Maybe<Admins_Bool_Exp>
  admin_id?: Maybe<Uuid_Comparison_Exp>
  capability?: Maybe<Capabilities_Bool_Exp>
  capability_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "admin_capabilities" */
export enum Admin_Capabilities_Constraint {
  /** unique or primary key constraint on columns "admin_id", "capability_id" */
  AdminCapabilitiesAdminIdCapabilityIdUnique = 'admin_capabilities_admin_id_capability_id_unique',
  /** unique or primary key constraint on columns "id" */
  AdminCapabilitiesPkey = 'admin_capabilities_pkey'
}

/** input type for inserting data into table "admin_capabilities" */
export type Admin_Capabilities_Insert_Input = {
  admin?: Maybe<Admins_Obj_Rel_Insert_Input>
  admin_id?: Maybe<Scalars['uuid']>
  capability?: Maybe<Capabilities_Obj_Rel_Insert_Input>
  capability_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "admin_capabilities" */
export type Admin_Capabilities_Max_Order_By = {
  admin_id?: Maybe<Order_By>
  capability_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "admin_capabilities" */
export type Admin_Capabilities_Min_Order_By = {
  admin_id?: Maybe<Order_By>
  capability_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "admin_capabilities" */
export type Admin_Capabilities_Mutation_Response = {
  __typename: 'admin_capabilities_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Admin_Capabilities>
}

/** on_conflict condition type for table "admin_capabilities" */
export type Admin_Capabilities_On_Conflict = {
  constraint: Admin_Capabilities_Constraint
  update_columns?: Array<Admin_Capabilities_Update_Column>
  where?: Maybe<Admin_Capabilities_Bool_Exp>
}

/** Ordering options when selecting data from "admin_capabilities". */
export type Admin_Capabilities_Order_By = {
  admin?: Maybe<Admins_Order_By>
  admin_id?: Maybe<Order_By>
  capability?: Maybe<Capabilities_Order_By>
  capability_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "admin_capabilities" */
export enum Admin_Capabilities_Select_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CapabilityId = 'capability_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** placeholder for update columns of table "admin_capabilities" (current role has no relevant permissions) */
export enum Admin_Capabilities_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "admins" */
export type Admins = {
  __typename: 'admins'
  /** An array relationship */
  admin_capabilities: Array<Admin_Capabilities>
  created_at: Scalars['timestamptz']
  email: Scalars['String']
  first_name: Scalars['String']
  front_id?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  last_name: Scalars['String']
  /** An array relationship */
  notes: Array<Notes>
  permissions: Scalars['jsonb']
  status: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "admins" */
export type AdminsAdmin_CapabilitiesArgs = {
  distinct_on?: Maybe<Array<Admin_Capabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Admin_Capabilities_Order_By>>
  where?: Maybe<Admin_Capabilities_Bool_Exp>
}

/** columns and relationships of "admins" */
export type AdminsNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notes_Order_By>>
  where?: Maybe<Notes_Bool_Exp>
}

/** columns and relationships of "admins" */
export type AdminsPermissionsArgs = {
  path?: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "admins". All fields are combined with a logical 'AND'. */
export type Admins_Bool_Exp = {
  _and?: Maybe<Array<Admins_Bool_Exp>>
  _not?: Maybe<Admins_Bool_Exp>
  _or?: Maybe<Array<Admins_Bool_Exp>>
  admin_capabilities?: Maybe<Admin_Capabilities_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  first_name?: Maybe<String_Comparison_Exp>
  front_id?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_name?: Maybe<String_Comparison_Exp>
  notes?: Maybe<Notes_Bool_Exp>
  permissions?: Maybe<Jsonb_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "admins" */
export enum Admins_Constraint {
  /** unique or primary key constraint on columns "email" */
  AdminsEmailUnique = 'admins_email_unique',
  /** unique or primary key constraint on columns "id" */
  AdminsPkey = 'admins_pkey'
}

/** input type for inserting data into table "admins" */
export type Admins_Insert_Input = {
  admin_capabilities?: Maybe<Admin_Capabilities_Arr_Rel_Insert_Input>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>
}

/** response of any mutation on the table "admins" */
export type Admins_Mutation_Response = {
  __typename: 'admins_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Admins>
}

/** input type for inserting object relation for remote table "admins" */
export type Admins_Obj_Rel_Insert_Input = {
  data: Admins_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Admins_On_Conflict>
}

/** on_conflict condition type for table "admins" */
export type Admins_On_Conflict = {
  constraint: Admins_Constraint
  update_columns?: Array<Admins_Update_Column>
  where?: Maybe<Admins_Bool_Exp>
}

/** Ordering options when selecting data from "admins". */
export type Admins_Order_By = {
  admin_capabilities_aggregate?: Maybe<Admin_Capabilities_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  first_name?: Maybe<Order_By>
  front_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>
  permissions?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "admins" */
export enum Admins_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FrontId = 'front_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** placeholder for update columns of table "admins" (current role has no relevant permissions) */
export enum Admins_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "agent_user_owners" */
export type Agent_User_Owners = {
  __typename: 'agent_user_owners'
  /** An object relationship */
  admin?: Maybe<Admins>
  agent_admin_id: Scalars['uuid']
  agent_type: Scalars['String']
  created_at: Scalars['timestamptz']
  deactivated_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['uuid']
}

/** Boolean expression to filter rows from the table "agent_user_owners". All fields are combined with a logical 'AND'. */
export type Agent_User_Owners_Bool_Exp = {
  _and?: Maybe<Array<Agent_User_Owners_Bool_Exp>>
  _not?: Maybe<Agent_User_Owners_Bool_Exp>
  _or?: Maybe<Array<Agent_User_Owners_Bool_Exp>>
  admin?: Maybe<Admins_Bool_Exp>
  agent_admin_id?: Maybe<Uuid_Comparison_Exp>
  agent_type?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** Ordering options when selecting data from "agent_user_owners". */
export type Agent_User_Owners_Order_By = {
  admin?: Maybe<Admins_Order_By>
  agent_admin_id?: Maybe<Order_By>
  agent_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "agent_user_owners" */
export enum Agent_User_Owners_Select_Column {
  /** column name */
  AgentAdminId = 'agent_admin_id',
  /** column name */
  AgentType = 'agent_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "agents" */
export type Agents = {
  __typename: 'agents'
  created_at?: Maybe<Scalars['timestamptz']>
  deactivated_at?: Maybe<Scalars['timestamptz']>
  email: Scalars['String']
  first_name: Scalars['String']
  home_address_city?: Maybe<Scalars['String']>
  home_address_coordinates?: Maybe<Scalars['geography']>
  home_address_state?: Maybe<Scalars['String']>
  home_address_street?: Maybe<Scalars['String']>
  home_address_zip?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  last_name: Scalars['String']
  phone: Scalars['String']
  priority: Scalars['Float']
  seller_agent_id?: Maybe<Scalars['uuid']>
  showingtime_password?: Maybe<Scalars['String']>
  showingtime_username?: Maybe<Scalars['String']>
  status: Scalars['String']
  type: Scalars['String']
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Boolean expression to filter rows from the table "agents". All fields are combined with a logical 'AND'. */
export type Agents_Bool_Exp = {
  _and?: Maybe<Array<Agents_Bool_Exp>>
  _not?: Maybe<Agents_Bool_Exp>
  _or?: Maybe<Array<Agents_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  first_name?: Maybe<String_Comparison_Exp>
  home_address_city?: Maybe<String_Comparison_Exp>
  home_address_coordinates?: Maybe<Geography_Comparison_Exp>
  home_address_state?: Maybe<String_Comparison_Exp>
  home_address_street?: Maybe<String_Comparison_Exp>
  home_address_zip?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_name?: Maybe<String_Comparison_Exp>
  phone?: Maybe<String_Comparison_Exp>
  priority?: Maybe<Float_Comparison_Exp>
  seller_agent_id?: Maybe<Uuid_Comparison_Exp>
  showingtime_password?: Maybe<String_Comparison_Exp>
  showingtime_username?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "agents". */
export type Agents_Order_By = {
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  first_name?: Maybe<Order_By>
  home_address_city?: Maybe<Order_By>
  home_address_coordinates?: Maybe<Order_By>
  home_address_state?: Maybe<Order_By>
  home_address_street?: Maybe<Order_By>
  home_address_zip?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  priority?: Maybe<Order_By>
  seller_agent_id?: Maybe<Order_By>
  showingtime_password?: Maybe<Order_By>
  showingtime_username?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "agents" */
export enum Agents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HomeAddressCity = 'home_address_city',
  /** column name */
  HomeAddressCoordinates = 'home_address_coordinates',
  /** column name */
  HomeAddressState = 'home_address_state',
  /** column name */
  HomeAddressStreet = 'home_address_street',
  /** column name */
  HomeAddressZip = 'home_address_zip',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Priority = 'priority',
  /** column name */
  SellerAgentId = 'seller_agent_id',
  /** column name */
  ShowingtimePassword = 'showingtime_password',
  /** column name */
  ShowingtimeUsername = 'showingtime_username',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "agreement_signers" */
export type Agreement_Signers = {
  __typename: 'agreement_signers'
  agreement_id: Scalars['uuid']
  external_id: Scalars['String']
  external_source: Scalars['String']
  id: Scalars['uuid']
  resource_id: Scalars['uuid']
  resource_type: Scalars['String']
  role: Scalars['String']
  signed_at?: Maybe<Scalars['timestamptz']>
  status: Scalars['String']
  /** An object relationship */
  user?: Maybe<Users>
}

/** order by aggregate values of table "agreement_signers" */
export type Agreement_Signers_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Agreement_Signers_Max_Order_By>
  min?: Maybe<Agreement_Signers_Min_Order_By>
}

/** Boolean expression to filter rows from the table "agreement_signers". All fields are combined with a logical 'AND'. */
export type Agreement_Signers_Bool_Exp = {
  _and?: Maybe<Array<Agreement_Signers_Bool_Exp>>
  _not?: Maybe<Agreement_Signers_Bool_Exp>
  _or?: Maybe<Array<Agreement_Signers_Bool_Exp>>
  agreement_id?: Maybe<Uuid_Comparison_Exp>
  external_id?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  role?: Maybe<String_Comparison_Exp>
  signed_at?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
}

/** order by max() on columns of table "agreement_signers" */
export type Agreement_Signers_Max_Order_By = {
  agreement_id?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  role?: Maybe<Order_By>
  signed_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
}

/** order by min() on columns of table "agreement_signers" */
export type Agreement_Signers_Min_Order_By = {
  agreement_id?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  role?: Maybe<Order_By>
  signed_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
}

/** Ordering options when selecting data from "agreement_signers". */
export type Agreement_Signers_Order_By = {
  agreement_id?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  role?: Maybe<Order_By>
  signed_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
}

/** select columns of table "agreement_signers" */
export enum Agreement_Signers_Select_Column {
  /** column name */
  AgreementId = 'agreement_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Role = 'role',
  /** column name */
  SignedAt = 'signed_at',
  /** column name */
  Status = 'status'
}

/** columns and relationships of "agreements" */
export type Agreements = {
  __typename: 'agreements'
  /** An array relationship */
  agreement_signers: Array<Agreement_Signers>
  completed_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  /** An array relationship */
  documents: Array<Documents>
  external_id: Scalars['String']
  external_source: Scalars['String']
  id: Scalars['uuid']
  metadata: Scalars['jsonb']
  /** An object relationship */
  rental_application?: Maybe<Rental_Applications>
  resource_id: Scalars['uuid']
  resource_type: Scalars['String']
  reviewed_at?: Maybe<Scalars['timestamptz']>
  status: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "agreements" */
export type AgreementsAgreement_SignersArgs = {
  distinct_on?: Maybe<Array<Agreement_Signers_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agreement_Signers_Order_By>>
  where?: Maybe<Agreement_Signers_Bool_Exp>
}

/** columns and relationships of "agreements" */
export type AgreementsDocumentsArgs = {
  distinct_on?: Maybe<Array<Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Documents_Order_By>>
  where?: Maybe<Documents_Bool_Exp>
}

/** columns and relationships of "agreements" */
export type AgreementsMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** order by aggregate values of table "agreements" */
export type Agreements_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Agreements_Max_Order_By>
  min?: Maybe<Agreements_Min_Order_By>
}

/** Boolean expression to filter rows from the table "agreements". All fields are combined with a logical 'AND'. */
export type Agreements_Bool_Exp = {
  _and?: Maybe<Array<Agreements_Bool_Exp>>
  _not?: Maybe<Agreements_Bool_Exp>
  _or?: Maybe<Array<Agreements_Bool_Exp>>
  agreement_signers?: Maybe<Agreement_Signers_Bool_Exp>
  completed_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  documents?: Maybe<Documents_Bool_Exp>
  external_id?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  rental_application?: Maybe<Rental_Applications_Bool_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  reviewed_at?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "agreements" */
export type Agreements_Max_Order_By = {
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "agreements" */
export type Agreements_Min_Order_By = {
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "agreements". */
export type Agreements_Order_By = {
  agreement_signers_aggregate?: Maybe<Agreement_Signers_Aggregate_Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  documents_aggregate?: Maybe<Documents_Aggregate_Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  rental_application?: Maybe<Rental_Applications_Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "agreements" */
export enum Agreements_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>
  _gt?: Maybe<Scalars['bigint']>
  _gte?: Maybe<Scalars['bigint']>
  _in?: Maybe<Array<Scalars['bigint']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['bigint']>
  _lte?: Maybe<Scalars['bigint']>
  _neq?: Maybe<Scalars['bigint']>
  _nin?: Maybe<Array<Scalars['bigint']>>
}

/** columns and relationships of "capabilities" */
export type Capabilities = {
  __typename: 'capabilities'
  /** An array relationship */
  admin_capabilities: Array<Admin_Capabilities>
  created_at: Scalars['timestamptz']
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  name: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "capabilities" */
export type CapabilitiesAdmin_CapabilitiesArgs = {
  distinct_on?: Maybe<Array<Admin_Capabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Admin_Capabilities_Order_By>>
  where?: Maybe<Admin_Capabilities_Bool_Exp>
}

/** Boolean expression to filter rows from the table "capabilities". All fields are combined with a logical 'AND'. */
export type Capabilities_Bool_Exp = {
  _and?: Maybe<Array<Capabilities_Bool_Exp>>
  _not?: Maybe<Capabilities_Bool_Exp>
  _or?: Maybe<Array<Capabilities_Bool_Exp>>
  admin_capabilities?: Maybe<Admin_Capabilities_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "capabilities" */
export enum Capabilities_Constraint {
  /** unique or primary key constraint on columns "name" */
  CapabilitiesNameUnique = 'capabilities_name_unique',
  /** unique or primary key constraint on columns "id" */
  CapabilitiesPkey = 'capabilities_pkey',
  /** unique or primary key constraint on columns "type" */
  CapabilitiesTypeUnique = 'capabilities_type_unique'
}

/** input type for inserting data into table "capabilities" */
export type Capabilities_Insert_Input = {
  admin_capabilities?: Maybe<Admin_Capabilities_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "capabilities" */
export type Capabilities_Mutation_Response = {
  __typename: 'capabilities_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Capabilities>
}

/** input type for inserting object relation for remote table "capabilities" */
export type Capabilities_Obj_Rel_Insert_Input = {
  data: Capabilities_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Capabilities_On_Conflict>
}

/** on_conflict condition type for table "capabilities" */
export type Capabilities_On_Conflict = {
  constraint: Capabilities_Constraint
  update_columns?: Array<Capabilities_Update_Column>
  where?: Maybe<Capabilities_Bool_Exp>
}

/** Ordering options when selecting data from "capabilities". */
export type Capabilities_Order_By = {
  admin_capabilities_aggregate?: Maybe<Admin_Capabilities_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "capabilities" */
export enum Capabilities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** placeholder for update columns of table "capabilities" (current role has no relevant permissions) */
export enum Capabilities_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "construction_projects" */
export type Construction_Projects = {
  __typename: 'construction_projects'
  assigned_to_admin_id?: Maybe<Scalars['uuid']>
  cancelation_reason?: Maybe<Scalars['String']>
  canceled_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  external_workorder_number?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  resource_id: Scalars['uuid']
  resource_type: Scalars['String']
  status: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** order by aggregate values of table "construction_projects" */
export type Construction_Projects_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Construction_Projects_Max_Order_By>
  min?: Maybe<Construction_Projects_Min_Order_By>
}

/** Boolean expression to filter rows from the table "construction_projects". All fields are combined with a logical 'AND'. */
export type Construction_Projects_Bool_Exp = {
  _and?: Maybe<Array<Construction_Projects_Bool_Exp>>
  _not?: Maybe<Construction_Projects_Bool_Exp>
  _or?: Maybe<Array<Construction_Projects_Bool_Exp>>
  assigned_to_admin_id?: Maybe<Uuid_Comparison_Exp>
  cancelation_reason?: Maybe<String_Comparison_Exp>
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  external_workorder_number?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "construction_projects" */
export type Construction_Projects_Max_Order_By = {
  assigned_to_admin_id?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  external_workorder_number?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "construction_projects" */
export type Construction_Projects_Min_Order_By = {
  assigned_to_admin_id?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  external_workorder_number?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "construction_projects". */
export type Construction_Projects_Order_By = {
  assigned_to_admin_id?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  external_workorder_number?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "construction_projects" */
export enum Construction_Projects_Select_Column {
  /** column name */
  AssignedToAdminId = 'assigned_to_admin_id',
  /** column name */
  CancelationReason = 'cancelation_reason',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalWorkorderNumber = 'external_workorder_number',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Track all conversations with our users. */
export type Conversations = {
  __typename: 'conversations'
  admin_id?: Maybe<Scalars['uuid']>
  content?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  direction: Scalars['String']
  external_id: Scalars['String']
  external_source: Scalars['String']
  from?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  medium: Scalars['String']
  payload: Scalars['jsonb']
  status: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** Track all conversations with our users. */
export type ConversationsPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** order by aggregate values of table "conversations" */
export type Conversations_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Conversations_Max_Order_By>
  min?: Maybe<Conversations_Min_Order_By>
}

/** Boolean expression to filter rows from the table "conversations". All fields are combined with a logical 'AND'. */
export type Conversations_Bool_Exp = {
  _and?: Maybe<Array<Conversations_Bool_Exp>>
  _not?: Maybe<Conversations_Bool_Exp>
  _or?: Maybe<Array<Conversations_Bool_Exp>>
  admin_id?: Maybe<Uuid_Comparison_Exp>
  content?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  direction?: Maybe<String_Comparison_Exp>
  external_id?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  from?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  medium?: Maybe<String_Comparison_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "conversations" */
export type Conversations_Max_Order_By = {
  admin_id?: Maybe<Order_By>
  content?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  direction?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  from?: Maybe<Order_By>
  id?: Maybe<Order_By>
  medium?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "conversations" */
export type Conversations_Min_Order_By = {
  admin_id?: Maybe<Order_By>
  content?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  direction?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  from?: Maybe<Order_By>
  id?: Maybe<Order_By>
  medium?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "conversations". */
export type Conversations_Order_By = {
  admin_id?: Maybe<Order_By>
  content?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  direction?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  from?: Maybe<Order_By>
  id?: Maybe<Order_By>
  medium?: Maybe<Order_By>
  payload?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "conversations" */
export enum Conversations_Select_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Direction = 'direction',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Medium = 'medium',
  /** column name */
  Payload = 'payload',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "credit_reports" */
export type Credit_Reports = {
  __typename: 'credit_reports'
  agency?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  document_id?: Maybe<Scalars['uuid']>
  error_message?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  provider: Scalars['String']
  provider_id: Scalars['String']
  score?: Maybe<Scalars['Int']>
  score_error_message?: Maybe<Scalars['String']>
  score_status?: Maybe<Scalars['String']>
  scoring_model?: Maybe<Scalars['String']>
  status: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** order by aggregate values of table "credit_reports" */
export type Credit_Reports_Aggregate_Order_By = {
  avg?: Maybe<Credit_Reports_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Credit_Reports_Max_Order_By>
  min?: Maybe<Credit_Reports_Min_Order_By>
  stddev?: Maybe<Credit_Reports_Stddev_Order_By>
  stddev_pop?: Maybe<Credit_Reports_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Credit_Reports_Stddev_Samp_Order_By>
  sum?: Maybe<Credit_Reports_Sum_Order_By>
  var_pop?: Maybe<Credit_Reports_Var_Pop_Order_By>
  var_samp?: Maybe<Credit_Reports_Var_Samp_Order_By>
  variance?: Maybe<Credit_Reports_Variance_Order_By>
}

/** order by avg() on columns of table "credit_reports" */
export type Credit_Reports_Avg_Order_By = {
  score?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "credit_reports". All fields are combined with a logical 'AND'. */
export type Credit_Reports_Bool_Exp = {
  _and?: Maybe<Array<Credit_Reports_Bool_Exp>>
  _not?: Maybe<Credit_Reports_Bool_Exp>
  _or?: Maybe<Array<Credit_Reports_Bool_Exp>>
  agency?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  document_id?: Maybe<Uuid_Comparison_Exp>
  error_message?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  provider?: Maybe<String_Comparison_Exp>
  provider_id?: Maybe<String_Comparison_Exp>
  score?: Maybe<Int_Comparison_Exp>
  score_error_message?: Maybe<String_Comparison_Exp>
  score_status?: Maybe<String_Comparison_Exp>
  scoring_model?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "credit_reports" */
export type Credit_Reports_Max_Order_By = {
  agency?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  error_message?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  score?: Maybe<Order_By>
  score_error_message?: Maybe<Order_By>
  score_status?: Maybe<Order_By>
  scoring_model?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "credit_reports" */
export type Credit_Reports_Min_Order_By = {
  agency?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  error_message?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  score?: Maybe<Order_By>
  score_error_message?: Maybe<Order_By>
  score_status?: Maybe<Order_By>
  scoring_model?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "credit_reports". */
export type Credit_Reports_Order_By = {
  agency?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  error_message?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  score?: Maybe<Order_By>
  score_error_message?: Maybe<Order_By>
  score_status?: Maybe<Order_By>
  scoring_model?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "credit_reports" */
export enum Credit_Reports_Select_Column {
  /** column name */
  Agency = 'agency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Score = 'score',
  /** column name */
  ScoreErrorMessage = 'score_error_message',
  /** column name */
  ScoreStatus = 'score_status',
  /** column name */
  ScoringModel = 'scoring_model',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "credit_reports" */
export type Credit_Reports_Stddev_Order_By = {
  score?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "credit_reports" */
export type Credit_Reports_Stddev_Pop_Order_By = {
  score?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "credit_reports" */
export type Credit_Reports_Stddev_Samp_Order_By = {
  score?: Maybe<Order_By>
}

/** order by sum() on columns of table "credit_reports" */
export type Credit_Reports_Sum_Order_By = {
  score?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "credit_reports" */
export type Credit_Reports_Var_Pop_Order_By = {
  score?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "credit_reports" */
export type Credit_Reports_Var_Samp_Order_By = {
  score?: Maybe<Order_By>
}

/** order by variance() on columns of table "credit_reports" */
export type Credit_Reports_Variance_Order_By = {
  score?: Maybe<Order_By>
}

/** columns and relationships of "criminal_reports" */
export type Criminal_Reports = {
  __typename: 'criminal_reports'
  created_at: Scalars['timestamptz']
  document_id?: Maybe<Scalars['uuid']>
  id: Scalars['uuid']
  provider: Scalars['String']
  provider_id: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** order by aggregate values of table "criminal_reports" */
export type Criminal_Reports_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Criminal_Reports_Max_Order_By>
  min?: Maybe<Criminal_Reports_Min_Order_By>
}

/** Boolean expression to filter rows from the table "criminal_reports". All fields are combined with a logical 'AND'. */
export type Criminal_Reports_Bool_Exp = {
  _and?: Maybe<Array<Criminal_Reports_Bool_Exp>>
  _not?: Maybe<Criminal_Reports_Bool_Exp>
  _or?: Maybe<Array<Criminal_Reports_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  document_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  provider?: Maybe<String_Comparison_Exp>
  provider_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "criminal_reports" */
export type Criminal_Reports_Max_Order_By = {
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "criminal_reports" */
export type Criminal_Reports_Min_Order_By = {
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "criminal_reports". */
export type Criminal_Reports_Order_By = {
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "criminal_reports" */
export enum Criminal_Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>
  _gt?: Maybe<Scalars['date']>
  _gte?: Maybe<Scalars['date']>
  _in?: Maybe<Array<Scalars['date']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['date']>
  _lte?: Maybe<Scalars['date']>
  _neq?: Maybe<Scalars['date']>
  _nin?: Maybe<Array<Scalars['date']>>
}

/** columns and relationships of "documents" */
export type Documents = {
  __typename: 'documents'
  /** An object relationship */
  admin?: Maybe<Admins>
  created_at: Scalars['timestamptz']
  /** An array relationship */
  credit_reports: Array<Credit_Reports>
  /** An array relationship */
  criminal_reports: Array<Criminal_Reports>
  /** An array relationship */
  eviction_reports: Array<Eviction_Reports>
  external_id: Scalars['String']
  external_source: Scalars['String']
  friendly_name: Scalars['String']
  id: Scalars['uuid']
  resource_id: Scalars['uuid']
  resource_notes?: Maybe<Scalars['String']>
  resource_type: Scalars['String']
  review_notes?: Maybe<Scalars['String']>
  reviewed_at?: Maybe<Scalars['timestamptz']>
  reviewed_by_admin_id?: Maybe<Scalars['uuid']>
  status: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "documents" */
export type DocumentsCredit_ReportsArgs = {
  distinct_on?: Maybe<Array<Credit_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Credit_Reports_Order_By>>
  where?: Maybe<Credit_Reports_Bool_Exp>
}

/** columns and relationships of "documents" */
export type DocumentsCriminal_ReportsArgs = {
  distinct_on?: Maybe<Array<Criminal_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Criminal_Reports_Order_By>>
  where?: Maybe<Criminal_Reports_Bool_Exp>
}

/** columns and relationships of "documents" */
export type DocumentsEviction_ReportsArgs = {
  distinct_on?: Maybe<Array<Eviction_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Eviction_Reports_Order_By>>
  where?: Maybe<Eviction_Reports_Bool_Exp>
}

/** order by aggregate values of table "documents" */
export type Documents_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Documents_Max_Order_By>
  min?: Maybe<Documents_Min_Order_By>
}

/** input type for inserting array relation for remote table "documents" */
export type Documents_Arr_Rel_Insert_Input = {
  data: Array<Documents_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Documents_On_Conflict>
}

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export type Documents_Bool_Exp = {
  _and?: Maybe<Array<Documents_Bool_Exp>>
  _not?: Maybe<Documents_Bool_Exp>
  _or?: Maybe<Array<Documents_Bool_Exp>>
  admin?: Maybe<Admins_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  credit_reports?: Maybe<Credit_Reports_Bool_Exp>
  criminal_reports?: Maybe<Criminal_Reports_Bool_Exp>
  eviction_reports?: Maybe<Eviction_Reports_Bool_Exp>
  external_id?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  friendly_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_notes?: Maybe<String_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  review_notes?: Maybe<String_Comparison_Exp>
  reviewed_at?: Maybe<Timestamptz_Comparison_Exp>
  reviewed_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "documents" */
export enum Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey',
  /** unique or primary key constraint on columns "resource_type", "resource_id" */
  UniqueDocumentsResourceIdResourceTypeAgreements = 'unique_documents_resource_id_resource_type_agreements'
}

/** input type for inserting data into table "documents" */
export type Documents_Insert_Input = {
  admin?: Maybe<Admins_Obj_Rel_Insert_Input>
  external_id?: Maybe<Scalars['String']>
  external_source?: Maybe<Scalars['String']>
  friendly_name?: Maybe<Scalars['String']>
  resource_id?: Maybe<Scalars['uuid']>
  resource_notes?: Maybe<Scalars['String']>
  resource_type?: Maybe<Scalars['String']>
  review_notes?: Maybe<Scalars['String']>
  reviewed_at?: Maybe<Scalars['timestamptz']>
  reviewed_by_admin_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "documents" */
export type Documents_Max_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "documents" */
export type Documents_Min_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "documents" */
export type Documents_Mutation_Response = {
  __typename: 'documents_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Documents>
}

/** on_conflict condition type for table "documents" */
export type Documents_On_Conflict = {
  constraint: Documents_Constraint
  update_columns?: Array<Documents_Update_Column>
  where?: Maybe<Documents_Bool_Exp>
}

/** Ordering options when selecting data from "documents". */
export type Documents_Order_By = {
  admin?: Maybe<Admins_Order_By>
  created_at?: Maybe<Order_By>
  credit_reports_aggregate?: Maybe<Credit_Reports_Aggregate_Order_By>
  criminal_reports_aggregate?: Maybe<Criminal_Reports_Aggregate_Order_By>
  eviction_reports_aggregate?: Maybe<Eviction_Reports_Aggregate_Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "documents" */
export enum Documents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  FriendlyName = 'friendly_name',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceNotes = 'resource_notes',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  ReviewNotes = 'review_notes',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  ReviewedByAdminId = 'reviewed_by_admin_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** placeholder for update columns of table "documents" (current role has no relevant permissions) */
export enum Documents_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "eviction_reports" */
export type Eviction_Reports = {
  __typename: 'eviction_reports'
  created_at: Scalars['timestamptz']
  document_id?: Maybe<Scalars['uuid']>
  id: Scalars['uuid']
  provider: Scalars['String']
  provider_id: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** order by aggregate values of table "eviction_reports" */
export type Eviction_Reports_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Eviction_Reports_Max_Order_By>
  min?: Maybe<Eviction_Reports_Min_Order_By>
}

/** Boolean expression to filter rows from the table "eviction_reports". All fields are combined with a logical 'AND'. */
export type Eviction_Reports_Bool_Exp = {
  _and?: Maybe<Array<Eviction_Reports_Bool_Exp>>
  _not?: Maybe<Eviction_Reports_Bool_Exp>
  _or?: Maybe<Array<Eviction_Reports_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  document_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  provider?: Maybe<String_Comparison_Exp>
  provider_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "eviction_reports" */
export type Eviction_Reports_Max_Order_By = {
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "eviction_reports" */
export type Eviction_Reports_Min_Order_By = {
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "eviction_reports". */
export type Eviction_Reports_Order_By = {
  created_at?: Maybe<Order_By>
  document_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "eviction_reports" */
export enum Eviction_Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "fund_llcs" */
export type Fund_Llcs = {
  __typename: 'fund_llcs'
  created_at: Scalars['timestamptz']
  end_date?: Maybe<Scalars['date']>
  fund_id: Scalars['uuid']
  id: Scalars['uuid']
  llc_id: Scalars['uuid']
  start_date: Scalars['date']
  updated_at: Scalars['timestamptz']
}

/** Boolean expression to filter rows from the table "fund_llcs". All fields are combined with a logical 'AND'. */
export type Fund_Llcs_Bool_Exp = {
  _and?: Maybe<Array<Fund_Llcs_Bool_Exp>>
  _not?: Maybe<Fund_Llcs_Bool_Exp>
  _or?: Maybe<Array<Fund_Llcs_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  end_date?: Maybe<Date_Comparison_Exp>
  fund_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  llc_id?: Maybe<Uuid_Comparison_Exp>
  start_date?: Maybe<Date_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "fund_llcs". */
export type Fund_Llcs_Order_By = {
  created_at?: Maybe<Order_By>
  end_date?: Maybe<Order_By>
  fund_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  llc_id?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "fund_llcs" */
export enum Fund_Llcs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FundId = 'fund_id',
  /** column name */
  Id = 'id',
  /** column name */
  LlcId = 'llc_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "funds" */
export type Funds = {
  __typename: 'funds'
  abbreviation?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  name: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** Boolean expression to filter rows from the table "funds". All fields are combined with a logical 'AND'. */
export type Funds_Bool_Exp = {
  _and?: Maybe<Array<Funds_Bool_Exp>>
  _not?: Maybe<Funds_Bool_Exp>
  _or?: Maybe<Array<Funds_Bool_Exp>>
  abbreviation?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "funds". */
export type Funds_Order_By = {
  abbreviation?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  name?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "funds" */
export enum Funds_Select_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>
}

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>
  _eq?: Maybe<Scalars['geography']>
  _gt?: Maybe<Scalars['geography']>
  _gte?: Maybe<Scalars['geography']>
  _in?: Maybe<Array<Scalars['geography']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['geography']>
  _lte?: Maybe<Scalars['geography']>
  _neq?: Maybe<Scalars['geography']>
  _nin?: Maybe<Array<Scalars['geography']>>
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>
}

export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>
}

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>
  _eq?: Maybe<Scalars['geometry']>
  _gt?: Maybe<Scalars['geometry']>
  _gte?: Maybe<Scalars['geometry']>
  _in?: Maybe<Array<Scalars['geometry']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['geometry']>
  _lte?: Maybe<Scalars['geometry']>
  _neq?: Maybe<Scalars['geometry']>
  _nin?: Maybe<Array<Scalars['geometry']>>
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>
  _gt?: Maybe<Scalars['json']>
  _gte?: Maybe<Scalars['json']>
  _in?: Maybe<Array<Scalars['json']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['json']>
  _lte?: Maybe<Scalars['json']>
  _neq?: Maybe<Scalars['json']>
  _nin?: Maybe<Array<Scalars['json']>>
}

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>
  _eq?: Maybe<Scalars['jsonb']>
  _gt?: Maybe<Scalars['jsonb']>
  _gte?: Maybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>
  _in?: Maybe<Array<Scalars['jsonb']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['jsonb']>
  _lte?: Maybe<Scalars['jsonb']>
  _neq?: Maybe<Scalars['jsonb']>
  _nin?: Maybe<Array<Scalars['jsonb']>>
}

/** columns and relationships of "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items = {
  __typename: 'kafka_connector_prorated_utility_bill_line_items'
  buildium_csv: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  original_upload_url: Scalars['String']
  rental_liabilities: Scalars['json']
  rental_liabilities_csv: Scalars['String']
  transformations: Scalars['json']
}

/** columns and relationships of "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_ItemsRental_LiabilitiesArgs =
  {
    path?: Maybe<Scalars['String']>
  }

/** columns and relationships of "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_ItemsTransformationsArgs =
  {
    path?: Maybe<Scalars['String']>
  }

/** Boolean expression to filter rows from the table "kafka_connector.prorated_utility_bill_line_items". All fields are combined with a logical 'AND'. */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp = {
  _and?: Maybe<Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp>>
  _not?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp>
  _or?: Maybe<Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp>>
  buildium_csv?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  original_upload_url?: Maybe<String_Comparison_Exp>
  rental_liabilities?: Maybe<Json_Comparison_Exp>
  rental_liabilities_csv?: Maybe<String_Comparison_Exp>
  transformations?: Maybe<Json_Comparison_Exp>
}

/** unique or primary key constraints on table "kafka_connector.prorated_utility_bill_line_items" */
export enum Kafka_Connector_Prorated_Utility_Bill_Line_Items_Constraint {
  /** unique or primary key constraint on columns "original_upload_url" */
  ProratedUtilityBillLineItemsOriginalUploadUrlKey = 'prorated_utility_bill_line_items_original_upload_url_key',
  /** unique or primary key constraint on columns "id" */
  ProratedUtilityBillLineItemsPkey = 'prorated_utility_bill_line_items_pkey'
}

/** input type for inserting data into table "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Insert_Input = {
  buildium_csv?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  original_upload_url?: Maybe<Scalars['String']>
  rental_liabilities?: Maybe<Scalars['json']>
  rental_liabilities_csv?: Maybe<Scalars['String']>
  transformations?: Maybe<Scalars['json']>
}

/** response of any mutation on the table "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Mutation_Response =
  {
    __typename: 'kafka_connector_prorated_utility_bill_line_items_mutation_response'
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']
    /** data from the rows affected by the mutation */
    returning: Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  }

/** on_conflict condition type for table "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_On_Conflict = {
  constraint: Kafka_Connector_Prorated_Utility_Bill_Line_Items_Constraint
  update_columns?: Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Update_Column>
  where?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp>
}

/** Ordering options when selecting data from "kafka_connector.prorated_utility_bill_line_items". */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Order_By = {
  buildium_csv?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  original_upload_url?: Maybe<Order_By>
  rental_liabilities?: Maybe<Order_By>
  rental_liabilities_csv?: Maybe<Order_By>
  transformations?: Maybe<Order_By>
}

/** primary key columns input for table: kafka_connector_prorated_utility_bill_line_items */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Pk_Columns_Input =
  {
    id: Scalars['uuid']
  }

/** select columns of table "kafka_connector.prorated_utility_bill_line_items" */
export enum Kafka_Connector_Prorated_Utility_Bill_Line_Items_Select_Column {
  /** column name */
  BuildiumCsv = 'buildium_csv',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalUploadUrl = 'original_upload_url',
  /** column name */
  RentalLiabilities = 'rental_liabilities',
  /** column name */
  RentalLiabilitiesCsv = 'rental_liabilities_csv',
  /** column name */
  Transformations = 'transformations'
}

/** input type for updating data in table "kafka_connector.prorated_utility_bill_line_items" */
export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Set_Input = {
  buildium_csv?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_upload_url?: Maybe<Scalars['String']>
  rental_liabilities?: Maybe<Scalars['json']>
  transformations?: Maybe<Scalars['json']>
}

/** update columns of table "kafka_connector.prorated_utility_bill_line_items" */
export enum Kafka_Connector_Prorated_Utility_Bill_Line_Items_Update_Column {
  /** column name */
  BuildiumCsv = 'buildium_csv',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalUploadUrl = 'original_upload_url',
  /** column name */
  RentalLiabilities = 'rental_liabilities',
  /** column name */
  Transformations = 'transformations'
}

export type Kafka_Connector_Prorated_Utility_Bill_Line_Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Set_Input>
  where: Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp
}

/** columns and relationships of "lead_group_rental_application_notes" */
export type Lead_Group_Rental_Application_Notes = {
  __typename: 'lead_group_rental_application_notes'
  /** An object relationship */
  admin?: Maybe<Admins>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by_admin_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  is_deleted?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  lead_group?: Maybe<Lead_Groups>
  note?: Maybe<Scalars['String']>
  /** An object relationship */
  rental_application?: Maybe<Rental_Applications>
  resource_id?: Maybe<Scalars['uuid']>
  resource_type?: Maybe<Scalars['String']>
  subject_id?: Maybe<Scalars['uuid']>
  subject_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by aggregate values of table "lead_group_rental_application_notes" */
export type Lead_Group_Rental_Application_Notes_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Lead_Group_Rental_Application_Notes_Max_Order_By>
  min?: Maybe<Lead_Group_Rental_Application_Notes_Min_Order_By>
}

/** Boolean expression to filter rows from the table "lead_group_rental_application_notes". All fields are combined with a logical 'AND'. */
export type Lead_Group_Rental_Application_Notes_Bool_Exp = {
  _and?: Maybe<Array<Lead_Group_Rental_Application_Notes_Bool_Exp>>
  _not?: Maybe<Lead_Group_Rental_Application_Notes_Bool_Exp>
  _or?: Maybe<Array<Lead_Group_Rental_Application_Notes_Bool_Exp>>
  admin?: Maybe<Admins_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  created_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_deleted?: Maybe<Boolean_Comparison_Exp>
  lead_group?: Maybe<Lead_Groups_Bool_Exp>
  note?: Maybe<String_Comparison_Exp>
  rental_application?: Maybe<Rental_Applications_Bool_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  subject_id?: Maybe<Uuid_Comparison_Exp>
  subject_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "lead_group_rental_application_notes" */
export type Lead_Group_Rental_Application_Notes_Max_Order_By = {
  created_at?: Maybe<Order_By>
  created_by_admin_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  subject_id?: Maybe<Order_By>
  subject_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "lead_group_rental_application_notes" */
export type Lead_Group_Rental_Application_Notes_Min_Order_By = {
  created_at?: Maybe<Order_By>
  created_by_admin_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  subject_id?: Maybe<Order_By>
  subject_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "lead_group_rental_application_notes". */
export type Lead_Group_Rental_Application_Notes_Order_By = {
  admin?: Maybe<Admins_Order_By>
  created_at?: Maybe<Order_By>
  created_by_admin_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_deleted?: Maybe<Order_By>
  lead_group?: Maybe<Lead_Groups_Order_By>
  note?: Maybe<Order_By>
  rental_application?: Maybe<Rental_Applications_Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  subject_id?: Maybe<Order_By>
  subject_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "lead_group_rental_application_notes" */
export enum Lead_Group_Rental_Application_Notes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByAdminId = 'created_by_admin_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Note = 'note',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  SubjectId = 'subject_id',
  /** column name */
  SubjectType = 'subject_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "lead_group_users" */
export type Lead_Group_Users = {
  __typename: 'lead_group_users'
  created_at?: Maybe<Scalars['timestamptz']>
  deactivated_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  lead_group: Lead_Groups
  lead_group_id: Scalars['uuid']
  /** An array relationship */
  rental_applications: Array<Rental_Applications>
  type: Scalars['String']
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user: Users
  /** An array relationship */
  user_contact_details: Array<User_Contact_Details>
  user_id: Scalars['uuid']
  /** An object relationship */
  user_searchable?: Maybe<User_Searchable>
}

/** columns and relationships of "lead_group_users" */
export type Lead_Group_UsersRental_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Rental_Applications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Applications_Order_By>>
  where?: Maybe<Rental_Applications_Bool_Exp>
}

/** columns and relationships of "lead_group_users" */
export type Lead_Group_UsersUser_Contact_DetailsArgs = {
  distinct_on?: Maybe<Array<User_Contact_Details_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Contact_Details_Order_By>>
  where?: Maybe<User_Contact_Details_Bool_Exp>
}

/** order by aggregate values of table "lead_group_users" */
export type Lead_Group_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Lead_Group_Users_Max_Order_By>
  min?: Maybe<Lead_Group_Users_Min_Order_By>
}

/** Boolean expression to filter rows from the table "lead_group_users". All fields are combined with a logical 'AND'. */
export type Lead_Group_Users_Bool_Exp = {
  _and?: Maybe<Array<Lead_Group_Users_Bool_Exp>>
  _not?: Maybe<Lead_Group_Users_Bool_Exp>
  _or?: Maybe<Array<Lead_Group_Users_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  lead_group?: Maybe<Lead_Groups_Bool_Exp>
  lead_group_id?: Maybe<Uuid_Comparison_Exp>
  rental_applications?: Maybe<Rental_Applications_Bool_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_contact_details?: Maybe<User_Contact_Details_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
  user_searchable?: Maybe<User_Searchable_Bool_Exp>
}

/** order by max() on columns of table "lead_group_users" */
export type Lead_Group_Users_Max_Order_By = {
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "lead_group_users" */
export type Lead_Group_Users_Min_Order_By = {
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "lead_group_users". */
export type Lead_Group_Users_Order_By = {
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group?: Maybe<Lead_Groups_Order_By>
  lead_group_id?: Maybe<Order_By>
  rental_applications_aggregate?: Maybe<Rental_Applications_Aggregate_Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_contact_details_aggregate?: Maybe<User_Contact_Details_Aggregate_Order_By>
  user_id?: Maybe<Order_By>
  user_searchable?: Maybe<User_Searchable_Order_By>
}

/** select columns of table "lead_group_users" */
export enum Lead_Group_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeadGroupId = 'lead_group_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "lead_groups" */
export type Lead_Groups = {
  __typename: 'lead_groups'
  created_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An array relationship */
  lead_group_users: Array<Lead_Group_Users>
  /** An array relationship */
  rental_application_notes: Array<Lead_Group_Rental_Application_Notes>
  /** An array relationship */
  rental_applications: Array<Rental_Applications>
  status: Scalars['String']
  /** An array relationship */
  tours: Array<Tours>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "lead_groups" */
export type Lead_GroupsLead_Group_UsersArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Users_Order_By>>
  where?: Maybe<Lead_Group_Users_Bool_Exp>
}

/** columns and relationships of "lead_groups" */
export type Lead_GroupsRental_Application_NotesArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Rental_Application_Notes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Rental_Application_Notes_Order_By>>
  where?: Maybe<Lead_Group_Rental_Application_Notes_Bool_Exp>
}

/** columns and relationships of "lead_groups" */
export type Lead_GroupsRental_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Rental_Applications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Applications_Order_By>>
  where?: Maybe<Rental_Applications_Bool_Exp>
}

/** columns and relationships of "lead_groups" */
export type Lead_GroupsToursArgs = {
  distinct_on?: Maybe<Array<Tours_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tours_Order_By>>
  where?: Maybe<Tours_Bool_Exp>
}

/** Boolean expression to filter rows from the table "lead_groups". All fields are combined with a logical 'AND'. */
export type Lead_Groups_Bool_Exp = {
  _and?: Maybe<Array<Lead_Groups_Bool_Exp>>
  _not?: Maybe<Lead_Groups_Bool_Exp>
  _or?: Maybe<Array<Lead_Groups_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  lead_group_users?: Maybe<Lead_Group_Users_Bool_Exp>
  rental_application_notes?: Maybe<Lead_Group_Rental_Application_Notes_Bool_Exp>
  rental_applications?: Maybe<Rental_Applications_Bool_Exp>
  status?: Maybe<String_Comparison_Exp>
  tours?: Maybe<Tours_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "lead_groups". */
export type Lead_Groups_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_users_aggregate?: Maybe<Lead_Group_Users_Aggregate_Order_By>
  rental_application_notes_aggregate?: Maybe<Lead_Group_Rental_Application_Notes_Aggregate_Order_By>
  rental_applications_aggregate?: Maybe<Rental_Applications_Aggregate_Order_By>
  status?: Maybe<Order_By>
  tours_aggregate?: Maybe<Tours_Aggregate_Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "lead_groups" */
export enum Lead_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "ledger_accounts" */
export type Ledger_Accounts = {
  __typename: 'ledger_accounts'
  external_source: Scalars['String']
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  name: Scalars['String']
  parent_ledger_account_id?: Maybe<Scalars['uuid']>
  rental_credit_type?: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "ledger_accounts". All fields are combined with a logical 'AND'. */
export type Ledger_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Ledger_Accounts_Bool_Exp>>
  _not?: Maybe<Ledger_Accounts_Bool_Exp>
  _or?: Maybe<Array<Ledger_Accounts_Bool_Exp>>
  external_source?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  parent_ledger_account_id?: Maybe<Uuid_Comparison_Exp>
  rental_credit_type?: Maybe<String_Comparison_Exp>
}

/** Ordering options when selecting data from "ledger_accounts". */
export type Ledger_Accounts_Order_By = {
  external_source?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_ledger_account_id?: Maybe<Order_By>
  rental_credit_type?: Maybe<Order_By>
}

/** select columns of table "ledger_accounts" */
export enum Ledger_Accounts_Select_Column {
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  ParentLedgerAccountId = 'parent_ledger_account_id',
  /** column name */
  RentalCreditType = 'rental_credit_type'
}

/** columns and relationships of "liability_types" */
export type Liability_Types = {
  __typename: 'liability_types'
  id: Scalars['uuid']
  is_variable: Scalars['Boolean']
  name: Scalars['String']
  payment_category?: Maybe<Scalars['String']>
  /** An array relationship */
  payments: Array<Payments>
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate
  price?: Maybe<Scalars['numeric']>
  recurring: Scalars['Boolean']
  /** An array relationship */
  rental_add_ons: Array<Rental_Add_Ons>
  /** An array relationship */
  rental_liabilities: Array<Rental_Liabilities>
}

/** columns and relationships of "liability_types" */
export type Liability_TypesPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

/** columns and relationships of "liability_types" */
export type Liability_TypesPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

/** columns and relationships of "liability_types" */
export type Liability_TypesRental_Add_OnsArgs = {
  distinct_on?: Maybe<Array<Rental_Add_Ons_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Add_Ons_Order_By>>
  where?: Maybe<Rental_Add_Ons_Bool_Exp>
}

/** columns and relationships of "liability_types" */
export type Liability_TypesRental_LiabilitiesArgs = {
  distinct_on?: Maybe<Array<Rental_Liabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Liabilities_Order_By>>
  where?: Maybe<Rental_Liabilities_Bool_Exp>
}

/** Boolean expression to filter rows from the table "liability_types". All fields are combined with a logical 'AND'. */
export type Liability_Types_Bool_Exp = {
  _and?: Maybe<Array<Liability_Types_Bool_Exp>>
  _not?: Maybe<Liability_Types_Bool_Exp>
  _or?: Maybe<Array<Liability_Types_Bool_Exp>>
  id?: Maybe<Uuid_Comparison_Exp>
  is_variable?: Maybe<Boolean_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  payment_category?: Maybe<String_Comparison_Exp>
  payments?: Maybe<Payments_Bool_Exp>
  price?: Maybe<Numeric_Comparison_Exp>
  recurring?: Maybe<Boolean_Comparison_Exp>
  rental_add_ons?: Maybe<Rental_Add_Ons_Bool_Exp>
  rental_liabilities?: Maybe<Rental_Liabilities_Bool_Exp>
}

/** Ordering options when selecting data from "liability_types". */
export type Liability_Types_Order_By = {
  id?: Maybe<Order_By>
  is_variable?: Maybe<Order_By>
  name?: Maybe<Order_By>
  payment_category?: Maybe<Order_By>
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>
  price?: Maybe<Order_By>
  recurring?: Maybe<Order_By>
  rental_add_ons_aggregate?: Maybe<Rental_Add_Ons_Aggregate_Order_By>
  rental_liabilities_aggregate?: Maybe<Rental_Liabilities_Aggregate_Order_By>
}

/** select columns of table "liability_types" */
export enum Liability_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsVariable = 'is_variable',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentCategory = 'payment_category',
  /** column name */
  Price = 'price',
  /** column name */
  Recurring = 'recurring'
}

/** columns and relationships of "llc_properties" */
export type Llc_Properties = {
  __typename: 'llc_properties'
  end_date?: Maybe<Scalars['date']>
  id: Scalars['uuid']
  /** An object relationship */
  llc: Llcs
  /** An object relationship */
  property: Properties
  start_date: Scalars['date']
}

/** order by aggregate values of table "llc_properties" */
export type Llc_Properties_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Llc_Properties_Max_Order_By>
  min?: Maybe<Llc_Properties_Min_Order_By>
}

/** Boolean expression to filter rows from the table "llc_properties". All fields are combined with a logical 'AND'. */
export type Llc_Properties_Bool_Exp = {
  _and?: Maybe<Array<Llc_Properties_Bool_Exp>>
  _not?: Maybe<Llc_Properties_Bool_Exp>
  _or?: Maybe<Array<Llc_Properties_Bool_Exp>>
  end_date?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  llc?: Maybe<Llcs_Bool_Exp>
  property?: Maybe<Properties_Bool_Exp>
  start_date?: Maybe<Date_Comparison_Exp>
}

/** order by max() on columns of table "llc_properties" */
export type Llc_Properties_Max_Order_By = {
  end_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** order by min() on columns of table "llc_properties" */
export type Llc_Properties_Min_Order_By = {
  end_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** Ordering options when selecting data from "llc_properties". */
export type Llc_Properties_Order_By = {
  end_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  llc?: Maybe<Llcs_Order_By>
  property?: Maybe<Properties_Order_By>
  start_date?: Maybe<Order_By>
}

/** select columns of table "llc_properties" */
export enum Llc_Properties_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date'
}

/** columns and relationships of "llcs" */
export type Llcs = {
  __typename: 'llcs'
  abbreviation?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  name: Scalars['String']
}

/** Boolean expression to filter rows from the table "llcs". All fields are combined with a logical 'AND'. */
export type Llcs_Bool_Exp = {
  _and?: Maybe<Array<Llcs_Bool_Exp>>
  _not?: Maybe<Llcs_Bool_Exp>
  _or?: Maybe<Array<Llcs_Bool_Exp>>
  abbreviation?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
}

/** Ordering options when selecting data from "llcs". */
export type Llcs_Order_By = {
  abbreviation?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  name?: Maybe<Order_By>
}

/** select columns of table "llcs" */
export enum Llcs_Select_Column {
  /** column name */
  Abbreviation = 'abbreviation',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "markets" */
export type Markets = {
  __typename: 'markets'
  created_at: Scalars['timestamptz']
  display_name: Scalars['String']
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  name: Scalars['String']
  short_name?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** Boolean expression to filter rows from the table "markets". All fields are combined with a logical 'AND'. */
export type Markets_Bool_Exp = {
  _and?: Maybe<Array<Markets_Bool_Exp>>
  _not?: Maybe<Markets_Bool_Exp>
  _or?: Maybe<Array<Markets_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  display_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  short_name?: Maybe<String_Comparison_Exp>
  timezone?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "markets". */
export type Markets_Order_By = {
  created_at?: Maybe<Order_By>
  display_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  name?: Maybe<Order_By>
  short_name?: Maybe<Order_By>
  timezone?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "markets" */
export enum Markets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes = {
  __typename: 'mls_listing_internal_attributes'
  analyzed_images?: Maybe<Scalars['jsonb']>
  census_tract_county_code?: Maybe<Scalars['String']>
  census_tract_income_level?: Maybe<Scalars['String']>
  census_tract_median_family_income?: Maybe<Scalars['Float']>
  census_tract_percent_below_poverty_line?: Maybe<Scalars['Float']>
  census_tract_tract_code?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  guidance_price?: Maybe<Scalars['numeric']>
  id: Scalars['uuid']
  internal_status?: Maybe<Scalars['String']>
  is_census_tract_opportunity_zone?: Maybe<Scalars['Boolean']>
  is_in_customer_buy_box: Scalars['Boolean']
  is_in_real_estate_buy_box: Scalars['Boolean']
  is_persisted_in_algolia: Scalars['Boolean']
  /** An object relationship */
  mls_listing: Mls_Listings
  mls_listing_id: Scalars['uuid']
  neighborhood_levels?: Maybe<Scalars['jsonb']>
  percent_over_comp?: Maybe<Scalars['Float']>
  rating_base?: Maybe<Scalars['Float']>
  rent?: Maybe<Scalars['numeric']>
  short_list_images?: Maybe<Scalars['jsonb']>
  tags?: Maybe<Scalars['_varchar']>
  updated_at: Scalars['timestamptz']
  url?: Maybe<Scalars['String']>
  zillow_deep_search_results?: Maybe<Scalars['jsonb']>
}

/** columns and relationships of "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_AttributesAnalyzed_ImagesArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_AttributesNeighborhood_LevelsArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_AttributesShort_List_ImagesArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_AttributesZillow_Deep_Search_ResultsArgs = {
  path?: Maybe<Scalars['String']>
}

/** order by aggregate values of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Aggregate_Order_By = {
  avg?: Maybe<Mls_Listing_Internal_Attributes_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Mls_Listing_Internal_Attributes_Max_Order_By>
  min?: Maybe<Mls_Listing_Internal_Attributes_Min_Order_By>
  stddev?: Maybe<Mls_Listing_Internal_Attributes_Stddev_Order_By>
  stddev_pop?: Maybe<Mls_Listing_Internal_Attributes_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Mls_Listing_Internal_Attributes_Stddev_Samp_Order_By>
  sum?: Maybe<Mls_Listing_Internal_Attributes_Sum_Order_By>
  var_pop?: Maybe<Mls_Listing_Internal_Attributes_Var_Pop_Order_By>
  var_samp?: Maybe<Mls_Listing_Internal_Attributes_Var_Samp_Order_By>
  variance?: Maybe<Mls_Listing_Internal_Attributes_Variance_Order_By>
}

/** order by avg() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Avg_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "mls_listing_internal_attributes". All fields are combined with a logical 'AND'. */
export type Mls_Listing_Internal_Attributes_Bool_Exp = {
  _and?: Maybe<Array<Mls_Listing_Internal_Attributes_Bool_Exp>>
  _not?: Maybe<Mls_Listing_Internal_Attributes_Bool_Exp>
  _or?: Maybe<Array<Mls_Listing_Internal_Attributes_Bool_Exp>>
  analyzed_images?: Maybe<Jsonb_Comparison_Exp>
  census_tract_county_code?: Maybe<String_Comparison_Exp>
  census_tract_income_level?: Maybe<String_Comparison_Exp>
  census_tract_median_family_income?: Maybe<Float_Comparison_Exp>
  census_tract_percent_below_poverty_line?: Maybe<Float_Comparison_Exp>
  census_tract_tract_code?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  guidance_price?: Maybe<Numeric_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  internal_status?: Maybe<String_Comparison_Exp>
  is_census_tract_opportunity_zone?: Maybe<Boolean_Comparison_Exp>
  is_in_customer_buy_box?: Maybe<Boolean_Comparison_Exp>
  is_in_real_estate_buy_box?: Maybe<Boolean_Comparison_Exp>
  is_persisted_in_algolia?: Maybe<Boolean_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  neighborhood_levels?: Maybe<Jsonb_Comparison_Exp>
  percent_over_comp?: Maybe<Float_Comparison_Exp>
  rating_base?: Maybe<Float_Comparison_Exp>
  rent?: Maybe<Numeric_Comparison_Exp>
  short_list_images?: Maybe<Jsonb_Comparison_Exp>
  tags?: Maybe<_Varchar_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
  zillow_deep_search_results?: Maybe<Jsonb_Comparison_Exp>
}

/** order by max() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Max_Order_By = {
  census_tract_county_code?: Maybe<Order_By>
  census_tract_income_level?: Maybe<Order_By>
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  census_tract_tract_code?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  id?: Maybe<Order_By>
  internal_status?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** order by min() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Min_Order_By = {
  census_tract_county_code?: Maybe<Order_By>
  census_tract_income_level?: Maybe<Order_By>
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  census_tract_tract_code?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  id?: Maybe<Order_By>
  internal_status?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** Ordering options when selecting data from "mls_listing_internal_attributes". */
export type Mls_Listing_Internal_Attributes_Order_By = {
  analyzed_images?: Maybe<Order_By>
  census_tract_county_code?: Maybe<Order_By>
  census_tract_income_level?: Maybe<Order_By>
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  census_tract_tract_code?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  id?: Maybe<Order_By>
  internal_status?: Maybe<Order_By>
  is_census_tract_opportunity_zone?: Maybe<Order_By>
  is_in_customer_buy_box?: Maybe<Order_By>
  is_in_real_estate_buy_box?: Maybe<Order_By>
  is_persisted_in_algolia?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  mls_listing_id?: Maybe<Order_By>
  neighborhood_levels?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  short_list_images?: Maybe<Order_By>
  tags?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  zillow_deep_search_results?: Maybe<Order_By>
}

/** select columns of table "mls_listing_internal_attributes" */
export enum Mls_Listing_Internal_Attributes_Select_Column {
  /** column name */
  AnalyzedImages = 'analyzed_images',
  /** column name */
  CensusTractCountyCode = 'census_tract_county_code',
  /** column name */
  CensusTractIncomeLevel = 'census_tract_income_level',
  /** column name */
  CensusTractMedianFamilyIncome = 'census_tract_median_family_income',
  /** column name */
  CensusTractPercentBelowPovertyLine = 'census_tract_percent_below_poverty_line',
  /** column name */
  CensusTractTractCode = 'census_tract_tract_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GuidancePrice = 'guidance_price',
  /** column name */
  Id = 'id',
  /** column name */
  InternalStatus = 'internal_status',
  /** column name */
  IsCensusTractOpportunityZone = 'is_census_tract_opportunity_zone',
  /** column name */
  IsInCustomerBuyBox = 'is_in_customer_buy_box',
  /** column name */
  IsInRealEstateBuyBox = 'is_in_real_estate_buy_box',
  /** column name */
  IsPersistedInAlgolia = 'is_persisted_in_algolia',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  NeighborhoodLevels = 'neighborhood_levels',
  /** column name */
  PercentOverComp = 'percent_over_comp',
  /** column name */
  RatingBase = 'rating_base',
  /** column name */
  Rent = 'rent',
  /** column name */
  ShortListImages = 'short_list_images',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  ZillowDeepSearchResults = 'zillow_deep_search_results'
}

/** order by stddev() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Stddev_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Stddev_Pop_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Stddev_Samp_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by sum() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Sum_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Var_Pop_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Var_Samp_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by variance() on columns of table "mls_listing_internal_attributes" */
export type Mls_Listing_Internal_Attributes_Variance_Order_By = {
  census_tract_median_family_income?: Maybe<Order_By>
  census_tract_percent_below_poverty_line?: Maybe<Order_By>
  guidance_price?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rating_base?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** columns and relationships of "mls_listing_photos" */
export type Mls_Listing_Photos = {
  __typename: 'mls_listing_photos'
  created_at: Scalars['timestamptz']
  display_order?: Maybe<Scalars['Int']>
  id: Scalars['uuid']
  is_deleted?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  mls_listing: Mls_Listings
  mls_listing_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
}

/** order by aggregate values of table "mls_listing_photos" */
export type Mls_Listing_Photos_Aggregate_Order_By = {
  avg?: Maybe<Mls_Listing_Photos_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Mls_Listing_Photos_Max_Order_By>
  min?: Maybe<Mls_Listing_Photos_Min_Order_By>
  stddev?: Maybe<Mls_Listing_Photos_Stddev_Order_By>
  stddev_pop?: Maybe<Mls_Listing_Photos_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Mls_Listing_Photos_Stddev_Samp_Order_By>
  sum?: Maybe<Mls_Listing_Photos_Sum_Order_By>
  var_pop?: Maybe<Mls_Listing_Photos_Var_Pop_Order_By>
  var_samp?: Maybe<Mls_Listing_Photos_Var_Samp_Order_By>
  variance?: Maybe<Mls_Listing_Photos_Variance_Order_By>
}

/** order by avg() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Avg_Order_By = {
  display_order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "mls_listing_photos". All fields are combined with a logical 'AND'. */
export type Mls_Listing_Photos_Bool_Exp = {
  _and?: Maybe<Array<Mls_Listing_Photos_Bool_Exp>>
  _not?: Maybe<Mls_Listing_Photos_Bool_Exp>
  _or?: Maybe<Array<Mls_Listing_Photos_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  display_order?: Maybe<Int_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_deleted?: Maybe<Boolean_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** order by max() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Max_Order_By = {
  created_at?: Maybe<Order_By>
  display_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** order by min() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Min_Order_By = {
  created_at?: Maybe<Order_By>
  display_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** Ordering options when selecting data from "mls_listing_photos". */
export type Mls_Listing_Photos_Order_By = {
  created_at?: Maybe<Order_By>
  display_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_deleted?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  mls_listing_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** select columns of table "mls_listing_photos" */
export enum Mls_Listing_Photos_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** order by stddev() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Stddev_Order_By = {
  display_order?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Stddev_Pop_Order_By = {
  display_order?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Stddev_Samp_Order_By = {
  display_order?: Maybe<Order_By>
}

/** order by sum() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Sum_Order_By = {
  display_order?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Var_Pop_Order_By = {
  display_order?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Var_Samp_Order_By = {
  display_order?: Maybe<Order_By>
}

/** order by variance() on columns of table "mls_listing_photos" */
export type Mls_Listing_Photos_Variance_Order_By = {
  display_order?: Maybe<Order_By>
}

/** columns and relationships of "mls_listing_promotions" */
export type Mls_Listing_Promotions = {
  __typename: 'mls_listing_promotions'
  amount: Scalars['numeric']
  category: Scalars['String']
  display_message: Scalars['String']
  end_date: Scalars['date']
  frequency: Scalars['String']
  id: Scalars['uuid']
  start_date: Scalars['date']
}

/** order by aggregate values of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Aggregate_Order_By = {
  avg?: Maybe<Mls_Listing_Promotions_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Mls_Listing_Promotions_Max_Order_By>
  min?: Maybe<Mls_Listing_Promotions_Min_Order_By>
  stddev?: Maybe<Mls_Listing_Promotions_Stddev_Order_By>
  stddev_pop?: Maybe<Mls_Listing_Promotions_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Mls_Listing_Promotions_Stddev_Samp_Order_By>
  sum?: Maybe<Mls_Listing_Promotions_Sum_Order_By>
  var_pop?: Maybe<Mls_Listing_Promotions_Var_Pop_Order_By>
  var_samp?: Maybe<Mls_Listing_Promotions_Var_Samp_Order_By>
  variance?: Maybe<Mls_Listing_Promotions_Variance_Order_By>
}

/** order by avg() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Avg_Order_By = {
  amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "mls_listing_promotions". All fields are combined with a logical 'AND'. */
export type Mls_Listing_Promotions_Bool_Exp = {
  _and?: Maybe<Array<Mls_Listing_Promotions_Bool_Exp>>
  _not?: Maybe<Mls_Listing_Promotions_Bool_Exp>
  _or?: Maybe<Array<Mls_Listing_Promotions_Bool_Exp>>
  amount?: Maybe<Numeric_Comparison_Exp>
  category?: Maybe<String_Comparison_Exp>
  display_message?: Maybe<String_Comparison_Exp>
  end_date?: Maybe<Date_Comparison_Exp>
  frequency?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  start_date?: Maybe<Date_Comparison_Exp>
}

/** order by max() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Max_Order_By = {
  amount?: Maybe<Order_By>
  category?: Maybe<Order_By>
  display_message?: Maybe<Order_By>
  end_date?: Maybe<Order_By>
  frequency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** order by min() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Min_Order_By = {
  amount?: Maybe<Order_By>
  category?: Maybe<Order_By>
  display_message?: Maybe<Order_By>
  end_date?: Maybe<Order_By>
  frequency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** Ordering options when selecting data from "mls_listing_promotions". */
export type Mls_Listing_Promotions_Order_By = {
  amount?: Maybe<Order_By>
  category?: Maybe<Order_By>
  display_message?: Maybe<Order_By>
  end_date?: Maybe<Order_By>
  frequency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** select columns of table "mls_listing_promotions" */
export enum Mls_Listing_Promotions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Category = 'category',
  /** column name */
  DisplayMessage = 'display_message',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date'
}

/** order by stddev() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Stddev_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by sum() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Sum_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by variance() on columns of table "mls_listing_promotions" */
export type Mls_Listing_Promotions_Variance_Order_By = {
  amount?: Maybe<Order_By>
}

/** columns and relationships of "mls_listings" */
export type Mls_Listings = {
  __typename: 'mls_listings'
  area?: Maybe<Scalars['String']>
  audit_results?: Maybe<Scalars['jsonb']>
  baths_full?: Maybe<Scalars['Float']>
  baths_half?: Maybe<Scalars['Float']>
  baths_total?: Maybe<Scalars['Float']>
  baths_weighted?: Maybe<Scalars['numeric']>
  beds?: Maybe<Scalars['Int']>
  city?: Maybe<Scalars['String']>
  /** An object relationship */
  co_listing_agent?: Maybe<Seller_Agents>
  co_listing_agent_id?: Maybe<Scalars['uuid']>
  co_listing_office_id?: Maybe<Scalars['uuid']>
  county?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  delivery_line?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  display_line_1?: Maybe<Scalars['String']>
  display_line_2?: Maybe<Scalars['String']>
  features_amenities?: Maybe<Scalars['_varchar']>
  features_appliances_and_equipment?: Maybe<Scalars['_varchar']>
  features_bathrooms?: Maybe<Scalars['_varchar']>
  features_bedrooms?: Maybe<Scalars['_varchar']>
  features_community?: Maybe<Scalars['_varchar']>
  features_cooling?: Maybe<Scalars['_varchar']>
  features_dining_room?: Maybe<Scalars['_varchar']>
  features_disclosures?: Maybe<Scalars['_varchar']>
  features_doors?: Maybe<Scalars['_varchar']>
  features_energy_saving?: Maybe<Scalars['_varchar']>
  features_exterior_features?: Maybe<Scalars['_varchar']>
  features_fees?: Maybe<Scalars['_varchar']>
  features_fence?: Maybe<Scalars['_varchar']>
  features_fireplaces?: Maybe<Scalars['_varchar']>
  features_foundation?: Maybe<Scalars['_varchar']>
  features_garage?: Maybe<Scalars['_varchar']>
  features_heating?: Maybe<Scalars['_varchar']>
  features_home_owners_association?: Maybe<Scalars['_varchar']>
  features_house?: Maybe<Scalars['_varchar']>
  features_interior?: Maybe<Scalars['_varchar']>
  features_interior_features?: Maybe<Scalars['_varchar']>
  features_kitchen?: Maybe<Scalars['_varchar']>
  features_laundry?: Maybe<Scalars['_varchar']>
  features_listing?: Maybe<Scalars['_varchar']>
  features_location?: Maybe<Scalars['_varchar']>
  features_lot?: Maybe<Scalars['_varchar']>
  features_miscellaneous?: Maybe<Scalars['_varchar']>
  features_parking?: Maybe<Scalars['_varchar']>
  features_pool?: Maybe<Scalars['_varchar']>
  features_property?: Maybe<Scalars['_varchar']>
  features_property_access?: Maybe<Scalars['_varchar']>
  features_roof?: Maybe<Scalars['_varchar']>
  features_schools?: Maybe<Scalars['_varchar']>
  features_security_system?: Maybe<Scalars['_varchar']>
  features_sewer?: Maybe<Scalars['_varchar']>
  features_spa?: Maybe<Scalars['_varchar']>
  features_taxes?: Maybe<Scalars['_varchar']>
  features_utilities?: Maybe<Scalars['_varchar']>
  features_water?: Maybe<Scalars['_varchar']>
  features_windows_and_doors?: Maybe<Scalars['_varchar']>
  firestore_id: Scalars['uuid']
  geo_type?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  /** An array relationship */
  internal_attributes: Array<Mls_Listing_Internal_Attributes>
  is_audited?: Maybe<Scalars['Boolean']>
  is_new_construction?: Maybe<Scalars['Boolean']>
  last_audited_at?: Maybe<Scalars['timestamptz']>
  last_updated_at?: Maybe<Scalars['timestamptz']>
  lat?: Maybe<Scalars['Float']>
  list_price?: Maybe<Scalars['numeric']>
  /** An object relationship */
  listing_agent?: Maybe<Seller_Agents>
  listing_agent_id?: Maybe<Scalars['uuid']>
  listing_date?: Maybe<Scalars['timestamptz']>
  listing_office_id?: Maybe<Scalars['uuid']>
  listing_type?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['Float']>
  lotsize_acres?: Maybe<Scalars['Float']>
  lotsize_sqft?: Maybe<Scalars['bigint']>
  /** An object relationship */
  market: Markets
  market_id: Scalars['uuid']
  mls: Scalars['String']
  /** An array relationship */
  mls_listing_promotions: Array<Mls_Listing_Promotions>
  modified_at?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  photos: Array<Mls_Listing_Photos>
  property_type?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  provider_id?: Maybe<Scalars['String']>
  /** An array relationship */
  real_estate_acquisitions: Array<Real_Estate_Acquisitions>
  region?: Maybe<Scalars['String']>
  /** An array relationship */
  rent_prices: Array<Rent_Prices>
  /** An array relationship */
  rent_ready_dates: Array<Rent_Ready_Dates>
  /** An array relationship */
  rental_applications: Array<Rental_Applications>
  school_district?: Maybe<Scalars['String']>
  school_elementary?: Maybe<Scalars['String']>
  school_high?: Maybe<Scalars['String']>
  school_middle?: Maybe<Scalars['String']>
  short_id: Scalars['String']
  size?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  std_address?: Maybe<Scalars['jsonb']>
  street?: Maybe<Scalars['String']>
  style?: Maybe<Scalars['String']>
  subdivision?: Maybe<Scalars['String']>
  system_id?: Maybe<Scalars['String']>
  tour_url?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  xf_attributes?: Maybe<Scalars['jsonb']>
  year_built?: Maybe<Scalars['Int']>
  zip?: Maybe<Scalars['String']>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsAudit_ResultsArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsInternal_AttributesArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Internal_Attributes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Internal_Attributes_Order_By>>
  where?: Maybe<Mls_Listing_Internal_Attributes_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsMls_Listing_PromotionsArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Promotions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Promotions_Order_By>>
  where?: Maybe<Mls_Listing_Promotions_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsPhotosArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Photos_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Photos_Order_By>>
  where?: Maybe<Mls_Listing_Photos_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsReal_Estate_AcquisitionsArgs = {
  distinct_on?: Maybe<Array<Real_Estate_Acquisitions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Real_Estate_Acquisitions_Order_By>>
  where?: Maybe<Real_Estate_Acquisitions_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsRent_PricesArgs = {
  distinct_on?: Maybe<Array<Rent_Prices_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rent_Prices_Order_By>>
  where?: Maybe<Rent_Prices_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsRent_Ready_DatesArgs = {
  distinct_on?: Maybe<Array<Rent_Ready_Dates_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rent_Ready_Dates_Order_By>>
  where?: Maybe<Rent_Ready_Dates_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsRental_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Rental_Applications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Applications_Order_By>>
  where?: Maybe<Rental_Applications_Bool_Exp>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsStd_AddressArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "mls_listings" */
export type Mls_ListingsXf_AttributesArgs = {
  path?: Maybe<Scalars['String']>
}

/** order by aggregate values of table "mls_listings" */
export type Mls_Listings_Aggregate_Order_By = {
  avg?: Maybe<Mls_Listings_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Mls_Listings_Max_Order_By>
  min?: Maybe<Mls_Listings_Min_Order_By>
  stddev?: Maybe<Mls_Listings_Stddev_Order_By>
  stddev_pop?: Maybe<Mls_Listings_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Mls_Listings_Stddev_Samp_Order_By>
  sum?: Maybe<Mls_Listings_Sum_Order_By>
  var_pop?: Maybe<Mls_Listings_Var_Pop_Order_By>
  var_samp?: Maybe<Mls_Listings_Var_Samp_Order_By>
  variance?: Maybe<Mls_Listings_Variance_Order_By>
}

/** order by avg() on columns of table "mls_listings" */
export type Mls_Listings_Avg_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "mls_listings". All fields are combined with a logical 'AND'. */
export type Mls_Listings_Bool_Exp = {
  _and?: Maybe<Array<Mls_Listings_Bool_Exp>>
  _not?: Maybe<Mls_Listings_Bool_Exp>
  _or?: Maybe<Array<Mls_Listings_Bool_Exp>>
  area?: Maybe<String_Comparison_Exp>
  audit_results?: Maybe<Jsonb_Comparison_Exp>
  baths_full?: Maybe<Float_Comparison_Exp>
  baths_half?: Maybe<Float_Comparison_Exp>
  baths_total?: Maybe<Float_Comparison_Exp>
  baths_weighted?: Maybe<Numeric_Comparison_Exp>
  beds?: Maybe<Int_Comparison_Exp>
  city?: Maybe<String_Comparison_Exp>
  co_listing_agent?: Maybe<Seller_Agents_Bool_Exp>
  co_listing_agent_id?: Maybe<Uuid_Comparison_Exp>
  co_listing_office_id?: Maybe<Uuid_Comparison_Exp>
  county?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  delivery_line?: Maybe<String_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_line_1?: Maybe<String_Comparison_Exp>
  display_line_2?: Maybe<String_Comparison_Exp>
  features_amenities?: Maybe<_Varchar_Comparison_Exp>
  features_appliances_and_equipment?: Maybe<_Varchar_Comparison_Exp>
  features_bathrooms?: Maybe<_Varchar_Comparison_Exp>
  features_bedrooms?: Maybe<_Varchar_Comparison_Exp>
  features_community?: Maybe<_Varchar_Comparison_Exp>
  features_cooling?: Maybe<_Varchar_Comparison_Exp>
  features_dining_room?: Maybe<_Varchar_Comparison_Exp>
  features_disclosures?: Maybe<_Varchar_Comparison_Exp>
  features_doors?: Maybe<_Varchar_Comparison_Exp>
  features_energy_saving?: Maybe<_Varchar_Comparison_Exp>
  features_exterior_features?: Maybe<_Varchar_Comparison_Exp>
  features_fees?: Maybe<_Varchar_Comparison_Exp>
  features_fence?: Maybe<_Varchar_Comparison_Exp>
  features_fireplaces?: Maybe<_Varchar_Comparison_Exp>
  features_foundation?: Maybe<_Varchar_Comparison_Exp>
  features_garage?: Maybe<_Varchar_Comparison_Exp>
  features_heating?: Maybe<_Varchar_Comparison_Exp>
  features_home_owners_association?: Maybe<_Varchar_Comparison_Exp>
  features_house?: Maybe<_Varchar_Comparison_Exp>
  features_interior?: Maybe<_Varchar_Comparison_Exp>
  features_interior_features?: Maybe<_Varchar_Comparison_Exp>
  features_kitchen?: Maybe<_Varchar_Comparison_Exp>
  features_laundry?: Maybe<_Varchar_Comparison_Exp>
  features_listing?: Maybe<_Varchar_Comparison_Exp>
  features_location?: Maybe<_Varchar_Comparison_Exp>
  features_lot?: Maybe<_Varchar_Comparison_Exp>
  features_miscellaneous?: Maybe<_Varchar_Comparison_Exp>
  features_parking?: Maybe<_Varchar_Comparison_Exp>
  features_pool?: Maybe<_Varchar_Comparison_Exp>
  features_property?: Maybe<_Varchar_Comparison_Exp>
  features_property_access?: Maybe<_Varchar_Comparison_Exp>
  features_roof?: Maybe<_Varchar_Comparison_Exp>
  features_schools?: Maybe<_Varchar_Comparison_Exp>
  features_security_system?: Maybe<_Varchar_Comparison_Exp>
  features_sewer?: Maybe<_Varchar_Comparison_Exp>
  features_spa?: Maybe<_Varchar_Comparison_Exp>
  features_taxes?: Maybe<_Varchar_Comparison_Exp>
  features_utilities?: Maybe<_Varchar_Comparison_Exp>
  features_water?: Maybe<_Varchar_Comparison_Exp>
  features_windows_and_doors?: Maybe<_Varchar_Comparison_Exp>
  firestore_id?: Maybe<Uuid_Comparison_Exp>
  geo_type?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  internal_attributes?: Maybe<Mls_Listing_Internal_Attributes_Bool_Exp>
  is_audited?: Maybe<Boolean_Comparison_Exp>
  is_new_construction?: Maybe<Boolean_Comparison_Exp>
  last_audited_at?: Maybe<Timestamptz_Comparison_Exp>
  last_updated_at?: Maybe<Timestamptz_Comparison_Exp>
  lat?: Maybe<Float_Comparison_Exp>
  list_price?: Maybe<Numeric_Comparison_Exp>
  listing_agent?: Maybe<Seller_Agents_Bool_Exp>
  listing_agent_id?: Maybe<Uuid_Comparison_Exp>
  listing_date?: Maybe<Timestamptz_Comparison_Exp>
  listing_office_id?: Maybe<Uuid_Comparison_Exp>
  listing_type?: Maybe<String_Comparison_Exp>
  lng?: Maybe<Float_Comparison_Exp>
  lotsize_acres?: Maybe<Float_Comparison_Exp>
  lotsize_sqft?: Maybe<Bigint_Comparison_Exp>
  market?: Maybe<Markets_Bool_Exp>
  market_id?: Maybe<Uuid_Comparison_Exp>
  mls?: Maybe<String_Comparison_Exp>
  mls_listing_promotions?: Maybe<Mls_Listing_Promotions_Bool_Exp>
  modified_at?: Maybe<Timestamptz_Comparison_Exp>
  photos?: Maybe<Mls_Listing_Photos_Bool_Exp>
  property_type?: Maybe<String_Comparison_Exp>
  provider?: Maybe<String_Comparison_Exp>
  provider_id?: Maybe<String_Comparison_Exp>
  real_estate_acquisitions?: Maybe<Real_Estate_Acquisitions_Bool_Exp>
  region?: Maybe<String_Comparison_Exp>
  rent_prices?: Maybe<Rent_Prices_Bool_Exp>
  rent_ready_dates?: Maybe<Rent_Ready_Dates_Bool_Exp>
  rental_applications?: Maybe<Rental_Applications_Bool_Exp>
  school_district?: Maybe<String_Comparison_Exp>
  school_elementary?: Maybe<String_Comparison_Exp>
  school_high?: Maybe<String_Comparison_Exp>
  school_middle?: Maybe<String_Comparison_Exp>
  short_id?: Maybe<String_Comparison_Exp>
  size?: Maybe<String_Comparison_Exp>
  state?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  std_address?: Maybe<Jsonb_Comparison_Exp>
  street?: Maybe<String_Comparison_Exp>
  style?: Maybe<String_Comparison_Exp>
  subdivision?: Maybe<String_Comparison_Exp>
  system_id?: Maybe<String_Comparison_Exp>
  tour_url?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  xf_attributes?: Maybe<Jsonb_Comparison_Exp>
  year_built?: Maybe<Int_Comparison_Exp>
  zip?: Maybe<String_Comparison_Exp>
}

/** order by max() on columns of table "mls_listings" */
export type Mls_Listings_Max_Order_By = {
  area?: Maybe<Order_By>
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  city?: Maybe<Order_By>
  co_listing_agent_id?: Maybe<Order_By>
  co_listing_office_id?: Maybe<Order_By>
  county?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  delivery_line?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_line_1?: Maybe<Order_By>
  display_line_2?: Maybe<Order_By>
  firestore_id?: Maybe<Order_By>
  geo_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_audited_at?: Maybe<Order_By>
  last_updated_at?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  listing_agent_id?: Maybe<Order_By>
  listing_date?: Maybe<Order_By>
  listing_office_id?: Maybe<Order_By>
  listing_type?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  market_id?: Maybe<Order_By>
  mls?: Maybe<Order_By>
  modified_at?: Maybe<Order_By>
  property_type?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  region?: Maybe<Order_By>
  school_district?: Maybe<Order_By>
  school_elementary?: Maybe<Order_By>
  school_high?: Maybe<Order_By>
  school_middle?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  size?: Maybe<Order_By>
  state?: Maybe<Order_By>
  status?: Maybe<Order_By>
  street?: Maybe<Order_By>
  style?: Maybe<Order_By>
  subdivision?: Maybe<Order_By>
  system_id?: Maybe<Order_By>
  tour_url?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
  zip?: Maybe<Order_By>
}

/** order by min() on columns of table "mls_listings" */
export type Mls_Listings_Min_Order_By = {
  area?: Maybe<Order_By>
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  city?: Maybe<Order_By>
  co_listing_agent_id?: Maybe<Order_By>
  co_listing_office_id?: Maybe<Order_By>
  county?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  delivery_line?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_line_1?: Maybe<Order_By>
  display_line_2?: Maybe<Order_By>
  firestore_id?: Maybe<Order_By>
  geo_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_audited_at?: Maybe<Order_By>
  last_updated_at?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  listing_agent_id?: Maybe<Order_By>
  listing_date?: Maybe<Order_By>
  listing_office_id?: Maybe<Order_By>
  listing_type?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  market_id?: Maybe<Order_By>
  mls?: Maybe<Order_By>
  modified_at?: Maybe<Order_By>
  property_type?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  region?: Maybe<Order_By>
  school_district?: Maybe<Order_By>
  school_elementary?: Maybe<Order_By>
  school_high?: Maybe<Order_By>
  school_middle?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  size?: Maybe<Order_By>
  state?: Maybe<Order_By>
  status?: Maybe<Order_By>
  street?: Maybe<Order_By>
  style?: Maybe<Order_By>
  subdivision?: Maybe<Order_By>
  system_id?: Maybe<Order_By>
  tour_url?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
  zip?: Maybe<Order_By>
}

/** Ordering options when selecting data from "mls_listings". */
export type Mls_Listings_Order_By = {
  area?: Maybe<Order_By>
  audit_results?: Maybe<Order_By>
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  city?: Maybe<Order_By>
  co_listing_agent?: Maybe<Seller_Agents_Order_By>
  co_listing_agent_id?: Maybe<Order_By>
  co_listing_office_id?: Maybe<Order_By>
  county?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  delivery_line?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_line_1?: Maybe<Order_By>
  display_line_2?: Maybe<Order_By>
  features_amenities?: Maybe<Order_By>
  features_appliances_and_equipment?: Maybe<Order_By>
  features_bathrooms?: Maybe<Order_By>
  features_bedrooms?: Maybe<Order_By>
  features_community?: Maybe<Order_By>
  features_cooling?: Maybe<Order_By>
  features_dining_room?: Maybe<Order_By>
  features_disclosures?: Maybe<Order_By>
  features_doors?: Maybe<Order_By>
  features_energy_saving?: Maybe<Order_By>
  features_exterior_features?: Maybe<Order_By>
  features_fees?: Maybe<Order_By>
  features_fence?: Maybe<Order_By>
  features_fireplaces?: Maybe<Order_By>
  features_foundation?: Maybe<Order_By>
  features_garage?: Maybe<Order_By>
  features_heating?: Maybe<Order_By>
  features_home_owners_association?: Maybe<Order_By>
  features_house?: Maybe<Order_By>
  features_interior?: Maybe<Order_By>
  features_interior_features?: Maybe<Order_By>
  features_kitchen?: Maybe<Order_By>
  features_laundry?: Maybe<Order_By>
  features_listing?: Maybe<Order_By>
  features_location?: Maybe<Order_By>
  features_lot?: Maybe<Order_By>
  features_miscellaneous?: Maybe<Order_By>
  features_parking?: Maybe<Order_By>
  features_pool?: Maybe<Order_By>
  features_property?: Maybe<Order_By>
  features_property_access?: Maybe<Order_By>
  features_roof?: Maybe<Order_By>
  features_schools?: Maybe<Order_By>
  features_security_system?: Maybe<Order_By>
  features_sewer?: Maybe<Order_By>
  features_spa?: Maybe<Order_By>
  features_taxes?: Maybe<Order_By>
  features_utilities?: Maybe<Order_By>
  features_water?: Maybe<Order_By>
  features_windows_and_doors?: Maybe<Order_By>
  firestore_id?: Maybe<Order_By>
  geo_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  internal_attributes_aggregate?: Maybe<Mls_Listing_Internal_Attributes_Aggregate_Order_By>
  is_audited?: Maybe<Order_By>
  is_new_construction?: Maybe<Order_By>
  last_audited_at?: Maybe<Order_By>
  last_updated_at?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  listing_agent?: Maybe<Seller_Agents_Order_By>
  listing_agent_id?: Maybe<Order_By>
  listing_date?: Maybe<Order_By>
  listing_office_id?: Maybe<Order_By>
  listing_type?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  market?: Maybe<Markets_Order_By>
  market_id?: Maybe<Order_By>
  mls?: Maybe<Order_By>
  mls_listing_promotions_aggregate?: Maybe<Mls_Listing_Promotions_Aggregate_Order_By>
  modified_at?: Maybe<Order_By>
  photos_aggregate?: Maybe<Mls_Listing_Photos_Aggregate_Order_By>
  property_type?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  provider_id?: Maybe<Order_By>
  real_estate_acquisitions_aggregate?: Maybe<Real_Estate_Acquisitions_Aggregate_Order_By>
  region?: Maybe<Order_By>
  rent_prices_aggregate?: Maybe<Rent_Prices_Aggregate_Order_By>
  rent_ready_dates_aggregate?: Maybe<Rent_Ready_Dates_Aggregate_Order_By>
  rental_applications_aggregate?: Maybe<Rental_Applications_Aggregate_Order_By>
  school_district?: Maybe<Order_By>
  school_elementary?: Maybe<Order_By>
  school_high?: Maybe<Order_By>
  school_middle?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  size?: Maybe<Order_By>
  state?: Maybe<Order_By>
  status?: Maybe<Order_By>
  std_address?: Maybe<Order_By>
  street?: Maybe<Order_By>
  style?: Maybe<Order_By>
  subdivision?: Maybe<Order_By>
  system_id?: Maybe<Order_By>
  tour_url?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  xf_attributes?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
  zip?: Maybe<Order_By>
}

/** select columns of table "mls_listings" */
export enum Mls_Listings_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  AuditResults = 'audit_results',
  /** column name */
  BathsFull = 'baths_full',
  /** column name */
  BathsHalf = 'baths_half',
  /** column name */
  BathsTotal = 'baths_total',
  /** column name */
  BathsWeighted = 'baths_weighted',
  /** column name */
  Beds = 'beds',
  /** column name */
  City = 'city',
  /** column name */
  CoListingAgentId = 'co_listing_agent_id',
  /** column name */
  CoListingOfficeId = 'co_listing_office_id',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryLine = 'delivery_line',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayLine_1 = 'display_line_1',
  /** column name */
  DisplayLine_2 = 'display_line_2',
  /** column name */
  FeaturesAmenities = 'features_amenities',
  /** column name */
  FeaturesAppliancesAndEquipment = 'features_appliances_and_equipment',
  /** column name */
  FeaturesBathrooms = 'features_bathrooms',
  /** column name */
  FeaturesBedrooms = 'features_bedrooms',
  /** column name */
  FeaturesCommunity = 'features_community',
  /** column name */
  FeaturesCooling = 'features_cooling',
  /** column name */
  FeaturesDiningRoom = 'features_dining_room',
  /** column name */
  FeaturesDisclosures = 'features_disclosures',
  /** column name */
  FeaturesDoors = 'features_doors',
  /** column name */
  FeaturesEnergySaving = 'features_energy_saving',
  /** column name */
  FeaturesExteriorFeatures = 'features_exterior_features',
  /** column name */
  FeaturesFees = 'features_fees',
  /** column name */
  FeaturesFence = 'features_fence',
  /** column name */
  FeaturesFireplaces = 'features_fireplaces',
  /** column name */
  FeaturesFoundation = 'features_foundation',
  /** column name */
  FeaturesGarage = 'features_garage',
  /** column name */
  FeaturesHeating = 'features_heating',
  /** column name */
  FeaturesHomeOwnersAssociation = 'features_home_owners_association',
  /** column name */
  FeaturesHouse = 'features_house',
  /** column name */
  FeaturesInterior = 'features_interior',
  /** column name */
  FeaturesInteriorFeatures = 'features_interior_features',
  /** column name */
  FeaturesKitchen = 'features_kitchen',
  /** column name */
  FeaturesLaundry = 'features_laundry',
  /** column name */
  FeaturesListing = 'features_listing',
  /** column name */
  FeaturesLocation = 'features_location',
  /** column name */
  FeaturesLot = 'features_lot',
  /** column name */
  FeaturesMiscellaneous = 'features_miscellaneous',
  /** column name */
  FeaturesParking = 'features_parking',
  /** column name */
  FeaturesPool = 'features_pool',
  /** column name */
  FeaturesProperty = 'features_property',
  /** column name */
  FeaturesPropertyAccess = 'features_property_access',
  /** column name */
  FeaturesRoof = 'features_roof',
  /** column name */
  FeaturesSchools = 'features_schools',
  /** column name */
  FeaturesSecuritySystem = 'features_security_system',
  /** column name */
  FeaturesSewer = 'features_sewer',
  /** column name */
  FeaturesSpa = 'features_spa',
  /** column name */
  FeaturesTaxes = 'features_taxes',
  /** column name */
  FeaturesUtilities = 'features_utilities',
  /** column name */
  FeaturesWater = 'features_water',
  /** column name */
  FeaturesWindowsAndDoors = 'features_windows_and_doors',
  /** column name */
  FirestoreId = 'firestore_id',
  /** column name */
  GeoType = 'geo_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsAudited = 'is_audited',
  /** column name */
  IsNewConstruction = 'is_new_construction',
  /** column name */
  LastAuditedAt = 'last_audited_at',
  /** column name */
  LastUpdatedAt = 'last_updated_at',
  /** column name */
  Lat = 'lat',
  /** column name */
  ListPrice = 'list_price',
  /** column name */
  ListingAgentId = 'listing_agent_id',
  /** column name */
  ListingDate = 'listing_date',
  /** column name */
  ListingOfficeId = 'listing_office_id',
  /** column name */
  ListingType = 'listing_type',
  /** column name */
  Lng = 'lng',
  /** column name */
  LotsizeAcres = 'lotsize_acres',
  /** column name */
  LotsizeSqft = 'lotsize_sqft',
  /** column name */
  MarketId = 'market_id',
  /** column name */
  Mls = 'mls',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  PropertyType = 'property_type',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Region = 'region',
  /** column name */
  SchoolDistrict = 'school_district',
  /** column name */
  SchoolElementary = 'school_elementary',
  /** column name */
  SchoolHigh = 'school_high',
  /** column name */
  SchoolMiddle = 'school_middle',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  Size = 'size',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  StdAddress = 'std_address',
  /** column name */
  Street = 'street',
  /** column name */
  Style = 'style',
  /** column name */
  Subdivision = 'subdivision',
  /** column name */
  SystemId = 'system_id',
  /** column name */
  TourUrl = 'tour_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  XfAttributes = 'xf_attributes',
  /** column name */
  YearBuilt = 'year_built',
  /** column name */
  Zip = 'zip'
}

/** order by stddev() on columns of table "mls_listings" */
export type Mls_Listings_Stddev_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "mls_listings" */
export type Mls_Listings_Stddev_Pop_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "mls_listings" */
export type Mls_Listings_Stddev_Samp_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** order by sum() on columns of table "mls_listings" */
export type Mls_Listings_Sum_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "mls_listings" */
export type Mls_Listings_Var_Pop_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "mls_listings" */
export type Mls_Listings_Var_Samp_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** order by variance() on columns of table "mls_listings" */
export type Mls_Listings_Variance_Order_By = {
  baths_full?: Maybe<Order_By>
  baths_half?: Maybe<Order_By>
  baths_total?: Maybe<Order_By>
  baths_weighted?: Maybe<Order_By>
  beds?: Maybe<Order_By>
  lat?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  lng?: Maybe<Order_By>
  lotsize_acres?: Maybe<Order_By>
  lotsize_sqft?: Maybe<Order_By>
  year_built?: Maybe<Order_By>
}

/** mutation root */
export type Mutation_Root = {
  __typename: 'mutation_root'
  /** delete data from the table: "admin_capabilities" */
  delete_admin_capabilities?: Maybe<Admin_Capabilities_Mutation_Response>
  /** delete single row from the table: "admin_capabilities" */
  delete_admin_capabilities_by_pk?: Maybe<Admin_Capabilities>
  /** insert data into the table: "admin_capabilities" */
  insert_admin_capabilities?: Maybe<Admin_Capabilities_Mutation_Response>
  /** insert a single row into the table: "admin_capabilities" */
  insert_admin_capabilities_one?: Maybe<Admin_Capabilities>
  /** insert data into the table: "admins" */
  insert_admins?: Maybe<Admins_Mutation_Response>
  /** insert a single row into the table: "admins" */
  insert_admins_one?: Maybe<Admins>
  /** insert data into the table: "capabilities" */
  insert_capabilities?: Maybe<Capabilities_Mutation_Response>
  /** insert a single row into the table: "capabilities" */
  insert_capabilities_one?: Maybe<Capabilities>
  /** insert data into the table: "documents" */
  insert_documents?: Maybe<Documents_Mutation_Response>
  /** insert a single row into the table: "documents" */
  insert_documents_one?: Maybe<Documents>
  /** insert data into the table: "kafka_connector.prorated_utility_bill_line_items" */
  insert_kafka_connector_prorated_utility_bill_line_items?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Mutation_Response>
  /** insert a single row into the table: "kafka_connector.prorated_utility_bill_line_items" */
  insert_kafka_connector_prorated_utility_bill_line_items_one?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  /** insert data into the table: "notes" */
  insert_notes?: Maybe<Notes_Mutation_Response>
  /** insert a single row into the table: "notes" */
  insert_notes_one?: Maybe<Notes>
  /** insert data into the table: "payments" */
  insert_payments?: Maybe<Payments_Mutation_Response>
  /** insert a single row into the table: "payments" */
  insert_payments_one?: Maybe<Payments>
  /** insert data into the table: "rental_add_ons" */
  insert_rental_add_ons?: Maybe<Rental_Add_Ons_Mutation_Response>
  /** insert a single row into the table: "rental_add_ons" */
  insert_rental_add_ons_one?: Maybe<Rental_Add_Ons>
  /** insert data into the table: "rental_agreement_histories" */
  insert_rental_agreement_histories?: Maybe<Rental_Agreement_Histories_Mutation_Response>
  /** insert a single row into the table: "rental_agreement_histories" */
  insert_rental_agreement_histories_one?: Maybe<Rental_Agreement_Histories>
  /** insert data into the table: "rental_credits" */
  insert_rental_credits?: Maybe<Rental_Credits_Mutation_Response>
  /** insert a single row into the table: "rental_credits" */
  insert_rental_credits_one?: Maybe<Rental_Credits>
  /** insert data into the table: "rental_liabilities" */
  insert_rental_liabilities?: Maybe<Rental_Liabilities_Mutation_Response>
  /** insert a single row into the table: "rental_liabilities" */
  insert_rental_liabilities_one?: Maybe<Rental_Liabilities>
  /** update data of the table: "kafka_connector.prorated_utility_bill_line_items" */
  update_kafka_connector_prorated_utility_bill_line_items?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Mutation_Response>
  /** update single row of the table: "kafka_connector.prorated_utility_bill_line_items" */
  update_kafka_connector_prorated_utility_bill_line_items_by_pk?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  /** update multiples rows of table: "kafka_connector.prorated_utility_bill_line_items" */
  update_kafka_connector_prorated_utility_bill_line_items_many?: Maybe<
    Array<
      Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Mutation_Response>
    >
  >
  /** update data of the table: "rental_add_ons" */
  update_rental_add_ons?: Maybe<Rental_Add_Ons_Mutation_Response>
  /** update single row of the table: "rental_add_ons" */
  update_rental_add_ons_by_pk?: Maybe<Rental_Add_Ons>
  /** update multiples rows of table: "rental_add_ons" */
  update_rental_add_ons_many?: Maybe<
    Array<Maybe<Rental_Add_Ons_Mutation_Response>>
  >
  /** update data of the table: "rental_agreement_histories" */
  update_rental_agreement_histories?: Maybe<Rental_Agreement_Histories_Mutation_Response>
  /** update single row of the table: "rental_agreement_histories" */
  update_rental_agreement_histories_by_pk?: Maybe<Rental_Agreement_Histories>
  /** update multiples rows of table: "rental_agreement_histories" */
  update_rental_agreement_histories_many?: Maybe<
    Array<Maybe<Rental_Agreement_Histories_Mutation_Response>>
  >
  /** update data of the table: "rentals" */
  update_rentals?: Maybe<Rentals_Mutation_Response>
  /** update single row of the table: "rentals" */
  update_rentals_by_pk?: Maybe<Rentals>
  /** update multiples rows of table: "rentals" */
  update_rentals_many?: Maybe<Array<Maybe<Rentals_Mutation_Response>>>
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootDelete_Admin_CapabilitiesArgs = {
  where: Admin_Capabilities_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Admin_Capabilities_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootInsert_Admin_CapabilitiesArgs = {
  objects: Array<Admin_Capabilities_Insert_Input>
  on_conflict?: Maybe<Admin_Capabilities_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Admin_Capabilities_OneArgs = {
  object: Admin_Capabilities_Insert_Input
  on_conflict?: Maybe<Admin_Capabilities_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_AdminsArgs = {
  objects: Array<Admins_Insert_Input>
  on_conflict?: Maybe<Admins_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Admins_OneArgs = {
  object: Admins_Insert_Input
  on_conflict?: Maybe<Admins_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CapabilitiesArgs = {
  objects: Array<Capabilities_Insert_Input>
  on_conflict?: Maybe<Capabilities_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Capabilities_OneArgs = {
  object: Capabilities_Insert_Input
  on_conflict?: Maybe<Capabilities_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DocumentsArgs = {
  objects: Array<Documents_Insert_Input>
  on_conflict?: Maybe<Documents_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Documents_OneArgs = {
  object: Documents_Insert_Input
  on_conflict?: Maybe<Documents_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Kafka_Connector_Prorated_Utility_Bill_Line_ItemsArgs =
  {
    objects: Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Insert_Input>
    on_conflict?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Kafka_Connector_Prorated_Utility_Bill_Line_Items_OneArgs =
  {
    object: Kafka_Connector_Prorated_Utility_Bill_Line_Items_Insert_Input
    on_conflict?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_NotesArgs = {
  objects: Array<Notes_Insert_Input>
  on_conflict?: Maybe<Notes_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notes_OneArgs = {
  object: Notes_Insert_Input
  on_conflict?: Maybe<Notes_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PaymentsArgs = {
  objects: Array<Payments_Insert_Input>
  on_conflict?: Maybe<Payments_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payments_OneArgs = {
  object: Payments_Insert_Input
  on_conflict?: Maybe<Payments_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_Add_OnsArgs = {
  objects: Array<Rental_Add_Ons_Insert_Input>
  on_conflict?: Maybe<Rental_Add_Ons_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_Add_Ons_OneArgs = {
  object: Rental_Add_Ons_Insert_Input
  on_conflict?: Maybe<Rental_Add_Ons_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_Agreement_HistoriesArgs = {
  objects: Array<Rental_Agreement_Histories_Insert_Input>
  on_conflict?: Maybe<Rental_Agreement_Histories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_Agreement_Histories_OneArgs = {
  object: Rental_Agreement_Histories_Insert_Input
  on_conflict?: Maybe<Rental_Agreement_Histories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_CreditsArgs = {
  objects: Array<Rental_Credits_Insert_Input>
  on_conflict?: Maybe<Rental_Credits_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_Credits_OneArgs = {
  object: Rental_Credits_Insert_Input
  on_conflict?: Maybe<Rental_Credits_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_LiabilitiesArgs = {
  objects: Array<Rental_Liabilities_Insert_Input>
  on_conflict?: Maybe<Rental_Liabilities_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rental_Liabilities_OneArgs = {
  object: Rental_Liabilities_Insert_Input
  on_conflict?: Maybe<Rental_Liabilities_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_Kafka_Connector_Prorated_Utility_Bill_Line_ItemsArgs =
  {
    _set?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Set_Input>
    where: Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp
  }

/** mutation root */
export type Mutation_RootUpdate_Kafka_Connector_Prorated_Utility_Bill_Line_Items_By_PkArgs =
  {
    _set?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Set_Input>
    pk_columns: Kafka_Connector_Prorated_Utility_Bill_Line_Items_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Kafka_Connector_Prorated_Utility_Bill_Line_Items_ManyArgs =
  {
    updates: Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Rental_Add_OnsArgs = {
  _set?: Maybe<Rental_Add_Ons_Set_Input>
  where: Rental_Add_Ons_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rental_Add_Ons_By_PkArgs = {
  _set?: Maybe<Rental_Add_Ons_Set_Input>
  pk_columns: Rental_Add_Ons_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rental_Add_Ons_ManyArgs = {
  updates: Array<Rental_Add_Ons_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Rental_Agreement_HistoriesArgs = {
  _set?: Maybe<Rental_Agreement_Histories_Set_Input>
  where: Rental_Agreement_Histories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rental_Agreement_Histories_By_PkArgs = {
  _set?: Maybe<Rental_Agreement_Histories_Set_Input>
  pk_columns: Rental_Agreement_Histories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rental_Agreement_Histories_ManyArgs = {
  updates: Array<Rental_Agreement_Histories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_RentalsArgs = {
  _set?: Maybe<Rentals_Set_Input>
  where: Rentals_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rentals_By_PkArgs = {
  _set?: Maybe<Rentals_Set_Input>
  pk_columns: Rentals_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rentals_ManyArgs = {
  updates: Array<Rentals_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: Maybe<Users_Append_Input>
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>
  _delete_elem?: Maybe<Users_Delete_Elem_Input>
  _delete_key?: Maybe<Users_Delete_Key_Input>
  _prepend?: Maybe<Users_Prepend_Input>
  _set?: Maybe<Users_Set_Input>
  where: Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: Maybe<Users_Append_Input>
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>
  _delete_elem?: Maybe<Users_Delete_Elem_Input>
  _delete_key?: Maybe<Users_Delete_Key_Input>
  _prepend?: Maybe<Users_Prepend_Input>
  _set?: Maybe<Users_Set_Input>
  pk_columns: Users_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>
}

/** columns and relationships of "notes" */
export type Notes = {
  __typename: 'notes'
  /** An object relationship */
  admin: Admins
  category?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  created_by_admin_id: Scalars['uuid']
  /** An array relationship */
  documents: Array<Documents>
  id: Scalars['uuid']
  is_deleted: Scalars['Boolean']
  note: Scalars['String']
  resource_id: Scalars['uuid']
  resource_type: Scalars['String']
  subject_id?: Maybe<Scalars['uuid']>
  subject_type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "notes" */
export type NotesDocumentsArgs = {
  distinct_on?: Maybe<Array<Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Documents_Order_By>>
  where?: Maybe<Documents_Bool_Exp>
}

/** order by aggregate values of table "notes" */
export type Notes_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Notes_Max_Order_By>
  min?: Maybe<Notes_Min_Order_By>
}

/** input type for inserting array relation for remote table "notes" */
export type Notes_Arr_Rel_Insert_Input = {
  data: Array<Notes_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notes_On_Conflict>
}

/** Boolean expression to filter rows from the table "notes". All fields are combined with a logical 'AND'. */
export type Notes_Bool_Exp = {
  _and?: Maybe<Array<Notes_Bool_Exp>>
  _not?: Maybe<Notes_Bool_Exp>
  _or?: Maybe<Array<Notes_Bool_Exp>>
  admin?: Maybe<Admins_Bool_Exp>
  category?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  created_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  documents?: Maybe<Documents_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_deleted?: Maybe<Boolean_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  subject_id?: Maybe<Uuid_Comparison_Exp>
  subject_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "notes" */
export enum Notes_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotesPkey = 'notes_pkey'
}

/** input type for inserting data into table "notes" */
export type Notes_Insert_Input = {
  admin?: Maybe<Admins_Obj_Rel_Insert_Input>
  category?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by_admin_id?: Maybe<Scalars['uuid']>
  documents?: Maybe<Documents_Arr_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  is_deleted?: Maybe<Scalars['Boolean']>
  note?: Maybe<Scalars['String']>
  resource_id?: Maybe<Scalars['uuid']>
  resource_type?: Maybe<Scalars['String']>
  subject_id?: Maybe<Scalars['uuid']>
  subject_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "notes" */
export type Notes_Max_Order_By = {
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  created_by_admin_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  subject_id?: Maybe<Order_By>
  subject_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "notes" */
export type Notes_Min_Order_By = {
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  created_by_admin_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  subject_id?: Maybe<Order_By>
  subject_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "notes" */
export type Notes_Mutation_Response = {
  __typename: 'notes_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notes>
}

/** on_conflict condition type for table "notes" */
export type Notes_On_Conflict = {
  constraint: Notes_Constraint
  update_columns?: Array<Notes_Update_Column>
  where?: Maybe<Notes_Bool_Exp>
}

/** Ordering options when selecting data from "notes". */
export type Notes_Order_By = {
  admin?: Maybe<Admins_Order_By>
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  created_by_admin_id?: Maybe<Order_By>
  documents_aggregate?: Maybe<Documents_Aggregate_Order_By>
  id?: Maybe<Order_By>
  is_deleted?: Maybe<Order_By>
  note?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  subject_id?: Maybe<Order_By>
  subject_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "notes" */
export enum Notes_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByAdminId = 'created_by_admin_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Note = 'note',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  SubjectId = 'subject_id',
  /** column name */
  SubjectType = 'subject_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** placeholder for update columns of table "notes" (current role has no relevant permissions) */
export enum Notes_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>
  _gt?: Maybe<Scalars['numeric']>
  _gte?: Maybe<Scalars['numeric']>
  _in?: Maybe<Array<Scalars['numeric']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['numeric']>
  _lte?: Maybe<Scalars['numeric']>
  _neq?: Maybe<Scalars['numeric']>
  _nin?: Maybe<Array<Scalars['numeric']>>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment_methods" */
export type Payment_Methods = {
  __typename: 'payment_methods'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  is_default?: Maybe<Scalars['Boolean']>
  mask: Scalars['String']
  name: Scalars['String']
  plaid_verification_status?: Maybe<Scalars['String']>
  status: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** aggregated selection of "payment_methods" */
export type Payment_Methods_Aggregate = {
  __typename: 'payment_methods_aggregate'
  aggregate?: Maybe<Payment_Methods_Aggregate_Fields>
  nodes: Array<Payment_Methods>
}

/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_Fields = {
  __typename: 'payment_methods_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Payment_Methods_Max_Fields>
  min?: Maybe<Payment_Methods_Min_Fields>
}

/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Methods_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "payment_methods" */
export type Payment_Methods_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Payment_Methods_Max_Order_By>
  min?: Maybe<Payment_Methods_Min_Order_By>
}

/** Boolean expression to filter rows from the table "payment_methods". All fields are combined with a logical 'AND'. */
export type Payment_Methods_Bool_Exp = {
  _and?: Maybe<Array<Payment_Methods_Bool_Exp>>
  _not?: Maybe<Payment_Methods_Bool_Exp>
  _or?: Maybe<Array<Payment_Methods_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_default?: Maybe<Boolean_Comparison_Exp>
  mask?: Maybe<String_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  plaid_verification_status?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** aggregate max on columns */
export type Payment_Methods_Max_Fields = {
  __typename: 'payment_methods_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  mask?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  plaid_verification_status?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "payment_methods" */
export type Payment_Methods_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mask?: Maybe<Order_By>
  name?: Maybe<Order_By>
  plaid_verification_status?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Payment_Methods_Min_Fields = {
  __typename: 'payment_methods_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  mask?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  plaid_verification_status?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "payment_methods" */
export type Payment_Methods_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mask?: Maybe<Order_By>
  name?: Maybe<Order_By>
  plaid_verification_status?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "payment_methods". */
export type Payment_Methods_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_default?: Maybe<Order_By>
  mask?: Maybe<Order_By>
  name?: Maybe<Order_By>
  plaid_verification_status?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "payment_methods" */
export enum Payment_Methods_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  Mask = 'mask',
  /** column name */
  Name = 'name',
  /** column name */
  PlaidVerificationStatus = 'plaid_verification_status',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "payments" */
export type Payments = {
  __typename: 'payments'
  amount: Scalars['numeric']
  available_on?: Maybe<Scalars['date']>
  category: Scalars['String']
  created_at?: Maybe<Scalars['timestamptz']>
  failed_payment_email_sent_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  initiated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  liability_type: Liability_Types
  liability_type_id: Scalars['uuid']
  lifecycle: Scalars['String']
  note?: Maybe<Scalars['String']>
  payment_date?: Maybe<Scalars['date']>
  payment_method?: Maybe<Scalars['String']>
  /** An object relationship */
  property?: Maybe<Properties>
  property_id?: Maybe<Scalars['uuid']>
  refunded_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  rental?: Maybe<Rentals>
  rental_id?: Maybe<Scalars['uuid']>
  status: Scalars['String']
  stripe_charge_id?: Maybe<Scalars['String']>
  stripe_payment_intent_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** aggregated selection of "payments" */
export type Payments_Aggregate = {
  __typename: 'payments_aggregate'
  aggregate?: Maybe<Payments_Aggregate_Fields>
  nodes: Array<Payments>
}

/** aggregate fields of "payments" */
export type Payments_Aggregate_Fields = {
  __typename: 'payments_aggregate_fields'
  avg?: Maybe<Payments_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Payments_Max_Fields>
  min?: Maybe<Payments_Min_Fields>
  stddev?: Maybe<Payments_Stddev_Fields>
  stddev_pop?: Maybe<Payments_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Payments_Stddev_Samp_Fields>
  sum?: Maybe<Payments_Sum_Fields>
  var_pop?: Maybe<Payments_Var_Pop_Fields>
  var_samp?: Maybe<Payments_Var_Samp_Fields>
  variance?: Maybe<Payments_Variance_Fields>
}

/** aggregate fields of "payments" */
export type Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payments_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "payments" */
export type Payments_Aggregate_Order_By = {
  avg?: Maybe<Payments_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Payments_Max_Order_By>
  min?: Maybe<Payments_Min_Order_By>
  stddev?: Maybe<Payments_Stddev_Order_By>
  stddev_pop?: Maybe<Payments_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Payments_Stddev_Samp_Order_By>
  sum?: Maybe<Payments_Sum_Order_By>
  var_pop?: Maybe<Payments_Var_Pop_Order_By>
  var_samp?: Maybe<Payments_Var_Samp_Order_By>
  variance?: Maybe<Payments_Variance_Order_By>
}

/** aggregate avg on columns */
export type Payments_Avg_Fields = {
  __typename: 'payments_avg_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "payments" */
export type Payments_Avg_Order_By = {
  amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type Payments_Bool_Exp = {
  _and?: Maybe<Array<Payments_Bool_Exp>>
  _not?: Maybe<Payments_Bool_Exp>
  _or?: Maybe<Array<Payments_Bool_Exp>>
  amount?: Maybe<Numeric_Comparison_Exp>
  available_on?: Maybe<Date_Comparison_Exp>
  category?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  failed_payment_email_sent_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  initiated_at?: Maybe<Timestamptz_Comparison_Exp>
  liability_type?: Maybe<Liability_Types_Bool_Exp>
  liability_type_id?: Maybe<Uuid_Comparison_Exp>
  lifecycle?: Maybe<String_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  payment_date?: Maybe<Date_Comparison_Exp>
  payment_method?: Maybe<String_Comparison_Exp>
  property?: Maybe<Properties_Bool_Exp>
  property_id?: Maybe<Uuid_Comparison_Exp>
  refunded_at?: Maybe<Timestamptz_Comparison_Exp>
  rental?: Maybe<Rentals_Bool_Exp>
  rental_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  stripe_charge_id?: Maybe<String_Comparison_Exp>
  stripe_payment_intent_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "payments" */
export enum Payments_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentsPkey = 'payments_pkey'
}

/** input type for inserting data into table "payments" */
export type Payments_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>
  category?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  failed_payment_email_sent_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  initiated_at?: Maybe<Scalars['timestamptz']>
  liability_type_id?: Maybe<Scalars['uuid']>
  lifecycle?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  payment_date?: Maybe<Scalars['date']>
  payment_method?: Maybe<Scalars['String']>
  property_id?: Maybe<Scalars['uuid']>
  rental_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  stripe_charge_id?: Maybe<Scalars['String']>
  stripe_payment_intent_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Payments_Max_Fields = {
  __typename: 'payments_max_fields'
  amount?: Maybe<Scalars['numeric']>
  available_on?: Maybe<Scalars['date']>
  category?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  failed_payment_email_sent_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  initiated_at?: Maybe<Scalars['timestamptz']>
  liability_type_id?: Maybe<Scalars['uuid']>
  lifecycle?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  payment_date?: Maybe<Scalars['date']>
  payment_method?: Maybe<Scalars['String']>
  property_id?: Maybe<Scalars['uuid']>
  refunded_at?: Maybe<Scalars['timestamptz']>
  rental_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  stripe_charge_id?: Maybe<Scalars['String']>
  stripe_payment_intent_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "payments" */
export type Payments_Max_Order_By = {
  amount?: Maybe<Order_By>
  available_on?: Maybe<Order_By>
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  failed_payment_email_sent_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  initiated_at?: Maybe<Order_By>
  liability_type_id?: Maybe<Order_By>
  lifecycle?: Maybe<Order_By>
  note?: Maybe<Order_By>
  payment_date?: Maybe<Order_By>
  payment_method?: Maybe<Order_By>
  property_id?: Maybe<Order_By>
  refunded_at?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  stripe_charge_id?: Maybe<Order_By>
  stripe_payment_intent_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Payments_Min_Fields = {
  __typename: 'payments_min_fields'
  amount?: Maybe<Scalars['numeric']>
  available_on?: Maybe<Scalars['date']>
  category?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  failed_payment_email_sent_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  initiated_at?: Maybe<Scalars['timestamptz']>
  liability_type_id?: Maybe<Scalars['uuid']>
  lifecycle?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  payment_date?: Maybe<Scalars['date']>
  payment_method?: Maybe<Scalars['String']>
  property_id?: Maybe<Scalars['uuid']>
  refunded_at?: Maybe<Scalars['timestamptz']>
  rental_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  stripe_charge_id?: Maybe<Scalars['String']>
  stripe_payment_intent_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "payments" */
export type Payments_Min_Order_By = {
  amount?: Maybe<Order_By>
  available_on?: Maybe<Order_By>
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  failed_payment_email_sent_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  initiated_at?: Maybe<Order_By>
  liability_type_id?: Maybe<Order_By>
  lifecycle?: Maybe<Order_By>
  note?: Maybe<Order_By>
  payment_date?: Maybe<Order_By>
  payment_method?: Maybe<Order_By>
  property_id?: Maybe<Order_By>
  refunded_at?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  stripe_charge_id?: Maybe<Order_By>
  stripe_payment_intent_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "payments" */
export type Payments_Mutation_Response = {
  __typename: 'payments_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Payments>
}

/** on_conflict condition type for table "payments" */
export type Payments_On_Conflict = {
  constraint: Payments_Constraint
  update_columns?: Array<Payments_Update_Column>
  where?: Maybe<Payments_Bool_Exp>
}

/** Ordering options when selecting data from "payments". */
export type Payments_Order_By = {
  amount?: Maybe<Order_By>
  available_on?: Maybe<Order_By>
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  failed_payment_email_sent_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  initiated_at?: Maybe<Order_By>
  liability_type?: Maybe<Liability_Types_Order_By>
  liability_type_id?: Maybe<Order_By>
  lifecycle?: Maybe<Order_By>
  note?: Maybe<Order_By>
  payment_date?: Maybe<Order_By>
  payment_method?: Maybe<Order_By>
  property?: Maybe<Properties_Order_By>
  property_id?: Maybe<Order_By>
  refunded_at?: Maybe<Order_By>
  rental?: Maybe<Rentals_Order_By>
  rental_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  stripe_charge_id?: Maybe<Order_By>
  stripe_payment_intent_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "payments" */
export enum Payments_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AvailableOn = 'available_on',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailedPaymentEmailSentAt = 'failed_payment_email_sent_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatedAt = 'initiated_at',
  /** column name */
  LiabilityTypeId = 'liability_type_id',
  /** column name */
  Lifecycle = 'lifecycle',
  /** column name */
  Note = 'note',
  /** column name */
  PaymentDate = 'payment_date',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  RefundedAt = 'refunded_at',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  Status = 'status',
  /** column name */
  StripeChargeId = 'stripe_charge_id',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Payments_Stddev_Fields = {
  __typename: 'payments_stddev_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "payments" */
export type Payments_Stddev_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Payments_Stddev_Pop_Fields = {
  __typename: 'payments_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "payments" */
export type Payments_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Payments_Stddev_Samp_Fields = {
  __typename: 'payments_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "payments" */
export type Payments_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate sum on columns */
export type Payments_Sum_Fields = {
  __typename: 'payments_sum_fields'
  amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "payments" */
export type Payments_Sum_Order_By = {
  amount?: Maybe<Order_By>
}

/** placeholder for update columns of table "payments" (current role has no relevant permissions) */
export enum Payments_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** aggregate var_pop on columns */
export type Payments_Var_Pop_Fields = {
  __typename: 'payments_var_pop_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "payments" */
export type Payments_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Payments_Var_Samp_Fields = {
  __typename: 'payments_var_samp_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "payments" */
export type Payments_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Payments_Variance_Fields = {
  __typename: 'payments_variance_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "payments" */
export type Payments_Variance_Order_By = {
  amount?: Maybe<Order_By>
}

/** columns and relationships of "plaid_income_verification_documents" */
export type Plaid_Income_Verification_Documents = {
  __typename: 'plaid_income_verification_documents'
  created_at?: Maybe<Scalars['timestamptz']>
  external_id?: Maybe<Scalars['String']>
  external_source?: Maybe<Scalars['String']>
  friendly_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  plaid_income_verification?: Maybe<Plaid_Income_Verifications>
  resource_id?: Maybe<Scalars['uuid']>
  resource_notes?: Maybe<Scalars['String']>
  resource_type?: Maybe<Scalars['String']>
  review_notes?: Maybe<Scalars['String']>
  reviewed_at?: Maybe<Scalars['timestamptz']>
  reviewed_by_admin_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by aggregate values of table "plaid_income_verification_documents" */
export type Plaid_Income_Verification_Documents_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Plaid_Income_Verification_Documents_Max_Order_By>
  min?: Maybe<Plaid_Income_Verification_Documents_Min_Order_By>
}

/** Boolean expression to filter rows from the table "plaid_income_verification_documents". All fields are combined with a logical 'AND'. */
export type Plaid_Income_Verification_Documents_Bool_Exp = {
  _and?: Maybe<Array<Plaid_Income_Verification_Documents_Bool_Exp>>
  _not?: Maybe<Plaid_Income_Verification_Documents_Bool_Exp>
  _or?: Maybe<Array<Plaid_Income_Verification_Documents_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  external_id?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  friendly_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  plaid_income_verification?: Maybe<Plaid_Income_Verifications_Bool_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_notes?: Maybe<String_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  review_notes?: Maybe<String_Comparison_Exp>
  reviewed_at?: Maybe<Timestamptz_Comparison_Exp>
  reviewed_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "plaid_income_verification_documents" */
export type Plaid_Income_Verification_Documents_Max_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "plaid_income_verification_documents" */
export type Plaid_Income_Verification_Documents_Min_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "plaid_income_verification_documents". */
export type Plaid_Income_Verification_Documents_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  plaid_income_verification?: Maybe<Plaid_Income_Verifications_Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "plaid_income_verification_documents" */
export enum Plaid_Income_Verification_Documents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  FriendlyName = 'friendly_name',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceNotes = 'resource_notes',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  ReviewNotes = 'review_notes',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  ReviewedByAdminId = 'reviewed_by_admin_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "plaid_income_verifications" */
export type Plaid_Income_Verifications = {
  __typename: 'plaid_income_verifications'
  created_at: Scalars['timestamptz']
  /** An array relationship */
  documents: Array<Plaid_Income_Verification_Documents>
  id: Scalars['uuid']
  income_response?: Maybe<Scalars['jsonb']>
  income_verification_id?: Maybe<Scalars['String']>
  income_verification_request_id?: Maybe<Scalars['String']>
  link_token_request_id: Scalars['String']
  paystub_data?: Maybe<Scalars['jsonb']>
  processed_at?: Maybe<Scalars['timestamptz']>
  summary_data?: Maybe<Scalars['jsonb']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** columns and relationships of "plaid_income_verifications" */
export type Plaid_Income_VerificationsDocumentsArgs = {
  distinct_on?: Maybe<Array<Plaid_Income_Verification_Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Plaid_Income_Verification_Documents_Order_By>>
  where?: Maybe<Plaid_Income_Verification_Documents_Bool_Exp>
}

/** columns and relationships of "plaid_income_verifications" */
export type Plaid_Income_VerificationsIncome_ResponseArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "plaid_income_verifications" */
export type Plaid_Income_VerificationsPaystub_DataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "plaid_income_verifications" */
export type Plaid_Income_VerificationsSummary_DataArgs = {
  path?: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "plaid_income_verifications". All fields are combined with a logical 'AND'. */
export type Plaid_Income_Verifications_Bool_Exp = {
  _and?: Maybe<Array<Plaid_Income_Verifications_Bool_Exp>>
  _not?: Maybe<Plaid_Income_Verifications_Bool_Exp>
  _or?: Maybe<Array<Plaid_Income_Verifications_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  documents?: Maybe<Plaid_Income_Verification_Documents_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  income_response?: Maybe<Jsonb_Comparison_Exp>
  income_verification_id?: Maybe<String_Comparison_Exp>
  income_verification_request_id?: Maybe<String_Comparison_Exp>
  link_token_request_id?: Maybe<String_Comparison_Exp>
  paystub_data?: Maybe<Jsonb_Comparison_Exp>
  processed_at?: Maybe<Timestamptz_Comparison_Exp>
  summary_data?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** Ordering options when selecting data from "plaid_income_verifications". */
export type Plaid_Income_Verifications_Order_By = {
  created_at?: Maybe<Order_By>
  documents_aggregate?: Maybe<Plaid_Income_Verification_Documents_Aggregate_Order_By>
  id?: Maybe<Order_By>
  income_response?: Maybe<Order_By>
  income_verification_id?: Maybe<Order_By>
  income_verification_request_id?: Maybe<Order_By>
  link_token_request_id?: Maybe<Order_By>
  paystub_data?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  summary_data?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "plaid_income_verifications" */
export enum Plaid_Income_Verifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IncomeResponse = 'income_response',
  /** column name */
  IncomeVerificationId = 'income_verification_id',
  /** column name */
  IncomeVerificationRequestId = 'income_verification_request_id',
  /** column name */
  LinkTokenRequestId = 'link_token_request_id',
  /** column name */
  PaystubData = 'paystub_data',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  SummaryData = 'summary_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "properties" */
export type Properties = {
  __typename: 'properties'
  exit_date?: Maybe<Scalars['date']>
  exit_price?: Maybe<Scalars['numeric']>
  id: Scalars['uuid']
  /** An array relationship */
  llc_properties: Array<Llc_Properties>
  /** An object relationship */
  real_estate_acquisition?: Maybe<Real_Estate_Acquisitions>
  real_estate_acquisition_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  rentals: Array<Rentals>
}

/** columns and relationships of "properties" */
export type PropertiesLlc_PropertiesArgs = {
  distinct_on?: Maybe<Array<Llc_Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Llc_Properties_Order_By>>
  where?: Maybe<Llc_Properties_Bool_Exp>
}

/** columns and relationships of "properties" */
export type PropertiesRentalsArgs = {
  distinct_on?: Maybe<Array<Rentals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rentals_Order_By>>
  where?: Maybe<Rentals_Bool_Exp>
}

/** Boolean expression to filter rows from the table "properties". All fields are combined with a logical 'AND'. */
export type Properties_Bool_Exp = {
  _and?: Maybe<Array<Properties_Bool_Exp>>
  _not?: Maybe<Properties_Bool_Exp>
  _or?: Maybe<Array<Properties_Bool_Exp>>
  exit_date?: Maybe<Date_Comparison_Exp>
  exit_price?: Maybe<Numeric_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  llc_properties?: Maybe<Llc_Properties_Bool_Exp>
  real_estate_acquisition?: Maybe<Real_Estate_Acquisitions_Bool_Exp>
  real_estate_acquisition_id?: Maybe<Uuid_Comparison_Exp>
  rentals?: Maybe<Rentals_Bool_Exp>
}

/** Ordering options when selecting data from "properties". */
export type Properties_Order_By = {
  exit_date?: Maybe<Order_By>
  exit_price?: Maybe<Order_By>
  id?: Maybe<Order_By>
  llc_properties_aggregate?: Maybe<Llc_Properties_Aggregate_Order_By>
  real_estate_acquisition?: Maybe<Real_Estate_Acquisitions_Order_By>
  real_estate_acquisition_id?: Maybe<Order_By>
  rentals_aggregate?: Maybe<Rentals_Aggregate_Order_By>
}

/** select columns of table "properties" */
export enum Properties_Select_Column {
  /** column name */
  ExitDate = 'exit_date',
  /** column name */
  ExitPrice = 'exit_price',
  /** column name */
  Id = 'id',
  /** column name */
  RealEstateAcquisitionId = 'real_estate_acquisition_id'
}

export type Query_Root = {
  __typename: 'query_root'
  /** An array relationship */
  admin_capabilities: Array<Admin_Capabilities>
  /** fetch data from the table: "admin_capabilities" using primary key columns */
  admin_capabilities_by_pk?: Maybe<Admin_Capabilities>
  /** fetch data from the table: "admins" */
  admins: Array<Admins>
  /** fetch data from the table: "admins" using primary key columns */
  admins_by_pk?: Maybe<Admins>
  /** fetch data from the table: "agent_user_owners" */
  agent_user_owners: Array<Agent_User_Owners>
  /** fetch data from the table: "agent_user_owners" using primary key columns */
  agent_user_owners_by_pk?: Maybe<Agent_User_Owners>
  /** fetch data from the table: "agents" */
  agents: Array<Agents>
  /** fetch data from the table: "agents" using primary key columns */
  agents_by_pk?: Maybe<Agents>
  /** An array relationship */
  agreement_signers: Array<Agreement_Signers>
  /** fetch data from the table: "agreement_signers" using primary key columns */
  agreement_signers_by_pk?: Maybe<Agreement_Signers>
  /** An array relationship */
  agreements: Array<Agreements>
  /** fetch data from the table: "agreements" using primary key columns */
  agreements_by_pk?: Maybe<Agreements>
  /** fetch data from the table: "capabilities" */
  capabilities: Array<Capabilities>
  /** fetch data from the table: "capabilities" using primary key columns */
  capabilities_by_pk?: Maybe<Capabilities>
  /** An array relationship */
  construction_projects: Array<Construction_Projects>
  /** fetch data from the table: "construction_projects" using primary key columns */
  construction_projects_by_pk?: Maybe<Construction_Projects>
  /** An array relationship */
  conversations: Array<Conversations>
  /** fetch data from the table: "conversations" using primary key columns */
  conversations_by_pk?: Maybe<Conversations>
  /** An array relationship */
  credit_reports: Array<Credit_Reports>
  /** fetch data from the table: "credit_reports" using primary key columns */
  credit_reports_by_pk?: Maybe<Credit_Reports>
  /** An array relationship */
  criminal_reports: Array<Criminal_Reports>
  /** fetch data from the table: "criminal_reports" using primary key columns */
  criminal_reports_by_pk?: Maybe<Criminal_Reports>
  /** An array relationship */
  documents: Array<Documents>
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>
  /** An array relationship */
  eviction_reports: Array<Eviction_Reports>
  /** fetch data from the table: "eviction_reports" using primary key columns */
  eviction_reports_by_pk?: Maybe<Eviction_Reports>
  /** fetch data from the table: "fund_llcs" */
  fund_llcs: Array<Fund_Llcs>
  /** fetch data from the table: "fund_llcs" using primary key columns */
  fund_llcs_by_pk?: Maybe<Fund_Llcs>
  /** fetch data from the table: "funds" */
  funds: Array<Funds>
  /** fetch data from the table: "funds" using primary key columns */
  funds_by_pk?: Maybe<Funds>
  getUserQualificationData?: Maybe<UserQualificationData>
  /** fetch data from the table: "kafka_connector.prorated_utility_bill_line_items" */
  kafka_connector_prorated_utility_bill_line_items: Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  /** fetch data from the table: "kafka_connector.prorated_utility_bill_line_items" using primary key columns */
  kafka_connector_prorated_utility_bill_line_items_by_pk?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  /** fetch data from the table: "lead_group_rental_application_notes" */
  lead_group_rental_application_notes: Array<Lead_Group_Rental_Application_Notes>
  /** An array relationship */
  lead_group_users: Array<Lead_Group_Users>
  /** fetch data from the table: "lead_group_users" using primary key columns */
  lead_group_users_by_pk?: Maybe<Lead_Group_Users>
  /** fetch data from the table: "lead_groups" */
  lead_groups: Array<Lead_Groups>
  /** fetch data from the table: "lead_groups" using primary key columns */
  lead_groups_by_pk?: Maybe<Lead_Groups>
  /** fetch data from the table: "ledger_accounts" */
  ledger_accounts: Array<Ledger_Accounts>
  /** fetch data from the table: "ledger_accounts" using primary key columns */
  ledger_accounts_by_pk?: Maybe<Ledger_Accounts>
  /** fetch data from the table: "liability_types" */
  liability_types: Array<Liability_Types>
  /** fetch data from the table: "liability_types" using primary key columns */
  liability_types_by_pk?: Maybe<Liability_Types>
  /** An array relationship */
  llc_properties: Array<Llc_Properties>
  /** fetch data from the table: "llc_properties" using primary key columns */
  llc_properties_by_pk?: Maybe<Llc_Properties>
  /** fetch data from the table: "llcs" */
  llcs: Array<Llcs>
  /** fetch data from the table: "llcs" using primary key columns */
  llcs_by_pk?: Maybe<Llcs>
  /** fetch data from the table: "markets" */
  markets: Array<Markets>
  /** fetch data from the table: "markets" using primary key columns */
  markets_by_pk?: Maybe<Markets>
  /** fetch data from the table: "mls_listing_internal_attributes" */
  mls_listing_internal_attributes: Array<Mls_Listing_Internal_Attributes>
  /** fetch data from the table: "mls_listing_internal_attributes" using primary key columns */
  mls_listing_internal_attributes_by_pk?: Maybe<Mls_Listing_Internal_Attributes>
  /** fetch data from the table: "mls_listing_photos" */
  mls_listing_photos: Array<Mls_Listing_Photos>
  /** fetch data from the table: "mls_listing_photos" using primary key columns */
  mls_listing_photos_by_pk?: Maybe<Mls_Listing_Photos>
  /** An array relationship */
  mls_listing_promotions: Array<Mls_Listing_Promotions>
  /** fetch data from the table: "mls_listing_promotions" using primary key columns */
  mls_listing_promotions_by_pk?: Maybe<Mls_Listing_Promotions>
  /** An array relationship */
  mls_listings: Array<Mls_Listings>
  /** fetch data from the table: "mls_listings" using primary key columns */
  mls_listings_by_pk?: Maybe<Mls_Listings>
  /** An array relationship */
  notes: Array<Notes>
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>
  /** An array relationship */
  payment_methods: Array<Payment_Methods>
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>
  /** An array relationship */
  payments: Array<Payments>
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>
  /** fetch data from the table: "plaid_income_verification_documents" */
  plaid_income_verification_documents: Array<Plaid_Income_Verification_Documents>
  /** fetch data from the table: "plaid_income_verifications" */
  plaid_income_verifications: Array<Plaid_Income_Verifications>
  /** fetch data from the table: "plaid_income_verifications" using primary key columns */
  plaid_income_verifications_by_pk?: Maybe<Plaid_Income_Verifications>
  /** fetch data from the table: "properties" */
  properties: Array<Properties>
  /** fetch data from the table: "properties" using primary key columns */
  properties_by_pk?: Maybe<Properties>
  /** An array relationship */
  real_estate_acquisitions: Array<Real_Estate_Acquisitions>
  /** fetch data from the table: "real_estate_acquisitions" using primary key columns */
  real_estate_acquisitions_by_pk?: Maybe<Real_Estate_Acquisitions>
  /** An array relationship */
  rent_prices: Array<Rent_Prices>
  /** fetch data from the table: "rent_prices" using primary key columns */
  rent_prices_by_pk?: Maybe<Rent_Prices>
  /** An array relationship */
  rent_ready_dates: Array<Rent_Ready_Dates>
  /** fetch data from the table: "rent_ready_dates" using primary key columns */
  rent_ready_dates_by_pk?: Maybe<Rent_Ready_Dates>
  /** An array relationship */
  rental_add_ons: Array<Rental_Add_Ons>
  /** fetch data from the table: "rental_add_ons" using primary key columns */
  rental_add_ons_by_pk?: Maybe<Rental_Add_Ons>
  /** An array relationship */
  rental_agreement_histories: Array<Rental_Agreement_Histories>
  /** fetch data from the table: "rental_agreement_histories" using primary key columns */
  rental_agreement_histories_by_pk?: Maybe<Rental_Agreement_Histories>
  /** An array relationship */
  rental_applications: Array<Rental_Applications>
  /** fetch data from the table: "rental_applications" using primary key columns */
  rental_applications_by_pk?: Maybe<Rental_Applications>
  /** An array relationship */
  rental_credits: Array<Rental_Credits>
  /** fetch data from the table: "rental_credits" using primary key columns */
  rental_credits_by_pk?: Maybe<Rental_Credits>
  /** An array relationship */
  rental_liabilities: Array<Rental_Liabilities>
  /** fetch data from the table: "rental_liabilities" using primary key columns */
  rental_liabilities_by_pk?: Maybe<Rental_Liabilities>
  /** fetch data from the table: "rental_site_accounts" */
  rental_site_accounts: Array<Rental_Site_Accounts>
  /** fetch data from the table: "rental_site_accounts" using primary key columns */
  rental_site_accounts_by_pk?: Maybe<Rental_Site_Accounts>
  /** An array relationship */
  rental_site_leads: Array<Rental_Site_Leads>
  /** fetch data from the table: "rental_site_leads" using primary key columns */
  rental_site_leads_by_pk?: Maybe<Rental_Site_Leads>
  /** fetch data from the table: "rental_site_listings" */
  rental_site_listings: Array<Rental_Site_Listings>
  /** fetch data from the table: "rental_site_listings" using primary key columns */
  rental_site_listings_by_pk?: Maybe<Rental_Site_Listings>
  /** An array relationship */
  rental_users: Array<Rental_Users>
  /** fetch data from the table: "rental_users" using primary key columns */
  rental_users_by_pk?: Maybe<Rental_Users>
  /** An array relationship */
  rentals: Array<Rentals>
  /** fetch data from the table: "rentals" using primary key columns */
  rentals_by_pk?: Maybe<Rentals>
  /** fetch data from the table: "seller_agent_searchable" */
  seller_agent_searchable: Array<Seller_Agent_Searchable>
  /** fetch data from the table: "seller_agents" */
  seller_agents: Array<Seller_Agents>
  /** fetch data from the table: "seller_agents" using primary key columns */
  seller_agents_by_pk?: Maybe<Seller_Agents>
  /** An array relationship */
  tour_properties: Array<Tour_Properties>
  /** fetch data from the table: "tour_properties" using primary key columns */
  tour_properties_by_pk?: Maybe<Tour_Properties>
  /** An array relationship */
  tours: Array<Tours>
  /** fetch data from the table: "tours" using primary key columns */
  tours_by_pk?: Maybe<Tours>
  /** An array relationship */
  user_contact_details: Array<User_Contact_Details>
  /** fetch data from the table: "user_contact_details" using primary key columns */
  user_contact_details_by_pk?: Maybe<User_Contact_Details>
  /** fetch data from the table: "user_documents" */
  user_documents: Array<User_Documents>
  /** fetch data from the table: "user_funnel_statuses" */
  user_funnel_statuses: Array<User_Funnel_Statuses>
  /** fetch data from the table: "user_funnel_statuses" using primary key columns */
  user_funnel_statuses_by_pk?: Maybe<User_Funnel_Statuses>
  /** An array relationship */
  user_incomes: Array<User_Incomes>
  /** fetch data from the table: "user_incomes" using primary key columns */
  user_incomes_by_pk?: Maybe<User_Incomes>
  /** fetch data from the table: "user_prequalification_data" */
  user_prequalification_data: Array<User_Prequalification_Data>
  /** fetch data from the table: "user_prequalification_data" using primary key columns */
  user_prequalification_data_by_pk?: Maybe<User_Prequalification_Data>
  /** fetch data from the table: "user_searchable" */
  user_searchable: Array<User_Searchable>
  /** fetch data from the table: "users" */
  users: Array<Users>
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>
}

export type Query_RootAdmin_CapabilitiesArgs = {
  distinct_on?: Maybe<Array<Admin_Capabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Admin_Capabilities_Order_By>>
  where?: Maybe<Admin_Capabilities_Bool_Exp>
}

export type Query_RootAdmin_Capabilities_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAdminsArgs = {
  distinct_on?: Maybe<Array<Admins_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Admins_Order_By>>
  where?: Maybe<Admins_Bool_Exp>
}

export type Query_RootAdmins_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAgent_User_OwnersArgs = {
  distinct_on?: Maybe<Array<Agent_User_Owners_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agent_User_Owners_Order_By>>
  where?: Maybe<Agent_User_Owners_Bool_Exp>
}

export type Query_RootAgent_User_Owners_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAgentsArgs = {
  distinct_on?: Maybe<Array<Agents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agents_Order_By>>
  where?: Maybe<Agents_Bool_Exp>
}

export type Query_RootAgents_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAgreement_SignersArgs = {
  distinct_on?: Maybe<Array<Agreement_Signers_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agreement_Signers_Order_By>>
  where?: Maybe<Agreement_Signers_Bool_Exp>
}

export type Query_RootAgreement_Signers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAgreementsArgs = {
  distinct_on?: Maybe<Array<Agreements_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agreements_Order_By>>
  where?: Maybe<Agreements_Bool_Exp>
}

export type Query_RootAgreements_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCapabilitiesArgs = {
  distinct_on?: Maybe<Array<Capabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Capabilities_Order_By>>
  where?: Maybe<Capabilities_Bool_Exp>
}

export type Query_RootCapabilities_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootConstruction_ProjectsArgs = {
  distinct_on?: Maybe<Array<Construction_Projects_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Construction_Projects_Order_By>>
  where?: Maybe<Construction_Projects_Bool_Exp>
}

export type Query_RootConstruction_Projects_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootConversationsArgs = {
  distinct_on?: Maybe<Array<Conversations_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Conversations_Order_By>>
  where?: Maybe<Conversations_Bool_Exp>
}

export type Query_RootConversations_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCredit_ReportsArgs = {
  distinct_on?: Maybe<Array<Credit_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Credit_Reports_Order_By>>
  where?: Maybe<Credit_Reports_Bool_Exp>
}

export type Query_RootCredit_Reports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCriminal_ReportsArgs = {
  distinct_on?: Maybe<Array<Criminal_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Criminal_Reports_Order_By>>
  where?: Maybe<Criminal_Reports_Bool_Exp>
}

export type Query_RootCriminal_Reports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDocumentsArgs = {
  distinct_on?: Maybe<Array<Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Documents_Order_By>>
  where?: Maybe<Documents_Bool_Exp>
}

export type Query_RootDocuments_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootEviction_ReportsArgs = {
  distinct_on?: Maybe<Array<Eviction_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Eviction_Reports_Order_By>>
  where?: Maybe<Eviction_Reports_Bool_Exp>
}

export type Query_RootEviction_Reports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFund_LlcsArgs = {
  distinct_on?: Maybe<Array<Fund_Llcs_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fund_Llcs_Order_By>>
  where?: Maybe<Fund_Llcs_Bool_Exp>
}

export type Query_RootFund_Llcs_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFundsArgs = {
  distinct_on?: Maybe<Array<Funds_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Funds_Order_By>>
  where?: Maybe<Funds_Bool_Exp>
}

export type Query_RootFunds_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGetUserQualificationDataArgs = {
  id: Scalars['ID']
}

export type Query_RootKafka_Connector_Prorated_Utility_Bill_Line_ItemsArgs = {
  distinct_on?: Maybe<
    Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Select_Column>
  >
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<
    Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Order_By>
  >
  where?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp>
}

export type Query_RootKafka_Connector_Prorated_Utility_Bill_Line_Items_By_PkArgs =
  {
    id: Scalars['uuid']
  }

export type Query_RootLead_Group_Rental_Application_NotesArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Rental_Application_Notes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Rental_Application_Notes_Order_By>>
  where?: Maybe<Lead_Group_Rental_Application_Notes_Bool_Exp>
}

export type Query_RootLead_Group_UsersArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Users_Order_By>>
  where?: Maybe<Lead_Group_Users_Bool_Exp>
}

export type Query_RootLead_Group_Users_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLead_GroupsArgs = {
  distinct_on?: Maybe<Array<Lead_Groups_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Groups_Order_By>>
  where?: Maybe<Lead_Groups_Bool_Exp>
}

export type Query_RootLead_Groups_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLedger_AccountsArgs = {
  distinct_on?: Maybe<Array<Ledger_Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Ledger_Accounts_Order_By>>
  where?: Maybe<Ledger_Accounts_Bool_Exp>
}

export type Query_RootLedger_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLiability_TypesArgs = {
  distinct_on?: Maybe<Array<Liability_Types_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Liability_Types_Order_By>>
  where?: Maybe<Liability_Types_Bool_Exp>
}

export type Query_RootLiability_Types_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLlc_PropertiesArgs = {
  distinct_on?: Maybe<Array<Llc_Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Llc_Properties_Order_By>>
  where?: Maybe<Llc_Properties_Bool_Exp>
}

export type Query_RootLlc_Properties_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLlcsArgs = {
  distinct_on?: Maybe<Array<Llcs_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Llcs_Order_By>>
  where?: Maybe<Llcs_Bool_Exp>
}

export type Query_RootLlcs_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMarketsArgs = {
  distinct_on?: Maybe<Array<Markets_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Markets_Order_By>>
  where?: Maybe<Markets_Bool_Exp>
}

export type Query_RootMarkets_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMls_Listing_Internal_AttributesArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Internal_Attributes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Internal_Attributes_Order_By>>
  where?: Maybe<Mls_Listing_Internal_Attributes_Bool_Exp>
}

export type Query_RootMls_Listing_Internal_Attributes_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMls_Listing_PhotosArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Photos_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Photos_Order_By>>
  where?: Maybe<Mls_Listing_Photos_Bool_Exp>
}

export type Query_RootMls_Listing_Photos_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMls_Listing_PromotionsArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Promotions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Promotions_Order_By>>
  where?: Maybe<Mls_Listing_Promotions_Bool_Exp>
}

export type Query_RootMls_Listing_Promotions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMls_ListingsArgs = {
  distinct_on?: Maybe<Array<Mls_Listings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listings_Order_By>>
  where?: Maybe<Mls_Listings_Bool_Exp>
}

export type Query_RootMls_Listings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notes_Order_By>>
  where?: Maybe<Notes_Bool_Exp>
}

export type Query_RootNotes_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payment_Methods_Order_By>>
  where?: Maybe<Payment_Methods_Bool_Exp>
}

export type Query_RootPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payment_Methods_Order_By>>
  where?: Maybe<Payment_Methods_Bool_Exp>
}

export type Query_RootPayment_Methods_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

export type Query_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

export type Query_RootPayments_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPlaid_Income_Verification_DocumentsArgs = {
  distinct_on?: Maybe<Array<Plaid_Income_Verification_Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Plaid_Income_Verification_Documents_Order_By>>
  where?: Maybe<Plaid_Income_Verification_Documents_Bool_Exp>
}

export type Query_RootPlaid_Income_VerificationsArgs = {
  distinct_on?: Maybe<Array<Plaid_Income_Verifications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Plaid_Income_Verifications_Order_By>>
  where?: Maybe<Plaid_Income_Verifications_Bool_Exp>
}

export type Query_RootPlaid_Income_Verifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPropertiesArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Properties_Order_By>>
  where?: Maybe<Properties_Bool_Exp>
}

export type Query_RootProperties_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootReal_Estate_AcquisitionsArgs = {
  distinct_on?: Maybe<Array<Real_Estate_Acquisitions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Real_Estate_Acquisitions_Order_By>>
  where?: Maybe<Real_Estate_Acquisitions_Bool_Exp>
}

export type Query_RootReal_Estate_Acquisitions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRent_PricesArgs = {
  distinct_on?: Maybe<Array<Rent_Prices_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rent_Prices_Order_By>>
  where?: Maybe<Rent_Prices_Bool_Exp>
}

export type Query_RootRent_Prices_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRent_Ready_DatesArgs = {
  distinct_on?: Maybe<Array<Rent_Ready_Dates_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rent_Ready_Dates_Order_By>>
  where?: Maybe<Rent_Ready_Dates_Bool_Exp>
}

export type Query_RootRent_Ready_Dates_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_Add_OnsArgs = {
  distinct_on?: Maybe<Array<Rental_Add_Ons_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Add_Ons_Order_By>>
  where?: Maybe<Rental_Add_Ons_Bool_Exp>
}

export type Query_RootRental_Add_Ons_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_Agreement_HistoriesArgs = {
  distinct_on?: Maybe<Array<Rental_Agreement_Histories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Agreement_Histories_Order_By>>
  where?: Maybe<Rental_Agreement_Histories_Bool_Exp>
}

export type Query_RootRental_Agreement_Histories_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Rental_Applications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Applications_Order_By>>
  where?: Maybe<Rental_Applications_Bool_Exp>
}

export type Query_RootRental_Applications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_CreditsArgs = {
  distinct_on?: Maybe<Array<Rental_Credits_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Credits_Order_By>>
  where?: Maybe<Rental_Credits_Bool_Exp>
}

export type Query_RootRental_Credits_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_LiabilitiesArgs = {
  distinct_on?: Maybe<Array<Rental_Liabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Liabilities_Order_By>>
  where?: Maybe<Rental_Liabilities_Bool_Exp>
}

export type Query_RootRental_Liabilities_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_Site_AccountsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Accounts_Order_By>>
  where?: Maybe<Rental_Site_Accounts_Bool_Exp>
}

export type Query_RootRental_Site_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_Site_LeadsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Leads_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Leads_Order_By>>
  where?: Maybe<Rental_Site_Leads_Bool_Exp>
}

export type Query_RootRental_Site_Leads_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_Site_ListingsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Listings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Listings_Order_By>>
  where?: Maybe<Rental_Site_Listings_Bool_Exp>
}

export type Query_RootRental_Site_Listings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRental_UsersArgs = {
  distinct_on?: Maybe<Array<Rental_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Users_Order_By>>
  where?: Maybe<Rental_Users_Bool_Exp>
}

export type Query_RootRental_Users_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRentalsArgs = {
  distinct_on?: Maybe<Array<Rentals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rentals_Order_By>>
  where?: Maybe<Rentals_Bool_Exp>
}

export type Query_RootRentals_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSeller_Agent_SearchableArgs = {
  distinct_on?: Maybe<Array<Seller_Agent_Searchable_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Seller_Agent_Searchable_Order_By>>
  where?: Maybe<Seller_Agent_Searchable_Bool_Exp>
}

export type Query_RootSeller_AgentsArgs = {
  distinct_on?: Maybe<Array<Seller_Agents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Seller_Agents_Order_By>>
  where?: Maybe<Seller_Agents_Bool_Exp>
}

export type Query_RootSeller_Agents_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootTour_PropertiesArgs = {
  distinct_on?: Maybe<Array<Tour_Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tour_Properties_Order_By>>
  where?: Maybe<Tour_Properties_Bool_Exp>
}

export type Query_RootTour_Properties_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootToursArgs = {
  distinct_on?: Maybe<Array<Tours_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tours_Order_By>>
  where?: Maybe<Tours_Bool_Exp>
}

export type Query_RootTours_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Contact_DetailsArgs = {
  distinct_on?: Maybe<Array<User_Contact_Details_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Contact_Details_Order_By>>
  where?: Maybe<User_Contact_Details_Bool_Exp>
}

export type Query_RootUser_Contact_Details_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_DocumentsArgs = {
  distinct_on?: Maybe<Array<User_Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Documents_Order_By>>
  where?: Maybe<User_Documents_Bool_Exp>
}

export type Query_RootUser_Funnel_StatusesArgs = {
  distinct_on?: Maybe<Array<User_Funnel_Statuses_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Funnel_Statuses_Order_By>>
  where?: Maybe<User_Funnel_Statuses_Bool_Exp>
}

export type Query_RootUser_Funnel_Statuses_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_IncomesArgs = {
  distinct_on?: Maybe<Array<User_Incomes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Incomes_Order_By>>
  where?: Maybe<User_Incomes_Bool_Exp>
}

export type Query_RootUser_Incomes_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Prequalification_DataArgs = {
  distinct_on?: Maybe<Array<User_Prequalification_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Prequalification_Data_Order_By>>
  where?: Maybe<User_Prequalification_Data_Bool_Exp>
}

export type Query_RootUser_Prequalification_Data_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_SearchableArgs = {
  distinct_on?: Maybe<Array<User_Searchable_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Searchable_Order_By>>
  where?: Maybe<User_Searchable_Bool_Exp>
}

export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Users_Order_By>>
  where?: Maybe<Users_Bool_Exp>
}

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Users_Order_By>>
  where?: Maybe<Users_Bool_Exp>
}

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid']
}

/** columns and relationships of "real_estate_acquisitions" */
export type Real_Estate_Acquisitions = {
  __typename: 'real_estate_acquisitions'
  accepted_bid_id?: Maybe<Scalars['uuid']>
  access_information_note_id?: Maybe<Scalars['uuid']>
  active_rent_price_id?: Maybe<Scalars['uuid']>
  actual_made_ready_date?: Maybe<Scalars['date']>
  admin_bid_request_id?: Maybe<Scalars['uuid']>
  brokerage_fee_percentage?: Maybe<Scalars['numeric']>
  cancelation_reason?: Maybe<Scalars['String']>
  canceled_at?: Maybe<Scalars['timestamptz']>
  closing_date?: Maybe<Scalars['date']>
  closing_real_estate_attorney_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  construction_projects: Array<Construction_Projects>
  created_at: Scalars['timestamptz']
  due_diligence_end_at?: Maybe<Scalars['timestamptz']>
  due_diligence_start_at?: Maybe<Scalars['timestamptz']>
  due_diligence_status?: Maybe<Scalars['String']>
  due_diligence_status_approval_at?: Maybe<Scalars['timestamptz']>
  emd_amount_returned_to_buyer?: Maybe<Scalars['numeric']>
  emd_returned_at?: Maybe<Scalars['timestamptz']>
  emd_returned_note_id?: Maybe<Scalars['uuid']>
  emd_returned_to_type?: Maybe<Scalars['String']>
  emd_sent_at?: Maybe<Scalars['timestamptz']>
  estimated_made_ready_date?: Maybe<Scalars['date']>
  external_link?: Maybe<Scalars['String']>
  external_source?: Maybe<Scalars['String']>
  final_ic_underwrite_at?: Maybe<Scalars['timestamptz']>
  final_ic_underwrite_rent_price_id?: Maybe<Scalars['uuid']>
  final_purchase_price?: Maybe<Scalars['numeric']>
  final_sellers_credits_amount?: Maybe<Scalars['numeric']>
  firestore_property_id: Scalars['uuid']
  fotonotes_project_id?: Maybe<Scalars['String']>
  housecanary_bpo_valuation?: Maybe<Scalars['numeric']>
  id: Scalars['uuid']
  inspection_notice_end_date?: Maybe<Scalars['date']>
  is_closing_confirmed: Scalars['Boolean']
  is_reinspection_required?: Maybe<Scalars['Boolean']>
  is_vacant?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  llc?: Maybe<Llcs>
  llc_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  mls_listing: Mls_Listings
  mls_listing_id: Scalars['uuid']
  possession_at?: Maybe<Scalars['timestamptz']>
  real_estate_screening_id?: Maybe<Scalars['uuid']>
  status: Scalars['String']
  submitted_to_review_committee_at?: Maybe<Scalars['timestamptz']>
  submitted_to_review_committee_by_admin_id?: Maybe<Scalars['uuid']>
  title_company_id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  utilities_activation_date?: Maybe<Scalars['date']>
}

/** columns and relationships of "real_estate_acquisitions" */
export type Real_Estate_AcquisitionsConstruction_ProjectsArgs = {
  distinct_on?: Maybe<Array<Construction_Projects_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Construction_Projects_Order_By>>
  where?: Maybe<Construction_Projects_Bool_Exp>
}

/** order by aggregate values of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Aggregate_Order_By = {
  avg?: Maybe<Real_Estate_Acquisitions_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Real_Estate_Acquisitions_Max_Order_By>
  min?: Maybe<Real_Estate_Acquisitions_Min_Order_By>
  stddev?: Maybe<Real_Estate_Acquisitions_Stddev_Order_By>
  stddev_pop?: Maybe<Real_Estate_Acquisitions_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Real_Estate_Acquisitions_Stddev_Samp_Order_By>
  sum?: Maybe<Real_Estate_Acquisitions_Sum_Order_By>
  var_pop?: Maybe<Real_Estate_Acquisitions_Var_Pop_Order_By>
  var_samp?: Maybe<Real_Estate_Acquisitions_Var_Samp_Order_By>
  variance?: Maybe<Real_Estate_Acquisitions_Variance_Order_By>
}

/** order by avg() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Avg_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "real_estate_acquisitions". All fields are combined with a logical 'AND'. */
export type Real_Estate_Acquisitions_Bool_Exp = {
  _and?: Maybe<Array<Real_Estate_Acquisitions_Bool_Exp>>
  _not?: Maybe<Real_Estate_Acquisitions_Bool_Exp>
  _or?: Maybe<Array<Real_Estate_Acquisitions_Bool_Exp>>
  accepted_bid_id?: Maybe<Uuid_Comparison_Exp>
  access_information_note_id?: Maybe<Uuid_Comparison_Exp>
  active_rent_price_id?: Maybe<Uuid_Comparison_Exp>
  actual_made_ready_date?: Maybe<Date_Comparison_Exp>
  admin_bid_request_id?: Maybe<Uuid_Comparison_Exp>
  brokerage_fee_percentage?: Maybe<Numeric_Comparison_Exp>
  cancelation_reason?: Maybe<String_Comparison_Exp>
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>
  closing_date?: Maybe<Date_Comparison_Exp>
  closing_real_estate_attorney_id?: Maybe<Uuid_Comparison_Exp>
  construction_projects?: Maybe<Construction_Projects_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  due_diligence_end_at?: Maybe<Timestamptz_Comparison_Exp>
  due_diligence_start_at?: Maybe<Timestamptz_Comparison_Exp>
  due_diligence_status?: Maybe<String_Comparison_Exp>
  due_diligence_status_approval_at?: Maybe<Timestamptz_Comparison_Exp>
  emd_amount_returned_to_buyer?: Maybe<Numeric_Comparison_Exp>
  emd_returned_at?: Maybe<Timestamptz_Comparison_Exp>
  emd_returned_note_id?: Maybe<Uuid_Comparison_Exp>
  emd_returned_to_type?: Maybe<String_Comparison_Exp>
  emd_sent_at?: Maybe<Timestamptz_Comparison_Exp>
  estimated_made_ready_date?: Maybe<Date_Comparison_Exp>
  external_link?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  final_ic_underwrite_at?: Maybe<Timestamptz_Comparison_Exp>
  final_ic_underwrite_rent_price_id?: Maybe<Uuid_Comparison_Exp>
  final_purchase_price?: Maybe<Numeric_Comparison_Exp>
  final_sellers_credits_amount?: Maybe<Numeric_Comparison_Exp>
  firestore_property_id?: Maybe<Uuid_Comparison_Exp>
  fotonotes_project_id?: Maybe<String_Comparison_Exp>
  housecanary_bpo_valuation?: Maybe<Numeric_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  inspection_notice_end_date?: Maybe<Date_Comparison_Exp>
  is_closing_confirmed?: Maybe<Boolean_Comparison_Exp>
  is_reinspection_required?: Maybe<Boolean_Comparison_Exp>
  is_vacant?: Maybe<Boolean_Comparison_Exp>
  llc?: Maybe<Llcs_Bool_Exp>
  llc_id?: Maybe<Uuid_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  possession_at?: Maybe<Timestamptz_Comparison_Exp>
  real_estate_screening_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  submitted_to_review_committee_at?: Maybe<Timestamptz_Comparison_Exp>
  submitted_to_review_committee_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  title_company_id?: Maybe<Uuid_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  utilities_activation_date?: Maybe<Date_Comparison_Exp>
}

/** order by max() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Max_Order_By = {
  accepted_bid_id?: Maybe<Order_By>
  access_information_note_id?: Maybe<Order_By>
  active_rent_price_id?: Maybe<Order_By>
  actual_made_ready_date?: Maybe<Order_By>
  admin_bid_request_id?: Maybe<Order_By>
  brokerage_fee_percentage?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  closing_date?: Maybe<Order_By>
  closing_real_estate_attorney_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  due_diligence_end_at?: Maybe<Order_By>
  due_diligence_start_at?: Maybe<Order_By>
  due_diligence_status?: Maybe<Order_By>
  due_diligence_status_approval_at?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  emd_returned_at?: Maybe<Order_By>
  emd_returned_note_id?: Maybe<Order_By>
  emd_returned_to_type?: Maybe<Order_By>
  emd_sent_at?: Maybe<Order_By>
  estimated_made_ready_date?: Maybe<Order_By>
  external_link?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  final_ic_underwrite_at?: Maybe<Order_By>
  final_ic_underwrite_rent_price_id?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  firestore_property_id?: Maybe<Order_By>
  fotonotes_project_id?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
  id?: Maybe<Order_By>
  inspection_notice_end_date?: Maybe<Order_By>
  llc_id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  possession_at?: Maybe<Order_By>
  real_estate_screening_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  submitted_to_review_committee_at?: Maybe<Order_By>
  submitted_to_review_committee_by_admin_id?: Maybe<Order_By>
  title_company_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  utilities_activation_date?: Maybe<Order_By>
}

/** order by min() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Min_Order_By = {
  accepted_bid_id?: Maybe<Order_By>
  access_information_note_id?: Maybe<Order_By>
  active_rent_price_id?: Maybe<Order_By>
  actual_made_ready_date?: Maybe<Order_By>
  admin_bid_request_id?: Maybe<Order_By>
  brokerage_fee_percentage?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  closing_date?: Maybe<Order_By>
  closing_real_estate_attorney_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  due_diligence_end_at?: Maybe<Order_By>
  due_diligence_start_at?: Maybe<Order_By>
  due_diligence_status?: Maybe<Order_By>
  due_diligence_status_approval_at?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  emd_returned_at?: Maybe<Order_By>
  emd_returned_note_id?: Maybe<Order_By>
  emd_returned_to_type?: Maybe<Order_By>
  emd_sent_at?: Maybe<Order_By>
  estimated_made_ready_date?: Maybe<Order_By>
  external_link?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  final_ic_underwrite_at?: Maybe<Order_By>
  final_ic_underwrite_rent_price_id?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  firestore_property_id?: Maybe<Order_By>
  fotonotes_project_id?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
  id?: Maybe<Order_By>
  inspection_notice_end_date?: Maybe<Order_By>
  llc_id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  possession_at?: Maybe<Order_By>
  real_estate_screening_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  submitted_to_review_committee_at?: Maybe<Order_By>
  submitted_to_review_committee_by_admin_id?: Maybe<Order_By>
  title_company_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  utilities_activation_date?: Maybe<Order_By>
}

/** Ordering options when selecting data from "real_estate_acquisitions". */
export type Real_Estate_Acquisitions_Order_By = {
  accepted_bid_id?: Maybe<Order_By>
  access_information_note_id?: Maybe<Order_By>
  active_rent_price_id?: Maybe<Order_By>
  actual_made_ready_date?: Maybe<Order_By>
  admin_bid_request_id?: Maybe<Order_By>
  brokerage_fee_percentage?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  closing_date?: Maybe<Order_By>
  closing_real_estate_attorney_id?: Maybe<Order_By>
  construction_projects_aggregate?: Maybe<Construction_Projects_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  due_diligence_end_at?: Maybe<Order_By>
  due_diligence_start_at?: Maybe<Order_By>
  due_diligence_status?: Maybe<Order_By>
  due_diligence_status_approval_at?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  emd_returned_at?: Maybe<Order_By>
  emd_returned_note_id?: Maybe<Order_By>
  emd_returned_to_type?: Maybe<Order_By>
  emd_sent_at?: Maybe<Order_By>
  estimated_made_ready_date?: Maybe<Order_By>
  external_link?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  final_ic_underwrite_at?: Maybe<Order_By>
  final_ic_underwrite_rent_price_id?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  firestore_property_id?: Maybe<Order_By>
  fotonotes_project_id?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
  id?: Maybe<Order_By>
  inspection_notice_end_date?: Maybe<Order_By>
  is_closing_confirmed?: Maybe<Order_By>
  is_reinspection_required?: Maybe<Order_By>
  is_vacant?: Maybe<Order_By>
  llc?: Maybe<Llcs_Order_By>
  llc_id?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  mls_listing_id?: Maybe<Order_By>
  possession_at?: Maybe<Order_By>
  real_estate_screening_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  submitted_to_review_committee_at?: Maybe<Order_By>
  submitted_to_review_committee_by_admin_id?: Maybe<Order_By>
  title_company_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  utilities_activation_date?: Maybe<Order_By>
}

/** select columns of table "real_estate_acquisitions" */
export enum Real_Estate_Acquisitions_Select_Column {
  /** column name */
  AcceptedBidId = 'accepted_bid_id',
  /** column name */
  AccessInformationNoteId = 'access_information_note_id',
  /** column name */
  ActiveRentPriceId = 'active_rent_price_id',
  /** column name */
  ActualMadeReadyDate = 'actual_made_ready_date',
  /** column name */
  AdminBidRequestId = 'admin_bid_request_id',
  /** column name */
  BrokerageFeePercentage = 'brokerage_fee_percentage',
  /** column name */
  CancelationReason = 'cancelation_reason',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  ClosingDate = 'closing_date',
  /** column name */
  ClosingRealEstateAttorneyId = 'closing_real_estate_attorney_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueDiligenceEndAt = 'due_diligence_end_at',
  /** column name */
  DueDiligenceStartAt = 'due_diligence_start_at',
  /** column name */
  DueDiligenceStatus = 'due_diligence_status',
  /** column name */
  DueDiligenceStatusApprovalAt = 'due_diligence_status_approval_at',
  /** column name */
  EmdAmountReturnedToBuyer = 'emd_amount_returned_to_buyer',
  /** column name */
  EmdReturnedAt = 'emd_returned_at',
  /** column name */
  EmdReturnedNoteId = 'emd_returned_note_id',
  /** column name */
  EmdReturnedToType = 'emd_returned_to_type',
  /** column name */
  EmdSentAt = 'emd_sent_at',
  /** column name */
  EstimatedMadeReadyDate = 'estimated_made_ready_date',
  /** column name */
  ExternalLink = 'external_link',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  FinalIcUnderwriteAt = 'final_ic_underwrite_at',
  /** column name */
  FinalIcUnderwriteRentPriceId = 'final_ic_underwrite_rent_price_id',
  /** column name */
  FinalPurchasePrice = 'final_purchase_price',
  /** column name */
  FinalSellersCreditsAmount = 'final_sellers_credits_amount',
  /** column name */
  FirestorePropertyId = 'firestore_property_id',
  /** column name */
  FotonotesProjectId = 'fotonotes_project_id',
  /** column name */
  HousecanaryBpoValuation = 'housecanary_bpo_valuation',
  /** column name */
  Id = 'id',
  /** column name */
  InspectionNoticeEndDate = 'inspection_notice_end_date',
  /** column name */
  IsClosingConfirmed = 'is_closing_confirmed',
  /** column name */
  IsReinspectionRequired = 'is_reinspection_required',
  /** column name */
  IsVacant = 'is_vacant',
  /** column name */
  LlcId = 'llc_id',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  PossessionAt = 'possession_at',
  /** column name */
  RealEstateScreeningId = 'real_estate_screening_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubmittedToReviewCommitteeAt = 'submitted_to_review_committee_at',
  /** column name */
  SubmittedToReviewCommitteeByAdminId = 'submitted_to_review_committee_by_admin_id',
  /** column name */
  TitleCompanyId = 'title_company_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtilitiesActivationDate = 'utilities_activation_date'
}

/** order by stddev() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Stddev_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Stddev_Pop_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Stddev_Samp_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** order by sum() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Sum_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Var_Pop_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Var_Samp_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** order by variance() on columns of table "real_estate_acquisitions" */
export type Real_Estate_Acquisitions_Variance_Order_By = {
  brokerage_fee_percentage?: Maybe<Order_By>
  emd_amount_returned_to_buyer?: Maybe<Order_By>
  final_purchase_price?: Maybe<Order_By>
  final_sellers_credits_amount?: Maybe<Order_By>
  housecanary_bpo_valuation?: Maybe<Order_By>
}

/** columns and relationships of "rent_prices" */
export type Rent_Prices = {
  __typename: 'rent_prices'
  annual_bad_debt_expense?: Maybe<Scalars['numeric']>
  annual_hoa?: Maybe<Scalars['numeric']>
  annual_insurance_cost?: Maybe<Scalars['numeric']>
  annual_opex?: Maybe<Scalars['numeric']>
  annual_other_opex?: Maybe<Scalars['numeric']>
  annual_repairs_and_maintenance?: Maybe<Scalars['numeric']>
  annual_taxes?: Maybe<Scalars['numeric']>
  annual_vacancy_expense?: Maybe<Scalars['numeric']>
  closing_costs?: Maybe<Scalars['numeric']>
  created_at: Scalars['timestamptz']
  deferred_capex_cost?: Maybe<Scalars['numeric']>
  estimated_cap_rate?: Maybe<Scalars['numeric']>
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  is_approved_to_list: Scalars['Boolean']
  list_price?: Maybe<Scalars['numeric']>
  mls_listing_id: Scalars['uuid']
  monthly_property_management_fee?: Maybe<Scalars['numeric']>
  origination_fee?: Maybe<Scalars['numeric']>
  percent_over_comp?: Maybe<Scalars['numeric']>
  rent_price?: Maybe<Scalars['numeric']>
  rental_comp?: Maybe<Scalars['numeric']>
  sellers_credits?: Maybe<Scalars['numeric']>
  source?: Maybe<Scalars['String']>
  underwritten_vacancy_days?: Maybe<Scalars['Int']>
  updated_at: Scalars['timestamptz']
  upfront_improvements_cost?: Maybe<Scalars['numeric']>
}

/** order by aggregate values of table "rent_prices" */
export type Rent_Prices_Aggregate_Order_By = {
  avg?: Maybe<Rent_Prices_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rent_Prices_Max_Order_By>
  min?: Maybe<Rent_Prices_Min_Order_By>
  stddev?: Maybe<Rent_Prices_Stddev_Order_By>
  stddev_pop?: Maybe<Rent_Prices_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rent_Prices_Stddev_Samp_Order_By>
  sum?: Maybe<Rent_Prices_Sum_Order_By>
  var_pop?: Maybe<Rent_Prices_Var_Pop_Order_By>
  var_samp?: Maybe<Rent_Prices_Var_Samp_Order_By>
  variance?: Maybe<Rent_Prices_Variance_Order_By>
}

/** order by avg() on columns of table "rent_prices" */
export type Rent_Prices_Avg_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rent_prices". All fields are combined with a logical 'AND'. */
export type Rent_Prices_Bool_Exp = {
  _and?: Maybe<Array<Rent_Prices_Bool_Exp>>
  _not?: Maybe<Rent_Prices_Bool_Exp>
  _or?: Maybe<Array<Rent_Prices_Bool_Exp>>
  annual_bad_debt_expense?: Maybe<Numeric_Comparison_Exp>
  annual_hoa?: Maybe<Numeric_Comparison_Exp>
  annual_insurance_cost?: Maybe<Numeric_Comparison_Exp>
  annual_opex?: Maybe<Numeric_Comparison_Exp>
  annual_other_opex?: Maybe<Numeric_Comparison_Exp>
  annual_repairs_and_maintenance?: Maybe<Numeric_Comparison_Exp>
  annual_taxes?: Maybe<Numeric_Comparison_Exp>
  annual_vacancy_expense?: Maybe<Numeric_Comparison_Exp>
  closing_costs?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deferred_capex_cost?: Maybe<Numeric_Comparison_Exp>
  estimated_cap_rate?: Maybe<Numeric_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  is_approved_to_list?: Maybe<Boolean_Comparison_Exp>
  list_price?: Maybe<Numeric_Comparison_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  monthly_property_management_fee?: Maybe<Numeric_Comparison_Exp>
  origination_fee?: Maybe<Numeric_Comparison_Exp>
  percent_over_comp?: Maybe<Numeric_Comparison_Exp>
  rent_price?: Maybe<Numeric_Comparison_Exp>
  rental_comp?: Maybe<Numeric_Comparison_Exp>
  sellers_credits?: Maybe<Numeric_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  underwritten_vacancy_days?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  upfront_improvements_cost?: Maybe<Numeric_Comparison_Exp>
}

/** order by max() on columns of table "rent_prices" */
export type Rent_Prices_Max_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  id?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  source?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by min() on columns of table "rent_prices" */
export type Rent_Prices_Min_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  id?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  source?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** Ordering options when selecting data from "rent_prices". */
export type Rent_Prices_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  is_approved_to_list?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  source?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** select columns of table "rent_prices" */
export enum Rent_Prices_Select_Column {
  /** column name */
  AnnualBadDebtExpense = 'annual_bad_debt_expense',
  /** column name */
  AnnualHoa = 'annual_hoa',
  /** column name */
  AnnualInsuranceCost = 'annual_insurance_cost',
  /** column name */
  AnnualOpex = 'annual_opex',
  /** column name */
  AnnualOtherOpex = 'annual_other_opex',
  /** column name */
  AnnualRepairsAndMaintenance = 'annual_repairs_and_maintenance',
  /** column name */
  AnnualTaxes = 'annual_taxes',
  /** column name */
  AnnualVacancyExpense = 'annual_vacancy_expense',
  /** column name */
  ClosingCosts = 'closing_costs',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeferredCapexCost = 'deferred_capex_cost',
  /** column name */
  EstimatedCapRate = 'estimated_cap_rate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsApprovedToList = 'is_approved_to_list',
  /** column name */
  ListPrice = 'list_price',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  MonthlyPropertyManagementFee = 'monthly_property_management_fee',
  /** column name */
  OriginationFee = 'origination_fee',
  /** column name */
  PercentOverComp = 'percent_over_comp',
  /** column name */
  RentPrice = 'rent_price',
  /** column name */
  RentalComp = 'rental_comp',
  /** column name */
  SellersCredits = 'sellers_credits',
  /** column name */
  Source = 'source',
  /** column name */
  UnderwrittenVacancyDays = 'underwritten_vacancy_days',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpfrontImprovementsCost = 'upfront_improvements_cost'
}

/** order by stddev() on columns of table "rent_prices" */
export type Rent_Prices_Stddev_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rent_prices" */
export type Rent_Prices_Stddev_Pop_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rent_prices" */
export type Rent_Prices_Stddev_Samp_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by sum() on columns of table "rent_prices" */
export type Rent_Prices_Sum_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "rent_prices" */
export type Rent_Prices_Var_Pop_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rent_prices" */
export type Rent_Prices_Var_Samp_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** order by variance() on columns of table "rent_prices" */
export type Rent_Prices_Variance_Order_By = {
  annual_bad_debt_expense?: Maybe<Order_By>
  annual_hoa?: Maybe<Order_By>
  annual_insurance_cost?: Maybe<Order_By>
  annual_opex?: Maybe<Order_By>
  annual_other_opex?: Maybe<Order_By>
  annual_repairs_and_maintenance?: Maybe<Order_By>
  annual_taxes?: Maybe<Order_By>
  annual_vacancy_expense?: Maybe<Order_By>
  closing_costs?: Maybe<Order_By>
  deferred_capex_cost?: Maybe<Order_By>
  estimated_cap_rate?: Maybe<Order_By>
  list_price?: Maybe<Order_By>
  monthly_property_management_fee?: Maybe<Order_By>
  origination_fee?: Maybe<Order_By>
  percent_over_comp?: Maybe<Order_By>
  rent_price?: Maybe<Order_By>
  rental_comp?: Maybe<Order_By>
  sellers_credits?: Maybe<Order_By>
  underwritten_vacancy_days?: Maybe<Order_By>
  upfront_improvements_cost?: Maybe<Order_By>
}

/** columns and relationships of "rent_ready_dates" */
export type Rent_Ready_Dates = {
  __typename: 'rent_ready_dates'
  earliest_move_in_date?: Maybe<Scalars['date']>
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  /** An object relationship */
  mls_listing: Mls_Listings
  mls_listing_id: Scalars['uuid']
  type: Scalars['String']
}

/** order by aggregate values of table "rent_ready_dates" */
export type Rent_Ready_Dates_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Rent_Ready_Dates_Max_Order_By>
  min?: Maybe<Rent_Ready_Dates_Min_Order_By>
}

/** Boolean expression to filter rows from the table "rent_ready_dates". All fields are combined with a logical 'AND'. */
export type Rent_Ready_Dates_Bool_Exp = {
  _and?: Maybe<Array<Rent_Ready_Dates_Bool_Exp>>
  _not?: Maybe<Rent_Ready_Dates_Bool_Exp>
  _or?: Maybe<Array<Rent_Ready_Dates_Bool_Exp>>
  earliest_move_in_date?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
}

/** order by max() on columns of table "rent_ready_dates" */
export type Rent_Ready_Dates_Max_Order_By = {
  earliest_move_in_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** order by min() on columns of table "rent_ready_dates" */
export type Rent_Ready_Dates_Min_Order_By = {
  earliest_move_in_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** Ordering options when selecting data from "rent_ready_dates". */
export type Rent_Ready_Dates_Order_By = {
  earliest_move_in_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  mls_listing_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** select columns of table "rent_ready_dates" */
export enum Rent_Ready_Dates_Select_Column {
  /** column name */
  EarliestMoveInDate = 'earliest_move_in_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "rental_add_ons" */
export type Rental_Add_Ons = {
  __typename: 'rental_add_ons'
  end_date?: Maybe<Scalars['date']>
  id: Scalars['uuid']
  /** An object relationship */
  liability_type: Liability_Types
  price: Scalars['numeric']
  /** An object relationship */
  rental: Rentals
  start_date: Scalars['date']
}

/** order by aggregate values of table "rental_add_ons" */
export type Rental_Add_Ons_Aggregate_Order_By = {
  avg?: Maybe<Rental_Add_Ons_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Add_Ons_Max_Order_By>
  min?: Maybe<Rental_Add_Ons_Min_Order_By>
  stddev?: Maybe<Rental_Add_Ons_Stddev_Order_By>
  stddev_pop?: Maybe<Rental_Add_Ons_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rental_Add_Ons_Stddev_Samp_Order_By>
  sum?: Maybe<Rental_Add_Ons_Sum_Order_By>
  var_pop?: Maybe<Rental_Add_Ons_Var_Pop_Order_By>
  var_samp?: Maybe<Rental_Add_Ons_Var_Samp_Order_By>
  variance?: Maybe<Rental_Add_Ons_Variance_Order_By>
}

/** order by avg() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Avg_Order_By = {
  price?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rental_add_ons". All fields are combined with a logical 'AND'. */
export type Rental_Add_Ons_Bool_Exp = {
  _and?: Maybe<Array<Rental_Add_Ons_Bool_Exp>>
  _not?: Maybe<Rental_Add_Ons_Bool_Exp>
  _or?: Maybe<Array<Rental_Add_Ons_Bool_Exp>>
  end_date?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  liability_type?: Maybe<Liability_Types_Bool_Exp>
  price?: Maybe<Numeric_Comparison_Exp>
  rental?: Maybe<Rentals_Bool_Exp>
  start_date?: Maybe<Date_Comparison_Exp>
}

/** unique or primary key constraints on table "rental_add_ons" */
export enum Rental_Add_Ons_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalAddOnsPkey = 'rental_add_ons_pkey'
}

/** input type for inserting data into table "rental_add_ons" */
export type Rental_Add_Ons_Insert_Input = {
  liability_type_id?: Maybe<Scalars['uuid']>
  price?: Maybe<Scalars['numeric']>
  rental_id?: Maybe<Scalars['uuid']>
  start_date?: Maybe<Scalars['date']>
}

/** order by max() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Max_Order_By = {
  end_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  price?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Min_Order_By = {
  end_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  price?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
}

/** response of any mutation on the table "rental_add_ons" */
export type Rental_Add_Ons_Mutation_Response = {
  __typename: 'rental_add_ons_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Add_Ons>
}

/** on_conflict condition type for table "rental_add_ons" */
export type Rental_Add_Ons_On_Conflict = {
  constraint: Rental_Add_Ons_Constraint
  update_columns?: Array<Rental_Add_Ons_Update_Column>
  where?: Maybe<Rental_Add_Ons_Bool_Exp>
}

/** Ordering options when selecting data from "rental_add_ons". */
export type Rental_Add_Ons_Order_By = {
  end_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  liability_type?: Maybe<Liability_Types_Order_By>
  price?: Maybe<Order_By>
  rental?: Maybe<Rentals_Order_By>
  start_date?: Maybe<Order_By>
}

/** primary key columns input for table: rental_add_ons */
export type Rental_Add_Ons_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "rental_add_ons" */
export enum Rental_Add_Ons_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "rental_add_ons" */
export type Rental_Add_Ons_Set_Input = {
  end_date?: Maybe<Scalars['date']>
}

/** order by stddev() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Stddev_Order_By = {
  price?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>
}

/** order by sum() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Sum_Order_By = {
  price?: Maybe<Order_By>
}

/** update columns of table "rental_add_ons" */
export enum Rental_Add_Ons_Update_Column {
  /** column name */
  EndDate = 'end_date'
}

export type Rental_Add_Ons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rental_Add_Ons_Set_Input>
  where: Rental_Add_Ons_Bool_Exp
}

/** order by var_pop() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Var_Pop_Order_By = {
  price?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Var_Samp_Order_By = {
  price?: Maybe<Order_By>
}

/** order by variance() on columns of table "rental_add_ons" */
export type Rental_Add_Ons_Variance_Order_By = {
  price?: Maybe<Order_By>
}

/** columns and relationships of "rental_agreement_histories" */
export type Rental_Agreement_Histories = {
  __typename: 'rental_agreement_histories'
  /** An object relationship */
  agreement?: Maybe<Agreements>
  agreement_id?: Maybe<Scalars['uuid']>
  ends_at: Scalars['date']
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  option_premium: Scalars['numeric']
  rent: Scalars['numeric']
  /** An object relationship */
  rental: Rentals
  rental_id: Scalars['uuid']
  starts_at: Scalars['date']
}

/** order by aggregate values of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Aggregate_Order_By = {
  avg?: Maybe<Rental_Agreement_Histories_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Agreement_Histories_Max_Order_By>
  min?: Maybe<Rental_Agreement_Histories_Min_Order_By>
  stddev?: Maybe<Rental_Agreement_Histories_Stddev_Order_By>
  stddev_pop?: Maybe<Rental_Agreement_Histories_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rental_Agreement_Histories_Stddev_Samp_Order_By>
  sum?: Maybe<Rental_Agreement_Histories_Sum_Order_By>
  var_pop?: Maybe<Rental_Agreement_Histories_Var_Pop_Order_By>
  var_samp?: Maybe<Rental_Agreement_Histories_Var_Samp_Order_By>
  variance?: Maybe<Rental_Agreement_Histories_Variance_Order_By>
}

/** order by avg() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Avg_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rental_agreement_histories". All fields are combined with a logical 'AND'. */
export type Rental_Agreement_Histories_Bool_Exp = {
  _and?: Maybe<Array<Rental_Agreement_Histories_Bool_Exp>>
  _not?: Maybe<Rental_Agreement_Histories_Bool_Exp>
  _or?: Maybe<Array<Rental_Agreement_Histories_Bool_Exp>>
  agreement?: Maybe<Agreements_Bool_Exp>
  agreement_id?: Maybe<Uuid_Comparison_Exp>
  ends_at?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  option_premium?: Maybe<Numeric_Comparison_Exp>
  rent?: Maybe<Numeric_Comparison_Exp>
  rental?: Maybe<Rentals_Bool_Exp>
  rental_id?: Maybe<Uuid_Comparison_Exp>
  starts_at?: Maybe<Date_Comparison_Exp>
}

/** unique or primary key constraints on table "rental_agreement_histories" */
export enum Rental_Agreement_Histories_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalPriceHistoriesPkey = 'rental_price_histories_pkey'
}

/** input type for inserting data into table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Insert_Input = {
  ends_at?: Maybe<Scalars['date']>
  is_active?: Maybe<Scalars['Boolean']>
  option_premium?: Maybe<Scalars['numeric']>
  rent?: Maybe<Scalars['numeric']>
  rental_id?: Maybe<Scalars['uuid']>
  starts_at?: Maybe<Scalars['date']>
}

/** order by max() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Max_Order_By = {
  agreement_id?: Maybe<Order_By>
  ends_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  starts_at?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Min_Order_By = {
  agreement_id?: Maybe<Order_By>
  ends_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  starts_at?: Maybe<Order_By>
}

/** response of any mutation on the table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Mutation_Response = {
  __typename: 'rental_agreement_histories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Agreement_Histories>
}

/** on_conflict condition type for table "rental_agreement_histories" */
export type Rental_Agreement_Histories_On_Conflict = {
  constraint: Rental_Agreement_Histories_Constraint
  update_columns?: Array<Rental_Agreement_Histories_Update_Column>
  where?: Maybe<Rental_Agreement_Histories_Bool_Exp>
}

/** Ordering options when selecting data from "rental_agreement_histories". */
export type Rental_Agreement_Histories_Order_By = {
  agreement?: Maybe<Agreements_Order_By>
  agreement_id?: Maybe<Order_By>
  ends_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  rental?: Maybe<Rentals_Order_By>
  rental_id?: Maybe<Order_By>
  starts_at?: Maybe<Order_By>
}

/** primary key columns input for table: rental_agreement_histories */
export type Rental_Agreement_Histories_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "rental_agreement_histories" */
export enum Rental_Agreement_Histories_Select_Column {
  /** column name */
  AgreementId = 'agreement_id',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  OptionPremium = 'option_premium',
  /** column name */
  Rent = 'rent',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  StartsAt = 'starts_at'
}

/** input type for updating data in table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Set_Input = {
  ends_at?: Maybe<Scalars['date']>
}

/** order by stddev() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Stddev_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Stddev_Pop_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Stddev_Samp_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by sum() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Sum_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** update columns of table "rental_agreement_histories" */
export enum Rental_Agreement_Histories_Update_Column {
  /** column name */
  EndsAt = 'ends_at'
}

export type Rental_Agreement_Histories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rental_Agreement_Histories_Set_Input>
  where: Rental_Agreement_Histories_Bool_Exp
}

/** order by var_pop() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Var_Pop_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Var_Samp_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** order by variance() on columns of table "rental_agreement_histories" */
export type Rental_Agreement_Histories_Variance_Order_By = {
  option_premium?: Maybe<Order_By>
  rent?: Maybe<Order_By>
}

/** columns and relationships of "rental_applications" */
export type Rental_Applications = {
  __typename: 'rental_applications'
  /** An array relationship */
  agreements: Array<Agreements>
  cancelation_reason?: Maybe<Scalars['String']>
  canceled_at?: Maybe<Scalars['timestamptz']>
  completed_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  is_underwriting_approved?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  lead_group: Lead_Groups
  lead_group_id: Scalars['uuid']
  /** An object relationship */
  mls_listing: Mls_Listings
  mls_listing_id: Scalars['uuid']
  projected_occupancy_date?: Maybe<Scalars['date']>
  /** An array relationship */
  rentals: Array<Rentals>
  reviewed_at?: Maybe<Scalars['timestamptz']>
  reviewed_by_admin_id?: Maybe<Scalars['uuid']>
  status: Scalars['String']
  underwritten_at?: Maybe<Scalars['timestamptz']>
  underwritten_by_admin_id?: Maybe<Scalars['uuid']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "rental_applications" */
export type Rental_ApplicationsAgreementsArgs = {
  distinct_on?: Maybe<Array<Agreements_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agreements_Order_By>>
  where?: Maybe<Agreements_Bool_Exp>
}

/** columns and relationships of "rental_applications" */
export type Rental_ApplicationsRentalsArgs = {
  distinct_on?: Maybe<Array<Rentals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rentals_Order_By>>
  where?: Maybe<Rentals_Bool_Exp>
}

/** order by aggregate values of table "rental_applications" */
export type Rental_Applications_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Applications_Max_Order_By>
  min?: Maybe<Rental_Applications_Min_Order_By>
}

/** Boolean expression to filter rows from the table "rental_applications". All fields are combined with a logical 'AND'. */
export type Rental_Applications_Bool_Exp = {
  _and?: Maybe<Array<Rental_Applications_Bool_Exp>>
  _not?: Maybe<Rental_Applications_Bool_Exp>
  _or?: Maybe<Array<Rental_Applications_Bool_Exp>>
  agreements?: Maybe<Agreements_Bool_Exp>
  cancelation_reason?: Maybe<String_Comparison_Exp>
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>
  completed_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_underwriting_approved?: Maybe<Boolean_Comparison_Exp>
  lead_group?: Maybe<Lead_Groups_Bool_Exp>
  lead_group_id?: Maybe<Uuid_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  projected_occupancy_date?: Maybe<Date_Comparison_Exp>
  rentals?: Maybe<Rentals_Bool_Exp>
  reviewed_at?: Maybe<Timestamptz_Comparison_Exp>
  reviewed_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  underwritten_at?: Maybe<Timestamptz_Comparison_Exp>
  underwritten_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "rental_applications" */
export type Rental_Applications_Max_Order_By = {
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  projected_occupancy_date?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  underwritten_at?: Maybe<Order_By>
  underwritten_by_admin_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_applications" */
export type Rental_Applications_Min_Order_By = {
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  mls_listing_id?: Maybe<Order_By>
  projected_occupancy_date?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  underwritten_at?: Maybe<Order_By>
  underwritten_by_admin_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "rental_applications". */
export type Rental_Applications_Order_By = {
  agreements_aggregate?: Maybe<Agreements_Aggregate_Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_underwriting_approved?: Maybe<Order_By>
  lead_group?: Maybe<Lead_Groups_Order_By>
  lead_group_id?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  mls_listing_id?: Maybe<Order_By>
  projected_occupancy_date?: Maybe<Order_By>
  rentals_aggregate?: Maybe<Rentals_Aggregate_Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  underwritten_at?: Maybe<Order_By>
  underwritten_by_admin_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "rental_applications" */
export enum Rental_Applications_Select_Column {
  /** column name */
  CancelationReason = 'cancelation_reason',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsUnderwritingApproved = 'is_underwriting_approved',
  /** column name */
  LeadGroupId = 'lead_group_id',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  ProjectedOccupancyDate = 'projected_occupancy_date',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  ReviewedByAdminId = 'reviewed_by_admin_id',
  /** column name */
  Status = 'status',
  /** column name */
  UnderwrittenAt = 'underwritten_at',
  /** column name */
  UnderwrittenByAdminId = 'underwritten_by_admin_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "rental_credits" */
export type Rental_Credits = {
  __typename: 'rental_credits'
  amount: Scalars['numeric']
  date: Scalars['date']
  id: Scalars['uuid']
  /** An object relationship */
  ledger_account: Ledger_Accounts
  note: Scalars['String']
  offsetting_ledger_account_id: Scalars['uuid']
  rental_id: Scalars['uuid']
  type: Scalars['String']
}

/** order by aggregate values of table "rental_credits" */
export type Rental_Credits_Aggregate_Order_By = {
  avg?: Maybe<Rental_Credits_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Credits_Max_Order_By>
  min?: Maybe<Rental_Credits_Min_Order_By>
  stddev?: Maybe<Rental_Credits_Stddev_Order_By>
  stddev_pop?: Maybe<Rental_Credits_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rental_Credits_Stddev_Samp_Order_By>
  sum?: Maybe<Rental_Credits_Sum_Order_By>
  var_pop?: Maybe<Rental_Credits_Var_Pop_Order_By>
  var_samp?: Maybe<Rental_Credits_Var_Samp_Order_By>
  variance?: Maybe<Rental_Credits_Variance_Order_By>
}

/** order by avg() on columns of table "rental_credits" */
export type Rental_Credits_Avg_Order_By = {
  amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rental_credits". All fields are combined with a logical 'AND'. */
export type Rental_Credits_Bool_Exp = {
  _and?: Maybe<Array<Rental_Credits_Bool_Exp>>
  _not?: Maybe<Rental_Credits_Bool_Exp>
  _or?: Maybe<Array<Rental_Credits_Bool_Exp>>
  amount?: Maybe<Numeric_Comparison_Exp>
  date?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  ledger_account?: Maybe<Ledger_Accounts_Bool_Exp>
  note?: Maybe<String_Comparison_Exp>
  offsetting_ledger_account_id?: Maybe<Uuid_Comparison_Exp>
  rental_id?: Maybe<Uuid_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "rental_credits" */
export enum Rental_Credits_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalCreditsPkey = 'rental_credits_pkey'
}

/** input type for inserting data into table "rental_credits" */
export type Rental_Credits_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>
  date?: Maybe<Scalars['date']>
  note?: Maybe<Scalars['String']>
  offsetting_ledger_account_id?: Maybe<Scalars['uuid']>
  rental_id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "rental_credits" */
export type Rental_Credits_Max_Order_By = {
  amount?: Maybe<Order_By>
  date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  offsetting_ledger_account_id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_credits" */
export type Rental_Credits_Min_Order_By = {
  amount?: Maybe<Order_By>
  date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  offsetting_ledger_account_id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** response of any mutation on the table "rental_credits" */
export type Rental_Credits_Mutation_Response = {
  __typename: 'rental_credits_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Credits>
}

/** on_conflict condition type for table "rental_credits" */
export type Rental_Credits_On_Conflict = {
  constraint: Rental_Credits_Constraint
  update_columns?: Array<Rental_Credits_Update_Column>
  where?: Maybe<Rental_Credits_Bool_Exp>
}

/** Ordering options when selecting data from "rental_credits". */
export type Rental_Credits_Order_By = {
  amount?: Maybe<Order_By>
  date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ledger_account?: Maybe<Ledger_Accounts_Order_By>
  note?: Maybe<Order_By>
  offsetting_ledger_account_id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
}

/** select columns of table "rental_credits" */
export enum Rental_Credits_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OffsettingLedgerAccountId = 'offsetting_ledger_account_id',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  Type = 'type'
}

/** order by stddev() on columns of table "rental_credits" */
export type Rental_Credits_Stddev_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rental_credits" */
export type Rental_Credits_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rental_credits" */
export type Rental_Credits_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by sum() on columns of table "rental_credits" */
export type Rental_Credits_Sum_Order_By = {
  amount?: Maybe<Order_By>
}

/** placeholder for update columns of table "rental_credits" (current role has no relevant permissions) */
export enum Rental_Credits_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "rental_credits" */
export type Rental_Credits_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rental_credits" */
export type Rental_Credits_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by variance() on columns of table "rental_credits" */
export type Rental_Credits_Variance_Order_By = {
  amount?: Maybe<Order_By>
}

/** columns and relationships of "rental_liabilities" */
export type Rental_Liabilities = {
  __typename: 'rental_liabilities'
  amount: Scalars['numeric']
  date: Scalars['date']
  id: Scalars['uuid']
  /** An object relationship */
  liability_type: Liability_Types
  /** An object relationship */
  rental: Rentals
  rental_id: Scalars['uuid']
}

/** order by aggregate values of table "rental_liabilities" */
export type Rental_Liabilities_Aggregate_Order_By = {
  avg?: Maybe<Rental_Liabilities_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Liabilities_Max_Order_By>
  min?: Maybe<Rental_Liabilities_Min_Order_By>
  stddev?: Maybe<Rental_Liabilities_Stddev_Order_By>
  stddev_pop?: Maybe<Rental_Liabilities_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rental_Liabilities_Stddev_Samp_Order_By>
  sum?: Maybe<Rental_Liabilities_Sum_Order_By>
  var_pop?: Maybe<Rental_Liabilities_Var_Pop_Order_By>
  var_samp?: Maybe<Rental_Liabilities_Var_Samp_Order_By>
  variance?: Maybe<Rental_Liabilities_Variance_Order_By>
}

/** order by avg() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Avg_Order_By = {
  amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rental_liabilities". All fields are combined with a logical 'AND'. */
export type Rental_Liabilities_Bool_Exp = {
  _and?: Maybe<Array<Rental_Liabilities_Bool_Exp>>
  _not?: Maybe<Rental_Liabilities_Bool_Exp>
  _or?: Maybe<Array<Rental_Liabilities_Bool_Exp>>
  amount?: Maybe<Numeric_Comparison_Exp>
  date?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  liability_type?: Maybe<Liability_Types_Bool_Exp>
  rental?: Maybe<Rentals_Bool_Exp>
  rental_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "rental_liabilities" */
export enum Rental_Liabilities_Constraint {
  /** unique or primary key constraint on columns "id" */
  RentalLiabiltiesPkey = 'rental_liabilties_pkey'
}

/** input type for inserting data into table "rental_liabilities" */
export type Rental_Liabilities_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>
  date?: Maybe<Scalars['date']>
  liability_type_id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  rental_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Max_Order_By = {
  amount?: Maybe<Order_By>
  date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Min_Order_By = {
  amount?: Maybe<Order_By>
  date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
}

/** response of any mutation on the table "rental_liabilities" */
export type Rental_Liabilities_Mutation_Response = {
  __typename: 'rental_liabilities_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rental_Liabilities>
}

/** on_conflict condition type for table "rental_liabilities" */
export type Rental_Liabilities_On_Conflict = {
  constraint: Rental_Liabilities_Constraint
  update_columns?: Array<Rental_Liabilities_Update_Column>
  where?: Maybe<Rental_Liabilities_Bool_Exp>
}

/** Ordering options when selecting data from "rental_liabilities". */
export type Rental_Liabilities_Order_By = {
  amount?: Maybe<Order_By>
  date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  liability_type?: Maybe<Liability_Types_Order_By>
  rental?: Maybe<Rentals_Order_By>
  rental_id?: Maybe<Order_By>
}

/** select columns of table "rental_liabilities" */
export enum Rental_Liabilities_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  RentalId = 'rental_id'
}

/** order by stddev() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Stddev_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by sum() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Sum_Order_By = {
  amount?: Maybe<Order_By>
}

/** placeholder for update columns of table "rental_liabilities" (current role has no relevant permissions) */
export enum Rental_Liabilities_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** order by variance() on columns of table "rental_liabilities" */
export type Rental_Liabilities_Variance_Order_By = {
  amount?: Maybe<Order_By>
}

/** columns and relationships of "rental_site_accounts" */
export type Rental_Site_Accounts = {
  __typename: 'rental_site_accounts'
  created_at: Scalars['timestamptz']
  deactivated_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  feed_format: Scalars['String']
  feed_specification?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  max_listings?: Maybe<Scalars['Int']>
  password?: Maybe<Scalars['String']>
  source: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** Boolean expression to filter rows from the table "rental_site_accounts". All fields are combined with a logical 'AND'. */
export type Rental_Site_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Rental_Site_Accounts_Bool_Exp>>
  _not?: Maybe<Rental_Site_Accounts_Bool_Exp>
  _or?: Maybe<Array<Rental_Site_Accounts_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  feed_format?: Maybe<String_Comparison_Exp>
  feed_specification?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  max_listings?: Maybe<Int_Comparison_Exp>
  password?: Maybe<String_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "rental_site_accounts". */
export type Rental_Site_Accounts_Order_By = {
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  feed_format?: Maybe<Order_By>
  feed_specification?: Maybe<Order_By>
  id?: Maybe<Order_By>
  max_listings?: Maybe<Order_By>
  password?: Maybe<Order_By>
  source?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "rental_site_accounts" */
export enum Rental_Site_Accounts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Email = 'email',
  /** column name */
  FeedFormat = 'feed_format',
  /** column name */
  FeedSpecification = 'feed_specification',
  /** column name */
  Id = 'id',
  /** column name */
  MaxListings = 'max_listings',
  /** column name */
  Password = 'password',
  /** column name */
  Source = 'source',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "rental_site_leads" */
export type Rental_Site_Leads = {
  __typename: 'rental_site_leads'
  created_at: Scalars['timestamptz']
  email?: Maybe<Scalars['String']>
  employer?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  is_smoker?: Maybe<Scalars['Boolean']>
  last_name?: Maybe<Scalars['String']>
  lead_comments?: Maybe<Scalars['String']>
  lease_length_in_months?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['jsonb']>
  number_of_bathrooms?: Maybe<Scalars['numeric']>
  number_of_bedrooms?: Maybe<Scalars['Int']>
  number_of_occupants?: Maybe<Scalars['Int']>
  occupancy_date?: Maybe<Scalars['date']>
  parking_type?: Maybe<Scalars['String']>
  pet_details?: Maybe<Scalars['_jsonb']>
  phone?: Maybe<Scalars['String']>
  reason_for_moving?: Maybe<Scalars['String']>
  rent?: Maybe<Scalars['numeric']>
  /** An object relationship */
  rental_site_listing: Rental_Site_Listings
  rental_site_listing_id: Scalars['uuid']
  self_reported_annual_income?: Maybe<Scalars['numeric']>
  self_reported_credit?: Maybe<Scalars['jsonb']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<Users>
  user_id?: Maybe<Scalars['uuid']>
}

/** columns and relationships of "rental_site_leads" */
export type Rental_Site_LeadsMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "rental_site_leads" */
export type Rental_Site_LeadsSelf_Reported_CreditArgs = {
  path?: Maybe<Scalars['String']>
}

/** order by aggregate values of table "rental_site_leads" */
export type Rental_Site_Leads_Aggregate_Order_By = {
  avg?: Maybe<Rental_Site_Leads_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Site_Leads_Max_Order_By>
  min?: Maybe<Rental_Site_Leads_Min_Order_By>
  stddev?: Maybe<Rental_Site_Leads_Stddev_Order_By>
  stddev_pop?: Maybe<Rental_Site_Leads_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rental_Site_Leads_Stddev_Samp_Order_By>
  sum?: Maybe<Rental_Site_Leads_Sum_Order_By>
  var_pop?: Maybe<Rental_Site_Leads_Var_Pop_Order_By>
  var_samp?: Maybe<Rental_Site_Leads_Var_Samp_Order_By>
  variance?: Maybe<Rental_Site_Leads_Variance_Order_By>
}

/** order by avg() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Avg_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rental_site_leads". All fields are combined with a logical 'AND'. */
export type Rental_Site_Leads_Bool_Exp = {
  _and?: Maybe<Array<Rental_Site_Leads_Bool_Exp>>
  _not?: Maybe<Rental_Site_Leads_Bool_Exp>
  _or?: Maybe<Array<Rental_Site_Leads_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  employer?: Maybe<String_Comparison_Exp>
  first_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_smoker?: Maybe<Boolean_Comparison_Exp>
  last_name?: Maybe<String_Comparison_Exp>
  lead_comments?: Maybe<String_Comparison_Exp>
  lease_length_in_months?: Maybe<Int_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  number_of_bathrooms?: Maybe<Numeric_Comparison_Exp>
  number_of_bedrooms?: Maybe<Int_Comparison_Exp>
  number_of_occupants?: Maybe<Int_Comparison_Exp>
  occupancy_date?: Maybe<Date_Comparison_Exp>
  parking_type?: Maybe<String_Comparison_Exp>
  pet_details?: Maybe<_Jsonb_Comparison_Exp>
  phone?: Maybe<String_Comparison_Exp>
  reason_for_moving?: Maybe<String_Comparison_Exp>
  rent?: Maybe<Numeric_Comparison_Exp>
  rental_site_listing?: Maybe<Rental_Site_Listings_Bool_Exp>
  rental_site_listing_id?: Maybe<Uuid_Comparison_Exp>
  self_reported_annual_income?: Maybe<Numeric_Comparison_Exp>
  self_reported_credit?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Max_Order_By = {
  created_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  employer?: Maybe<Order_By>
  first_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  lead_comments?: Maybe<Order_By>
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  occupancy_date?: Maybe<Order_By>
  parking_type?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  reason_for_moving?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  rental_site_listing_id?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Min_Order_By = {
  created_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  employer?: Maybe<Order_By>
  first_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  lead_comments?: Maybe<Order_By>
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  occupancy_date?: Maybe<Order_By>
  parking_type?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  reason_for_moving?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  rental_site_listing_id?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "rental_site_leads". */
export type Rental_Site_Leads_Order_By = {
  created_at?: Maybe<Order_By>
  email?: Maybe<Order_By>
  employer?: Maybe<Order_By>
  first_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_smoker?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  lead_comments?: Maybe<Order_By>
  lease_length_in_months?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  occupancy_date?: Maybe<Order_By>
  parking_type?: Maybe<Order_By>
  pet_details?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  reason_for_moving?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  rental_site_listing?: Maybe<Rental_Site_Listings_Order_By>
  rental_site_listing_id?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
  self_reported_credit?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "rental_site_leads" */
export enum Rental_Site_Leads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Employer = 'employer',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsSmoker = 'is_smoker',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LeadComments = 'lead_comments',
  /** column name */
  LeaseLengthInMonths = 'lease_length_in_months',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NumberOfBathrooms = 'number_of_bathrooms',
  /** column name */
  NumberOfBedrooms = 'number_of_bedrooms',
  /** column name */
  NumberOfOccupants = 'number_of_occupants',
  /** column name */
  OccupancyDate = 'occupancy_date',
  /** column name */
  ParkingType = 'parking_type',
  /** column name */
  PetDetails = 'pet_details',
  /** column name */
  Phone = 'phone',
  /** column name */
  ReasonForMoving = 'reason_for_moving',
  /** column name */
  Rent = 'rent',
  /** column name */
  RentalSiteListingId = 'rental_site_listing_id',
  /** column name */
  SelfReportedAnnualIncome = 'self_reported_annual_income',
  /** column name */
  SelfReportedCredit = 'self_reported_credit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Stddev_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Stddev_Pop_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Stddev_Samp_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** order by sum() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Sum_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Var_Pop_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Var_Samp_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** order by variance() on columns of table "rental_site_leads" */
export type Rental_Site_Leads_Variance_Order_By = {
  lease_length_in_months?: Maybe<Order_By>
  number_of_bathrooms?: Maybe<Order_By>
  number_of_bedrooms?: Maybe<Order_By>
  number_of_occupants?: Maybe<Order_By>
  rent?: Maybe<Order_By>
  self_reported_annual_income?: Maybe<Order_By>
}

/** columns and relationships of "rental_site_listings" */
export type Rental_Site_Listings = {
  __typename: 'rental_site_listings'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  is_active: Scalars['Boolean']
  /** An object relationship */
  mls_listing: Mls_Listings
  mls_listing_id: Scalars['uuid']
  /** An object relationship */
  rental_site_account: Rental_Site_Accounts
  rental_site_account_id: Scalars['uuid']
  source_listing_id: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** Boolean expression to filter rows from the table "rental_site_listings". All fields are combined with a logical 'AND'. */
export type Rental_Site_Listings_Bool_Exp = {
  _and?: Maybe<Array<Rental_Site_Listings_Bool_Exp>>
  _not?: Maybe<Rental_Site_Listings_Bool_Exp>
  _or?: Maybe<Array<Rental_Site_Listings_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_active?: Maybe<Boolean_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  mls_listing_id?: Maybe<Uuid_Comparison_Exp>
  rental_site_account?: Maybe<Rental_Site_Accounts_Bool_Exp>
  rental_site_account_id?: Maybe<Uuid_Comparison_Exp>
  source_listing_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "rental_site_listings". */
export type Rental_Site_Listings_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_active?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  mls_listing_id?: Maybe<Order_By>
  rental_site_account?: Maybe<Rental_Site_Accounts_Order_By>
  rental_site_account_id?: Maybe<Order_By>
  source_listing_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "rental_site_listings" */
export enum Rental_Site_Listings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MlsListingId = 'mls_listing_id',
  /** column name */
  RentalSiteAccountId = 'rental_site_account_id',
  /** column name */
  SourceListingId = 'source_listing_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "rental_users" */
export type Rental_Users = {
  __typename: 'rental_users'
  auto_charge_disabled_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  deactivated_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  rental: Rentals
  rental_id: Scalars['uuid']
  role: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** order by aggregate values of table "rental_users" */
export type Rental_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Rental_Users_Max_Order_By>
  min?: Maybe<Rental_Users_Min_Order_By>
}

/** Boolean expression to filter rows from the table "rental_users". All fields are combined with a logical 'AND'. */
export type Rental_Users_Bool_Exp = {
  _and?: Maybe<Array<Rental_Users_Bool_Exp>>
  _not?: Maybe<Rental_Users_Bool_Exp>
  _or?: Maybe<Array<Rental_Users_Bool_Exp>>
  auto_charge_disabled_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  rental?: Maybe<Rentals_Bool_Exp>
  rental_id?: Maybe<Uuid_Comparison_Exp>
  role?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "rental_users" */
export type Rental_Users_Max_Order_By = {
  auto_charge_disabled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  role?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "rental_users" */
export type Rental_Users_Min_Order_By = {
  auto_charge_disabled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  rental_id?: Maybe<Order_By>
  role?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "rental_users". */
export type Rental_Users_Order_By = {
  auto_charge_disabled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  rental?: Maybe<Rentals_Order_By>
  rental_id?: Maybe<Order_By>
  role?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "rental_users" */
export enum Rental_Users_Select_Column {
  /** column name */
  AutoChargeDisabledAt = 'auto_charge_disabled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  Id = 'id',
  /** column name */
  RentalId = 'rental_id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "rentals" */
export type Rentals = {
  __typename: 'rentals'
  auto_charge: Scalars['Boolean']
  created_at?: Maybe<Scalars['timestamptz']>
  final_liability_date?: Maybe<Scalars['date']>
  id: Scalars['uuid']
  initial_lease_term: Scalars['Int']
  initial_option_premium: Scalars['Int']
  is_card_payment_allowed: Scalars['Boolean']
  lease_end_reason?: Maybe<Scalars['String']>
  minimum_monthly_option_premium: Scalars['numeric']
  move_out_date?: Maybe<Scalars['date']>
  occupancy_date: Scalars['date']
  /** An array relationship */
  payments: Array<Payments>
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate
  /** An object relationship */
  property: Properties
  property_id: Scalars['uuid']
  /** An array relationship */
  rental_add_ons: Array<Rental_Add_Ons>
  /** An array relationship */
  rental_agreement_histories: Array<Rental_Agreement_Histories>
  /** An object relationship */
  rental_application: Rental_Applications
  /** An array relationship */
  rental_credits: Array<Rental_Credits>
  /** An array relationship */
  rental_liabilities: Array<Rental_Liabilities>
  /** An array relationship */
  rental_users: Array<Rental_Users>
  status: Scalars['String']
  target_monthly_option_premium: Scalars['Int']
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** columns and relationships of "rentals" */
export type RentalsPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

/** columns and relationships of "rentals" */
export type RentalsPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

/** columns and relationships of "rentals" */
export type RentalsRental_Add_OnsArgs = {
  distinct_on?: Maybe<Array<Rental_Add_Ons_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Add_Ons_Order_By>>
  where?: Maybe<Rental_Add_Ons_Bool_Exp>
}

/** columns and relationships of "rentals" */
export type RentalsRental_Agreement_HistoriesArgs = {
  distinct_on?: Maybe<Array<Rental_Agreement_Histories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Agreement_Histories_Order_By>>
  where?: Maybe<Rental_Agreement_Histories_Bool_Exp>
}

/** columns and relationships of "rentals" */
export type RentalsRental_CreditsArgs = {
  distinct_on?: Maybe<Array<Rental_Credits_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Credits_Order_By>>
  where?: Maybe<Rental_Credits_Bool_Exp>
}

/** columns and relationships of "rentals" */
export type RentalsRental_LiabilitiesArgs = {
  distinct_on?: Maybe<Array<Rental_Liabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Liabilities_Order_By>>
  where?: Maybe<Rental_Liabilities_Bool_Exp>
}

/** columns and relationships of "rentals" */
export type RentalsRental_UsersArgs = {
  distinct_on?: Maybe<Array<Rental_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Users_Order_By>>
  where?: Maybe<Rental_Users_Bool_Exp>
}

/** order by aggregate values of table "rentals" */
export type Rentals_Aggregate_Order_By = {
  avg?: Maybe<Rentals_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Rentals_Max_Order_By>
  min?: Maybe<Rentals_Min_Order_By>
  stddev?: Maybe<Rentals_Stddev_Order_By>
  stddev_pop?: Maybe<Rentals_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Rentals_Stddev_Samp_Order_By>
  sum?: Maybe<Rentals_Sum_Order_By>
  var_pop?: Maybe<Rentals_Var_Pop_Order_By>
  var_samp?: Maybe<Rentals_Var_Samp_Order_By>
  variance?: Maybe<Rentals_Variance_Order_By>
}

/** order by avg() on columns of table "rentals" */
export type Rentals_Avg_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "rentals". All fields are combined with a logical 'AND'. */
export type Rentals_Bool_Exp = {
  _and?: Maybe<Array<Rentals_Bool_Exp>>
  _not?: Maybe<Rentals_Bool_Exp>
  _or?: Maybe<Array<Rentals_Bool_Exp>>
  auto_charge?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  final_liability_date?: Maybe<Date_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  initial_lease_term?: Maybe<Int_Comparison_Exp>
  initial_option_premium?: Maybe<Int_Comparison_Exp>
  is_card_payment_allowed?: Maybe<Boolean_Comparison_Exp>
  lease_end_reason?: Maybe<String_Comparison_Exp>
  minimum_monthly_option_premium?: Maybe<Numeric_Comparison_Exp>
  move_out_date?: Maybe<Date_Comparison_Exp>
  occupancy_date?: Maybe<Date_Comparison_Exp>
  payments?: Maybe<Payments_Bool_Exp>
  property?: Maybe<Properties_Bool_Exp>
  property_id?: Maybe<Uuid_Comparison_Exp>
  rental_add_ons?: Maybe<Rental_Add_Ons_Bool_Exp>
  rental_agreement_histories?: Maybe<Rental_Agreement_Histories_Bool_Exp>
  rental_application?: Maybe<Rental_Applications_Bool_Exp>
  rental_credits?: Maybe<Rental_Credits_Bool_Exp>
  rental_liabilities?: Maybe<Rental_Liabilities_Bool_Exp>
  rental_users?: Maybe<Rental_Users_Bool_Exp>
  status?: Maybe<String_Comparison_Exp>
  target_monthly_option_premium?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "rentals" */
export type Rentals_Max_Order_By = {
  created_at?: Maybe<Order_By>
  final_liability_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  lease_end_reason?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  move_out_date?: Maybe<Order_By>
  occupancy_date?: Maybe<Order_By>
  property_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "rentals" */
export type Rentals_Min_Order_By = {
  created_at?: Maybe<Order_By>
  final_liability_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  lease_end_reason?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  move_out_date?: Maybe<Order_By>
  occupancy_date?: Maybe<Order_By>
  property_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "rentals" */
export type Rentals_Mutation_Response = {
  __typename: 'rentals_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rentals>
}

/** Ordering options when selecting data from "rentals". */
export type Rentals_Order_By = {
  auto_charge?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  final_liability_date?: Maybe<Order_By>
  id?: Maybe<Order_By>
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  is_card_payment_allowed?: Maybe<Order_By>
  lease_end_reason?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  move_out_date?: Maybe<Order_By>
  occupancy_date?: Maybe<Order_By>
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>
  property?: Maybe<Properties_Order_By>
  property_id?: Maybe<Order_By>
  rental_add_ons_aggregate?: Maybe<Rental_Add_Ons_Aggregate_Order_By>
  rental_agreement_histories_aggregate?: Maybe<Rental_Agreement_Histories_Aggregate_Order_By>
  rental_application?: Maybe<Rental_Applications_Order_By>
  rental_credits_aggregate?: Maybe<Rental_Credits_Aggregate_Order_By>
  rental_liabilities_aggregate?: Maybe<Rental_Liabilities_Aggregate_Order_By>
  rental_users_aggregate?: Maybe<Rental_Users_Aggregate_Order_By>
  status?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: rentals */
export type Rentals_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "rentals" */
export enum Rentals_Select_Column {
  /** column name */
  AutoCharge = 'auto_charge',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinalLiabilityDate = 'final_liability_date',
  /** column name */
  Id = 'id',
  /** column name */
  InitialLeaseTerm = 'initial_lease_term',
  /** column name */
  InitialOptionPremium = 'initial_option_premium',
  /** column name */
  IsCardPaymentAllowed = 'is_card_payment_allowed',
  /** column name */
  LeaseEndReason = 'lease_end_reason',
  /** column name */
  MinimumMonthlyOptionPremium = 'minimum_monthly_option_premium',
  /** column name */
  MoveOutDate = 'move_out_date',
  /** column name */
  OccupancyDate = 'occupancy_date',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Status = 'status',
  /** column name */
  TargetMonthlyOptionPremium = 'target_monthly_option_premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "rentals" */
export type Rentals_Set_Input = {
  auto_charge?: Maybe<Scalars['Boolean']>
  final_liability_date?: Maybe<Scalars['date']>
  is_card_payment_allowed?: Maybe<Scalars['Boolean']>
  lease_end_reason?: Maybe<Scalars['String']>
  move_out_date?: Maybe<Scalars['date']>
  status?: Maybe<Scalars['String']>
}

/** order by stddev() on columns of table "rentals" */
export type Rentals_Stddev_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "rentals" */
export type Rentals_Stddev_Pop_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "rentals" */
export type Rentals_Stddev_Samp_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** order by sum() on columns of table "rentals" */
export type Rentals_Sum_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

export type Rentals_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rentals_Set_Input>
  where: Rentals_Bool_Exp
}

/** order by var_pop() on columns of table "rentals" */
export type Rentals_Var_Pop_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "rentals" */
export type Rentals_Var_Samp_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** order by variance() on columns of table "rentals" */
export type Rentals_Variance_Order_By = {
  initial_lease_term?: Maybe<Order_By>
  initial_option_premium?: Maybe<Order_By>
  minimum_monthly_option_premium?: Maybe<Order_By>
  target_monthly_option_premium?: Maybe<Order_By>
}

/** columns and relationships of "seller_agent_searchable" */
export type Seller_Agent_Searchable = {
  __typename: 'seller_agent_searchable'
  contact?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  info?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** An object relationship */
  seller_agent?: Maybe<Seller_Agents>
}

/** Boolean expression to filter rows from the table "seller_agent_searchable". All fields are combined with a logical 'AND'. */
export type Seller_Agent_Searchable_Bool_Exp = {
  _and?: Maybe<Array<Seller_Agent_Searchable_Bool_Exp>>
  _not?: Maybe<Seller_Agent_Searchable_Bool_Exp>
  _or?: Maybe<Array<Seller_Agent_Searchable_Bool_Exp>>
  contact?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  info?: Maybe<String_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  seller_agent?: Maybe<Seller_Agents_Bool_Exp>
}

/** Ordering options when selecting data from "seller_agent_searchable". */
export type Seller_Agent_Searchable_Order_By = {
  contact?: Maybe<Order_By>
  id?: Maybe<Order_By>
  info?: Maybe<Order_By>
  name?: Maybe<Order_By>
  seller_agent?: Maybe<Seller_Agents_Order_By>
}

/** select columns of table "seller_agent_searchable" */
export enum Seller_Agent_Searchable_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "seller_agents" */
export type Seller_Agents = {
  __typename: 'seller_agents'
  close_lead_id?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  do_not_contact?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  first_name: Scalars['String']
  id: Scalars['uuid']
  last_contacted_at?: Maybe<Scalars['timestamptz']>
  last_name: Scalars['String']
  mls: Scalars['String']
  /** An array relationship */
  mlsListingsByCoListingAgentId: Array<Mls_Listings>
  mls_id: Scalars['String']
  /** An array relationship */
  mls_listings: Array<Mls_Listings>
  permission_for_all_postings?: Maybe<Scalars['Boolean']>
  phone?: Maybe<Scalars['String']>
  /** An object relationship */
  seller_agent_searchable?: Maybe<Seller_Agent_Searchable>
  seller_office_id?: Maybe<Scalars['uuid']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "seller_agents" */
export type Seller_AgentsMlsListingsByCoListingAgentIdArgs = {
  distinct_on?: Maybe<Array<Mls_Listings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listings_Order_By>>
  where?: Maybe<Mls_Listings_Bool_Exp>
}

/** columns and relationships of "seller_agents" */
export type Seller_AgentsMls_ListingsArgs = {
  distinct_on?: Maybe<Array<Mls_Listings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listings_Order_By>>
  where?: Maybe<Mls_Listings_Bool_Exp>
}

/** Boolean expression to filter rows from the table "seller_agents". All fields are combined with a logical 'AND'. */
export type Seller_Agents_Bool_Exp = {
  _and?: Maybe<Array<Seller_Agents_Bool_Exp>>
  _not?: Maybe<Seller_Agents_Bool_Exp>
  _or?: Maybe<Array<Seller_Agents_Bool_Exp>>
  close_lead_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  do_not_contact?: Maybe<Boolean_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  first_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_contacted_at?: Maybe<Timestamptz_Comparison_Exp>
  last_name?: Maybe<String_Comparison_Exp>
  mls?: Maybe<String_Comparison_Exp>
  mlsListingsByCoListingAgentId?: Maybe<Mls_Listings_Bool_Exp>
  mls_id?: Maybe<String_Comparison_Exp>
  mls_listings?: Maybe<Mls_Listings_Bool_Exp>
  permission_for_all_postings?: Maybe<Boolean_Comparison_Exp>
  phone?: Maybe<String_Comparison_Exp>
  seller_agent_searchable?: Maybe<Seller_Agent_Searchable_Bool_Exp>
  seller_office_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Ordering options when selecting data from "seller_agents". */
export type Seller_Agents_Order_By = {
  close_lead_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  do_not_contact?: Maybe<Order_By>
  email?: Maybe<Order_By>
  first_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_contacted_at?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  mls?: Maybe<Order_By>
  mlsListingsByCoListingAgentId_aggregate?: Maybe<Mls_Listings_Aggregate_Order_By>
  mls_id?: Maybe<Order_By>
  mls_listings_aggregate?: Maybe<Mls_Listings_Aggregate_Order_By>
  permission_for_all_postings?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  seller_agent_searchable?: Maybe<Seller_Agent_Searchable_Order_By>
  seller_office_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "seller_agents" */
export enum Seller_Agents_Select_Column {
  /** column name */
  CloseLeadId = 'close_lead_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DoNotContact = 'do_not_contact',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastContactedAt = 'last_contacted_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mls = 'mls',
  /** column name */
  MlsId = 'mls_id',
  /** column name */
  PermissionForAllPostings = 'permission_for_all_postings',
  /** column name */
  Phone = 'phone',
  /** column name */
  SellerOfficeId = 'seller_office_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float']
  from: Scalars['geography']
  use_spheroid?: Maybe<Scalars['Boolean']>
}

export type St_D_Within_Input = {
  distance: Scalars['Float']
  from: Scalars['geometry']
}

export type Subscription_Root = {
  __typename: 'subscription_root'
  /** An array relationship */
  admin_capabilities: Array<Admin_Capabilities>
  /** fetch data from the table: "admin_capabilities" using primary key columns */
  admin_capabilities_by_pk?: Maybe<Admin_Capabilities>
  /** fetch data from the table: "admins" */
  admins: Array<Admins>
  /** fetch data from the table: "admins" using primary key columns */
  admins_by_pk?: Maybe<Admins>
  /** fetch data from the table: "agent_user_owners" */
  agent_user_owners: Array<Agent_User_Owners>
  /** fetch data from the table: "agent_user_owners" using primary key columns */
  agent_user_owners_by_pk?: Maybe<Agent_User_Owners>
  /** fetch data from the table: "agents" */
  agents: Array<Agents>
  /** fetch data from the table: "agents" using primary key columns */
  agents_by_pk?: Maybe<Agents>
  /** An array relationship */
  agreement_signers: Array<Agreement_Signers>
  /** fetch data from the table: "agreement_signers" using primary key columns */
  agreement_signers_by_pk?: Maybe<Agreement_Signers>
  /** An array relationship */
  agreements: Array<Agreements>
  /** fetch data from the table: "agreements" using primary key columns */
  agreements_by_pk?: Maybe<Agreements>
  /** fetch data from the table: "capabilities" */
  capabilities: Array<Capabilities>
  /** fetch data from the table: "capabilities" using primary key columns */
  capabilities_by_pk?: Maybe<Capabilities>
  /** An array relationship */
  construction_projects: Array<Construction_Projects>
  /** fetch data from the table: "construction_projects" using primary key columns */
  construction_projects_by_pk?: Maybe<Construction_Projects>
  /** An array relationship */
  conversations: Array<Conversations>
  /** fetch data from the table: "conversations" using primary key columns */
  conversations_by_pk?: Maybe<Conversations>
  /** An array relationship */
  credit_reports: Array<Credit_Reports>
  /** fetch data from the table: "credit_reports" using primary key columns */
  credit_reports_by_pk?: Maybe<Credit_Reports>
  /** An array relationship */
  criminal_reports: Array<Criminal_Reports>
  /** fetch data from the table: "criminal_reports" using primary key columns */
  criminal_reports_by_pk?: Maybe<Criminal_Reports>
  /** An array relationship */
  documents: Array<Documents>
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>
  /** An array relationship */
  eviction_reports: Array<Eviction_Reports>
  /** fetch data from the table: "eviction_reports" using primary key columns */
  eviction_reports_by_pk?: Maybe<Eviction_Reports>
  /** fetch data from the table: "fund_llcs" */
  fund_llcs: Array<Fund_Llcs>
  /** fetch data from the table: "fund_llcs" using primary key columns */
  fund_llcs_by_pk?: Maybe<Fund_Llcs>
  /** fetch data from the table: "funds" */
  funds: Array<Funds>
  /** fetch data from the table: "funds" using primary key columns */
  funds_by_pk?: Maybe<Funds>
  /** fetch data from the table: "kafka_connector.prorated_utility_bill_line_items" */
  kafka_connector_prorated_utility_bill_line_items: Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  /** fetch data from the table: "kafka_connector.prorated_utility_bill_line_items" using primary key columns */
  kafka_connector_prorated_utility_bill_line_items_by_pk?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items>
  /** fetch data from the table: "lead_group_rental_application_notes" */
  lead_group_rental_application_notes: Array<Lead_Group_Rental_Application_Notes>
  /** An array relationship */
  lead_group_users: Array<Lead_Group_Users>
  /** fetch data from the table: "lead_group_users" using primary key columns */
  lead_group_users_by_pk?: Maybe<Lead_Group_Users>
  /** fetch data from the table: "lead_groups" */
  lead_groups: Array<Lead_Groups>
  /** fetch data from the table: "lead_groups" using primary key columns */
  lead_groups_by_pk?: Maybe<Lead_Groups>
  /** fetch data from the table: "ledger_accounts" */
  ledger_accounts: Array<Ledger_Accounts>
  /** fetch data from the table: "ledger_accounts" using primary key columns */
  ledger_accounts_by_pk?: Maybe<Ledger_Accounts>
  /** fetch data from the table: "liability_types" */
  liability_types: Array<Liability_Types>
  /** fetch data from the table: "liability_types" using primary key columns */
  liability_types_by_pk?: Maybe<Liability_Types>
  /** An array relationship */
  llc_properties: Array<Llc_Properties>
  /** fetch data from the table: "llc_properties" using primary key columns */
  llc_properties_by_pk?: Maybe<Llc_Properties>
  /** fetch data from the table: "llcs" */
  llcs: Array<Llcs>
  /** fetch data from the table: "llcs" using primary key columns */
  llcs_by_pk?: Maybe<Llcs>
  /** fetch data from the table: "markets" */
  markets: Array<Markets>
  /** fetch data from the table: "markets" using primary key columns */
  markets_by_pk?: Maybe<Markets>
  /** fetch data from the table: "mls_listing_internal_attributes" */
  mls_listing_internal_attributes: Array<Mls_Listing_Internal_Attributes>
  /** fetch data from the table: "mls_listing_internal_attributes" using primary key columns */
  mls_listing_internal_attributes_by_pk?: Maybe<Mls_Listing_Internal_Attributes>
  /** fetch data from the table: "mls_listing_photos" */
  mls_listing_photos: Array<Mls_Listing_Photos>
  /** fetch data from the table: "mls_listing_photos" using primary key columns */
  mls_listing_photos_by_pk?: Maybe<Mls_Listing_Photos>
  /** An array relationship */
  mls_listing_promotions: Array<Mls_Listing_Promotions>
  /** fetch data from the table: "mls_listing_promotions" using primary key columns */
  mls_listing_promotions_by_pk?: Maybe<Mls_Listing_Promotions>
  /** An array relationship */
  mls_listings: Array<Mls_Listings>
  /** fetch data from the table: "mls_listings" using primary key columns */
  mls_listings_by_pk?: Maybe<Mls_Listings>
  /** An array relationship */
  notes: Array<Notes>
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>
  /** An array relationship */
  payment_methods: Array<Payment_Methods>
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>
  /** An array relationship */
  payments: Array<Payments>
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>
  /** fetch data from the table: "plaid_income_verification_documents" */
  plaid_income_verification_documents: Array<Plaid_Income_Verification_Documents>
  /** fetch data from the table: "plaid_income_verifications" */
  plaid_income_verifications: Array<Plaid_Income_Verifications>
  /** fetch data from the table: "plaid_income_verifications" using primary key columns */
  plaid_income_verifications_by_pk?: Maybe<Plaid_Income_Verifications>
  /** fetch data from the table: "properties" */
  properties: Array<Properties>
  /** fetch data from the table: "properties" using primary key columns */
  properties_by_pk?: Maybe<Properties>
  /** An array relationship */
  real_estate_acquisitions: Array<Real_Estate_Acquisitions>
  /** fetch data from the table: "real_estate_acquisitions" using primary key columns */
  real_estate_acquisitions_by_pk?: Maybe<Real_Estate_Acquisitions>
  /** An array relationship */
  rent_prices: Array<Rent_Prices>
  /** fetch data from the table: "rent_prices" using primary key columns */
  rent_prices_by_pk?: Maybe<Rent_Prices>
  /** An array relationship */
  rent_ready_dates: Array<Rent_Ready_Dates>
  /** fetch data from the table: "rent_ready_dates" using primary key columns */
  rent_ready_dates_by_pk?: Maybe<Rent_Ready_Dates>
  /** An array relationship */
  rental_add_ons: Array<Rental_Add_Ons>
  /** fetch data from the table: "rental_add_ons" using primary key columns */
  rental_add_ons_by_pk?: Maybe<Rental_Add_Ons>
  /** An array relationship */
  rental_agreement_histories: Array<Rental_Agreement_Histories>
  /** fetch data from the table: "rental_agreement_histories" using primary key columns */
  rental_agreement_histories_by_pk?: Maybe<Rental_Agreement_Histories>
  /** An array relationship */
  rental_applications: Array<Rental_Applications>
  /** fetch data from the table: "rental_applications" using primary key columns */
  rental_applications_by_pk?: Maybe<Rental_Applications>
  /** An array relationship */
  rental_credits: Array<Rental_Credits>
  /** fetch data from the table: "rental_credits" using primary key columns */
  rental_credits_by_pk?: Maybe<Rental_Credits>
  /** An array relationship */
  rental_liabilities: Array<Rental_Liabilities>
  /** fetch data from the table: "rental_liabilities" using primary key columns */
  rental_liabilities_by_pk?: Maybe<Rental_Liabilities>
  /** fetch data from the table: "rental_site_accounts" */
  rental_site_accounts: Array<Rental_Site_Accounts>
  /** fetch data from the table: "rental_site_accounts" using primary key columns */
  rental_site_accounts_by_pk?: Maybe<Rental_Site_Accounts>
  /** An array relationship */
  rental_site_leads: Array<Rental_Site_Leads>
  /** fetch data from the table: "rental_site_leads" using primary key columns */
  rental_site_leads_by_pk?: Maybe<Rental_Site_Leads>
  /** fetch data from the table: "rental_site_listings" */
  rental_site_listings: Array<Rental_Site_Listings>
  /** fetch data from the table: "rental_site_listings" using primary key columns */
  rental_site_listings_by_pk?: Maybe<Rental_Site_Listings>
  /** An array relationship */
  rental_users: Array<Rental_Users>
  /** fetch data from the table: "rental_users" using primary key columns */
  rental_users_by_pk?: Maybe<Rental_Users>
  /** An array relationship */
  rentals: Array<Rentals>
  /** fetch data from the table: "rentals" using primary key columns */
  rentals_by_pk?: Maybe<Rentals>
  /** fetch data from the table: "seller_agent_searchable" */
  seller_agent_searchable: Array<Seller_Agent_Searchable>
  /** fetch data from the table: "seller_agents" */
  seller_agents: Array<Seller_Agents>
  /** fetch data from the table: "seller_agents" using primary key columns */
  seller_agents_by_pk?: Maybe<Seller_Agents>
  /** An array relationship */
  tour_properties: Array<Tour_Properties>
  /** fetch data from the table: "tour_properties" using primary key columns */
  tour_properties_by_pk?: Maybe<Tour_Properties>
  /** An array relationship */
  tours: Array<Tours>
  /** fetch data from the table: "tours" using primary key columns */
  tours_by_pk?: Maybe<Tours>
  /** An array relationship */
  user_contact_details: Array<User_Contact_Details>
  /** fetch data from the table: "user_contact_details" using primary key columns */
  user_contact_details_by_pk?: Maybe<User_Contact_Details>
  /** fetch data from the table: "user_documents" */
  user_documents: Array<User_Documents>
  /** fetch data from the table: "user_funnel_statuses" */
  user_funnel_statuses: Array<User_Funnel_Statuses>
  /** fetch data from the table: "user_funnel_statuses" using primary key columns */
  user_funnel_statuses_by_pk?: Maybe<User_Funnel_Statuses>
  /** An array relationship */
  user_incomes: Array<User_Incomes>
  /** fetch data from the table: "user_incomes" using primary key columns */
  user_incomes_by_pk?: Maybe<User_Incomes>
  /** fetch data from the table: "user_prequalification_data" */
  user_prequalification_data: Array<User_Prequalification_Data>
  /** fetch data from the table: "user_prequalification_data" using primary key columns */
  user_prequalification_data_by_pk?: Maybe<User_Prequalification_Data>
  /** fetch data from the table: "user_searchable" */
  user_searchable: Array<User_Searchable>
  /** fetch data from the table: "users" */
  users: Array<Users>
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>
}

export type Subscription_RootAdmin_CapabilitiesArgs = {
  distinct_on?: Maybe<Array<Admin_Capabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Admin_Capabilities_Order_By>>
  where?: Maybe<Admin_Capabilities_Bool_Exp>
}

export type Subscription_RootAdmin_Capabilities_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAdminsArgs = {
  distinct_on?: Maybe<Array<Admins_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Admins_Order_By>>
  where?: Maybe<Admins_Bool_Exp>
}

export type Subscription_RootAdmins_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAgent_User_OwnersArgs = {
  distinct_on?: Maybe<Array<Agent_User_Owners_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agent_User_Owners_Order_By>>
  where?: Maybe<Agent_User_Owners_Bool_Exp>
}

export type Subscription_RootAgent_User_Owners_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAgentsArgs = {
  distinct_on?: Maybe<Array<Agents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agents_Order_By>>
  where?: Maybe<Agents_Bool_Exp>
}

export type Subscription_RootAgents_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAgreement_SignersArgs = {
  distinct_on?: Maybe<Array<Agreement_Signers_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agreement_Signers_Order_By>>
  where?: Maybe<Agreement_Signers_Bool_Exp>
}

export type Subscription_RootAgreement_Signers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAgreementsArgs = {
  distinct_on?: Maybe<Array<Agreements_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Agreements_Order_By>>
  where?: Maybe<Agreements_Bool_Exp>
}

export type Subscription_RootAgreements_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCapabilitiesArgs = {
  distinct_on?: Maybe<Array<Capabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Capabilities_Order_By>>
  where?: Maybe<Capabilities_Bool_Exp>
}

export type Subscription_RootCapabilities_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootConstruction_ProjectsArgs = {
  distinct_on?: Maybe<Array<Construction_Projects_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Construction_Projects_Order_By>>
  where?: Maybe<Construction_Projects_Bool_Exp>
}

export type Subscription_RootConstruction_Projects_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootConversationsArgs = {
  distinct_on?: Maybe<Array<Conversations_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Conversations_Order_By>>
  where?: Maybe<Conversations_Bool_Exp>
}

export type Subscription_RootConversations_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCredit_ReportsArgs = {
  distinct_on?: Maybe<Array<Credit_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Credit_Reports_Order_By>>
  where?: Maybe<Credit_Reports_Bool_Exp>
}

export type Subscription_RootCredit_Reports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCriminal_ReportsArgs = {
  distinct_on?: Maybe<Array<Criminal_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Criminal_Reports_Order_By>>
  where?: Maybe<Criminal_Reports_Bool_Exp>
}

export type Subscription_RootCriminal_Reports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDocumentsArgs = {
  distinct_on?: Maybe<Array<Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Documents_Order_By>>
  where?: Maybe<Documents_Bool_Exp>
}

export type Subscription_RootDocuments_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootEviction_ReportsArgs = {
  distinct_on?: Maybe<Array<Eviction_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Eviction_Reports_Order_By>>
  where?: Maybe<Eviction_Reports_Bool_Exp>
}

export type Subscription_RootEviction_Reports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFund_LlcsArgs = {
  distinct_on?: Maybe<Array<Fund_Llcs_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fund_Llcs_Order_By>>
  where?: Maybe<Fund_Llcs_Bool_Exp>
}

export type Subscription_RootFund_Llcs_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFundsArgs = {
  distinct_on?: Maybe<Array<Funds_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Funds_Order_By>>
  where?: Maybe<Funds_Bool_Exp>
}

export type Subscription_RootFunds_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootKafka_Connector_Prorated_Utility_Bill_Line_ItemsArgs =
  {
    distinct_on?: Maybe<
      Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<
      Array<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Order_By>
    >
    where?: Maybe<Kafka_Connector_Prorated_Utility_Bill_Line_Items_Bool_Exp>
  }

export type Subscription_RootKafka_Connector_Prorated_Utility_Bill_Line_Items_By_PkArgs =
  {
    id: Scalars['uuid']
  }

export type Subscription_RootLead_Group_Rental_Application_NotesArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Rental_Application_Notes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Rental_Application_Notes_Order_By>>
  where?: Maybe<Lead_Group_Rental_Application_Notes_Bool_Exp>
}

export type Subscription_RootLead_Group_UsersArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Users_Order_By>>
  where?: Maybe<Lead_Group_Users_Bool_Exp>
}

export type Subscription_RootLead_Group_Users_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLead_GroupsArgs = {
  distinct_on?: Maybe<Array<Lead_Groups_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Groups_Order_By>>
  where?: Maybe<Lead_Groups_Bool_Exp>
}

export type Subscription_RootLead_Groups_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLedger_AccountsArgs = {
  distinct_on?: Maybe<Array<Ledger_Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Ledger_Accounts_Order_By>>
  where?: Maybe<Ledger_Accounts_Bool_Exp>
}

export type Subscription_RootLedger_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLiability_TypesArgs = {
  distinct_on?: Maybe<Array<Liability_Types_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Liability_Types_Order_By>>
  where?: Maybe<Liability_Types_Bool_Exp>
}

export type Subscription_RootLiability_Types_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLlc_PropertiesArgs = {
  distinct_on?: Maybe<Array<Llc_Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Llc_Properties_Order_By>>
  where?: Maybe<Llc_Properties_Bool_Exp>
}

export type Subscription_RootLlc_Properties_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLlcsArgs = {
  distinct_on?: Maybe<Array<Llcs_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Llcs_Order_By>>
  where?: Maybe<Llcs_Bool_Exp>
}

export type Subscription_RootLlcs_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMarketsArgs = {
  distinct_on?: Maybe<Array<Markets_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Markets_Order_By>>
  where?: Maybe<Markets_Bool_Exp>
}

export type Subscription_RootMarkets_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMls_Listing_Internal_AttributesArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Internal_Attributes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Internal_Attributes_Order_By>>
  where?: Maybe<Mls_Listing_Internal_Attributes_Bool_Exp>
}

export type Subscription_RootMls_Listing_Internal_Attributes_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMls_Listing_PhotosArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Photos_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Photos_Order_By>>
  where?: Maybe<Mls_Listing_Photos_Bool_Exp>
}

export type Subscription_RootMls_Listing_Photos_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMls_Listing_PromotionsArgs = {
  distinct_on?: Maybe<Array<Mls_Listing_Promotions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listing_Promotions_Order_By>>
  where?: Maybe<Mls_Listing_Promotions_Bool_Exp>
}

export type Subscription_RootMls_Listing_Promotions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMls_ListingsArgs = {
  distinct_on?: Maybe<Array<Mls_Listings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mls_Listings_Order_By>>
  where?: Maybe<Mls_Listings_Bool_Exp>
}

export type Subscription_RootMls_Listings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notes_Order_By>>
  where?: Maybe<Notes_Bool_Exp>
}

export type Subscription_RootNotes_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payment_Methods_Order_By>>
  where?: Maybe<Payment_Methods_Bool_Exp>
}

export type Subscription_RootPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payment_Methods_Order_By>>
  where?: Maybe<Payment_Methods_Bool_Exp>
}

export type Subscription_RootPayment_Methods_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

export type Subscription_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

export type Subscription_RootPayments_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPlaid_Income_Verification_DocumentsArgs = {
  distinct_on?: Maybe<Array<Plaid_Income_Verification_Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Plaid_Income_Verification_Documents_Order_By>>
  where?: Maybe<Plaid_Income_Verification_Documents_Bool_Exp>
}

export type Subscription_RootPlaid_Income_VerificationsArgs = {
  distinct_on?: Maybe<Array<Plaid_Income_Verifications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Plaid_Income_Verifications_Order_By>>
  where?: Maybe<Plaid_Income_Verifications_Bool_Exp>
}

export type Subscription_RootPlaid_Income_Verifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPropertiesArgs = {
  distinct_on?: Maybe<Array<Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Properties_Order_By>>
  where?: Maybe<Properties_Bool_Exp>
}

export type Subscription_RootProperties_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootReal_Estate_AcquisitionsArgs = {
  distinct_on?: Maybe<Array<Real_Estate_Acquisitions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Real_Estate_Acquisitions_Order_By>>
  where?: Maybe<Real_Estate_Acquisitions_Bool_Exp>
}

export type Subscription_RootReal_Estate_Acquisitions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRent_PricesArgs = {
  distinct_on?: Maybe<Array<Rent_Prices_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rent_Prices_Order_By>>
  where?: Maybe<Rent_Prices_Bool_Exp>
}

export type Subscription_RootRent_Prices_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRent_Ready_DatesArgs = {
  distinct_on?: Maybe<Array<Rent_Ready_Dates_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rent_Ready_Dates_Order_By>>
  where?: Maybe<Rent_Ready_Dates_Bool_Exp>
}

export type Subscription_RootRent_Ready_Dates_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_Add_OnsArgs = {
  distinct_on?: Maybe<Array<Rental_Add_Ons_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Add_Ons_Order_By>>
  where?: Maybe<Rental_Add_Ons_Bool_Exp>
}

export type Subscription_RootRental_Add_Ons_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_Agreement_HistoriesArgs = {
  distinct_on?: Maybe<Array<Rental_Agreement_Histories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Agreement_Histories_Order_By>>
  where?: Maybe<Rental_Agreement_Histories_Bool_Exp>
}

export type Subscription_RootRental_Agreement_Histories_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_ApplicationsArgs = {
  distinct_on?: Maybe<Array<Rental_Applications_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Applications_Order_By>>
  where?: Maybe<Rental_Applications_Bool_Exp>
}

export type Subscription_RootRental_Applications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_CreditsArgs = {
  distinct_on?: Maybe<Array<Rental_Credits_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Credits_Order_By>>
  where?: Maybe<Rental_Credits_Bool_Exp>
}

export type Subscription_RootRental_Credits_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_LiabilitiesArgs = {
  distinct_on?: Maybe<Array<Rental_Liabilities_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Liabilities_Order_By>>
  where?: Maybe<Rental_Liabilities_Bool_Exp>
}

export type Subscription_RootRental_Liabilities_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_Site_AccountsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Accounts_Order_By>>
  where?: Maybe<Rental_Site_Accounts_Bool_Exp>
}

export type Subscription_RootRental_Site_Accounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_Site_LeadsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Leads_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Leads_Order_By>>
  where?: Maybe<Rental_Site_Leads_Bool_Exp>
}

export type Subscription_RootRental_Site_Leads_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_Site_ListingsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Listings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Listings_Order_By>>
  where?: Maybe<Rental_Site_Listings_Bool_Exp>
}

export type Subscription_RootRental_Site_Listings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRental_UsersArgs = {
  distinct_on?: Maybe<Array<Rental_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Users_Order_By>>
  where?: Maybe<Rental_Users_Bool_Exp>
}

export type Subscription_RootRental_Users_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRentalsArgs = {
  distinct_on?: Maybe<Array<Rentals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rentals_Order_By>>
  where?: Maybe<Rentals_Bool_Exp>
}

export type Subscription_RootRentals_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSeller_Agent_SearchableArgs = {
  distinct_on?: Maybe<Array<Seller_Agent_Searchable_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Seller_Agent_Searchable_Order_By>>
  where?: Maybe<Seller_Agent_Searchable_Bool_Exp>
}

export type Subscription_RootSeller_AgentsArgs = {
  distinct_on?: Maybe<Array<Seller_Agents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Seller_Agents_Order_By>>
  where?: Maybe<Seller_Agents_Bool_Exp>
}

export type Subscription_RootSeller_Agents_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootTour_PropertiesArgs = {
  distinct_on?: Maybe<Array<Tour_Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tour_Properties_Order_By>>
  where?: Maybe<Tour_Properties_Bool_Exp>
}

export type Subscription_RootTour_Properties_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootToursArgs = {
  distinct_on?: Maybe<Array<Tours_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tours_Order_By>>
  where?: Maybe<Tours_Bool_Exp>
}

export type Subscription_RootTours_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Contact_DetailsArgs = {
  distinct_on?: Maybe<Array<User_Contact_Details_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Contact_Details_Order_By>>
  where?: Maybe<User_Contact_Details_Bool_Exp>
}

export type Subscription_RootUser_Contact_Details_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_DocumentsArgs = {
  distinct_on?: Maybe<Array<User_Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Documents_Order_By>>
  where?: Maybe<User_Documents_Bool_Exp>
}

export type Subscription_RootUser_Funnel_StatusesArgs = {
  distinct_on?: Maybe<Array<User_Funnel_Statuses_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Funnel_Statuses_Order_By>>
  where?: Maybe<User_Funnel_Statuses_Bool_Exp>
}

export type Subscription_RootUser_Funnel_Statuses_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_IncomesArgs = {
  distinct_on?: Maybe<Array<User_Incomes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Incomes_Order_By>>
  where?: Maybe<User_Incomes_Bool_Exp>
}

export type Subscription_RootUser_Incomes_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Prequalification_DataArgs = {
  distinct_on?: Maybe<Array<User_Prequalification_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Prequalification_Data_Order_By>>
  where?: Maybe<User_Prequalification_Data_Bool_Exp>
}

export type Subscription_RootUser_Prequalification_Data_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_SearchableArgs = {
  distinct_on?: Maybe<Array<User_Searchable_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Searchable_Order_By>>
  where?: Maybe<User_Searchable_Bool_Exp>
}

export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Users_Order_By>>
  where?: Maybe<Users_Bool_Exp>
}

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Users_Order_By>>
  where?: Maybe<Users_Bool_Exp>
}

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid']
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>
  _gt?: Maybe<Scalars['timestamp']>
  _gte?: Maybe<Scalars['timestamp']>
  _in?: Maybe<Array<Scalars['timestamp']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamp']>
  _lte?: Maybe<Scalars['timestamp']>
  _neq?: Maybe<Scalars['timestamp']>
  _nin?: Maybe<Array<Scalars['timestamp']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>
  _gt?: Maybe<Scalars['timestamptz']>
  _gte?: Maybe<Scalars['timestamptz']>
  _in?: Maybe<Array<Scalars['timestamptz']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamptz']>
  _lte?: Maybe<Scalars['timestamptz']>
  _neq?: Maybe<Scalars['timestamptz']>
  _nin?: Maybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "tour_properties" */
export type Tour_Properties = {
  __typename: 'tour_properties'
  agent_confirmed_at?: Maybe<Scalars['timestamptz']>
  cancelation_reason?: Maybe<Scalars['String']>
  canceled_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  mls_listing: Mls_Listings
  property_id: Scalars['uuid']
  status: Scalars['String']
  tour_id: Scalars['uuid']
  tour_order: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** order by aggregate values of table "tour_properties" */
export type Tour_Properties_Aggregate_Order_By = {
  avg?: Maybe<Tour_Properties_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Tour_Properties_Max_Order_By>
  min?: Maybe<Tour_Properties_Min_Order_By>
  stddev?: Maybe<Tour_Properties_Stddev_Order_By>
  stddev_pop?: Maybe<Tour_Properties_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Tour_Properties_Stddev_Samp_Order_By>
  sum?: Maybe<Tour_Properties_Sum_Order_By>
  var_pop?: Maybe<Tour_Properties_Var_Pop_Order_By>
  var_samp?: Maybe<Tour_Properties_Var_Samp_Order_By>
  variance?: Maybe<Tour_Properties_Variance_Order_By>
}

/** order by avg() on columns of table "tour_properties" */
export type Tour_Properties_Avg_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "tour_properties". All fields are combined with a logical 'AND'. */
export type Tour_Properties_Bool_Exp = {
  _and?: Maybe<Array<Tour_Properties_Bool_Exp>>
  _not?: Maybe<Tour_Properties_Bool_Exp>
  _or?: Maybe<Array<Tour_Properties_Bool_Exp>>
  agent_confirmed_at?: Maybe<Timestamptz_Comparison_Exp>
  cancelation_reason?: Maybe<String_Comparison_Exp>
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  mls_listing?: Maybe<Mls_Listings_Bool_Exp>
  property_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  tour_id?: Maybe<Uuid_Comparison_Exp>
  tour_order?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "tour_properties" */
export type Tour_Properties_Max_Order_By = {
  agent_confirmed_at?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  property_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  tour_id?: Maybe<Order_By>
  tour_order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "tour_properties" */
export type Tour_Properties_Min_Order_By = {
  agent_confirmed_at?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  property_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  tour_id?: Maybe<Order_By>
  tour_order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "tour_properties". */
export type Tour_Properties_Order_By = {
  agent_confirmed_at?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  mls_listing?: Maybe<Mls_Listings_Order_By>
  property_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  tour_id?: Maybe<Order_By>
  tour_order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "tour_properties" */
export enum Tour_Properties_Select_Column {
  /** column name */
  AgentConfirmedAt = 'agent_confirmed_at',
  /** column name */
  CancelationReason = 'cancelation_reason',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PropertyId = 'property_id',
  /** column name */
  Status = 'status',
  /** column name */
  TourId = 'tour_id',
  /** column name */
  TourOrder = 'tour_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** order by stddev() on columns of table "tour_properties" */
export type Tour_Properties_Stddev_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "tour_properties" */
export type Tour_Properties_Stddev_Pop_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "tour_properties" */
export type Tour_Properties_Stddev_Samp_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** order by sum() on columns of table "tour_properties" */
export type Tour_Properties_Sum_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "tour_properties" */
export type Tour_Properties_Var_Pop_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "tour_properties" */
export type Tour_Properties_Var_Samp_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** order by variance() on columns of table "tour_properties" */
export type Tour_Properties_Variance_Order_By = {
  tour_order?: Maybe<Order_By>
}

/** columns and relationships of "tours" */
export type Tours = {
  __typename: 'tours'
  /** An object relationship */
  agent?: Maybe<Agents>
  agent_id?: Maybe<Scalars['uuid']>
  cancelation_reason?: Maybe<Scalars['String']>
  canceled_at?: Maybe<Scalars['timestamptz']>
  completed_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  first_lead_group_confirmation_sms_sent_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  lead_group_confirmed_at?: Maybe<Scalars['timestamptz']>
  lead_group_id: Scalars['uuid']
  note?: Maybe<Scalars['String']>
  second_lead_group_confirmation_sms_sent_at?: Maybe<Scalars['timestamptz']>
  start_at: Scalars['timestamptz']
  status: Scalars['String']
  /** An array relationship */
  tour_properties: Array<Tour_Properties>
  type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "tours" */
export type ToursTour_PropertiesArgs = {
  distinct_on?: Maybe<Array<Tour_Properties_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tour_Properties_Order_By>>
  where?: Maybe<Tour_Properties_Bool_Exp>
}

/** order by aggregate values of table "tours" */
export type Tours_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Tours_Max_Order_By>
  min?: Maybe<Tours_Min_Order_By>
}

/** Boolean expression to filter rows from the table "tours". All fields are combined with a logical 'AND'. */
export type Tours_Bool_Exp = {
  _and?: Maybe<Array<Tours_Bool_Exp>>
  _not?: Maybe<Tours_Bool_Exp>
  _or?: Maybe<Array<Tours_Bool_Exp>>
  agent?: Maybe<Agents_Bool_Exp>
  agent_id?: Maybe<Uuid_Comparison_Exp>
  cancelation_reason?: Maybe<String_Comparison_Exp>
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>
  completed_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  first_lead_group_confirmation_sms_sent_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  lead_group_confirmed_at?: Maybe<Timestamptz_Comparison_Exp>
  lead_group_id?: Maybe<Uuid_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  second_lead_group_confirmation_sms_sent_at?: Maybe<Timestamptz_Comparison_Exp>
  start_at?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  tour_properties?: Maybe<Tour_Properties_Bool_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** order by max() on columns of table "tours" */
export type Tours_Max_Order_By = {
  agent_id?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  first_lead_group_confirmation_sms_sent_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_confirmed_at?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  second_lead_group_confirmation_sms_sent_at?: Maybe<Order_By>
  start_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "tours" */
export type Tours_Min_Order_By = {
  agent_id?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  first_lead_group_confirmation_sms_sent_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_confirmed_at?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  second_lead_group_confirmation_sms_sent_at?: Maybe<Order_By>
  start_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "tours". */
export type Tours_Order_By = {
  agent?: Maybe<Agents_Order_By>
  agent_id?: Maybe<Order_By>
  cancelation_reason?: Maybe<Order_By>
  canceled_at?: Maybe<Order_By>
  completed_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  first_lead_group_confirmation_sms_sent_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_group_confirmed_at?: Maybe<Order_By>
  lead_group_id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  second_lead_group_confirmation_sms_sent_at?: Maybe<Order_By>
  start_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  tour_properties_aggregate?: Maybe<Tour_Properties_Aggregate_Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** select columns of table "tours" */
export enum Tours_Select_Column {
  /** column name */
  AgentId = 'agent_id',
  /** column name */
  CancelationReason = 'cancelation_reason',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstLeadGroupConfirmationSmsSentAt = 'first_lead_group_confirmation_sms_sent_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeadGroupConfirmedAt = 'lead_group_confirmed_at',
  /** column name */
  LeadGroupId = 'lead_group_id',
  /** column name */
  Note = 'note',
  /** column name */
  SecondLeadGroupConfirmationSmsSentAt = 'second_lead_group_confirmation_sms_sent_at',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "user_contact_details" */
export type User_Contact_Details = {
  __typename: 'user_contact_details'
  contact_info: Scalars['String']
  contact_type: Scalars['String']
  id: Scalars['uuid']
  is_primary_for_contact_type: Scalars['Boolean']
  /** An object relationship */
  user: Users
  user_id: Scalars['uuid']
}

/** order by aggregate values of table "user_contact_details" */
export type User_Contact_Details_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Contact_Details_Max_Order_By>
  min?: Maybe<User_Contact_Details_Min_Order_By>
}

/** Boolean expression to filter rows from the table "user_contact_details". All fields are combined with a logical 'AND'. */
export type User_Contact_Details_Bool_Exp = {
  _and?: Maybe<Array<User_Contact_Details_Bool_Exp>>
  _not?: Maybe<User_Contact_Details_Bool_Exp>
  _or?: Maybe<Array<User_Contact_Details_Bool_Exp>>
  contact_info?: Maybe<String_Comparison_Exp>
  contact_type?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_primary_for_contact_type?: Maybe<Boolean_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** order by max() on columns of table "user_contact_details" */
export type User_Contact_Details_Max_Order_By = {
  contact_info?: Maybe<Order_By>
  contact_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** order by min() on columns of table "user_contact_details" */
export type User_Contact_Details_Min_Order_By = {
  contact_info?: Maybe<Order_By>
  contact_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** Ordering options when selecting data from "user_contact_details". */
export type User_Contact_Details_Order_By = {
  contact_info?: Maybe<Order_By>
  contact_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_primary_for_contact_type?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_contact_details" */
export enum User_Contact_Details_Select_Column {
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimaryForContactType = 'is_primary_for_contact_type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_documents" */
export type User_Documents = {
  __typename: 'user_documents'
  created_at?: Maybe<Scalars['timestamptz']>
  external_id?: Maybe<Scalars['String']>
  external_source?: Maybe<Scalars['String']>
  friendly_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  resource_id?: Maybe<Scalars['uuid']>
  resource_notes?: Maybe<Scalars['String']>
  resource_type?: Maybe<Scalars['String']>
  review_notes?: Maybe<Scalars['String']>
  reviewed_at?: Maybe<Scalars['timestamptz']>
  reviewed_by_admin_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user?: Maybe<Users>
}

/** order by aggregate values of table "user_documents" */
export type User_Documents_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Documents_Max_Order_By>
  min?: Maybe<User_Documents_Min_Order_By>
}

/** Boolean expression to filter rows from the table "user_documents". All fields are combined with a logical 'AND'. */
export type User_Documents_Bool_Exp = {
  _and?: Maybe<Array<User_Documents_Bool_Exp>>
  _not?: Maybe<User_Documents_Bool_Exp>
  _or?: Maybe<Array<User_Documents_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  external_id?: Maybe<String_Comparison_Exp>
  external_source?: Maybe<String_Comparison_Exp>
  friendly_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  resource_id?: Maybe<Uuid_Comparison_Exp>
  resource_notes?: Maybe<String_Comparison_Exp>
  resource_type?: Maybe<String_Comparison_Exp>
  review_notes?: Maybe<String_Comparison_Exp>
  reviewed_at?: Maybe<Timestamptz_Comparison_Exp>
  reviewed_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
}

/** order by max() on columns of table "user_documents" */
export type User_Documents_Max_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** order by min() on columns of table "user_documents" */
export type User_Documents_Min_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** Ordering options when selecting data from "user_documents". */
export type User_Documents_Order_By = {
  created_at?: Maybe<Order_By>
  external_id?: Maybe<Order_By>
  external_source?: Maybe<Order_By>
  friendly_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  resource_id?: Maybe<Order_By>
  resource_notes?: Maybe<Order_By>
  resource_type?: Maybe<Order_By>
  review_notes?: Maybe<Order_By>
  reviewed_at?: Maybe<Order_By>
  reviewed_by_admin_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
}

/** select columns of table "user_documents" */
export enum User_Documents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  FriendlyName = 'friendly_name',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  ResourceNotes = 'resource_notes',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  ReviewNotes = 'review_notes',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  ReviewedByAdminId = 'reviewed_by_admin_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "user_funnel_statuses" */
export type User_Funnel_Statuses = {
  __typename: 'user_funnel_statuses'
  archived_at?: Maybe<Scalars['timestamptz']>
  archived_by_admin_id?: Maybe<Scalars['uuid']>
  conversion_propensity_score?: Maybe<Scalars['numeric']>
  created_at: Scalars['timestamptz']
  deactivated_at?: Maybe<Scalars['timestamptz']>
  deactivated_by_admin_id?: Maybe<Scalars['uuid']>
  escalated_at?: Maybe<Scalars['timestamptz']>
  escalated_by_admin_id?: Maybe<Scalars['uuid']>
  escalated_to_admin_id?: Maybe<Scalars['uuid']>
  escalation_note_id?: Maybe<Scalars['uuid']>
  id: Scalars['uuid']
  lead_status?: Maybe<Scalars['String']>
  priority_note_id?: Maybe<Scalars['uuid']>
  priority_score?: Maybe<Scalars['numeric']>
  prospective_move_in_date?: Maybe<Scalars['date']>
  snoozed_by_admin_id?: Maybe<Scalars['uuid']>
  snoozed_until?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['uuid']
}

/** Boolean expression to filter rows from the table "user_funnel_statuses". All fields are combined with a logical 'AND'. */
export type User_Funnel_Statuses_Bool_Exp = {
  _and?: Maybe<Array<User_Funnel_Statuses_Bool_Exp>>
  _not?: Maybe<User_Funnel_Statuses_Bool_Exp>
  _or?: Maybe<Array<User_Funnel_Statuses_Bool_Exp>>
  archived_at?: Maybe<Timestamptz_Comparison_Exp>
  archived_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  conversion_propensity_score?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>
  deactivated_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  escalated_at?: Maybe<Timestamptz_Comparison_Exp>
  escalated_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  escalated_to_admin_id?: Maybe<Uuid_Comparison_Exp>
  escalation_note_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  lead_status?: Maybe<String_Comparison_Exp>
  priority_note_id?: Maybe<Uuid_Comparison_Exp>
  priority_score?: Maybe<Numeric_Comparison_Exp>
  prospective_move_in_date?: Maybe<Date_Comparison_Exp>
  snoozed_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  snoozed_until?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** Ordering options when selecting data from "user_funnel_statuses". */
export type User_Funnel_Statuses_Order_By = {
  archived_at?: Maybe<Order_By>
  archived_by_admin_id?: Maybe<Order_By>
  conversion_propensity_score?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deactivated_at?: Maybe<Order_By>
  deactivated_by_admin_id?: Maybe<Order_By>
  escalated_at?: Maybe<Order_By>
  escalated_by_admin_id?: Maybe<Order_By>
  escalated_to_admin_id?: Maybe<Order_By>
  escalation_note_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  lead_status?: Maybe<Order_By>
  priority_note_id?: Maybe<Order_By>
  priority_score?: Maybe<Order_By>
  prospective_move_in_date?: Maybe<Order_By>
  snoozed_by_admin_id?: Maybe<Order_By>
  snoozed_until?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_funnel_statuses" */
export enum User_Funnel_Statuses_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ArchivedByAdminId = 'archived_by_admin_id',
  /** column name */
  ConversionPropensityScore = 'conversion_propensity_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  DeactivatedByAdminId = 'deactivated_by_admin_id',
  /** column name */
  EscalatedAt = 'escalated_at',
  /** column name */
  EscalatedByAdminId = 'escalated_by_admin_id',
  /** column name */
  EscalatedToAdminId = 'escalated_to_admin_id',
  /** column name */
  EscalationNoteId = 'escalation_note_id',
  /** column name */
  Id = 'id',
  /** column name */
  LeadStatus = 'lead_status',
  /** column name */
  PriorityNoteId = 'priority_note_id',
  /** column name */
  PriorityScore = 'priority_score',
  /** column name */
  ProspectiveMoveInDate = 'prospective_move_in_date',
  /** column name */
  SnoozedByAdminId = 'snoozed_by_admin_id',
  /** column name */
  SnoozedUntil = 'snoozed_until',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_incomes" */
export type User_Incomes = {
  __typename: 'user_incomes'
  created_at: Scalars['timestamptz']
  employee_name?: Maybe<Scalars['String']>
  employee_title?: Maybe<Scalars['String']>
  employer_industry?: Maybe<Scalars['String']>
  employer_name?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  notes?: Maybe<Scalars['String']>
  pay_frequency?: Maybe<Scalars['String']>
  projected_annual_income?: Maybe<Scalars['numeric']>
  status?: Maybe<Scalars['String']>
  submitted_by_admin_id?: Maybe<Scalars['uuid']>
  unverified_at?: Maybe<Scalars['timestamptz']>
  unverified_by_admin_id?: Maybe<Scalars['uuid']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<Users>
  user_id: Scalars['uuid']
  verified_at?: Maybe<Scalars['timestamptz']>
  verified_by_admin_id?: Maybe<Scalars['uuid']>
  ytd_gross_income?: Maybe<Scalars['numeric']>
  ytd_net_income?: Maybe<Scalars['numeric']>
}

/** order by aggregate values of table "user_incomes" */
export type User_Incomes_Aggregate_Order_By = {
  avg?: Maybe<User_Incomes_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Incomes_Max_Order_By>
  min?: Maybe<User_Incomes_Min_Order_By>
  stddev?: Maybe<User_Incomes_Stddev_Order_By>
  stddev_pop?: Maybe<User_Incomes_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Incomes_Stddev_Samp_Order_By>
  sum?: Maybe<User_Incomes_Sum_Order_By>
  var_pop?: Maybe<User_Incomes_Var_Pop_Order_By>
  var_samp?: Maybe<User_Incomes_Var_Samp_Order_By>
  variance?: Maybe<User_Incomes_Variance_Order_By>
}

/** order by avg() on columns of table "user_incomes" */
export type User_Incomes_Avg_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_incomes". All fields are combined with a logical 'AND'. */
export type User_Incomes_Bool_Exp = {
  _and?: Maybe<Array<User_Incomes_Bool_Exp>>
  _not?: Maybe<User_Incomes_Bool_Exp>
  _or?: Maybe<Array<User_Incomes_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  employee_name?: Maybe<String_Comparison_Exp>
  employee_title?: Maybe<String_Comparison_Exp>
  employer_industry?: Maybe<String_Comparison_Exp>
  employer_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notes?: Maybe<String_Comparison_Exp>
  pay_frequency?: Maybe<String_Comparison_Exp>
  projected_annual_income?: Maybe<Numeric_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  submitted_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  unverified_at?: Maybe<Timestamptz_Comparison_Exp>
  unverified_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
  verified_at?: Maybe<Timestamptz_Comparison_Exp>
  verified_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  ytd_gross_income?: Maybe<Numeric_Comparison_Exp>
  ytd_net_income?: Maybe<Numeric_Comparison_Exp>
}

/** order by max() on columns of table "user_incomes" */
export type User_Incomes_Max_Order_By = {
  created_at?: Maybe<Order_By>
  employee_name?: Maybe<Order_By>
  employee_title?: Maybe<Order_By>
  employer_industry?: Maybe<Order_By>
  employer_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  pay_frequency?: Maybe<Order_By>
  projected_annual_income?: Maybe<Order_By>
  status?: Maybe<Order_By>
  submitted_by_admin_id?: Maybe<Order_By>
  unverified_at?: Maybe<Order_By>
  unverified_by_admin_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  verified_at?: Maybe<Order_By>
  verified_by_admin_id?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by min() on columns of table "user_incomes" */
export type User_Incomes_Min_Order_By = {
  created_at?: Maybe<Order_By>
  employee_name?: Maybe<Order_By>
  employee_title?: Maybe<Order_By>
  employer_industry?: Maybe<Order_By>
  employer_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  pay_frequency?: Maybe<Order_By>
  projected_annual_income?: Maybe<Order_By>
  status?: Maybe<Order_By>
  submitted_by_admin_id?: Maybe<Order_By>
  unverified_at?: Maybe<Order_By>
  unverified_by_admin_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  verified_at?: Maybe<Order_By>
  verified_by_admin_id?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** Ordering options when selecting data from "user_incomes". */
export type User_Incomes_Order_By = {
  created_at?: Maybe<Order_By>
  employee_name?: Maybe<Order_By>
  employee_title?: Maybe<Order_By>
  employer_industry?: Maybe<Order_By>
  employer_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  pay_frequency?: Maybe<Order_By>
  projected_annual_income?: Maybe<Order_By>
  status?: Maybe<Order_By>
  submitted_by_admin_id?: Maybe<Order_By>
  unverified_at?: Maybe<Order_By>
  unverified_by_admin_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
  user_id?: Maybe<Order_By>
  verified_at?: Maybe<Order_By>
  verified_by_admin_id?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** select columns of table "user_incomes" */
export enum User_Incomes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployeeName = 'employee_name',
  /** column name */
  EmployeeTitle = 'employee_title',
  /** column name */
  EmployerIndustry = 'employer_industry',
  /** column name */
  EmployerName = 'employer_name',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PayFrequency = 'pay_frequency',
  /** column name */
  ProjectedAnnualIncome = 'projected_annual_income',
  /** column name */
  Status = 'status',
  /** column name */
  SubmittedByAdminId = 'submitted_by_admin_id',
  /** column name */
  UnverifiedAt = 'unverified_at',
  /** column name */
  UnverifiedByAdminId = 'unverified_by_admin_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifiedByAdminId = 'verified_by_admin_id',
  /** column name */
  YtdGrossIncome = 'ytd_gross_income',
  /** column name */
  YtdNetIncome = 'ytd_net_income'
}

/** order by stddev() on columns of table "user_incomes" */
export type User_Incomes_Stddev_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by stddev_pop() on columns of table "user_incomes" */
export type User_Incomes_Stddev_Pop_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by stddev_samp() on columns of table "user_incomes" */
export type User_Incomes_Stddev_Samp_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by sum() on columns of table "user_incomes" */
export type User_Incomes_Sum_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by var_pop() on columns of table "user_incomes" */
export type User_Incomes_Var_Pop_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by var_samp() on columns of table "user_incomes" */
export type User_Incomes_Var_Samp_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** order by variance() on columns of table "user_incomes" */
export type User_Incomes_Variance_Order_By = {
  projected_annual_income?: Maybe<Order_By>
  ytd_gross_income?: Maybe<Order_By>
  ytd_net_income?: Maybe<Order_By>
}

/** columns and relationships of "user_prequalification_data" */
export type User_Prequalification_Data = {
  __typename: 'user_prequalification_data'
  campaign?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  /** An array relationship */
  credit_reports: Array<Credit_Reports>
  has_coapplicant?: Maybe<Scalars['Boolean']>
  has_cosigner?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  is_credit_qualified?: Maybe<Scalars['Boolean']>
  is_income_qualified?: Maybe<Scalars['Boolean']>
  is_ok_with_two_year_lease?: Maybe<Scalars['Boolean']>
  is_section_eight?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  move_in_timeline_at_submission?: Maybe<Scalars['String']>
  prequalification_status: Scalars['String']
  prequalified_at?: Maybe<Scalars['timestamptz']>
  prequalified_by_admin_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['uuid']
}

/** columns and relationships of "user_prequalification_data" */
export type User_Prequalification_DataCredit_ReportsArgs = {
  distinct_on?: Maybe<Array<Credit_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Credit_Reports_Order_By>>
  where?: Maybe<Credit_Reports_Bool_Exp>
}

/** columns and relationships of "user_prequalification_data" */
export type User_Prequalification_DataMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** Boolean expression to filter rows from the table "user_prequalification_data". All fields are combined with a logical 'AND'. */
export type User_Prequalification_Data_Bool_Exp = {
  _and?: Maybe<Array<User_Prequalification_Data_Bool_Exp>>
  _not?: Maybe<User_Prequalification_Data_Bool_Exp>
  _or?: Maybe<Array<User_Prequalification_Data_Bool_Exp>>
  campaign?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  credit_reports?: Maybe<Credit_Reports_Bool_Exp>
  has_coapplicant?: Maybe<Boolean_Comparison_Exp>
  has_cosigner?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_credit_qualified?: Maybe<Boolean_Comparison_Exp>
  is_income_qualified?: Maybe<Boolean_Comparison_Exp>
  is_ok_with_two_year_lease?: Maybe<Boolean_Comparison_Exp>
  is_section_eight?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  move_in_timeline_at_submission?: Maybe<String_Comparison_Exp>
  prequalification_status?: Maybe<String_Comparison_Exp>
  prequalified_at?: Maybe<Timestamptz_Comparison_Exp>
  prequalified_by_admin_id?: Maybe<Uuid_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<Uuid_Comparison_Exp>
}

/** Ordering options when selecting data from "user_prequalification_data". */
export type User_Prequalification_Data_Order_By = {
  campaign?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  credit_reports_aggregate?: Maybe<Credit_Reports_Aggregate_Order_By>
  has_coapplicant?: Maybe<Order_By>
  has_cosigner?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_credit_qualified?: Maybe<Order_By>
  is_income_qualified?: Maybe<Order_By>
  is_ok_with_two_year_lease?: Maybe<Order_By>
  is_section_eight?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  move_in_timeline_at_submission?: Maybe<Order_By>
  prequalification_status?: Maybe<Order_By>
  prequalified_at?: Maybe<Order_By>
  prequalified_by_admin_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_prequalification_data" */
export enum User_Prequalification_Data_Select_Column {
  /** column name */
  Campaign = 'campaign',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasCoapplicant = 'has_coapplicant',
  /** column name */
  HasCosigner = 'has_cosigner',
  /** column name */
  Id = 'id',
  /** column name */
  IsCreditQualified = 'is_credit_qualified',
  /** column name */
  IsIncomeQualified = 'is_income_qualified',
  /** column name */
  IsOkWithTwoYearLease = 'is_ok_with_two_year_lease',
  /** column name */
  IsSectionEight = 'is_section_eight',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MoveInTimelineAtSubmission = 'move_in_timeline_at_submission',
  /** column name */
  PrequalificationStatus = 'prequalification_status',
  /** column name */
  PrequalifiedAt = 'prequalified_at',
  /** column name */
  PrequalifiedByAdminId = 'prequalified_by_admin_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_searchable" */
export type User_Searchable = {
  __typename: 'user_searchable'
  contact?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  info?: Maybe<Scalars['String']>
  /** An object relationship */
  lead_group_user?: Maybe<Lead_Group_Users>
  name?: Maybe<Scalars['String']>
  /** An object relationship */
  user?: Maybe<Users>
}

/** Boolean expression to filter rows from the table "user_searchable". All fields are combined with a logical 'AND'. */
export type User_Searchable_Bool_Exp = {
  _and?: Maybe<Array<User_Searchable_Bool_Exp>>
  _not?: Maybe<User_Searchable_Bool_Exp>
  _or?: Maybe<Array<User_Searchable_Bool_Exp>>
  contact?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  info?: Maybe<String_Comparison_Exp>
  lead_group_user?: Maybe<Lead_Group_Users_Bool_Exp>
  name?: Maybe<String_Comparison_Exp>
  user?: Maybe<Users_Bool_Exp>
}

/** Ordering options when selecting data from "user_searchable". */
export type User_Searchable_Order_By = {
  contact?: Maybe<Order_By>
  id?: Maybe<Order_By>
  info?: Maybe<Order_By>
  lead_group_user?: Maybe<Lead_Group_Users_Order_By>
  name?: Maybe<Order_By>
  user?: Maybe<Users_Order_By>
}

/** select columns of table "user_searchable" */
export enum User_Searchable_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "users" */
export type Users = {
  __typename: 'users'
  /** An array relationship */
  conversations: Array<Conversations>
  created_at?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  credit_reports: Array<Credit_Reports>
  /** An array relationship */
  criminal_reports: Array<Criminal_Reports>
  deleted_at?: Maybe<Scalars['timestamptz']>
  disable_self_initiated_payments?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  documents: Array<User_Documents>
  email?: Maybe<Scalars['String']>
  /** An array relationship */
  eviction_reports: Array<Eviction_Reports>
  first_name?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  last_name?: Maybe<Scalars['String']>
  /** An array relationship */
  lead_group_users: Array<Lead_Group_Users>
  password?: Maybe<Scalars['String']>
  /** An array relationship */
  payment_methods: Array<Payment_Methods>
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Methods_Aggregate
  /** An array relationship */
  payments: Array<Payments>
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate
  phone?: Maybe<Scalars['String']>
  plaid_access_token?: Maybe<Scalars['String']>
  plaid_access_token_invalid_at?: Maybe<Scalars['timestamp']>
  primary_user_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  rental_site_leads: Array<Rental_Site_Leads>
  /** An array relationship */
  rental_users: Array<Rental_Users>
  /** An array relationship */
  rentals: Array<Rentals>
  reset_password: Scalars['Boolean']
  settings?: Maybe<Scalars['jsonb']>
  stripe_customer_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  user_contact_details: Array<User_Contact_Details>
  /** An object relationship */
  user_funnel_status?: Maybe<User_Funnel_Statuses>
  /** An array relationship */
  user_incomes: Array<User_Incomes>
  /** An object relationship */
  user_prequalification_data?: Maybe<User_Prequalification_Data>
  /** An object relationship */
  user_searchable?: Maybe<User_Searchable>
}

/** columns and relationships of "users" */
export type UsersConversationsArgs = {
  distinct_on?: Maybe<Array<Conversations_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Conversations_Order_By>>
  where?: Maybe<Conversations_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersCredit_ReportsArgs = {
  distinct_on?: Maybe<Array<Credit_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Credit_Reports_Order_By>>
  where?: Maybe<Credit_Reports_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersCriminal_ReportsArgs = {
  distinct_on?: Maybe<Array<Criminal_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Criminal_Reports_Order_By>>
  where?: Maybe<Criminal_Reports_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersDocumentsArgs = {
  distinct_on?: Maybe<Array<User_Documents_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Documents_Order_By>>
  where?: Maybe<User_Documents_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersEviction_ReportsArgs = {
  distinct_on?: Maybe<Array<Eviction_Reports_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Eviction_Reports_Order_By>>
  where?: Maybe<Eviction_Reports_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersLead_Group_UsersArgs = {
  distinct_on?: Maybe<Array<Lead_Group_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Lead_Group_Users_Order_By>>
  where?: Maybe<Lead_Group_Users_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPayment_MethodsArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payment_Methods_Order_By>>
  where?: Maybe<Payment_Methods_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPayment_Methods_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Methods_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payment_Methods_Order_By>>
  where?: Maybe<Payment_Methods_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Payments_Order_By>>
  where?: Maybe<Payments_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersRental_Site_LeadsArgs = {
  distinct_on?: Maybe<Array<Rental_Site_Leads_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Site_Leads_Order_By>>
  where?: Maybe<Rental_Site_Leads_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersRental_UsersArgs = {
  distinct_on?: Maybe<Array<Rental_Users_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rental_Users_Order_By>>
  where?: Maybe<Rental_Users_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersRentalsArgs = {
  distinct_on?: Maybe<Array<Rentals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rentals_Order_By>>
  where?: Maybe<Rentals_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersSettingsArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "users" */
export type UsersUser_Contact_DetailsArgs = {
  distinct_on?: Maybe<Array<User_Contact_Details_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Contact_Details_Order_By>>
  where?: Maybe<User_Contact_Details_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersUser_IncomesArgs = {
  distinct_on?: Maybe<Array<User_Incomes_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Incomes_Order_By>>
  where?: Maybe<User_Incomes_Bool_Exp>
}

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename: 'users_aggregate'
  aggregate?: Maybe<Users_Aggregate_Fields>
  nodes: Array<Users>
}

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename: 'users_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Users_Max_Fields>
  min?: Maybe<Users_Min_Fields>
}

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  settings?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>
  _not?: Maybe<Users_Bool_Exp>
  _or?: Maybe<Array<Users_Bool_Exp>>
  conversations?: Maybe<Conversations_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  credit_reports?: Maybe<Credit_Reports_Bool_Exp>
  criminal_reports?: Maybe<Criminal_Reports_Bool_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  disable_self_initiated_payments?: Maybe<Boolean_Comparison_Exp>
  documents?: Maybe<User_Documents_Bool_Exp>
  email?: Maybe<String_Comparison_Exp>
  eviction_reports?: Maybe<Eviction_Reports_Bool_Exp>
  first_name?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_name?: Maybe<String_Comparison_Exp>
  lead_group_users?: Maybe<Lead_Group_Users_Bool_Exp>
  password?: Maybe<String_Comparison_Exp>
  payment_methods?: Maybe<Payment_Methods_Bool_Exp>
  payments?: Maybe<Payments_Bool_Exp>
  phone?: Maybe<String_Comparison_Exp>
  plaid_access_token?: Maybe<String_Comparison_Exp>
  plaid_access_token_invalid_at?: Maybe<Timestamp_Comparison_Exp>
  primary_user_id?: Maybe<Uuid_Comparison_Exp>
  rental_site_leads?: Maybe<Rental_Site_Leads_Bool_Exp>
  rental_users?: Maybe<Rental_Users_Bool_Exp>
  rentals?: Maybe<Rentals_Bool_Exp>
  reset_password?: Maybe<Boolean_Comparison_Exp>
  settings?: Maybe<Jsonb_Comparison_Exp>
  stripe_customer_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_contact_details?: Maybe<User_Contact_Details_Bool_Exp>
  user_funnel_status?: Maybe<User_Funnel_Statuses_Bool_Exp>
  user_incomes?: Maybe<User_Incomes_Bool_Exp>
  user_prequalification_data?: Maybe<User_Prequalification_Data_Bool_Exp>
  user_searchable?: Maybe<User_Searchable_Bool_Exp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  settings?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  settings?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  settings?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename: 'users_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  last_name?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  plaid_access_token?: Maybe<Scalars['String']>
  plaid_access_token_invalid_at?: Maybe<Scalars['timestamp']>
  primary_user_id?: Maybe<Scalars['uuid']>
  stripe_customer_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename: 'users_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  last_name?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  plaid_access_token?: Maybe<Scalars['String']>
  plaid_access_token_invalid_at?: Maybe<Scalars['timestamp']>
  primary_user_id?: Maybe<Scalars['uuid']>
  stripe_customer_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename: 'users_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Users>
}

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  conversations_aggregate?: Maybe<Conversations_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  credit_reports_aggregate?: Maybe<Credit_Reports_Aggregate_Order_By>
  criminal_reports_aggregate?: Maybe<Criminal_Reports_Aggregate_Order_By>
  deleted_at?: Maybe<Order_By>
  disable_self_initiated_payments?: Maybe<Order_By>
  documents_aggregate?: Maybe<User_Documents_Aggregate_Order_By>
  email?: Maybe<Order_By>
  eviction_reports_aggregate?: Maybe<Eviction_Reports_Aggregate_Order_By>
  first_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_name?: Maybe<Order_By>
  lead_group_users_aggregate?: Maybe<Lead_Group_Users_Aggregate_Order_By>
  password?: Maybe<Order_By>
  payment_methods_aggregate?: Maybe<Payment_Methods_Aggregate_Order_By>
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>
  phone?: Maybe<Order_By>
  plaid_access_token?: Maybe<Order_By>
  plaid_access_token_invalid_at?: Maybe<Order_By>
  primary_user_id?: Maybe<Order_By>
  rental_site_leads_aggregate?: Maybe<Rental_Site_Leads_Aggregate_Order_By>
  rental_users_aggregate?: Maybe<Rental_Users_Aggregate_Order_By>
  rentals_aggregate?: Maybe<Rentals_Aggregate_Order_By>
  reset_password?: Maybe<Order_By>
  settings?: Maybe<Order_By>
  stripe_customer_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_contact_details_aggregate?: Maybe<User_Contact_Details_Aggregate_Order_By>
  user_funnel_status?: Maybe<User_Funnel_Statuses_Order_By>
  user_incomes_aggregate?: Maybe<User_Incomes_Aggregate_Order_By>
  user_prequalification_data?: Maybe<User_Prequalification_Data_Order_By>
  user_searchable?: Maybe<User_Searchable_Order_By>
}

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  settings?: Maybe<Scalars['jsonb']>
}

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisableSelfInitiatedPayments = 'disable_self_initiated_payments',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  PlaidAccessToken = 'plaid_access_token',
  /** column name */
  PlaidAccessTokenInvalidAt = 'plaid_access_token_invalid_at',
  /** column name */
  PrimaryUserId = 'primary_user_id',
  /** column name */
  ResetPassword = 'reset_password',
  /** column name */
  Settings = 'settings',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  settings?: Maybe<Scalars['jsonb']>
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Users_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Users_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Users_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Users_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Users_Set_Input>
  where: Users_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>
  _gt?: Maybe<Scalars['uuid']>
  _gte?: Maybe<Scalars['uuid']>
  _in?: Maybe<Array<Scalars['uuid']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['uuid']>
  _lte?: Maybe<Scalars['uuid']>
  _neq?: Maybe<Scalars['uuid']>
  _nin?: Maybe<Array<Scalars['uuid']>>
}

export type PropertyHeaderFragment = {
  __typename: 'properties'
  real_estate_acquisition?:
    | {
        __typename: 'real_estate_acquisitions'
        closing_date?: any | null | undefined
        closingPrice?: any | null | undefined
        mls_listing: {
          __typename: 'mls_listings'
          id: any
          delivery_line?: string | null | undefined
          city?: string | null | undefined
          addressLineOne?: string | null | undefined
          addressLineTwo?: string | null | undefined
        }
      }
    | null
    | undefined
}

export type AcquisitionInfoFragmentFragment = {
  __typename: 'real_estate_acquisitions'
  id: any
  closing_date?: any | null | undefined
  type?: string | null | undefined
  status: string
  final_purchase_price?: any | null | undefined
  mls_listing: {
    __typename: 'mls_listings'
    id: any
    area?: string | null | undefined
    baths_total?: number | null | undefined
    beds?: number | null | undefined
    city?: string | null | undefined
    display_line_1?: string | null | undefined
    firestore_id: any
    listing_date?: any | null | undefined
    list_price?: any | null | undefined
    mls: string
    provider?: string | null | undefined
    size?: string | null | undefined
    state?: string | null | undefined
    year_built?: number | null | undefined
    zip?: string | null | undefined
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      internal_status?: string | null | undefined
    }>
    rent_prices: Array<{
      __typename: 'rent_prices'
      id: any
      rent_price?: any | null | undefined
      estimated_cap_rate?: any | null | undefined
      upfront_improvements_cost?: any | null | undefined
      sellers_credits?: any | null | undefined
    }>
    rent_ready_dates: Array<{
      __typename: 'rent_ready_dates'
      id: any
      earliest_move_in_date?: any | null | undefined
      type: string
    }>
    market: { __typename: 'markets'; id: any; display_name: string }
  }
  llc?: { __typename: 'llcs'; id: any } | null | undefined
}

export type MlsListingFragmentFragment = {
  __typename: 'mls_listings'
  id: any
  area?: string | null | undefined
  baths_total?: number | null | undefined
  beds?: number | null | undefined
  city?: string | null | undefined
  display_line_1?: string | null | undefined
  firestore_id: any
  listing_date?: any | null | undefined
  list_price?: any | null | undefined
  mls: string
  provider?: string | null | undefined
  size?: string | null | undefined
  state?: string | null | undefined
  year_built?: number | null | undefined
  zip?: string | null | undefined
  internal_attributes: Array<{
    __typename: 'mls_listing_internal_attributes'
    internal_status?: string | null | undefined
  }>
  rent_prices: Array<{
    __typename: 'rent_prices'
    id: any
    rent_price?: any | null | undefined
    estimated_cap_rate?: any | null | undefined
    upfront_improvements_cost?: any | null | undefined
    sellers_credits?: any | null | undefined
  }>
  rent_ready_dates: Array<{
    __typename: 'rent_ready_dates'
    id: any
    earliest_move_in_date?: any | null | undefined
    type: string
  }>
  market: { __typename: 'markets'; id: any; display_name: string }
}

export type PropertyInfoFragment = {
  __typename: 'properties'
  id: any
  exit_date?: any | null | undefined
  exit_price?: any | null | undefined
  real_estate_acquisition?:
    | {
        __typename: 'real_estate_acquisitions'
        id: any
        closing_date?: any | null | undefined
        type?: string | null | undefined
        status: string
        final_purchase_price?: any | null | undefined
        mls_listing: {
          __typename: 'mls_listings'
          id: any
          area?: string | null | undefined
          baths_total?: number | null | undefined
          beds?: number | null | undefined
          city?: string | null | undefined
          display_line_1?: string | null | undefined
          firestore_id: any
          listing_date?: any | null | undefined
          list_price?: any | null | undefined
          mls: string
          provider?: string | null | undefined
          size?: string | null | undefined
          state?: string | null | undefined
          year_built?: number | null | undefined
          zip?: string | null | undefined
          internal_attributes: Array<{
            __typename: 'mls_listing_internal_attributes'
            internal_status?: string | null | undefined
          }>
          rent_prices: Array<{
            __typename: 'rent_prices'
            id: any
            rent_price?: any | null | undefined
            estimated_cap_rate?: any | null | undefined
            upfront_improvements_cost?: any | null | undefined
            sellers_credits?: any | null | undefined
          }>
          rent_ready_dates: Array<{
            __typename: 'rent_ready_dates'
            id: any
            earliest_move_in_date?: any | null | undefined
            type: string
          }>
          market: { __typename: 'markets'; id: any; display_name: string }
        }
        llc?: { __typename: 'llcs'; id: any } | null | undefined
      }
    | null
    | undefined
}

export type HomeByPropertyIdQueryVariables = Exact<{
  propertyId: Scalars['uuid']
}>

export type HomeByPropertyIdQuery = {
  __typename: 'query_root'
  properties: Array<{
    __typename: 'properties'
    id: any
    exit_date?: any | null | undefined
    exit_price?: any | null | undefined
    real_estate_acquisition?:
      | {
          __typename: 'real_estate_acquisitions'
          closing_date?: any | null | undefined
          id: any
          type?: string | null | undefined
          status: string
          final_purchase_price?: any | null | undefined
          closingPrice?: any | null | undefined
          mls_listing: {
            __typename: 'mls_listings'
            id: any
            delivery_line?: string | null | undefined
            city?: string | null | undefined
            area?: string | null | undefined
            baths_total?: number | null | undefined
            beds?: number | null | undefined
            display_line_1?: string | null | undefined
            firestore_id: any
            listing_date?: any | null | undefined
            list_price?: any | null | undefined
            mls: string
            provider?: string | null | undefined
            size?: string | null | undefined
            state?: string | null | undefined
            year_built?: number | null | undefined
            zip?: string | null | undefined
            addressLineOne?: string | null | undefined
            addressLineTwo?: string | null | undefined
            internal_attributes: Array<{
              __typename: 'mls_listing_internal_attributes'
              internal_status?: string | null | undefined
            }>
            rent_prices: Array<{
              __typename: 'rent_prices'
              id: any
              rent_price?: any | null | undefined
              estimated_cap_rate?: any | null | undefined
              upfront_improvements_cost?: any | null | undefined
              sellers_credits?: any | null | undefined
            }>
            rent_ready_dates: Array<{
              __typename: 'rent_ready_dates'
              id: any
              earliest_move_in_date?: any | null | undefined
              type: string
            }>
            market: { __typename: 'markets'; id: any; display_name: string }
          }
          llc?: { __typename: 'llcs'; id: any } | null | undefined
        }
      | null
      | undefined
  }>
}

export type HomesGridQueryVariables = Exact<{ [key: string]: never }>

export type HomesGridQuery = {
  __typename: 'query_root'
  properties: Array<{
    __typename: 'properties'
    id: any
    real_estate_acquisition?:
      | {
          __typename: 'real_estate_acquisitions'
          id: any
          closing_date?: any | null | undefined
          type?: string | null | undefined
          status: string
          final_purchase_price?: any | null | undefined
          mls_listing: {
            __typename: 'mls_listings'
            id: any
            area?: string | null | undefined
            baths_total?: number | null | undefined
            beds?: number | null | undefined
            city?: string | null | undefined
            display_line_1?: string | null | undefined
            firestore_id: any
            listing_date?: any | null | undefined
            list_price?: any | null | undefined
            mls: string
            provider?: string | null | undefined
            size?: string | null | undefined
            state?: string | null | undefined
            year_built?: number | null | undefined
            zip?: string | null | undefined
            internal_attributes: Array<{
              __typename: 'mls_listing_internal_attributes'
              internal_status?: string | null | undefined
            }>
            rent_prices: Array<{
              __typename: 'rent_prices'
              id: any
              rent_price?: any | null | undefined
              estimated_cap_rate?: any | null | undefined
              upfront_improvements_cost?: any | null | undefined
              sellers_credits?: any | null | undefined
            }>
            rent_ready_dates: Array<{
              __typename: 'rent_ready_dates'
              id: any
              earliest_move_in_date?: any | null | undefined
              type: string
            }>
            market: { __typename: 'markets'; id: any; display_name: string }
          }
          llc?: { __typename: 'llcs'; id: any } | null | undefined
        }
      | null
      | undefined
  }>
}

export type UpupLeadGroupsUserListSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  where?: Maybe<Lead_Groups_Bool_Exp>
}>

export type UpupLeadGroupsUserListSubscription = {
  __typename: 'subscription_root'
  lead_groups: Array<{
    __typename: 'lead_groups'
    id: any
    created_at?: any | null | undefined
    status: string
    lead_group_users: Array<{
      __typename: 'lead_group_users'
      id: any
      type: string
      deactivated_at?: any | null | undefined
      user: {
        __typename: 'users'
        id: any
        first_name?: string | null | undefined
        last_name?: string | null | undefined
        user_contact_details: Array<{
          __typename: 'user_contact_details'
          id: any
          contact_type: string
          contact_info: string
        }>
      }
    }>
  }>
}

export type UpupGenericNotesQueryVariables = Exact<{
  where?: Maybe<Notes_Bool_Exp>
  created_at?: Maybe<Order_By>
}>

export type UpupGenericNotesQuery = {
  __typename: 'query_root'
  notes: Array<{
    __typename: 'notes'
    id: any
    category?: string | null | undefined
    created_at: any
    note: string
    resource_type: string
    resource_id: any
    subject_id?: any | null | undefined
    subject_type?: string | null | undefined
    admin: {
      __typename: 'admins'
      id: any
      first_name: string
      last_name: string
    }
    documents: Array<{
      __typename: 'documents'
      id: any
      external_id: string
      external_source: string
      friendly_name: string
    }>
  }>
}

export type UpupAdminsWithCapabilitiesQueryVariables = Exact<{
  [key: string]: never
}>

export type UpupAdminsWithCapabilitiesQuery = {
  __typename: 'query_root'
  admins: Array<{
    __typename: 'admins'
    id: any
    first_name: string
    last_name: string
    email: string
    admin_capabilities: Array<{
      __typename: 'admin_capabilities'
      id: any
      capability: {
        __typename: 'capabilities'
        id: any
        name: string
        type: string
        description?: string | null | undefined
      }
    }>
  }>
}

export type UpupApplicationListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<
    Array<Rental_Applications_Order_By> | Rental_Applications_Order_By
  >
  where?: Maybe<Rental_Applications_Bool_Exp>
}>

export type UpupApplicationListQuery = {
  __typename: 'query_root'
  rental_applications: Array<{
    __typename: 'rental_applications'
    id: any
    created_at: any
    projected_occupancy_date?: any | null | undefined
    status: string
    agreements: Array<{
      __typename: 'agreements'
      id: any
      status: string
      metadata: any
    }>
    lead_group: {
      __typename: 'lead_groups'
      id: any
      lead_group_users: Array<{
        __typename: 'lead_group_users'
        id: any
        type: string
        user: {
          __typename: 'users'
          id: any
          first_name?: string | null | undefined
          last_name?: string | null | undefined
          payments: Array<{ __typename: 'payments'; id: any }>
          documents: Array<{
            __typename: 'user_documents'
            id?: any | null | undefined
            status?: string | null | undefined
            type?: string | null | undefined
          }>
          credit_reports: Array<{ __typename: 'credit_reports'; id: any }>
          criminal_reports: Array<{ __typename: 'criminal_reports'; id: any }>
          eviction_reports: Array<{ __typename: 'eviction_reports'; id: any }>
          user_incomes: Array<{ __typename: 'user_incomes'; id: any }>
        }
      }>
    }
    mls_listing: {
      __typename: 'mls_listings'
      id: any
      display_line_1?: string | null | undefined
      region?: string | null | undefined
      internal_attributes: Array<{
        __typename: 'mls_listing_internal_attributes'
        id: any
        internal_status?: string | null | undefined
      }>
    }
  }>
}

export type UpupApplicationMlsListingsByFirestoreIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type UpupApplicationMlsListingsByFirestoreIdsQuery = {
  __typename: 'query_root'
  mls_listings: Array<{
    __typename: 'mls_listings'
    state?: string | null | undefined
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      rent?: any | null | undefined
    }>
  }>
}

export type UpupApplicationUserQualQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UpupApplicationUserQualQuery = {
  __typename: 'query_root'
  getUserQualificationData?:
    | {
        __typename: 'UserQualificationData'
        status?: string | null | undefined
      }
    | null
    | undefined
}

export type UpupApplicationMlsListingsByAddressQueryVariables = Exact<{
  address?: Maybe<Scalars['String']>
}>

export type UpupApplicationMlsListingsByAddressQuery = {
  __typename: 'query_root'
  mls_listings: Array<{
    __typename: 'mls_listings'
    baths_weighted?: any | null | undefined
    beds?: number | null | undefined
    display_line_1?: string | null | undefined
    display_line_2?: string | null | undefined
    firestore_id: any
    id: any
    list_price?: any | null | undefined
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      internal_status?: string | null | undefined
      rent?: any | null | undefined
    }>
    photos: Array<{ __typename: 'mls_listing_photos'; url: string }>
  }>
}

export type UpupCapabilitiesWithAdminQueryVariables = Exact<{
  where?: Maybe<Capabilities_Bool_Exp>
}>

export type UpupCapabilitiesWithAdminQuery = {
  __typename: 'query_root'
  capabilities: Array<{
    __typename: 'capabilities'
    id: any
    name: string
    type: string
    description?: string | null | undefined
    admin_capabilities: Array<{
      __typename: 'admin_capabilities'
      id: any
      admin: {
        __typename: 'admins'
        id: any
        first_name: string
        last_name: string
        email: string
      }
    }>
  }>
}

export type CustomerHeaderInfoByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type CustomerHeaderInfoByUserIdQuery = {
  __typename: 'query_root'
  users: Array<{
    __typename: 'users'
    id: any
    created_at?: any | null | undefined
    deleted_at?: any | null | undefined
    rental_users: Array<{ __typename: 'rental_users'; id: any }>
    lead_group_users: Array<{
      __typename: 'lead_group_users'
      id: any
      lead_group_id: any
      rental_applications: Array<{ __typename: 'rental_applications'; id: any }>
    }>
    user_prequalification_data?:
      | { __typename: 'user_prequalification_data'; id: any }
      | null
      | undefined
    user_funnel_status?:
      | {
          __typename: 'user_funnel_statuses'
          id: any
          lead_status?: string | null | undefined
        }
      | null
      | undefined
  }>
}

export type UpupDeleteAdminCapabilityMutationVariables = Exact<{
  id: Scalars['uuid']
}>

export type UpupDeleteAdminCapabilityMutation = {
  __typename: 'mutation_root'
  delete_admin_capabilities_by_pk?:
    | {
        __typename: 'admin_capabilities'
        id: any
        admin_id: any
        capability_id: any
      }
    | null
    | undefined
}

export type DocumentsByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type DocumentsByUserIdQuery = {
  __typename: 'query_root'
  documents: Array<{
    __typename: 'documents'
    id: any
    friendly_name: string
    status: string
    created_at: any
    reviewed_at?: any | null | undefined
    reviewed_by_admin_id?: any | null | undefined
    admin?:
      | { __typename: 'admins'; id: any; first_name: string; last_name: string }
      | null
      | undefined
  }>
}

export type MlsSellerAgentDataFragment = {
  __typename: 'seller_agents'
  close_lead_id?: string | null | undefined
  created_at: any
  do_not_contact?: boolean | null | undefined
  email?: string | null | undefined
  first_name: string
  id: any
  last_contacted_at?: any | null | undefined
  last_name: string
  mls: string
  mls_id: string
  phone?: string | null | undefined
}

export type MlsListingDataFragment = {
  __typename: 'mls_listings'
  area?: string | null | undefined
  baths_full?: number | null | undefined
  baths_half?: number | null | undefined
  baths_total?: number | null | undefined
  beds?: number | null | undefined
  city?: string | null | undefined
  co_listing_office_id?: any | null | undefined
  county?: string | null | undefined
  created_at: any
  delivery_line?: string | null | undefined
  description?: string | null | undefined
  display_line_1?: string | null | undefined
  display_line_2?: string | null | undefined
  features_amenities?: any | null | undefined
  features_appliances_and_equipment?: any | null | undefined
  features_bathrooms?: any | null | undefined
  features_bedrooms?: any | null | undefined
  features_community?: any | null | undefined
  features_cooling?: any | null | undefined
  features_dining_room?: any | null | undefined
  features_disclosures?: any | null | undefined
  features_doors?: any | null | undefined
  features_energy_saving?: any | null | undefined
  features_exterior_features?: any | null | undefined
  features_fees?: any | null | undefined
  features_fence?: any | null | undefined
  features_fireplaces?: any | null | undefined
  features_foundation?: any | null | undefined
  features_garage?: any | null | undefined
  features_heating?: any | null | undefined
  features_home_owners_association?: any | null | undefined
  features_house?: any | null | undefined
  features_interior?: any | null | undefined
  features_interior_features?: any | null | undefined
  features_kitchen?: any | null | undefined
  features_laundry?: any | null | undefined
  features_listing?: any | null | undefined
  features_location?: any | null | undefined
  features_lot?: any | null | undefined
  features_miscellaneous?: any | null | undefined
  features_parking?: any | null | undefined
  features_pool?: any | null | undefined
  features_property?: any | null | undefined
  features_property_access?: any | null | undefined
  features_roof?: any | null | undefined
  features_schools?: any | null | undefined
  features_security_system?: any | null | undefined
  features_sewer?: any | null | undefined
  features_spa?: any | null | undefined
  features_taxes?: any | null | undefined
  features_utilities?: any | null | undefined
  features_water?: any | null | undefined
  features_windows_and_doors?: any | null | undefined
  geo_type?: string | null | undefined
  id: any
  firestore_id: any
  is_new_construction?: boolean | null | undefined
  last_updated_at?: any | null | undefined
  lat?: number | null | undefined
  list_price?: any | null | undefined
  listing_date?: any | null | undefined
  listing_office_id?: any | null | undefined
  listing_type?: string | null | undefined
  lng?: number | null | undefined
  lotsize_acres?: number | null | undefined
  lotsize_sqft?: any | null | undefined
  market_id: any
  mls: string
  modified_at?: any | null | undefined
  property_type?: string | null | undefined
  provider_id?: string | null | undefined
  provider?: string | null | undefined
  region?: string | null | undefined
  school_district?: string | null | undefined
  school_elementary?: string | null | undefined
  school_high?: string | null | undefined
  school_middle?: string | null | undefined
  short_id: string
  size?: string | null | undefined
  state?: string | null | undefined
  status?: string | null | undefined
  std_address?: any | null | undefined
  street?: string | null | undefined
  style?: string | null | undefined
  subdivision?: string | null | undefined
  system_id?: string | null | undefined
  tour_url?: string | null | undefined
  updated_at: any
  xf_attributes?: any | null | undefined
  year_built?: number | null | undefined
  zip?: string | null | undefined
  co_listing_agent?:
    | {
        __typename: 'seller_agents'
        close_lead_id?: string | null | undefined
        created_at: any
        do_not_contact?: boolean | null | undefined
        email?: string | null | undefined
        first_name: string
        id: any
        last_contacted_at?: any | null | undefined
        last_name: string
        mls: string
        mls_id: string
        phone?: string | null | undefined
      }
    | null
    | undefined
  internal_attributes: Array<{
    __typename: 'mls_listing_internal_attributes'
    analyzed_images?: any | null | undefined
    census_tract_county_code?: string | null | undefined
    census_tract_income_level?: string | null | undefined
    census_tract_median_family_income?: number | null | undefined
    census_tract_percent_below_poverty_line?: number | null | undefined
    census_tract_tract_code?: string | null | undefined
    created_at: any
    guidance_price?: any | null | undefined
    id: any
    internal_status?: string | null | undefined
    is_census_tract_opportunity_zone?: boolean | null | undefined
    is_in_customer_buy_box: boolean
    is_in_real_estate_buy_box: boolean
    is_persisted_in_algolia: boolean
    mls_listing_id: any
    neighborhood_levels?: any | null | undefined
    percent_over_comp?: number | null | undefined
    rating_base?: number | null | undefined
    rent?: any | null | undefined
    short_list_images?: any | null | undefined
    tags?: any | null | undefined
    updated_at: any
    url?: string | null | undefined
    zillow_deep_search_results?: any | null | undefined
  }>
  listing_agent?:
    | {
        __typename: 'seller_agents'
        close_lead_id?: string | null | undefined
        created_at: any
        do_not_contact?: boolean | null | undefined
        email?: string | null | undefined
        first_name: string
        id: any
        last_contacted_at?: any | null | undefined
        last_name: string
        mls: string
        mls_id: string
        phone?: string | null | undefined
      }
    | null
    | undefined
  photos: Array<{
    __typename: 'mls_listing_photos'
    created_at: any
    display_order?: number | null | undefined
    id: any
    is_deleted?: boolean | null | undefined
    updated_at: any
    url: string
  }>
}

export type UpupInitialReviewMlsListingsByFirestoreIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type UpupInitialReviewMlsListingsByFirestoreIdsQuery = {
  __typename: 'query_root'
  mls_listings: Array<{
    __typename: 'mls_listings'
    area?: string | null | undefined
    baths_full?: number | null | undefined
    baths_half?: number | null | undefined
    baths_total?: number | null | undefined
    beds?: number | null | undefined
    city?: string | null | undefined
    co_listing_office_id?: any | null | undefined
    county?: string | null | undefined
    created_at: any
    delivery_line?: string | null | undefined
    description?: string | null | undefined
    display_line_1?: string | null | undefined
    display_line_2?: string | null | undefined
    features_amenities?: any | null | undefined
    features_appliances_and_equipment?: any | null | undefined
    features_bathrooms?: any | null | undefined
    features_bedrooms?: any | null | undefined
    features_community?: any | null | undefined
    features_cooling?: any | null | undefined
    features_dining_room?: any | null | undefined
    features_disclosures?: any | null | undefined
    features_doors?: any | null | undefined
    features_energy_saving?: any | null | undefined
    features_exterior_features?: any | null | undefined
    features_fees?: any | null | undefined
    features_fence?: any | null | undefined
    features_fireplaces?: any | null | undefined
    features_foundation?: any | null | undefined
    features_garage?: any | null | undefined
    features_heating?: any | null | undefined
    features_home_owners_association?: any | null | undefined
    features_house?: any | null | undefined
    features_interior?: any | null | undefined
    features_interior_features?: any | null | undefined
    features_kitchen?: any | null | undefined
    features_laundry?: any | null | undefined
    features_listing?: any | null | undefined
    features_location?: any | null | undefined
    features_lot?: any | null | undefined
    features_miscellaneous?: any | null | undefined
    features_parking?: any | null | undefined
    features_pool?: any | null | undefined
    features_property?: any | null | undefined
    features_property_access?: any | null | undefined
    features_roof?: any | null | undefined
    features_schools?: any | null | undefined
    features_security_system?: any | null | undefined
    features_sewer?: any | null | undefined
    features_spa?: any | null | undefined
    features_taxes?: any | null | undefined
    features_utilities?: any | null | undefined
    features_water?: any | null | undefined
    features_windows_and_doors?: any | null | undefined
    geo_type?: string | null | undefined
    id: any
    firestore_id: any
    is_new_construction?: boolean | null | undefined
    last_updated_at?: any | null | undefined
    lat?: number | null | undefined
    list_price?: any | null | undefined
    listing_date?: any | null | undefined
    listing_office_id?: any | null | undefined
    listing_type?: string | null | undefined
    lng?: number | null | undefined
    lotsize_acres?: number | null | undefined
    lotsize_sqft?: any | null | undefined
    market_id: any
    mls: string
    modified_at?: any | null | undefined
    property_type?: string | null | undefined
    provider_id?: string | null | undefined
    provider?: string | null | undefined
    region?: string | null | undefined
    school_district?: string | null | undefined
    school_elementary?: string | null | undefined
    school_high?: string | null | undefined
    school_middle?: string | null | undefined
    short_id: string
    size?: string | null | undefined
    state?: string | null | undefined
    status?: string | null | undefined
    std_address?: any | null | undefined
    street?: string | null | undefined
    style?: string | null | undefined
    subdivision?: string | null | undefined
    system_id?: string | null | undefined
    tour_url?: string | null | undefined
    updated_at: any
    xf_attributes?: any | null | undefined
    year_built?: number | null | undefined
    zip?: string | null | undefined
    co_listing_agent?:
      | {
          __typename: 'seller_agents'
          close_lead_id?: string | null | undefined
          created_at: any
          do_not_contact?: boolean | null | undefined
          email?: string | null | undefined
          first_name: string
          id: any
          last_contacted_at?: any | null | undefined
          last_name: string
          mls: string
          mls_id: string
          phone?: string | null | undefined
        }
      | null
      | undefined
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      analyzed_images?: any | null | undefined
      census_tract_county_code?: string | null | undefined
      census_tract_income_level?: string | null | undefined
      census_tract_median_family_income?: number | null | undefined
      census_tract_percent_below_poverty_line?: number | null | undefined
      census_tract_tract_code?: string | null | undefined
      created_at: any
      guidance_price?: any | null | undefined
      id: any
      internal_status?: string | null | undefined
      is_census_tract_opportunity_zone?: boolean | null | undefined
      is_in_customer_buy_box: boolean
      is_in_real_estate_buy_box: boolean
      is_persisted_in_algolia: boolean
      mls_listing_id: any
      neighborhood_levels?: any | null | undefined
      percent_over_comp?: number | null | undefined
      rating_base?: number | null | undefined
      rent?: any | null | undefined
      short_list_images?: any | null | undefined
      tags?: any | null | undefined
      updated_at: any
      url?: string | null | undefined
      zillow_deep_search_results?: any | null | undefined
    }>
    listing_agent?:
      | {
          __typename: 'seller_agents'
          close_lead_id?: string | null | undefined
          created_at: any
          do_not_contact?: boolean | null | undefined
          email?: string | null | undefined
          first_name: string
          id: any
          last_contacted_at?: any | null | undefined
          last_name: string
          mls: string
          mls_id: string
          phone?: string | null | undefined
        }
      | null
      | undefined
    photos: Array<{
      __typename: 'mls_listing_photos'
      created_at: any
      display_order?: number | null | undefined
      id: any
      is_deleted?: boolean | null | undefined
      updated_at: any
      url: string
    }>
  }>
}

export type UpupMlsListingsByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
}>

export type UpupMlsListingsByIdsQuery = {
  __typename: 'query_root'
  mls_listings: Array<{
    __typename: 'mls_listings'
    area?: string | null | undefined
    baths_full?: number | null | undefined
    baths_half?: number | null | undefined
    baths_total?: number | null | undefined
    beds?: number | null | undefined
    city?: string | null | undefined
    co_listing_office_id?: any | null | undefined
    county?: string | null | undefined
    created_at: any
    delivery_line?: string | null | undefined
    description?: string | null | undefined
    display_line_1?: string | null | undefined
    display_line_2?: string | null | undefined
    features_amenities?: any | null | undefined
    features_appliances_and_equipment?: any | null | undefined
    features_bathrooms?: any | null | undefined
    features_bedrooms?: any | null | undefined
    features_community?: any | null | undefined
    features_cooling?: any | null | undefined
    features_dining_room?: any | null | undefined
    features_disclosures?: any | null | undefined
    features_doors?: any | null | undefined
    features_energy_saving?: any | null | undefined
    features_exterior_features?: any | null | undefined
    features_fees?: any | null | undefined
    features_fence?: any | null | undefined
    features_fireplaces?: any | null | undefined
    features_foundation?: any | null | undefined
    features_garage?: any | null | undefined
    features_heating?: any | null | undefined
    features_home_owners_association?: any | null | undefined
    features_house?: any | null | undefined
    features_interior?: any | null | undefined
    features_interior_features?: any | null | undefined
    features_kitchen?: any | null | undefined
    features_laundry?: any | null | undefined
    features_listing?: any | null | undefined
    features_location?: any | null | undefined
    features_lot?: any | null | undefined
    features_miscellaneous?: any | null | undefined
    features_parking?: any | null | undefined
    features_pool?: any | null | undefined
    features_property?: any | null | undefined
    features_property_access?: any | null | undefined
    features_roof?: any | null | undefined
    features_schools?: any | null | undefined
    features_security_system?: any | null | undefined
    features_sewer?: any | null | undefined
    features_spa?: any | null | undefined
    features_taxes?: any | null | undefined
    features_utilities?: any | null | undefined
    features_water?: any | null | undefined
    features_windows_and_doors?: any | null | undefined
    geo_type?: string | null | undefined
    id: any
    firestore_id: any
    is_new_construction?: boolean | null | undefined
    last_updated_at?: any | null | undefined
    lat?: number | null | undefined
    list_price?: any | null | undefined
    listing_date?: any | null | undefined
    listing_office_id?: any | null | undefined
    listing_type?: string | null | undefined
    lng?: number | null | undefined
    lotsize_acres?: number | null | undefined
    lotsize_sqft?: any | null | undefined
    market_id: any
    mls: string
    modified_at?: any | null | undefined
    property_type?: string | null | undefined
    provider_id?: string | null | undefined
    provider?: string | null | undefined
    region?: string | null | undefined
    school_district?: string | null | undefined
    school_elementary?: string | null | undefined
    school_high?: string | null | undefined
    school_middle?: string | null | undefined
    short_id: string
    size?: string | null | undefined
    state?: string | null | undefined
    status?: string | null | undefined
    std_address?: any | null | undefined
    street?: string | null | undefined
    style?: string | null | undefined
    subdivision?: string | null | undefined
    system_id?: string | null | undefined
    tour_url?: string | null | undefined
    updated_at: any
    xf_attributes?: any | null | undefined
    year_built?: number | null | undefined
    zip?: string | null | undefined
    co_listing_agent?:
      | {
          __typename: 'seller_agents'
          close_lead_id?: string | null | undefined
          created_at: any
          do_not_contact?: boolean | null | undefined
          email?: string | null | undefined
          first_name: string
          id: any
          last_contacted_at?: any | null | undefined
          last_name: string
          mls: string
          mls_id: string
          phone?: string | null | undefined
        }
      | null
      | undefined
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      analyzed_images?: any | null | undefined
      census_tract_county_code?: string | null | undefined
      census_tract_income_level?: string | null | undefined
      census_tract_median_family_income?: number | null | undefined
      census_tract_percent_below_poverty_line?: number | null | undefined
      census_tract_tract_code?: string | null | undefined
      created_at: any
      guidance_price?: any | null | undefined
      id: any
      internal_status?: string | null | undefined
      is_census_tract_opportunity_zone?: boolean | null | undefined
      is_in_customer_buy_box: boolean
      is_in_real_estate_buy_box: boolean
      is_persisted_in_algolia: boolean
      mls_listing_id: any
      neighborhood_levels?: any | null | undefined
      percent_over_comp?: number | null | undefined
      rating_base?: number | null | undefined
      rent?: any | null | undefined
      short_list_images?: any | null | undefined
      tags?: any | null | undefined
      updated_at: any
      url?: string | null | undefined
      zillow_deep_search_results?: any | null | undefined
    }>
    listing_agent?:
      | {
          __typename: 'seller_agents'
          close_lead_id?: string | null | undefined
          created_at: any
          do_not_contact?: boolean | null | undefined
          email?: string | null | undefined
          first_name: string
          id: any
          last_contacted_at?: any | null | undefined
          last_name: string
          mls: string
          mls_id: string
          phone?: string | null | undefined
        }
      | null
      | undefined
    photos: Array<{
      __typename: 'mls_listing_photos'
      created_at: any
      display_order?: number | null | undefined
      id: any
      is_deleted?: boolean | null | undefined
      updated_at: any
      url: string
    }>
  }>
}

export type UpupInsertAdminCapabilityMutationVariables = Exact<{
  admin_id?: Maybe<Scalars['uuid']>
  capability_id?: Maybe<Scalars['uuid']>
}>

export type UpupInsertAdminCapabilityMutation = {
  __typename: 'mutation_root'
  insert_admin_capabilities_one?:
    | {
        __typename: 'admin_capabilities'
        id: any
        admin_id: any
        capability_id: any
      }
    | null
    | undefined
}

export type UpupInsertAdminMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}>

export type UpupInsertAdminMutation = {
  __typename: 'mutation_root'
  insert_admins_one?:
    | {
        __typename: 'admins'
        id: any
        email: string
        first_name: string
        last_name: string
      }
    | null
    | undefined
}

export type UpupInsertCapabilityMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}>

export type UpupInsertCapabilityMutation = {
  __typename: 'mutation_root'
  insert_capabilities_one?:
    | {
        __typename: 'capabilities'
        id: any
        name: string
        type: string
        description?: string | null | undefined
      }
    | null
    | undefined
}

export type UpupInsertDocumentMutationVariables = Exact<{
  external_id?: Maybe<Scalars['String']>
  external_source?: Maybe<Scalars['String']>
  friendly_name?: Maybe<Scalars['String']>
  resource_id?: Maybe<Scalars['uuid']>
  resource_type?: Maybe<Scalars['String']>
  review_notes?: Maybe<Scalars['String']>
  reviewed_at?: Maybe<Scalars['timestamptz']>
  reviewed_by_admin_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}>

export type UpupInsertDocumentMutation = {
  __typename: 'mutation_root'
  insert_documents_one?: { __typename: 'documents'; id: any } | null | undefined
}

export type UpupInsertNoteMutationVariables = Exact<{
  category?: Maybe<Scalars['String']>
  created_by_admin_id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  resource_id?: Maybe<Scalars['uuid']>
  resource_type?: Maybe<Scalars['String']>
}>

export type UpupInsertNoteMutation = {
  __typename: 'mutation_root'
  insert_notes_one?: { __typename: 'notes'; id: any } | null | undefined
}

export type UpupInsertRentalCreditMutationVariables = Exact<{
  amount?: Maybe<Scalars['numeric']>
  date?: Maybe<Scalars['date']>
  note?: Maybe<Scalars['String']>
  offsetting_ledger_account_id?: Maybe<Scalars['uuid']>
  rental_id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
}>

export type UpupInsertRentalCreditMutation = {
  __typename: 'mutation_root'
  insert_rental_credits_one?:
    | { __typename: 'rental_credits'; id: any; amount: any; date: any }
    | null
    | undefined
}

export type LeadGroupByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type LeadGroupByUserIdQuery = {
  __typename: 'query_root'
  lead_group_users: Array<{
    __typename: 'lead_group_users'
    id: any
    lead_group: {
      __typename: 'lead_groups'
      id: any
      status: string
      created_at?: any | null | undefined
    }
  }>
}

export type LeadGroupUsersByLeadGroupIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type LeadGroupUsersByLeadGroupIdQuery = {
  __typename: 'query_root'
  lead_group_users: Array<{
    __typename: 'lead_group_users'
    id: any
    user_id: any
    type: string
    user: {
      __typename: 'users'
      id: any
      first_name?: string | null | undefined
      last_name?: string | null | undefined
    }
    emails: Array<{
      __typename: 'user_contact_details'
      id: any
      contact_info: string
    }>
  }>
}

export type LeasingStaffByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type LeasingStaffByUserIdQuery = {
  __typename: 'query_root'
  agent_user_owners: Array<{
    __typename: 'agent_user_owners'
    id: any
    agent_type: string
    admin?:
      | { __typename: 'admins'; id: any; first_name: string; last_name: string }
      | null
      | undefined
  }>
}

export type UpupOverwriteAdminCapabilitiesMutationVariables = Exact<{
  ids_to_remove?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>
  objects_to_add:
    | Array<Admin_Capabilities_Insert_Input>
    | Admin_Capabilities_Insert_Input
}>

export type UpupOverwriteAdminCapabilitiesMutation = {
  __typename: 'mutation_root'
  delete_admin_capabilities?:
    | {
        __typename: 'admin_capabilities_mutation_response'
        affected_rows: number
      }
    | null
    | undefined
  insert_admin_capabilities?:
    | {
        __typename: 'admin_capabilities_mutation_response'
        returning: Array<{
          __typename: 'admin_capabilities'
          id: any
          admin_id: any
          capability_id: any
        }>
      }
    | null
    | undefined
}

export type PersonalDetailsByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type PersonalDetailsByUserIdQuery = {
  __typename: 'query_root'
  users: Array<{
    __typename: 'users'
    id: any
    first_name?: string | null | undefined
    last_name?: string | null | undefined
    emails: Array<{
      __typename: 'user_contact_details'
      id: any
      contact_info: string
      is_primary_for_contact_type: boolean
    }>
    phones: Array<{
      __typename: 'user_contact_details'
      id: any
      contact_info: string
      is_primary_for_contact_type: boolean
    }>
    user_funnel_status?:
      | {
          __typename: 'user_funnel_statuses'
          id: any
          lead_status?: string | null | undefined
        }
      | null
      | undefined
    credit_reports: Array<{
      __typename: 'credit_reports'
      id: any
      score?: number | null | undefined
    }>
  }>
}

export type PrequalificationDetailsByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type PrequalificationDetailsByUserIdQuery = {
  __typename: 'query_root'
  user_prequalification_data: Array<{
    __typename: 'user_prequalification_data'
    id: any
    prequalification_status: string
    is_section_eight?: boolean | null | undefined
    is_ok_with_two_year_lease?: boolean | null | undefined
    is_income_qualified?: boolean | null | undefined
    is_credit_qualified?: boolean | null | undefined
    has_cosigner?: boolean | null | undefined
    has_coapplicant?: boolean | null | undefined
  }>
}

export type UpupProcessedUtilityBillsSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type UpupProcessedUtilityBillsSubscription = {
  __typename: 'subscription_root'
  kafka_connector_prorated_utility_bill_line_items: Array<{
    __typename: 'kafka_connector_prorated_utility_bill_line_items'
    buildium_csv: string
    id: any
    rental_liabilities: any
    transformations: any
    original_upload_url: string
    created_at: any
    rental_liabilities_csv: string
  }>
}

export type UpUpPropertyInformationForUtilityBillsQueryVariables = Exact<{
  [key: string]: never
}>

export type UpUpPropertyInformationForUtilityBillsQuery = {
  __typename: 'query_root'
  llcs: Array<{
    __typename: 'llcs'
    id: any
    name: string
    abbreviation?: string | null | undefined
  }>
  llc_properties: Array<{
    __typename: 'llc_properties'
    start_date: any
    end_date?: any | null | undefined
    llc: { __typename: 'llcs'; id: any }
    property: {
      __typename: 'properties'
      id: any
      rentals: Array<{
        __typename: 'rentals'
        final_liability_date?: any | null | undefined
        occupancy_date: any
        user_id: any
        id: any
      }>
      real_estate_acquisition?:
        | {
            __typename: 'real_estate_acquisitions'
            mls_listing: {
              __typename: 'mls_listings'
              display_line_1?: string | null | undefined
              street?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              zip?: string | null | undefined
              id: any
            }
          }
        | null
        | undefined
    }
  }>
  funds: Array<{
    __typename: 'funds'
    id: any
    name: string
    abbreviation?: string | null | undefined
  }>
  fund_llcs: Array<{ __typename: 'fund_llcs'; fund_id: any; llc_id: any }>
  real_estate_acquisitions: Array<{
    __typename: 'real_estate_acquisitions'
    canceled_at?: any | null | undefined
    closing_date?: any | null | undefined
    llc_id?: any | null | undefined
    status: string
    possession_at?: any | null | undefined
    utilities_activation_date?: any | null | undefined
    mls_listing: {
      __typename: 'mls_listings'
      display_line_1?: string | null | undefined
      street?: string | null | undefined
      city?: string | null | undefined
      state?: string | null | undefined
      zip?: string | null | undefined
      id: any
    }
  }>
}

export type UpupRentalByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid']
}>

export type UpupRentalByIdSubscription = {
  __typename: 'subscription_root'
  rentals: Array<{
    __typename: 'rentals'
    id: any
    auto_charge: boolean
    final_liability_date?: any | null | undefined
    initial_option_premium: number
    is_card_payment_allowed: boolean
    lease_end_reason?: string | null | undefined
    move_out_date?: any | null | undefined
    occupancy_date: any
    status: string
    target_monthly_option_premium: number
    property: {
      __typename: 'properties'
      id: any
      llc_properties: Array<{
        __typename: 'llc_properties'
        id: any
        llc: { __typename: 'llcs'; id: any; name: string }
      }>
      real_estate_acquisition?:
        | {
            __typename: 'real_estate_acquisitions'
            id: any
            mls_listing: {
              __typename: 'mls_listings'
              id: any
              display_line_1?: string | null | undefined
              display_line_2?: string | null | undefined
            }
          }
        | null
        | undefined
    }
    payments: Array<{
      __typename: 'payments'
      amount: any
      available_on?: any | null | undefined
      category: string
      created_at?: any | null | undefined
      id: any
      payment_date?: any | null | undefined
      payment_method?: string | null | undefined
      status: string
      lifecycle: string
      initiated_at?: any | null | undefined
      liability_type: {
        __typename: 'liability_types'
        id: any
        name: string
        payment_category?: string | null | undefined
      }
      user: {
        __typename: 'users'
        id: any
        first_name?: string | null | undefined
        last_name?: string | null | undefined
      }
    }>
    rental_credits: Array<{
      __typename: 'rental_credits'
      id: any
      amount: any
      date: any
    }>
    rental_add_ons: Array<{
      __typename: 'rental_add_ons'
      id: any
      end_date?: any | null | undefined
      price: any
      start_date: any
      liability_type: {
        __typename: 'liability_types'
        id: any
        name: string
        payment_category?: string | null | undefined
      }
    }>
    rental_agreement_histories: Array<{
      __typename: 'rental_agreement_histories'
      id: any
      option_premium: any
      rent: any
      starts_at: any
      ends_at: any
    }>
    rental_application: {
      __typename: 'rental_applications'
      agreements: Array<{
        __typename: 'agreements'
        type: string
        id: any
        metadata: any
        documents: Array<{
          __typename: 'documents'
          id: any
          created_at: any
          external_id: string
          external_source: string
          friendly_name: string
          review_notes?: string | null | undefined
          reviewed_at?: any | null | undefined
          status: string
          admin?:
            | {
                __typename: 'admins'
                id: any
                first_name: string
                last_name: string
              }
            | null
            | undefined
        }>
        agreement_signers: Array<{
          __typename: 'agreement_signers'
          id: any
          role: string
          user?:
            | {
                __typename: 'users'
                id: any
                first_name?: string | null | undefined
                last_name?: string | null | undefined
                user_contact_details: Array<{
                  __typename: 'user_contact_details'
                  id: any
                  contact_type: string
                  contact_info: string
                }>
              }
            | null
            | undefined
        }>
      }>
      mls_listing: {
        __typename: 'mls_listings'
        id: any
        display_line_1?: string | null | undefined
        display_line_2?: string | null | undefined
      }
    }
    rental_liabilities: Array<{
      __typename: 'rental_liabilities'
      amount: any
      date: any
      id: any
      liability_type: {
        __typename: 'liability_types'
        id: any
        name: string
        payment_category?: string | null | undefined
      }
    }>
    user: {
      __typename: 'users'
      id: any
      first_name?: string | null | undefined
      last_name?: string | null | undefined
      plaid_access_token_invalid_at?: any | null | undefined
      user_contact_details: Array<{
        __typename: 'user_contact_details'
        id: any
        contact_type: string
        contact_info: string
      }>
    }
  }>
}

export type UpupRentalListQueryVariables = Exact<{ [key: string]: never }>

export type UpupRentalListQuery = {
  __typename: 'query_root'
  rentals: Array<{
    __typename: 'rentals'
    id: any
    auto_charge: boolean
    final_liability_date?: any | null | undefined
    initial_option_premium: number
    is_card_payment_allowed: boolean
    lease_end_reason?: string | null | undefined
    move_out_date?: any | null | undefined
    occupancy_date: any
    status: string
    target_monthly_option_premium: number
    property: {
      __typename: 'properties'
      id: any
      llc_properties: Array<{
        __typename: 'llc_properties'
        id: any
        llc: { __typename: 'llcs'; id: any; name: string }
      }>
      real_estate_acquisition?:
        | {
            __typename: 'real_estate_acquisitions'
            id: any
            mls_listing: {
              __typename: 'mls_listings'
              id: any
              display_line_1?: string | null | undefined
              display_line_2?: string | null | undefined
            }
          }
        | null
        | undefined
    }
    payments: Array<{
      __typename: 'payments'
      amount: any
      available_on?: any | null | undefined
      category: string
      created_at?: any | null | undefined
      id: any
      payment_date?: any | null | undefined
      payment_method?: string | null | undefined
      status: string
      lifecycle: string
      initiated_at?: any | null | undefined
      liability_type: {
        __typename: 'liability_types'
        id: any
        name: string
        payment_category?: string | null | undefined
      }
    }>
    rental_add_ons: Array<{
      __typename: 'rental_add_ons'
      id: any
      end_date?: any | null | undefined
      price: any
      start_date: any
      liability_type: {
        __typename: 'liability_types'
        id: any
        name: string
        payment_category?: string | null | undefined
      }
    }>
    rental_credits: Array<{
      __typename: 'rental_credits'
      id: any
      amount: any
      date: any
    }>
    rental_agreement_histories: Array<{
      __typename: 'rental_agreement_histories'
      id: any
      option_premium: any
      rent: any
      starts_at: any
      ends_at: any
    }>
    rental_application: {
      __typename: 'rental_applications'
      id: any
      lead_group: { __typename: 'lead_groups'; id: any }
      agreements: Array<{
        __typename: 'agreements'
        id: any
        type: string
        metadata: any
      }>
      mls_listing: {
        __typename: 'mls_listings'
        id: any
        display_line_1?: string | null | undefined
        display_line_2?: string | null | undefined
      }
    }
    rental_liabilities: Array<{
      __typename: 'rental_liabilities'
      amount: any
      date: any
      id: any
      liability_type: {
        __typename: 'liability_types'
        id: any
        name: string
        payment_category?: string | null | undefined
      }
    }>
    user: {
      __typename: 'users'
      id: any
      first_name?: string | null | undefined
      last_name?: string | null | undefined
      plaid_access_token_invalid_at?: any | null | undefined
      user_contact_details: Array<{
        __typename: 'user_contact_details'
        id: any
        contact_type: string
        contact_info: string
      }>
    }
  }>
}

export type UpupUserListSubscriptionVariables = Exact<{
  where?: Maybe<Users_Bool_Exp>
}>

export type UpupUserListSubscription = {
  __typename: 'subscription_root'
  users: Array<{
    __typename: 'users'
    id: any
    first_name?: string | null | undefined
    last_name?: string | null | undefined
    user_contact_details: Array<{
      __typename: 'user_contact_details'
      id: any
      contact_type: string
      contact_info: string
    }>
  }>
}

export type UpUpAddProcessedUtilityBillMutationVariables = Exact<{
  transformations?: Maybe<Scalars['json']>
  rental_liabilities?: Maybe<Scalars['json']>
  original_upload_url?: Maybe<Scalars['String']>
  buildium_csv?: Maybe<Scalars['String']>
  rental_liabilities_csv?: Maybe<Scalars['String']>
}>

export type UpUpAddProcessedUtilityBillMutation = {
  __typename: 'mutation_root'
  insert_kafka_connector_prorated_utility_bill_line_items_one?:
    | {
        __typename: 'kafka_connector_prorated_utility_bill_line_items'
        id: any
      }
    | null
    | undefined
}

export type FindPlaidIncomeVerificationByIdQueryQueryVariables = Exact<{
  plaidIncomeVerificationId: Scalars['uuid']
}>

export type FindPlaidIncomeVerificationByIdQueryQuery = {
  __typename: 'query_root'
  plaid_income_verifications: Array<{
    __typename: 'plaid_income_verifications'
    id: any
    income_response?: any | null | undefined
    income_verification_id?: string | null | undefined
    link_token_request_id: string
    income_verification_request_id?: string | null | undefined
    paystub_data?: any | null | undefined
    processed_at?: any | null | undefined
    summary_data?: any | null | undefined
    updated_at: any
    user: {
      __typename: 'users'
      id: any
      first_name?: string | null | undefined
      last_name?: string | null | undefined
    }
    documents: Array<{
      __typename: 'plaid_income_verification_documents'
      id?: any | null | undefined
      external_id?: string | null | undefined
      external_source?: string | null | undefined
      friendly_name?: string | null | undefined
      type?: string | null | undefined
    }>
  }>
}

export type Upup_Properties_MlsListingsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Mls_Listings_Order_By> | Mls_Listings_Order_By>
  where?: Maybe<Mls_Listings_Bool_Exp>
}>

export type Upup_Properties_MlsListingsQuery = {
  __typename: 'query_root'
  mls_listings: Array<{
    __typename: 'mls_listings'
    id: any
    area?: string | null | undefined
    city?: string | null | undefined
    display_line_1?: string | null | undefined
    firestore_id: any
    listing_date?: any | null | undefined
    list_price?: any | null | undefined
    mls: string
    provider?: string | null | undefined
    state?: string | null | undefined
    zip?: string | null | undefined
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      internal_status?: string | null | undefined
    }>
  }>
}

export type Upup_LiabilityTypesQueryVariables = Exact<{ [key: string]: never }>

export type Upup_LiabilityTypesQuery = {
  __typename: 'query_root'
  liability_types: Array<{
    __typename: 'liability_types'
    id: any
    is_variable: boolean
    name: string
    price?: any | null | undefined
    recurring: boolean
  }>
}

export type Upup_CreateRentalAddOnMutationVariables = Exact<{
  liability_type_id?: Maybe<Scalars['uuid']>
  rental_id?: Maybe<Scalars['uuid']>
  start_date?: Maybe<Scalars['date']>
  price?: Maybe<Scalars['numeric']>
}>

export type Upup_CreateRentalAddOnMutation = {
  __typename: 'mutation_root'
  insert_rental_add_ons_one?:
    | {
        __typename: 'rental_add_ons'
        id: any
        end_date?: any | null | undefined
        price: any
        start_date: any
        liability_type: { __typename: 'liability_types'; name: string }
      }
    | null
    | undefined
}

export type Upup_SetRentalAddOnEndDateMutationVariables = Exact<{
  end_date?: Maybe<Scalars['date']>
  id: Scalars['uuid']
}>

export type Upup_SetRentalAddOnEndDateMutation = {
  __typename: 'mutation_root'
  update_rental_add_ons_by_pk?:
    | {
        __typename: 'rental_add_ons'
        id: any
        end_date?: any | null | undefined
        price: any
        start_date: any
        liability_type: { __typename: 'liability_types'; name: string }
      }
    | null
    | undefined
}

export type Upup_CreateRentalAgreementHistoryMutationVariables = Exact<{
  rental_id?: Maybe<Scalars['uuid']>
  rent?: Maybe<Scalars['numeric']>
  starts_at?: Maybe<Scalars['date']>
  ends_at?: Maybe<Scalars['date']>
}>

export type Upup_CreateRentalAgreementHistoryMutation = {
  __typename: 'mutation_root'
  insert_rental_agreement_histories_one?:
    | {
        __typename: 'rental_agreement_histories'
        ends_at: any
        rent: any
        rental_id: any
        starts_at: any
      }
    | null
    | undefined
}

export type Upup_CreateRentalLiabilityMutationVariables = Exact<{
  liability_type_id?: Maybe<Scalars['uuid']>
  rental_id?: Maybe<Scalars['uuid']>
  amount?: Maybe<Scalars['numeric']>
  date?: Maybe<Scalars['date']>
  note?: Maybe<Scalars['String']>
}>

export type Upup_CreateRentalLiabilityMutation = {
  __typename: 'mutation_root'
  insert_rental_liabilities_one?:
    | {
        __typename: 'rental_liabilities'
        amount: any
        date: any
        liability_type: { __typename: 'liability_types'; name: string }
      }
    | null
    | undefined
}

export type Upup_EditRentalAgreementHistoryMutationVariables = Exact<{
  ends_at?: Maybe<Scalars['date']>
  id: Scalars['uuid']
}>

export type Upup_EditRentalAgreementHistoryMutation = {
  __typename: 'mutation_root'
  update_rental_agreement_histories_by_pk?:
    | { __typename: 'rental_agreement_histories'; id: any; ends_at: any }
    | null
    | undefined
}

export type Upup_SetFinalLiabilityDateMutationVariables = Exact<{
  final_liability_date?: Maybe<Scalars['date']>
  id: Scalars['uuid']
}>

export type Upup_SetFinalLiabilityDateMutation = {
  __typename: 'mutation_root'
  update_rentals_by_pk?:
    | {
        __typename: 'rentals'
        id: any
        final_liability_date?: any | null | undefined
      }
    | null
    | undefined
}

export type Upup_LedgerAccountsQueryVariables = Exact<{ [key: string]: never }>

export type Upup_LedgerAccountsQuery = {
  __typename: 'query_root'
  ledger_accounts: Array<{
    __typename: 'ledger_accounts'
    id: any
    name: string
    rental_credit_type?: string | null | undefined
  }>
}

export type Upup_SetMoveOutDateMutationVariables = Exact<{
  lease_end_reason?: Maybe<Scalars['String']>
  move_out_date?: Maybe<Scalars['date']>
  status?: Maybe<Scalars['String']>
  id: Scalars['uuid']
}>

export type Upup_SetMoveOutDateMutation = {
  __typename: 'mutation_root'
  update_rentals_by_pk?:
    | {
        __typename: 'rentals'
        id: any
        lease_end_reason?: string | null | undefined
        move_out_date?: any | null | undefined
        status: string
      }
    | null
    | undefined
}

export type Upup_SetAutoChargeMutationVariables = Exact<{
  auto_charge?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
}>

export type Upup_SetAutoChargeMutation = {
  __typename: 'mutation_root'
  update_rentals_by_pk?:
    | { __typename: 'rentals'; id: any; auto_charge: boolean }
    | null
    | undefined
}

export type Upup_SetCardPaymentMutationVariables = Exact<{
  is_card_payment_allowed?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
}>

export type Upup_SetCardPaymentMutation = {
  __typename: 'mutation_root'
  update_rentals_by_pk?:
    | { __typename: 'rentals'; id: any; is_card_payment_allowed: boolean }
    | null
    | undefined
}

export type Upup_SellerAgents_ListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>
  where?: Maybe<Seller_Agents_Bool_Exp>
}>

export type Upup_SellerAgents_ListQuery = {
  __typename: 'query_root'
  seller_agents: Array<{
    __typename: 'seller_agents'
    id: any
    email?: string | null | undefined
    first_name: string
    last_name: string
    phone?: string | null | undefined
  }>
}

export type UpUpUserTimelineByUserIdQueryVariables = Exact<{
  id: Scalars['uuid']
  with_property_details: Scalars['Boolean']
  with_conversations: Scalars['Boolean']
  with_tours: Scalars['Boolean']
  with_rental_app_events: Scalars['Boolean']
  with_rental_site_leads: Scalars['Boolean']
}>

export type UpUpUserTimelineByUserIdQuery = {
  __typename: 'query_root'
  users: Array<{
    __typename: 'users'
    id: any
    first_name?: string | null | undefined
    last_name?: string | null | undefined
    lead_group_users: Array<{
      __typename: 'lead_group_users'
      id: any
      lead_group: {
        __typename: 'lead_groups'
        id: any
        tours?: Array<{
          __typename: 'tours'
          id: any
          status: string
          type?: string | null | undefined
          start_at: any
          created_at: any
          completed_at?: any | null | undefined
          canceled_at?: any | null | undefined
          lead_group_confirmed_at?: any | null | undefined
          cancelation_reason?: string | null | undefined
          agent?:
            | {
                __typename: 'agents'
                id: any
                first_name: string
                last_name: string
                status: string
                email: string
                phone: string
              }
            | null
            | undefined
          tour_properties?: Array<{
            __typename: 'tour_properties'
            id: any
            mls_listing: {
              __typename: 'mls_listings'
              id: any
              delivery_line?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              county?: string | null | undefined
              zip?: string | null | undefined
              description?: string | null | undefined
              size?: string | null | undefined
              list_price?: any | null | undefined
              property_type?: string | null | undefined
              area?: string | null | undefined
              baths_full?: number | null | undefined
              baths_half?: number | null | undefined
              baths_total?: number | null | undefined
              firestore_id: any
              status?: string | null | undefined
              lotsize_sqft?: any | null | undefined
              market: {
                __typename: 'markets'
                id: any
                name: string
                short_name?: string | null | undefined
                display_name: string
              }
              internal_attributes: Array<{
                __typename: 'mls_listing_internal_attributes'
                rent?: any | null | undefined
                internal_status?: string | null | undefined
              }>
            }
          }>
        }>
        rental_applications?: Array<{
          __typename: 'rental_applications'
          id: any
          status: string
          created_at: any
          completed_at?: any | null | undefined
          projected_occupancy_date?: any | null | undefined
          is_underwriting_approved?: boolean | null | undefined
          underwritten_at?: any | null | undefined
          canceled_at?: any | null | undefined
          cancelation_reason?: string | null | undefined
          mls_listing?: {
            __typename: 'mls_listings'
            id: any
            delivery_line?: string | null | undefined
            city?: string | null | undefined
            state?: string | null | undefined
            county?: string | null | undefined
            zip?: string | null | undefined
            description?: string | null | undefined
            size?: string | null | undefined
            list_price?: any | null | undefined
            property_type?: string | null | undefined
            area?: string | null | undefined
            baths_full?: number | null | undefined
            baths_half?: number | null | undefined
            baths_total?: number | null | undefined
            firestore_id: any
            status?: string | null | undefined
            lotsize_sqft?: any | null | undefined
            market: {
              __typename: 'markets'
              id: any
              name: string
              short_name?: string | null | undefined
              display_name: string
            }
            internal_attributes: Array<{
              __typename: 'mls_listing_internal_attributes'
              rent?: any | null | undefined
              internal_status?: string | null | undefined
            }>
          }
          lead_group: {
            __typename: 'lead_groups'
            id: any
            lead_group_users: Array<{
              __typename: 'lead_group_users'
              id: any
              user: {
                __typename: 'users'
                id: any
                first_name?: string | null | undefined
                last_name?: string | null | undefined
              }
            }>
          }
        }>
      }
    }>
    conversations?: Array<{
      __typename: 'conversations'
      id: any
      medium: string
      from?: string | null | undefined
      external_source: string
      direction: string
      created_at: any
      status: string
      payload: any
    }>
    rental_site_leads?: Array<{
      __typename: 'rental_site_leads'
      id: any
      lead_comments?: string | null | undefined
      created_at: any
      rental_site_listing: {
        __typename: 'rental_site_listings'
        id: any
        rental_site_account: {
          __typename: 'rental_site_accounts'
          id: any
          source: string
        }
        mls_listing?: {
          __typename: 'mls_listings'
          id: any
          delivery_line?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          county?: string | null | undefined
          zip?: string | null | undefined
          description?: string | null | undefined
          size?: string | null | undefined
          list_price?: any | null | undefined
          property_type?: string | null | undefined
          area?: string | null | undefined
          baths_full?: number | null | undefined
          baths_half?: number | null | undefined
          baths_total?: number | null | undefined
          firestore_id: any
          status?: string | null | undefined
          lotsize_sqft?: any | null | undefined
          market: {
            __typename: 'markets'
            id: any
            name: string
            short_name?: string | null | undefined
            display_name: string
          }
          internal_attributes: Array<{
            __typename: 'mls_listing_internal_attributes'
            rent?: any | null | undefined
            internal_status?: string | null | undefined
          }>
        }
      }
    }>
  }>
}

export type Tour_ItemsFragment = {
  __typename: 'tours'
  id: any
  status: string
  type?: string | null | undefined
  start_at: any
  created_at: any
  completed_at?: any | null | undefined
  canceled_at?: any | null | undefined
  lead_group_confirmed_at?: any | null | undefined
  cancelation_reason?: string | null | undefined
  agent?:
    | {
        __typename: 'agents'
        id: any
        first_name: string
        last_name: string
        status: string
        email: string
        phone: string
      }
    | null
    | undefined
  tour_properties?: Array<{
    __typename: 'tour_properties'
    id: any
    mls_listing: {
      __typename: 'mls_listings'
      id: any
      delivery_line?: string | null | undefined
      city?: string | null | undefined
      state?: string | null | undefined
      county?: string | null | undefined
      zip?: string | null | undefined
      description?: string | null | undefined
      size?: string | null | undefined
      list_price?: any | null | undefined
      property_type?: string | null | undefined
      area?: string | null | undefined
      baths_full?: number | null | undefined
      baths_half?: number | null | undefined
      baths_total?: number | null | undefined
      firestore_id: any
      status?: string | null | undefined
      lotsize_sqft?: any | null | undefined
      market: {
        __typename: 'markets'
        id: any
        name: string
        short_name?: string | null | undefined
        display_name: string
      }
      internal_attributes: Array<{
        __typename: 'mls_listing_internal_attributes'
        rent?: any | null | undefined
        internal_status?: string | null | undefined
      }>
    }
  }>
}

export type Rental_Applications_ItemsFragment = {
  __typename: 'rental_applications'
  id: any
  status: string
  created_at: any
  completed_at?: any | null | undefined
  projected_occupancy_date?: any | null | undefined
  is_underwriting_approved?: boolean | null | undefined
  underwritten_at?: any | null | undefined
  canceled_at?: any | null | undefined
  cancelation_reason?: string | null | undefined
  mls_listing?: {
    __typename: 'mls_listings'
    id: any
    delivery_line?: string | null | undefined
    city?: string | null | undefined
    state?: string | null | undefined
    county?: string | null | undefined
    zip?: string | null | undefined
    description?: string | null | undefined
    size?: string | null | undefined
    list_price?: any | null | undefined
    property_type?: string | null | undefined
    area?: string | null | undefined
    baths_full?: number | null | undefined
    baths_half?: number | null | undefined
    baths_total?: number | null | undefined
    firestore_id: any
    status?: string | null | undefined
    lotsize_sqft?: any | null | undefined
    market: {
      __typename: 'markets'
      id: any
      name: string
      short_name?: string | null | undefined
      display_name: string
    }
    internal_attributes: Array<{
      __typename: 'mls_listing_internal_attributes'
      rent?: any | null | undefined
      internal_status?: string | null | undefined
    }>
  }
  lead_group: {
    __typename: 'lead_groups'
    id: any
    lead_group_users: Array<{
      __typename: 'lead_group_users'
      id: any
      user: {
        __typename: 'users'
        id: any
        first_name?: string | null | undefined
        last_name?: string | null | undefined
      }
    }>
  }
}

export type Primary_User_NameFragment = {
  __typename: 'lead_groups'
  lead_group_users: Array<{
    __typename: 'lead_group_users'
    id: any
    user: {
      __typename: 'users'
      id: any
      first_name?: string | null | undefined
      last_name?: string | null | undefined
    }
  }>
}

export type Mls_Listing_ItemsFragment = {
  __typename: 'mls_listings'
  id: any
  delivery_line?: string | null | undefined
  city?: string | null | undefined
  state?: string | null | undefined
  county?: string | null | undefined
  zip?: string | null | undefined
  description?: string | null | undefined
  size?: string | null | undefined
  list_price?: any | null | undefined
  property_type?: string | null | undefined
  area?: string | null | undefined
  baths_full?: number | null | undefined
  baths_half?: number | null | undefined
  baths_total?: number | null | undefined
  firestore_id: any
  status?: string | null | undefined
  lotsize_sqft?: any | null | undefined
  market: {
    __typename: 'markets'
    id: any
    name: string
    short_name?: string | null | undefined
    display_name: string
  }
  internal_attributes: Array<{
    __typename: 'mls_listing_internal_attributes'
    rent?: any | null | undefined
    internal_status?: string | null | undefined
  }>
}

export type Conversations_ItemsFragment = {
  __typename: 'conversations'
  id: any
  medium: string
  from?: string | null | undefined
  external_source: string
  direction: string
  created_at: any
  status: string
  payload: any
}

export type Rental_Site_Lead_ItemsFragment = {
  __typename: 'rental_site_leads'
  id: any
  lead_comments?: string | null | undefined
  created_at: any
  rental_site_listing: {
    __typename: 'rental_site_listings'
    id: any
    rental_site_account: {
      __typename: 'rental_site_accounts'
      id: any
      source: string
    }
    mls_listing?: {
      __typename: 'mls_listings'
      id: any
      delivery_line?: string | null | undefined
      city?: string | null | undefined
      state?: string | null | undefined
      county?: string | null | undefined
      zip?: string | null | undefined
      description?: string | null | undefined
      size?: string | null | undefined
      list_price?: any | null | undefined
      property_type?: string | null | undefined
      area?: string | null | undefined
      baths_full?: number | null | undefined
      baths_half?: number | null | undefined
      baths_total?: number | null | undefined
      firestore_id: any
      status?: string | null | undefined
      lotsize_sqft?: any | null | undefined
      market: {
        __typename: 'markets'
        id: any
        name: string
        short_name?: string | null | undefined
        display_name: string
      }
      internal_attributes: Array<{
        __typename: 'mls_listing_internal_attributes'
        rent?: any | null | undefined
        internal_status?: string | null | undefined
      }>
    }
  }
}

export type Upup_UserCrmQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type Upup_UserCrmQuery = {
  __typename: 'query_root'
  users: Array<{
    __typename: 'users'
    id: any
    first_name?: string | null | undefined
    last_name?: string | null | undefined
    lead_group_users: Array<{
      __typename: 'lead_group_users'
      id: any
      lead_group: {
        __typename: 'lead_groups'
        id: any
        rental_applications: Array<{
          __typename: 'rental_applications'
          id: any
          mls_listing: {
            __typename: 'mls_listings'
            id: any
            display_line_1?: string | null | undefined
          }
        }>
      }
    }>
    rentals: Array<{
      __typename: 'rentals'
      id: any
      rental_application: {
        __typename: 'rental_applications'
        id: any
        mls_listing: {
          __typename: 'mls_listings'
          id: any
          display_line_1?: string | null | undefined
        }
      }
    }>
  }>
}

export const PropertyHeaderFragmentDoc = gql`
  fragment PropertyHeader on properties {
    real_estate_acquisition {
      closingPrice: final_purchase_price
      closing_date
      mls_listing {
        id
        addressLineOne: display_line_1
        addressLineTwo: display_line_2
        delivery_line
        city
      }
    }
  }
`
export const MlsListingFragmentFragmentDoc = gql`
  fragment MlsListingFragment on mls_listings {
    id
    area
    baths_total
    beds
    city
    display_line_1
    firestore_id
    listing_date
    list_price
    mls
    provider
    size
    state
    year_built
    zip
    internal_attributes {
      internal_status
    }
    rent_prices(
      where: {
        is_active: { _eq: true }
        source: { _eq: "rent_pricing_pipeline" }
      }
    ) {
      id
      rent_price
      estimated_cap_rate
      upfront_improvements_cost
      sellers_credits
    }
    rent_ready_dates(where: { is_active: { _eq: true } }) {
      id
      earliest_move_in_date
      type
    }
    market {
      id
      display_name
    }
  }
`
export const AcquisitionInfoFragmentFragmentDoc = gql`
  fragment AcquisitionInfoFragment on real_estate_acquisitions {
    id
    closing_date
    type
    status
    final_purchase_price
    mls_listing {
      ...MlsListingFragment
    }
    llc {
      id
    }
  }
  ${MlsListingFragmentFragmentDoc}
`
export const PropertyInfoFragmentDoc = gql`
  fragment PropertyInfo on properties {
    id
    exit_date
    exit_price
    real_estate_acquisition {
      ...AcquisitionInfoFragment
    }
  }
  ${AcquisitionInfoFragmentFragmentDoc}
`
export const MlsSellerAgentDataFragmentDoc = gql`
  fragment mlsSellerAgentData on seller_agents {
    close_lead_id
    created_at
    do_not_contact
    email
    first_name
    id
    last_contacted_at
    last_name
    mls
    mls_id
    phone
  }
`
export const MlsListingDataFragmentDoc = gql`
  fragment mlsListingData on mls_listings {
    area
    baths_full
    baths_half
    baths_total
    beds
    city
    co_listing_agent {
      ...mlsSellerAgentData
    }
    co_listing_office_id
    county
    created_at
    delivery_line
    description
    display_line_1
    display_line_2
    features_amenities
    features_appliances_and_equipment
    features_bathrooms
    features_bedrooms
    features_community
    features_cooling
    features_dining_room
    features_disclosures
    features_doors
    features_energy_saving
    features_exterior_features
    features_fees
    features_fence
    features_fireplaces
    features_foundation
    features_garage
    features_heating
    features_home_owners_association
    features_house
    features_interior
    features_interior_features
    features_kitchen
    features_laundry
    features_listing
    features_location
    features_lot
    features_miscellaneous
    features_parking
    features_pool
    features_property
    features_property_access
    features_roof
    features_schools
    features_security_system
    features_sewer
    features_spa
    features_taxes
    features_utilities
    features_water
    features_windows_and_doors
    geo_type
    id
    firestore_id
    internal_attributes {
      analyzed_images
      census_tract_county_code
      census_tract_income_level
      census_tract_median_family_income
      census_tract_percent_below_poverty_line
      census_tract_tract_code
      created_at
      guidance_price
      id
      internal_status
      is_census_tract_opportunity_zone
      is_in_customer_buy_box
      is_in_real_estate_buy_box
      is_persisted_in_algolia
      mls_listing_id
      neighborhood_levels
      percent_over_comp
      rating_base
      rent
      short_list_images
      tags
      updated_at
      url
      zillow_deep_search_results
    }
    is_new_construction
    last_updated_at
    lat
    list_price
    listing_agent {
      ...mlsSellerAgentData
    }
    listing_date
    listing_office_id
    listing_type
    lng
    lotsize_acres
    lotsize_sqft
    market_id
    mls
    modified_at
    photos(
      order_by: { display_order: asc }
      where: { is_deleted: { _eq: false } }
    ) {
      created_at
      display_order
      id
      is_deleted
      updated_at
      url
    }
    property_type
    provider_id
    provider
    region
    school_district
    school_elementary
    school_high
    school_middle
    short_id
    size
    state
    status
    std_address
    street
    style
    subdivision
    system_id
    tour_url
    updated_at
    xf_attributes
    year_built
    zip
  }
  ${MlsSellerAgentDataFragmentDoc}
`
export const Mls_Listing_ItemsFragmentDoc = gql`
  fragment mls_listing_items on mls_listings {
    id
    delivery_line
    city
    state
    county
    zip
    description
    size
    list_price
    property_type
    area
    baths_full
    baths_half
    baths_total
    firestore_id
    status
    lotsize_sqft
    market {
      id
      name
      short_name
      display_name
    }
    internal_attributes {
      rent
      internal_status
    }
  }
`
export const Tour_ItemsFragmentDoc = gql`
  fragment tour_items on tours {
    id
    status
    type
    start_at
    created_at
    completed_at
    canceled_at
    lead_group_confirmed_at
    cancelation_reason
    agent {
      id
      first_name
      last_name
      status
      email
      phone
    }
    tour_properties @include(if: $with_property_details) {
      id
      mls_listing {
        ...mls_listing_items
      }
    }
  }
  ${Mls_Listing_ItemsFragmentDoc}
`
export const Primary_User_NameFragmentDoc = gql`
  fragment primary_user_name on lead_groups {
    lead_group_users(
      where: { type: { _eq: "primary" }, deactivated_at: { _is_null: true } }
    ) {
      id
      user {
        id
        first_name
        last_name
      }
    }
  }
`
export const Rental_Applications_ItemsFragmentDoc = gql`
  fragment rental_applications_items on rental_applications {
    id
    status
    created_at
    completed_at
    projected_occupancy_date
    is_underwriting_approved
    underwritten_at
    canceled_at
    cancelation_reason
    mls_listing @include(if: $with_property_details) {
      ...mls_listing_items
    }
    lead_group {
      id
      ...primary_user_name
    }
  }
  ${Mls_Listing_ItemsFragmentDoc}
  ${Primary_User_NameFragmentDoc}
`
export const Conversations_ItemsFragmentDoc = gql`
  fragment conversations_items on conversations {
    id
    medium
    from
    external_source
    direction
    created_at
    status
    payload
  }
`
export const Rental_Site_Lead_ItemsFragmentDoc = gql`
  fragment rental_site_lead_items on rental_site_leads {
    id
    lead_comments
    created_at
    rental_site_listing {
      id
      rental_site_account {
        id
        source
      }
      mls_listing @include(if: $with_property_details) {
        ...mls_listing_items
      }
    }
  }
  ${Mls_Listing_ItemsFragmentDoc}
`
export const HomeByPropertyIdDocument = gql`
  query HomeByPropertyId($propertyId: uuid!) {
    properties(where: { id: { _eq: $propertyId } }) {
      ...PropertyHeader
      ...PropertyInfo
    }
  }
  ${PropertyHeaderFragmentDoc}
  ${PropertyInfoFragmentDoc}
`

export function useHomeByPropertyIdQuery(
  options: Omit<Urql.UseQueryArgs<HomeByPropertyIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<HomeByPropertyIdQuery, HomeByPropertyIdQueryVariables>({
    query: HomeByPropertyIdDocument,
    ...options
  })
}
export const HomesGridDocument = gql`
  query HomesGrid {
    properties {
      id
      real_estate_acquisition {
        ...AcquisitionInfoFragment
        mls_listing {
          ...MlsListingFragment
        }
      }
    }
  }
  ${AcquisitionInfoFragmentFragmentDoc}
  ${MlsListingFragmentFragmentDoc}
`

export function useHomesGridQuery(
  options?: Omit<Urql.UseQueryArgs<HomesGridQueryVariables>, 'query'>
) {
  return Urql.useQuery<HomesGridQuery, HomesGridQueryVariables>({
    query: HomesGridDocument,
    ...options
  })
}
export const UpupLeadGroupsUserListDocument = gql`
  subscription UpupLeadGroupsUserList(
    $limit: Int = 10
    $where: lead_groups_bool_exp = {}
  ) {
    lead_groups(limit: $limit, where: $where) {
      id
      created_at
      status
      lead_group_users(
        limit: 10
        distinct_on: user_id
        where: { deactivated_at: { _is_null: true } }
      ) {
        id
        type
        deactivated_at
        user {
          id
          first_name
          last_name
          user_contact_details(
            where: { is_primary_for_contact_type: { _eq: true } }
          ) {
            id
            contact_type
            contact_info
          }
        }
      }
    }
  }
`

export function useUpupLeadGroupsUserListSubscription<
  TData = UpupLeadGroupsUserListSubscription
>(
  options: Omit<
    Urql.UseSubscriptionArgs<UpupLeadGroupsUserListSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<UpupLeadGroupsUserListSubscription, TData>
) {
  return Urql.useSubscription<
    UpupLeadGroupsUserListSubscription,
    TData,
    UpupLeadGroupsUserListSubscriptionVariables
  >({ query: UpupLeadGroupsUserListDocument, ...options }, handler)
}
export const UpupGenericNotesDocument = gql`
  query UpupGenericNotes(
    $where: notes_bool_exp = {}
    $created_at: order_by = desc_nulls_last
  ) {
    notes(where: $where, order_by: { created_at: $created_at }) {
      admin {
        id
        first_name
        last_name
      }
      documents {
        id
        external_id
        external_source
        friendly_name
      }
      id
      category
      created_at
      note
      resource_type
      resource_id
      subject_id
      subject_type
    }
  }
`

export function useUpupGenericNotesQuery(
  options?: Omit<Urql.UseQueryArgs<UpupGenericNotesQueryVariables>, 'query'>
) {
  return Urql.useQuery<UpupGenericNotesQuery, UpupGenericNotesQueryVariables>({
    query: UpupGenericNotesDocument,
    ...options
  })
}
export const UpupAdminsWithCapabilitiesDocument = gql`
  query UpupAdminsWithCapabilities {
    admins(order_by: { email: asc }, where: { status: { _eq: "active" } }) {
      id
      first_name
      last_name
      email
      admin_capabilities {
        id
        capability {
          id
          name
          type
          description
        }
      }
    }
  }
`

export function useUpupAdminsWithCapabilitiesQuery(
  options?: Omit<
    Urql.UseQueryArgs<UpupAdminsWithCapabilitiesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpupAdminsWithCapabilitiesQuery,
    UpupAdminsWithCapabilitiesQueryVariables
  >({ query: UpupAdminsWithCapabilitiesDocument, ...options })
}
export const UpupApplicationListDocument = gql`
  query UpupApplicationList(
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [rental_applications_order_by!] = { created_at: desc }
    $where: rental_applications_bool_exp = {}
  ) {
    rental_applications(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      agreements(where: { status: { _neq: "canceled" } }) {
        id
        status
        metadata
      }
      created_at
      projected_occupancy_date
      lead_group {
        id
        lead_group_users(limit: 10, distinct_on: user_id) {
          id
          type
          user {
            id
            first_name
            last_name
            payments(
              where: {
                rental_id: { _is_null: true }
                category: { _eq: "initial_payment" }
                status: { _in: ["processing", "succeeded"] }
              }
            ) {
              id
            }
            documents(
              where: {
                status: { _in: ["valid", "pending_review"] }
                type: {
                  _in: [
                    "identity_document"
                    "pay_stub"
                    "offer_letter"
                    "bank_statement"
                    "tax_return"
                  ]
                }
              }
            ) {
              id
              status
              type
            }
            credit_reports(limit: 1) {
              id
            }
            criminal_reports(limit: 1) {
              id
            }
            eviction_reports(limit: 1) {
              id
            }
            user_incomes(limit: 1) {
              id
            }
          }
        }
      }
      mls_listing {
        id
        display_line_1
        region
        internal_attributes {
          id
          internal_status
        }
      }
      status
    }
  }
`

export function useUpupApplicationListQuery(
  options?: Omit<Urql.UseQueryArgs<UpupApplicationListQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    UpupApplicationListQuery,
    UpupApplicationListQueryVariables
  >({ query: UpupApplicationListDocument, ...options })
}
export const UpupApplicationMlsListingsByFirestoreIdsDocument = gql`
  query UpupApplicationMlsListingsByFirestoreIds($ids: [uuid!]) {
    mls_listings(where: { firestore_id: { _in: $ids } }) {
      internal_attributes {
        rent
      }
      state
    }
  }
`

export function useUpupApplicationMlsListingsByFirestoreIdsQuery(
  options?: Omit<
    Urql.UseQueryArgs<UpupApplicationMlsListingsByFirestoreIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpupApplicationMlsListingsByFirestoreIdsQuery,
    UpupApplicationMlsListingsByFirestoreIdsQueryVariables
  >({ query: UpupApplicationMlsListingsByFirestoreIdsDocument, ...options })
}
export const UpupApplicationUserQualDocument = gql`
  query UpupApplicationUserQual($id: ID!) {
    getUserQualificationData(id: $id) {
      status
    }
  }
`

export function useUpupApplicationUserQualQuery(
  options: Omit<
    Urql.UseQueryArgs<UpupApplicationUserQualQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpupApplicationUserQualQuery,
    UpupApplicationUserQualQueryVariables
  >({ query: UpupApplicationUserQualDocument, ...options })
}
export const UpupApplicationMlsListingsByAddressDocument = gql`
  query UpupApplicationMlsListingsByAddress($address: String) {
    mls_listings(
      where: {
        display_line_1: { _ilike: $address }
        internal_attributes: {
          internal_status: { _in: ["homevested", "internally_listed"] }
        }
      }
      limit: 5
    ) {
      internal_attributes {
        internal_status
        rent
      }
      photos(limit: 1) {
        url
      }
      baths_weighted
      beds
      display_line_1
      display_line_2
      firestore_id
      id
      list_price
    }
  }
`

export function useUpupApplicationMlsListingsByAddressQuery(
  options?: Omit<
    Urql.UseQueryArgs<UpupApplicationMlsListingsByAddressQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpupApplicationMlsListingsByAddressQuery,
    UpupApplicationMlsListingsByAddressQueryVariables
  >({ query: UpupApplicationMlsListingsByAddressDocument, ...options })
}
export const UpupCapabilitiesWithAdminDocument = gql`
  query UpupCapabilitiesWithAdmin($where: capabilities_bool_exp = {}) {
    capabilities(order_by: { type: asc }, where: $where) {
      id
      name
      type
      description
      admin_capabilities(
        order_by: { admin: { email: asc } }
        where: { admin: { status: { _eq: "active" } } }
      ) {
        id
        admin {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`

export function useUpupCapabilitiesWithAdminQuery(
  options?: Omit<
    Urql.UseQueryArgs<UpupCapabilitiesWithAdminQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpupCapabilitiesWithAdminQuery,
    UpupCapabilitiesWithAdminQueryVariables
  >({ query: UpupCapabilitiesWithAdminDocument, ...options })
}
export const CustomerHeaderInfoByUserIdDocument = gql`
  query customerHeaderInfoByUserId($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      id
      created_at
      deleted_at
      rental_users(where: { deactivated_at: { _is_null: true } }) {
        id
      }
      lead_group_users {
        id
        lead_group_id
        rental_applications(where: { canceled_at: { _is_null: true } }) {
          id
        }
      }
      user_prequalification_data {
        id
      }
      user_funnel_status {
        id
        lead_status
      }
    }
  }
`

export function useCustomerHeaderInfoByUserIdQuery(
  options: Omit<
    Urql.UseQueryArgs<CustomerHeaderInfoByUserIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    CustomerHeaderInfoByUserIdQuery,
    CustomerHeaderInfoByUserIdQueryVariables
  >({ query: CustomerHeaderInfoByUserIdDocument, ...options })
}
export const UpupDeleteAdminCapabilityDocument = gql`
  mutation UpupDeleteAdminCapability($id: uuid!) {
    delete_admin_capabilities_by_pk(id: $id) {
      id
      admin_id
      capability_id
    }
  }
`

export function useUpupDeleteAdminCapabilityMutation() {
  return Urql.useMutation<
    UpupDeleteAdminCapabilityMutation,
    UpupDeleteAdminCapabilityMutationVariables
  >(UpupDeleteAdminCapabilityDocument)
}
export const DocumentsByUserIdDocument = gql`
  query documentsByUserId($id: uuid!) {
    documents(
      where: { resource_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      id
      friendly_name
      status
      created_at
      reviewed_at
      reviewed_by_admin_id
      admin {
        id
        first_name
        last_name
      }
    }
  }
`

export function useDocumentsByUserIdQuery(
  options: Omit<Urql.UseQueryArgs<DocumentsByUserIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<DocumentsByUserIdQuery, DocumentsByUserIdQueryVariables>(
    { query: DocumentsByUserIdDocument, ...options }
  )
}
export const UpupInitialReviewMlsListingsByFirestoreIdsDocument = gql`
  query UpupInitialReviewMlsListingsByFirestoreIds($ids: [uuid!]) {
    mls_listings(where: { firestore_id: { _in: $ids } }) {
      ...mlsListingData
    }
  }
  ${MlsListingDataFragmentDoc}
`

export function useUpupInitialReviewMlsListingsByFirestoreIdsQuery(
  options?: Omit<
    Urql.UseQueryArgs<UpupInitialReviewMlsListingsByFirestoreIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpupInitialReviewMlsListingsByFirestoreIdsQuery,
    UpupInitialReviewMlsListingsByFirestoreIdsQueryVariables
  >({ query: UpupInitialReviewMlsListingsByFirestoreIdsDocument, ...options })
}
export const UpupMlsListingsByIdsDocument = gql`
  query UpupMlsListingsByIds($ids: [uuid!]) {
    mls_listings(where: { id: { _in: $ids } }) {
      ...mlsListingData
    }
  }
  ${MlsListingDataFragmentDoc}
`

export function useUpupMlsListingsByIdsQuery(
  options?: Omit<Urql.UseQueryArgs<UpupMlsListingsByIdsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    UpupMlsListingsByIdsQuery,
    UpupMlsListingsByIdsQueryVariables
  >({ query: UpupMlsListingsByIdsDocument, ...options })
}
export const UpupInsertAdminCapabilityDocument = gql`
  mutation UpupInsertAdminCapability($admin_id: uuid, $capability_id: uuid) {
    insert_admin_capabilities_one(
      object: { admin_id: $admin_id, capability_id: $capability_id }
    ) {
      id
      admin_id
      capability_id
    }
  }
`

export function useUpupInsertAdminCapabilityMutation() {
  return Urql.useMutation<
    UpupInsertAdminCapabilityMutation,
    UpupInsertAdminCapabilityMutationVariables
  >(UpupInsertAdminCapabilityDocument)
}
export const UpupInsertAdminDocument = gql`
  mutation UpupInsertAdmin(
    $email: String
    $firstName: String
    $lastName: String
  ) {
    insert_admins_one(
      object: { email: $email, first_name: $firstName, last_name: $lastName }
    ) {
      id
      email
      first_name
      last_name
    }
  }
`

export function useUpupInsertAdminMutation() {
  return Urql.useMutation<
    UpupInsertAdminMutation,
    UpupInsertAdminMutationVariables
  >(UpupInsertAdminDocument)
}
export const UpupInsertCapabilityDocument = gql`
  mutation UpupInsertCapability(
    $name: String
    $type: String
    $description: String
  ) {
    insert_capabilities_one(
      object: { name: $name, type: $type, description: $description }
    ) {
      id
      name
      type
      description
    }
  }
`

export function useUpupInsertCapabilityMutation() {
  return Urql.useMutation<
    UpupInsertCapabilityMutation,
    UpupInsertCapabilityMutationVariables
  >(UpupInsertCapabilityDocument)
}
export const UpupInsertDocumentDocument = gql`
  mutation UpupInsertDocument(
    $external_id: String
    $external_source: String
    $friendly_name: String
    $resource_id: uuid
    $resource_type: String
    $review_notes: String
    $reviewed_at: timestamptz
    $reviewed_by_admin_id: uuid
    $status: String
    $type: String
  ) {
    insert_documents_one(
      object: {
        external_id: $external_id
        external_source: $external_source
        friendly_name: $friendly_name
        resource_id: $resource_id
        resource_type: $resource_type
        review_notes: $review_notes
        reviewed_at: $reviewed_at
        reviewed_by_admin_id: $reviewed_by_admin_id
        status: $status
        type: $type
      }
    ) {
      id
    }
  }
`

export function useUpupInsertDocumentMutation() {
  return Urql.useMutation<
    UpupInsertDocumentMutation,
    UpupInsertDocumentMutationVariables
  >(UpupInsertDocumentDocument)
}
export const UpupInsertNoteDocument = gql`
  mutation UpupInsertNote(
    $category: String
    $created_by_admin_id: uuid
    $note: String
    $resource_id: uuid
    $resource_type: String
  ) {
    insert_notes_one(
      object: {
        category: $category
        created_by_admin_id: $created_by_admin_id
        note: $note
        resource_id: $resource_id
        resource_type: $resource_type
      }
    ) {
      id
    }
  }
`

export function useUpupInsertNoteMutation() {
  return Urql.useMutation<
    UpupInsertNoteMutation,
    UpupInsertNoteMutationVariables
  >(UpupInsertNoteDocument)
}
export const UpupInsertRentalCreditDocument = gql`
  mutation UpupInsertRentalCredit(
    $amount: numeric
    $date: date
    $note: String
    $offsetting_ledger_account_id: uuid
    $rental_id: uuid
    $type: String
  ) {
    insert_rental_credits_one(
      object: {
        amount: $amount
        date: $date
        note: $note
        offsetting_ledger_account_id: $offsetting_ledger_account_id
        rental_id: $rental_id
        type: $type
      }
    ) {
      id
      amount
      date
    }
  }
`

export function useUpupInsertRentalCreditMutation() {
  return Urql.useMutation<
    UpupInsertRentalCreditMutation,
    UpupInsertRentalCreditMutationVariables
  >(UpupInsertRentalCreditDocument)
}
export const LeadGroupByUserIdDocument = gql`
  query leadGroupByUserId($id: uuid!) {
    lead_group_users(
      where: { user_id: { _eq: $id }, deactivated_at: { _is_null: true } }
    ) {
      id
      lead_group {
        id
        status
        created_at
      }
    }
  }
`

export function useLeadGroupByUserIdQuery(
  options: Omit<Urql.UseQueryArgs<LeadGroupByUserIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<LeadGroupByUserIdQuery, LeadGroupByUserIdQueryVariables>(
    { query: LeadGroupByUserIdDocument, ...options }
  )
}
export const LeadGroupUsersByLeadGroupIdDocument = gql`
  query leadGroupUsersByLeadGroupId($id: uuid!) {
    lead_group_users(
      where: { lead_group_id: { _eq: $id }, deactivated_at: { _is_null: true } }
    ) {
      id
      user_id
      type
      user {
        id
        first_name
        last_name
      }
      emails: user_contact_details(
        order_by: { is_primary_for_contact_type: desc }
        where: { contact_type: { _eq: "email" } }
      ) {
        id
        contact_info
      }
    }
  }
`

export function useLeadGroupUsersByLeadGroupIdQuery(
  options: Omit<
    Urql.UseQueryArgs<LeadGroupUsersByLeadGroupIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    LeadGroupUsersByLeadGroupIdQuery,
    LeadGroupUsersByLeadGroupIdQueryVariables
  >({ query: LeadGroupUsersByLeadGroupIdDocument, ...options })
}
export const LeasingStaffByUserIdDocument = gql`
  query leasingStaffByUserId($id: uuid!) {
    agent_user_owners(
      where: { user_id: { _eq: $id }, deactivated_at: { _is_null: true } }
    ) {
      id
      agent_type
      admin {
        id
        first_name
        last_name
      }
    }
  }
`

export function useLeasingStaffByUserIdQuery(
  options: Omit<Urql.UseQueryArgs<LeasingStaffByUserIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    LeasingStaffByUserIdQuery,
    LeasingStaffByUserIdQueryVariables
  >({ query: LeasingStaffByUserIdDocument, ...options })
}
export const UpupOverwriteAdminCapabilitiesDocument = gql`
  mutation UpupOverwriteAdminCapabilities(
    $ids_to_remove: [uuid!]
    $objects_to_add: [admin_capabilities_insert_input!]!
  ) {
    delete_admin_capabilities(where: { id: { _in: $ids_to_remove } }) {
      affected_rows
    }
    insert_admin_capabilities(objects: $objects_to_add) {
      returning {
        id
        admin_id
        capability_id
      }
    }
  }
`

export function useUpupOverwriteAdminCapabilitiesMutation() {
  return Urql.useMutation<
    UpupOverwriteAdminCapabilitiesMutation,
    UpupOverwriteAdminCapabilitiesMutationVariables
  >(UpupOverwriteAdminCapabilitiesDocument)
}
export const PersonalDetailsByUserIdDocument = gql`
  query personalDetailsByUserId($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      id
      first_name
      last_name
      emails: user_contact_details(
        order_by: { is_primary_for_contact_type: desc }
        where: { contact_type: { _eq: "email" } }
      ) {
        id
        contact_info
        is_primary_for_contact_type
      }
      phones: user_contact_details(
        order_by: { is_primary_for_contact_type: desc }
        where: { contact_type: { _eq: "phone" } }
      ) {
        id
        contact_info
        is_primary_for_contact_type
      }
      user_funnel_status {
        id
        lead_status
      }
      credit_reports(
        order_by: { created_at: desc }
        where: { score_status: { _eq: "Success" } }
      ) {
        id
        score
      }
    }
  }
`

export function usePersonalDetailsByUserIdQuery(
  options: Omit<
    Urql.UseQueryArgs<PersonalDetailsByUserIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    PersonalDetailsByUserIdQuery,
    PersonalDetailsByUserIdQueryVariables
  >({ query: PersonalDetailsByUserIdDocument, ...options })
}
export const PrequalificationDetailsByUserIdDocument = gql`
  query prequalificationDetailsByUserId($id: uuid!) {
    user_prequalification_data(where: { user_id: { _eq: $id } }) {
      id
      prequalification_status
      is_section_eight
      is_ok_with_two_year_lease
      is_income_qualified
      is_credit_qualified
      has_cosigner
      has_coapplicant
    }
  }
`

export function usePrequalificationDetailsByUserIdQuery(
  options: Omit<
    Urql.UseQueryArgs<PrequalificationDetailsByUserIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    PrequalificationDetailsByUserIdQuery,
    PrequalificationDetailsByUserIdQueryVariables
  >({ query: PrequalificationDetailsByUserIdDocument, ...options })
}
export const UpupProcessedUtilityBillsDocument = gql`
  subscription UpupProcessedUtilityBills {
    kafka_connector_prorated_utility_bill_line_items {
      buildium_csv
      id
      rental_liabilities
      transformations
      original_upload_url
      created_at
      rental_liabilities_csv
    }
  }
`

export function useUpupProcessedUtilityBillsSubscription<
  TData = UpupProcessedUtilityBillsSubscription
>(
  options: Omit<
    Urql.UseSubscriptionArgs<UpupProcessedUtilityBillsSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    UpupProcessedUtilityBillsSubscription,
    TData
  >
) {
  return Urql.useSubscription<
    UpupProcessedUtilityBillsSubscription,
    TData,
    UpupProcessedUtilityBillsSubscriptionVariables
  >({ query: UpupProcessedUtilityBillsDocument, ...options }, handler)
}
export const UpUpPropertyInformationForUtilityBillsDocument = gql`
  query UpUpPropertyInformationForUtilityBills @cached {
    llcs {
      id
      name
      abbreviation
    }
    llc_properties {
      llc {
        id
      }
      start_date
      end_date
      property {
        id
        rentals {
          final_liability_date
          occupancy_date
          user_id
          id
        }
        real_estate_acquisition {
          mls_listing {
            display_line_1
            street
            city
            state
            zip
            id
          }
        }
      }
    }
    funds {
      id
      name
      abbreviation
    }
    fund_llcs {
      fund_id
      llc_id
    }
    real_estate_acquisitions {
      canceled_at
      closing_date
      llc_id
      mls_listing {
        display_line_1
        street
        city
        state
        zip
        id
      }
      status
      possession_at
      utilities_activation_date
    }
  }
`

export function useUpUpPropertyInformationForUtilityBillsQuery(
  options?: Omit<
    Urql.UseQueryArgs<UpUpPropertyInformationForUtilityBillsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpUpPropertyInformationForUtilityBillsQuery,
    UpUpPropertyInformationForUtilityBillsQueryVariables
  >({ query: UpUpPropertyInformationForUtilityBillsDocument, ...options })
}
export const UpupRentalByIdDocument = gql`
  subscription UpupRentalById($id: uuid!) {
    rentals(where: { id: { _eq: $id } }) {
      id
      auto_charge
      final_liability_date
      initial_option_premium
      is_card_payment_allowed
      lease_end_reason
      move_out_date
      occupancy_date
      status
      target_monthly_option_premium
      property {
        id
        llc_properties(order_by: { start_date: desc }) {
          id
          llc {
            id
            name
          }
        }
        real_estate_acquisition {
          id
          mls_listing {
            id
            display_line_1
            display_line_2
          }
        }
      }
      payments(where: { _not: { category: { _eq: "initial_payment" } } }) {
        amount
        available_on
        category
        created_at
        id
        liability_type {
          id
          name
          payment_category
        }
        payment_date
        payment_method
        status
        lifecycle
        initiated_at
        user {
          id
          first_name
          last_name
        }
      }
      rental_credits {
        id
        amount
        date
      }
      rental_add_ons {
        id
        end_date
        price
        start_date
        liability_type {
          id
          name
          payment_category
        }
      }
      rental_agreement_histories(
        where: { is_active: { _eq: true } }
        order_by: { ends_at: desc }
      ) {
        id
        option_premium
        rent
        starts_at
        ends_at
      }
      rental_application {
        agreements(
          where: { status: { _eq: "complete" } }
          order_by: { created_at: desc }
        ) {
          type
          id
          metadata
          documents {
            id
            admin {
              id
              first_name
              last_name
            }
            created_at
            external_id
            external_source
            friendly_name
            review_notes
            reviewed_at
            status
          }
          agreement_signers(where: { resource_type: { _eq: "users" } }) {
            id
            role
            user {
              id
              first_name
              last_name
              user_contact_details(
                where: { is_primary_for_contact_type: { _eq: true } }
              ) {
                id
                contact_type
                contact_info
              }
            }
          }
        }
        mls_listing {
          id
          display_line_1
          display_line_2
        }
      }
      rental_liabilities {
        amount
        date
        id
        liability_type {
          id
          name
          payment_category
        }
      }
      user {
        id
        first_name
        last_name
        plaid_access_token_invalid_at
        user_contact_details(
          where: { is_primary_for_contact_type: { _eq: true } }
        ) {
          id
          contact_type
          contact_info
        }
      }
    }
  }
`

export function useUpupRentalByIdSubscription<
  TData = UpupRentalByIdSubscription
>(
  options: Omit<
    Urql.UseSubscriptionArgs<UpupRentalByIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<UpupRentalByIdSubscription, TData>
) {
  return Urql.useSubscription<
    UpupRentalByIdSubscription,
    TData,
    UpupRentalByIdSubscriptionVariables
  >({ query: UpupRentalByIdDocument, ...options }, handler)
}
export const UpupRentalListDocument = gql`
  query UpupRentalList {
    rentals {
      id
      auto_charge
      final_liability_date
      initial_option_premium
      is_card_payment_allowed
      lease_end_reason
      move_out_date
      occupancy_date
      status
      target_monthly_option_premium
      property {
        id
        llc_properties(order_by: { start_date: desc }) {
          id
          llc {
            id
            name
          }
        }
        real_estate_acquisition {
          id
          mls_listing {
            id
            display_line_1
            display_line_2
          }
        }
      }
      payments(where: { _not: { category: { _eq: "initial_payment" } } }) {
        amount
        available_on
        category
        created_at
        id
        liability_type {
          id
          name
          payment_category
        }
        payment_date
        payment_method
        status
        lifecycle
        initiated_at
      }
      rental_add_ons {
        id
        end_date
        price
        start_date
        liability_type {
          id
          name
          payment_category
        }
      }
      rental_credits {
        id
        amount
        date
      }
      rental_agreement_histories(
        where: { is_active: { _eq: true } }
        order_by: { ends_at: desc }
      ) {
        id
        option_premium
        rent
        starts_at
        ends_at
      }
      rental_application {
        id
        lead_group {
          id
        }
        agreements(
          where: { status: { _eq: "complete" }, type: { _eq: "lease" } }
          order_by: { created_at: desc }
        ) {
          id
          type
          metadata
        }
        mls_listing {
          id
          display_line_1
          display_line_2
        }
      }
      rental_liabilities {
        amount
        date
        id
        liability_type {
          id
          name
          payment_category
        }
      }
      user {
        id
        first_name
        last_name
        plaid_access_token_invalid_at
        user_contact_details(
          where: { is_primary_for_contact_type: { _eq: true } }
        ) {
          id
          contact_type
          contact_info
        }
      }
    }
  }
`

export function useUpupRentalListQuery(
  options?: Omit<Urql.UseQueryArgs<UpupRentalListQueryVariables>, 'query'>
) {
  return Urql.useQuery<UpupRentalListQuery, UpupRentalListQueryVariables>({
    query: UpupRentalListDocument,
    ...options
  })
}
export const UpupUserListDocument = gql`
  subscription UpupUserList($where: users_bool_exp = {}) {
    users(limit: 10, where: $where) {
      id
      first_name
      last_name
      user_contact_details(
        where: { is_primary_for_contact_type: { _eq: true } }
      ) {
        id
        contact_type
        contact_info
      }
    }
  }
`

export function useUpupUserListSubscription<TData = UpupUserListSubscription>(
  options: Omit<
    Urql.UseSubscriptionArgs<UpupUserListSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<UpupUserListSubscription, TData>
) {
  return Urql.useSubscription<
    UpupUserListSubscription,
    TData,
    UpupUserListSubscriptionVariables
  >({ query: UpupUserListDocument, ...options }, handler)
}
export const UpUpAddProcessedUtilityBillDocument = gql`
  mutation UpUpAddProcessedUtilityBill(
    $transformations: json = {}
    $rental_liabilities: json = {}
    $original_upload_url: String = "http://"
    $buildium_csv: String = "c,s,v"
    $rental_liabilities_csv: String = "c,s,v"
  ) {
    insert_kafka_connector_prorated_utility_bill_line_items_one(
      object: {
        original_upload_url: $original_upload_url
        transformations: $transformations
        buildium_csv: $buildium_csv
        rental_liabilities: $rental_liabilities
        rental_liabilities_csv: $rental_liabilities_csv
      }
    ) {
      id
    }
  }
`

export function useUpUpAddProcessedUtilityBillMutation() {
  return Urql.useMutation<
    UpUpAddProcessedUtilityBillMutation,
    UpUpAddProcessedUtilityBillMutationVariables
  >(UpUpAddProcessedUtilityBillDocument)
}
export const FindPlaidIncomeVerificationByIdQueryDocument = gql`
  query FindPlaidIncomeVerificationByIdQuery(
    $plaidIncomeVerificationId: uuid!
  ) {
    plaid_income_verifications(
      where: { id: { _eq: $plaidIncomeVerificationId } }
    ) {
      id
      income_response
      income_verification_id
      link_token_request_id
      income_verification_request_id
      paystub_data
      processed_at
      summary_data
      updated_at
      user {
        id
        first_name
        last_name
      }
      documents {
        id
        external_id
        external_source
        friendly_name
        type
      }
    }
  }
`

export function useFindPlaidIncomeVerificationByIdQueryQuery(
  options: Omit<
    Urql.UseQueryArgs<FindPlaidIncomeVerificationByIdQueryQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    FindPlaidIncomeVerificationByIdQueryQuery,
    FindPlaidIncomeVerificationByIdQueryQueryVariables
  >({ query: FindPlaidIncomeVerificationByIdQueryDocument, ...options })
}
export const Upup_Properties_MlsListingsDocument = gql`
  query Upup_Properties_MlsListings(
    $limit: Int = 10
    $offset: Int = 0
    $sort: [mls_listings_order_by!] = {}
    $where: mls_listings_bool_exp = {}
  ) {
    mls_listings(
      limit: $limit
      offset: $offset
      order_by: $sort
      where: $where
    ) {
      id
      area
      city
      display_line_1
      firestore_id
      listing_date
      list_price
      mls
      provider
      state
      zip
      internal_attributes {
        internal_status
      }
    }
  }
`

export function useUpup_Properties_MlsListingsQuery(
  options?: Omit<
    Urql.UseQueryArgs<Upup_Properties_MlsListingsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    Upup_Properties_MlsListingsQuery,
    Upup_Properties_MlsListingsQueryVariables
  >({ query: Upup_Properties_MlsListingsDocument, ...options })
}
export const Upup_LiabilityTypesDocument = gql`
  query Upup_LiabilityTypes {
    liability_types {
      id
      is_variable
      name
      price
      recurring
    }
  }
`

export function useUpup_LiabilityTypesQuery(
  options?: Omit<Urql.UseQueryArgs<Upup_LiabilityTypesQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    Upup_LiabilityTypesQuery,
    Upup_LiabilityTypesQueryVariables
  >({ query: Upup_LiabilityTypesDocument, ...options })
}
export const Upup_CreateRentalAddOnDocument = gql`
  mutation Upup_CreateRentalAddOn(
    $liability_type_id: uuid
    $rental_id: uuid
    $start_date: date
    $price: numeric
  ) {
    insert_rental_add_ons_one(
      object: {
        liability_type_id: $liability_type_id
        price: $price
        rental_id: $rental_id
        start_date: $start_date
      }
    ) {
      id
      end_date
      liability_type {
        name
      }
      price
      start_date
    }
  }
`

export function useUpup_CreateRentalAddOnMutation() {
  return Urql.useMutation<
    Upup_CreateRentalAddOnMutation,
    Upup_CreateRentalAddOnMutationVariables
  >(Upup_CreateRentalAddOnDocument)
}
export const Upup_SetRentalAddOnEndDateDocument = gql`
  mutation Upup_SetRentalAddOnEndDate($end_date: date, $id: uuid!) {
    update_rental_add_ons_by_pk(
      pk_columns: { id: $id }
      _set: { end_date: $end_date }
    ) {
      id
      end_date
      price
      start_date
      liability_type {
        name
      }
    }
  }
`

export function useUpup_SetRentalAddOnEndDateMutation() {
  return Urql.useMutation<
    Upup_SetRentalAddOnEndDateMutation,
    Upup_SetRentalAddOnEndDateMutationVariables
  >(Upup_SetRentalAddOnEndDateDocument)
}
export const Upup_CreateRentalAgreementHistoryDocument = gql`
  mutation Upup_CreateRentalAgreementHistory(
    $rental_id: uuid
    $rent: numeric
    $starts_at: date
    $ends_at: date
  ) {
    insert_rental_agreement_histories_one(
      object: {
        ends_at: $ends_at
        is_active: true
        option_premium: 0
        rent: $rent
        rental_id: $rental_id
        starts_at: $starts_at
      }
    ) {
      ends_at
      rent
      rental_id
      starts_at
    }
  }
`

export function useUpup_CreateRentalAgreementHistoryMutation() {
  return Urql.useMutation<
    Upup_CreateRentalAgreementHistoryMutation,
    Upup_CreateRentalAgreementHistoryMutationVariables
  >(Upup_CreateRentalAgreementHistoryDocument)
}
export const Upup_CreateRentalLiabilityDocument = gql`
  mutation Upup_CreateRentalLiability(
    $liability_type_id: uuid
    $rental_id: uuid
    $amount: numeric
    $date: date
    $note: String
  ) {
    insert_rental_liabilities_one(
      object: {
        amount: $amount
        date: $date
        liability_type_id: $liability_type_id
        note: $note
        rental_id: $rental_id
      }
    ) {
      amount
      date
      liability_type {
        name
      }
    }
  }
`

export function useUpup_CreateRentalLiabilityMutation() {
  return Urql.useMutation<
    Upup_CreateRentalLiabilityMutation,
    Upup_CreateRentalLiabilityMutationVariables
  >(Upup_CreateRentalLiabilityDocument)
}
export const Upup_EditRentalAgreementHistoryDocument = gql`
  mutation Upup_EditRentalAgreementHistory($ends_at: date, $id: uuid!) {
    update_rental_agreement_histories_by_pk(
      pk_columns: { id: $id }
      _set: { ends_at: $ends_at }
    ) {
      id
      ends_at
    }
  }
`

export function useUpup_EditRentalAgreementHistoryMutation() {
  return Urql.useMutation<
    Upup_EditRentalAgreementHistoryMutation,
    Upup_EditRentalAgreementHistoryMutationVariables
  >(Upup_EditRentalAgreementHistoryDocument)
}
export const Upup_SetFinalLiabilityDateDocument = gql`
  mutation Upup_SetFinalLiabilityDate($final_liability_date: date, $id: uuid!) {
    update_rentals_by_pk(
      pk_columns: { id: $id }
      _set: { final_liability_date: $final_liability_date }
    ) {
      id
      final_liability_date
    }
  }
`

export function useUpup_SetFinalLiabilityDateMutation() {
  return Urql.useMutation<
    Upup_SetFinalLiabilityDateMutation,
    Upup_SetFinalLiabilityDateMutationVariables
  >(Upup_SetFinalLiabilityDateDocument)
}
export const Upup_LedgerAccountsDocument = gql`
  query Upup_LedgerAccounts {
    ledger_accounts(
      where: {
        is_active: { _eq: true }
        external_source: { _eq: "buildium" }
        parent_ledger_account_id: {
          _eq: "3f919f76-13c5-4653-8d84-804af19ef509"
        }
      }
    ) {
      id
      name
      rental_credit_type
    }
  }
`

export function useUpup_LedgerAccountsQuery(
  options?: Omit<Urql.UseQueryArgs<Upup_LedgerAccountsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    Upup_LedgerAccountsQuery,
    Upup_LedgerAccountsQueryVariables
  >({ query: Upup_LedgerAccountsDocument, ...options })
}
export const Upup_SetMoveOutDateDocument = gql`
  mutation Upup_SetMoveOutDate(
    $lease_end_reason: String
    $move_out_date: date
    $status: String
    $id: uuid!
  ) {
    update_rentals_by_pk(
      pk_columns: { id: $id }
      _set: {
        lease_end_reason: $lease_end_reason
        move_out_date: $move_out_date
        status: $status
      }
    ) {
      id
      lease_end_reason
      move_out_date
      status
    }
  }
`

export function useUpup_SetMoveOutDateMutation() {
  return Urql.useMutation<
    Upup_SetMoveOutDateMutation,
    Upup_SetMoveOutDateMutationVariables
  >(Upup_SetMoveOutDateDocument)
}
export const Upup_SetAutoChargeDocument = gql`
  mutation Upup_SetAutoCharge($auto_charge: Boolean, $id: uuid!) {
    update_rentals_by_pk(
      pk_columns: { id: $id }
      _set: { auto_charge: $auto_charge }
    ) {
      id
      auto_charge
    }
  }
`

export function useUpup_SetAutoChargeMutation() {
  return Urql.useMutation<
    Upup_SetAutoChargeMutation,
    Upup_SetAutoChargeMutationVariables
  >(Upup_SetAutoChargeDocument)
}
export const Upup_SetCardPaymentDocument = gql`
  mutation Upup_SetCardPayment($is_card_payment_allowed: Boolean, $id: uuid!) {
    update_rentals_by_pk(
      pk_columns: { id: $id }
      _set: { is_card_payment_allowed: $is_card_payment_allowed }
    ) {
      id
      is_card_payment_allowed
    }
  }
`

export function useUpup_SetCardPaymentMutation() {
  return Urql.useMutation<
    Upup_SetCardPaymentMutation,
    Upup_SetCardPaymentMutationVariables
  >(Upup_SetCardPaymentDocument)
}
export const Upup_SellerAgents_ListDocument = gql`
  query Upup_SellerAgents_List(
    $limit: Int = 10
    $where: seller_agents_bool_exp = {}
  ) {
    seller_agents(limit: $limit, where: $where) {
      id
      email
      first_name
      last_name
      phone
    }
  }
`

export function useUpup_SellerAgents_ListQuery(
  options?: Omit<
    Urql.UseQueryArgs<Upup_SellerAgents_ListQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    Upup_SellerAgents_ListQuery,
    Upup_SellerAgents_ListQueryVariables
  >({ query: Upup_SellerAgents_ListDocument, ...options })
}
export const UpUpUserTimelineByUserIdDocument = gql`
  query UpUpUserTimelineByUserID(
    $id: uuid!
    $with_property_details: Boolean!
    $with_conversations: Boolean!
    $with_tours: Boolean!
    $with_rental_app_events: Boolean!
    $with_rental_site_leads: Boolean!
  ) {
    users(where: { id: { _eq: $id } }) {
      id
      first_name
      last_name
      lead_group_users(where: { deactivated_at: { _is_null: true } }) {
        id
        lead_group {
          id
          tours @include(if: $with_tours) {
            ...tour_items
          }
          rental_applications @include(if: $with_rental_app_events) {
            ...rental_applications_items
          }
        }
      }
      conversations @include(if: $with_conversations) {
        ...conversations_items
      }
      rental_site_leads @include(if: $with_rental_site_leads) {
        ...rental_site_lead_items
      }
    }
  }
  ${Tour_ItemsFragmentDoc}
  ${Rental_Applications_ItemsFragmentDoc}
  ${Conversations_ItemsFragmentDoc}
  ${Rental_Site_Lead_ItemsFragmentDoc}
`

export function useUpUpUserTimelineByUserIdQuery(
  options: Omit<
    Urql.UseQueryArgs<UpUpUserTimelineByUserIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    UpUpUserTimelineByUserIdQuery,
    UpUpUserTimelineByUserIdQueryVariables
  >({ query: UpUpUserTimelineByUserIdDocument, ...options })
}
export const Upup_UserCrmDocument = gql`
  query Upup_UserCrm($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      id
      first_name
      last_name
      lead_group_users {
        id
        lead_group {
          id
          rental_applications {
            id
            mls_listing {
              id
              display_line_1
            }
          }
        }
      }
      rentals {
        id
        rental_application {
          id
          mls_listing {
            id
            display_line_1
          }
        }
      }
    }
  }
`

export function useUpup_UserCrmQuery(
  options: Omit<Urql.UseQueryArgs<Upup_UserCrmQueryVariables>, 'query'>
) {
  return Urql.useQuery<Upup_UserCrmQuery, Upup_UserCrmQueryVariables>({
    query: Upup_UserCrmDocument,
    ...options
  })
}
