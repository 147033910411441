import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Comparators as Comp } from 'utils/hasura-comparators'
import { useQuery } from 'urql'
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { debounce } from 'lodash'

import { Input } from 'components/Toolkit'
import { Upup_SellerAgents_ListDocument as queryDoc } from 'graphql/generated'
import { addSellerAgentToProperty } from '../../state/properties/actions'
import AgentSearchDetails from './AgentSearchDetails'

function SellerAgentEntries({
  searchText = '',
  onSelect,
  primaryStatus,
  idsToFilter
}) {
  const [{ data }] = useQuery({
    query: queryDoc,
    variables: {
      where: {
        seller_agent_searchable: {
          info: Comp.isLike(`%${searchText}%`)
        }
      }
    }
  })

  const sellerAgents = data?.seller_agents || []

  return searchText
    ? sellerAgents
        .filter((agent) => !idsToFilter.includes(agent.id))
        .map((agent) => (
          <AgentSearchDetails
            key={agent.id}
            agent={agent}
            onSelect={onSelect}
            isPrimary={primaryStatus[agent.id]}
          />
        ))
    : []
}

SellerAgentEntries.propTypes = {
  searchText: PropTypes.string.isRequired
}

const AddAgentModal = ({ modal, toggle, propertyId }) => {
  const dispatch = useDispatch()
  const primaryStatusByAgentId = useSelector(
    (state) => state.properties.primaryStatusByAgentId
  )
  const propertySellerAgentIds = useSelector((state) =>
    state.properties.sellerAgents.map((sa) => sa.id)
  )

  const [searchText, setSearchText] = useState('')
  const [inputText, setInputText] = useState('')

  const setSearchTextDebounced = React.useMemo(
    () => debounce(setSearchText, 250, { trailing: true }),
    [setSearchText]
  )

  const addAgent = (agentId) => {
    dispatch(addSellerAgentToProperty(propertyId, agentId, false))
  }

  const selectAgent = (agent) => {
    addAgent(agent.id)
    setSearchText('')
    setInputText('')
    toggle()
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add an Agent to this Property</ModalHeader>
      <ModalBody>
        <Input
          placeholder='Search for an agent...'
          value={inputText}
          onChange={(value) => {
            // We need two separate values so we can debounce to the subscription
            setInputText(value)
            setSearchTextDebounced(value)
          }}
        />

        <React.Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                padding: '6px',
                justifyContent: 'center',
                marginTop: '50px'
              }}
            >
              <Spinner color='primary' />
            </div>
          }
        >
          <SellerAgentEntries
            onSelect={selectAgent}
            searchText={searchText}
            primaryStatus={primaryStatusByAgentId}
            idsToFilter={propertySellerAgentIds}
          />
        </React.Suspense>
      </ModalBody>
      <ModalFooter>
        <Link to='/add-agent' target='_blank' style={{ fontSize: 14 }}>
          Not finding the agent you're looking for? Add an agent here.
        </Link>
      </ModalFooter>
    </Modal>
  )
}

AddAgentModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired
}

export default AddAgentModal
