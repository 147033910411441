import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StoreState } from 'store'

import { hasCapability } from 'lib/admin-perms'
import { formatMoney } from 'lib/numbers'
import FullRental from 'types/FullRental'
import axios from 'lib/axios'
import { Button, HeaderText } from 'components/Toolkit'
import { capabilities } from '@homevest/utils'

const { MOVE_IN_CHARGERS } = capabilities.CAPABILITY_TYPES

export default function MoveInPayment({ rental }: { rental: FullRental }) {
  const [moveInPayment, setMoveInPayment] = useState<any>(null)
  const [isSaving, setIsSaving] = useState(false)
  const admin = useSelector<StoreState, any>((state) => state.admin)
  const canChargeMoveInPayment = hasCapability(admin, MOVE_IN_CHARGERS)

  useEffect(() => {
    const loadMoveInPayment = async (rentalId: string) => {
      const res = await axios.get(`/rentals/${rentalId}/move_in_payment`)

      setMoveInPayment(res.data)
    }

    if (
      rental &&
      // Only load the move in payment if the occupancy is this month or in the future
      moment(rental.occupancy_date).isSameOrAfter(moment(), 'month')
    ) {
      loadMoveInPayment(rental.id)
    }
  }, [rental])

  const handleSubmit = async () => {
    if (!get(moveInPayment, 'total_move_in_payment')) {
      return
    }

    if (
      window.confirm(
        `Are you sure you want to charge this customer $${formatMoney(
          moveInPayment.total_move_in_payment
        )}? Reversing is a pain in the ass ⚠.`
      )
    ) {
      try {
        setIsSaving(true)

        await axios.post(`/rentals/${rental.id}/move_in_payment`)
      } catch (err) {
        const error = err as any
        const detailMessage = get(error, 'response.data.message')

        window.alert(detailMessage || error.message)
        setIsSaving(false)
      }
    }
  }

  if (!canChargeMoveInPayment || !get(moveInPayment, 'total_move_in_payment')) {
    return null
  }

  const {
    initial_option_premium: initialOptionPremium,
    initial_payment: initialPayment,
    rent,
    next_months_rent: nextMonthsRent,
    total_move_in_payment: totalMoveInPayment
  } = moveInPayment

  if (!totalMoveInPayment) {
    return null
  }

  return (
    <div style={{ marginBottom: '2rem' }}>
      <HeaderText size='h5' style={{ marginBottom: '.5rem' }}>
        Move-in Payment
      </HeaderText>
      <p>
        Prorated Rent: <b>${formatMoney(rent)}</b>
      </p>
      {nextMonthsRent > 0 && (
        <p title='Added when a move-in date is after the 26th of the month'>
          Next Month's Rent: <b>${formatMoney(nextMonthsRent)}</b>
        </p>
      )}
      <p>
        Remaining Option Premium:{' '}
        <b>${formatMoney(initialOptionPremium - initialPayment)}</b>
      </p>
      <p>
        Total Move In Payment: <b>${formatMoney(totalMoveInPayment)}</b>
      </p>

      <Button
        size='vs'
        onClick={handleSubmit}
        disabled={isSaving}
        isSecondary={false}
      >
        Charge move-in payment
      </Button>
    </div>
  )
}
