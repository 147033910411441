import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'
import ForwardChevron from 'components/Toolkit/Icons/ForwardChevron'
import BackChevron from 'components/Toolkit/Icons/BackChevron'
import CircleCheckmark from 'components/Toolkit/Icons/CircleCheckmark'

import moment from 'moment'

const StyledDateOption = styled.div`
  transition: all 200ms linear;
  background: ${(props) =>
    props.isSelected ? 'rgba(92, 204, 203, 0.05)' : COLORS.PRIMARY.COCONUT};
  border: 1px solid
    ${(props) =>
      props.isSelected ? COLORS.PRIMARY.JELLO : COLORS.LIGHT_NEUTRALS.MERCURY};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${(props) =>
    props.isSelected ? COLORS.PRIMARY.JELLO : COLORS.NEUTRALS.OYSTER};
  width: 80px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: ${(props) => `${props.daysSinceStart * 89 - props.page * 89}px`};
  cursor: pointer;
`

const StyledInputContainer = styled.div`
  margin: 25px 0;
`

const StyledDatesContainer = styled.div`
  height: 70px;
  width: 100%;
`

const StyledDatesContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 10px;
  transition: all 100ms linear;

  &:before {
    visibility: ${(props) => (props.page !== 0 ? 'visible' : 'hidden')};
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      ${(props) => props.backgroundColor}
    );
    height: 100%;
    width: 8em;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      ${(props) => props.backgroundColor}
    );
    height: 100%;
    width: 8em;
  }
`

const StyledDay = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
`

const StyledDate = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
`

const StyledForwardWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 20px 20px 20px 40px;
`

const StyledBackWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  padding: 20px 40px 20px 20px;
`

const StyledCheckmark = styled.span`
  position: absolute;
  top: -17px;
  right: -7px;
`

const TODAY = moment().startOf('d')

function DateSlider({
  label,
  value = TODAY.format('YYYY-MM-DD'),
  onChange,
  backgroundColor,
  minDate = TODAY,
  selectedDays = []
}) {
  const [page, setPage] = useState(0)

  const DAYS = Array(10)
    .fill(0)
    .map((val, idx) => {
      const minDateClone = minDate.clone()
      return minDateClone.startOf('d').add(idx + page, 'day')
    })

  return (
    <StyledInputContainer>
      <Label>{label}</Label>
      <StyledDatesContainerWrapper
        backgroundColor={backgroundColor}
        page={page}
      >
        {page !== 0 && (
          <StyledBackWrapper onClick={() => setPage(page - 1)}>
            <BackChevron />
          </StyledBackWrapper>
        )}

        <StyledDatesContainer>
          {DAYS.map((day) => {
            const dayFormatted = day.format('YYYY-MM-DD')
            return (
              <StyledDateOption
                key={dayFormatted}
                daysSinceStart={day.diff(minDate.startOf('day'), 'days')}
                page={page}
                onClick={() => onChange(dayFormatted)}
                isSelected={value === dayFormatted}
              >
                {selectedDays.includes(dayFormatted) ? (
                  <StyledCheckmark>
                    <CircleCheckmark />
                  </StyledCheckmark>
                ) : null}
                <StyledDay>
                  {day.isSame(TODAY, 'day') ? 'Today' : day.format('ddd')}
                </StyledDay>
                <StyledDate>{day.format('MMM DD')}</StyledDate>
              </StyledDateOption>
            )
          })}
        </StyledDatesContainer>
        <StyledForwardWrapper onClick={() => setPage(page + 1)}>
          <ForwardChevron />
        </StyledForwardWrapper>
      </StyledDatesContainerWrapper>
    </StyledInputContainer>
  )
}

DateSlider.propTypes = {
  backgroundColor: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  selectedDays: PropTypes.array,
  startDate: PropTypes.object,
  value: PropTypes.string
}

export default memo(DateSlider)
