export function formatMoney(number, decimals = 2, currency = '') {
  if (typeof number !== 'number') {
    return ''
  } else if (number === 0) {
    return currency + '0'
  } else {
    return `${currency}${Number(number).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })}`
  }
}
