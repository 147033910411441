import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap'
const {
  leadGroups: { LEAD_GROUP_USER_TYPES }
} = require('@homevest/utils')
const startCase = require('lodash/startCase')

export default class CreateModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    isActioning: PropTypes.bool.isRequired
  }

  state = {
    type: '',
    userId: ''
  }

  handleCreate = () => {
    const { onCreate } = this.props
    const { userId, type } = this.state

    onCreate([{ user_id: userId, type }])
  }

  render() {
    const { onClose, isActioning } = this.props
    const { userId, type } = this.state
    const canCreate = !isActioning && userId && type

    return (
      <>
        <Modal size='lg' isOpen toggle={onClose}>
          <ModalHeader>Create Lead Group</ModalHeader>
          <ModalBody>
            <div>
              <div>
                <b>User ID</b>
              </div>
              <div>
                <Input
                  onChange={(event) =>
                    this.setState({ userId: event.target.value })
                  }
                  value={userId}
                  type='text'
                />
                <Input
                  style={{ marginTop: '1rem' }}
                  onChange={(event) =>
                    this.setState({ type: event.target.value })
                  }
                  value={type}
                  type='select'
                >
                  <option value=''>Select the tenant type</option>
                  {Object.values(LEAD_GROUP_USER_TYPES).map((type) => (
                    <option key={type} value={type}>
                      {startCase(type)}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              disabled={!canCreate}
              onClick={this.handleCreate}
            >
              {isActioning ? 'Creating...' : 'Create'}
            </Button>
            <Button color='secondary' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
