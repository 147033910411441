import React, { FunctionComponent } from 'react'
import { types } from '@homevest/timelines'
import {
  Email,
  Tour,
  RentalApplication,
  SMS,
  Call,
  Inquiry,
  Note
} from './EventTypeTemplates'
import {
  isEmailTimelineEvent,
  isCallTimelineEvent,
  isSmsTimelineEvent,
  isTourTimelineEvent,
  isRentalAppTimelineEvent,
  isInquiryTimelineEvent,
  isNoteTimelineEvent
} from './types'
import { Customer } from '../types'

type DisplayEventContentProps = {
  event: types.TimelineEvent
  expanded: boolean
  customer: Customer
  homes: types.HomeDetails[]
}

const DisplayEventContent: FunctionComponent<DisplayEventContentProps> = ({
  event,
  expanded,
  customer,
  homes
}) => {
  if (isTourTimelineEvent(event)) {
    return (
      <Tour
        event={event}
        expanded={expanded}
        customer={customer}
        homes={homes}
      />
    )
  }
  if (isEmailTimelineEvent(event)) {
    return <Email event={event} expanded={expanded} customer={customer} />
  }
  if (isSmsTimelineEvent(event)) {
    return <SMS event={event} expanded={expanded} customer={customer} />
  }
  if (isCallTimelineEvent(event)) {
    return <Call event={event} expanded={expanded} customer={customer} />
  }
  if (isRentalAppTimelineEvent(event)) {
    return (
      <RentalApplication
        event={event}
        expanded={expanded}
        customer={customer}
        homes={homes}
      />
    )
  }
  if (isInquiryTimelineEvent(event)) {
    return (
      <Inquiry
        event={event}
        expanded={expanded}
        customer={customer}
        homes={homes}
      />
    )
  }
  if (isNoteTimelineEvent(event)) {
    return (
      <Note
        event={event}
        expanded={expanded}
        customer={customer}
        homes={homes}
      />
    )
  }

  return (
    <div>
      unknown event type for {customer.firstName} :/ {event.eventTimeAt}
    </div>
  )
}

export default DisplayEventContent
