import React from 'react'
import { documents } from '@homevest/utils'
import { Spinner } from 'reactstrap'

import QualificationStatus from './QualificationStatus'
// eslint-disable-next-line
import { Lead_Group_Users } from 'graphql/generated'

const { DOCUMENT_TYPES } = documents

type Props = { leadGroupUsers: any[] }

const ItemInfo: React.FC<Props> = ({ leadGroupUsers }) => (
  <div
    style={{
      gridColumnStart: 1,
      gridColumnEnd: 'end',
      backgroundColor: '#eee',
      padding: '1rem'
    }}
  >
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '3fr 3fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr'
      }}
    >
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Name</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Type</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Qualified</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Credit</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Criminal</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Eviction</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Identity</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Income</b>
      </div>
      <div>
        <b style={{ whiteSpace: 'nowrap' }}>Payment</b>
      </div>
      {/* eslint-disable-next-line */}
      {(leadGroupUsers ?? []).map(({ user, type }: Lead_Group_Users) => {
        const identityChecked = user.documents.some(
          (doc: any) => doc.type === DOCUMENT_TYPES.IDENTITY_DOCUMENT
        )
        const incomeChecked = user.user_incomes.length > 0
        return (
          <React.Fragment key={user.id}>
            <div
              style={{
                paddingTop: '4px',
                marginBottom: '4px',
                gridColumnStart: 1,
                gridColumnEnd: 'end',
                borderBottom: '1px solid #fdfdfd'
              }}
            />
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div>{type}</div>
            <React.Suspense
              fallback={
                <div style={{ display: 'flex', padding: '6px' }}>
                  <Spinner color='primary' />
                </div>
              }
            >
              <QualificationStatus userId={user.id} />
            </React.Suspense>
            <div>{user.credit_reports.length > 0 ? '✔' : '✖'}</div>
            <div>{user.criminal_reports.length > 0 ? '✔' : '✖'}</div>
            <div>{user.eviction_reports.length > 0 ? '✔' : '✖'}</div>
            <div>{identityChecked !== undefined ? '✔' : '✖'}</div>
            <div>{incomeChecked ? '✔' : '✖'}</div>
            <div>{user.payments.length > 0 ? '✔' : '✖'}</div>
          </React.Fragment>
        )
      })}
    </div>
  </div>
)

export default ItemInfo
