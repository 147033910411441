import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { types } from '@homevest/timelines'
import {
  Map,
  ChatAlt,
  Phone,
  Home,
  Mail,
  QuestionMarkCircle,
  Check,
  PencilAlt
} from 'styled-icons/heroicons-solid'
import { StyledIcon } from 'styled-icons/types'

const eventTypeToIcon: { [type in types.EventTypeString]: StyledIcon } = {
  tour: Map,
  email: Mail,
  sms: ChatAlt,
  call: Phone,
  rental_application: Home,
  inquiry: QuestionMarkCircle,
  task: Check,
  note: PencilAlt
}

const EventIcon: FunctionComponent<{ eventType: types.EventTypeString }> = ({
  eventType
}) => {
  const icon = eventTypeToIcon[eventType]

  const StyledIcon = styled(icon)`
    width: 1rem;
    height: 1rem;
    color: white;
  `
  return <StyledIcon />
}

export default EventIcon
