import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

export default function AgentPropertyHardOfferSentButton({
  isDisabled,
  onUpdate,
  sellerAgentPropertyId,
  style
}) {
  const handleHardOfferSent = async () => {
    if (
      !isDisabled &&
      window.confirm(
        'Are you sure you sent the hard offer? This is irreversible ⚠.'
      )
    ) {
      await onUpdate({ is_hard_offer_sent: true }, sellerAgentPropertyId)
    }
  }
  return (
    <Button
      type='button'
      disabled={isDisabled}
      size='sm'
      color='success'
      style={style}
      onClick={handleHardOfferSent}
    >
      Hard Offer Sent
    </Button>
  )
}

AgentPropertyHardOfferSentButton.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  sellerAgentPropertyId: PropTypes.string,
  style: PropTypes.object
}

AgentPropertyHardOfferSentButton.defaultProps = {
  isDisabled: false,
  onUpdate: () => null,
  style: {}
}
