"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
exports.findAll = (ref, limit = 1000) => __awaiter(void 0, void 0, void 0, function* () {
    let loop = true;
    let docs = [];
    let lastDoc;
    while (loop) {
        const query = lastDoc
            ? yield ref.limit(limit).startAfter(lastDoc).get()
            : yield ref.limit(limit).get();
        docs = docs.concat(query.docs);
        query.docs.length && query.docs.length === limit
            ? (lastDoc = query.docs[query.docs.length - 1])
            : (loop = false);
    }
    return docs;
});
exports.each = (ref, callback, options = {}) => __awaiter(void 0, void 0, void 0, function* () {
    options.limit = options.limit || 1000;
    let loop = true;
    let lastDoc;
    while (loop) {
        const query = lastDoc
            ? yield ref.limit(options.limit).startAfter(lastDoc).get()
            : yield ref.limit(options.limit).get();
        const { docs } = query;
        if (callback) {
            for (let i = 0; i < docs.length; i++) {
                yield callback(docs[i].data());
            }
        }
        docs.length && docs.length === options.limit
            ? (lastDoc = docs[docs.length - 1])
            : (loop = false);
    }
    return true;
});
exports.map = (ref, callback, options = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const limit = options.limit || 1000;
    const concurrency = options.concurrency || Infinity;
    let loop = true;
    let lastDoc;
    while (loop) {
        const query = lastDoc
            ? yield ref.limit(limit).startAfter(lastDoc).get()
            : yield ref.limit(limit).get();
        const { docs } = query;
        if (callback) {
            for (let i = 0, j = docs.length; i < j; i += concurrency) {
                yield Promise.all(docs.slice(i, i + concurrency).map((doc) => callback(doc.data())));
            }
        }
        docs.length && docs.length === limit
            ? (lastDoc = docs[docs.length - 1])
            : (loop = false);
    }
    return true;
});
exports.getFirestoreCollections = (firestore) => ({
    agentListingApprovalRequestsRef: firestore.collection('agent_listing_approval_requests'),
    asyncUserRequests: firestore.collection('async_user_requests'),
    autoPostedRentalSiteListings: firestore.collection('auto_posted_rental_site_listings'),
    creditRequestsRef: firestore.collection('credit_requests'),
    criminalRequestsRef: firestore.collection('criminal_requests'),
    dataDumpsRef: firestore.collection('data_dumps'),
    devicesRef: firestore.collection('devices'),
    evictionRequestsRef: firestore.collection('eviction_requests'),
    frontContactsRef: firestore.collection('front_contacts'),
    housecanaryRequestsRef: firestore.collection('housecanary_requests'),
    jobsRef: firestore.collection('jobs'),
    newPaymentSourcesRef: firestore.collection('new_payment_sources'),
    onboardingsRef: firestore.collection('onboardings'),
    propertiesInMarketRef: firestore.collection('properties_in_market'),
    propertiesRef: firestore.collection('properties'),
    propertyFinancialsRef: firestore.collection('property_financials'),
    propertyGapsInFootprintRef: firestore.collection('property_gaps_in_footprint'),
    propertyRentHistoryRef: firestore.collection('property_rent_history'),
    propertySettingsRef: firestore.collection('property_settings'),
    pubsubEventsRef: firestore.collection('pubsub_events'),
    rentPricesRef: firestore.collection('rent_prices'),
    rentalSiteAccountsRef: firestore.collection('rental_site_accounts'),
    rentalSiteContactsRef: firestore.collection('rental_site_contacts'),
    rentalSiteLeadsRef: firestore.collection('rental_site_leads'),
    rentalSiteListingsRef: firestore.collection('rental_site_listings'),
    rentalSiteRequestsRef: firestore.collection('rental_site_requests'),
    savedPropertiesRef: firestore.collection('saved_properties'),
    userPropertyInteractionsRef: firestore.collection('user_property_interactions'),
    usersRef: firestore.collection('users'),
    userTasksRef: firestore.collection('user_tasks'),
    viewingRequestsRef: firestore.collection('viewing_requests')
});
