import React, { FunctionComponent } from 'react'
import * as SC from './StyledComponents'

type ContentSectionPropsType = {
  title: string
  content: JSX.Element
  action?: JSX.Element
  size?: string
  borders?: boolean
}

const ContentSection: FunctionComponent<ContentSectionPropsType> = ({
  title,
  action,
  content,
  size = 'large',
  borders = false
}) => {
  return (
    <SC.SectionContainer>
      <SC.SectionHeader>
        {title}
        {action ?? null}
      </SC.SectionHeader>
      <SC.SectionContent size={size} borders={borders}>
        {content}
      </SC.SectionContent>
    </SC.SectionContainer>
  )
}

export default ContentSection
