import React from 'react'
import { Input } from 'reactstrap'
import { startCase } from 'lodash'
import PropTypes from 'prop-types'
import { sellerAgents } from '@homevest/utils'

const { SELLER_AGENT_REJECTION_REASONS } = sellerAgents

export default function PermissionDeniedReasonSelect({
  isDisabled,
  rejectionReason,
  setRejectionReason
}) {
  return (
    <Input
      type='select'
      disabled={isDisabled}
      onChange={(e) => setRejectionReason(e.target.value)}
      defaultValue={rejectionReason}
    >
      <option value=''>Select a Rejection Reason</option>
      {Object.values(SELLER_AGENT_REJECTION_REASONS).map((reason) => (
        <option value={reason} key={reason}>
          {startCase(reason).replace('Upandup', 'Up&Up')}
        </option>
      ))}
    </Input>
  )
}

PermissionDeniedReasonSelect.propTypes = {
  isDisabled: PropTypes.bool,
  rejectionReason: PropTypes.string,
  setRejectionReason: PropTypes.func.isRequired
}

PermissionDeniedReasonSelect.defaultProps = {
  isDisabled: false,
  rejectionReason: ''
}
