import { Exchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast'
import {
  Mutation_RootDelete_Admin_Capabilities_By_PkArgs,
  Mutation_RootInsert_Admin_Capabilities_OneArgs
} from 'graphql/generated'

export const getCacheExchange = (schema: IntrospectionData): Exchange =>
  cacheExchange({
    keys: {
      UserQualificationData: () => null
    },
    updates: {
      Mutation: {
        insert_admins_one(_result, _args, cache, _info) {
          cache
            .inspectFields('query_root')
            .filter((field) => field.fieldName === 'admins')
            .forEach((field) => {
              cache.invalidate('query_root', field.fieldKey)
            })
        },
        insert_admin_capabilities(_result, _args, cache, _info) {
          cache
            .inspectFields('query_root')
            .filter((field) => field.fieldName === 'admins')
            .forEach((field) => {
              cache.invalidate('query_root', field.fieldKey)
            })
        },
        insert_notes_one(_result, _args, cache, _info) {
          cache
            .inspectFields('query_root')
            .filter((field) => field.fieldName === 'notes')
            .forEach((field) => {
              cache.invalidate('query_root', field.fieldKey)
            })
        },
        insert_admin_capabilities_one(_result, args, cache, _info) {
          cache.invalidate({
            __typename: 'admins',
            // eslint-disable-next-line
            id: (args as Mutation_RootInsert_Admin_Capabilities_OneArgs).object
              .admin_id
          })
        },
        delete_admin_capabilities_by_pk(_result, args, cache, _info) {
          cache.invalidate({
            __typename: 'admin_capabilities',
            // eslint-disable-next-line
            id: (args as Mutation_RootDelete_Admin_Capabilities_By_PkArgs).id
          })
        },
        insert_capabilities_one(_result, _args, cache, _info) {
          cache
            .inspectFields('query_root')
            .filter((field) => field.fieldName === 'capabilities')
            .forEach((field) => {
              cache.invalidate('query_root', field.fieldKey)
            })
        }
      }
    },
    schema
  })
