// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useSubscription } from 'urql'
import { UpupMlsListingsByIdsDocument as queryDoc } from 'graphql/generated'
import pluralize from 'pluralize'
import { v4 as uuidv4 } from 'uuid'
import { capabilities } from '@homevest/utils'

import { hasCapability } from 'lib/admin-perms'

import Dropzone from 'components/Dropzone'
import { BodyText, Checkbox, ErrorText } from 'components/Toolkit'

const { MLS_PHOTO_MANAGER } = capabilities.CAPABILITY_TYPES

function MlsPhotosManager() {
  const { mlsListingId } = useParams()

  const [attachmentPath, setAttachmentPath] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [processing, setProcessing] = useState(false)
  const [reviewedPhotos, setReviewedPhotos] = useState(false)
  const [reviewedZip, setReviewedZip] = useState(false)

  const history = useHistory()

  const admin = useSelector((state) => state.admin)
  const canViewPage = hasCapability(admin, MLS_PHOTO_MANAGER)

  useEffect(() => {
    setAttachmentPath(`/mls-photo-manager/${mlsListingId}/${uuidv4()}`)
  }, [mlsListingId])

  const [{ data, fetching }] = useSubscription({
    query: queryDoc,
    variables: { ids: [mlsListingId] }
  })

  if (!canViewPage) {
    return <ErrorText>You do not have permissions to use this tool!</ErrorText>
  }

  if (fetching || !data) {
    return <h1>Loading</h1>
  }

  const mlsListing = data.mls_listings[0]
  const mlsListingPhotos = mlsListing.photos

  const onSuccess = async (_url: string) => {
    setProcessing(true)
    try {
      history.push(`/review/initial/${mlsListing.firestore_id}`)
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div style={{ paddingLeft: '2rem' }}>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <h1>Photo Managing {mlsListing.display_line_1}</h1>
      {!processing && (
        <>
          <BodyText>
            This tool will replace {mlsListingPhotos.length}{' '}
            {pluralize('photo', mlsListingPhotos.length)} for{' '}
            {mlsListing.display_line_1}. This action is irreversible so please
            check that you have done the following:
          </BodyText>
          <Checkbox
            label='Ensure you want to delete the current listing photos (see below).'
            value={reviewedPhotos}
            onChange={setReviewedPhotos}
          />
          <Checkbox
            label='Ensure your zip contains the new photos named as photo_1.jpg, photo_2.jpg, etc.'
            value={reviewedZip}
            onChange={setReviewedZip}
          />
        </>
      )}
      {reviewedPhotos && reviewedZip && !processing && (
        <div
          style={{
            marginBottom: '20px',
            padding: '5px',
            border: 'dotted 1px black'
          }}
        >
          <Dropzone onSuccess={onSuccess} uploadPath={attachmentPath} />
        </div>
      )}
      {processing && (
        <BodyText>
          Your zip is being processed. Once complete, this page will redirect
          you to the property review screen.
        </BodyText>
      )}
      {!processing &&
        mlsListingPhotos.map((photo, idx) => (
          <div key={idx}>
            <img
              alt={`${photo.display_order} of ${mlsListingPhotos.length} for ${mlsListing.display_line_1}`}
              style={{ maxWidth: '500px', paddingBottom: '.5rem' }}
              src={photo.url}
            />
          </div>
        ))}
    </div>
  )
}

export default MlsPhotosManager
