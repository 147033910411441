import axios from 'lib/axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capabilities } from '@homevest/utils'

import { hasCapability } from 'lib/admin-perms'

import Timeline from './Timeline'
import Close from './Close'
import Merge from './Merge'
import Tabs from './Tabs'
import User from './User'
import Homes from './Homes'
import HomeInteractions from './HomeInteractions'
import Documents from './Documents'

const { USER_MANAGER } = capabilities.CAPABILITY_TYPES

const StyledContainer = styled.div`
  display: flex;
  align-items: stretch;
  height: calc(100vh - 96px);
  border: 1px solid #dbd9d8;
  font-family: Poppins;
  font-style: normal;
`

const StyledLeftTab = styled.div`
  background-color: #fff;
  width: 500px;
  padding: 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dbd9d8;
  overflow: scroll;
`

const StyledRightTab = styled.div`
  flex-grow: 1;
  background-color: #fff;
  padding: 20px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 67%;
`

const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`

const StyledTabsContainer = styled.div`
  border-bottom: 1px solid #dbd9d8;
  padding: 20px 20px 0 20px;
`

const TABS = {
  DOCUMENTS: 'Documents',
  HOME_INTERACTIONS: 'Home Interactions',
  HOMES: 'Web App',
  MERGE: 'Merge',
  CLOSE: 'Close',
  TIMELINE: 'Timeline'
}

function UserCrm() {
  const [tab, setTab] = useState(TABS.DOCUMENTS)
  const [user, setUser] = useState(null)

  const { tab: initTab, userId } = useParams()

  function setInitialTab() {
    if (
      initTab &&
      Object.values(TABS)
        .map((i) => i.toLowerCase())
        .includes(initTab.toLowerCase())
    ) {
      setTab(initTab)
    } else {
      setTab(TABS.DOCUMENTS)
    }
  }

  useEffect(() => {
    // Fetch the user given that this page only has the ID.
    // Provide to all tabs if and as needed.
    async function run() {
      const { data } = await axios.get(`/admin/users/${userId}`)
      data.email = data.email2
      data.phone = data.phone2
      setUser(data)
    }
    return run()
  }, [userId])

  useEffect(() => {
    setInitialTab()
  }, [userId, setInitialTab])

  const admin = useSelector((store) => store.admin)
  const isMergeAllowed = hasCapability(admin, USER_MANAGER)

  if (!userId) {
    return null
  }

  if (!user) {
    return (
      <StyledContainer>
        <h3>Loading</h3>
      </StyledContainer>
    )
  }

  const TAB_OPTIONS = Object.values(TABS).map((value) => ({
    label: value,
    value
  }))

  return (
    <StyledContainer>
      <StyledLeftTab style={{ minWidth: '33%' }}>
        <User userId={userId} />
      </StyledLeftTab>
      <StyledRightTab>
        <StyledTabsContainer>
          <Tabs
            onChange={setTab}
            value={tab}
            options={TAB_OPTIONS}
            userId={userId}
          />
        </StyledTabsContainer>

        <StyledContent>
          {tab.toLowerCase() === TABS.DOCUMENTS.toLowerCase() ? (
            <Documents userId={userId} />
          ) : null}
          {tab.toLowerCase() === TABS.HOMES.toLowerCase() ? (
            <Homes userId={userId} />
          ) : null}
          {tab.toLowerCase() === TABS.HOME_INTERACTIONS.toLowerCase() ? (
            <HomeInteractions userId={userId} />
          ) : null}
          {tab.toLowerCase() === TABS.CLOSE.toLowerCase() ? (
            <Close userId={userId} />
          ) : null}
          {isMergeAllowed && tab.toLowerCase() === TABS.MERGE.toLowerCase() ? (
            <Merge user={user} />
          ) : null}
          {tab.toLowerCase() === TABS.TIMELINE.toLowerCase() ? (
            <Timeline userId={userId} />
          ) : null}
        </StyledContent>
      </StyledRightTab>
    </StyledContainer>
  )
}

UserCrm.propTypes = {}

export default UserCrm
