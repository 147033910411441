import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { startCase, isEqual } from 'lodash'
import styled from 'styled-components'
import { Input, Dropdown, MultiSelect, Label, Button, Pencil } from '../Toolkit'

const StyledField = styled.div`
  &:hover {
    background-color: ${(props) => (props.isEditing ? '#fff' : '#eee')};

    .pencil {
      display: ${(props) => (props.isEditing ? 'none' : 'block')};
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pencil {
    display: none;
  }
`

const FIELD_TYPES = {
  VALUE: 'value',
  RANGE: 'range',
  SELECT: 'select',
  MULTI_SELECT: 'multi_select'
}

const FIELD_VALUE_TYPES = {
  STRING: 'string',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  DATE: 'date'
}

const NONE_OPTION = {
  label: 'Not Known',
  value: '',
  actualValue: null
}

const BOOL_OPTIONS = [
  {
    label: 'Not Known',
    value: '',
    actualValue: null
  },
  {
    label: 'True',
    value: 'true',
    actualValue: true
  },
  {
    label: 'False',
    value: 'false',
    actualValue: false
  }
]

const VALIDATORS = {
  integer: (num) => num === '' || !isNaN(parseInt(num)),
  float: (num) => num === '' || !isNaN(parseFloat(num)),
  string: () => true
}

const PARSERS = {
  integer: (num) => (num === '' ? null : parseInt(num)),
  float: (num) => (num === '' ? null : parseFloat(num)),
  string: (str) => str
}

function getDisplayValue(fieldType, fieldValueType, value, options) {
  const idToLabel = options.reduce((acc, item) => {
    acc[item.value] = item.label
    return acc
  }, {})

  if (value === null) {
    return 'Not known'
  } else if (fieldType === FIELD_TYPES.MULTI_SELECT) {
    if (value.length) {
      return value.map((item) => idToLabel[item]).join(', ')
    } else {
      return 'None'
    }
  } else if (fieldType === FIELD_TYPES.SELECT) {
    return idToLabel[value]
  } else if (fieldType === FIELD_TYPES.RANGE) {
    let low = value.low
    let high = value.high

    if (
      fieldValueType === FIELD_VALUE_TYPES.INTEGER ||
      fieldValueType === FIELD_VALUE_TYPES.FLOAT
    ) {
      if (low !== null) {
        low = low.toLocaleString()
      }

      if (high !== null) {
        high = high.toLocaleString()
      }
    }
    return `${low === null ? '?' : low} - ${high === null ? '?' : high}`
  } else if (fieldValueType === FIELD_VALUE_TYPES.DATE) {
    return value.toString()
  } else {
    return value.toString()
  }
}

function getStartingValue(fieldType, value, options) {
  const labelToValue = options.reduce((acc, item) => {
    acc[item.label] = item.id
    return acc
  }, {})

  if (value === null) {
    return value
  } else if (fieldType === FIELD_TYPES.MULTI_SELECT) {
    return value.map((item) => labelToValue[item])
  } else if (fieldType === FIELD_TYPES.SELECT) {
    return labelToValue[value]
  } else {
    return value
  }
}

function CrmField({
  label,
  fieldType,
  fieldValueType,
  value,
  onChange,
  options = []
}) {
  const [startingValue, setStartingValue] = useState(
    getStartingValue(fieldType, value, options)
  )
  const [currentValue, setCurrentValue] = useState(
    getStartingValue(fieldType, value, options)
  )
  const [isEditing, setIsEditing] = useState(false)
  const [labelCased] = useState(startCase(label))
  const [optionsCased] = useState(
    options.map((item) => ({
      label: startCase(item.label),
      value: item.id
    }))
  )

  let inputStructure = null

  if (
    fieldType === FIELD_TYPES.VALUE &&
    [
      FIELD_VALUE_TYPES.STRING,
      FIELD_VALUE_TYPES.INTEGER,
      FIELD_VALUE_TYPES.FLOAT
    ].includes(fieldValueType)
  ) {
    // TESTED FOR INTEGER

    inputStructure = (
      <Input
        value={currentValue ? currentValue.toString() : ''}
        onChange={(val) => {
          if (VALIDATORS[fieldValueType](val)) {
            setCurrentValue(PARSERS[fieldValueType](val))
          }
        }}
      />
    )
  } else if (
    fieldType === FIELD_TYPES.VALUE &&
    fieldValueType === FIELD_VALUE_TYPES.BOOLEAN
  ) {
    // TESTED

    inputStructure = (
      <Dropdown
        showSelectText={false}
        value={currentValue === null ? '' : currentValue.toString()}
        onChange={(val) => {
          setCurrentValue(
            BOOL_OPTIONS.find((item) => item.value === val).actualValue
          )
        }}
        options={BOOL_OPTIONS}
      />
    )
  } else if (
    fieldType === FIELD_TYPES.VALUE &&
    fieldValueType === FIELD_VALUE_TYPES.DATE
  ) {
    // TESTED

    inputStructure = (
      <Input
        type='date'
        value={currentValue || ''}
        onChange={(val) => setCurrentValue(val)}
      />
    )
  } else if (fieldType === FIELD_TYPES.RANGE) {
    if (
      [FIELD_VALUE_TYPES.INTEGER, FIELD_VALUE_TYPES.FLOAT].includes(
        fieldValueType
      )
    ) {
      // TESTED

      inputStructure = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            value={
              currentValue && currentValue.low
                ? currentValue.low.toString()
                : ''
            }
            onChange={(val) => {
              if (VALIDATORS[fieldValueType](val)) {
                setCurrentValue({
                  low: PARSERS[fieldValueType](val),
                  high: currentValue ? currentValue.high : null
                })
              }
            }}
          />
          <div style={{ margin: '0 5px', whiteSpace: 'nowrap' }}>to</div>
          <Input
            value={
              currentValue && currentValue.high
                ? currentValue.high.toString()
                : ''
            }
            onChange={(val) => {
              if (VALIDATORS[fieldValueType](val)) {
                setCurrentValue({
                  low: currentValue ? currentValue.low : null,
                  high: PARSERS[fieldValueType](val)
                })
              }
            }}
          />
        </div>
      )
    } else if (fieldValueType === FIELD_VALUE_TYPES.DATE) {
      inputStructure = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type='date'
            value={currentValue && currentValue.low ? currentValue.low : ''}
            onChange={(val) => {
              setCurrentValue({
                low: val,
                high: currentValue.high
              })
            }}
          />
          <div style={{ margin: '0 5px', whiteSpace: 'nowrap' }}>to</div>
          <Input
            type='date'
            value={currentValue && currentValue.high ? currentValue.high : ''}
            onChange={(val) => {
              setCurrentValue({
                low: currentValue.low,
                high: val
              })
            }}
          />
        </div>
      )
    }
  } else if (fieldType === FIELD_TYPES.SELECT) {
    // TESTED

    inputStructure = (
      <Dropdown
        showSelectText={false}
        value={!currentValue ? '' : currentValue}
        onChange={(val) => setCurrentValue(val || null)}
        options={[NONE_OPTION].concat(optionsCased)}
      />
    )
  } else if (fieldType === FIELD_TYPES.MULTI_SELECT) {
    // TESTED

    inputStructure = (
      <MultiSelect
        isSearchable={false}
        values={!currentValue ? [] : currentValue}
        onChange={setCurrentValue}
        options={optionsCased}
      />
    )
  }

  if (!inputStructure) {
    return (
      <div>
        {label} of type: {fieldType} and value type: {fieldValueType} not
        supported
      </div>
    )
  }

  return (
    <StyledField
      style={{
        cursor: isEditing ? '' : 'pointer'
      }}
      onClick={() => {
        if (!isEditing) {
          setIsEditing(true)
        }
      }}
      isEditing={isEditing}
    >
      <div>
        <Label>{labelCased}</Label>
        {isEditing ? (
          <div style={{ display: 'flex', alignItems: 'top ' }}>
            <div style={{ flexGrow: 1 }}> {inputStructure}</div>
            <div style={{ marginLeft: '5px' }}>
              <Button
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  if (!isEqual(startingValue, currentValue)) {
                    let returnValue = currentValue

                    if (
                      fieldType === FIELD_TYPES.SELECT &&
                      currentValue !== null
                    ) {
                      returnValue = {
                        option_type: 'field_options',
                        option_id: currentValue
                      }
                    } else if (fieldType === FIELD_TYPES.MULTI_SELECT) {
                      returnValue = currentValue.map((item) => ({
                        option_type: 'field_options',
                        option_id: item
                      }))
                    }

                    onChange(returnValue)
                    setStartingValue(currentValue)
                  }

                  setIsEditing(false)
                }}
                size='s'
              >
                Done
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {getDisplayValue(
              fieldType,
              fieldValueType,
              currentValue,
              optionsCased
            )}
          </div>
        )}
      </div>
      <div className='pencil' style={{ marginRight: '10px' }}>
        <Pencil />
      </div>
    </StyledField>
  )
}

CrmField.propTypes = {
  fieldType: PropTypes.oneOf(Object.values(FIELD_TYPES)),
  fieldValueType: PropTypes.oneOf(Object.values(FIELD_VALUE_TYPES)),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.string,
  values: PropTypes.any
}

export default memo(CrmField)
