import React, { FunctionComponent, useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { Spinner } from 'reactstrap'

import * as SC from '../StyledComponents'
import { REQUEST_POLICY } from 'constants/urql'
import { CustomerHeaderInfoByUserIdDocument as queryDoc } from 'graphql/generated'
import { format, subHours } from 'date-fns'
import { mapContentToBadge } from '../badges'
import { Customer } from '../types'
import DropDown from './DropDown'

import { connect } from 'react-redux'
import { StoreState } from 'store'

type PageTitleComponentPropTypes = {
  customer: Customer
  triggerReexecute?: Function
}

const PageTitle: FunctionComponent<PageTitleComponentPropTypes> = ({
  customer,
  triggerReexecute
}) => {
  const [createdAt, setCreatedAt] = useState<Date | null>(null)
  const [badgeStatus, setBadgeStatus] = useState<string>('')

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: queryDoc,
    variables: { id: customer.userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  useEffect(() => {
    if (triggerReexecute) {
      reexecuteQuery()
    }
  }, [triggerReexecute])

  useEffect(() => {
    const user = data?.users[0]
    if (!user) return

    setCreatedAt(subHours(new Date(user.created_at), 100))

    if (user.deleted_at) {
      setBadgeStatus('deleted')
    } else if (user.rental_users?.length) {
      setBadgeStatus('renter')
    } else if (
      // checks if any active rental applications associated with any of the users lead groups
      user.lead_group_users.some((lg: any) => lg.rental_applications.length)
    ) {
      setBadgeStatus('applied')
    } else if (!user.user_prequalification_data) {
      setBadgeStatus('needs_prequalification')
    } else if (user.user_funnel_status.lead_status) {
      setBadgeStatus(user.user_funnel_status.lead_status)
    }
  }, [data])

  if (fetching) {
    return <Spinner />
  }
  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  if (!data?.users[0]) {
    return null
  }

  return (
    <>
      <SC.PageTitleContainer>
        <SC.PageTitleSubContainer>
          <SC.PageTitle>
            {customer.firstName} {customer.lastName}
          </SC.PageTitle>
          {badgeStatus ? mapContentToBadge(badgeStatus) : null}
        </SC.PageTitleSubContainer>
        <DropDown />
      </SC.PageTitleContainer>
      {!!createdAt ? (
        <SC.PageSubtitle>
          Created on {format(createdAt, 'MMMM d, yyyy')}
        </SC.PageSubtitle>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: StoreState) => ({
  triggerReexecute: state.refreshComponents.reexecuteHeader
})

export default connect(mapStateToProps, null)(PageTitle)
