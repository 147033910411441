import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import styled from 'styled-components'

import axios from '../lib/axios'

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`

export default class ShortUrlsScreen extends Component {
  static propTypes = {
    createShortUrl: PropTypes.func.isRequired
  }

  state = {
    url: '',
    submittedUrl: '',
    shortUrl: '',
    disabled: true
  }

  handleUrlUpdate = (e) => {
    const { value } = e.target

    this.setState({
      url: value,
      disabled: !value,
      shortUrl: '',
      submittedUrl: ''
    })
  }

  handleSubmit = async () => {
    this.setState({ disabled: true })

    const url = this.state.url

    const shortUrlRes = await axios.post('/shortened_urls', { url })

    const shortUrl = shortUrlRes.data

    this.setState({
      url: '',
      shortUrl,
      submittedUrl: url
    })
  }

  render() {
    const { disabled, shortUrl, url, submittedUrl } = this.state

    return (
      <Container>
        <h3>Create a short url</h3>

        <Form autoComplete='off'>
          <FormGroup>
            <Label for='url'>Enter the URL to shorten</Label>

            <Input
              type='text'
              name='url'
              value={url}
              onChange={this.handleUrlUpdate}
            />
          </FormGroup>

          <StyledButton
            color='primary'
            onClick={this.handleSubmit}
            disabled={disabled}
            type='submit'
          >
            Submit
          </StyledButton>
        </Form>

        {shortUrl && (
          <>
            <h5>Short URL for {submittedUrl}:</h5>
            <b>{shortUrl}</b>
          </>
        )}
      </Container>
    )
  }
}
