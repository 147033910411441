import PropTypes from 'prop-types'
import React, { Component } from 'react'
import get from 'lodash/get'

import { firestoreCollections } from '../../lib/firebase'

export default class UsersEmailRedirectScreen extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  async UNSAFE_componentWillMount() {
    const email = get(this.props.match, 'params.email')

    const usersQuery = await firestoreCollections.usersRef
      .where('email', '==', email)
      .limit(1)
      .get()

    if (!usersQuery.docs.length) return

    this.props.history.push(`/users/${usersQuery.docs[0].id}`)
  }

  render() {
    return <div />
  }
}
