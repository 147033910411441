"use strict";
exports.AGREEMENT_STATUSES = Object.freeze({
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    PENDING: 'pending'
});
exports.AGREEMENT_EXTERNAL_SOURCES = Object.freeze({
    GOOGLE_CLOUD: 'google_cloud',
    HELLOSIGN: 'hellosign'
});
exports.AGREEMENT_TYPES = Object.freeze({
    LEASE: 'lease',
    LEASE_AMENDMENT: 'lease_amendment',
    LEASE_RENEWAL: 'lease_renewal',
    LEASE_TERMINATION: 'lease_termination',
    PURCHASE_OPTION_AGREEMENT: 'purchase_option_agreement'
});
