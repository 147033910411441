import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { useMutation } from 'urql'

import { Button, Input, ErrorText, TextArea } from 'components/Toolkit'
import { UpupInsertRentalCreditDocument } from 'graphql/generated'
import LedgerAccountsDropdown from './LedgerAccountsDropdown'
import { rentalCredits } from '@homevest/utils'

const IssueCreditForm: React.FC<{
  onSubmit: () => void
  rentalId: string
}> = ({ onSubmit, rentalId }) => {
  const [price, setPrice] = useState('')
  const [note, setNote] = useState('')
  const [date, setDate] = useState(
    moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')
  )
  const [offsettingLedgerAccountId, setOffsettingLedgerAccountId] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const [{ fetching, error }, createRentalCredit] = useMutation(
    UpupInsertRentalCreditDocument
  )

  const isValid =
    date &&
    moment(date).isValid() &&
    price &&
    !Number.isNaN(price) &&
    note &&
    note.trim() &&
    offsettingLedgerAccountId

  const handleSubmit = async () => {
    if (!isValid) {
      return
    }

    setIsSaving(true)

    const result = await createRentalCredit({
      amount: Number(price),
      date,
      note: note.trim(),
      offsetting_ledger_account_id: offsettingLedgerAccountId,
      rental_id: rentalId,
      type: rentalCredits.RENTAL_CREDIT_TYPES.EXCHANGE
    })

    setIsSaving(false)

    if (result.error === undefined) {
      setPrice('')
      setDate('')
      setNote('')
      setOffsettingLedgerAccountId('')
      onSubmit()
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <Input
          label='Price'
          type='number'
          onChange={setPrice}
          value={price}
          placeholder='Enter the amount of the credit'
          required
          min='0'
          hasError={Number(price) <= 0}
          disabled={isSaving || fetching}
        />
        <Input
          label='Date to credit - usually the first of the next month'
          type='date'
          onChange={setDate}
          value={date}
          placeholder='Usually the first of the next month'
          required
          disabled={fetching || isSaving}
          hasError={!date}
        />
        <LedgerAccountsDropdown
          disabled={fetching || isSaving}
          onSelect={setOffsettingLedgerAccountId}
          value={offsettingLedgerAccountId}
        />
        <TextArea
          label='Note'
          type='textarea'
          rows={5}
          onChange={setNote}
          value={note}
          placeholder='Enter the reason for giving the credit'
          required
          disabled={fetching || isSaving}
          hasError={!note}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={handleSubmit}
            disabled={!isValid || fetching || isSaving}
          >
            Issue Rental Credit
          </Button>
          {error !== undefined && <ErrorText>{error.message}</ErrorText>}
        </div>
      </React.Suspense>
    </>
  )
}

IssueCreditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  rentalId: PropTypes.string.isRequired
}

export default IssueCreditForm
