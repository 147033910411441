import React, { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import BackArrow from 'components/Toolkit/Icons/BackArrow'
import ProgressBar from 'components/Toolkit/ProgressBar'

const TYPE_TO_COLOR = {
  intro: COLORS.LIGHT_NEUTRALS.CORNFLOWER,
  default: COLORS.LIGHT_NEUTRALS.GARLIC
}

const StyledContainer = styled.div`
  padding: 70px 29px;
  min-height: 86vh;

  @media (max-width: 750px) {
    padding: 50px 29px;
    min-height: initial;
  }
`

const StyledContentWrapper = styled.div`
  margin: 0 auto 143px auto;
  max-width: ${(props) => (props.type === 'intro' ? '900px' : '600px')};
  display: flex;
  overflow: hidden;

  @media (max-width: 750px) {
    display: block;
  }
`

const StyledArrowContainer = styled.div`
  cursor: pointer;
  margin-right: 40px;

  @media (max-width: 750px) {
    margin-bottom: 50px;
  }
`

const StyledFixedFooter = styled.div`
  background: ${COLORS.PRIMARY.COCONUT};
  border: 1px solid ${COLORS.PRIMARY.COCONUT};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 143px;
  z-index: 10;
`

const StyledFixedFooterInnerContainer = styled.div`
  padding: 26px 36px;
`

const StyledChildren = styled.div`
  width: 100%;
`

function ContentContainer({
  type = 'default',
  goBack = null,
  fixedFooter = null,
  progress = null,
  shouldScrollDown = false,
  onScrollDown = () => null,
  children
}) {
  const containerRef = useRef(null)

  const scrollToBottom = () => {
    if (shouldScrollDown) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      onScrollDown()
    }
  }
  useEffect(scrollToBottom, [shouldScrollDown])

  return (
    <div
      style={{
        background: TYPE_TO_COLOR[type]
      }}
      ref={containerRef}
    >
      <StyledContainer type={type}>
        <StyledContentWrapper type={type}>
          {goBack && (
            <StyledArrowContainer onClick={goBack}>
              <BackArrow />
            </StyledArrowContainer>
          )}
          <StyledChildren>{children}</StyledChildren>
        </StyledContentWrapper>
        {fixedFooter && (
          <StyledFixedFooter>
            {progress && <ProgressBar {...progress} />}

            <StyledFixedFooterInnerContainer>
              {fixedFooter}
            </StyledFixedFooterInnerContainer>
          </StyledFixedFooter>
        )}
      </StyledContainer>
    </div>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node,
  fixedFooter: PropTypes.node,
  goBack: PropTypes.func,
  onScrollDown: PropTypes.func,
  progress: PropTypes.object,
  shouldScrollDown: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'intro'])
}

export default memo(ContentContainer)
