"use strict";
exports.format = (phone) => {
    if (!phone) {
        return null;
    }
    let to = phone.replace(/\D/g, '');
    if (to.length === 10) {
        to = `+1${to}`;
    }
    else if (to.length === 11 && to[0] === '1') {
        to = `+${to}`;
    }
    else if (to.length !== 12 || to.substring(0, 2) !== '+1') {
        return null;
    }
    return to;
};
