import ContentSection from 'components/360/CustomerProfile/ContentSection'
import React, { FunctionComponent } from 'react'
import { DetailsContainer } from 'components/360/CustomerProfile/StyledComponents'
import SingleDetailRow from 'components/360/CustomerProfile/PersonalDetails/SingleDetailRow'
import { PropertyGridDataType } from 'components/Homes/Grid/grid'
import { first, get } from 'lodash'

type AcquisitionType = PropertyGridDataType['real_estate_acquisition']
interface OverviewPanelProps {
  title: string
  acquisition: AcquisitionType
}

interface PanelAttribute<T> {
  label: string
  value?: T
  valueGetter?: (acquisition: AcquisitionType) => string
  valueFormatter?: (value: T) => string
}

const OVERVIEW_PANEL_CONFIG: PanelAttribute<string | number>[] = [
  {
    label: 'Price',
    value: 'final_purchase_price'
  },
  {
    label: 'Closing Date',
    value: 'closing_date'
  },
  {
    label: 'Underwritten Rent',
    valueGetter: (acq) => {
      return first(acq?.mls_listing.rent_prices)?.rent_price
    }
  },
  {
    label: 'Underwritten Yield',
    valueGetter: (acquisition) => {
      return first(acquisition?.mls_listing.rent_prices)?.estimated_cap_rate
    }
  },
  {
    label: 'Initial Capex',
    valueGetter: (acquisition) => {
      return first(acquisition?.mls_listing.rent_prices)
        ?.upfront_improvements_cost
    }
  },
  {
    label: "Seller's Credits",
    valueGetter: (acquisition) => {
      return first(acquisition?.mls_listing.rent_prices)?.sellers_credits
    }
  }
]

const AcquisitionPanel: FunctionComponent<OverviewPanelProps> = ({
  title,
  acquisition
}) => {
  return (
    <ContentSection
      title={title}
      content={
        <DetailsContainer>
          {OVERVIEW_PANEL_CONFIG.map((attribute) => {
            let value = ''
            if (attribute.value) {
              value = get(acquisition, attribute.value)
            } else if (attribute.valueGetter) {
              value = attribute.valueGetter(acquisition)
            } else {
              value = get(acquisition, attribute.label.toLowerCase())
            }
            return (
              <SingleDetailRow
                header={attribute.label}
                content={value}
                editable={false}
              />
            )
          })}
        </DetailsContainer>
      }
    />
  )
}

export default AcquisitionPanel
