import {
  Client,
  createClient,
  dedupExchange,
  fetchExchange,
  subscriptionExchange
} from 'urql'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { devtoolsExchange } from '@urql/devtools'
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast'
import schema from '../graphql/graphql.schema.json'
import { graphqlConfig } from './config'
import { getCacheExchange } from './urql-cache-exchange'

const createUrqlClient = (authToken: string | null): Client => {
  const subscriptionClient = new SubscriptionClient(
    graphqlConfig.websocketURL,
    {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${authToken ?? ''}`
        }
      }
    }
  )

  return createClient({
    url: graphqlConfig.serverURL,
    suspense: true,
    fetchOptions: {
      headers: {
        Authorization: `Bearer ${authToken ?? ''}`
      }
    },
    exchanges: [
      ...(process.env.NODE_ENV === 'development' ? [devtoolsExchange] : []),
      dedupExchange,
      getCacheExchange(schema as unknown as IntrospectionData),
      ...(authToken === null
        ? []
        : [
            subscriptionExchange({
              forwardSubscription(operation) {
                return subscriptionClient.request(operation)
              }
            })
          ]),
      fetchExchange
    ]
  })
}

export default createUrqlClient
