import React from 'react'
import PropTypes from 'prop-types'

import { BodyText, Label } from 'components/Toolkit'

export default function CriminalReportOffense({ hasBottomBorder, offense }) {
  const { caseType, description, offenseDate } = offense

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 1.5rem',
        borderBottom: hasBottomBorder ? '1px solid rgb(204, 204, 204)' : ''
      }}
    >
      <div style={{ flex: 1 }}>
        <Label>Offense Date</Label>
        <BodyText style={{ marginTop: 0 }}>
          {offenseDate || 'Not reported'}
        </BodyText>
      </div>
      <div style={{ flex: 1 }}>
        <Label>Case Type</Label>
        <BodyText style={{ marginTop: 0 }}>
          {caseType || 'Not reported'}
        </BodyText>
      </div>
      <div style={{ flex: 1 }}>
        <Label>Description</Label>
        <BodyText style={{ marginTop: 0 }}>
          {description || 'Not reported'}
        </BodyText>
      </div>
    </div>
  )
}

CriminalReportOffense.propTypes = {
  hasBottomBorder: PropTypes.bool,
  offense: PropTypes.object.isRequired
}
