import React, { memo } from 'react'
import PropTypes from 'prop-types'
import COLORS from 'components/Toolkit/colors'

function UpChevron({ color = COLORS.NEUTRALS.OYSTER }) {
  return (
    <svg width='17' height='10' viewBox='0 0 17 10' fill='none'>
      <path
        d='M14.3128 9.34441C14.6991 9.71163 15.3056 9.7113 15.6915 9.34366L16.2399 8.82122C16.6536 8.42715 16.6536 7.76718 16.2399 7.37311L9.18974 0.657054C8.80351 0.289128 8.19649 0.289127 7.81026 0.657053L0.760078 7.37311C0.346401 7.76718 0.346399 8.42715 0.760078 8.82122L1.30851 9.34366C1.69444 9.7113 2.30088 9.71163 2.6872 9.34441L7.81105 4.47404C8.19707 4.10711 8.80293 4.10711 9.18895 4.47403L14.3128 9.34441Z'
        fill={color}
      />
    </svg>
  )
}

UpChevron.propTypes = {
  color: PropTypes.string
}

export default memo(UpChevron)
