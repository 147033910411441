// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: FIX TS ERRORS in this file!!!
import React from 'react'
import PropTypes from 'prop-types'
import {
  UpupAdminsWithCapabilitiesQuery,
  UpupCapabilitiesWithAdminQuery
} from 'graphql/generated'
import { Modal, BodyText, HeaderText } from 'components/Toolkit'
import CapabilityToggle from './CapabilityToggle'

type Capability =
  | UpupCapabilitiesWithAdminQuery['capabilities'][number]
  | undefined

type Props = {
  isOpen: boolean
  onClose: () => void
  admins: UpupAdminsWithCapabilitiesQuery['admins']
  capability: Capability
}

const EditCapAdminsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  admins,
  capability
}) => {
  const adminCapToPkMap = React.useMemo(
    () =>
      capability?.admin_capabilities.reduce(
        (acc, adminCap) => ({ ...acc, [adminCap.admin.id]: adminCap.id }),
        {}
      ) ?? {},
    [capability]
  ) as { [id: string]: string }

  return isOpen ? (
    <Modal hasCloseButton close={onClose} width='50vw'>
      <HeaderText size='h3'>{capability?.name}</HeaderText>
      <BodyText size='s' style={{ fontStyle: 'italic', marginBottom: '1rem' }}>
        Changes are applied immediately.
      </BodyText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridAutoColumns: 'none',
          maxHeight: '66vh',
          overflowY: 'auto'
        }}
      >
        <BodyText>Admin</BodyText>
        <BodyText style={{ textAlign: 'center' }}>Has Access</BodyText>
        <hr
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 'end',
            width: '100%',
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
          }}
        />
        {admins.map((admin) => (
          <React.Fragment key={admin.id}>
            <BodyText weight='bold' style={{ gridColumn: 1 }}>
              {admin.first_name} {admin.last_name}
            </BodyText>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '0.5rem'
              }}
            >
              <CapabilityToggle
                value={typeof adminCapToPkMap[admin.id] === 'string'}
                adminId={admin.id}
                capId={capability?.id}
                adminCapPk={adminCapToPkMap[admin.id]}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </Modal>
  ) : null
}

EditCapAdminsModal.propTypes = {
  admins: PropTypes.array.isRequired,
  capability: PropTypes.shape({
    admin_capabilities: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default EditCapAdminsModal
