import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { Grid } from 'components/Homes/Grid/grid'
import { useHomesGridQuery } from 'graphql/generated'

interface HomesListProps {}

const AddressInput: FunctionComponent<{
  handleChange: (input: string) => void
}> = ({ handleChange }) => {
  return (
    <div>
      <label
        htmlFor='search'
        className='block text-sm font-medium text-gray-700'
      >
        Quick search
      </label>
      <div className='relative mt-1 flex items-center'>
        <input
          type='text'
          onChange={(e) => handleChange(e.currentTarget.value)}
          name='search'
          id='search'
          placeholder='10 Grand St...'
          className='block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        />
      </div>
    </div>
  )
}
const HomesList: FunctionComponent<HomesListProps> = () => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [_columnApi, setColumnApi] = useState<ColumnApi>()
  const [addressQuery, setAddressQuery] = useState<string>()
  const context = useMemo(() => ({ suspense: false }), [])
  const [{ data, error }] = useHomesGridQuery({
    context
  })

  const onGridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api)
    setColumnApi(evt.columnApi)
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.getFilterInstance('address', (filter) => {
        filter?.setModel({
          filterType: 'text',
          type: 'contains',
          filter: addressQuery
        })
        gridApi.onFilterChanged()
      })
    }
  }, [addressQuery, gridApi])

  if (error) {
    return <div>Error: {error.message}</div>
  }
  return (
    <div>
      <AddressInput handleChange={setAddressQuery} />
      <Grid onGridReady={onGridReady} rowData={data?.properties || []} />;
    </div>
  )
}

export default HomesList
