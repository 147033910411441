import React, { useState } from 'react'
import { FormGroup, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'

import ApproveButton from './ApproveButton'
import RejectButton from './RejectButton'
import PermissionDeniedReasonSelect from './PermissionDeniedReasonSelect'

export default function ApproveReject({
  isDisabled,
  onUpdate,
  rejectionReason: propsRejectionReason,
  sellerAgentPropertyId,
  setRejectionReason: propsSetRejectionReason,
  showApprove,
  showReject,
  style
}) {
  const [rejectionReason, setRejectionReason] = useState(propsRejectionReason)

  const fullSetRejectionReason = (rejectionReason) => {
    setRejectionReason(rejectionReason)
    propsSetRejectionReason(rejectionReason)
  }

  return (
    <>
      {showReject && (
        <Row style={style}>
          <Col>
            <FormGroup>
              <PermissionDeniedReasonSelect
                isDisabled={isDisabled}
                rejectionReason={rejectionReason}
                setRejectionReason={fullSetRejectionReason}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row style={showReject ? {} : style}>
        <Col>
          {showReject && (
            <RejectButton
              isDisabled={isDisabled || !rejectionReason}
              onUpdate={onUpdate}
              sellerAgentPropertyId={sellerAgentPropertyId}
              rejectionReason={rejectionReason}
            />
          )}
          {showApprove && (
            <ApproveButton
              isDisabled={isDisabled}
              onUpdate={onUpdate}
              style={showReject ? { marginLeft: '1rem' } : {}}
              sellerAgentPropertyId={sellerAgentPropertyId}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

ApproveReject.propTypes = {
  isDisabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  rejectionReason: PropTypes.string,
  sellerAgentPropertyId: PropTypes.string,
  setRejectionReason: PropTypes.func,
  showApprove: PropTypes.bool,
  showReject: PropTypes.bool,
  style: PropTypes.object
}

ApproveReject.defaultProps = {
  isDisabled: false,
  onUpdate: () => null,
  rejectionReason: '',
  setRejectionReason: () => null,
  showApprove: true,
  showReject: true,
  style: {}
}
