"use strict";
exports.USER_FIELDS = Object.freeze({
    HAS_COSIGNER: 'has_cosigner',
    HAS_GOOD_CREDIT_ROOMMATE: 'has_good_credit_roommate',
    HAS_RECEIVED_SALES_PRODUCT_EXPLANATION: 'has_received_sales_product_explanation',
    HAS_VOUCHER: 'has_voucher',
    NOT_INTERESTED_REASON: 'not_interested_reason',
    SELF_REPORTED_CREDIT: 'self_reported_credit',
    VOUCHER_TYPE: 'voucher_type' // string
});
