import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'

const APPLICATION_ID = process.env.REACT_APP_DD_APPLICATION_ID
const CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN
const SERVICE = process.env.REACT_APP_DD_SERVICE
const ENV = process.env.REACT_APP_DD_ENV

/**
 * Initialize Datadog RUM application
 */
export function initializeDatadog() {
  if (!IS_PRODUCTION || !APPLICATION_ID || !CLIENT_TOKEN || !SERVICE || !ENV) {
    return console.debug('Missing some env:', {
      APPLICATION_ID,
      CLIENT_TOKEN,
      SERVICE,
      ENV
    })
  }

  console.debug(`Initializing "${SERVICE}" for "${ENV}" environment!`)

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    sampleRate: 100,
    trackInteractions: true,
    service: SERVICE,
    env: ENV,
    allowedTracingOrigins: [/https:\/\/(staging-|)upup.hasura.app/]
  })

  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: SERVICE,
    env: ENV
  })
}
