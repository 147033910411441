import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'

// Just a random note, HTML doesn't support treating the date text as a placeholder
// So having the placeholder color and then then input color didn't make sense
const StyledInput = styled.input`
  background: ${COLORS.PRIMARY.COCONUT};
  border: ${(props) =>
    props.hasError
      ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
      : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${(props) =>
    props.type === 'date'
      ? COLORS.LIGHT_NEUTRALS.PARSNIP
      : COLORS.PRIMARY.PLUM};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 168.68%;
  padding: 10px 22px;
  transition: border 200ms;
  width: 100%;

  &::placeholder {
    color: ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  }

  &:focus {
    border: ${(props) =>
      props.hasError
        ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
        : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  }
`

const StyledInputContainer = styled.div`
  margin: ${(props) => (props.hasLabel ? '25px 0' : '')};
`

function Input({ label, value, onChange, type, hasError, ...otherProps }) {
  const inputRef = React.useRef()

  // Disable the number scroll for very important number inputs
  // https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
  const duplicateProps = {
    ...otherProps
  }
  if (duplicateProps.disableNumberScroll) {
    duplicateProps.onWheel = () => {
      inputRef.current.blur()
    }
    duplicateProps.ref = inputRef
  }

  return (
    <StyledInputContainer hasLabel={Boolean(label)}>
      {label && <Label>{label}</Label>}
      <StyledInput
        type={type}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        hasError={hasError}
        {...duplicateProps}
      />
    </StyledInputContainer>
  )
}

Input.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
}

export default memo(Input)
