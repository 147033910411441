import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import { Link } from 'react-router-dom'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col
} from 'reactstrap'

const KVRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-bottom: ${(props) => (props.hasBorder ? '10px' : '5px')}
  border-bottom: ${(props) => (props.hasBorder ? '1px solid grey' : 0)}
`

const Key = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
`

const EditDropdown = styled(Dropdown)`
  padding-bottom: 0px;
  padding-top: 0rem;
  margin-left: 10px;
`

const AgentRow = ({
  firstName,
  lastName,
  agentId,
  isPrimary,
  propertyHasSecondaryAgents,
  handlePrimaryUpdate
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(!dropdownOpen)

  return (
    <KVRow hasBorder={isPrimary && propertyHasSecondaryAgents}>
      {isPrimary ? (
        <Key style={{ marginBottom: '10px', borderBottom: '1px' }}>
          Primary:
        </Key>
      ) : (
        <Key>Other:</Key>
      )}
      <Link to={`/seller-agents/${agentId}`} target='_blank'>
        {firstName || lastName
          ? startCase(`${firstName} ${lastName}`.toLowerCase())
          : { agentId }}
      </Link>

      {!isPrimary ? (
        <Col>
          <EditDropdown size='sm' isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              style={{
                background: 'transparent',
                color: '#545b62',
                paddingLeft: '0.2rem',
                paddingBottom: '0rem',
                verticalAlign: '0.5em'
              }}
              caret
            />
            <DropdownMenu>
              <DropdownItem onClick={handlePrimaryUpdate}>
                Make Primary Agent
              </DropdownItem>
            </DropdownMenu>
          </EditDropdown>
        </Col>
      ) : null}
    </KVRow>
  )
}

AgentRow.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  agentId: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
  propertyHasSecondaryAgents: PropTypes.bool,
  handlePrimaryUpdate: PropTypes.func
}

export default AgentRow
