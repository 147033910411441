import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner, Input } from 'reactstrap'
import { useSubscription } from 'urql'
import { Comparators as Comp } from 'utils/hasura-comparators'

import { debounce } from 'lodash'
import {
  UpupLeadGroupsUserListDocument as query,
  UpupLeadGroupsUserListSubscription
} from 'graphql/generated'
import { getContactInfo } from 'lib/users'

const LeadGroupEntries: React.FC<{
  isDisabled: boolean
  showSearchOnly?: boolean
  selectText: string
  searchText: string
  onSelect: (leadGroup: any) => void
}> = ({ searchText, selectText, isDisabled, showSearchOnly, onSelect }) => {
  const hasSearchText = Boolean(searchText.length)
  const [{ data }] = useSubscription<UpupLeadGroupsUserListSubscription>({
    query,
    variables: {
      limit: Boolean(showSearchOnly) && !hasSearchText ? 0 : 10,
      where: {
        status: { _eq: 'active' },
        ...(!hasSearchText
          ? {}
          : {
              lead_group_users: {
                user_searchable: {
                  info: Comp.isLike(`%${searchText}%`)
                }
              }
            })
      }
    }
  })

  const leadGroups = data?.lead_groups ?? []

  return (
    <>
      {leadGroups.map((leadGroup, idx) => (
        <div
          key={leadGroup.id}
          style={{
            display: 'flex',
            padding: '10px 0',
            alignItems: 'center',
            backgroundColor: idx % 2 === 0 ? '#fff' : '#eee'
          }}
        >
          <div style={{ display: 'flex', width: '100%' }} key={leadGroup.id}>
            <div style={{ width: '40%' }}>
              {leadGroup.lead_group_users.map(
                ({
                  user: { id, first_name: firstName, last_name: lastName }
                }) => (
                  <div key={`${id as string}_name`}>
                    {firstName} {lastName}
                  </div>
                )
              )}
            </div>
            <div style={{ width: '60%' }}>
              {leadGroup.lead_group_users.map(
                ({ user: { id, user_contact_details } }) => (
                  <div key={id}>
                    {getContactInfo('email', user_contact_details)}
                  </div>
                )
              )}
            </div>
          </div>
          <div style={{ minWidth: '80px' }}>
            <Button
              isDisabled={isDisabled}
              onClick={() => onSelect(leadGroup)}
              color='primary'
            >
              {selectText}
            </Button>
          </div>
        </div>
      ))}
    </>
  )
}

LeadGroupEntries.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  selectText: PropTypes.string.isRequired,
  showSearchOnly: PropTypes.bool.isRequired
}

const LeadGroupSearch: React.FC<{
  isDisabled: boolean
  showSearchOnly?: boolean
  selectText: string
  onSelect: (leadGroup: any) => void
}> = ({ onSelect, selectText, isDisabled, showSearchOnly }) => {
  const [searchText, setSearchText] = useState('')
  const [slowText, setSlowText] = useState('')

  const setSearchDebounced = React.useMemo(
    () => debounce(setSlowText, 250, { trailing: true }),
    [setSlowText]
  )

  return (
    <div>
      <Input
        placeholder='Search lead groups...'
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
          setSearchDebounced(e.target.value)
        }}
      />
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <LeadGroupEntries
          onSelect={onSelect}
          selectText={selectText}
          isDisabled={isDisabled}
          showSearchOnly={showSearchOnly}
          searchText={slowText}
        />
      </React.Suspense>
    </div>
  )
}

LeadGroupSearch.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectText: PropTypes.string.isRequired,
  showSearchOnly: PropTypes.bool.isRequired
}

export default LeadGroupSearch
