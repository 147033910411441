import React, { FunctionComponent } from 'react'
import { format } from 'date-fns'
import { types } from '@homevest/timelines'
import { Customer } from '../types'
import { RENTLY_PROPERTY_PAGE_SLUG } from 'constants/urls'
import * as SC from '../../StyledComponents'
import DisplayPropertyDetails from './PropertyDetails'
import { buildZillowUrlFromAddress } from 'lib/build-urls'

//TODO: figure out how to add links to the listings
export const DisplayInquiry: FunctionComponent<{
  event: types.InquiryTimelineEvent
  expanded: boolean
  customer: Customer
  homes?: types.HomeDetails[]
}> = ({ event, expanded, customer, homes }) => {
  const homeDetails = homes?.find((h) => h.mlsListingId === event.mlsListingId)

  const message = !!event.message ? (
    <SC.Subsection>
      <SC.EventSubheading>Message</SC.EventSubheading>
      <SC.MessageText>{event.message}</SC.MessageText>
    </SC.Subsection>
  ) : null

  const moveInDate = !!event.occupancyDate ? (
    <SC.Subsection>
      <SC.EventSubheading>Move In Date</SC.EventSubheading>
      <SC.MessageText>{event.occupancyDate}</SC.MessageText>
    </SC.Subsection>
  ) : null

  const ExternalListing: FunctionComponent = () => {
    switch (event.externalSource) {
      case 'rently':
        return homeDetails?.firestoreId ? (
          <SC.StyledLink
            href={`${RENTLY_PROPERTY_PAGE_SLUG}${homeDetails?.firestoreId}`}
          >
            Rently Link
          </SC.StyledLink>
        ) : null
      case 'zillow':
        return (
          <SC.StyledLink
            href={buildZillowUrlFromAddress(
              homeDetails?.address,
              homeDetails?.city,
              homeDetails?.state,
              homeDetails?.zip
            )}
          >
            Zillow Link
          </SC.StyledLink>
        )

      default:
        return (
          <SC.ErrorMessage>
            don't have a link to the listing yet
          </SC.ErrorMessage>
        )

      // TODO: add links for zumper, rentals.com, apartments.com, and rentable?
    }
  }

  const externalListing = (
    <SC.Subsection>
      <SC.EventSubheading>External Listing</SC.EventSubheading>
      <SC.MessageText>
        <ExternalListing />
      </SC.MessageText>
    </SC.Subsection>
  )

  const expandedContent = (
    <>
      {message}
      {externalListing}
      {moveInDate}
      <DisplayPropertyDetails details={homeDetails} />
      <SC.DescriptionText>
        Sent on{' '}
        <SC.InlineBold>
          {format(new Date(event.eventTimeAt), 'PP')}
        </SC.InlineBold>
      </SC.DescriptionText>
    </>
  )

  return (
    <div>
      <SC.MessageText>
        <SC.InlineBold>
          {customer.firstName} {customer.lastName}
        </SC.InlineBold>{' '}
        inquired about{' '}
        <SC.InlineBold>{homeDetails?.address || 'some property'}</SC.InlineBold>{' '}
        from {event.externalSource}
      </SC.MessageText>
      {expanded ? <SC.FadeIn>{expandedContent}</SC.FadeIn> : null}
    </div>
  )
}
