import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import {
  reStandardizeAddress,
  standardizeAddress
} from './addressStandardizationFunctions'
import { csvToJsonArr } from './csv_helpers'
import {
  daysBetween,
  removeBackSlashR,
  UPUP,
  combineTranformations,
  arrayToCSV
} from './helpers'
import {
  configureLlcData,
  configureLlcToFunds,
  configureProperties,
  configureRealEsateAcquisitions,
  memo_to_gl_account_id
} from './mappings'
import {
  calculateCostPerParty,
  correctLiabilityTimes,
  removeNonliableParties
} from './proration'
import {
  BillDates,
  Liability,
  Prorateable,
  ProratableLineItem,
  TypedUtilityCharge,
  BuildiumUtilityOutput,
  rental_liabilities_utility_chargeback
} from './utility_chargeback.types'

// const path = require('path');
// const here = path.resolve(process.cwd(), 'rental_utilities');

// const OUTPUT_DIR = 'rental_utilities/output_files';

// function getTestFiles() {
//   const inputFolder = `${here}/input_files`;

//   const testFiles = readdirSync(inputFolder).filter((file) => {
//     return file.includes('UpandUp');
//   });

//   const utilityBillFiles = testFiles.map((file) => {
//     let contents = readFileSync(`${inputFolder}/${file}`, 'utf-8');
//     return removeBackSlashR(contents);
//   });

//   const rawJsonArr = utilityBillFiles
//     .filter((contents) => {
//       try {
//         csvToJsonArr(contents);
//       } catch (er) {
//         console.log('fail', er);
//         return false;
//       }
//       return true;
//     })
//     .map((contents) => csvToJsonArr(contents));

//   return rawJsonArr;
// }

async function writeUiltityOutputToHasura(
  {
    buildium: buildium_csv,
    rental_liabilities,
    transformations,
    original_upload_url,
    rental_liabilities_csv
  }: any,
  saveProcessedUtilityBill: Function
) {
  const handleCreate = async (): Promise<void> => {
    const result = await saveProcessedUtilityBill({
      buildium_csv,
      rental_liabilities,
      rental_liabilities_csv,
      original_upload_url,
      transformations
    })
    if (result.error === undefined) {
      alert(result.error)
    }
  }

  handleCreate()
}

export const parseUtilityBillData = async (
  utilityBillData: string,
  propertyData: any,
  saveProcessedUtilityBill: any
) => {
  console.log(propertyData, utilityBillData)
  try {
    const { llcs, llc_properties, funds, fund_llcs, real_estate_acquisitions } =
      propertyData

    let utilityBill: Object[] = []

    try {
      utilityBill = csvToJsonArr(removeBackSlashR(utilityBillData))
    } catch (err) {
      console.log(err)
    }

    const llcToFund = configureLlcToFunds(funds, fund_llcs)
    const llcData = configureLlcData(llcs)
    const ownedProperties = configureProperties(llc_properties)
    const realEsateAcquisitionData = configureRealEsateAcquisitions(
      real_estate_acquisitions
    )

    const addToAmountsDue = ({
      liablePartyType,
      payer,
      lineItem
    }: {
      liablePartyType: string
      payer: Liability
      lineItem: ProratableLineItem
    }) => {
      if (!amountsDue[liablePartyType]) {
        amountsDue[liablePartyType] = {}
      }
      if (!amountsDue[liablePartyType][payer.id]) {
        amountsDue[liablePartyType][payer.id] = []
      }
      amountsDue[liablePartyType][payer.id].push(lineItem)
    }
    const parseVariablesFromRawLineItem = (line_item: any) => {
      const typeLineItem = (line_item: any) => {
        const [serviceStart, serviceEnd] = line_item['Service Period']
          .split('-')
          .map((date: string) => moment(date, 'MM/DD/YYYY'))

        return {
          lineItemDate: UPUP.date(line_item['Bill Date'], { addDay: false }),
          lineItemDueDate: UPUP.date(line_item['Bill Due Date'], {
            addDay: false
          }),
          reference_number: line_item['Reference Number'],
          memo: line_item['Memo'],
          total_amount_in_dollars: parseFloat(line_item['Total Bill Amount']),
          number_of_allocations_in_connected_line_items: parseInt(
            line_item['# of Itemized Allocations']
          ),
          street_address: line_item['Itemized Allocation Property Name'],
          amount_of_allocation_in_dollars: parseFloat(
            line_item['Itemized Allocation Amount']
          ),
          gl_account_id: line_item['Itemized Allocation GL Expense Account'],
          description: line_item['Itemized Allocation Description'],
          external_property_id: line_item['Property ID'],
          serviceStart: UPUP.date(serviceStart, { addDay: false }),
          serviceEnd: UPUP.date(serviceEnd, { addDay: false })
        } as TypedUtilityCharge
      }
      const getStandardizedAddress = (address: string) => {
        let standardizedAddress = standardizeAddress(address)

        if (
          !ownedProperties[standardizedAddress] &&
          !realEsateAcquisitionData[standardizedAddress]
        ) {
          standardizedAddress = reStandardizeAddress(standardizedAddress)
        }
        return standardizedAddress
      }

      const typedLineItem = typeLineItem(line_item)
      const {
        street_address: propertyAddress,
        serviceStart,
        serviceEnd,
        amount_of_allocation_in_dollars
      } = typedLineItem
      const standardizedAddress = getStandardizedAddress(propertyAddress)
      const billDates: BillDates = {
        serviceStart,
        serviceEnd
      }
      const daysInBillingPeriod = daysBetween(
        billDates.serviceStart,
        billDates.serviceEnd
      )
      const costPerDay = amount_of_allocation_in_dollars / daysInBillingPeriod

      return {
        ...typedLineItem,
        costPerDay,
        billDates,
        daysInBillingPeriod,
        standardizedAddress,
        amount_of_allocation_in_dollars,
        ownedPropertyBeingBilled: ownedProperties[standardizedAddress]
      }
    }

    // Buildable Outputs
    const amountsDue: {
      [liabilityPartyType: string]: {
        [liableParty: string]: ProratableLineItem[]
      }
    } = {}
    const propertiesNotFound: { [standardizedAddress: string]: any } = {}
    const duplicateLineItems: { [standardizedAddress: string]: any } = {}
    const transformationDict: { [reference_number: string]: {} } = {}
    const unmappedCharges = {}

    const handleRawLineItem = (line_item: any) => {
      const handlePropertyNotFound = (standardizedAddress: any) => {
        const key = `We standardized the address to: ${standardizedAddress}`
        if (!propertiesNotFound[key]) {
          propertiesNotFound[key] = []
        }
        propertiesNotFound[key].push(line_item)
      }

      const lineItemVariables = parseVariablesFromRawLineItem(line_item)

      let transformations = combineTranformations({
        changes: lineItemVariables,
        previousTransforms: { raw_line_item: line_item }
      })

      const {
        costPerDay,
        billDates,
        daysInBillingPeriod,
        standardizedAddress,
        memo,
        amount_of_allocation_in_dollars,
        ownedPropertyBeingBilled,
        reference_number,
        external_property_id
      } = lineItemVariables

      if (
        !ownedProperties[standardizedAddress] &&
        !realEsateAcquisitionData[standardizedAddress]
      ) {
        handlePropertyNotFound(standardizeAddress)
        return
      }

      const handleDuplicateBill = (referenceId: string) => {
        if (!duplicateLineItems[referenceId]) {
          duplicateLineItems[referenceId] = []
        }
        duplicateLineItems[referenceId].push(line_item)
      }

      const referenceId = `${external_property_id}.${reference_number}.${memo}`
      if (transformationDict[referenceId]) {
        handleDuplicateBill(referenceId)
      }

      if (!ownedPropertyBeingBilled) {
        const incompleteAcquisition =
          realEsateAcquisitionData[standardizedAddress]

        if (incompleteAcquisition) {
          const { llc_id } = incompleteAcquisition

          const formattedAcquisition: Liability = {
            reference_number,
            daysLiable: daysInBillingPeriod,
            amountDueInDollars: amount_of_allocation_in_dollars,
            startDate: billDates.serviceStart,
            endDate: billDates.serviceEnd,
            id: llc_id ?? 'No llc assigned',
            id_origin: 'llc_id'
          }

          const lineItem: ProratableLineItem = {
            reference_number,
            memo,
            daysPaidFor: daysInBillingPeriod,
            amount: amount_of_allocation_in_dollars,
            start: billDates.serviceStart,
            end: billDates.serviceEnd,
            buildium: lineItemVariables,
            address: standardizedAddress
          }

          addToAmountsDue({
            liablePartyType: 'llc_id',
            lineItem,
            payer: formattedAcquisition
          })
          return
        }
      }

      const ownership: Prorateable[] =
        ownedPropertyBeingBilled.propertyOwnership.map((owner) => {
          return {
            startDate: owner.start_date,
            endDate: owner.end_date,
            id: owner.owner_id,
            id_origin: 'llc_id'
          }
        })

      const occupancies: Prorateable[] =
        ownedPropertyBeingBilled.propertyOccupancy.map((occupant) => {
          return {
            startDate: occupant.occupancy_date,
            endDate: occupant.final_liability_date,
            id: occupant.rental_id,
            id_origin: 'rental_id'
          }
        })

      transformations = combineTranformations({
        previousTransforms: transformations,
        changes: { occupancies, ownership }
      })

      const [ownerLiabilities, occupantLiabilities] = [
        ownership,
        occupancies
      ].map((liableGroup) => {
        const liableParties: Prorateable[] = removeNonliableParties({
          potentiallyLiableParties: liableGroup,
          bill: billDates
        })
        const liablePartiesWithRelevantTimes: Prorateable[] =
          correctLiabilityTimes({
            liableParties,
            bill: billDates
          })
        const charges: Liability[] = calculateCostPerParty({
          arr: liablePartiesWithRelevantTimes,
          costPerDay
        })
        return { liableParties, liablePartiesWithRelevantTimes, charges }
      })

      transformations = combineTranformations({
        previousTransforms: transformations,
        changes: { ownerLiabilities, occupantLiabilities }
      })
      ;[ownerLiabilities, occupantLiabilities].forEach(({ charges }) => {
        charges.forEach((payer) => {
          const liablePartyType = payer.id_origin

          const ProratableLineItem: ProratableLineItem = {
            memo,
            amount: payer.amountDueInDollars,
            daysPaidFor: payer.daysLiable,
            start: payer.startDate,
            end: payer.endDate,
            reference_number,
            buildium: lineItemVariables,
            address: standardizedAddress
          }

          addToAmountsDue({
            liablePartyType,
            payer,
            lineItem: ProratableLineItem
          })
        })
      })

      const { amountStillDueInBill, daysStillDueInBill } =
        calculateLiabilityOutsideKnownOwnership(
          ownerLiabilities,
          amount_of_allocation_in_dollars,
          daysInBillingPeriod
        )

      if (amountStillDueInBill > 0) {
        handleLineItemForDatesOutsideOwnership()
      }

      transformationDict[referenceId] = transformations

      function calculateLiabilityOutsideKnownOwnership(
        ownerLiabilities: any,
        amount_of_allocation_in_dollars: any,
        daysInBillingPeriod: any
      ) {
        return ownerLiabilities.charges.reduce(
          (
            { daysStillDueInBill, amountStillDueInBill }: any,
            currentCharge: any
          ) => {
            daysStillDueInBill -= currentCharge.daysLiable
            amountStillDueInBill -= currentCharge.amountDueInDollars

            return { daysStillDueInBill, amountStillDueInBill }
          },
          {
            amountStillDueInBill: amount_of_allocation_in_dollars,
            daysStillDueInBill: daysInBillingPeriod
          }
        )
      }

      function handleLineItemForDatesOutsideOwnership() {
        const errorLineItem: ProratableLineItem = {
          memo,
          amount: amountStillDueInBill,
          daysPaidFor: daysStillDueInBill,
          start: billDates.serviceStart,
          end: billDates.serviceEnd,
          reference_number,
          buildium: lineItemVariables,
          address: standardizedAddress
        }

        const errorLiableParty: Liability = {
          reference_number,
          amountDueInDollars: amountStillDueInBill,
          daysLiable: daysStillDueInBill,
          startDate: billDates.serviceStart,
          endDate: billDates.serviceEnd,
          id: 'id for error llc',
          id_origin: 'errors'
        }

        transformations = combineTranformations({
          previousTransforms: transformations,
          changes: {
            knownLiabilitiesDoNotCoverBill: {
              errorLiableParty,
              errorLineItem
            }
          }
        })

        addToAmountsDue({
          liablePartyType: 'errors',
          payer: errorLiableParty,
          lineItem: errorLineItem
        })
      }
    }

    utilityBill.forEach(handleRawLineItem)

    console.log('Amoutnts DUe', amountsDue)

    const buildium: BuildiumUtilityOutput[] = Object.entries(amountsDue.llc_id)
      .map(([llc_id, charges]) => {
        return charges.map((charge) => {
          // eslint-disable-line
          if (_.get(memo_to_gl_account_id, charge.memo)) {
            // eslint-disable-line
            _.set(unmappedCharges, charge.memo, 0)
          }
          _.set(
            unmappedCharges,
            charge.memo,
            _.get(unmappedCharges, charge.memo)
          )

          const street =
            ownedProperties[charge.address]?.street ||
            realEsateAcquisitionData[charge.address]?.display_line_1

          const fund = llcToFund[llc_id]

          const llc = llcData[llc_id]
          const buildiumPropertyName = `${fund} - ${llc} - ${street}`
          return {
            'Bill Date': moment(charge.buildium.lineItemDate).format(
              'MM/DD/YYY'
            ),
            'Bill Due Date': moment(charge.buildium.lineItemDueDate).format(
              'MM/DD/YYY'
            ),
            Vendor: '"Homevest, Inc - Utilities"',
            'Reference Number': charge.reference_number,
            Memo: charge.memo,
            'Total Bill Amount':
              +charge.buildium.total_amount_in_dollars.toFixed(2),
            '# of Itemized allocations':
              charge.buildium.number_of_allocations_in_connected_line_items,
            'Itemized Allocation Property Name': buildiumPropertyName,
            'Itemized Allocation Amount': +charge.amount.toFixed(2),
            'Itemized Allocation GL Expense Account': _.get(
              memo_to_gl_account_id,
              charge.memo
            )
          } as BuildiumUtilityOutput
        })
      })
      .flat()

    const rental_liabilities: rental_liabilities_utility_chargeback[] =
      Object.entries(amountsDue.rental_id)
        .map(([rental_id, charges]) => {
          return charges.map((charge) => {
            return {
              Rental_UUID: rental_id,
              Liability_Type: `Utilities - ${charge.memo}`,
              Price: +charge.amount.toFixed(2),
              Service_Start: moment(+charge.start).format('MM/DD/YYYY'),
              Service_End: moment(+charge.end).format('MM/DD/YYYY')
            } as rental_liabilities_utility_chargeback
          })
        })
        .flat()

    const logResults = () => {
      console.log("Properties that we couldn't match", propertiesNotFound)
      console.log('Duplicate bills', duplicateLineItems)
      ;[buildium, rental_liabilities].forEach((output) => {
        const numberEntries = output.length
        console.log(`\n\nWriting ${numberEntries} rows`)
        console.table(output.slice(0, 5))
      })
    }

    logResults()

    const buildiumCSV = arrayToCSV(buildium)

    writeUiltityOutputToHasura(
      {
        buildium: buildiumCSV,
        rental_liabilities: JSON.stringify(rental_liabilities),
        transformations: JSON.stringify(transformationDict),
        original_upload_url: `wait for v2 ${Math.random()}`,
        rental_liabilities_csv: arrayToCSV(rental_liabilities)
      },
      saveProcessedUtilityBill
    )
  } catch (err) {
    console.error(err)
  }

  return <></>
}
