import React, { FunctionComponent } from 'react'
import * as SC from './StyledComponents'

const TwoColumnLayout: FunctionComponent<{
  mainContent: JSX.Element
  sidebarContent: JSX.Element
}> = ({ mainContent, sidebarContent }) => {
  return (
    <SC.ColumnsContainer>
      <SC.MainContainer>
        {/* Start main area*/}
        {mainContent}
        {/* End main area */}
      </SC.MainContainer>
      <SC.SidebarContainer>
        {/* Start secondary column (hidden on smaller screens) */}
        {sidebarContent}
        {/* End secondary column */}
      </SC.SidebarContainer>
    </SC.ColumnsContainer>
  )
}

export default TwoColumnLayout
