import React, { FunctionComponent, useState } from 'react'
import { types } from '@homevest/timelines'

import * as SC from '../StyledComponents'

const FilterButton: FunctionComponent<{
  setTypesToHide: Function
  typesToHide: types.EventTypeString[]
}> = ({ typesToHide, setTypesToHide }) => {
  // define the filter panel within the scope of the filter button so that
  // don't have to pass arguments along to the panel
  const [tours, setTours] = useState(typesToHide.includes('tour'))
  const [emails, setEmails] = useState(typesToHide.includes('email'))
  const [calls, setCalls] = useState(typesToHide.includes('call'))
  const [texts, setTexts] = useState(typesToHide.includes('sms'))
  const [notes, setNotes] = useState(typesToHide.includes('note'))
  // const [tasks, setTasks] = useState(typesToHide.includes('task'))
  const [rentalApps, setRentalApps] = useState(
    typesToHide.includes('rental_application')
  )
  const [inquiries, setInquiries] = useState(typesToHide.includes('inquiry'))

  const FilterPanel: FunctionComponent = () => {
    const filters: {
      name: string
      eventType: types.EventTypeString
      getter: boolean
      setter: Function
    }[] = [
      { name: 'Emails', eventType: 'email', getter: emails, setter: setEmails },
      { name: 'Calls', eventType: 'call', getter: calls, setter: setCalls },
      { name: 'Texts', eventType: 'sms', getter: texts, setter: setTexts },
      { name: 'Tours', eventType: 'tour', getter: tours, setter: setTours },
      {
        name: 'Rental Applications',
        eventType: 'rental_application',
        getter: rentalApps,
        setter: setRentalApps
      },
      { name: 'Notes', eventType: 'note', getter: notes, setter: setNotes },
      {
        name: 'Inquiries',
        eventType: 'inquiry',
        getter: inquiries,
        setter: setInquiries
      }
      // { name: 'Tasks', eventType: 'task', getter: tasks, setter: setTasks }
    ]

    return (
      <SC.FilterModal isOpen={isOpen}>
        {filters.map(({ name, getter, setter }, i) => (
          <SC.FilterCheckButton key={i} onClick={() => setter(!getter)}>
            <SC.FilterName>{name}</SC.FilterName>
            <SC.FilterInput type='checkbox' checked={!getter} readOnly />
          </SC.FilterCheckButton>
        ))}
        <SC.FilterApplyButton
          type='button'
          onClick={() => {
            setIsOpen(!isOpen)
            setTypesToHide(
              filters.filter((f) => f.getter).map(({ eventType }) => eventType)
            )
          }}
        >
          <SC.FilterName>Apply</SC.FilterName>
        </SC.FilterApplyButton>
      </SC.FilterModal>
    )
  }

  // this is the actual button component

  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <SC.ActionButton type='button' onClick={() => setIsOpen(!isOpen)}>
        <SC.AdjustmentsIcon />
        Filter
      </SC.ActionButton>
      <FilterPanel />
    </div>
  )
}

export default FilterButton
