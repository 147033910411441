import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, reduxForm, Field } from 'redux-form'
import {
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Button
} from 'reactstrap'
import styled from 'styled-components'
import { trim, each } from 'lodash'

import { firestoreCollections } from '../../lib/firebase'
import { createUser } from '../../state/users/actions'
import {
  phoneNumber,
  onlyNums,
  required,
  normalizePhone,
  email
} from '../../lib/form-validation'

import { BodyText } from 'components/Toolkit'

const FormContainer = styled.div`
  padding: 2em 1em;
  border: 1px solid #d8d8d8;
  margin: 1.5rem 0 0;
`

class UsersCreateForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired
  }

  state = {
    submitting: false
  }

  submit = async (data) => {
    // Format form inputs
    each(data, (value, key) => {
      if (key === 'phone') {
        data[key] = `+1${onlyNums(value)}`
      } else {
        data[key] = trim(value)
      }

      if (data[key] === '') {
        delete data[key]
      }
    })

    let userId

    try {
      this.setState({ submitting: true })

      if (data.email) {
        const email = data.email.toLowerCase()
        const existingUserQuery = await firestoreCollections.usersRef
          .where('email', '==', email)
          .limit(1)
          .get()

        let userId

        if (existingUserQuery.docs.length) {
          const existingUser = existingUserQuery.docs[0].data()

          userId = existingUser.id

          // eslint-disable-next-line no-undef
          window.alert(
            `Customer with email ${email} already exists - routing to their customer page`
          )

          this.props.reset()
          this.props.history.push(`/users/${userId}`)
          return
        }
      }

      const user = await this.props.createUser(data)
      userId = user.id

      this.props.reset()
      this.props.history.push(`/users/${userId}`)
    } catch (err) {
      console.error(err)
      // eslint-disable-next-line no-undef
      window.alert(err.message)
    } finally {
      this.setState({ submitting: false })
    }
  }

  render() {
    const { valid, handleSubmit } = this.props

    return (
      <FormContainer>
        <Form onSubmit={handleSubmit(this.submit)}>
          <FormGroup>
            <Label>First name *</Label>

            <InputGroup>
              <Field
                validate={[required]}
                component='input'
                className='form-control'
                type='text'
                name='first_name'
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Label>Last name *</Label>

            <InputGroup>
              <Field
                validate={[required]}
                component='input'
                className='form-control'
                type='text'
                name='last_name'
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Label>Email</Label>
            <InputGroup>
              <Field
                validate={[email]}
                component='input'
                className='form-control'
                type='email'
                name='email'
              />
            </InputGroup>
            <BodyText>* An email or phone must be provided</BodyText>
          </FormGroup>

          <FormGroup>
            <Label>Phone</Label>

            <InputGroup>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText>+1</InputGroupText>
              </InputGroupAddon>
              <Field
                normalize={normalizePhone}
                validate={[phoneNumber]}
                component='input'
                className='form-control'
                type='tel'
                name='phone'
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Label>Source</Label>

            <InputGroup>
              <Field component='select' className='form-control' name='source'>
                <option value='' />
                <option value='direct'>Direct (not rental sites or ads)</option>
                <option value='adwords'>AdWords</option>
                <option value='apartments.com'>apartments.com</option>
                <option value='facebook'>Facebook (includes IG)</option>
                <option value='google'>Google Search</option>
                <option value='rentable'>Rentable (ADOBO)</option>
                <option value='rentals.com'>rentals.com</option>
                <option value='rently'>Rently</option>
                <option value='tenant_turner'>Tenant Turner</option>
                <option value='zillow'>Zillow</option>
                <option value='zumper'>Zumper</option>
              </Field>
            </InputGroup>
          </FormGroup>
        </Form>

        {valid && !this.state.submitting && (
          <Button color='primary' onClick={handleSubmit(this.submit)}>
            Submit
          </Button>
        )}
      </FormContainer>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(reset('userCreateForm')),
  createUser: (data) => dispatch(createUser(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'userCreateForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(UsersCreateForm)
)

function validate() {
  const errors = {}

  return errors
}
