import React, { FunctionComponent } from 'react'
import * as SC from '../StyledComponents'

type EditModePropTypes = {
  content: string
  primary?: boolean
  addable?: boolean
  handleEdit?: Function
  handleDelete?: Function
  handleSetPrimary?: Function
  index?: number
  isInvalid?: boolean
  onBlur?: Function
}

const EditMode: FunctionComponent<EditModePropTypes> = ({
  content,
  primary,
  addable,
  handleEdit = () => {},
  handleDelete,
  handleSetPrimary = () => {},
  index,
  isInvalid = false,
  onBlur = () => {}
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleEdit(event.currentTarget.value, index)
    // if input is invalid, checks for it to become valid again on each keystroke
    if (isInvalid) {
      onBlur(event, index)
    }
  }
  return (
    <SC.EditDetailsWrapper invalid={isInvalid}>
      <SC.EditDetailsInput
        type='text'
        value={content}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => onBlur(event, index)}
      />

      {addable ? (
        <SC.IconContainer>
          <SC.InvisibleButton
            disabled={!content}
            onClick={() => handleSetPrimary(index)}
          >
            {primary ? <SC.PrimaryStar /> : <SC.NonPrimaryStar />}
          </SC.InvisibleButton>

          {handleDelete ? (
            <SC.InvisibleButton
              disabled={primary}
              onClick={() => handleDelete(index)}
            >
              <SC.TrashIcon visibility={primary ? 'hidden' : 'visible'} />
            </SC.InvisibleButton>
          ) : null}
        </SC.IconContainer>
      ) : null}
    </SC.EditDetailsWrapper>
  )
}

export default EditMode
