import React, { FunctionComponent, useEffect, useState } from 'react'
import { useQuery } from 'urql'
import * as SC from '../StyledComponents'
import { Spinner } from 'reactstrap'
import axios from 'lib/axios'

import { REQUEST_POLICY } from 'constants/urql'
import { DocumentsByUserIdDocument as queryDoc } from 'graphql/generated'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { mapContentToBadge } from '../badges'
import { DocumentType } from '../types'
import ReviewButtons from './ReviewButtons'

import { documents } from '@homevest/utils'
const { DOCUMENT_STATUSES } = documents

type DocumentsComponentPropTypes = {
  userId: string
}

const Documents: FunctionComponent<DocumentsComponentPropTypes> = ({
  userId
}) => {
  const [docUrls, setDocUrls] = useState<string[]>([])
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: queryDoc,
    variables: { id: userId },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK,
    context: React.useMemo(() => ({ suspense: false }), [])
  })

  useEffect(() => {
    if (!data?.documents) {
      return
    }

    const getDocUrls = async () => {
      const documentUrls = await Promise.all(
        data.documents.map((d: DocumentType) =>
          axios
            .get(`/admin/documents/${d.id}/url`)
            .then((resp) => resp.data.url)
        )
      )
      setDocUrls(documentUrls)
    }

    getDocUrls()
  }, [data])

  if (fetching) {
    return <Spinner />
  }
  if (error) {
    return <SC.ErrorMessage>{error.message}</SC.ErrorMessage>
  }

  const documents = data?.documents

  if (!documents.length) {
    return (
      <SC.StyledTable>
        <tbody>
          <SC.StyledTableRow>
            <SC.StyledTableData>No documents found for user</SC.StyledTableData>
          </SC.StyledTableRow>
        </tbody>
      </SC.StyledTable>
    )
  }

  return (
    <SC.StyledTable>
      <SC.StyledTableHeader>
        <tr>
          <SC.StyledTableHeadData>NAME</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>STATUS</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>UPLOADED</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>REVIEWED</SC.StyledTableHeadData>
          <SC.StyledTableHeadData>REVIEWER</SC.StyledTableHeadData>
          {/* placeholder for review buttons */}
          <SC.StyledTableHeadData />
          <SC.StyledTableHeadData />
        </tr>
      </SC.StyledTableHeader>
      <tbody>
        {documents.map((d: DocumentType, i: number) => (
          <SC.StyledTableRow key={d.id}>
            <SC.StyledTableData center>
              {docUrls[i] ? (
                <SC.StyledTableLink
                  rel='noopener noreferrer'
                  target='_blank'
                  href={`${docUrls[i]}`}
                >
                  {startCase(d.friendly_name)}
                </SC.StyledTableLink>
              ) : (
                <>{startCase(d.friendly_name)}</>
              )}
            </SC.StyledTableData>
            <SC.StyledTableData center>
              {mapContentToBadge(d.status, false)}
            </SC.StyledTableData>
            <SC.StyledTableData center>
              {format(new Date(d.created_at), 'PP')}
            </SC.StyledTableData>
            <SC.StyledTableData center>
              {d.reviewed_at ? format(new Date(d.reviewed_at), 'PP') : 'N/A'}
            </SC.StyledTableData>
            <SC.StyledTableData center>
              {d.reviewed_by_admin_id
                ? `${d.admin?.first_name} ${d.admin?.last_name}`
                : 'N/A'}
            </SC.StyledTableData>
            {d.status === DOCUMENT_STATUSES.PENDING_REVIEW ? (
              <SC.StyledTableData center>
                <ReviewButtons docId={d.id} reexecuteQuery={reexecuteQuery} />
              </SC.StyledTableData>
            ) : null}
            <SC.StyledTableData center />
          </SC.StyledTableRow>
        ))}
      </tbody>
    </SC.StyledTable>
  )
}

export default Documents
