import React from 'react'
import PropTypes from 'prop-types'
import { reset, reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import styled from 'styled-components'

import {
  required,
  email,
  normalizePhone,
  phoneNumber
} from '../../lib/form-validation'
import SuccessErrorMessage from './SuccessErrorMessage'
import axios from '../../lib/axios'

const StyledLabel = styled(Label)`
  font-weight: bold;
`

class AddAgentForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    valid: PropTypes.bool
  }

  state = {
    isError: false,
    message: ''
  }

  processPhoneNumber = (phone) => {
    if (!phone) {
      return
    }
    const onlyNums = phone.replace(/\D/gi, '')
    return `+1${onlyNums}`
  }

  buildRequestFromForm = (formValues) => {
    return {
      mls_id: formValues.mlsId,
      mls: formValues.mls,
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      email: formValues.email,
      phone: this.processPhoneNumber(formValues.phoneNumber)
    }
  }

  submitForm = async (formValues) => {
    try {
      const requestPayload = this.buildRequestFromForm(formValues)
      const response = await axios.post(
        '/admin/seller_agents/with_validation',
        requestPayload
      )
      const result = await response.data

      this.setState({
        isError: false,
        message: `Successfully added agent ${result.mls_id}!`
      })

      this.props.reset()
    } catch (err) {
      const errString = err.toString()
      if (errString.includes('404')) {
        this.setState({
          isError: true,
          message:
            'Agent was not recognized by HomeJuction - double check the MLS ID'
        })
      } else if (errString.includes('422')) {
        this.setState({
          isError: true,
          message: 'Agent is already in the database!'
        })
      }
    }
  }

  render() {
    const { handleSubmit, valid } = this.props

    return (
      <Form onSubmit={handleSubmit(this.submitForm)}>
        {this.state.message ? (
          <Row>
            <SuccessErrorMessage
              isError={this.state.isError}
              message={this.state.message}
            />
          </Row>
        ) : null}
        <Row>
          <Col md={3}>
            <FormGroup>
              <StyledLabel for='firstName'> First Name </StyledLabel>
              <Field
                className='form-control'
                name='firstName'
                component='input'
                type='text'
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <StyledLabel for='lastName'> Last Name </StyledLabel>
              <Field
                className='form-control'
                name='lastName'
                component='input'
                type='text'
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <StyledLabel for='mlsId'>
                {' '}
                MLS ID <sup style={{ color: 'red' }}>*</sup>{' '}
              </StyledLabel>
              <Field
                className='form-control'
                name='mlsId'
                component='input'
                type='text'
                validate={required}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <StyledLabel for='mls'> MLS </StyledLabel>
              <Field
                className='form-control'
                name='mls'
                component='input'
                type='text'
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <StyledLabel for='email'> Email </StyledLabel>
              <Field
                className='form-control'
                name='email'
                component='input'
                type='text'
                validate={email}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <StyledLabel for='phoneNumber'>Phone </StyledLabel>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>+1</InputGroupText>
                </InputGroupAddon>
                <Field
                  className='form-control'
                  normalize={normalizePhone}
                  validate={phoneNumber}
                  component='input'
                  type='tel'
                  name='phoneNumber'
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Button
              onClick={handleSubmit(this.submitForm)}
              type='button'
              style={{ marginBottom: 10, marginRight: 10 }}
              color='primary'
              disabled={!valid}
              md={2}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

function validate() {
  const errors = {}

  return errors
}

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(reset('addAgentForm'))
})

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'addAgentForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(AddAgentForm)
)
