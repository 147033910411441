import { Interaction } from 'types/Timeline'
import {
  Voice,
  RentalApplicationEvent,
  RentalSiteInquiry,
  TourEvent,
  SMS,
  Email
} from 'types/Timeline'

// a series of functions that will validate the type of interaction
// passed along using a user-defined interaction constructor property

const isSMS = (interaction: Interaction): interaction is SMS => {
  return interaction.interactionType === 'sms'
}
const isEmail = (interaction: Interaction): interaction is Email => {
  return interaction.interactionType === 'email'
}
const isVoice = (interaction: Interaction): interaction is Voice => {
  return interaction.interactionType === 'voice'
}
const isTour = (interaction: Interaction): interaction is TourEvent => {
  return interaction.interactionType === 'tour'
}
const isRentalSiteInquiry = (
  interaction: Interaction
): interaction is RentalSiteInquiry => {
  return interaction.interactionType === 'rental_site_inquiry'
}
const isRentalAppEvent = (
  interaction: Interaction
): interaction is RentalApplicationEvent => {
  return interaction.interactionType === 'rental_application_event'
}

export const VALIDATORS = Object.freeze({
  isSMS,
  isEmail,
  isVoice,
  isTour,
  isRentalSiteInquiry,
  isRentalAppEvent
})
