import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Row, Col } from 'reactstrap'
import { startCase } from 'lodash'
import { formatPhoneNumber } from '../../lib/phone'
import styled from 'styled-components'
import { addSellerAgentToSystem } from '../../state/properties/actions'

const Container = styled.div`
  display: 'flex';
  flex: 1;
  flexd-irection: 'column';
`
const IndentRow = styled(Row)`
  margin-left: 15px;
`

const Key = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 15px;
`
const Value = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 10px;
`

const AddContactButton = styled(Button)`
  margin-bottom: 20px;
`

export default function MLSAgentDetails({ property }) {
  const dispatch = useDispatch()
  const sellerAgentMLSIds = useSelector(
    (state) => state.properties.sellerAgentMLSIds
  )

  const {
    listing_agent: listingAgent,
    mls,
    firestore_id: propertyId
  } = property

  const handleButtonClick = () => {
    dispatch(addSellerAgentToSystem(listingAgent, mls, propertyId))
  }

  if (!listingAgent) {
    return null
  }

  return (
    <Container>
      <Header>MLS Agent Info:</Header>
      <Col>
        {listingAgent.first_name || listingAgent.last_name ? (
          <IndentRow>
            <Key>Name:</Key>
            <Value style={{ marginBottom: '5px' }}>
              {startCase(listingAgent.first_name)}{' '}
              {startCase(listingAgent.last_name)}
            </Value>
          </IndentRow>
        ) : (
          <IndentRow>
            <Key>MLS ID:</Key>
            <Value style={{ marginBottom: '5px' }}>{listingAgent.mls_id}</Value>
          </IndentRow>
        )}

        {listingAgent.phone ? (
          <IndentRow>
            <Key>Phone:</Key>
            <Value>{formatPhoneNumber(listingAgent.phone)}</Value>
          </IndentRow>
        ) : null}
        {listingAgent.email ? (
          <IndentRow>
            <Key>Email:</Key>
            <Value>{listingAgent.email}</Value>
          </IndentRow>
        ) : null}

        {sellerAgentMLSIds.includes(listingAgent.mls_id) ? null : (
          <AddContactButton
            outline
            color='primary'
            size='sm'
            onClick={handleButtonClick}
          >
            + Add to Contacts
          </AddContactButton>
        )}
      </Col>
    </Container>
  )
}

MLSAgentDetails.propTypes = {
  property: PropTypes.object.isRequired
}
