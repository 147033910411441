import React from 'react'
import ReactDOM from 'react-dom'
import './lib/sentry'
import 'bootstrap/dist/css/bootstrap.css'
import 'sanitize.css/sanitize.css'
import './index.css'
import { initializeDatadog } from 'datadog'
import App from './App'

initializeDatadog()

ReactDOM.render(<App />, document.getElementById('root'))
