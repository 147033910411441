"use strict";
const moment = require('moment-timezone');
const { STATES } = require('./regions');
exports.DATE_FORMATS = Object.freeze({
    READABLE_DATE: 'MMM Do, YYYY',
    YEAR_MONTH_DAY: 'YYYY-MM-DD'
});
exports.TIME_ZONES = Object.freeze({
    CENTRAL: 'America/Chicago',
    EASTERN: 'America/New_York',
    MOUNTAIN: 'America/Denver',
    PACIFIC: 'America/Los_Angeles'
});
exports.formatISODate = (date, format = exports.DATE_FORMATS.YEAR_MONTH_DAY) => {
    // This isMoment check is very important because it preserves
    // the timezones of any passed in dates that are already Moments
    return moment.isMoment(date)
        ? date.format(format)
        : moment(date).format(format);
};
exports.isEndOfMonth = (date) => {
    return moment(date).isSame(moment(date).endOf('month'), 'day');
};
exports.isSameDay = (date, secondDate) => {
    return moment(date).isSame(secondDate, 'day');
};
exports.getTimezoneFromState = (state) => {
    switch (state) {
        case STATES.MO:
        case STATES.IN:
            return exports.TIME_ZONES.CENTRAL;
        case STATES.CA:
            return exports.TIME_ZONES.PACIFIC;
        case STATES.GA:
            return exports.TIME_ZONES.EASTERN;
        default:
            throw new Error(`Cannot get timezone from state: ${state}.`);
    }
};
