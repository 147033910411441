import React, { useMemo, useState } from 'react'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { useMutation } from 'urql'
import { rentals as RENTALS } from '@homevest/utils'
import startCase from 'lodash/startCase'

import { Button, Dropdown, Input, ErrorText } from 'components/Toolkit'
import { Upup_SetMoveOutDateDocument as MoveOut } from 'graphql/generated'
import { getStatusForLeaseEndDateAndLeaseEndReason } from 'lib/rentals'
import FullRental from 'types/FullRental'

const { LEASE_END_REASONS } = RENTALS

const leaseEndReasonOptions = Object.values(LEASE_END_REASONS).map(
  (reason) => ({
    label: startCase(reason),
    value: reason
  })
)

export default function MoveOutForm({
  onSubmit = () => null,
  rental
}: {
  onSubmit: () => void
  rental: FullRental
}) {
  const [moveOutDate, setMoveOutDate] = useState(
    rental.move_out_date || moment().format('YYYY-MM-DD')
  )
  const [leaseEndReason, setLeaseEndReason] = useState(
    rental.lease_end_reason || ''
  )
  const [isSaving, setIsSaving] = useState(false)

  const [{ fetching, error }, setMoveOutDateMutation] = useMutation(MoveOut)

  const finalLiabilityDate = rental.final_liability_date

  const { isValid, editDisabled, submitDisabled } = useMemo(() => {
    const _isValid =
      moveOutDate &&
      moment(moveOutDate).isValid() &&
      (!finalLiabilityDate ||
        moment(moveOutDate).isSameOrBefore(finalLiabilityDate)) &&
      leaseEndReason
    const _editDisabled = fetching || isSaving

    return {
      isValid: _isValid,
      editDisabled: _editDisabled,
      submitDisabled: !_isValid || _editDisabled
    }
  }, [moveOutDate, leaseEndReason, isSaving, fetching, finalLiabilityDate])

  const submitForm = async () => {
    if (!isValid || submitDisabled) {
      return
    }

    const status = getStatusForLeaseEndDateAndLeaseEndReason(
      moveOutDate,
      leaseEndReason,
      rental.status
    )

    setIsSaving(true)

    const result = await setMoveOutDateMutation({
      lease_end_reason: leaseEndReason,
      move_out_date: moment(moveOutDate).format('YYYY-MM-DD'),
      status,
      id: rental.id
    })

    setIsSaving(false)
    if (result.error === undefined) {
      setMoveOutDate('')
      onSubmit()
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        {finalLiabilityDate && (
          <p>Must set move out date no later than {finalLiabilityDate}</p>
        )}
        <Input
          label='Move out date'
          type='date'
          onChange={setMoveOutDate}
          value={moveOutDate}
          placeholder='Enter the move out date'
          required
          disabled={editDisabled}
          hasError={!moveOutDate}
        />

        <Dropdown
          disabled={editDisabled}
          label='Lease End Reason'
          placeholder='Select a reason'
          onChange={setLeaseEndReason}
          options={leaseEndReasonOptions}
          value={leaseEndReason || ''}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={submitForm}
            disabled={submitDisabled}
          >
            Set Move Out Date
          </Button>
          {error && <ErrorText>{error.message}</ErrorText>}
        </div>
      </React.Suspense>
    </>
  )
}
