import React, { FunctionComponent, useState } from 'react'

import * as SC from '../StyledComponents'

const InputModal: FunctionComponent<{
  isOpen: boolean
  setIsOpen: Function
  callback: (input: string) => void
  message: string
}> = ({ isOpen, setIsOpen, callback, message }) => {
  const [value, setValue] = useState('')

  const handleSubmit = () => {
    callback(value)
    setIsOpen(false)
    setValue('')
  }

  const handleCancel = () => {
    setIsOpen(false)
    setValue('')
  }

  return (
    <SC.Modal isOpen={isOpen}>
      <SC.ModalSubText>{message}</SC.ModalSubText>
      <SC.EditDetailsWrapper>
        <SC.EditDetailsInput
          type='text'
          value={value}
          onChange={(evt) => setValue(evt.currentTarget.value)}
        />
      </SC.EditDetailsWrapper>
      <SC.ButtonContainer>
        <SC.ContinueButton onClick={handleSubmit}>Submit</SC.ContinueButton>
        <SC.CancelButton show onClick={handleCancel}>
          Cancel
        </SC.CancelButton>
      </SC.ButtonContainer>
    </SC.Modal>
  )
}

export default InputModal
