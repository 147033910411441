"use strict";
exports.VERIFICATION_EXTERNAL_SOURCES = Object.freeze({
    STRIPE: 'stripe'
});
exports.VERIFICATION_STATUSES = Object.freeze({
    CANCELED: 'canceled',
    PROCESSING: 'processing',
    REQUIRES_ACTION: 'requires_action',
    UNVERIFIED: 'unverified',
    VERIFIED: 'verified'
});
exports.VERIFICATION_TYPES = Object.freeze({
    IDENTITY_DOCUMENT: 'identity_document'
});
