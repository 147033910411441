import React, { FunctionComponent } from 'react'
import * as SC from '../StyledComponents'
import NonEditMode from './NonEditMode'
import EditMode from './EditMode'

type SingleDetailRowPropTypes = {
  header: string
  content: JSX.Element | string
  editable?: boolean
  onEdit?: Function
}

const SingleDetailRow: FunctionComponent<SingleDetailRowPropTypes> = ({
  header,
  content,
  editable = false,
  onEdit = () => {}
}) => {
  return (
    <SC.DetailBoxWithBottomBorder>
      <SC.InfoHeading>{header}</SC.InfoHeading>
      <SC.EditDetailsBox>
        {!editable ? (
          <NonEditMode content={content} />
        ) : (
          <EditMode content={content.toString()} handleEdit={onEdit} />
        )}
      </SC.EditDetailsBox>
    </SC.DetailBoxWithBottomBorder>
  )
}

export default SingleDetailRow
