import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Container } from 'reactstrap'

import UserTask from '../components/UserTask'

class UserTasksScreen extends Component {
  static propTypes = {
    userTasks: PropTypes.array.isRequired
  }

  render() {
    return (
      <Container>
        <h3>Incomplete User Tasks</h3>

        <Table responsive>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Task Name</th>
              <th>Created At</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {this.props.userTasks.map((userTask) => (
              <UserTask userTask={userTask} key={userTask.id} />
            ))}
          </tbody>
        </Table>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  userTasks: Array.from(state.userTasks.data.values())
})

export default connect(mapStateToProps, null)(UserTasksScreen)
