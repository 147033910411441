import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { kebabCase } from 'lodash'

import { firebaseConfig } from '../lib/config'

firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()

export const mergeLeftOrCreate = async (ref, defaults) => {
  const doc = await ref.get()
  let data = defaults

  if (doc.exists) {
    data = Object.assign(defaults, doc.data())
  }

  await ref.set(data)
}

export const generateUploadPath = (userId, key) => {
  return `/users/${userId}/documents/${kebabCase(key)}`
}

export const firestoreCollections = {
  autoPostedRentalSiteListingsRef: firestore.collection(
    'auto_posted_rental_site_listings'
  ),
  savedPropertiesRef: firestore.collection('saved_properties'),
  creditRequestsRef: firestore.collection('credit_requests'),
  propertySettingsRef: firestore.collection('property_settings'),
  usersRef: firestore.collection('users'),
  newPaymentSourcesRef: firestore.collection('new_payment_sources'),
  userTasksRef: firestore.collection('user_tasks'),
  savedSearchFiltersRef: firestore.collection('saved_search_filters'),
  userPropertyInteractionsRef: firestore.collection(
    'user_property_interactions'
  )
}

export default firebase
