import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Tooltip } from 'reactstrap'

export default function AgentSearchDetails({ agent, onSelect, isPrimary }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <Row
      style={{
        marginLeft: 0,
        marginRight: 0,
        marginTop: '1rem',
        paddingBottom: '1rem',
        borderBottom: '1px solid black'
      }}
    >
      <Col md={6}>
        <div>
          <b>Name: </b>
          {agent.first_name} {agent.last_name}
        </div>
        <div>
          <b>Phone: </b>
          {agent.phone}
        </div>
      </Col>
      <Col md={{ size: 6, offset: 18 }}>
        {isPrimary === undefined ? (
          <Button color='success' size='sm' onClick={() => onSelect(agent)}>
            Add +
          </Button>
        ) : (
          <span>
            <Button id={`btn-${agent.id}`} color='secondary' size='sm'>
              {isPrimary ? 'Primary Agent' : 'Other Agent'}
            </Button>
            <Tooltip
              placement='right'
              isOpen={tooltipOpen}
              target={`btn-${agent.id}`}
              toggle={toggle}
            >
              This agent is already added to the property!
            </Tooltip>
          </span>
        )}
      </Col>
    </Row>
  )
}

AgentSearchDetails.propTypes = {
  agent: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isPrimary: PropTypes.bool
}
