import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'

const StyledTextArea = styled.textarea`
  background: ${COLORS.PRIMARY.COCONUT};
  border: ${(props) =>
    props.hasError
      ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
      : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  box-sizing: border-box;
  border-radius: 10px;
  color: ${(props) =>
    props.type === 'date'
      ? COLORS.LIGHT_NEUTRALS.PARSNIP
      : COLORS.PRIMARY.PLUM};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 168.68%;
  padding: 10px 22px;
  transition: border 200ms;
  width: 100%;

  &::placeholder {
    color: ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  }

  &:focus {
    border: ${(props) =>
      props.hasError
        ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
        : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  }
`

const StyledInputContainer = styled.div`
  margin: ${(props) => (props.hasLabel ? '25px 0' : '')};
`

function TextArea({
  label,
  value,
  onChange,
  hasError,
  noMargin = false,
  ...otherProps
}) {
  return (
    <StyledInputContainer hasLabel={!noMargin && Boolean(label)}>
      {label && <Label>{label}</Label>}
      <StyledTextArea
        value={value}
        onChange={(event) => onChange(event.target.value)}
        hasError={hasError}
        {...otherProps}
      />
    </StyledInputContainer>
  )
}

TextArea.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string,
  noMargin: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
}

export default memo(TextArea)
