import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get } from 'lodash'

import CrmField from 'components/CrmField'

import { HeaderText, BodyText, Label } from 'components/Toolkit'
import LeadType from '../../components/LeadType'

function UserInfo({
  leadGroupUser,
  firestoreUser,
  crmUser,
  crmFields,
  updateField,
  hasSigned
}) {
  const user = leadGroupUser.user
  const creditScore =
    get(leadGroupUser, 'user.successful_credit_report.score') ||
    get(leadGroupUser, 'user.credit_report.score', 'N/A')

  return (
    <div>
      <div>
        <HeaderText
          style={{ display: 'flex', justifyContent: 'space-between' }}
          size='h3'
        >
          <span>
            <Link
              to={`/users/${user.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {user.first_name} {user.last_name}
            </Link>
          </span>
          <span style={{ color: '#CCC' }}>
            {hasSigned ? 'Signed Lease' : ''}
          </span>
        </HeaderText>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ width: '33%' }}>
          <LeadType leadGroupUser={leadGroupUser} />
        </div>
        <div style={{ width: '33%' }}>
          <Label>Email</Label>
          <BodyText style={{ marginTop: 0 }}>{user.email}</BodyText>
        </div>
        <div style={{ width: '33%' }}>
          <Label>Phone</Label>
          <BodyText style={{ marginTop: 0 }}>{user.phone || 'N/A'}</BodyText>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <div style={{ width: '33%' }}>
          <Label>Verified credit</Label>
          <BodyText style={{ marginTop: 0 }}>{creditScore}</BodyText>
        </div>
        <div style={{ width: '33%' }}>
          <Label>Date of birth</Label>
          <BodyText style={{ marginTop: 0 }}>
            {get(firestoreUser, 'underwriting.dateOfBirth', 'N/A')}
          </BodyText>
        </div>
        <div style={{ width: '33%' }}>
          <Label>Current address</Label>
          <BodyText style={{ marginTop: 0 }}>
            {get(firestoreUser, 'underwriting.currentAddress.formatted', 'N/A')}
          </BodyText>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        {crmFields.map((item) => (
          <div key={item.id} style={{ width: '33%' }}>
            <CrmField
              label={item.label}
              value={
                crmUser.fields[item.label] === undefined
                  ? null
                  : crmUser.fields[item.label]
              }
              fieldValueType={item.details.value_type}
              fieldType={item.details.type}
              options={item.options.map((option) => ({
                ...option,
                value: option.id
              }))}
              onChange={(val) => {
                updateField(user.id, item.id, val)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

UserInfo.propTypes = {
  crmFields: PropTypes.array.isRequired,
  crmUser: PropTypes.object.isRequired,
  documentUrls: PropTypes.array.isRequired,
  firestoreUser: PropTypes.object.isRequired,
  hasSigned: PropTypes.bool,
  leadGroupUser: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired
}

export default memo(UserInfo)
