import includes from 'lodash/includes'
import get from 'lodash/get'
import { properties, regions } from '@homevest/utils'
import humanizeString from 'humanize-string'

const { REGIONS } = regions

const { homevestStatus } = properties

export const getBathsString = (mlsListing) => {
  return `${mlsListing.baths_full + mlsListing.baths_half / 2}`
}

export const canBeReviewed = (property) => {
  const { internal_status: propertyHomevestStatus } =
    property.internal_attributes[0]

  if (
    includes(
      [
        homevestStatus.pendingReview,
        homevestStatus.listed,
        homevestStatus.internallyListed
      ],
      propertyHomevestStatus
    )
  ) {
    return property.images && property.images.length
      ? [true]
      : [false, 'missing images']
  } else {
    return [false, `status is ${humanizeString(propertyHomevestStatus)}`]
  }
}

// TODO should come from db/backend eventually
export const buyboxZipCodes = [
  '63116',
  '63109',
  '63139',
  '63143',
  '63119',
  '63132',
  '63130',
  '63123',
  '63111',
  '63117',
  '63144',
  '63110',
  '63301',
  '63043',
  '63074',
  '63385',
  '63376',
  '63126',
  '63118',
  '63125',
  '63125',
  '63104',
  '63108',
  '63112',
  '63141',
  '63114'
]

// TODO region should come from db/backend eventually
export const isInBuyBox = (property) => {
  return (
    property.region === REGIONS.ST_LOUIS &&
    property.listPrice < MAX_BUYBOX_PRICE &&
    includes(buyboxZipCodes, property.address.zip)
  )
}

export function calculateYieldFromConfig(propertyConfig = {}) {
  const { tenantTerms, acquisition } = propertyConfig

  const rent = get(tenantTerms, 'rent')
  const purchasePrice = get(acquisition, 'purchasePrice')
  const improvementCost = get(acquisition, 'improvementCost')
  const closingCostPercentage = get(acquisition, 'closingCostPercentage')

  return calculateYield(
    rent,
    purchasePrice,
    improvementCost,
    closingCostPercentage
  )
}

export function calculateYield(
  rent,
  purchasePrice,
  improvementCost,
  closingCostRate
) {
  return (
    (rent * 12) /
    getTotalPropertyPrice(purchasePrice, improvementCost, closingCostRate)
  )
}

export function getTotalPropertyPrice(
  purchasePrice,
  improvementCost,
  closingCostRate
) {
  return improvementCost + purchasePrice + purchasePrice * closingCostRate
}

export function getYieldFromTotalPrice(totalPrice) {
  if (totalPrice < 89999) {
    return 0.13
  } else if (totalPrice >= 90000 && totalPrice < 99999) {
    return 0.125
  } else if (totalPrice >= 100000 && totalPrice < 109999) {
    return 0.12
  } else if (totalPrice >= 110000 && totalPrice < 119999) {
    return 0.115
  } else if (totalPrice >= 120000 && totalPrice < 139999) {
    return 0.11
  } else if (totalPrice >= 140000 && totalPrice < 149999) {
    return 0.108
  } else if (totalPrice >= 150000 && totalPrice < 174999) {
    return 0.105
  } else if (totalPrice >= 175000 && totalPrice < 199999) {
    return 0.1025
  } else if (totalPrice >= 200000 && totalPrice < 274999) {
    return 0.0985
  } else if (totalPrice >= 275000 && totalPrice < 324999) {
    return 0.095
  } else {
    return 0.09
  }
}

export function getRentFromYield(rentYield, totalPrice) {
  return Math.round((rentYield * totalPrice) / 12)
}

const defaultImprovementCost = 2500
const defaultClosingCostRate = 0.015

export function getRentFromProperty(property) {
  if (property.rent) return property.rent

  const totalPrice = getTotalPropertyPrice(
    property.guidancePrice || property.listPrice,
    defaultImprovementCost,
    defaultClosingCostRate
  )
  const rentYield = getYieldFromTotalPrice(totalPrice)
  return getRentFromYield(rentYield, totalPrice)
}

// TODO should come from db/backend eventually,
//   probably broken out by zip or region
export const MAX_BUYBOX_PRICE = 300000

export const getTimezoneFromProperty = (property) => {
  const { state } = property.address

  if (state === 'CA') {
    return 'America/Los_Angeles'
  } else if (state === 'MO') {
    return 'America/Chicago'
  } else if (state === 'GA') {
    return 'America/New_York'
  } else {
    console.warn(
      `Unsupported state ${state}, so we are defaulting to Eastern Time`
    )

    return 'America/New_York'
  }
}

export const getPropertiesAddressString = (firestoreProperties) =>
  firestoreProperties
    .sort((a, b) => (a.address.displayLine1 > b.address.displayLine1 ? 1 : -1))
    .reduce((acc, p, idx) => {
      const address = p.address.displayLine1

      if (idx === 0) {
        return `${acc}${address}`
      } else if (idx === firestoreProperties.length - 1) {
        return `${acc} and ${address}`
      } else {
        return `${acc}, ${address}`
      }
    }, '')
