import upperFirst from 'lodash/upperFirst'

export const getName = (name: string) => {
  const splits = name.split(' ')

  const firstName =
    splits[0] === splits[0].toUpperCase()
      ? upperFirst(splits[0].toLowerCase())
      : splits[0]

  const lastName =
    splits[1] === splits[1].toUpperCase()
      ? upperFirst(splits[1].toLowerCase())
      : splits[1]

  return { firstName, lastName }
}

export const getContactInfo = (
  type: string,
  userContactDetails?: { contact_type: string; contact_info: string }[]
) =>
  (userContactDetails ?? []).find(({ contact_type }) => type === contact_type)
    ?.contact_info
