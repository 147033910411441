import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InteractionGroup from './InteractionGroup'
import { firestoreCollections } from 'lib/firebase'
import { findMlsListingsByFirestoreIds } from 'lib/graphql/axios'

const StyledContainer = styled.div`
  padding: 20px;
  height: calc(100vh - 248px);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const StyledColumn = styled.div`
  height: 50%;
  width: 50%;
  padding-bottom: 20px;
`

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #302654;
`

const INTERACTIONS = {
  HIDDEN: 'hidden',
  SAVED: 'saved',
  BOOKED: 'booked',
  UPANDUP_RECOMMENDED: 'upandup_recommended'
}

function getEmptySet() {
  return Object.values(INTERACTIONS).reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  )
}

async function getData(setActivityGroupings, userId) {
  const userPropertyInteractionsData =
    await firestoreCollections.userPropertyInteractionsRef
      .where('userId', '==', userId)
      .get()

  const propertyInteraction = userPropertyInteractionsData.docs
    .map((doc) => doc.data())
    .reduce((acc, item) => ({ ...acc, [item.propertyId]: item }), {})

  const propertyIds = Object.keys(propertyInteraction)
  const {
    data: { mls_listings: properties }
  } = await findMlsListingsByFirestoreIds(propertyIds)

  const activityGroupingsRetrieved = properties
    .map((property) => {
      return {
        ...property,
        interaction: propertyInteraction[property.firestore_id]
      }
    })
    .reduce((acc, item) => {
      acc[item.interaction.interaction].push(item)
      return acc
    }, getEmptySet())

  setActivityGroupings(activityGroupingsRetrieved)
}

function HomeInteractions({ userId }) {
  const [activityGroupings, setActivityGroupings] = useState(null)

  useEffect(() => {
    getData(setActivityGroupings, userId)
  }, [setActivityGroupings, userId])

  if (!activityGroupings) {
    return (
      <StyledLoading>
        <span>Loading...</span>
      </StyledLoading>
    )
  }
  return (
    <StyledContainer>
      <StyledColumn>
        <InteractionGroup
          label={`Saved (${activityGroupings[INTERACTIONS.SAVED].length})`}
          homes={activityGroupings[INTERACTIONS.SAVED]}
        />
      </StyledColumn>

      <StyledColumn>
        <InteractionGroup
          label={`Booked (${activityGroupings[INTERACTIONS.BOOKED].length})`}
          homes={activityGroupings[INTERACTIONS.BOOKED]}
        />
      </StyledColumn>
      <StyledColumn>
        <InteractionGroup
          label={`Recommended (${
            activityGroupings[INTERACTIONS.UPANDUP_RECOMMENDED].length
          })`}
          homes={activityGroupings[INTERACTIONS.UPANDUP_RECOMMENDED]}
        />
      </StyledColumn>
    </StyledContainer>
  )
}

HomeInteractions.propTypes = {
  userId: PropTypes.string.isRequired
}

export default HomeInteractions
