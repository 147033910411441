import React, { FunctionComponent, useEffect, useState } from 'react'

import axios from 'lib/axios'
import { Rental_Applications } from 'graphql/generated'

import * as SC from '../StyledComponents'
import { mapContentToBadge } from '../badges'
import { Spinner } from 'reactstrap'
import { startCase } from 'lodash'
import { format } from 'date-fns'

import {
  INTERNAL_PROPERTY_PAGE_SLUG,
  INTERNAL_APPLICATION_SLUG
} from 'constants/urls'

const Application: FunctionComponent<{ userId: string }> = ({ userId }) => {
  const [applications, setApplications] = useState([])
  const [fetching, setFetching] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const { data } = await axios.get(
          `/admin/rental_applications?user_id=${userId}`
        )
        setApplications(data)
      } catch (err: any) {
        setErrorMessage(err.message)
        setFetching(false)
        throw err
      }
      setFetching(false)
    }

    fetchApplications()
  }, [userId])

  if (fetching) {
    return (
      <SC.DetailsContainer borders>
        <Spinner />
      </SC.DetailsContainer>
    )
  }

  if (errorMessage) {
    return (
      <SC.DetailsContainer borders>
        <SC.ErrorMessage>{errorMessage}</SC.ErrorMessage>
      </SC.DetailsContainer>
    )
  }

  if (!applications.length) {
    return (
      <SC.DetailsContainer borders>
        <SC.MessageText>No applications found for user</SC.MessageText>
      </SC.DetailsContainer>
    )
  }

  return (
    <>
      {applications.map((a: Rental_Applications) => (
        <SC.DetailsContainer borders key={a.id}>
          <SC.SubsectionTitleLink
            rel='noopener noreferrer'
            target='_blank'
            href={`${INTERNAL_PROPERTY_PAGE_SLUG}${a.mls_listing.firestore_id}`}
          >
            {`${a.mls_listing.display_line_1}, ${a.mls_listing.display_line_2}` ||
              'Address Not Found'}
          </SC.SubsectionTitleLink>
          <SC.DetailText>{mapContentToBadge(a.status)}</SC.DetailText>
          <SC.LightDetailText>
            <SC.GrayCalendar />
            {startCase(a.status)} on {format(new Date(a.updated_at), 'PP')}
          </SC.LightDetailText>
          <SC.SubsectionTitleLink
            rel='noopener noreferrer'
            target='_blank'
            href={`${INTERNAL_APPLICATION_SLUG}${a.id}`}
          >
            <SC.AppLink />
            Application link
          </SC.SubsectionTitleLink>
        </SC.DetailsContainer>
      ))}
    </>
  )
}

export default Application
