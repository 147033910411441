import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { HeaderText, Button } from 'components/Toolkit'

function Review({ isSaving, review }) {
  return (
    <div>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '20px',
          backgroundColor: '#fff'
        }}
      >
        <div>
          <HeaderText size='h4'>Initial Document Review</HeaderText>
        </div>
        {!isSaving ? (
          <div style={{ marginTop: '1rem' }}>
            <Button
              isSecondary
              disabled={isSaving}
              style={{ marginRight: '1.5rem' }}
              onClick={() => review(false)}
              size='s'
            >
              Needs cosigner
            </Button>
            <Button
              disabled={isSaving}
              style={{ marginRight: '1.5rem' }}
              onClick={() => review(true)}
              size='s'
            >
              Looks good
            </Button>
          </div>
        ) : (
          <b>Saving...</b>
        )}
      </div>
    </div>
  )
}

Review.propTypes = {
  isSaving: PropTypes.bool,
  review: PropTypes.func.isRequired
}

export default memo(Review)
