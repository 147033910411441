// the file containing the CustomerProfile screen
import React, { FunctionComponent } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { validate as isValidUUID } from 'uuid'

import CustomerProfile from 'components/360/CustomerProfile'

const Profile: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>()
  const history = useHistory()

  if (!isValidUUID(userId)) {
    history.push('/users')
  }

  return <CustomerProfile userId={userId} />
}

export default Profile
